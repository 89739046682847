import { Container, Grid, IconButton, Step, StepButton, Stepper, useMediaQuery, useTheme, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import BusinessIcon from '@material-ui/icons/Business';
import HomeIcon from '@material-ui/icons/Home';
import { DeepPartial } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { AppDispatch } from '../../../../../../browser';
import { DadoBancarioVO } from '../../../../../models/DadoBancario';
import { EmpresaVO } from '../../../../../models/Empresa';
import { EnderecoVO } from '../../../../../models/Endereco';
import { ImagemVO } from '../../../../../models/Imagem';
import { fetchEmpresaByCnpj, trackFetchEmpresaByCnpj, patchEmpresaDadosBasicos, patchEmpresaEndereco, patchEmpresaDadoBancario, changeEmpresaImagemLogo } from '../../../../../stores/slices/entities/empresasSlice';
import { useDialog } from '../../../../components/dialog/PageServiceProvider';
import ErrorEmptyState from '../../../../components/empty/ErrorEmptyState';
import Loading from '../../../../components/Loading';
import PageTemplate from '../../../../layout/PageTemplate';
import { DadosBancariosForm, DadosBancariosFormMode } from '../../../../components/form/shared/DadosBancariosForm';
import { EmpresaDadosBasicosForm, EmpresaDadosBasicosFormMode } from '../create/components/EmpresaDadosBasicosForm';
import { EmpresaReviewCadastro } from '../create/components/EmpresaReviewCadastro';
import { EnderecoForm, EnderecoFormMode } from '../../../../components/form/shared/EnderecoForm';
import { EmpresaImagemLogoForm, EmpresaImagemLogoFormMode } from '../create/components/EmpresaImagemLogoForm';
import { useSnackbar } from 'notistack';
import { Patch } from 'immer';
import { APIError } from '../../../../../services/api/APIError';
import { generateErrorDialog } from '../../../../../services/api/APIErrorUtils';

enum EmpresaEditSteps {
  DADOS_BASICOS,
  ENDERECO,
  DADOS_BANCARIOS,
  LOGO,
  REVISAO
}

export const EmpresaEditPage = () => {

  const history = useHistory();
  const { from } = history.location.state as any || { from: { pathname: "/admin/empresas" } };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const dispatch = useDispatch<AppDispatch>()
  const { cnpj } = useParams<{ cnpj: string }>();
  const requestState = useSelector(trackFetchEmpresaByCnpj(cnpj));

  const [state, setState] = useState<DeepPartial<EmpresaVO>|undefined>();

  const dialog = useDialog();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [step, setStep] = useState(EmpresaEditSteps.REVISAO);

  useEffect(() => {
    const promise = dispatch(fetchEmpresaByCnpj({ cnpj }));
    return () => promise.abort();
  }, [cnpj, dispatch]);

  useEffect(() => {
    if(requestState.data){
      setState(requestState.data)
    }
  }, [requestState.data]);


  const handleDadosBasicosSave = async (nextState: DeepPartial<EmpresaVO>, patches: Patch[], changes: DeepPartial<EmpresaVO>) => {
    const resultAction = await dispatch(patchEmpresaDadosBasicos({ cnpj, changes }));
    if (patchEmpresaDadosBasicos.fulfilled.match(resultAction)) {
      enqueueSnackbar('Dados básicos alterados com sucesso!', {
        variant: 'success',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });
      setState({ ...state, ...nextState });
      setStep(EmpresaEditSteps.REVISAO);
      scrollTo({ top: 0, behavior: "smooth" })
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível atualizar os dados básicos. Tente novamente."))
    }
  };

  const handleEnderecoSave = async (nextState: DeepPartial<EnderecoVO>, patches: Patch[], changes: DeepPartial<EnderecoVO>) => {
    const resultAction = await dispatch(patchEmpresaEndereco({ cnpj, changes }));
    if (patchEmpresaEndereco.fulfilled.match(resultAction)) {
      enqueueSnackbar('Endereço alterado com sucesso!', {
        variant: 'success',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });
      setState({ ...state, endereco: { ...nextState } });
      setStep(EmpresaEditSteps.REVISAO);
      scrollTo({ top: 0, behavior: "smooth" })
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível atualizar endereço. Tente novamente."))
    }
  };

  const handleDadosBancariosSave = async (nextState: DeepPartial<DadoBancarioVO>, patches: Patch[], changes: DeepPartial<DadoBancarioVO>) => {
    const resultAction = await dispatch(patchEmpresaDadoBancario({ cnpj, changes }));
    if (patchEmpresaDadoBancario.fulfilled.match(resultAction)) {
      enqueueSnackbar('Dados Bancários alterados com sucesso!', {
        variant: 'success',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });
      setState({ ...state, dadoBancario: { ...nextState } });
      setStep(EmpresaEditSteps.REVISAO);
      scrollTo({ top: 0, behavior: "smooth" })
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível atualizar dados bancários. Tente novamente."))
    }
  };

  const handleImagemLogoSave = async (nextState: DeepPartial<ImagemVO>) => {
    const resultAction = await dispatch(changeEmpresaImagemLogo({ cnpj, imagemLogo: nextState }));
    if (changeEmpresaImagemLogo.fulfilled.match(resultAction)) {
      enqueueSnackbar('Logo trocado com sucesso!', {
        variant: 'success',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });
      setState({ ...state, imagemLogo: { ...nextState } });
      setStep(EmpresaEditSteps.REVISAO);
      scrollTo({ top: 0, behavior: "smooth" })
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível trocar logo. Tente novamente."))
    }
  };

  const handleBack = () => {
    setStep(EmpresaEditSteps.REVISAO)
    scrollTo({ top: 0, behavior: "smooth" })
  }

  const handleDadosBasicosEdit = () => setStep(EmpresaEditSteps.DADOS_BASICOS)
  const handleEnderecoEdit = () => setStep(EmpresaEditSteps.ENDERECO)
  const handleDadosBancariosEdit = () => setStep(EmpresaEditSteps.DADOS_BANCARIOS)
  const handleLogoEdit = () => setStep(EmpresaEditSteps.LOGO)

  return (
    <PageTemplate
      metaTags={{
        title: "Gottaup.com | Editar Empresa",
        description: "Atualize o cadastro da empresa"
      }}
      menuDesktop
      headerMobile={{
        title: "Editar Empresa",
        leftButtons: (
          <IconButton edge="start" color="inherit" component={Link} to={from}>
            <ArrowBackIcon />
          </IconButton>
        )
      }}
      headerDesktop={{
        title: "Editar Empresa",
        breadcrumbs: [
          { label: "Home", to: '/', icon: HomeIcon },
          { label: "Empresas", to: '/admin/empresas', icon: BusinessIcon },
          { label: "Editar" }
        ]
      }}
    >

      <Container component="section" style={{ marginTop: 16 }}>

        {requestState.pending &&
          <Loading text="Carregando Formulário" />
        }

        {requestState.error &&
          <ErrorEmptyState
            error={requestState.error}
            defaultDescription="Não foi possível carregar dados da empresa"
            onTryAgainClick={() => dispatch(fetchEmpresaByCnpj({ cnpj }))}
          />
        }

        {requestState.data && state &&

          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <Stepper nonLinear activeStep={step} orientation={isDesktop ? "vertical" : "horizontal"} alternativeLabel={!isDesktop} variant="outlined"  >
                <Step>
                  <StepButton onClick={handleDadosBasicosEdit} icon=" " style={{textAlign: 'left'}}>Dados Básicos</StepButton>
                </Step>
                <Step>
                  <StepButton onClick={handleEnderecoEdit} icon=" ">Endereco</StepButton>
                </Step>
                <Step>
                  <StepButton onClick={handleDadosBancariosEdit} icon=" " style={{textAlign: 'left'}}>Dados Bancários</StepButton>
                </Step>
                <Step>
                  <StepButton onClick={handleLogoEdit} icon=" ">Logo</StepButton>
                </Step>
              </Stepper>
            </Grid>
            <Grid item xs={12} md={10}>
              {step == EmpresaEditSteps.DADOS_BASICOS &&
                <EmpresaDadosBasicosForm
                  empresa={state}
                  submitText="Salvar"
                  onBack={handleBack}
                  formMode={EmpresaDadosBasicosFormMode.EDIT}
                  onSubmit={handleDadosBasicosSave} />
              }
              {step == EmpresaEditSteps.ENDERECO &&
                <EnderecoForm
                  endereco={state.endereco||{}}
                  onBack={handleBack}
                  onSubmit={handleEnderecoSave}
                  formMode={EnderecoFormMode.EDIT}
                  submitText="Salvar" />
              }
              {step == EmpresaEditSteps.DADOS_BANCARIOS &&
                <DadosBancariosForm
                  dadoBancario={state.dadoBancario||{}}
                  onSubmit={handleDadosBancariosSave}
                  onBack={handleBack}
                  formMode={DadosBancariosFormMode.EDIT}
                  submitText="Salvar" />
              }
              {step == EmpresaEditSteps.LOGO &&
                <EmpresaImagemLogoForm
                  imagem={state.imagemLogo||{}}
                  formTitle="Logo"
                  onSubmit={handleImagemLogoSave}
                  onBack={handleBack}
                  formMode={EmpresaImagemLogoFormMode.EDIT}
                  submitText="Salvar" />
              }
              {step == EmpresaEditSteps.REVISAO &&
                <EmpresaReviewCadastro
                  empresa={state}
                  onDadosBancariosEdit={handleDadosBancariosEdit}
                  onDadosBasicosEdit={handleDadosBasicosEdit}
                  onEnderecoEdit={handleEnderecoEdit}
                  onLogoEdit={handleLogoEdit}
                />
              }
            </Grid>
          </Grid>
        }

      </Container>

    </PageTemplate >
  )
}
import { createStyles, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(() => createStyles({
    message: {
        minHeight: '1em',
        position: 'relative',
        background: '#F8F8F9',
        padding: '1em 1.5em',
        lineHeight: '1.4285em',
        color: 'rgba(0, 0, 0, 0.87)',
        transition: 'opacity 0.1s ease, color 0.1s ease, background 0.1s ease, box-shadow 0.1s ease, -webkit-box-shadow 0.1s ease',
        borderRadius: '0.28571429rem',
        boxShadow: '0px 0px 0px 1px rgba(34, 36, 38, 0.22) inset, 0px 0px 0px 0px rgba(0, 0, 0, 0)',
        width: '100%',
        display: 'flex'
    },
    negative: {
        backgroundColor: '#FFF6F6',
        color: '#9F3A38'
    },
    content: {
        margin: '0.75em 0em'
    },
    icon: {
        cursor: 'pointer',
        position: 'absolute',
        margin: '0em',
        right: '0.5em',
        opacity: 0.7,
        transition: 'opacity 0.1s ease',
        '&:hover': {
            opacity: 1
        }
    }
}), { name: 'Message' });

export interface MessageProps {
    id?: string;
    content: string;
    onDismiss?: () => void;
}

export default function Message(props: MessageProps) {
    const classes = useStyles();

    const handleDismiss = () => {
        if (props.onDismiss) props.onDismiss()
    }

    return (
        <div id={props.id} className={clsx({
            [classes.message]: true,
            [classes.negative]: true
        })}>
            <CloseIcon className={classes.icon} onClick={handleDismiss} />
            <p className={classes.content}>{props.content}</p>
        </div>
    );
}
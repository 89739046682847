import { Container, Typography, Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import ArrowRightIcon from 'mdi-material-ui/ArrowRight';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    color: theme.palette.common.white,
    backgroundColor: '#141d21',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      height: '50vh',
      minHeight: 500,
      maxHeight: 1300,
    },
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  subtitle: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
    color: 'rgba(255,255,255,.8)'
  },

  estatisticas: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },

  },
  titleMarkedCenter: {
    height: 4,
    width: 73,
    display: 'block',
    margin: `${theme.spacing(1)}px auto 0`,
    backgroundColor: theme.palette.primary.main,
  },
  button: {
    marginTop: theme.spacing(8),
    height: 'auto',
    padding: theme.spacing(2, 5)
  },
}), { name: 'CapteConosco' });


export default function CapteConosco() {

  const classes = useStyles();


  return (
    <section className={classes.root}>
      <Container className={classes.container}>

        <Typography color="inherit" align="center" variant="h2">
          SELEÇÃO DE PROJETOS
        <span className={classes.titleMarkedCenter} />
        </Typography>
        <Typography color="inherit" align="center" variant="h5" className={classes.subtitle}>
          <p>Possui um projeto e está em busca de recursos para transformá-lo em realidade?</p>
          <p>Submeta seu projeto para avaliação.</p>
        </Typography>
        <Button
          size="large"
          variant="contained"
          color="secondary"
          component={Link}
          to="/captar"
          className={classes.button}
          endIcon={<ArrowRightIcon />}
        >
          Descubra como
        </Button>


      </Container>
    </section>

  );
}

import { Box, Button, Grid, Paper, Typography, IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import { DeepPartial } from 'redux';
import { ImagemVO } from '../../../../../../models/Imagem';
import ButtonProgress from '../../../../../components/ButtonProgress';
import ConfirmLeavePage from '../../../../../components/ComfirmLeavePage';
import { FileUtils } from '../../../../../../utils/files';
import { ImageUtils } from '../../../../../../utils/images';
import FormPhotoGaleryUpload from '../../../../../components/form/inputs/FileUpload/FormPhotoGaleryUpload';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { APIUtils } from '../../../../../../services/api/APIUtils';

export enum ProjetoGaleriaFotosFormMode {
  CREATE,
  EDIT
}

interface ProjetoGaleriaFotosFormProps {
  galeriaFotos: DeepPartial<ImagemVO>[];
  onSubmit: (imagensToInsert: DeepPartial<ImagemVO>[], imagensToDelete: DeepPartial<ImagemVO>[], galeriaFotos: DeepPartial<ImagemVO>[]) => Promise<void> | void;
  onBack?: () => void;
  submitText: string;
  formMode: ProjetoGaleriaFotosFormMode;
}

export const ProjetoGaleriaFotosForm = ({ galeriaFotos, onSubmit, onBack, submitText, formMode }: ProjetoGaleriaFotosFormProps) => {

  const { handleSubmit, formState: { isSubmitting } } = useForm({
    mode: "onBlur",
    //defaultValues: generateFormDefaultValues(imagem)
  });

  const [imagensGaleria, setImagensGaleria] = useState<DeepPartial<ImagemVO>[]>(galeriaFotos);
  const imagensToInsert = imagensGaleria.filter(img => img.nuImagem == undefined);
  const imagensToDelete = galeriaFotos.filter(img => !imagensGaleria.includes(img))

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleFormSubmit = async () => {
    await onSubmit(imagensToInsert, imagensToDelete, imagensGaleria)
  }

  const handleImageDelete = (index: number) => {
    setImagensGaleria(imagensGaleria.filter((img, idx) => idx != index)); // Remove da galeria
  }

  const handleImageUpload = async (files: File[]) => {
    const acceptedPhotos: DeepPartial<ImagemVO>[] = [];
    for (const file of files) {
      if (!file.name.match(/.[png|jpg]$/)) {
        // Imagem inválida
        const dismissButton = (key: string | number | undefined) => <IconButton onClick={() => closeSnackbar(key)}>OK</IconButton>
        enqueueSnackbar("Arquivo não aceito! Selecione um arquivo .png ou .jpg", { variant: 'error', action: dismissButton, autoHideDuration: 5000 });
        return; // Retorna para não processar outras imagens
      }
      // Imagem Aceita
      const imageResized = FileUtils.dataURLtoBase64(await ImageUtils.resizeImageKeepProportions(await FileUtils.fileToDataURL(file), 800, 800));
      acceptedPhotos.push({ noImagem: file.name, imImagem: imageResized })
    }
    // Imagens processadas com sucesso, então atualiza estado.
    setImagensGaleria(imagensGaleria.concat(acceptedPhotos));// Atualiza Galeria
  }


  return (
    <form id="formImagemCapa" noValidate autoComplete="off" onSubmit={handleSubmit(handleFormSubmit)}>
      <ConfirmLeavePage
        when={imagensToInsert.length > 0 || imagensToDelete.length > 0}
        title="Tem certeza que deseja deixar a página sem salvar as alterações?"
        content="Ao confirmar os dados alterados não serão salvos. Se cancelar, poderá continuar a edição."
      />
      <Paper variant="outlined" square >
        <Box p={2}>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <Typography variant="h6">Galeria de Fotos</Typography>
            </Grid>

            <Grid item xs={12} >

              <FormPhotoGaleryUpload
                photosUrl={imagensGaleria.map(img => APIUtils.generateImageURL(img))}
                accept=".jpg, .png"
                helperText="Utilize imagens .png ou .jpg com pelo menos 800px"
                onDelete={handleImageDelete}
                onUpload={handleImageUpload}
              />

            </Grid>

            <Grid item container justify="flex-end" spacing={2}>
              {onBack &&
                <Grid item>
                  <Button
                    id="btnVoltar"
                    variant="text"
                    color="default"
                    onClick={onBack}
                  >
                    Voltar
              </Button>
                </Grid>
              }
              <Grid item>
                <ButtonProgress
                  id="btnSubmit"
                  loading={isSubmitting}
                  disabled={formMode == ProjetoGaleriaFotosFormMode.EDIT && imagensToInsert.length == 0 && imagensToDelete.length == 0}
                  variant="contained"
                  color="primary"
                  type="submit">
                  {submitText}
                </ButtonProgress>
              </Grid>
            </Grid>


          </Grid>


        </Box>
      </Paper>
    </form>
  )
}

import AbortController from 'abort-controller';
import fetch from 'isomorphic-fetch';
import Empresa from '../models/Empresa';
import Investimento from '../models/Investimento';
import APIErrorMessage from './APIErrorMessage';
import APIException from './APIException';
import { getServiceURL } from './ServiceURL';

const BASE_URL = `${getServiceURL()}/api/admin/projetos`;
//const TRANSACTION_TIMEOUT = 5000;

export default class ProjetoService {

    async listReservasByProjeto(nuProjeto: number, token: string): Promise<Array<Investimento>> {
        if (__isBrowser__ && location.hostname != "localhost" && navigator.onLine !== undefined && navigator.onLine === false ) {
            throw new APIException(new APIErrorMessage("Navegador Offline - Verifique sua conexão com a internet"));
        } 
        const controller = new AbortController();
        const signal = controller.signal;
        const timeoutId = setTimeout(() => controller.abort(), TRANSACTION_TIMEOUT);
        let response;
        try {
            response = await fetch(`${BASE_URL}/${nuProjeto}/reservas`, { 
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                signal 
            });
        } catch (ex) {
            console.log(ex);
            throw new APIException(new APIErrorMessage().setFromError(ex));
        }
        clearTimeout(timeoutId);
        if (response.status == 200) {
            const json = await response.json();
            return json.map((obj: any) => new Investimento(obj));
        } else {
            throw new APIException(await new APIErrorMessage().setFromResponse(response));
        }

    }



    async listInvestimentosByProjeto(nuProjeto: number, token: string): Promise<Array<Investimento>> {
        if (__isBrowser__ && location.hostname != "localhost" && navigator.onLine !== undefined && navigator.onLine === false ) {
            throw new APIException(new APIErrorMessage("Navegador Offline - Verifique sua conexão com a internet"));
        } 
        const controller = new AbortController();
        const signal = controller.signal;
        const timeoutId = setTimeout(() => controller.abort(), TRANSACTION_TIMEOUT);
        let response;
        try {
            response = await fetch(`${BASE_URL}/${nuProjeto}/investimentos`, { 
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                signal 
            });
        } catch (ex) {
            console.log(ex);
            throw new APIException(new APIErrorMessage().setFromError(ex));
        }
        clearTimeout(timeoutId);
        if (response.status == 200) {
            const json = await response.json();
            return json.map((obj: any) => new Investimento(obj));
        } else {
            throw new APIException(await new APIErrorMessage().setFromResponse(response));
        }

    }
    
    async searchInvestimentosUploadContrato(nuProjeto: number, token: string): Promise<Array<Investimento>> {
        if (__isBrowser__ && location.hostname != "localhost" && navigator.onLine !== undefined && navigator.onLine === false ) {
            throw new APIException(new APIErrorMessage("Navegador Offline - Verifique sua conexão com a internet"));
        } 
        const controller = new AbortController();
        const signal = controller.signal;
        const timeoutId = setTimeout(() => controller.abort(), TRANSACTION_TIMEOUT);
        let response;
        try {
            response = await fetch(`${BASE_URL}/${nuProjeto}/investimentosuploadcontrato`, { 
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                signal 
            });
        } catch (ex) {
            console.log(ex);
            throw new APIException(new APIErrorMessage().setFromError(ex));
        }
        clearTimeout(timeoutId);
        if (response.status == 200) {
            const json = await response.json();
            return json.map((obj: any) => new Investimento(obj));
        } else {
            throw new APIException(await new APIErrorMessage().setFromResponse(response));
        }

    }

  
    

    async findEmpresa(id: number, token: string): Promise<Empresa|undefined>{
        if (__isBrowser__ && location.hostname != "localhost" && navigator.onLine !== undefined && navigator.onLine === false ) {
            throw new APIException(new APIErrorMessage("Navegador Offline - Verifique sua conexão com a internet"));
        } 
        const controller = new AbortController();
        const signal = controller.signal;
        const timeoutId = setTimeout(() => controller.abort(), TRANSACTION_TIMEOUT);
        let response;
        try {
            response = await fetch(`${BASE_URL}/${id}/empresa`,{
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                signal 
            });
        } catch (ex) {
            console.log(ex);
            throw new APIException(new APIErrorMessage().setFromError(ex));
        }
        clearTimeout(timeoutId);
        if (response.status == 200) {
            return new Empresa(await response.json());
        } else if (response.status === 404) {
            return undefined;
        } else {
            throw new APIException(await new APIErrorMessage().setFromResponse(response));
        }
    }


}

import { Box, Button, Container, createStyles, Grid, IconButton, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core';
import { lighten, withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HomeIcon from '@material-ui/icons/Home';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import moment from 'moment';
import React, { useEffect } from 'react';
import ReactImageGallery from 'react-image-gallery';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, useParams, match } from 'react-router-dom';
import { AppDispatch } from '../../../../../../browser';
import { TipoPagamentoAmortizacao } from '../../../../../models/enums/TipoPagamentoAmortizacao';
import { TipoPagamentoJuros } from '../../../../../models/enums/TipoPagamentoJuros';
import { TipoRentabilidadeGarantida } from '../../../../../models/enums/TipoRentabilidadeGarantida';
import { TipoRentabilidadeProjetada } from '../../../../../models/enums/TipoRentabilidadeProjetada';
import { APIUtils } from '../../../../../services/api/APIUtils';
import { fetchDetalhesCaptacao, trackFetchDetalhesCaptacao } from '../../../../../stores/slices/entities/captacoesSlice';
import { selectLogin } from '../../../../../stores/slices/userSlice';
import { LoginUtils } from '../../../../../utils/login';
import DownloadBox from '../../../../components/DownloadBox';
import ErrorEmptyState from '../../../../components/empty/ErrorEmptyState';
import Loading from '../../../../components/Loading';
import OnlyDesktop from '../../../../layout/OnlyDesktop';
import OnlyMobile from '../../../../layout/OnlyMobile';
import { PageSubtitle } from '../../../../layout/PageSubtitle';
import PageTemplate from '../../../../layout/PageTemplate';
import ForumPerguntasProjeto from './components/ForumPerguntasProjeto';
import HistoricoPerformance from './components/HistoricoPerformance';
import Simulador from './components/Simulador';

const useStyles = makeStyles((theme: Theme) => createStyles({
  imagemCapa: {
    width: '100%'
  },
  logo: {
    width: '100%'
  },
  labelText: {
    color: 'rgba(0,0,0,.5)'
  },
  informacoesProjeto: {
    color: 'rgba(24,24,24,.9)',
    letterSpacing: '-.02em',
    lineHeight: 1.5,
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4)
  },
  simulador: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },

}), { name: 'DetalhesProjeto' });

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: lighten('#EA5911', 0.5),
  },
  bar: {
    backgroundColor: '#EA5911',
  },
})(LinearProgress);

export const DetalhesProjetoPage = () => {

  const classes = useStyles();

  const dispatch = useDispatch<AppDispatch>()
  const { permalink } = useParams<{ permalink: string }>();
  const { pending: isLoading, error, data: projeto } = useSelector(trackFetchDetalhesCaptacao(permalink));

  const intl = useIntl();

  const login = useSelector(selectLogin);


  const location = useLocation();
  const { from } = location.state as any || { from: "/investir" };


  useEffect(() => {
    const promise = dispatch(fetchDetalhesCaptacao({ permalink }));
    return () => promise.abort();
  }, [permalink, dispatch]);


  if (!projeto) {
    return (
      <PageTemplate
        menuDesktop
        headerMobile={{
          title: "Detalhes do Projeto",
          leftButtons: (
            <IconButton edge="start" color="inherit" component={RouterLink} to={from}>
              <ArrowBackIcon />
            </IconButton>
          )
        }}
        loading={{ isLoading }}
      >
        {isLoading &&
          <Loading text="Carregando Captação..." />
        }

        {error &&
          <ErrorEmptyState
            error={error}
            defaultDescription="Não foi possível carregar captação"
            onTryAgainClick={() => dispatch(fetchDetalhesCaptacao({ permalink }))}
          />
        }
      </PageTemplate>
    );
  } else {
    return (
      <PageTemplate
        metaTags={{
          title: `Gottaup.com | ${projeto.empresa?.noFantasia}: ${projeto.noProjeto}`,
          description: projeto.deProjeto || '',
          imageUrl: APIUtils.generateImageURL(projeto.imagemCapa)
        }}
        menuDesktop
        headerMobile={{
          title: "Detalhes do Projeto",
          leftButtons: (
            <IconButton edge="start" color="inherit" component={RouterLink} to={from}>
              <ArrowBackIcon />
            </IconButton>
          )
        }}
        headerDesktop={{
          title: `${projeto.empresa?.noFantasia}: ${projeto.noProjeto}`,
          breadcrumbs: [
            { label: "Home", to: '/', icon: HomeIcon },
            { label: "Investir", to: '/investir', icon: MonetizationOnIcon },
            { label: projeto.empresa?.noFantasia || 'Projeto' }
          ]
        }}
      >

        <Container component="section" style={{ marginTop: 24 }}>

          <Grid container spacing={3}>

            <Grid item xs={12} md={8}>

              <ReactImageGallery
                items={[
                  { original: APIUtils.generateImageURL(projeto.imagemCapa), thumbnail: APIUtils.generateImageURL(projeto.imagemCapa) },
                  ...projeto.galeriaFotos?.map((foto) => { return { original: APIUtils.generateImageURL(foto), thumbnail: APIUtils.generateImageURL(foto) } }) || []
                ]}
                showThumbnails={true}
                showPlayButton={false}
                additionalClass={classes.imagemCapa}
              />
              {/* 
                <img className={classes.imagemCapa} src={captacao.imagemCapa && captacao.imagemCapa.imagemURL} alt={"capa do projeto da " + (captacao.empresa && captacao.empresa.noFantasia)} />
                */}
            </Grid>

            <Grid item xs={12} md={4}>

              <Grid container spacing={3}>

                <Grid item xs={12}>
                  <Grid container direction="column">
                    <Grid container justify="space-between">
                      <Typography variant="subtitle1" color="inherit" component="p" className={classes.labelText}>Reservado</Typography>
                      <Typography variant="subtitle1" color="inherit" component="p" className={classes.labelText}>Alvo</Typography>
                    </Grid>
                    <Grid container justify="space-between">
                      <Typography variant="h6" color="inherit" component="p">{intl.formatNumber(projeto.captacaoProjeto.vrTotalReservado, { style: "currency", currency: "BRL" })}</Typography>
                      <Typography variant="h6" color="inherit" component="p">{intl.formatNumber(projeto.vrAlvoCaptacao, { style: "currency", currency: "BRL" })}</Typography>
                    </Grid>
                    <BorderLinearProgress
                      variant="determinate"
                      color="primary"
                      value={(projeto.captacaoProjeto.vrTotalReservado / projeto.vrAlvoCaptacao) < 1 ? (projeto.captacaoProjeto.vrTotalReservado / projeto.vrAlvoCaptacao) * 100 : 100}
                    />
                    <Grid container justify="flex-end">
                      <Typography variant="subtitle1" color="inherit" component="p" className={classes.labelText}>{intl.formatNumber(projeto.captacaoProjeto.vrTotalReservado / projeto.vrAlvoCaptacao, { style: "percent", minimumFractionDigits: 2 })}</Typography>
                    </Grid>

                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <DownloadBox name="Prospecto da oferta" url={APIUtils.generateDocumentoURL(projeto.documentoProspecto)} icon="pdf" />
                </Grid>

                <Grid item xs={12}>
                  <Grid container direction="column">
                    <Typography variant="subtitle1" color="inherit" component="p" className={classes.labelText}>Rentabilidade</Typography>
                    <Typography variant="h6" color="inherit" component="p">{projeto.tipoRentabilidadeProjetada == TipoRentabilidadeProjetada.Fixa ? `${intl.formatNumber(projeto.pcRentabilidadeProjetadaFixaAnual || 0, { style: "percent", minimumFractionDigits: 2 })} a.a.` : `${intl.formatNumber(projeto.pcRentabilidadeProjetadaMinimaAnual || 0, { style: "percent", minimumFractionDigits: 2 })} a ${intl.formatNumber(projeto.pcRentabilidadeProjetadaMaximaAnual || 0, { style: "percent", minimumFractionDigits: 2 })} a.a.`}</Typography>

                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container direction="column">
                    <Typography variant="subtitle1" color="inherit" component="p" className={classes.labelText}>Prazo do Investimento</Typography>
                    <Typography variant="h6" color="inherit" component="p">{projeto.qtPrazoMesesTitulo} meses</Typography>

                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container direction="column">
                    <Typography variant="subtitle1" color="inherit" component="p" className={classes.labelText}>Investimento Mínimo</Typography>
                    <Typography variant="h6" color="inherit" component="p">{intl.formatNumber(projeto.vrCotaEmitida, { style: "currency", currency: "BRL" })}</Typography>

                  </Grid>
                </Grid>

                {projeto.tipoRentabilidadeGarantida && projeto.tipoRentabilidadeGarantida == TipoRentabilidadeGarantida["% do CDI Pré-fixado"] &&
                  <Grid item xs={12}>
                    <Grid container direction="column">
                      <Typography variant="subtitle1" color="inherit" component="p" className={classes.labelText}>Mínimo garantido</Typography>
                      <Typography variant="h6" color="inherit" component="p">{intl.formatNumber(projeto.pcRentabilidadeGarantidaFixaAnual || 0, { style: "percent", minimumFractionDigits: 2 })} a.a. ({intl.formatNumber(projeto.pcRentabilidadeGarantidaCdi || 0, { style: "percent", minimumFractionDigits: 2 })} do CDI)</Typography>
                    </Grid>
                  </Grid>
                }

                <Grid item xs={12}>
                  <Grid container direction="column">
                    <Typography variant="subtitle1" color="inherit" component="p" className={classes.labelText}>Pagamento de Juros</Typography>
                    <Typography variant="h6" color="inherit" component="p">{projeto.tipoPagamentoJuros && TipoPagamentoJuros[projeto.tipoPagamentoJuros]}</Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container direction="column">
                    <Typography variant="subtitle1" color="inherit" component="p" className={classes.labelText}>Pagamento da Amortização</Typography>
                    <Typography variant="h6" color="inherit" component="p">{projeto.tipoPagamentoAmortizacao && TipoPagamentoAmortizacao[projeto.tipoPagamentoAmortizacao]}</Typography>

                  </Grid>
                </Grid>

                {moment(projeto.dtFimCaptacao).isSameOrAfter(moment()) &&
                  <Grid item xs={12}>
                    <Grid container justify="space-around">
                      <Grid item>
                        {(!login || !LoginUtils.isValid(login))
                          ?
                            <Button variant="contained" color="primary" component={RouterLink} to={{ pathname: `/login`, state: { from: location.pathname }}}>Efetue login para efetuar reserva</Button>
                          :
                          <Button variant="contained" color="primary" component={RouterLink} to={`/investir/${projeto.noPermalink}/reserva`}>
                            Efetuar Reserva
                          </Button>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                }

              </Grid>

            </Grid>
          </Grid>

          <PageSubtitle>Informações sobre o Projeto</PageSubtitle>

          <Typography variant="subtitle1" className={classes.informacoesProjeto}>
            {projeto.deProjeto?.split('\n').map((item, i) => <p key={i}>{item}</p>)}
          </Typography>


          {moment(projeto.dtInicioTitulo).isAfter(moment()) &&
            <Grid container className={classes.simulador}>
              <Grid item xs={12}>
                <Simulador
                  nomeInvestimento={(projeto.empresa?.noFantasia) || "Projeto"}
                  rentabilidadeInvestimentoFixa={projeto.pcRentabilidadeProjetadaFixaAnual || undefined}
                  rentabilidadeInvestimentoMin={projeto.pcRentabilidadeProjetadaMinimaAnual || undefined}
                  rentabilidadeInvestimentoMax={projeto.pcRentabilidadeProjetadaMaximaAnual || undefined}
                  prazoMeses={projeto.qtPrazoMesesTitulo || 12}
                  rentabilidadeCDI={projeto.taxaJuros?.pcTaxaCdi || 0}
                  rentabilidadePoupanca={projeto.taxaJuros?.pcTaxaPoupanca || 0}
                  rentabilidadeGarantida={projeto.pcRentabilidadeGarantidaFixaAnual || undefined}
                  dtInicioTitulo={new Date(projeto.dtInicioTitulo)}
                />
              </Grid>
            </Grid>
          }

          {moment(projeto.dtInicioTitulo).isSameOrBefore(moment()) &&
            <Grid container className={classes.simulador}>
              <Grid item xs={12}>
                <HistoricoPerformance
                  valorCotaEmitida={projeto.vrCotaEmitida}
                  valoresCota={projeto.valoresCota}
                  nomeInvestimento={projeto.empresa.noFantasia}
                  rentabilidadeInvestimentoFixa={projeto.pcRentabilidadeProjetadaFixaAnual || undefined}
                  rentabilidadeInvestimentoMin={projeto.pcRentabilidadeProjetadaMinimaAnual || undefined}
                  rentabilidadeInvestimentoMax={projeto.pcRentabilidadeProjetadaMaximaAnual || undefined}
                  prazoMeses={projeto.qtPrazoMesesTitulo}
                  rentabilidadeCDI={projeto.taxaJuros.pcTaxaCdi}
                  rentabilidadePoupanca={projeto.taxaJuros.pcTaxaPoupanca}
                  rentabilidadeGarantida={projeto.pcRentabilidadeGarantidaFixaAnual || undefined}
                  dtInicioTitulo={projeto.dtInicioTitulo}
                  dtVencimentoTitulo={projeto.dtVencimentoTitulo}
                />
              </Grid>
            </Grid>
          }

          <ForumPerguntasProjeto
            projeto={projeto}
            perguntas={projeto.perguntasForum}
          />

          <OnlyMobile>
            <Box mt={2} />
          </OnlyMobile>

          <OnlyDesktop>
            <Box display="flex" justifyContent="flex-end" mt={2} mb={2}>
              <Button variant="contained" color="secondary" component={RouterLink} to={from}>Voltar</Button>
            </Box>
          </OnlyDesktop>


        </Container>

      </PageTemplate>
    )
  }
}

export const DetalhesProjetoLoadDataSSR = (matcher: match<{permalink: string}>) => fetchDetalhesCaptacao({permalink:matcher.params.permalink});
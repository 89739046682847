import { APIError } from "../services/api/APIError";
import moment, {Duration} from "moment";

export type RequestState<T> = {
  id: string;
  pending: boolean;
  error?: APIError;
  timestamp?: string;
  data?: T;
}


export const shouldFetch = (request: RequestState<any>, maxAge: Duration = moment.duration(0)) => {
  if (!request) return true; // No request
  if (request.pending) return false; // Other request loading
  if (request.timestamp && moment(request.timestamp).add(maxAge).isAfter(moment())) {
    return false; // not expired
  } else {
    return true; // expired
  }
};






import React, { useState } from 'react'
import { Paper, IconButton, Box, Typography, makeStyles, createStyles, Collapse } from '@material-ui/core'
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AlertProps, AlertTitle, Alert } from '@material-ui/lab';
import LoginService from '../../../../../../services/LoginService';
import { ContaUsuarioVO } from '../../../../../../models/ContaUsuario';
import { useSnackbar } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import FormInput from '../../../../../components/FormInput';
import { CodigoMaskedInput } from '../../../../../components/MaskedInputs';
import ButtonProgress from '../../../../../components/ButtonProgress';
import { registraToken } from '../../../../../../stores/slices/userSlice';

const useStyles = makeStyles(() => createStyles({
    inputCodigo: {
        fontSize: 24, width: '7em', fontFamily: 'monospace'
    },
}), { name: 'InformaCodigoEmailStep' });

interface InformaCodigoEmailStepFormData {
    codigo: string;
}

interface InformaCodigoEmailStepProps {
    contaUsuario: ContaUsuarioVO;
    onComplete: () => void;
}

export default function InformaCodigoEmailStep(props: InformaCodigoEmailStepProps) {

    const classes = useStyles();

    const { register, handleSubmit, errors, formState: { isSubmitting } } = useForm<InformaCodigoEmailStepFormData>();
    const dispatch = useDispatch();
    
    const [isSubmittingReenvioCodigo, setIsSubmittingReenvioCodigo] = useState(false);

    const [alert, setAlert] = useState<AlertProps | undefined>(undefined);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const validaCodigoEmail = async (formData: InformaCodigoEmailStepFormData) => {
        try {
            const contaUsuario = await new LoginService().validaCodigoEmail(props.contaUsuario.coContaUsuario||0, formData.codigo);
            if (contaUsuario.token) {
                dispatch(registraToken({token: contaUsuario.token}));
            }
            props.onComplete();
        } catch (apiexception) {
            setAlert({
                severity: 'error',
                children: <>
                    <AlertTitle>{apiexception.message}</AlertTitle>
                    {apiexception.errorJson.traceid && `Traceid: ${apiexception.errorJson.traceid}`}
                </>
            })
        }

    }

    const reenviaCodigoEmail = async () => {
        try {
            setIsSubmittingReenvioCodigo(true);
            await new LoginService().reenviaCodigoEmail(props.contaUsuario.coContaUsuario || 0);
            const dismissButton = (key: string | number | undefined) => <IconButton id="btnCloseSnackbar" onClick={() => closeSnackbar(key)}><CloseIcon color="action" /></IconButton>
            enqueueSnackbar('Código reenviado com sucesso para seu email!', { variant: 'success', action: dismissButton });
        } catch (apiexception) {
            setAlert({
                severity: 'error',
                children: <>
                    <AlertTitle>apiexception.message</AlertTitle>
                    {apiexception.errorJson.traceid && `Traceid: ${apiexception.errorJson.traceid}`}
                </>
            })
        } finally {
            setIsSubmittingReenvioCodigo(false);
        }

    }


    return (
        <Paper variant="outlined" style={{ padding: 20 }}>

            <form noValidate onSubmit={handleSubmit(validaCodigoEmail)} >

                <Box mt={1}>
                    <Typography variant="body1" paragraph ><strong>Olá {props.contaUsuario.noUsuario},</strong></Typography>
                    <Typography variant="body1" paragraph align="justify">Um código foi encaminhado para o endereço <strong>{props.contaUsuario.noEmail}</strong>. Digite o código recebido abaixo para validar o seu email.</Typography>
                </Box>

                <Box display="flex" justifyContent="center" mt={3}>
                    <FormInput
                        required
                        fullwidth={false}
                        name="codigo"
                        variant="outlined"
                        label="Código de Validação"
                        inputComponent={CodigoMaskedInput as any}
                        shrinkLabel={true}
                        helperText=' '
                        inputClassName={classes.inputCodigo}
                        error={errors.codigo?.message?.toString()}
                        inputRef={register({
                            required: 'Preencha o código',
                            pattern: { value: /[0-9A-Z]{6}/, message: 'Formato inválido' },
                            minLength: { value: 6, message: 'Informe 6 posições' },
                            maxLength: { value: 6, message: 'Informe 6 posições' }
                        })}
                    />
                </Box>

                <Box display="flex" justifyContent="center" mt={2}>
                    <ButtonProgress id="btnValidarEmail" loading={isSubmitting} variant="contained" size="large" color="secondary" type="submit">
                        Continuar
                    </ButtonProgress>
                </Box>
                <Box mt={2}>
                    <Collapse in={alert && !alert.hidden} onExited={() => setAlert(undefined)}>
                        {alert && <Alert id="msgErro" onClose={() => setAlert({ ...alert, hidden: true })} {...alert} />}
                    </Collapse>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center" mt={2} flexWrap="wrap">
                    <Typography variant="body2">Não recebeu ou código expirado?</Typography>
                    <ButtonProgress id="btnReenviarCodigo" loading={isSubmittingReenvioCodigo} variant="text" size="small" onClick={reenviaCodigoEmail}>Reenviar código</ButtonProgress>
                </Box>


            </form>
        </Paper>
    )
}

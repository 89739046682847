import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import { applyPatches, Patch, produceWithPatches } from 'immer';
import React from 'react';
import { useForm } from 'react-hook-form';
import { DeepPartial } from 'redux';
import { ImagemVO } from '../../../../../../models/Imagem';
import ButtonProgress from '../../../../../components/ButtonProgress';
import ConfirmLeavePage from '../../../../../components/ComfirmLeavePage';
import FormPhotoUpload from '../../../../../components/form/inputs/FileUpload/FormPhotoUpload';
import { FileUtils } from '../../../../../../utils/files';
import { ImageUtils } from '../../../../../../utils/images';
import { APIUtils } from '../../../../../../services/api/APIUtils';

export enum ProjetoImagemCapaFormMode {
  CREATE,
  EDIT
}

interface ProjetoImagemCapaFormData {
  nuImagem: string;
  noImagem: string;
  imImagem: string;
}

const generateFormDefaultValues = (imagem: DeepPartial<ImagemVO>): ProjetoImagemCapaFormData => {
  return {
    nuImagem: imagem.nuImagem ? imagem.nuImagem.toString() : '',
    imImagem: imagem.imImagem || '',
    noImagem: imagem.noImagem || ''
  }
}


interface ProjetoImagemCapaFormProps {
  imagem: DeepPartial<ImagemVO>;
  onSubmit: (nextState: DeepPartial<ImagemVO>, patches: Patch[], changes: DeepPartial<ImagemVO>) => Promise<void> | void;
  onBack?: () => void;
  submitText: string;
  formMode: ProjetoImagemCapaFormMode;
}

export const ProjetoImagemCapaForm = ({ imagem, onSubmit, onBack, submitText, formMode }: ProjetoImagemCapaFormProps) => {

  const { register, handleSubmit, errors, setValue, formState: { isSubmitting, dirty } } = useForm<ProjetoImagemCapaFormData>({
    mode: "onBlur",
    defaultValues: generateFormDefaultValues(imagem)
  });

  const handleFormSubmit = async (formData: ProjetoImagemCapaFormData) => {
    const [nextState, patches] = produceWithPatches(imagem, draft => {
      draft.nuImagem = formData.nuImagem ? Number(formData.nuImagem) : undefined;
      draft.noImagem = formData.noImagem;
      draft.imImagem = formData.imImagem;
    })
    const changes = applyPatches({}, patches) as DeepPartial<ImagemVO>;
    await onSubmit(nextState, patches, changes)
  }

  const handleChangeImagem = async (file: File | null) => {
    if (file) {
      setValue("noImagem", file.name, true);
      const imageResized = FileUtils.dataURLtoBase64(await ImageUtils.resizeImageKeepProportions(await FileUtils.fileToDataURL(file), 800, 800));
      setValue("imImagem", imageResized);
    } else {
      setValue("noImagem", '');
      setValue("imImagem", '');
    }
  }


  return (
    <form id="formImagemCapa" noValidate autoComplete="off" onSubmit={handleSubmit(handleFormSubmit)}>
      <ConfirmLeavePage
        when={dirty}
        title="Tem certeza que deseja deixar a página sem salvar as alterações?"
        content="Ao confirmar os dados alterados não serão salvos. Se cancelar, poderá continuar a edição."
      />
      <Paper variant="outlined" square >
        <Box p={2}>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <Typography variant="h6">Imagem de Capa</Typography>
            </Grid>

            <Grid item xs={12} >
              <input
                hidden
                name="noImagem"
                ref={register({
                  required: "Faça upload de uma imagem de capa para o projeto",
                  validate: {
                    extension: value => value.match(/\.(png)|(jpg)$/) ? true : 'Arquivo não aceito! Selecione uma imagem em formato .png ou .jpg',
                  }
                })} />
              <input
                hidden
                name="imImagem"
                ref={register()} />
              <FormPhotoUpload
                accept=".png, .jpg"
                variant="picture"
                helperText="Utilize imagens .png ou .jpg com pelo menos 800px"
                error={errors.noImagem?.message?.toString()}
                onChange={handleChangeImagem}
                previewURL={APIUtils.generateImageURL(imagem as ImagemVO)}
              />

            </Grid>

            <Grid item container justify="flex-end" spacing={2}>
              {onBack &&
                <Grid item>
                  <Button
                    id="btnVoltar"
                    variant="text"
                    color="default"
                    onClick={onBack}
                  >
                    Voltar
              </Button>
                </Grid>
              }
              <Grid item>
                <ButtonProgress
                  id="btnSubmit"
                  loading={isSubmitting}
                  disabled={Object.values(errors).length > 0 || (formMode == ProjetoImagemCapaFormMode.EDIT && !dirty)}
                  variant="contained"
                  color="primary"
                  type="submit">
                  {submitText}
                </ButtonProgress>
              </Grid>
            </Grid>


          </Grid>


        </Box>
      </Paper>
    </form>
  )
}

import React, { useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/Home';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PersonIcon from '@material-ui/icons/Person';
import MenuIcon from '@material-ui/icons/Menu';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import { Link, useLocation } from 'react-router-dom';
import OnlyMobile from './OnlyMobile';

const useStyles = makeStyles((theme: Theme) => createStyles({
  menu: {
    backgroundColor: theme.palette.primary.main,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: theme.zIndex.appBar,
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    minWidth: 0,
    color: theme.palette.primary.dark,
    '&$selected': {
      color: theme.palette.primary.contrastText,
    },
  },
  selected: {},
  toolbarMargin: {
    minHeight: theme.mixins.toolbar.minHeight
  }

}), { name: 'PageMenuMobile' });

export default function PageMenuMobile() {
  const classes = useStyles();
  const location = useLocation();
  const pathMap = [
    '/',
    '/investir',
    '/captar',
    '/conta',
    '/menu'
  ]
  const [state, setState] = React.useState(pathMap.indexOf(location.pathname));

  useEffect(() => {
    const value = pathMap.indexOf(location.pathname);
    setState(value);
  }, [pathMap, location])

  const handleChange = (event: React.ChangeEvent<{}>, value: number) => {
    setState(value);
  };

  return (
    <OnlyMobile>
      <BottomNavigation
        value={state}
        onChange={handleChange}
        showLabels
        classes={{root: classes.menu}}
      >
        <BottomNavigationAction label="Início" component={Link} to={pathMap[0]} icon={<HomeIcon />} classes={{ root: classes.button, selected: classes.selected }} />
        <BottomNavigationAction label="Investir" component={Link} to={pathMap[1]} icon={<MonetizationOnIcon />} classes={{ root: classes.button, selected: classes.selected }} />
        <BottomNavigationAction label="Captar" component={Link} to={pathMap[2]} icon={<BusinessCenterIcon />} classes={{ root: classes.button, selected: classes.selected }} />
        <BottomNavigationAction label="Conta" component={Link} to={pathMap[3]} icon={<PersonIcon />} classes={{ root: classes.button, selected: classes.selected }} />
        <BottomNavigationAction label="Mais" component={Link} to={pathMap[4]} icon={<MenuIcon />} classes={{ root: classes.button, selected: classes.selected }} />
      </BottomNavigation>
      <div className={classes.toolbarMargin} />
    </OnlyMobile>
  );
}

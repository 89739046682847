import * as React from "react";
import { PageDialog, PageDialogOptions } from "./PageDialog";
import { ReactNode } from "react";

const DialogServiceContext = React.createContext<
  (options: PageDialogOptions) => Promise<boolean>
>(Promise.reject);

export const useDialog = () =>
  React.useContext(DialogServiceContext);

  interface DialogServiceProviderProps{
      children: ReactNode;
  }


export function DialogServiceProvider({ children }: DialogServiceProviderProps) {
  const [
    confirmationState,
    setConfirmationState
  ] = React.useState<PageDialogOptions | null>(null);

  const awaitingPromiseRef = React.useRef<{
    resolve: (ok: boolean) => void;
    reject: () => void;
  }>();

  const openDialog = (options: PageDialogOptions) => {
    setConfirmationState(options);
    return new Promise<boolean>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (awaitingPromiseRef.current) {
      if (confirmationState && confirmationState.catchOnCancel) {
        awaitingPromiseRef.current.reject(); // rejeita, se catchOnCancel
      } else {
        awaitingPromiseRef.current.resolve(false); // resolve false, se não catchOnCancel
      }
    }
    setConfirmationState(null);
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(true);
    }
    setConfirmationState(null);
  };

  return (
    <>
      <DialogServiceContext.Provider
        value={openDialog}
        // eslint-disable-next-line react/no-children-prop
        children={children}
      />

      <PageDialog
        open={Boolean(confirmationState)}
        onSubmit={handleSubmit}
        onClose={handleClose}
        {...confirmationState}
      />
    </>
  );
}

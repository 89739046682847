import {createAsyncThunk, createEntityAdapter, createSlice, DeepPartial, EntityState} from "@reduxjs/toolkit";
import moment from "moment";
import {fromEvent, throwError, of} from "rxjs";
import {catchError, takeUntil, timeout, map} from "rxjs/operators";
import {EmpresaVO} from "../../../models/Empresa";
import {api} from "../../../services/api/api";
import {APIError} from "../../../services/api/APIError";
import {APIUtils} from "../../../services/api/APIUtils";
import {RootState} from "../../configureStore";
import {RequestState, shouldFetch} from "../../RequestsState";
import {selectToken} from "../userSlice";
import {InvestidorVO} from "../../../models/Investidor";
import {InvestimentoVO} from "../../../models/Investimento";
import {DocumentoVO} from "../../../models/Documento";
import {MotivoCancelamentoInvestimento} from "../../../models/enums/MotivoCancelamentoInvestimento";
import {AxiosError} from "axios";
import {EnderecoVO} from "../../../models/Endereco";
import {DadoBancarioVO} from "../../../models/DadoBancario";

interface InvestidoresSliceState {
  requests: EntityState<RequestState<any>>;
}

const requestsAdapter = createEntityAdapter<RequestState<any>>({
  selectId: request => request.id
});

const initialState: InvestidoresSliceState = {
  requests: requestsAdapter.getInitialState(),
}

// REQUESTS
const REQ_SEARCH_PAINEL_INVESTIMENTOS = "searchPainelInvestimentos";
const REQ_SUM_TOTAL_INVESTIMENTOS_ANO = "sumTotalInvestimentosAno";
const REQ_FETCH_BY_CONTA_USUARIO = "findByContaUsuario"
const REQ_CREATE_INVESTIDOR = "createInvestidor";
const REQ_CREATE_RESERVA = "createReserva";
const REQ_PATCH_INVESTIDOR_DADOS_BASICOS = "patchInvestidorDadosBasicos";
const REQ_PATCH_INVESTIDOR_ENDERECO = "patchInvestidorEndereco";
const REQ_PATCH_INVESTIDOR_DADOS_BANCARIOS = "patchInvestidorDadosBancarios";
const REQ_CHANGE_INVESTIDOR_COMPROVANTE_RESIDENCIA = "changeInvestidorComprovanteResidencia";
const REQ_CHANGE_INVESTIDOR_SELFIE_AUTENTICA = "changeInvestidorSelfieAutentica";
const REQ_CHANGE_INVESTIMENTO_COMPROVANTE_DEPOSITO = "changeInvestimentoComprovanteDeposito";
const REQ_CANCELAR_RESERVA = "cancelarReserva";
const REQ_CHANGE_INVESTIDOR_INVESTIMENTO_CONTRATO = "changeInvestidorInvestimentoContrato";

const requestSelectors = requestsAdapter.getSelectors((state: RootState) => state.entities.investidores.requests);

const trackSearchPainelInvestimentos = (coContaUsuario: number) => (state: RootState): RequestState<InvestimentoVO[]> => {
  return requestSelectors.selectById(state, `${REQ_SEARCH_PAINEL_INVESTIMENTOS}:${coContaUsuario}`) ?? {
    id: `${REQ_SEARCH_PAINEL_INVESTIMENTOS}:${coContaUsuario}`,
    pending: false
  }
}

const trackSumTotalInvestimentosAno = (coContaUsuario: number) => (state: RootState): RequestState<number> => {
  return requestSelectors.selectById(state, `${REQ_SUM_TOTAL_INVESTIMENTOS_ANO}:${coContaUsuario}`) ?? {
    id: `${REQ_SUM_TOTAL_INVESTIMENTOS_ANO}:${coContaUsuario}`,
    pending: false
  }
}

const trackFindInvestidorByContaUsuario = (coContaUsuario: number) => (state: RootState): RequestState<InvestidorVO> => {
  return requestSelectors.selectById(state, `${REQ_FETCH_BY_CONTA_USUARIO}:${coContaUsuario}`) ?? {
    id: `${REQ_FETCH_BY_CONTA_USUARIO}:${coContaUsuario}`,
    pending: false
  }
}

const trackCreateInvestidor = (state: RootState): RequestState<InvestidorVO> => {
  return requestSelectors.selectById(state, REQ_CREATE_INVESTIDOR) ?? {id: REQ_CREATE_INVESTIDOR, pending: false}
}

const trackCreateReserva = (coContaUsuario: number) => (state: RootState): RequestState<InvestimentoVO> => {
  return requestSelectors.selectById(state, `${REQ_CREATE_RESERVA}:${coContaUsuario}`) ?? {
    id: `${REQ_CREATE_RESERVA}:${coContaUsuario}`,
    pending: false
  }
}

const trackPatchInvestidorDadosBasicos = (coContaUsuario: number) => (state: RootState): RequestState<void> => {
  return requestSelectors.selectById(state, `${REQ_PATCH_INVESTIDOR_DADOS_BASICOS}:${coContaUsuario}`) ?? {
    id: `${REQ_PATCH_INVESTIDOR_DADOS_BASICOS}:${coContaUsuario}`,
    pending: false
  }
}

const trackPatchInvestidorDadosBancarios = (coContaUsuario: number) => (state: RootState): RequestState<void> => {
  return requestSelectors.selectById(state, `${REQ_PATCH_INVESTIDOR_DADOS_BANCARIOS}:${coContaUsuario}`) ?? {
    id: `${REQ_PATCH_INVESTIDOR_DADOS_BANCARIOS}:${coContaUsuario}`,
    pending: false
  }
}

const trackPatchInvestidorEndereco = (coContaUsuario: number) => (state: RootState): RequestState<void> => {
  return requestSelectors.selectById(state, `${REQ_PATCH_INVESTIDOR_ENDERECO}:${coContaUsuario}`) ?? {
    id: `${REQ_PATCH_INVESTIDOR_ENDERECO}:${coContaUsuario}`,
    pending: false
  }
}

const trackChangeInvestidorComprovanteResidencia = (coInvestimento: number) => (state: RootState): RequestState<DocumentoVO> => {
  return requestSelectors.selectById(state, `${REQ_CHANGE_INVESTIDOR_COMPROVANTE_RESIDENCIA}:${coInvestimento}`) ?? {
    id: `${REQ_CHANGE_INVESTIDOR_COMPROVANTE_RESIDENCIA}:${coInvestimento}`,
    pending: false
  }
}

const trackChangeInvestidorSelfieAutentica = (coInvestimento: number) => (state: RootState): RequestState<DocumentoVO> => {
  return requestSelectors.selectById(state, `${REQ_CHANGE_INVESTIDOR_SELFIE_AUTENTICA}:${coInvestimento}`) ?? {
    id: `${REQ_CHANGE_INVESTIDOR_SELFIE_AUTENTICA}:${coInvestimento}`,
    pending: false
  }
}


const trackChangeInvestimentoComprovanteDeposito = (coInvestimento: number) => (state: RootState): RequestState<EmpresaVO> => {
  return requestSelectors.selectById(state, `${REQ_CHANGE_INVESTIMENTO_COMPROVANTE_DEPOSITO}:${coInvestimento}`) ?? {
    id: `${REQ_CHANGE_INVESTIMENTO_COMPROVANTE_DEPOSITO}:${coInvestimento}`,
    pending: false
  }
}

const trackChangeInvestidorInvestimentoContrato = (coInvestimento: number) => (state: RootState): RequestState<void> => {
  return requestSelectors.selectById(state, `${REQ_CHANGE_INVESTIDOR_INVESTIMENTO_CONTRATO}:${coInvestimento}`) ?? {
    id: `${REQ_CHANGE_INVESTIDOR_INVESTIMENTO_CONTRATO}:${coInvestimento}`,
    pending: false
  }
}

const trackCancelarReserva = (coInvestimento: number) => (state: RootState): RequestState<EmpresaVO> => {
  return requestSelectors.selectById(state, `${REQ_CANCELAR_RESERVA}:${coInvestimento}`) ?? {
    id: `${REQ_CANCELAR_RESERVA}:${coInvestimento}`,
    pending: false
  }
}


// THUNKS
const searchPainelInvestimentos = createAsyncThunk<InvestimentoVO[], { coContaUsuario: number }, { state: RootState; rejectValue: APIError }>
('investidoresRequests/searchPainelInvestimentos',
  async ({coContaUsuario}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.investidores.searchPainelInvestimentos(coContaUsuario, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({coContaUsuario}, {getState}) => shouldFetch(trackSearchPainelInvestimentos(coContaUsuario)(getState()))}
)

const sumTotalInvestimentosAno = createAsyncThunk<number, { coContaUsuario: number }, { state: RootState; rejectValue: APIError }>
('investidoresRequests/sumTotalInvestimentosAno',
  async ({coContaUsuario}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.investidores.sumTotalInvestimentosAno(coContaUsuario, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({coContaUsuario}, {getState}) => shouldFetch(trackSumTotalInvestimentosAno(coContaUsuario)(getState()))}
)

const findInvestidorByContaUsuario = createAsyncThunk<InvestidorVO, { coContaUsuario: number }, { state: RootState; rejectValue: APIError }>
('investidoresRequests/findInvestidorByContaUsuario',
  async ({coContaUsuario}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.investidores.findByContaUsuario(coContaUsuario, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError((err: AxiosError) => err.response?.status == 404 ? of({} as InvestidorVO) : throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({coContaUsuario}, {getState}) => shouldFetch(trackFindInvestidorByContaUsuario(coContaUsuario)(getState()), moment.duration(1, 'minute'))}
)

const createInvestidor = createAsyncThunk<InvestidorVO, { investidor: DeepPartial<InvestidorVO> }, { state: RootState; rejectValue: APIError }>
('investidoresRequests/createInvestidor',
  async ({investidor}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.investidores.createInvestidor(investidor, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: (args, {getState}) => shouldFetch(trackCreateInvestidor(getState()))}
)

const createReserva = createAsyncThunk<InvestimentoVO, { coContaUsuario: number; reserva: DeepPartial<InvestimentoVO> }, { state: RootState; rejectValue: APIError }>
('investidoresRequests/createReserva',
  async ({coContaUsuario, reserva}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.investidores.createReserva(coContaUsuario, reserva, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({coContaUsuario}, {getState}) => shouldFetch(trackCreateReserva(coContaUsuario)(getState()))}
)

const patchInvestidorDadosBasicos = createAsyncThunk<void, { coContaUsuario: number; changes: DeepPartial<InvestidorVO> }, { state: RootState; rejectValue: APIError }>
('investidores/patchInvestidorDadosBasicos',
  async ({coContaUsuario, changes}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.investidores.patchInvestidorDadosBasicos(coContaUsuario, changes, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({coContaUsuario}, {getState}) => shouldFetch(trackPatchInvestidorDadosBasicos(coContaUsuario)(getState()))}
)

const patchInvestidorDadosBancarios = createAsyncThunk<void, { coContaUsuario: number; changes: DeepPartial<DadoBancarioVO> }, { state: RootState; rejectValue: APIError }>
('investidores/patchInvestidorDadosBancarios',
  async ({coContaUsuario, changes}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.investidores.patchInvestidorDadosBancarios(coContaUsuario, changes, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({coContaUsuario}, {getState}) => shouldFetch(trackPatchInvestidorDadosBancarios(coContaUsuario)(getState()))}
)


const patchInvestidorEndereco = createAsyncThunk<void, { coContaUsuario: number; changes: DeepPartial<EnderecoVO> }, { state: RootState; rejectValue: APIError }>
('investidores/patchInvestidorEndereco',
  async ({coContaUsuario, changes}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.investidores.patchInvestidorEndereco(coContaUsuario, changes, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({coContaUsuario}, {getState}) => shouldFetch(trackPatchInvestidorEndereco(coContaUsuario)(getState()))}
)

const changeInvestidorComprovanteResidencia = createAsyncThunk<DocumentoVO, { coContaUsuario: number; comprovante: Partial<DocumentoVO> }, { state: RootState; rejectValue: APIError }>
('investidores/changeInvestidorComprovanteResidencia',
  async ({coContaUsuario, comprovante}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.investidores.changeDocumentoComprovanteResidencia(coContaUsuario, comprovante, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({coContaUsuario}, {getState}) => shouldFetch(trackChangeInvestidorComprovanteResidencia(coContaUsuario)(getState()))}
)

const changeInvestidorSelfieAutentica = createAsyncThunk<DocumentoVO, { coContaUsuario: number; selfie: Partial<DocumentoVO> }, { state: RootState; rejectValue: APIError }>
('investidores/changeInvestidorSelfieAutentica',
  async ({coContaUsuario, selfie}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.investidores.changeDocumentoSelfieAutentica(coContaUsuario, selfie, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({coContaUsuario}, {getState}) => shouldFetch(trackChangeInvestidorSelfieAutentica(coContaUsuario)(getState()))}
)


const changeInvestimentoComprovanteDeposito = createAsyncThunk<DocumentoVO, { coContaUsuario: number; coInvestimento: number; comprovante: Partial<DocumentoVO> }, { state: RootState; rejectValue: APIError }>
('investidores/changeInvestimentoComprovanteDeposito',
  async ({coContaUsuario, coInvestimento, comprovante}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.investidores.changeDocumentoComprovanteDeposito(coContaUsuario, coInvestimento, comprovante, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({coInvestimento}, {getState}) => shouldFetch(trackChangeInvestimentoComprovanteDeposito(coInvestimento)(getState()))}
)

const changeInvestidorInvestimentoContrato = createAsyncThunk<DocumentoVO, { coContaUsuario: number; coInvestimento: number; contrato: Partial<DocumentoVO> }, { state: RootState; rejectValue: APIError }>
('investidores/changeInvestidorInvestimentoContrato',
  async ({coContaUsuario, coInvestimento, contrato}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.investidores.changeContrato(coContaUsuario, coInvestimento, contrato, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({coInvestimento}, {getState}) => shouldFetch(trackChangeInvestidorInvestimentoContrato(coInvestimento)(getState()))}
)

const cancelarReserva = createAsyncThunk<InvestimentoVO, { coContaUsuario: number; coInvestimento: number; motivoCancelamento: MotivoCancelamentoInvestimento }, { state: RootState; rejectValue: APIError }>
('investidoresRequests/cancelarReserva',
  async ({coContaUsuario, coInvestimento, motivoCancelamento}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.investidores.cancelarReserva(coContaUsuario, coInvestimento, motivoCancelamento, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({coInvestimento}, {getState}) => shouldFetch(trackCancelarReserva(coInvestimento)(getState()))}
)


// SLICE
const investidoresSlice = createSlice({
  name: 'investidoresRequests',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    //
    builder.addCase(searchPainelInvestimentos.pending, (state, {meta: {arg: {coContaUsuario}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_SEARCH_PAINEL_INVESTIMENTOS}:${coContaUsuario}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(searchPainelInvestimentos.fulfilled, (state, {meta: {arg: {coContaUsuario}}, payload: investimentos}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_SEARCH_PAINEL_INVESTIMENTOS}:${coContaUsuario}`,
        changes: {pending: false, timestamp: moment().toISOString(), data: investimentos}
      });
    })
    builder.addCase(searchPainelInvestimentos.rejected, (state, action) => {
      const {coContaUsuario} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_SEARCH_PAINEL_INVESTIMENTOS}:${coContaUsuario}`,
        changes: {pending: false, error}
      });
    })

    //
    builder.addCase(sumTotalInvestimentosAno.pending, (state, {meta: {arg: {coContaUsuario}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_SUM_TOTAL_INVESTIMENTOS_ANO}:${coContaUsuario}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(sumTotalInvestimentosAno.fulfilled, (state, {meta: {arg: {coContaUsuario}}, payload: total}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_SUM_TOTAL_INVESTIMENTOS_ANO}:${coContaUsuario}`,
        changes: {pending: false, timestamp: moment().toISOString(), data: total}
      });
    })
    builder.addCase(sumTotalInvestimentosAno.rejected, (state, action) => {
      const {coContaUsuario} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_SUM_TOTAL_INVESTIMENTOS_ANO}:${coContaUsuario}`,
        changes: {pending: false, error}
      });
    })

    //
    builder.addCase(findInvestidorByContaUsuario.pending, (state, {meta: {arg: {coContaUsuario}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_FETCH_BY_CONTA_USUARIO}:${coContaUsuario}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(findInvestidorByContaUsuario.fulfilled, (state, {meta: {arg: {coContaUsuario}}, payload: investidor}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_FETCH_BY_CONTA_USUARIO}:${coContaUsuario}`,
        changes: {pending: false, timestamp: moment().toISOString(), data: investidor}
      });
    })
    builder.addCase(findInvestidorByContaUsuario.rejected, (state, action) => {
      const {coContaUsuario} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_FETCH_BY_CONTA_USUARIO}:${coContaUsuario}`,
        changes: {pending: false, error}
      });
    })

    //
    builder.addCase(createInvestidor.pending, (state) => {
      requestsAdapter.upsertOne(state.requests, {
        id: REQ_CREATE_INVESTIDOR,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(createInvestidor.fulfilled, (state, {payload: investidor}) => {
      requestsAdapter.updateOne(state.requests, {
        id: REQ_CREATE_INVESTIDOR,
        changes: {pending: false, timestamp: moment().toISOString(), data: investidor}
      });
    })
    builder.addCase(createInvestidor.rejected, (state, action) => {
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {id: REQ_CREATE_INVESTIDOR, changes: {pending: false, error}});
    })

    //
    builder.addCase(createReserva.pending, (state, {meta: {arg: {coContaUsuario}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_CREATE_INVESTIDOR}:${coContaUsuario}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(createReserva.fulfilled, (state, {meta: {arg: {coContaUsuario}}, payload: investidor}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_CREATE_INVESTIDOR}:${coContaUsuario}`,
        changes: {pending: false, timestamp: moment().toISOString(), data: investidor}
      });
    })
    builder.addCase(createReserva.rejected, (state, action) => {
      const {coContaUsuario} = action.meta.arg
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_CREATE_INVESTIDOR}:${coContaUsuario}`,
        changes: {pending: false, error}
      });
    })

    //
    builder.addCase(patchInvestidorDadosBasicos.pending, (state, {meta: {arg: {coContaUsuario}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_PATCH_INVESTIDOR_DADOS_BASICOS}:${coContaUsuario}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(patchInvestidorDadosBasicos.fulfilled, (state, {meta: {arg: {coContaUsuario}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_PATCH_INVESTIDOR_DADOS_BASICOS}:${coContaUsuario}`,
        changes: {pending: false, timestamp: moment().toISOString()}
      });
    })
    builder.addCase(patchInvestidorDadosBasicos.rejected, (state, action) => {
      const {coContaUsuario} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_PATCH_INVESTIDOR_DADOS_BASICOS}:${coContaUsuario}`,
        changes: {pending: false, error}
      });
    })

    //
    builder.addCase(patchInvestidorDadosBancarios.pending, (state, {meta: {arg: {coContaUsuario}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_PATCH_INVESTIDOR_DADOS_BANCARIOS}:${coContaUsuario}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(patchInvestidorDadosBancarios.fulfilled, (state, {meta: {arg: {coContaUsuario}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_PATCH_INVESTIDOR_DADOS_BANCARIOS}:${coContaUsuario}`,
        changes: {pending: false, timestamp: moment().toISOString()}
      });
    })
    builder.addCase(patchInvestidorDadosBancarios.rejected, (state, action) => {
      const {coContaUsuario} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_PATCH_INVESTIDOR_DADOS_BANCARIOS}:${coContaUsuario}`,
        changes: {pending: false, error}
      });
    })

    //
    builder.addCase(patchInvestidorEndereco.pending, (state, {meta: {arg: {coContaUsuario}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_PATCH_INVESTIDOR_ENDERECO}:${coContaUsuario}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(patchInvestidorEndereco.fulfilled, (state, {meta: {arg: {coContaUsuario}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_PATCH_INVESTIDOR_ENDERECO}:${coContaUsuario}`,
        changes: {pending: false, timestamp: moment().toISOString()}
      });
    })
    builder.addCase(patchInvestidorEndereco.rejected, (state, action) => {
      const {coContaUsuario} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_PATCH_INVESTIDOR_ENDERECO}:${coContaUsuario}`,
        changes: {pending: false, error}
      });
    })

    //
    builder.addCase(changeInvestidorComprovanteResidencia.pending, (state, {meta: {arg: {coContaUsuario}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_CHANGE_INVESTIDOR_COMPROVANTE_RESIDENCIA}:${coContaUsuario}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(changeInvestidorComprovanteResidencia.fulfilled, (state, {meta: {arg: {coContaUsuario}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_CHANGE_INVESTIDOR_COMPROVANTE_RESIDENCIA}:${coContaUsuario}`,
        changes: {pending: false, timestamp: moment().toISOString()}
      });
    })
    builder.addCase(changeInvestidorComprovanteResidencia.rejected, (state, action) => {
      const {coContaUsuario} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_CHANGE_INVESTIDOR_COMPROVANTE_RESIDENCIA}:${coContaUsuario}`,
        changes: {pending: false, error}
      });
    })

    //
    builder.addCase(changeInvestidorSelfieAutentica.pending, (state, {meta: {arg: {coContaUsuario}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_CHANGE_INVESTIDOR_SELFIE_AUTENTICA}:${coContaUsuario}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(changeInvestidorSelfieAutentica.fulfilled, (state, {meta: {arg: {coContaUsuario}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_CHANGE_INVESTIDOR_SELFIE_AUTENTICA}:${coContaUsuario}`,
        changes: {pending: false, timestamp: moment().toISOString()}
      });
    })
    builder.addCase(changeInvestidorSelfieAutentica.rejected, (state, action) => {
      const {coContaUsuario} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_CHANGE_INVESTIDOR_SELFIE_AUTENTICA}:${coContaUsuario}`,
        changes: {pending: false, error}
      });
    })

    //
    builder.addCase(changeInvestimentoComprovanteDeposito.pending, (state, {meta: {arg: {coInvestimento}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_CHANGE_INVESTIMENTO_COMPROVANTE_DEPOSITO}:${coInvestimento}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(changeInvestimentoComprovanteDeposito.fulfilled, (state, {meta: {arg: {coInvestimento}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_CHANGE_INVESTIMENTO_COMPROVANTE_DEPOSITO}:${coInvestimento}`,
        changes: {pending: false, timestamp: moment().toISOString()}
      });
    })
    builder.addCase(changeInvestimentoComprovanteDeposito.rejected, (state, action) => {
      const {coInvestimento} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_CHANGE_INVESTIMENTO_COMPROVANTE_DEPOSITO}:${coInvestimento}`,
        changes: {pending: false, error}
      });
    })


    //
    builder.addCase(changeInvestidorInvestimentoContrato.pending, (state, {meta: {arg: {coInvestimento}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_CHANGE_INVESTIDOR_INVESTIMENTO_CONTRATO}:${coInvestimento}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(changeInvestidorInvestimentoContrato.fulfilled, (state, {meta: {arg: {coInvestimento}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_CHANGE_INVESTIDOR_INVESTIMENTO_CONTRATO}:${coInvestimento}`,
        changes: {pending: false, timestamp: moment().toISOString()}
      });
    })
    builder.addCase(changeInvestidorInvestimentoContrato.rejected, (state, action) => {
      const {coInvestimento} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_CHANGE_INVESTIDOR_INVESTIMENTO_CONTRATO}:${coInvestimento}`,
        changes: {pending: false, error}
      });
    })


    //
    builder.addCase(cancelarReserva.pending, (state, {meta: {arg: {coInvestimento}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_CANCELAR_RESERVA}:${coInvestimento}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(cancelarReserva.fulfilled, (state, {meta: {arg: {coInvestimento}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_CANCELAR_RESERVA}:${coInvestimento}`,
        changes: {pending: false, timestamp: moment().toISOString()}
      });
    })
    builder.addCase(cancelarReserva.rejected, (state, action) => {
      const {coInvestimento} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_CANCELAR_RESERVA}:${coInvestimento}`,
        changes: {pending: false, error}
      });
    })

  }
})

export {
  searchPainelInvestimentos,
  createInvestidor,
  createReserva,
  changeInvestimentoComprovanteDeposito,
  changeInvestidorComprovanteResidencia,
  changeInvestidorInvestimentoContrato,
  changeInvestidorSelfieAutentica,
  patchInvestidorDadosBasicos,
  patchInvestidorDadosBancarios,
  patchInvestidorEndereco,
  cancelarReserva,
  findInvestidorByContaUsuario,
  sumTotalInvestimentosAno,
  trackSearchPainelInvestimentos,
  trackSumTotalInvestimentosAno,
  trackFindInvestidorByContaUsuario
};

export default investidoresSlice.reducer
import { Box, Button, Grid, Paper, Typography, Link, makeStyles, createStyles } from '@material-ui/core';
import { Patch, produceWithPatches, applyPatches } from 'immer';
import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { DeepPartial } from 'redux';
import moment from 'moment';
import { IntlShape, useIntl } from 'react-intl';
import { ProjetoVO } from '../../../../../../../models/Projeto';
import { AppDispatch } from '../../../../../../../../browser';
import { useDispatch, useSelector } from 'react-redux';
import { trackFetchTaxasJuros, fetchTaxasJurosList } from '../../../../../../../stores/slices/entities/taxaJurosSlice';
import { TipoRentabilidadeProjetada } from '../../../../../../../models/enums/TipoRentabilidadeProjetada';
import { TipoRentabilidadeGarantida } from '../../../../../../../models/enums/TipoRentabilidadeGarantida';
import { reverseFormatNumber } from '../../../../../../../utils/intl';
import { Finance } from '../../../../../../../utils/finance';
import ConfirmLeavePage from '../../../../../../components/ComfirmLeavePage';
import FormDatePickerInput from '../../../../../../components/form/inputs/DataPicker/FormDatePickerInput';
import { Patterns } from '../../../../../../../utils/patterns';
import FormInput from '../../../../../../components/FormInput';
import { OnlyNumbersMaskedInput, ReaisMaskedInput, PorcentagemMaskedInput } from '../../../../../../components/MaskedInputs';
import { TipoPagamentoAmortizacao } from '../../../../../../../models/enums/TipoPagamentoAmortizacao';
import { TipoPagamentoJuros } from '../../../../../../../models/enums/TipoPagamentoJuros';
import { LoadingText } from '../../../../../../components/progress/LoadingText';
import ButtonProgress from '../../../../../../components/ButtonProgress';

const useStyles = makeStyles(() => createStyles({
    observacoes: {
        color: 'rgba(0,0,0,.5)',
    },
}), { name: 'ProjetoEditCaptacaoForm' });

interface ProjetoEditCaptacaoFormData {
    dtInicioCaptacao: string;
    dtFimCaptacao: string;
    dtInicioDepositos: string;
    dtFimDepositos: string;
    dtInicioTitulo: string;
    qtPrazoMesesTitulo: string;
    dtVencimentoTitulo: string;
    tipoPagamentoAmortizacao: string;
    tipoPagamentoJuros: string;
    vrAlvoCaptacao: string;
    qtCota: string;
    vrCotaEmitida: string;
    tipoRentabilidadeProjetada: string;
    pcRentabilidadeProjetadaFixa: string;
    pcRentabilidadeProjetadaFixaAnual: string;
    pcRentabilidadeProjetadaMinima: string;
    pcRentabilidadeProjetadaMinimaAnual: string;
    pcRentabilidadeProjetadaMaxima: string;
    pcRentabilidadeProjetadaMaximaAnual: string;
    coTaxaJuros: string;
    tipoRentabilidadeGarantida: string;
    pcRentabilidadeGarantidaCdi: string;
    pcRentabilidadeGarantidaFixaAnual: string;
}

const generateFormDefaultValues = (projeto: DeepPartial<ProjetoVO>, intl: IntlShape): ProjetoEditCaptacaoFormData => {

    return {
        dtInicioCaptacao: projeto.dtInicioCaptacao ? moment(projeto.dtInicioCaptacao).format("L") : '',
        dtFimCaptacao: projeto.dtFimCaptacao ? moment(projeto.dtFimCaptacao).format("L") : '',
        dtInicioDepositos: projeto.dtInicioDepositos ? moment(projeto.dtInicioDepositos).format("L") : '',
        dtFimDepositos: projeto.dtFimDepositos ? moment(projeto.dtFimDepositos).format("L") : '',
        dtInicioTitulo: projeto.dtInicioTitulo ? moment(projeto.dtInicioTitulo).format("L") : '',
        qtPrazoMesesTitulo: projeto.qtPrazoMesesTitulo?.toString() || '',
        dtVencimentoTitulo: projeto.dtVencimentoTitulo ? moment(projeto.dtVencimentoTitulo).format("L") : '',
        tipoPagamentoAmortizacao: projeto.tipoPagamentoAmortizacao?.toString() || '',
        tipoPagamentoJuros: projeto.tipoPagamentoJuros?.toString() || '',
        vrAlvoCaptacao: projeto.vrAlvoCaptacao ? intl.formatNumber(projeto.vrAlvoCaptacao, { style: "decimal", minimumFractionDigits: 2 }) : '',
        qtCota: projeto.qtCota?.toString() || '',
        vrCotaEmitida: projeto.vrCotaEmitida ? intl.formatNumber(projeto.vrCotaEmitida, { style: "decimal", minimumFractionDigits: 2 }) : '',
        tipoRentabilidadeProjetada: projeto.tipoRentabilidadeProjetada?.toString() || '',
        pcRentabilidadeProjetadaFixa: projeto.pcRentabilidadeProjetadaFixa ? intl.formatNumber(projeto.pcRentabilidadeProjetadaFixa, { style: "percent", minimumFractionDigits: 2 }) : '',
        pcRentabilidadeProjetadaFixaAnual: projeto.pcRentabilidadeProjetadaFixaAnual ? intl.formatNumber(projeto.pcRentabilidadeProjetadaFixaAnual, { style: "percent", minimumFractionDigits: 2 }) : '',
        pcRentabilidadeProjetadaMinima: projeto.pcRentabilidadeProjetadaMinima ? intl.formatNumber(projeto.pcRentabilidadeProjetadaMinima, { style: "percent", minimumFractionDigits: 2 }) : '',
        pcRentabilidadeProjetadaMinimaAnual: projeto.pcRentabilidadeProjetadaMinimaAnual ? intl.formatNumber(projeto.pcRentabilidadeProjetadaMinimaAnual, { style: "percent", minimumFractionDigits: 2 }) : '',
        pcRentabilidadeProjetadaMaxima: projeto.pcRentabilidadeProjetadaMaxima ? intl.formatNumber(projeto.pcRentabilidadeProjetadaMaxima, { style: "percent", minimumFractionDigits: 2 }) : '',
        pcRentabilidadeProjetadaMaximaAnual: projeto.pcRentabilidadeProjetadaMaximaAnual ? intl.formatNumber(projeto.pcRentabilidadeProjetadaMaximaAnual, { style: "percent", minimumFractionDigits: 2 }) : '',
        coTaxaJuros: projeto.taxaJuros?.coTaxaJuros?.toString() || '',
        tipoRentabilidadeGarantida: projeto.tipoRentabilidadeGarantida?.toString() || '',
        pcRentabilidadeGarantidaCdi: projeto.pcRentabilidadeGarantidaCdi ? intl.formatNumber(projeto.pcRentabilidadeGarantidaCdi, { style: "percent", minimumFractionDigits: 2 }) : '',
        pcRentabilidadeGarantidaFixaAnual: projeto.pcRentabilidadeGarantidaFixaAnual ? intl.formatNumber(projeto.pcRentabilidadeGarantidaFixaAnual, { style: "percent", minimumFractionDigits: 2 }) : '',
    }
}

interface ProjetoEditCaptacaoFormProps {
    projeto: DeepPartial<ProjetoVO>;
    onSubmit: (nextState: DeepPartial<ProjetoVO>, patches: Patch[], changes: DeepPartial<ProjetoVO>) => Promise<void> | void;
    onBack?: () => void;
    submitText: string;
}

export const ProjetoEditCaptacaoForm = ({ projeto, onSubmit, onBack, submitText }: ProjetoEditCaptacaoFormProps) => {

    const classes = useStyles();

    const dispatch = useDispatch<AppDispatch>()

    const taxasJurosRequestState = useSelector(trackFetchTaxasJuros);

    const intl = useIntl();

    const { register, handleSubmit, errors, watch, control, setValue, formState: { isSubmitting, dirty } } = useForm<ProjetoEditCaptacaoFormData>({
        mode: "onBlur",
        defaultValues: generateFormDefaultValues(projeto, intl)
    });

    useEffect(() => {
        const promiseTaxas = dispatch(fetchTaxasJurosList());
        return () => {
            promiseTaxas.abort();
        }
    }, [dispatch]);

    const isCaptacaoIniciada = moment(projeto.dtInicioCaptacao).isBefore(moment());

    const dtInicioCaptacao = watch({ nest: true }).dtInicioCaptacao;
    const dtFimCaptacao = watch({ nest: true }).dtFimCaptacao;
    const dtInicioDepositos = watch({ nest: true }).dtInicioDepositos;
    const dtFimDepositos = watch({ nest: true }).dtFimDepositos;
    const dtInicioTitulo = watch({ nest: true }).dtInicioTitulo;
    const qtPrazoMesesTitulo = watch({ nest: true }).qtPrazoMesesTitulo;
    const qtCota = watch({ nest: true }).qtCota;
    const vrCotaEmitida = watch({ nest: true }).vrCotaEmitida;
    const tipoRentabilidadeProjetada = watch({ nest: true }).tipoRentabilidadeProjetada;
    const pcRentabilidadeProjetadaFixaAnual = watch({ nest: true }).pcRentabilidadeProjetadaFixaAnual;
    const pcRentabilidadeProjetadaMinimaAnual = watch({ nest: true }).pcRentabilidadeProjetadaMinimaAnual;
    const pcRentabilidadeProjetadaMaximaAnual = watch({ nest: true }).pcRentabilidadeProjetadaMaximaAnual;
    const tipoRentabilidadeGarantida = watch({ nest: true }).tipoRentabilidadeGarantida;
    const pcRentabilidadeGarantidaCdi = watch({ nest: true }).pcRentabilidadeGarantidaCdi;
    const coTaxaJuros = watch({ nest: true }).coTaxaJuros;

    useEffect(() => {
        if (tipoRentabilidadeProjetada != TipoRentabilidadeProjetada.Fixa.toString()) {
            setValue("pcRentabilidadeProjetadaFixa", "");
            setValue("pcRentabilidadeProjetadaFixaAnual", "");
        }
        if (tipoRentabilidadeProjetada != TipoRentabilidadeProjetada.Variável.toString()) {
            setValue("pcRentabilidadeProjetadaMinima", "");
            setValue("pcRentabilidadeProjetadaMinimaAnual", "");
            setValue("pcRentabilidadeProjetadaMaxima", "");
            setValue("pcRentabilidadeProjetadaMaximaAnual", "");
        }
    }, [setValue, tipoRentabilidadeProjetada])

    useEffect(() => {
        if (tipoRentabilidadeGarantida != TipoRentabilidadeGarantida["% do CDI Pré-fixado"].toString()) {
            setValue("pcRentabilidadeGarantidaCdi", "");
            setValue("pcRentabilidadeGarantidaFixaAnual", "");
        }
    }, [setValue, tipoRentabilidadeGarantida])

    useEffect(() => {
        if (pcRentabilidadeGarantidaCdi && coTaxaJuros && taxasJurosRequestState.data) {
            const taxaCdi = taxasJurosRequestState.data.find(t => t.coTaxaJuros == Number(coTaxaJuros))?.pcTaxaCdi || 0;
            const pcCdi = reverseFormatNumber(intl, pcRentabilidadeGarantidaCdi, { style: "percent" });
            const taxaFixa = taxaCdi * pcCdi;
            setValue("pcRentabilidadeGarantidaFixaAnual", intl.formatNumber(taxaFixa, { style: "percent", minimumFractionDigits: 2 }));
        } else {
            setValue("pcRentabilidadeGarantidaFixaAnual", "");
        }
    }, [intl, pcRentabilidadeGarantidaCdi, coTaxaJuros, setValue, taxasJurosRequestState.data])

    useEffect(() => {
        if (pcRentabilidadeProjetadaFixaAnual && qtPrazoMesesTitulo) {
            const rate = reverseFormatNumber(intl, pcRentabilidadeProjetadaFixaAnual, { style: "percent" });
            const months = Number(qtPrazoMesesTitulo);
            const interest = Finance.FV(1, rate, months / 12) - 1;
            setValue("pcRentabilidadeProjetadaFixa", intl.formatNumber(interest, { style: "percent", minimumFractionDigits: 2 }));
        } else {
            setValue("pcRentabilidadeProjetadaFixa", "");
        }
    }, [intl, pcRentabilidadeProjetadaFixaAnual, qtPrazoMesesTitulo, setValue])

    useEffect(() => {
        if (pcRentabilidadeProjetadaMinimaAnual && qtPrazoMesesTitulo) {
            const rate = reverseFormatNumber(intl, pcRentabilidadeProjetadaMinimaAnual, { style: "percent" });
            const months = Number(qtPrazoMesesTitulo);
            const interest = Finance.FV(1, rate, months / 12) - 1;
            setValue("pcRentabilidadeProjetadaMinima", intl.formatNumber(interest, { style: "percent", minimumFractionDigits: 2 }));
        } else {
            setValue("pcRentabilidadeProjetadaMinima", "");
        }
    }, [intl, pcRentabilidadeProjetadaMinimaAnual, qtPrazoMesesTitulo, setValue])

    useEffect(() => {
        if (pcRentabilidadeProjetadaMaximaAnual && qtPrazoMesesTitulo) {
            const rate = reverseFormatNumber(intl, pcRentabilidadeProjetadaMaximaAnual, { style: "percent" });
            const months = Number(qtPrazoMesesTitulo);
            const interest = Finance.FV(1, rate, months / 12) - 1;
            setValue("pcRentabilidadeProjetadaMaxima", intl.formatNumber(interest, { style: "percent", minimumFractionDigits: 2 }));
        } else {
            setValue("pcRentabilidadeProjetadaMaxima", "");
        }
    }, [intl, pcRentabilidadeProjetadaMaximaAnual, qtPrazoMesesTitulo, setValue])


    useEffect(() => {
        if (dtInicioTitulo && qtPrazoMesesTitulo) {
            setValue("dtVencimentoTitulo", moment(dtInicioTitulo, "L").add(qtPrazoMesesTitulo, 'month').format("L"));
        } else {
            setValue("dtVencimentoTitulo", "");
        }
    }, [dtInicioTitulo, qtPrazoMesesTitulo, setValue])

    useEffect(() => {
        if (vrCotaEmitida && qtCota) {
            const newVrAlvoCaptacao = reverseFormatNumber(intl, vrCotaEmitida) * Number(qtCota);
            setValue("vrAlvoCaptacao", intl.formatNumber(newVrAlvoCaptacao, { style: "decimal", minimumFractionDigits: 2 }));
        } else {
            setValue("vrAlvoCaptacao", "");
        }
    }, [vrCotaEmitida, qtCota, setValue, intl])


    const handleFormSubmit = async (formData: ProjetoEditCaptacaoFormData) => {
        const [nextState, patches] = produceWithPatches(projeto, draft => {
            draft.dtInicioCaptacao = moment(formData.dtInicioCaptacao, "L").format("YYYY-MM-DD")
            draft.dtFimCaptacao = moment(formData.dtFimCaptacao, "L").format("YYYY-MM-DD")
            draft.dtInicioDepositos = moment(formData.dtInicioDepositos, "L").format("YYYY-MM-DD")
            draft.dtFimDepositos = moment(formData.dtFimDepositos, "L").format("YYYY-MM-DD")
            draft.dtInicioTitulo = moment(formData.dtInicioTitulo, "L").format("YYYY-MM-DD")
            draft.dtVencimentoTitulo = moment(formData.dtVencimentoTitulo, "L").format("YYYY-MM-DD")
            draft.qtPrazoMesesTitulo = Number(qtPrazoMesesTitulo);
            draft.tipoPagamentoAmortizacao = Number(formData.tipoPagamentoAmortizacao)
            draft.tipoPagamentoJuros = Number(formData.tipoPagamentoJuros)
            draft.qtCota = Number(formData.qtCota);
            draft.vrCotaEmitida = reverseFormatNumber(intl, formData.vrCotaEmitida, { style: "decimal", minimumFractionDigits: 2 })
            draft.vrAlvoCaptacao = reverseFormatNumber(intl, formData.vrAlvoCaptacao, { style: "decimal", minimumFractionDigits: 2 })
            draft.tipoRentabilidadeProjetada = Number(formData.tipoRentabilidadeProjetada);
            draft.pcRentabilidadeProjetadaFixa = formData.pcRentabilidadeProjetadaFixa ? reverseFormatNumber(intl, formData.pcRentabilidadeProjetadaFixa, { style: "percent", maximumFractionDigits: 4 }) : null;
            draft.pcRentabilidadeProjetadaFixaAnual = formData.pcRentabilidadeProjetadaFixaAnual ? reverseFormatNumber(intl, formData.pcRentabilidadeProjetadaFixaAnual, { style: "percent", maximumFractionDigits: 4 }) : null;
            draft.pcRentabilidadeProjetadaMinima = formData.pcRentabilidadeProjetadaMinima ? reverseFormatNumber(intl, formData.pcRentabilidadeProjetadaMinima, { style: "percent", maximumFractionDigits: 4 }) : null;
            draft.pcRentabilidadeProjetadaMinimaAnual = formData.pcRentabilidadeProjetadaMinimaAnual ? reverseFormatNumber(intl, formData.pcRentabilidadeProjetadaMinimaAnual, { style: "percent", maximumFractionDigits: 4 }) : null;
            draft.pcRentabilidadeProjetadaMaxima = formData.pcRentabilidadeProjetadaMaxima ? reverseFormatNumber(intl, formData.pcRentabilidadeProjetadaMaxima, { style: "percent", maximumFractionDigits: 4 }) : null;
            draft.pcRentabilidadeProjetadaMaximaAnual = formData.pcRentabilidadeProjetadaMaximaAnual ? reverseFormatNumber(intl, formData.pcRentabilidadeProjetadaMaximaAnual, { style: "percent", maximumFractionDigits: 4 }) : null;
            draft.tipoRentabilidadeGarantida = Number(formData.tipoRentabilidadeGarantida);
            draft.pcRentabilidadeGarantidaCdi = formData.pcRentabilidadeGarantidaCdi ? reverseFormatNumber(intl, formData.pcRentabilidadeGarantidaCdi, { style: "percent", maximumFractionDigits: 4 }) : null;
            draft.pcRentabilidadeGarantidaFixaAnual = formData.pcRentabilidadeGarantidaFixaAnual ? reverseFormatNumber(intl, formData.pcRentabilidadeGarantidaFixaAnual, { style: "percent", maximumFractionDigits: 4 }) : null;
            if (draft.taxaJuros?.coTaxaJuros != Number(formData.coTaxaJuros)) {
                draft.taxaJuros = { coTaxaJuros: Number(formData.coTaxaJuros) }
            }
        })
        console.log(nextState);
        const changes = applyPatches({}, patches) as Partial<ProjetoVO>;
        await onSubmit(nextState, patches, changes)
    }


    return (
        <form id="formCaptacaoProjeto" noValidate autoComplete="off" onSubmit={handleSubmit(handleFormSubmit)}>
            <ConfirmLeavePage
                when={dirty}
                title="Tem certeza que deseja deixar a página sem salvar as alterações?"
                content="Ao confirmar os dados alterados não serão salvos. Se cancelar, poderá continuar a edição."
            />
            <Paper variant="outlined" square >
                <Box p={2}>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <Typography variant="h6">Captação</Typography>
                            {isCaptacaoIniciada &&
                                <Typography variant="caption" className={classes.observacoes}>* Captação já iniciada, portanto alguns dados estão bloqueados para alteração.</Typography>
                            }
                        </Grid>

                        <Grid item xs={12} sm={6} lg={4}>

                            <FormDatePickerInput
                                required
                                disabled={isCaptacaoIniciada}
                                autoFocus
                                name="dtInicioCaptacao"
                                label="Início da Captação"
                                minDate={moment(projeto.dtInicioCaptacao)}
                                maxDate={dtFimCaptacao ? moment(dtFimCaptacao, "L") : undefined}
                                error={errors.dtInicioCaptacao?.message?.toString()}
                                format="DD/MM/YYYY"
                                inputRef={register({
                                    required: 'Informe o Início Captação',
                                    pattern: { value: Patterns.data, message: 'Formato DD/MM/AAAA' },
                                    validate: {
                                        validDate: value => moment(value, "L").isValid() || "Data Inválida!",
                                        minDate: value => moment(value, "L").isSameOrAfter(moment(projeto.dtInicioCaptacao), "day") || "Deve ser igual ou superior a hoje",
                                        maxDate: value => (dtFimCaptacao ? moment(value, "L").isSameOrBefore(moment(dtFimCaptacao, "L")) : true) || "Deve ser igual ou inferior ao fim captação"
                                    }
                                })}
                            />

                        </Grid>

                        <Grid item xs={12} sm={6} lg={4}>

                            <FormDatePickerInput
                                required
                                disabled={isCaptacaoIniciada}
                                name="dtFimCaptacao"
                                label="Fim da Captação"
                                minDate={dtInicioCaptacao ? moment(dtInicioCaptacao, "L") : undefined}
                                maxDate={dtInicioDepositos ? moment(dtInicioDepositos, "L").subtract(1, 'day') : undefined}
                                error={errors.dtFimCaptacao?.message?.toString()}
                                format="DD/MM/YYYY"
                                inputRef={register({
                                    required: 'Informe o Fim Captação',
                                    pattern: { value: Patterns.data, message: 'Formato DD/MM/AAAA' },
                                    validate: {
                                        validDate: value => moment(value, "L").isValid() || "Data Inválida!",
                                        minDate: value => (dtInicioCaptacao ? moment(value, "L").isSameOrAfter(moment(dtInicioCaptacao, "L")) : true) || "Deve ser superior ao início captação",
                                        maxDate: value => (dtInicioDepositos ? moment(value, "L").isBefore(moment(dtInicioDepositos, "L")) : true) || "Deve ser inferior ao início dos depósitos"
                                    }
                                })}
                            />

                        </Grid>

                        <Grid item xs={12} sm={6} lg={4}>

                            <FormDatePickerInput
                                required
                                disabled={isCaptacaoIniciada}
                                name="dtInicioDepositos"
                                label="Início dos Depósitos"
                                minDate={dtFimCaptacao ? moment(dtFimCaptacao, "L").add(1, 'day') : undefined}
                                maxDate={dtFimDepositos ? moment(dtFimDepositos, "L") : undefined}
                                error={errors.dtInicioDepositos?.message?.toString()}
                                format="DD/MM/YYYY"
                                inputRef={register({
                                    required: 'Informe o Início dos Depósitos',
                                    pattern: { value: Patterns.data, message: 'Formato DD/MM/AAAA' },
                                    validate: {
                                        validDate: value => moment(value, "L").isValid() || "Data Inválida!",
                                        minDate: value => (dtFimCaptacao ? moment(value, "L").isAfter(moment(dtFimCaptacao, "L")) : true) || "Deve ser superior ao fim da captação",
                                        maxDate: value => (dtFimDepositos ? moment(value, "L").isSameOrBefore(moment(dtFimDepositos, "L")) : true) || "Deve ser antes ou igual ao fim dos depósitos"
                                    }
                                })}
                            />

                        </Grid>

                        <Grid item xs={12} sm={6} lg={4}>

                            <FormDatePickerInput
                                required
                                disabled={isCaptacaoIniciada}
                                name="dtFimDepositos"
                                label="Fim dos Depósitos"
                                minDate={dtInicioDepositos ? moment(dtInicioDepositos, "L") : undefined}
                                maxDate={dtInicioTitulo ? moment(dtInicioTitulo, "L").subtract(1, 'day') : undefined}
                                error={errors.dtFimDepositos?.message?.toString()}
                                format="DD/MM/YYYY"
                                inputRef={register({
                                    required: 'Informe o Fim dos Depósitos',
                                    pattern: { value: Patterns.data, message: 'Formato DD/MM/AAAA' },
                                    validate: {
                                        validDate: value => moment(value, "L").isValid() || "Data Inválida!",
                                        minDate: value => (dtInicioDepositos ? moment(value, "L").isSameOrAfter(moment(dtInicioDepositos, "L")) : true) || "Deve ser superior ao início dos depósitos",
                                        maxDate: value => (dtInicioTitulo ? moment(value, "L").isBefore(moment(dtInicioTitulo, "L")) : true) || "Deve ser inferior ao início do título"
                                    }
                                })}
                            />

                        </Grid>

                        <Grid item xs={12} sm={6} lg={4}>

                            <FormDatePickerInput
                                required
                                disabled={isCaptacaoIniciada}
                                name="dtInicioTitulo"
                                label="Início do Título"
                                minDate={dtFimDepositos ? moment(dtFimDepositos, "L").add(1, 'day') : undefined}
                                error={errors.dtInicioTitulo?.message?.toString()}
                                format="DD/MM/YYYY"
                                inputRef={register({
                                    required: 'Informe o Início do Título',
                                    pattern: { value: Patterns.data, message: 'Formato DD/MM/AAAA' },
                                    validate: {
                                        validDate: value => moment(value, "L").isValid() || "Data Inválida!",
                                        minDate: value => (dtFimDepositos ? moment(value, "L").isAfter(moment(dtFimDepositos, "L")) : true) || "Deve ser superior ao fim dos depósitos",
                                    }
                                })}
                            />

                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <FormInput
                                name="qtPrazoMesesTitulo"
                                disabled={isCaptacaoIniciada}
                                required
                                label="Prazo em Meses"
                                placeholder="000"
                                maxLength={3}
                                inputComponent={OnlyNumbersMaskedInput as any}
                                error={errors.qtPrazoMesesTitulo?.message?.toString()}
                                inputRef={register({
                                    required: "Informe o Prazo",
                                    pattern: { value: /\d{1,3}/, message: "Somente números" },
                                    validate: {
                                        min: value => Number(value) >= 1 || 'Deve ser no mínimo 1',
                                        max: value => Number(value) <= 120 || 'Deve ser no máximo 120'
                                    }
                                })}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={4}>

                            <Controller
                                name="dtVencimentoTitulo"
                                as={
                                    <FormInput
                                        disabled
                                        label="Vencimento do Título"
                                    />
                                }
                                rules={{ required: true }}
                                control={control}
                            />

                        </Grid>

                        <Grid item xs={12} sm={6} lg={4}>
                            <FormInput
                                name="tipoPagamentoAmortizacao"
                                disabled={isCaptacaoIniciada}
                                required
                                label="Amortização"
                                error={errors.tipoPagamentoAmortizacao?.message?.toString()}
                                inputRef={register({
                                    required: "Informe o Tipo Amortização",
                                })}
                                select
                            >
                                <option value="" />
                                {Object.keys(TipoPagamentoAmortizacao).filter(key => !isNaN(Number(key))).map(key => (
                                    <option key={key} value={key}>{TipoPagamentoAmortizacao[Number(key)]}</option>
                                ))}
                            </FormInput>

                        </Grid>



                        <Grid item xs={12} sm={6} lg={4}>
                            <FormInput
                                name="tipoPagamentoJuros"
                                disabled={isCaptacaoIniciada}
                                required
                                label="Pagamento Juros"
                                error={errors.tipoPagamentoJuros?.message?.toString()}
                                inputRef={register({
                                    required: "Informe o Pagamento de Juros",
                                })}
                                select
                            >
                                <option value="" />
                                {Object.keys(TipoPagamentoJuros).filter(key => !isNaN(Number(key))).map(key => (
                                    <option key={key} value={key}>{TipoPagamentoJuros[Number(key)]}</option>
                                ))}
                            </FormInput>

                        </Grid>

                        <Grid item xs={12} sm={6} lg={4}>
                            <FormInput
                                name="qtCota"
                                disabled={isCaptacaoIniciada}
                                required
                                label="Quantidade de Cotas"
                                placeholder="00000"
                                maxLength={5}
                                inputComponent={OnlyNumbersMaskedInput as any}
                                error={errors.qtCota?.message?.toString()}
                                inputRef={register({
                                    required: "Informe a quantidade de cotas",
                                    pattern: { value: /\d{1,5}/, message: "Somente números" },
                                    validate: {
                                        min: value => Number(value) >= 1 || 'No mínimo 1 cota',
                                    }
                                })}
                            />

                        </Grid>

                        <Grid item xs={12} sm={6} lg={4}>
                            <FormInput
                                name="vrCotaEmitida"
                                disabled={isCaptacaoIniciada}
                                required
                                label="Valor Cota Emitida"
                                placeholder="0,00"
                                maxLength={9}
                                inputComponent={ReaisMaskedInput as any}
                                startAdornment="R$"
                                error={errors.vrCotaEmitida?.message?.toString()}
                                inputRef={register({
                                    required: "Informe o Valor da Cota Emitida",
                                    pattern: { value: Patterns.moeda, message: "Formato ??.??9,99" },
                                    validate: {
                                        min: value => reverseFormatNumber(intl, value) >= 1.00 || 'No mínimo R$ 1,00'
                                    }
                                })}
                            />

                        </Grid>

                        <Grid item xs={12} sm={6} lg={4}>

                            <Controller
                                name="vrAlvoCaptacao"
                                as={
                                    <FormInput
                                        disabled
                                        startAdornment="R$"
                                        label="Valor Alvo Captação"
                                        error={errors.vrAlvoCaptacao?.message?.toString()}
                                    />
                                }
                                rules={{
                                    required: true,
                                    validate: {
                                        max: value => reverseFormatNumber(intl, value) <= 10000000.00 || 'No máximo R$ 10.000.000,00'
                                    }
                                }}
                                control={control}
                            />

                        </Grid>

                        <Grid item xs={12} sm={6} lg={4}>
                            <FormInput
                                name="tipoRentabilidadeProjetada"
                                disabled={isCaptacaoIniciada}
                                required
                                label="Tipo de Rentabilidade"
                                error={errors.tipoRentabilidadeProjetada?.message?.toString()}
                                inputRef={register({
                                    required: "Selecione o Tipo de Rentabilidade",
                                })}
                                select
                            >
                                <option value="" />
                                {Object.keys(TipoRentabilidadeProjetada).filter(key => !isNaN(Number(key))).map(key => (
                                    <option key={key} value={key}>{TipoRentabilidadeProjetada[Number(key)]}</option>
                                ))}
                            </FormInput>

                        </Grid>

                        {tipoRentabilidadeProjetada == TipoRentabilidadeProjetada.Fixa.toString() &&
                            <>
                                <Grid item xs={12} sm={6} lg={4}>

                                    <Controller
                                        name="pcRentabilidadeProjetadaFixaAnual"
                                        as={
                                            <FormInput
                                                label="Rentabilidade Anual"
                                                disabled={isCaptacaoIniciada}                                                
                                                placeholder="0,00%"
                                                maxLength={7}
                                                error={errors.pcRentabilidadeProjetadaFixaAnual?.message?.toString()}
                                                inputComponent={PorcentagemMaskedInput as any}
                                            />
                                        }
                                        rules={{
                                            required: 'Informe a Rentabilidade Anual',
                                            pattern: { value: Patterns.porcentagem, message: "Formato ??9,99%" }
                                        }}
                                        control={control}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} lg={4}>

                                    <Controller
                                        name="pcRentabilidadeProjetadaFixa"
                                        as={
                                            <FormInput
                                                disabled
                                                label="Rentabilidade Projetada"
                                            />
                                        }
                                        rules={{
                                            required: true,
                                        }}
                                        control={control}
                                    />
                                </Grid>
                            </>
                        }

                        {tipoRentabilidadeProjetada == TipoRentabilidadeProjetada.Variável.toString() &&
                            <>
                                <Grid item xs={12} sm={6} lg={4}>

                                    <Controller
                                        name="pcRentabilidadeProjetadaMinimaAnual"
                                        as={
                                            <FormInput
                                                label="Rentabilidade Anual Mínima"
                                                disabled={isCaptacaoIniciada}    
                                                placeholder="0,00%"
                                                maxLength={7}
                                                error={errors.pcRentabilidadeProjetadaMinimaAnual?.message?.toString()}
                                                inputComponent={PorcentagemMaskedInput as any}
                                            />
                                        }
                                        rules={{
                                            required: 'Informe a Rentabilidade Anual Mínima',
                                            pattern: { value: Patterns.porcentagem, message: "Formato ??9,99%" }
                                        }}
                                        control={control}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} lg={4}>

                                    <Controller
                                        name="pcRentabilidadeProjetadaMinima"
                                        as={
                                            <FormInput
                                                disabled
                                                label="Rentabilidade Projetada Mínima"
                                            />
                                        }
                                        rules={{
                                            required: true,
                                        }}
                                        control={control}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} lg={4}>

                                    <Controller
                                        name="pcRentabilidadeProjetadaMaximaAnual"
                                        as={
                                            <FormInput
                                                label="Rentabilidade Anual Máxima"
                                                disabled={isCaptacaoIniciada}    
                                                placeholder="0,00%"
                                                maxLength={7}
                                                error={errors.pcRentabilidadeProjetadaMaximaAnual?.message?.toString()}
                                                inputComponent={PorcentagemMaskedInput as any}
                                            />
                                        }
                                        rules={{
                                            required: 'Informe a Rentabilidade Anual Máxima',
                                            pattern: { value: Patterns.porcentagem, message: "Formato ??9,99%" }
                                        }}
                                        control={control}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} lg={4}>

                                    <Controller
                                        name="pcRentabilidadeProjetadaMaxima"
                                        as={
                                            <FormInput
                                                disabled
                                                label="Rentabilidade Projetada Máxima"
                                            />
                                        }
                                        rules={{
                                            required: true,
                                        }}
                                        control={control}
                                    />
                                </Grid>

                            </>
                        }

                        <Grid item xs={12} sm={6} lg={4}>
                            <FormInput
                                name="coTaxaJuros"
                                disabled={!taxasJurosRequestState.data}
                                helperText={taxasJurosRequestState.pending ? <LoadingText text="Carregando taxas" /> : undefined}
                                required
                                label="Taxa de Juros"
                                error={
                                    taxasJurosRequestState.error
                                        ? <Link component="button" onClick={() => dispatch(fetchTaxasJurosList())}>Erro ao Carregar. Tentar Novamente</Link>
                                        : errors.coTaxaJuros?.message?.toString()
                                }
                                inputRef={register({
                                    required: "Selecione a taxa a vigorar",
                                })}
                                select
                            >
                                <option value="" />
                                {taxasJurosRequestState.data?.map(taxaJuros => (
                                    <option
                                        key={taxaJuros.coTaxaJuros}
                                        value={taxaJuros.coTaxaJuros}>
                                        {
                                            moment(taxaJuros.dtInicio).format("L")}|
                                        {intl.formatNumber(taxaJuros.pcTaxaSelic, { style: "percent", minimumFractionDigits: 2 })}|
                                        {intl.formatNumber(taxaJuros.pcTaxaCdi, { style: "percent", minimumFractionDigits: 2 })}|
                                        {intl.formatNumber(taxaJuros.pcTaxaPoupanca, { style: "percent", minimumFractionDigits: 2 })
                                        }
                                    </option>
                                ))}
                            </FormInput>

                        </Grid>

                        <Grid item xs={12} sm={6} lg={4}>
                            <FormInput
                                name="tipoRentabilidadeGarantida"
                                disabled={isCaptacaoIniciada}    
                                required
                                label="Rentabilidade Garantida"
                                error={errors.tipoRentabilidadeGarantida?.message?.toString()}
                                inputRef={register({
                                    required: "Selecione o Tipo de Garantia",
                                })}
                                select
                            >
                                <option value="" />
                                {Object.keys(TipoRentabilidadeGarantida).filter(key => !isNaN(Number(key))).map(key => (
                                    <option key={key} value={key}>{TipoRentabilidadeGarantida[Number(key)]}</option>
                                ))}
                            </FormInput>
                        </Grid>

                        {tipoRentabilidadeGarantida == TipoRentabilidadeGarantida["% do CDI Pré-fixado"].toString() &&
                            <>
                                <Grid item xs={12} sm={6} lg={4}>
                                    <Controller
                                        name="pcRentabilidadeGarantidaCdi"
                                        as={
                                            <FormInput
                                                label="% do CDI Pré-fixado"
                                                disabled={isCaptacaoIniciada}    
                                                placeholder="0,00%"
                                                maxLength={7}
                                                error={errors.pcRentabilidadeGarantidaCdi?.message?.toString()}
                                                inputComponent={PorcentagemMaskedInput as any}
                                            />
                                        }
                                        rules={{
                                            required: 'Informe a % de Rentabilidade Garantida',
                                            pattern: { value: Patterns.porcentagem, message: "Formato ??9,99%" }
                                        }}
                                        control={control}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4}>

                                    <Controller
                                        name="pcRentabilidadeGarantidaFixaAnual"
                                        as={
                                            <FormInput
                                                disabled
                                                label="Rentabilidade Anual Garantida"
                                            />
                                        }
                                        rules={{
                                            required: true,
                                        }}
                                        control={control}
                                    />
                                </Grid>
                            </>
                        }

                        <Grid item container justify="flex-end" spacing={2}>
                            {onBack &&
                                <Grid item>
                                    <Button
                                        id="btnVoltar"
                                        variant="text"
                                        color="default"
                                        onClick={onBack}
                                    >
                                        Voltar
              </Button>
                                </Grid>
                            }
                            <Grid item>
                                <ButtonProgress
                                    id="btnSubmit"
                                    loading={isSubmitting}
                                    disabled={Object.values(errors).length > 0 || !dirty}
                                    variant="contained"
                                    color="primary"
                                    type="submit">
                                    {submitText}
                                </ButtonProgress>
                            </Grid>
                        </Grid>


                    </Grid>


                </Box>
            </Paper>
        </form >
    )
}

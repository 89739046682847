import {createAsyncThunk, createEntityAdapter, createSlice, EntityState} from "@reduxjs/toolkit";
import moment from "moment";
import {fromEvent, throwError} from "rxjs";
import {catchError, map, takeUntil, timeout} from "rxjs/operators";
import {ProjetoVO} from "../../../models/Projeto";
import {api} from "../../../services/api/api";
import {APIError} from "../../../services/api/APIError";
import {APIUtils} from "../../../services/api/APIUtils";
import {RootState} from "../../configureStore";
import {RequestState, shouldFetch} from "../../RequestsState";

interface CaptacoesSliceState {
  requests: EntityState<RequestState<any>>;
}

const requestsAdapter = createEntityAdapter<RequestState<any>>({
  selectId: request => request.id
});

const initialState = {
  requests: requestsAdapter.getInitialState()
} as CaptacoesSliceState


// REQUESTS

const REQ_FETCH_CAPTACOES = "fetchCaptacoes";
const REQ_FETCH_DETALHES_CAPTACAO = "fetchDetalhesCaptacao";


const requestSelectors = requestsAdapter.getSelectors((state: RootState) => state.entities.captacoes.requests)

const trackFetchCaptacoes = (state: RootState): RequestState<ProjetoVO[]> => {
  return requestSelectors.selectById(state, REQ_FETCH_CAPTACOES) ?? {id: REQ_FETCH_CAPTACOES, pending: false}
}

const trackFetchDetalhesCaptacao = (permalink: string) => (state: RootState): RequestState<ProjetoVO> => {
  return requestSelectors.selectById(state, `${REQ_FETCH_DETALHES_CAPTACAO}:${permalink}`) ?? {
    id: `${REQ_FETCH_DETALHES_CAPTACAO}:${permalink}`,
    pending: false
  }
}

// THUNKS
const fetchCaptacoes = createAsyncThunk<ProjetoVO[], void, { state: RootState; rejectValue: APIError }>
('captacoes/fetchCaptacoes',
  async (args, {signal, rejectWithValue}) => {
    return api.investir.fetchCaptacoes().pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: (args, {getState}) => shouldFetch(trackFetchCaptacoes(getState()), moment.duration(1, 'minute'))}
)

const fetchDetalhesCaptacao = createAsyncThunk<ProjetoVO, { permalink: string }, { state: RootState; rejectValue: APIError }>
('captacoes/fetchDetalhesCaptacao',
  async ({permalink}, {signal, rejectWithValue}) => {
    return api.investir.fetchDetalhesCaptacao(permalink).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({permalink}, {getState}) => shouldFetch(trackFetchDetalhesCaptacao(permalink)(getState()), moment.duration(1, 'minute'))}
)


// SLICE
const captacoesSlice = createSlice({
  name: 'captacoes',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    //
    builder.addCase(fetchCaptacoes.pending, (state) => {
      requestsAdapter.upsertOne(state.requests, {
        id: REQ_FETCH_CAPTACOES,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(fetchCaptacoes.fulfilled, (state, {payload: captacoes}) => {
      requestsAdapter.updateOne(state.requests, {
        id: REQ_FETCH_CAPTACOES,
        changes: {pending: false, timestamp: moment().toISOString(), data: captacoes}
      });
    })
    builder.addCase(fetchCaptacoes.rejected, (state, action) => {
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {id: REQ_FETCH_CAPTACOES, changes: {pending: false, error}});
    })

    //
    builder.addCase(fetchDetalhesCaptacao.pending, (state, {meta: {arg: {permalink}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_FETCH_DETALHES_CAPTACAO}:${permalink}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(fetchDetalhesCaptacao.fulfilled, (state, {payload: empresas, meta: {arg: {permalink}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_FETCH_DETALHES_CAPTACAO}:${permalink}`,
        changes: {pending: false, timestamp: moment().toISOString(), data: empresas}
      });
    })
    builder.addCase(fetchDetalhesCaptacao.rejected, (state, action) => {
      const {permalink} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_FETCH_DETALHES_CAPTACAO}:${permalink}`,
        changes: {pending: false, error}
      });
    })

  }
})

export {
  fetchCaptacoes,
  fetchDetalhesCaptacao,
  trackFetchDetalhesCaptacao,
  trackFetchCaptacoes,
};

export default captacoesSlice.reducer
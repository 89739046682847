import * as React from "react";
import * as ReactDOM from "react-dom";
import { setConfig } from "react-hot-loader";
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import logger from "redux-logger";
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import 'react-image-gallery/styles/css/image-gallery.css';
import { pdfjs } from 'react-pdf';
import 'typeface-roboto';
import { rootReducer } from "../shared/stores/configureStore";
import App from "../shared/views/App";
import { syncronizeClientServerClock } from "../shared/utils/clock";
import moment from "moment";

pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.js`;

// Configuração para remover warning do hot loader
setConfig({
  showReactDomPatchNotification: false
})

// Start Server Clock Syncronization on Background
syncronizeClientServerClock(); 
setInterval(syncronizeClientServerClock, moment.duration(60,'minute').asMilliseconds());

// REDUX
const preloadedState = window.__PRELOADED_STATE__; //Captura estado do servidor
delete window.__PRELOADED_STATE__; // Remove da variável global

const persistConfig = {
  key: 'root',
  storage,
  version: 1,
  whitelist: ['user']
}
 
const persistedReducer = persistReducer(persistConfig, rootReducer)

let middlewares = getDefaultMiddleware({
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER] // Ignore Redux Persist
  }
})
if(__isDevelopment__) middlewares = middlewares.concat(logger);

const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewares,
  // eslint-disable-next-line no-undef
  devTools: __isDevelopment__,
  preloadedState,
  enhancers: []
});

const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch

function Main() {

  // Remove store e css renderizado no servidor quando o componente e re-renderizado
  React.useEffect(() => {
    const jssStyles = document.getElementById('jss-server-side');
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }, []);

  return (
    <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
    </Provider>
  );
}

const rootDiv = document.getElementById("root");

persistor.subscribe(() => {
  /* Hydrate React components when persistor has synced with redux store */
  const { bootstrapped } = persistor.getState();
  if (bootstrapped) {
    if (rootDiv && rootDiv.innerHTML) {
      // Foi renderizado no servidor, rehidratar
      ReactDOM.hydrate(<Main />, rootDiv);
    } else {
      // Somente client side, somente renderizar
      ReactDOM.render(<Main />, rootDiv);
    }
  }
});




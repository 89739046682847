import {createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction} from "@reduxjs/toolkit";
import {fromEvent, throwError} from "rxjs";
import {catchError, map, takeUntil, timeout} from "rxjs/operators";
import {ContaUsuarioVO} from "../../models/ContaUsuario";
import {api} from "../../services/api/api";
import {APIError} from "../../services/api/APIError";
import {APIUtils} from "../../services/api/APIUtils";
import {LoginUsuario, LoginUtils} from "../../utils/login";
import {parseJwtTokenToLoginUsuario} from "../../utils/token";
import {RootState} from "../configureStore";
import moment from "moment";
import {RequestState, shouldFetch} from "../RequestsState";

interface UserSliceState {
  token?: string;
  login?: LoginUsuario;
  contaUsuario?: ContaUsuarioVO;
  requests: EntityState<RequestState<any>>;
}

const requestsAdapter = createEntityAdapter<RequestState<any>>({
  selectId: request => request.id
});

const initialState = {
  requests: requestsAdapter.getInitialState()
} as UserSliceState


// REQUESTS

const REQ_EFETUA_LOGIN = "efetuaLogin";

const REQ_AUTO_REGISTRO = "autoRegistro";

const requestSelectors = requestsAdapter.getSelectors((state: RootState) => state.user.requests)

const trackAutoRegistroRequest = (state: RootState): RequestState<ContaUsuarioVO> => {
  return requestSelectors.selectById(state, REQ_AUTO_REGISTRO) ?? {id: REQ_AUTO_REGISTRO, pending: false}
}

const trackEfetuaLoginRequest = (state: RootState): RequestState<ContaUsuarioVO> => {
  return requestSelectors.selectById(state, REQ_EFETUA_LOGIN) ?? {id: REQ_EFETUA_LOGIN, pending: false}
}


// THUNKS

const decodeTokenUsuarioIfExists = (contaUsuario: ContaUsuarioVO) => ({
  ...contaUsuario,
  loginUsuario: contaUsuario.token ? parseJwtTokenToLoginUsuario(contaUsuario.token) : undefined
})

const autoRegistro = createAsyncThunk<ContaUsuarioVO, { usuario: ContaUsuarioVO }, { state: RootState; rejectValue: APIError }>
('user/autoRegistro',
  async ({usuario}, {signal, rejectWithValue}) => {
    usuario.senha = LoginUtils.encriptarSenha(usuario.noEmail, usuario.senha);
    return api.login.autoRegistro(usuario).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: (args, {getState}) => shouldFetch(trackAutoRegistroRequest(getState()))}
)

const efetuaLogin = createAsyncThunk<ContaUsuarioVO, { email: string; senha: string }, { state: RootState; rejectValue: APIError }>
('user/efetuaLogin',
  async ({email, senha}, {signal, rejectWithValue}) => {
    const senhaCrypto = LoginUtils.encriptarSenha(email, senha);
    return api.login.efetuaLogin(email, senhaCrypto).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      map(decodeTokenUsuarioIfExists),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: (args, {getState}) => shouldFetch(trackEfetuaLoginRequest(getState()))}
)

// SLICE

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    efetuaLogoff() {
      document.cookie = "token=;max-age=0"; // Remove Token
      return initialState // Clean State
    },
    registraToken(state, {payload: {token}}: PayloadAction<{ token: string }>) {
      state.login = parseJwtTokenToLoginUsuario(token);
      state.token = token;
    },
  },
  extraReducers: builder => {
    builder.addCase(autoRegistro.pending, (state) => {
      requestsAdapter.upsertOne(state.requests, {
        id: REQ_AUTO_REGISTRO,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(autoRegistro.fulfilled, (state, {payload: contaUsuario}) => {
      requestsAdapter.updateOne(state.requests, {
        id: REQ_AUTO_REGISTRO,
        changes: {pending: false, timestamp: moment().toISOString()}
      });
      state.contaUsuario = contaUsuario;
    })
    builder.addCase(autoRegistro.rejected, (state, action) => {
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {id: REQ_AUTO_REGISTRO, changes: {pending: false, error}});
    })


    //
    builder.addCase(efetuaLogin.pending, (state) => {
      requestsAdapter.upsertOne(state.requests, {
        id: REQ_EFETUA_LOGIN,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(efetuaLogin.fulfilled, (state, {payload: contaUsuario}) => {
      requestsAdapter.updateOne(state.requests, {
        id: REQ_EFETUA_LOGIN,
        changes: {pending: false, timestamp: moment().toISOString()}
      });
      if (contaUsuario.token && contaUsuario.loginUsuario) {
        state.login = contaUsuario.loginUsuario;
        state.token = contaUsuario.token;
        document.cookie = `token=${contaUsuario.token}; path=/; expires=${new Date(contaUsuario.loginUsuario.expiration * 1000).toUTCString()}`;
      }
      state.contaUsuario = contaUsuario;
    })
    builder.addCase(efetuaLogin.rejected, (state, action) => {
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {id: REQ_EFETUA_LOGIN, changes: {pending: false, error}});
    })
  }
})

const {efetuaLogoff, registraToken} = userSlice.actions;

const selectLogin = (state: RootState) => state.user.login;
const selectContaUsuario = (state: RootState) => state.user.contaUsuario;
const selectToken = (state: RootState) => state.user.token;

export {
  efetuaLogin,
  efetuaLogoff,
  registraToken,
  autoRegistro,
  selectLogin,
  selectContaUsuario,
  selectToken,
  trackEfetuaLoginRequest
};

export default userSlice.reducer
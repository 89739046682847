import { Button, Container, createStyles, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, makeStyles, Paper, Theme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Edit';
import HomeIcon from '@material-ui/icons/Home';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import MoneyIcon from '@material-ui/icons/Money';
import PostAddIcon from '@material-ui/icons/PostAdd';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AppDispatch } from '../../../../../../browser';
import { APIUtils } from '../../../../../services/api/APIUtils';
import { fetchProjetosList, trackFetchProjetos } from '../../../../../stores/slices/entities/projetosSlice';
import ErrorEmptyState from '../../../../components/empty/ErrorEmptyState';
import EmptyState from '../../../../components/EmptyState';
import { FieldOutput } from '../../../../components/form/output/FieldOutput';
import Loading from '../../../../components/Loading';
import OnlyDesktop from '../../../../layout/OnlyDesktop';
import OnlyMobile from '../../../../layout/OnlyMobile';
import PageTemplate from '../../../../layout/PageTemplate';

const useStyles = makeStyles((theme: Theme) => createStyles({
  logoMobile: {
    maxHeight: 40,
    width: 40
  },
  arrow: {
    minWidth: 0
  },
  listItem: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(3, 2),
  },
  logoDesktop: {
    maxHeight: 60,
    width: 60
  },
}), { name: 'ProjetosListPage' });

export const ProjetosListPage = () => {

  const classes = useStyles();

  const location = useLocation();

  const requestState = useSelector(trackFetchProjetos);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const promise = dispatch(fetchProjetosList());
    return () => promise.abort();
  }, [dispatch]);

  return (
    <PageTemplate
      metaTags={{
        title: "Gottaup.com | Projetos",
        description: "Lista de projetos cadastrados"
      }}
      menuDesktop
      headerMobile={{
        title: "Projetos",
        leftButtons: (
          <IconButton edge="start" color="inherit" component={Link} to="/conta">
            <ArrowBackIcon />
          </IconButton>
        ),
        rightButtons: (
          <IconButton edge="end" color="inherit" component={Link} to="/admin/projetos/create">
            <AddIcon />
          </IconButton>
        )
      }}
      headerDesktop={{
        title: "Projetos Cadastrados",
        breadcrumbs: [
          { label: "Home", to: '/', icon: HomeIcon },
          { label: "Projetos" }
        ]
      }}
    >

      <section>

        <OnlyDesktop>
          <Container>

            <Grid container justify="flex-end">
              <Grid item >
                <Button variant="contained" color="secondary" component={Link} to="/admin/projetos/create" startIcon={<AddIcon />}>Novo</Button>
              </Grid>
            </Grid>

          </Container>
        </OnlyDesktop>

        {requestState.pending &&
          <Loading text="Buscando projetos..." />
        }

        {requestState.error &&
          <ErrorEmptyState
            error={requestState.error}
            defaultDescription="Não foi possível buscar projetos cadastrados"
            onTryAgainClick={() => dispatch(fetchProjetosList())}
          />
        }

        {requestState.data && requestState.data.length == 0 &&
          <EmptyState
            icon={<PostAddIcon style={{ fontSize: '58px' }} color="disabled" />}
            title="Nenhum projeto cadastrado."
            subtitle="Crie um novo projeto e ele será listado aqui."
          />
        }

          {requestState.data && requestState.data.length > 0 &&
          <Fragment>
            <OnlyMobile>
              <List>
                {requestState.data.map((projeto, index) => (
                  <Fragment key={index}>
                    <ListItem button divider component={Link} to={`/admin/projetos/${projeto.nuProjeto}`}>
                      <ListItemAvatar>
                        <img className={classes.logoMobile} src={APIUtils.generateImageURL(projeto.imagemCapa)} alt={"capa do projeto " + projeto.noProjeto} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={projeto.noProjeto}
                        secondary={projeto.deProjeto} />
                      <ListItemIcon className={classes.arrow}>
                        <KeyboardArrowRightIcon />
                      </ListItemIcon>
                    </ListItem>
                  </Fragment>
                ))}
              </List>
            </OnlyMobile>

            <OnlyDesktop>

              <Container>
                {requestState.data.map((projeto, index) => (
                  <Paper key={index} className={classes.listItem}>
                    <Grid container alignItems="center">
                      <Grid item sm={2} md={2}>
                        <Grid container justify="center">
                          <img className={classes.logoDesktop} src={APIUtils.generateImageURL(projeto.imagemCapa)} alt={"capa do projeto " + projeto.noProjeto} />
                        </Grid>
                      </Grid>
                      <Grid item sm={6} md={6}>
                        <FieldOutput label={projeto.noProjeto} value={projeto.deProjeto} />
                      </Grid>
                      <Grid item sm={4} md={4}>
                        <Grid container justify="flex-end" spacing={2}>
                          <Grid item>
                            <Button
                              variant="outlined"
                              color="secondary"
                              component={Link}
                              startIcon={<MoneyIcon />}
                              to={{ pathname: `/admin/projetos/${projeto.nuProjeto}/investimentos`, state: { from: location.pathname } }}
                            >
                              Investimentos
                          </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              color="secondary"
                              variant="outlined"
                              component={Link}
                              startIcon={<EditIcon />}
                              to={`/admin/projetos/${projeto.nuProjeto}`}>
                              Editar
                          </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                  </Paper>
                ))}
              </Container>
            </OnlyDesktop>
          </Fragment>
        }
      </section>


    </PageTemplate>
  )
}
import { Box, Collapse, Container, createStyles, IconButton, makeStyles, Theme, Typography, Link } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Alert, AlertProps, AlertTitle } from '@material-ui/lab';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { AppDispatch } from '../../../../../browser';
import { APIError } from '../../../../services/api/APIError';
import { efetuaLogin } from '../../../../stores/slices/userSlice';
import ButtonProgress from '../../../components/ButtonProgress';
import FormInput from '../../../components/FormInput';
import GottaupLogo from '../../../images/gottaup-logo.png';
import PageTemplate from '../../../layout/PageTemplate';
import { Patterns } from '../../../../utils/patterns';

const useStyles = makeStyles((theme: Theme) => createStyles({
  logo: {
    maxWidth: '180px'
  },
  fieldGroup: {
    marginTop: theme.spacing(2)
  },
  link: {
    marginLeft: theme.spacing(1),
  },
  container: {
    marginTop: theme.spacing(6)
  },
}), { name: 'Login' });


interface LoginFormData {
  email: string;
  senha: string;
}

export const LoginPage = () => {

  const classes = useStyles();

  const history = useHistory();

  const { from } = history.location.state as any || { from: { pathname: "/" } };

  const dispatch = useDispatch<AppDispatch>();

  const { register, handleSubmit, errors, formState: { isSubmitting } } = useForm<LoginFormData>();

  const [alert, setAlert] = useState<AlertProps | undefined>(undefined);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  
  const submitLogin = async (data: LoginFormData) => {
    const resultAction = await dispatch(efetuaLogin(data));
    if (efetuaLogin.fulfilled.match(resultAction)) {
      const contaUsuario = unwrapResult(resultAction)
      if (contaUsuario.loginUsuario) {
        const dismissButton = (key: string | number | undefined) => <IconButton id="btnCloseSnackbar" onClick={() => closeSnackbar(key)}><CloseIcon color="action" /></IconButton>
        enqueueSnackbar('Login efetuado com sucesso!', { variant: 'success', action: dismissButton });
        history.push(from);
      } else {
        // Conta do usuário possui alguma restrição
        if (!contaUsuario.icEmailValidado) {
          history.push("/login/validacao/email")
        } else {
          setAlert({
            severity: 'error',
            children: "Falha no login, token inexistente."
          })
        }
      }
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      setAlert({
        severity: 'error',
        children: <>
          <AlertTitle>{error.message}</AlertTitle>
          {error.traceid && `Traceid: ${error.traceid}`}
        </>
      })
    }

  }

  return (
    <PageTemplate
      metaTags={{
        title: "Gottaup.com | Login",
        description: "Efetue seu login"
      }}
      menuDesktop
      menuMobile
      loading={{ isLoading: isSubmitting }}
    >
      <Container maxWidth="sm" className={classes.container}>
        <form noValidate onSubmit={handleSubmit(submitLogin)}>
          <Box display="flex" justifyContent="center" mt={1}>
            <img src={GottaupLogo} alt="Logo do Gottaup" className={classes.logo} />
          </Box>
          <Box mt={3}>
            <FormInput
              required
              name="email"
              label="Email"
              helperText=" "
              placeholder="usuario@domínio.com"
              error={errors.email?.message?.toString()}
              inputRef={register({
                required: 'Informe o email',
                pattern: { value: Patterns.email, message: 'Email em formato inválido' },
                maxLength: 100
              })}
            />
          </Box>
          <Box mt={2}>
            <FormInput
              required
              name="senha"
              label="Senha"
              placeholder="*******"
              helperText=" "
              error={errors.senha?.message?.toString()}
              submitOnEnter={true}
              type="password"
              inputRef={register({
                required: 'Informe a senha',
                maxLength: 8,
              })}
            />
          </Box>
          <Box display="flex" justifyContent="center" mt={2}>
            <ButtonProgress id="btnEntrar" loading={isSubmitting} variant="contained" size="large" color="secondary" type="submit">
              Entrar
             </ButtonProgress>
          </Box>
          <Box mt={1}>
            <Collapse in={alert && !alert.hidden} onExited={() => setAlert(undefined)}>
              {alert && <Alert id="msgErro" onClose={() => setAlert({ ...alert, hidden: true })} {...alert} />}
            </Collapse>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center" mt={1} flexWrap="wrap">
            <Typography variant="body2">Esqueceu sua senha ou conta bloqueada?</Typography>
            <Link component={RouterLink} to="/login/senha/reset" className={classes.link}>Recuperar Senha</Link>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center" mt={1} flexWrap="wrap">
            <Typography variant="body2">Não possui conta?</Typography>
            <Link component={RouterLink} id="btnRegistro" to="/login/registro" className={classes.link}>Registre-se agora</Link>
          </Box>
        </form>
      </Container>


    </PageTemplate>
  )
}

//const mapState = (state: RootState) => ({ efetuaLoginStatus: trackEfetuaLoginRequest(state) });
//const mapDispatch = { efetuaLogin }
//const connector = connect(mapState, mapDispatch)
//type PropsFromRedux = ConnectedProps<typeof connector>
//type Props = PropsFromRedux & RouteComponentProps<{}>
//export default connector(LoginPage);
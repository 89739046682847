import AbortController from 'abort-controller';
import fetch from 'isomorphic-fetch';
import Investidor from '../models/Investidor';
import Investimento from '../models/Investimento';
import APIErrorMessage from './APIErrorMessage';
import APIException from './APIException';
import { getServiceURL } from './ServiceURL';
import { MotivoCancelamentoInvestimento } from '../models/enums/MotivoCancelamentoInvestimento';

const BASE_URL = `${getServiceURL()}/api/investidores`;

export default class InvestidorService {


    async aprovarReserva(coContaUsuario: number, coInvestimento: number, token: string): Promise<Investimento>{
        if (__isBrowser__ && location.hostname != "localhost" && navigator.onLine !== undefined && navigator.onLine === false ) {
            throw new APIException(new APIErrorMessage("Navegador Offline - Verifique sua conexão com a internet"));
        } 
        const controller = new AbortController();
        const signal = controller.signal;
        const timeoutId = setTimeout(() => controller.abort(), TRANSACTION_TIMEOUT);
        let response;
        try {
            response = await fetch(`${BASE_URL}/${coContaUsuario}/investimentos/${coInvestimento}/aprovar`, {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    "Content-type": "application/json; charset=UTF-8"
                },
                signal
            });
        } catch (ex) {
            console.log(ex);
            throw new APIException(new APIErrorMessage().setFromError(ex));
        }
        clearTimeout(timeoutId);
        if (response.status == 200) {
            return new Investimento(await response.json());
        } else {
            throw new APIException(await new APIErrorMessage().setFromResponse(response));
        }
    }

    async cancelarReserva(coContaUsuario: number, coInvestimento: number, motivoCancelamento: MotivoCancelamentoInvestimento, token: string): Promise<Investimento> {
        if (__isBrowser__ && location.hostname != "localhost" && navigator.onLine !== undefined && navigator.onLine === false ) {
            throw new APIException(new APIErrorMessage("Navegador Offline - Verifique sua conexão com a internet"));
        } 
        const controller = new AbortController();
        const signal = controller.signal;
        const timeoutId = setTimeout(() => controller.abort(), TRANSACTION_TIMEOUT);
        let response;
        try {
            response = await fetch(`${BASE_URL}/${coContaUsuario}/investimentos/${coInvestimento}/cancelar`, {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    "Content-type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify(motivoCancelamento),
                signal
            });
        } catch (ex) {
            console.log(ex);
            throw new APIException(new APIErrorMessage().setFromError(ex));
        }
        clearTimeout(timeoutId);
        if (response.status == 200) {
            return new Investimento(await response.json());
        } else {
            throw new APIException(await new APIErrorMessage().setFromResponse(response));
        }
    }


    async updateSituacaoDocumentos(coContaUsuario: number, investidor: Investidor, token: string) {
        if (__isBrowser__ && location.hostname != "localhost" && navigator.onLine !== undefined && navigator.onLine === false ) {
            throw new APIException(new APIErrorMessage("Navegador Offline - Verifique sua conexão com a internet"));
        } 
        const controller = new AbortController();
        const signal = controller.signal;
        const timeoutId = setTimeout(() => controller.abort(), TRANSACTION_TIMEOUT);
        let response;
        try {
            response = await fetch(`${BASE_URL}/${coContaUsuario}/documentosvalidacao`, {
                method: 'PATCH',
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json; charset=UTF-8',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(investidor),
                signal
            });
        } catch (ex) {
            console.log(ex);
            throw new APIException(new APIErrorMessage().setFromError(ex));
        }
        clearTimeout(timeoutId);
        if (response.status != 204) {
            throw new APIException(await new APIErrorMessage().setFromResponse(response));
        }
    }

    async searchInvestidoresComDocumentosParaValidacao(token: string): Promise<Array<Investidor>> {
        if (__isBrowser__ && location.hostname != "localhost" && navigator.onLine !== undefined && navigator.onLine === false ) {
            throw new APIException(new APIErrorMessage("Navegador Offline - Verifique sua conexão com a internet"));
        } 
        const controller = new AbortController();
        const signal = controller.signal;
        const timeoutId = setTimeout(() => controller.abort(), TRANSACTION_TIMEOUT);
        let response;
        try {
            response = await fetch(`${BASE_URL}/documentosvalidacao`, { 
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                signal 
            });
        } catch (ex) {
            console.log(ex);
            throw new APIException(new APIErrorMessage().setFromError(ex));
        }
        clearTimeout(timeoutId);
        if (response.status == 200) {
            const json = await response.json();
            return json.map((obj: any) => new Investidor(obj));
        } else {
            throw new APIException(await new APIErrorMessage().setFromResponse(response));
        }

    }



}

import { Container, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HomeIcon from '@material-ui/icons/Home';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import { AppDispatch } from '../../../../../../../browser';
import { findInvestidorByContaUsuario, trackFindInvestidorByContaUsuario } from '../../../../../../stores/slices/entities/investidoresSlice';
import { selectLogin } from '../../../../../../stores/slices/userSlice';
import { LoginUsuario } from '../../../../../../utils/login';
import ErrorEmptyState from '../../../../../components/empty/ErrorEmptyState';
import Loading from '../../../../../components/Loading';
import PageTemplate from '../../../../../layout/PageTemplate';

export const CadastroInvestidorEntryPage = () => {

  const history = useHistory();
  const { from } = history.location.state as any || { from: undefined };
  const params = useParams<{ id?: string }>();

  const login = useSelector(selectLogin) as LoginUsuario;
  const coContaUsuario = Number(params.id || login.id);
  const requestState = useSelector(trackFindInvestidorByContaUsuario(coContaUsuario))

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if(coContaUsuario){
      dispatch(findInvestidorByContaUsuario({ coContaUsuario }))
      //return () => promise.abort(); Estava abortando a requisição do edit??
    }
  }, [dispatch, coContaUsuario])

  return (
    <PageTemplate
      metaTags={{
        title: "Gottaup.com | Cadastro do Investidor",
        description: "Atualizar cadastro do investidor"
      }}
      menuDesktop
      headerMobile={{
        title: "Cadastro do Investidor",
        leftButtons: (
          <IconButton edge="start" color="inherit" component={Link} to={from}>
            <ArrowBackIcon />
          </IconButton>
        )
      }}
      headerDesktop={{
        title: "Cadastro do Investidor",
        breadcrumbs: [
          { label: "Home", to: '/', icon: HomeIcon },
          { label: "Cadastro do Investidor" }
        ]
      }}
    >

      <Container component="section" style={{ marginTop: 24 }}>

        {requestState.pending &&
          <Loading text="Carregando cadastro do investidor..." />
        }

        {requestState.error &&
          <ErrorEmptyState
            error={requestState.error}
            defaultDescription="Não foi possível carregar cadastro do investidor"
            onTryAgainClick={() => dispatch(findInvestidorByContaUsuario({ coContaUsuario }))}
          />
        }

        {requestState.data &&
          <>
            {requestState.data.coContaUsuario
              ? <Redirect to="/conta/investidor/edit" />
              : <Redirect to="/conta/investidor/create" />
            }
          </>
        }

      </Container>
    </PageTemplate>
  )
}
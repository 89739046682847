import { Collapse } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import ISODateTime from '../../models/types/ISODateTime';

export interface PageBannerProps {
  icon?: JSX.Element;
  text?: string;
  traceId?: string;
  timestamp?: ISODateTime;
  buttons?: React.ReactNode;
}

export default function PageBanner(props: PageBannerProps) {

  const [state, setState] = useState(props);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (props.text && props.buttons) {
      // Se tem texto e botões, então atualiza banner e abre
      setState(props);
      setOpen(true);
      scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    } else {
      // Se não tem mais texto, só fecha, para fazer efeito de collapse
      setOpen(false)
    }
  }, [props])


  return (
    <Collapse in={open} timeout={800}>
      <Box clone pt={2} pr={1} pb={1} pl={2}>
        <Paper square={true} variant="outlined">
          <Grid container spacing={2} alignItems="center" wrap="nowrap">
            {state.icon &&
              <Grid item>
                <Box bgcolor="primary.main" clone>
                  <Avatar>
                    {state.icon}
                  </Avatar>
                </Box>
              </Grid>
            }
            <Grid item>
              <Typography variant="h6" id="banner.msg">{state.text}</Typography>
              {state.traceId &&
                <Typography variant="body2" id="banner.traceid"><b>Código de Rastreio</b>: {state.traceId}</Typography>
              }
              {state.timestamp &&
                <Typography variant="body2" id="banner.timestamp"><b>Horário no Servidor</b>: {state.timestamp.toFormat("yyyy-MM-dd HH:mm:ss,SSS")}</Typography>
              }
            </Grid>
          </Grid>
          <Grid container justify="flex-end" spacing={2}>
            <Grid item>
              {state.buttons}
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Collapse>
  )



}
import Projeto, { ProjetoVO } from "./Projeto";
import ISODateTime from "./types/ISODateTime";
import { formatMoeda } from "../views/ViewUtils";
import Documento, { DocumentoVO } from "./Documento";
import { SituacaoInvestimento } from "./enums/SituacaoInvestimento";
import ContaUsuario, { ContaUsuarioVO } from "./ContaUsuario";
import { MotivoCancelamentoInvestimento } from "./enums/MotivoCancelamentoInvestimento";

export interface InvestimentoVO {
  coInvestimento: number;
  projeto: ProjetoVO;
  contaUsuario: ContaUsuarioVO;
  dhReserva: string;
  dhAprovacao: string  | undefined;
  usuarioAprovacao: ContaUsuarioVO | undefined;
  dhCancelamento: string  | undefined;
  usuarioCancelamento: ContaUsuarioVO | undefined;
  motivoCancelamentoInvestimento: MotivoCancelamentoInvestimento | undefined;
  qtCota: number;
  vrInvestimento: number;
  vrAtualizado: number;
  situacaoInvestimento: SituacaoInvestimento;
  documentoContrato: DocumentoVO | undefined;
  documentoComprovanteDeposito: DocumentoVO | undefined;
}


export default class Investimento {

    public coInvestimento: number | undefined;
    public projeto: Projeto | undefined;
    public contaUsuario: ContaUsuario | undefined;
    public dhReserva: ISODateTime | undefined;
    public dhAprovacao: ISODateTime | undefined;
    public usuarioAprovacao: ContaUsuario | undefined;
    public dhCancelamento: ISODateTime | undefined;
    public usuarioCancelamento: ContaUsuario | undefined;
    public motivoCancelamentoInvestimento: MotivoCancelamentoInvestimento | undefined;
    public qtCota: number | undefined;
    public vrInvestimento: number | undefined;
    public vrAtualizado: number | undefined;
    public situacaoInvestimento: SituacaoInvestimento | undefined;
    public documentoContrato: Documento | undefined;
    public documentoComprovanteDeposito: Documento | undefined;
    constructor(obj: object | null = null) {
        if (obj) {
            Object.assign(this, obj);
            if (this.projeto) this.projeto = new Projeto(this.projeto);
            if (this.contaUsuario) this.contaUsuario = new ContaUsuario(this.contaUsuario);
            if (this.dhReserva) this.dhReserva = new ISODateTime(String(this.dhReserva))
            if (this.dhAprovacao) this.dhAprovacao = new ISODateTime(String(this.dhAprovacao))
            if (this.usuarioAprovacao) this.usuarioAprovacao = new ContaUsuario(this.usuarioAprovacao);
            if (this.dhCancelamento) this.dhCancelamento = new ISODateTime(String(this.dhCancelamento))
            if (this.usuarioCancelamento) this.usuarioCancelamento = new ContaUsuario(this.usuarioCancelamento);
            if (this.documentoContrato) this.documentoContrato = new Documento(this.documentoContrato);
            if (this.documentoComprovanteDeposito) this.documentoComprovanteDeposito = new Documento(this.documentoComprovanteDeposito);
            Object.seal(this); // Não permite alterar estrutura da classe
        }
    }

    get vrInvestimentoFormatado() {
        return this.vrInvestimento ? formatMoeda(this.vrInvestimento) : '';
    }

    get vrAtualizadoFormatado() {
        return this.vrAtualizado ? formatMoeda(this.vrAtualizado) : '';
    }

    get vrRendimentoFormatado() {
        return (this.vrInvestimento && this.vrAtualizado) ? formatMoeda(this.vrAtualizado-this.vrInvestimento) : '';
    }

    get dhReservaFormatado() {
        return this.dhReserva ? this.dhReserva.toFormat('dd/MM/yyyy HH:mm') : ''
    }

    get dhAprovacaoFormatado() {
        return this.dhAprovacao ? this.dhAprovacao.toFormat('dd/MM/yyyy HH:mm') : ''
    }

    get dhCancelamentoFormatado() {
        return this.dhCancelamento ? this.dhCancelamento.toFormat('dd/MM/yyyy HH:mm') : ''
    }

    get pcRentabilidadeFormatado() {
        if (this.vrAtualizado && this.vrInvestimento) {
            return (((this.vrAtualizado - this.vrInvestimento) / this.vrInvestimento) * 100).toFixed(2).replace('.', ',');
        } else {
            return '';
        }
    }


}
import MomentUtils from '@date-io/moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import { responsiveFontSizes } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import moment, { Moment } from 'moment';
import 'moment/locale/pt-br';
import { SnackbarProvider } from 'notistack';
import React, { Fragment } from 'react';
import { hot } from 'react-hot-loader/root';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import { DialogServiceProvider } from './components/dialog/PageServiceProvider';
import { PageRouter } from './pages/PageRouter';
import useGlobalStyles from './styles/GlobalStyles';
import theme from './styles/theme';
import {enablePatches} from "immer"
require('twix');

// Immer, enable JSON Patch support
enablePatches();

// Set Locale for moment
moment.locale('pt-br');

// Set Custom Configs for Material UI Pickers
class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText = (moment: Moment) => moment.format("LL")
}

// Configuration for react-intl use outside React Components
const cache = createIntlCache()

export const intl = createIntl({
  locale: 'pt-BR',
  messages: {},
  timeZone: "America/Sao_Paulo"
}, cache)


const App = () => {

  useGlobalStyles(); // Load Global Styles
  
  return (
    <Fragment>
      <CssBaseline />
        <ThemeProvider theme={responsiveFontSizes(theme)}>
          <SnackbarProvider maxSnack={1} >
            <DialogServiceProvider>
              <MuiPickersUtilsProvider utils={LocalizedUtils}>
                <RawIntlProvider value={intl}>
                  <PageRouter />
                </RawIntlProvider>
              </MuiPickersUtilsProvider>
            </DialogServiceProvider>
          </SnackbarProvider>
        </ThemeProvider>
    </Fragment>
  )

}

export default hot(App);
import {  Defs, DotsItem } from '@nivo/core';
import { CustomLayer, CustomLayerProps, Datum, DatumValue, Layer, ResponsiveLine, Serie } from '@nivo/line';
import { TableTooltip } from '@nivo/tooltip';
import { area } from 'd3-shape';
import React, { CSSProperties } from 'react';
import { ValorCotaVO } from '../../../../../../models/ValorCota';
import { differenceInMonthsFractional, formatPorcentagem } from '../../../../../ViewUtils';
import moment from 'moment';


interface Props {
  rentabilidadeInvestimentoFixa?: number;
  rentabilidadeInvestimentoMin?: number;
  rentabilidadeInvestimentoMax?: number;
  rentabilidadeGarantida?: number;
  rentabilidadeCDI: number;
  rentabilidadePoupanca: number;
  prazoMeses: number;
  dtInicioTitulo: string;
  dtVencimentoTitulo: string;
  valorCotaEmitida: number;
  qtCotas: number;
  valoresCota: ValorCotaVO[];
}


export default function GraficoHistoricoPerformance(props: Props) {

  const dtUltimaAtualizacaoCota = moment(props.valoresCota[0].dtInicio).toDate();
  const prazoMesesAteUltimaAtualizacao = differenceInMonthsFractional(dtUltimaAtualizacaoCota, moment(props.dtInicioTitulo).toDate());

  const projetoRealTotal = props.valoresCota[0].vrCota || 0;
  const projetoRealROI = (projetoRealTotal / props.valorCotaEmitida) - 1;
  const projetoRealRentabilidade = projetoRealROI > 0 ? Math.pow(1 + projetoRealROI, 12 / prazoMesesAteUltimaAtualizacao) - 1 : 0;
  const projetoRealId = `Real (${formatPorcentagem(projetoRealRentabilidade)}% a.a.)`;
  const projetoRealCapitalizacao = props.valoresCota.map(cota => { return { x: moment(cota.dtInicio).toDate(), y: (cota.vrCota ? cota.vrCota : 0) * props.qtCotas } }).reverse();
  const projetoRealColor = "#EA5911FF";
  const projetoRealSerie = { id: projetoRealId, data: projetoRealCapitalizacao, color: projetoRealColor };

  const calcularCapitalizacao = (valorInvestimento: number, prazo: number, jurosAnual: number): Datum[] => {
    const jurosMensal = Math.pow(1 + jurosAnual, 1 / 12) - 1

    const capitalizacao = new Array<Datum>();

    // Calcula capitalização somente nos dias em que houve atualização de cota
    projetoRealCapitalizacao.forEach(cota => {
      // Montante = Capital * (1 + taxa) ^ prazo
      const prazoTotal = differenceInMonthsFractional(cota.x, moment(props.dtInicioTitulo).toDate());

      const montante = (valorInvestimento * Math.pow((1.0 + jurosMensal), prazoTotal)).toFixed(2);
      capitalizacao.push({
        x: cota.x,
        y: montante
      });
    });

    if (!moment(dtUltimaAtualizacaoCota).isSame(props.dtVencimentoTitulo)) {
      // Titulo ainda em andamento, então calcula valor final para preencher gráfico
      capitalizacao.push({
        x: moment(props.dtInicioTitulo).add(prazo,'months').toDate(),
        y: (valorInvestimento * Math.pow((1.0 + jurosMensal), prazo)).toFixed(2)
      });
    }
    return capitalizacao;
  }

  const poupancaId = `Poupança (${props.rentabilidadePoupanca.toFixed(4).replace('.', '').replace(/^(0*)(\d+)(\d{2})$/g, '$2,$3')}% a.a.)`;
  const poupancaCapitalizacao = calcularCapitalizacao(props.valorCotaEmitida * props.qtCotas, props.prazoMeses, props.rentabilidadePoupanca);
  const poupancaColor = "#9E9AC888";
  const poupancaSerie = { id: poupancaId, data: poupancaCapitalizacao, color: poupancaColor };

  const cdiId = `CDI (${props.rentabilidadeCDI.toFixed(4).replace('.', '').replace(/^(0*)(\d+)(\d{2})$/g, '$2,$3')}% a.a.)`;
  const cdiCapitalizacao = calcularCapitalizacao(props.valorCotaEmitida * props.qtCotas, props.prazoMeses, props.rentabilidadeCDI);
  const cdiColor = "#7F7F7F88";
  const cdiSerie = { id: cdiId, data: cdiCapitalizacao, color: cdiColor };

  const projetoFixaId = props.rentabilidadeInvestimentoFixa ? `Estimada (${props.rentabilidadeInvestimentoFixa.toFixed(4).replace('.', '').replace(/^(0*)(\d+)(\d{2})$/g, '$2,$3')}% a.a.)` : "";
  const projetoFixaCapitalizacao = props.rentabilidadeInvestimentoFixa ? calcularCapitalizacao(props.valorCotaEmitida * props.qtCotas, props.prazoMeses, props.rentabilidadeInvestimentoFixa) : [];
  const projetoFixaColor = "#EA591188";
  const projetoFixaSerie = { id: projetoFixaId, data: projetoFixaCapitalizacao, color: projetoFixaColor };

  const projetoMinId = props.rentabilidadeInvestimentoMin ? `Mínima (${props.rentabilidadeInvestimentoMin.toFixed(4).replace('.', '').replace(/^(0*)(\d+)(\d{2})$/g, '$2,$3')}% a.a.)` : "";
  const projetoMinCapitalizacao = props.rentabilidadeInvestimentoMin ? calcularCapitalizacao(props.valorCotaEmitida * props.qtCotas, props.prazoMeses, props.rentabilidadeInvestimentoMin) : [];
  const projetoMinColor = "#EA591188";
  const projetoMinSerie = { id: projetoMinId, data: projetoMinCapitalizacao, color: projetoMinColor };

  const projetoMaxId = props.rentabilidadeInvestimentoMax ? `Máxima (${props.rentabilidadeInvestimentoMax.toFixed(4).replace('.', '').replace(/^(0*)(\d+)(\d{2})$/g, '$2,$3')}% a.a.)` : "";
  const projetoMaxCapitalizacao = props.rentabilidadeInvestimentoMax ? calcularCapitalizacao(props.valorCotaEmitida * props.qtCotas, props.prazoMeses, props.rentabilidadeInvestimentoMax) : [];
  const projetoMaxColor = "#EA591188";
  const projetoMaxSerie = { id: projetoMaxId, data: projetoMaxCapitalizacao, color: projetoMaxColor };


  const rentabilidadeGarantidaId = props.rentabilidadeGarantida ? `Garantida (${props.rentabilidadeGarantida.toFixed(4).replace('.', '').replace(/^(0*)(\d+)(\d{2})$/g, '$2,$3')}% a.a.)` : "";
  const rentabilidadeGarantidaCapitalizacao = props.rentabilidadeGarantida ? calcularCapitalizacao(props.valorCotaEmitida * props.qtCotas, props.prazoMeses, props.rentabilidadeGarantida) : [];
  const rentabilidadeGarantidaColor = "#2CA02C88";
  const rentabilidadeGarantidaSerie = { id: rentabilidadeGarantidaId, data: rentabilidadeGarantidaCapitalizacao, color: rentabilidadeGarantidaColor }




  const chartData: Serie[] = [
    poupancaSerie,
    cdiSerie,
    ...(props.rentabilidadeGarantida ? [rentabilidadeGarantidaSerie] : []),
    ...(props.rentabilidadeInvestimentoFixa ? [projetoFixaSerie] : []),
    ...(props.rentabilidadeInvestimentoMin ? [projetoMinSerie] : []),
    ...(props.rentabilidadeInvestimentoMax ? [projetoMaxSerie] : []),
    projetoRealSerie
  ];

  const colors = [
    poupancaColor,
    cdiColor,
    ...(props.rentabilidadeGarantida ? [rentabilidadeGarantidaColor] : []),
    ...(props.rentabilidadeInvestimentoFixa ? [projetoFixaColor] : []),
    ...(props.rentabilidadeInvestimentoMin ? [projetoMinColor] : []),
    ...(props.rentabilidadeInvestimentoMax ? [projetoMaxColor] : []),
    projetoRealColor
  ];

  const styleById = (id: string | number): CSSProperties => {
    switch (id) {
      case cdiId:
        return {
          strokeDasharray: '12, 6',
          strokeWidth: 2
        }
      case poupancaId:
        return {
          strokeDasharray: '8, 6',
          strokeWidth: 2
        }
      case rentabilidadeGarantidaId:
        return {
          strokeDasharray: '6, 6',
          strokeWidth: 2
        }
      case projetoFixaId:
      case projetoMinId:
      case projetoMaxId:
        return {
          strokeWidth: 2,
          strokeDasharray: '4, 6',
        }
      case projetoRealId:
        return {
          strokeWidth: 2,
        }
      default:
        return {
          strokeWidth: 1
        }
    }

  }

  const Points = ({ points }: CustomLayerProps) => {

    const filteredPoints = points.filter(point => point.serieId == projetoRealId);

    const mappedPoints = filteredPoints.map(point => {
      const mappedPoint = {
        id: point.id,
        x: point.x,
        y: point.y,
        datum: point.data,
        fill: point.color,
        stroke: point.borderColor,
      }
      return mappedPoint
    })

    return (
      <g>
        {mappedPoints.map(point => (
          <DotsItem
            key={point.id}
            x={point.x}
            y={point.y}
            datum={point.datum}
            size={10}
            color={point.fill}
            borderWidth={2}
            borderColor={point.stroke}
            label={undefined}
            labelYOffset={-12}
            theme={
              {

                dots: {
                  text: {
                    fill: '#bbb',
                    fontSize: 12,
                  },
                },

              }
            }
          />
        ))}
      </g>
    )
  }


  const DashedLine = ({ /*series,*/ lineGenerator, xScale, yScale }: CustomLayerProps) => {
    return chartData.map((serie) => (
      <path
        key={serie.id}
        d={lineGenerator(
          serie.data.map(d => ({
            x: xScale(d.x as DatumValue),
            y: yScale(d.y as DatumValue),
          }))
        )}
        fill="none"
        stroke={serie.color}
        style={styleById(serie.id)}
      />
    ))
  }

  const AreaRentabilidadeMinMaxLayer = ({ series, xScale, yScale }: CustomLayerProps) => {
    const areaGenerator = area<Datum>()
      .x(d => xScale(d.data.x))
      .y0((d, index) => yScale(projetoMaxSerie.data[index].y as DatumValue))
      .y1((d, index) => yScale(projetoMinSerie.data[index].y as DatumValue))

    return (
      <>
        <Defs
          defs={[
            {
              id: 'pattern',
              type: 'patternLines',
              background: 'transparent',
              color: '#EA591188',
              lineWidth: 1,
              rotation: -45,
            },
          ]}
        />
        <path
          d={areaGenerator(series[0].data) || undefined}
          fill="url(#pattern)"
          fillOpacity={0.6}
          stroke="transparent"
          strokeWidth={2}
        />
      </>
    )
  }


  return (

    <ResponsiveLine
      data={chartData}
      margin={{ top: 0, right: 10, bottom: 70, left: 10 }}
      xScale={{
        type: 'time',
        //format: '%Y-%m-%d',
        precision: 'day'
      }}
      //xFormat="time:%Y-%m-%d"
      yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
      yFormat={value =>
        `R$ ${Number(value).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
        })}`
      }
      axisTop={null}
      axisRight={null}

      axisBottom={{
        format: "%d/%m/%Y",
        //format: v => `${format(v as Date,'MMM/yyyy',{locale: ptBR})}`,
        tickValues: 12,
        tickRotation: -85,
      }}
      axisLeft={null}
      enableGridX={false}
      enableGridY={false}
      colors={colors}
      isInteractive={true}
      useMesh={false}
      //enablePoints={true}
      //pointSize={10}
      //pointColor={{ from: 'color', modifiers: [] }}
      //pointBorderWidth={2}
      //pointBorderColor={{ from: 'serieColor' }}
      //pointLabel="y"
      //pointLabelYOffset={-12}
      enableSlices="x"
      layers={[
        'grid',
        'markers',
        'axes',
        ...(props.rentabilidadeInvestimentoMin && props.rentabilidadeInvestimentoMax) ? [AreaRentabilidadeMinMaxLayer as CustomLayer] : [],
        DashedLine as CustomLayer,
        'crosshair' as Layer,
        Points as CustomLayer,
        'slices',
        'mesh',
        'legends'
      ]}

      sliceTooltip={({ slice }) => {
        const dia = slice.points[0].data.x as Date;
        const title = moment(dia).format("L");
        return (
          <TableTooltip
            title={<strong style={{ textAlign: 'center' }}>{title}</strong>}
            rows={slice.points.map(point => [
              <span key="chip" style={{ display: 'block', width: '12px', height: '12px', background: point.serieColor }} />,
              point.serieId,
              <strong key="value">{point.data.yFormatted} | {formatPorcentagem((point.data.y as number / (props.qtCotas * props.valorCotaEmitida)) - 1)}%</strong>,
            ])}
          />
        )
      }}

      markers={(moment(props.dtInicioTitulo).isSameOrBefore(moment()) && moment(props.dtVencimentoTitulo).isSameOrAfter(moment()) ) ? [
        {
          axis: "x",
          value: moment().toDate(),
          lineStyle: { stroke: '#b0413e88', strokeWidth: 1 },
          legend: moment().format("DD/MM/YYYY"),
          textStyle: { fontSize: 10 }
        },
      ] : undefined}


      legends={undefined /*[
                  {
                    anchor: 'top-left',
                    direction: 'column',
                    justify: false,
                    translateX: 0,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 120,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemBackground: 'rgba(0, 0, 0, .03)',
                          itemOpacity: 1
                        }
                      }
                    ]
                  }
                ]*/}

    />

  )
}
import { format, parseISO } from 'date-fns';

export default class ISODateTime{

    private localDateTime: Date;

    constructor(value: string|Date){
        if(typeof value === 'string'){
            this.localDateTime = parseISO(value);
        } else {
            this.localDateTime = value;
        }
    }

    get date(){
        return this.localDateTime;
    }

    toFormat(formatString: string){
        return format(this.localDateTime,formatString);
    }

    toString(): string|undefined{
        return format(this.localDateTime,"yyyy-MM-dd'T'HH:mm:ss.SSS");
    }

    toJSON(): string|undefined{
        return format(this.localDateTime,"yyyy-MM-dd'T'HH:mm:ss.SSS");
    }

}
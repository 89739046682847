import { Card, CardContent, CardHeader, createStyles, Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { ValorCotaVO } from '../../../../../../models/ValorCota';
import { differenceInMonthsFractional, formatMoeda, formatPorcentagem } from '../../../../../ViewUtils';
import GraficoHistoricoPerformance from './GraficoHistoricoPerformance';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) => createStyles({
  chartContainer: {
    marginTop: theme.spacing(1),
    position: 'relative',
    height: 200,
    [theme.breakpoints.up('sm')]: {
      height: 400
    },
  },
  labelText: {
    color: 'rgba(0,0,0,.5)'
  },
  projeto: {
    color: "#EA5911FF"
  },
  cdi: {
    color: "#7F7F7FFF"
  },
  poupanca: {
    color: "#9E9AC8FF"
  },
  minimoGarantido: {
    color: "#2CA02CFF"
  }

}), { name: 'HistoricoPerformance' });


interface Props {
  nomeInvestimento: string;
  rentabilidadeInvestimentoFixa?: number;
  rentabilidadeInvestimentoMin?: number;
  rentabilidadeInvestimentoMax?: number;
  rentabilidadeGarantida?: number;
  rentabilidadeCDI: number;
  rentabilidadePoupanca: number;
  prazoMeses: number;
  dtInicioTitulo: string;
  dtVencimentoTitulo: string;
  valoresCota: ValorCotaVO[];
  valorCotaEmitida: number;
}

interface State {
  valorInvestimento: string;
}

export default function HistoricoPerformance(props: Props) {

  const classes = useStyles();

  const calcularTotal = (valorInvestimento: number, prazo: number, jurosAnual: number): number => {
    const jurosMensal = Math.pow(1 + jurosAnual, 1 / 12) - 1
    // Montante = Capital * (1 + taxa) ^ prazo
    return Number.parseFloat((valorInvestimento * Math.pow((1.0 + jurosMensal), prazo)).toFixed(2));
  }

  const dtUltimaAtualizacaoCota = moment(props.valoresCota[0].dtInicio).toDate();
  const prazoMesesAteUltimaAtualizacao = differenceInMonthsFractional(dtUltimaAtualizacaoCota, moment(props.dtInicioTitulo).toDate());

  const projetoRealTotal = props.valoresCota[0].vrCota || 0;
  const projetoRealROI = (projetoRealTotal / props.valorCotaEmitida) - 1;
  const projetoRealRentabilidade = projetoRealROI>0?Math.pow(1 + projetoRealROI, 12 / prazoMesesAteUltimaAtualizacao) - 1:0;
  const projetoRealId = `Real (${formatPorcentagem(projetoRealRentabilidade)}% a.a.)`;

  const poupancaId = `Poupança (${props.rentabilidadePoupanca.toFixed(4).replace('.', '').replace(/^(0*)(\d+)(\d{2})$/g, '$2,$3')}% a.a.)`;
  const poupancaTotal = calcularTotal(props.valorCotaEmitida, prazoMesesAteUltimaAtualizacao, props.rentabilidadePoupanca);
  const poupancaROI = (poupancaTotal / props.valorCotaEmitida) - 1;

  const cdiId = `CDI (${props.rentabilidadeCDI.toFixed(4).replace('.', '').replace(/^(0*)(\d+)(\d{2})$/g, '$2,$3')}% a.a.)`;
  const cdiTotal = calcularTotal(props.valorCotaEmitida, prazoMesesAteUltimaAtualizacao, props.rentabilidadeCDI);
  const cdiROI = (cdiTotal / props.valorCotaEmitida) - 1;

  const projetoFixaId = props.rentabilidadeInvestimentoFixa ? `Estimada (${props.rentabilidadeInvestimentoFixa.toFixed(4).replace('.', '').replace(/^(0*)(\d+)(\d{2})$/g, '$2,$3')}% a.a.)` : "";
  const projetoFixaTotal = props.rentabilidadeInvestimentoFixa ? calcularTotal(props.valorCotaEmitida, prazoMesesAteUltimaAtualizacao, props.rentabilidadeInvestimentoFixa) : 0;
  const projetoFixaROI = (projetoFixaTotal / props.valorCotaEmitida) - 1;

  const projetoMinId = props.rentabilidadeInvestimentoMin ? `Mínima (${props.rentabilidadeInvestimentoMin.toFixed(4).replace('.', '').replace(/^(0*)(\d+)(\d{2})$/g, '$2,$3')}% a.a.)` : "";
  const projetoMinTotal = props.rentabilidadeInvestimentoMin ? calcularTotal(props.valorCotaEmitida, prazoMesesAteUltimaAtualizacao, props.rentabilidadeInvestimentoMin) : 0;
  const projetoMinROI = (projetoMinTotal / props.valorCotaEmitida) - 1;

  const projetoMaxId = props.rentabilidadeInvestimentoMax ? `Máxima (${props.rentabilidadeInvestimentoMax.toFixed(4).replace('.', '').replace(/^(0*)(\d+)(\d{2})$/g, '$2,$3')}% a.a.)` : "";
  const projetoMaxTotal = props.rentabilidadeInvestimentoMax ? calcularTotal(props.valorCotaEmitida, prazoMesesAteUltimaAtualizacao, props.rentabilidadeInvestimentoMax) : 0;
  const projetoMaxROI = (projetoMaxTotal / props.valorCotaEmitida) - 1;

  const rentabilidadeGarantidaId = props.rentabilidadeGarantida ? `Garantida (${props.rentabilidadeGarantida.toFixed(4).replace('.', '').replace(/^(0*)(\d+)(\d{2})$/g, '$2,$3')}% a.a.)` : "";
  const rentabilidadeGarantidaTotal = props.rentabilidadeGarantida ? calcularTotal(props.valorCotaEmitida, prazoMesesAteUltimaAtualizacao, props.rentabilidadeGarantida) : 0;
  const rentabilidadeGarantidaROI = (rentabilidadeGarantidaTotal / props.valorCotaEmitida) - 1;

  return (
    <Card>
      <CardHeader
        title="Histórico de Performance"
      />
      <Divider />
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={4}>
            <Grid container direction="column" spacing={2}>

              <Grid item>
                <Grid container justify="space-between">
                  <Typography variant="subtitle2" color="inherit" component="p" className={classes.labelText}>Rentabilidades</Typography>
                  <Typography variant="subtitle2" color="inherit" component="p" className={classes.labelText}></Typography>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container justify="space-between">
                  <Typography variant="subtitle2" color="inherit" component="p" className={classes.projeto}>{projetoRealId}:</Typography>
                  <Typography variant="subtitle2" color="inherit" component="p" className={classes.projeto}>R$ {formatMoeda(projetoRealTotal)} ({formatPorcentagem(projetoRealROI)}%)</Typography>
                </Grid>
              </Grid>

              {props.rentabilidadeInvestimentoFixa &&
                <Grid item>
                  <Grid container justify="space-between">
                    <Typography variant="subtitle2" color="inherit" component="p" className={classes.projeto}>{projetoFixaId}:</Typography>
                    <Typography variant="subtitle2" color="inherit" component="p" className={classes.projeto}>R$ {formatMoeda(projetoFixaTotal)} ({formatPorcentagem(projetoFixaROI)}%)</Typography>
                  </Grid>
                </Grid>
              }

              {props.rentabilidadeInvestimentoMax &&
                <Grid item>
                  <Grid container justify="space-between">
                    <Typography variant="subtitle2" color="inherit" component="p" className={classes.projeto}>{projetoMaxId}:</Typography>
                    <Typography variant="subtitle2" color="inherit" component="p" className={classes.projeto}>R$ {formatMoeda(projetoMaxTotal)} ({formatPorcentagem(projetoMaxROI)}%)</Typography>
                  </Grid>
                </Grid>
              }
              {props.rentabilidadeInvestimentoMin &&
                <Grid item>
                  <Grid container justify="space-between">
                    <Typography variant="subtitle2" color="inherit" component="p" className={classes.projeto}>{projetoMinId}:</Typography>
                    <Typography variant="subtitle2" color="inherit" component="p" className={classes.projeto}>R$ {formatMoeda(projetoMinTotal)} ({formatPorcentagem(projetoMinROI)}%)</Typography>
                  </Grid>
                </Grid>
              }
              {props.rentabilidadeGarantida &&
                <Grid item>
                  <Grid container justify="space-between">
                    <Typography variant="subtitle2" color="inherit" component="p" className={classes.minimoGarantido}>{rentabilidadeGarantidaId}:</Typography>
                    <Typography variant="subtitle2" color="inherit" component="p" className={classes.minimoGarantido}>R$ {formatMoeda(rentabilidadeGarantidaTotal)} ({formatPorcentagem(rentabilidadeGarantidaROI)}%)</Typography>
                  </Grid>
                </Grid>
              }
              <Grid item>
                <Grid container justify="space-between">
                  <Typography variant="subtitle2" color="inherit" component="p" className={classes.cdi}>{cdiId}:</Typography>
                  <Typography variant="subtitle2" color="inherit" component="p" className={classes.cdi}>R$ {formatMoeda(cdiTotal)} ({formatPorcentagem(cdiROI)}%)</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container justify="space-between">
                  <Typography variant="subtitle2" color="inherit" component="p" className={classes.poupanca}>{poupancaId}:</Typography>
                  <Typography variant="subtitle2" color="inherit" component="p" className={classes.poupanca}>R$ {formatMoeda(poupancaTotal)} ({formatPorcentagem(poupancaROI)}%)</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container justify="space-between">
                  <Typography variant="caption" color="inherit" component="p" className={classes.labelText}></Typography>
                  <Typography variant="caption" color="inherit" component="p" className={classes.labelText}>* Última atualização de cota em {moment(dtUltimaAtualizacaoCota).format("L")}</Typography>
                </Grid>
              </Grid>
            </Grid>


          </Grid>
          <Grid item className={classes.chartContainer} xs={12} md={8}>
            <GraficoHistoricoPerformance
              qtCotas={1}
              valoresCota={props.valoresCota}
              dtInicioTitulo={props.dtInicioTitulo}
              dtVencimentoTitulo={props.dtVencimentoTitulo}
              prazoMeses={props.prazoMeses}
              rentabilidadeCDI={props.rentabilidadeCDI}
              rentabilidadePoupanca={props.rentabilidadePoupanca}
              rentabilidadeInvestimentoFixa={props.rentabilidadeInvestimentoFixa}
              rentabilidadeInvestimentoMin={props.rentabilidadeInvestimentoMin}
              rentabilidadeInvestimentoMax={props.rentabilidadeInvestimentoMax}
              rentabilidadeGarantida={props.rentabilidadeGarantida}
              valorCotaEmitida={props.valorCotaEmitida}
            />
          </Grid>
        </Grid>



      </CardContent>

    </Card>


  )
}
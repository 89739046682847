import { parse } from 'date-fns';
import { formatMoeda, formatPorcentagem } from '../views/ViewUtils';
import CaptacaoProjeto, { CaptacaoProjetoVO } from './CaptacaoProjeto';
import Documento, { DocumentoVO } from './Documento';
import Empresa, { EmpresaVO } from './Empresa';
import Endereco, { EnderecoVO } from "./Endereco";
import { SituacaoCaptacao } from './enums/SituacaoCaptacao';
import { TipoPagamentoAmortizacao } from './enums/TipoPagamentoAmortizacao';
import { TipoPagamentoJuros } from './enums/TipoPagamentoJuros';
import { TipoRentabilidadeGarantida } from './enums/TipoRentabilidadeGarantida';
import { TipoRentabilidadeProjetada } from './enums/TipoRentabilidadeProjetada';
import Imagem, { ImagemVO } from './Imagem';
import Investimento, { InvestimentoVO } from './Investimento';
import PerguntaForumProjeto, { PerguntaForumProjetoVO } from './PerguntaForumProjeto';
import TaxaJuros, { TaxaJurosVO } from './TaxaJuros';
import ISODate from './types/ISODate';
import ValorCota, { ValorCotaVO } from './ValorCota';


export interface ProjetoVO {

  nuProjeto: number;
  noPermalink: string;
  noProjeto: string;
  deProjeto: string;
  empresa: EmpresaVO;
  endereco: EnderecoVO;
  dtInicioCaptacao: string;
  dtFimCaptacao: string;
  dtInicioDepositos: string;
  dtFimDepositos: string;
  dtInicioTitulo: string;
  dtVencimentoTitulo: string;
  qtPrazoMesesTitulo: number;
  vrAlvoCaptacao: number;
  vrCotaEmitida: number;
  vrCotaAtualizada: number;
  qtCota: number;
  tipoRentabilidadeProjetada: TipoRentabilidadeProjetada;
  pcRentabilidadeProjetadaMinima: number | undefined | null;
  pcRentabilidadeProjetadaMinimaAnual: number | undefined | null;
  pcRentabilidadeProjetadaMaxima: number | undefined | null;
  pcRentabilidadeProjetadaMaximaAnual: number | undefined | null;
  pcRentabilidadeProjetadaFixa: number | undefined | null;
  pcRentabilidadeProjetadaFixaAnual: number | undefined | null;
  tipoRentabilidadeGarantida: TipoRentabilidadeGarantida;
  pcRentabilidadeGarantidaCdi: number | undefined | null;
  pcRentabilidadeGarantidaFixaAnual: number | undefined | null;
  imagemCapa: ImagemVO;
  documentoProspecto: DocumentoVO;
  documentoModeloContrato: DocumentoVO;
  icSituacaoCaptacao: SituacaoCaptacao;
  tipoPagamentoAmortizacao: TipoPagamentoAmortizacao;
  tipoPagamentoJuros: TipoPagamentoJuros;
  captacaoProjeto: CaptacaoProjetoVO;
  investimentos: InvestimentoVO[];
  galeriaFotos: ImagemVO[];
  taxaJuros: TaxaJurosVO;
  valoresCota: ValorCotaVO[];
  perguntasForum: PerguntaForumProjetoVO[];
}

export default class Projeto {
    public nuProjeto: number | undefined;
    public noPermalink: string | undefined;
    public noProjeto: string | undefined;
    public deProjeto: string | undefined;
    public empresa: Empresa | undefined;
    public endereco: Endereco | undefined;
    public dtInicioCaptacao: ISODate | undefined;
    public dtFimCaptacao: ISODate | undefined;
    public dtInicioDepositos: ISODate | undefined;
    public dtFimDepositos: ISODate | undefined;
    public dtInicioTitulo: ISODate | undefined;
    public dtVencimentoTitulo: ISODate | undefined;
    public qtPrazoMesesTitulo: number | undefined;
    public vrAlvoCaptacao: number | undefined;
    public vrCotaEmitida: number | undefined;
    public vrCotaAtualizada: number | undefined;
    public qtCota: number | undefined;
    public tipoRentabilidadeProjetada: TipoRentabilidadeProjetada | undefined;
    public pcRentabilidadeProjetadaMinima: number | undefined | null;
    public pcRentabilidadeProjetadaMinimaAnual: number | undefined | null;
    public pcRentabilidadeProjetadaMaxima: number | undefined | null;
    public pcRentabilidadeProjetadaMaximaAnual: number | undefined | null;
    public pcRentabilidadeProjetadaFixa: number | undefined | null;
    public pcRentabilidadeProjetadaFixaAnual: number | undefined | null;
    public tipoRentabilidadeGarantida: TipoRentabilidadeGarantida | undefined;
    public pcRentabilidadeGarantidaCdi: number | undefined | null;
    public pcRentabilidadeGarantidaFixaAnual: number | undefined | null;
    public imagemCapa: Imagem | undefined;
    public documentoProspecto: Documento | undefined;
    public documentoModeloContrato: Documento | undefined;
    public icSituacaoCaptacao: SituacaoCaptacao | undefined;
    public tipoPagamentoAmortizacao: TipoPagamentoAmortizacao | undefined;
    public tipoPagamentoJuros: TipoPagamentoJuros | undefined;
    public captacaoProjeto: CaptacaoProjeto | undefined;
    public investimentos: Investimento[] | undefined;
    public galeriaFotos: Imagem[] | undefined;
    public taxaJuros: TaxaJuros | undefined;
    public valoresCota: ValorCota[] | undefined;
    public perguntasForum: PerguntaForumProjeto[] | undefined;

    constructor(obj: object | null = null) {
        if (obj) {
            Object.assign(this, obj);
            if (this.empresa) this.empresa = new Empresa(this.empresa);
            if (this.endereco) this.endereco = new Endereco(this.endereco);
            if (this.imagemCapa) this.imagemCapa = new Imagem(this.imagemCapa);
            if (this.documentoProspecto) this.documentoProspecto = new Documento(this.documentoProspecto);
            if (this.documentoModeloContrato) this.documentoModeloContrato = new Documento(this.documentoModeloContrato);
            if (this.dtInicioCaptacao) this.dtInicioCaptacao = new ISODate(String(this.dtInicioCaptacao))
            if (this.dtFimCaptacao) this.dtFimCaptacao = new ISODate(String(this.dtFimCaptacao))
            if (this.dtInicioDepositos) this.dtInicioDepositos = new ISODate(String(this.dtInicioDepositos))
            if (this.dtFimDepositos) this.dtFimDepositos = new ISODate(String(this.dtFimDepositos))
            if (this.dtInicioTitulo) this.dtInicioTitulo = new ISODate(String(this.dtInicioTitulo))
            if (this.dtVencimentoTitulo) this.dtVencimentoTitulo = new ISODate(String(this.dtVencimentoTitulo))
            if (this.captacaoProjeto) this.captacaoProjeto = new CaptacaoProjeto(this.captacaoProjeto);
            if (this.investimentos) this.investimentos = this.investimentos.map(i => new Investimento(i));
            if (this.galeriaFotos) this.galeriaFotos = this.galeriaFotos.map(i => new Imagem(i));
            if (this.taxaJuros) this.taxaJuros = new TaxaJuros(this.taxaJuros);
            if (this.valoresCota) this.valoresCota = this.valoresCota.map(i => new ValorCota(i));
            if (this.perguntasForum) this.perguntasForum = this.perguntasForum.map(i => new PerguntaForumProjeto(i));
        }
        Object.seal(this); // Não permite alterar estrutura da classe
    }

    set dtInicioCaptacaoFormatado(value) {
        this.dtInicioCaptacao = value ? new ISODate(parse(value, 'dd/MM/yyyy', new Date())) : undefined;
    }

    get dtInicioCaptacaoFormatado() {
        return this.dtInicioCaptacao ? this.dtInicioCaptacao.toFormat('dd/MM/yyyy') : ''
    }

    set dtFimCaptacaoFormatado(value) {
        this.dtFimCaptacao = value ? new ISODate(parse(value, 'dd/MM/yyyy', new Date())) : undefined;
    }

    get dtFimCaptacaoFormatado() {
        return this.dtFimCaptacao ? this.dtFimCaptacao.toFormat('dd/MM/yyyy') : ''
    }

    get dtInicioDepositosFormatado() {
        return this.dtInicioDepositos ? this.dtInicioDepositos.toFormat('dd/MM/yyyy') : ''
    }

    set dtInicioDepositosFormatado(value) {
        this.dtInicioDepositos = value ? new ISODate(parse(value, 'dd/MM/yyyy', new Date())) : undefined;
    }

    get dtFimDepositosFormatado() {
        return this.dtFimDepositos ? this.dtFimDepositos.toFormat('dd/MM/yyyy') : ''
    }

    set dtFimDepositosFormatado(value) {
        this.dtFimDepositos = value ? new ISODate(parse(value, 'dd/MM/yyyy', new Date())) : undefined;
    }

    get dtInicioTituloFormatado() {
        return this.dtInicioTitulo ? this.dtInicioTitulo.toFormat('dd/MM/yyyy') : '';
    }

    set dtInicioTituloFormatado(value) {
        this.dtInicioTitulo = value ? new ISODate(parse(value, 'dd/MM/yyyy', new Date())) : undefined;
    }

    set dtVencimentoTituloFormatado(value) {
        this.dtVencimentoTitulo = value ? new ISODate(parse(value, 'dd/MM/yyyy', new Date())) : undefined;
    }

    get dtVencimentoTituloFormatado() {
        return this.dtVencimentoTitulo ? this.dtVencimentoTitulo.toFormat('dd/MM/yyyy') : ''
    }

    set vrAlvoCaptacaoFormatado(value: string) {
        this.vrAlvoCaptacao = value ? Number.parseFloat(value.replace(/\./g, '').replace(',', '.')) : undefined;
    }

    get vrAlvoCaptacaoFormatado() {
        return this.vrAlvoCaptacao ? this.vrAlvoCaptacao.toFixed(2).replace('.', ',').replace(/\d(?=(\d{3})+,)/g, '$&.') : '';
    }

    set vrCotaAtualizadaFormatado(value: string) {
        this.vrCotaAtualizada = value ? Number.parseFloat(value.replace(/\./g, '').replace(',', '.')) : undefined;
    }

    get vrCotaAtualizadaFormatado() {
        return this.vrCotaAtualizada ? formatMoeda(this.vrCotaAtualizada) : '';
    }

    get vrCotaEmitidaFormatado() {
        return this.vrCotaEmitida ? formatMoeda(this.vrCotaEmitida) : '';
    }

    set pcRentabilidadeProjetadaFixaFormatado(value: string) {
        this.pcRentabilidadeProjetadaFixa = value ? Number.parseFloat(('0000' + value).replace(',', '').replace(/^(0+)(\d+)(\d{4})$/g, '$2.$3')) : undefined;
    }

    get pcRentabilidadeProjetadaFixaFormatado() {
        return this.pcRentabilidadeProjetadaFixa ? formatPorcentagem(this.pcRentabilidadeProjetadaFixa) : '';
    }

    set pcRentabilidadeProjetadaMinimaFormatado(value: string) {
        this.pcRentabilidadeProjetadaMinima = value ? Number.parseFloat(('0000' + value).replace(',', '').replace(/^(0+)(\d+)(\d{4})$/g, '$2.$3')) : undefined;
    }

    get pcRentabilidadeProjetadaMinimaFormatado() {
        return this.pcRentabilidadeProjetadaMinima ? formatPorcentagem(this.pcRentabilidadeProjetadaMinima) : '';
    }

    set pcRentabilidadeProjetadaMaximaFormatado(value: string) {
        this.pcRentabilidadeProjetadaMaxima = value ? Number.parseFloat(('0000' + value).replace(',', '').replace(/^(0+)(\d+)(\d{4})$/g, '$2.$3')) : undefined;
    }

    get pcRentabilidadeProjetadaMaximaFormatado() {
        return this.pcRentabilidadeProjetadaMaxima ? formatPorcentagem(this.pcRentabilidadeProjetadaMaxima) : '';
    }

    get pcRentabilidadeGarantidaCdiFormatado() {
        return this.pcRentabilidadeGarantidaCdi ? this.pcRentabilidadeGarantidaCdi.toFixed(4).replace('.', '').replace(/^(0*)(\d+)(\d{2})$/g, '$2,$3') : '';
    }

    set pcRentabilidadeGarantidaCdiFormatado(value: string) {
        this.pcRentabilidadeGarantidaCdi = value ? Number.parseFloat(('0000' + value).replace(',', '').replace(/^(0*)(\d+)(\d{4})$/g, '$2.$3')) : undefined;
    }

    get pcRentabilidadeGarantidaFixaAnualFormatado() {
        return this.pcRentabilidadeGarantidaFixaAnual ? this.pcRentabilidadeGarantidaFixaAnual.toFixed(4).replace('.', '').replace(/^(0*)(\d+)(\d{2})$/g, '$2,$3') : '';
    }

    get pcRentabilidadeProjetadaFixaAnualFormatado() {
        return this.pcRentabilidadeProjetadaFixaAnual ? this.pcRentabilidadeProjetadaFixaAnual.toFixed(4).replace('.', '').replace(/^(0*)(\d+)(\d{2})$/g, '$2,$3') : '';
    }

    get pcRentabilidadeProjetadaMinimaAnualFormatado() {
        return this.pcRentabilidadeProjetadaMinimaAnual ? this.pcRentabilidadeProjetadaMinimaAnual.toFixed(4).replace('.', '').replace(/^(0*)(\d+)(\d{2})$/g, '$2,$3') : '';
    }

    get pcRentabilidadeProjetadaMaximaAnualFormatado() {
        return this.pcRentabilidadeProjetadaMaximaAnual ? this.pcRentabilidadeProjetadaMaximaAnual.toFixed(4).replace('.', '').replace(/^(0*)(\d+)(\d{2})$/g, '$2,$3') : '';
    }

    get pcTotalReservado() {
        if (this.vrAlvoCaptacao && this.captacaoProjeto && this.captacaoProjeto.vrTotalReservado !== undefined) {
            return this.captacaoProjeto.vrTotalReservado / this.vrAlvoCaptacao;
        } else {
            return 0.00;
        }
    }

    get pcTotalReservadoFormatado() {
        if (this.vrAlvoCaptacao && this.captacaoProjeto && this.captacaoProjeto.vrTotalReservado !== undefined) {
            const pc = this.captacaoProjeto.vrTotalReservado / this.vrAlvoCaptacao;
            return pc.toFixed(4).replace('.', '').replace(/^(0*)(\d+)(\d{2})$/g, '$2,$3');
        } else {
            return '';
        }
    }



}
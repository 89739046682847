import { createStyles, IconButton, List, ListItem, ListItemText, makeStyles, Theme } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import FilePdfBoxOutlineIcon from 'mdi-material-ui/FilePdfBoxOutline';
import ImageOutlineIcon from 'mdi-material-ui/ImageOutline';
import FileOutlineIcon from 'mdi-material-ui/FileOutline';
import React from 'react';
import { dataURItoBlob } from '../ViewUtils';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  content: {
    padding: 0
  },
  pdfIcon: {
    color: '#FF3500',
    minWidth: 40
  },
  imgIcon: {
    color: '#FF3500',
    minWidth: 40
  },
  downloadContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '5px',
  }

}), { name: 'DownloadBox' });


interface Props {
  name: string;
  url: string;
  icon: "pdf"|"img"|"file";
}

export default function DownloadBox(props: Props) {

  const classes = useStyles();

  const {icon} = props;

  const handleClick = () => {
    if(props.url.startsWith("data:")){
      const blob = dataURItoBlob(props.url);
      const blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    } else {
      window.open(props.url);
    }
    
  }

  // href={props.url} rel="noopener" target="_blank"

  return (

    <div className={classes.downloadContainer}>
      <List>
        <ListItem >
          {icon === 'pdf' &&
            <FilePdfBoxOutlineIcon className={classes.pdfIcon}/>
          }
          {icon === 'img' &&
            <ImageOutlineIcon className={classes.imgIcon}/>
          }
          {icon === 'file' &&
            <FileOutlineIcon className={classes.imgIcon}/>
          }
          
          <ListItemText primary={props.name} />
          <IconButton edge="end" size="small" onClick={handleClick} >
              <GetAppIcon />
          </IconButton>
        </ListItem>
        </List>
    </div>

  );
}
import { Box, Button, createStyles, Grid, makeStyles, Paper, Typography, Link } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { DeepPartial } from 'redux';
import moment from 'moment';
import { InvestidorVO } from '../../../../../../../../models/Investidor';
import ConfirmLeavePage from '../../../../../../../components/ComfirmLeavePage';
import { FieldOutput } from '../../../../../../../components/form/output/FieldOutput';
import { Formatters } from '../../../../../../../../utils/formatters';
import { EstadoCivil } from '../../../../../../../../models/enums/EstadoCivil';
import { Sexo } from '../../../../../../../../models/enums/Sexo';
import { NivelInvestidor } from '../../../../../../../../models/enums/NivelInvestidor';
import { APIUtils } from '../../../../../../../../services/api/APIUtils';
import { DocumentoVO } from '../../../../../../../../models/Documento';
import ButtonProgress from '../../../../../../../components/ButtonProgress';


const useStyles = makeStyles(() => createStyles({
  imagem: {
    width: 200
  },
}), { name: 'InvestidorReviewCadastro' });


interface InvestidorReviewCadastroProps {
  investidor: DeepPartial<InvestidorVO>;
  onSubmit?: () => Promise<void> | void;
  submitText?: string;
  onBack?: () => void;
  onDadosBasicosEdit: () => void;
  onSelfieDocumentoEdit: () => void;
  onEnderecoEdit: () => void;
  onComprovatenResidenciaEdit: () => void;
  onDadosBancariosEdit: () => void;


}

export const InvestidorReviewCadastro = ({ investidor, onSubmit, onBack, submitText,
  onDadosBancariosEdit, onDadosBasicosEdit, onEnderecoEdit, onSelfieDocumentoEdit, onComprovatenResidenciaEdit }: InvestidorReviewCadastroProps) => {

  const classes = useStyles();

  const { handleSubmit, formState: { isSubmitting } } = useForm();

  return (
    <>
      <ConfirmLeavePage
        when={!!onSubmit}
        title="Tem certeza que deseja deixar a página sem salvar as alterações?"
        content="Ao confirmar os dados alterados não serão salvos. Se cancelar, poderá continuar a edição."
      />
      <Paper variant="outlined" square >
        <Box p={2}>

          <Box mb={2} display="flex" alignItems="center">
            <Typography variant="h6">Dados Básicos&nbsp;</Typography>
            <Link component="button" variant="subtitle2" color="primary" onClick={onDadosBasicosEdit}>[Editar]</Link>
          </Box>
          <Box ml={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="CPF" value={Formatters.cpf.format(investidor.nuCpfCnpjInvestidor)} />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Data de Nascimento" value={moment(investidor.dtNascimento).format("L")} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="RG" value={investidor.coRg || ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Orgão Emissor" value={investidor.noOrgaoEmissorRg || ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Estado Civil" value={investidor.icEstadoCivil ? EstadoCivil[investidor.icEstadoCivil] : ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Sexo" value={investidor.icSexo ? Sexo[investidor.icSexo] : ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Telefone" value={Formatters.telefone.format(investidor.nuTelefone) || '(em branco)'} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Celular" value={Formatters.telefone.format(investidor.nuCelular)} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Profissão" value={investidor.noProfissao || ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Pessoa Politicamente Exposta (PPE)" value={investidor.icPpe ? 'Sim' : 'Não'} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Nível de Investidor" value={investidor.icNivelInvestidor ? NivelInvestidor[investidor.icNivelInvestidor] : ''} />
              </Grid>


            </Grid>
          </Box>

          <Box my={2} display="flex" alignItems="center">
            <Typography variant="h6">Selfie com Documento&nbsp;</Typography>
            <Link component="button" variant="subtitle2" color="primary" onClick={onSelfieDocumentoEdit}>[Editar]</Link>
          </Box>

          <Box ml={2} mb={2}>
            <img className={classes.imagem} src={APIUtils.generateDocumentoURL(investidor.documentoSelfieAutentica as DocumentoVO)} />
          </Box>

          <Box my={2} display="flex" alignItems="center">
            <Typography variant="h6">Endereço&nbsp;</Typography>
            <Link component="button" variant="subtitle2" color="primary" onClick={onEnderecoEdit}>[Editar]</Link>
          </Box>
          <Box ml={2}>
            <Grid container spacing={2}>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="CEP" value={Formatters.cep.format(investidor.endereco?.coCep)} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="UF" value={investidor.endereco?.noUf || ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Município" value={investidor.endereco?.noMunicipio || ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Bairro" value={investidor.endereco?.noBairro || ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Rua" value={investidor.endereco?.noLogradouro || ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Número" value={investidor.endereco?.nuLogradouro?.toString() || ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Complemento" value={investidor.endereco?.deComplemento || '(em branco)'} />
              </Grid>

            </Grid>
          </Box>

          <Box my={2} display="flex" alignItems="center">
            <Typography variant="h6">Comprovante de Endereço&nbsp;</Typography>
            <Link component="button" variant="subtitle2" color="primary" onClick={onComprovatenResidenciaEdit}>[Editar]</Link>
          </Box>

          <Box ml={2} mb={2}>
            <img className={classes.imagem} src={APIUtils.generateDocumentoURL(investidor.documentoComprovanteResidencia as DocumentoVO)} />
          </Box>

          <Box my={2} display="flex" alignItems="center">
            <Typography variant="h6">Dados Bancários&nbsp;</Typography>
            <Link component="button" variant="subtitle2" color="primary" onClick={onDadosBancariosEdit}>[Editar]</Link>
          </Box>
          <Box ml={2}>
            <Grid container spacing={2}>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Banco" value={Formatters.conta.banco.format(investidor.dadoBancario?.coBanco)} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Agência" value={investidor.dadoBancario?.nuAgencia || ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Conta" value={Formatters.conta.numero.format(investidor.dadoBancario?.nuConta)} />
              </Grid>

            </Grid>
          </Box>

          <Box mt={2}>

            <Grid container justify="flex-end" spacing={2}>
              {onBack &&
                <Grid item>
                  <Button
                    id="btnVoltar"
                    variant="text"
                    color="default"
                    onClick={onBack}
                  >
                    Voltar
              </Button>
                </Grid>
              }
              {onSubmit &&
                <Grid item>
                  <form id="formRevisaCadastro" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <ButtonProgress
                      id="btnSubmit"
                      loading={isSubmitting}
                      variant="contained"
                      color="primary"
                      type="submit">
                      {submitText}
                    </ButtonProgress>
                  </form>
                </Grid>
              }

            </Grid>
          </Box>

        </Box>
      </Paper >
    </ >
  )
}

import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import { applyPatches, Patch, produceWithPatches } from 'immer';
import React from 'react';
import { useForm } from 'react-hook-form';
import { DeepPartial } from 'redux';
import { DadoBancarioVO } from '../../../../models/DadoBancario';
import { Formatters } from '../../../../utils/formatters';
import { Patterns } from '../../../../utils/patterns';
import ButtonProgress from '../../ButtonProgress';
import ConfirmLeavePage from '../../ComfirmLeavePage';
import FormInput from '../../FormInput';
import { ContaBancariaMaskedInput, OnlyNumbersMaskedInput } from '../../MaskedInputs';

export enum DadosBancariosFormMode {
  CREATE,
  EDIT
}

interface DadosBancariosFormData {
  coBanco: string;
  nuAgencia: string;
  nuConta: string;
}

const generateFormDefaultValues = (dadoBancario: DeepPartial<DadoBancarioVO>): DadosBancariosFormData => {
  return {
    coBanco: Formatters.conta.banco.format(dadoBancario.coBanco),
    nuAgencia: dadoBancario.nuAgencia || '',
    nuConta: Formatters.conta.numero.format(dadoBancario.nuConta)
  }
}

interface DadosBancariosFormProps {
  dadoBancario: DeepPartial<DadoBancarioVO>;
  onSubmit: (nextState: DeepPartial<DadoBancarioVO>, patches: Patch[], changes: DeepPartial<DadoBancarioVO>) => Promise<void> | void;
  onBack?: () => void;
  submitText: string;
  formMode: DadosBancariosFormMode;
}

export const DadosBancariosForm = ({ dadoBancario, onSubmit, onBack, submitText, formMode }: DadosBancariosFormProps) => {

  const { register, handleSubmit, errors, formState: { isSubmitting, dirty } } = useForm<DadosBancariosFormData>({
    mode: "onBlur",
    defaultValues: generateFormDefaultValues(dadoBancario)
  });

  const handleFormSubmit = async (formData: DadosBancariosFormData) => {
    const [nextState, patches] = produceWithPatches(dadoBancario, draft => {
      draft.coBanco = Formatters.conta.banco.unformat(formData.coBanco);
      draft.nuAgencia = formData.nuAgencia;
      draft.nuConta = Formatters.conta.numero.unformat(formData.nuConta);
    })
    const changes = applyPatches({}, patches) as DeepPartial<DadoBancarioVO>;
    await onSubmit(nextState, patches, changes)
  }

  return (
    <form id="formDadoBancario" noValidate autoComplete="off" onSubmit={handleSubmit(handleFormSubmit)}>
      <ConfirmLeavePage
        when={dirty}
        title="Tem certeza que deseja deixar a página sem salvar as alterações?"
        content="Ao confirmar os dados alterados não serão salvos. Se cancelar, poderá continuar a edição."
      />
      <Paper variant="outlined" square >
        <Box p={2}>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <Typography variant="h6">Dados Bancários</Typography>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>

              <FormInput
                required
                autoFocus
                name="coBanco"
                placeholder="999"
                label="Banco"
                maxLength={3}
                inputComponent={OnlyNumbersMaskedInput as any}
                error={errors.coBanco?.message?.toString()}
                inputRef={register({
                  required: "Informe o Código do Banco",
                  pattern: { value: Patterns.conta.banco, message: "Formato 999" }
                })}
              />

            </Grid>
            <Grid item xs={12} sm={6} lg={4}>

              <FormInput
                required
                name="nuAgencia"
                placeholder="9999"
                label="Agência"
                maxLength={4}
                inputComponent={OnlyNumbersMaskedInput as any}
                error={errors.nuAgencia?.message?.toString()}
                inputRef={register({
                  required: "Informe o Número da Agência",
                  pattern: { value: Patterns.conta.agencia, message: "Formato 9999" }
                })}
              />

            </Grid>
            <Grid item xs={12} sm={6} lg={4}>

              <FormInput
                required
                name="nuConta"
                placeholder="???????????9-X"
                label="Conta"
                maxLength={13}
                inputComponent={ContaBancariaMaskedInput as any}
                error={errors.nuConta?.message?.toString()}
                inputRef={register({
                  required: "Informe o Número da Conta e Dígito",
                  pattern: { value: Patterns.conta.numero, message: "Formato ???????????9-X" }
                })}
              />

            </Grid>

            <Grid item container justify="flex-end" spacing={2}>
              {onBack &&
                <Grid item>
                  <Button
                    id="btnVoltar"
                    variant="text"
                    color="default"
                    onClick={onBack}
                  >
                    Voltar
              </Button>
                </Grid>
              }
              <Grid item>
                <ButtonProgress
                  id="btnSubmit"
                  loading={isSubmitting}
                  disabled={Object.values(errors).length > 0 || (formMode == DadosBancariosFormMode.EDIT && !dirty)}
                  variant="contained"
                  color="primary"
                  type="submit">
                  {submitText}
                </ButtonProgress>
              </Grid>
            </Grid>


          </Grid>


        </Box>
      </Paper>
    </form>
  )
}

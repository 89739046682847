import { AppBar, Avatar, Button, Divider, ListItemIcon, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import BusinessIcon from '@material-ui/icons/Business';
import CloseIcon from '@material-ui/icons/Close';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import WorkIcon from '@material-ui/icons/Work';
import AccountCardDetailsIcon from 'mdi-material-ui/AccountCardDetails';
import FileCheckOutlineIcon from 'mdi-material-ui/FileCheckOutline';
import FileDocumentBoxCheckOutlineIcon from 'mdi-material-ui/FileDocumentBoxCheckOutline';
import FileUploadOutlineIcon from 'mdi-material-ui/FileUploadOutline';
import LogoutIcon from 'mdi-material-ui/Logout';
import MonitorDashboardIcon from 'mdi-material-ui/MonitorDashboard';
import PercentIcon from 'mdi-material-ui/Percent';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Perfil } from '../../models/enums/Perfil';
import { ConfirmDialog, ConfirmDialogProps } from '../components/ConfirmDialog';
import GottaupLogo from '../images/gottaup-logo-horizontal.png';
import OnlyDesktop from './OnlyDesktop';
import { efetuaLogoff, selectLogin } from '../../stores/slices/userSlice';
import { LoginUtils } from '../../utils/login';
import { AppDispatch } from '../../../browser';

const useStyles = makeStyles((theme: Theme) => createStyles({
  appBar: {
    //borderBottom: `1px solid ${theme.palette.divider}`,
    zIndex: theme.zIndex.appBar
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  avatar: {
    width: 30,
    height: 30,
    backgroundColor: theme.palette.secondary.main
  },
  menuGroup: {
    marginLeft: '1em'
  }
}), { name: 'PageMenuDesktop' });

export default function PageMenuDesktop() {

  const classes = useStyles();

  const dispatch = useDispatch<AppDispatch>();

  const login = useSelector(selectLogin);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [dialog, setDialog] = useState<ConfirmDialogProps | undefined>(undefined);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    setDialog(undefined);
    dispatch(efetuaLogoff());
    const dismissButton = (key: string | number | undefined) => <Button onClick={() => closeSnackbar(key)}><CloseIcon color="action" /></Button>
    enqueueSnackbar('Logout efetuado com sucesso!', {
      variant: 'success',
      action: dismissButton
    });


    history.push('/');
  }

  const confirmaLogout = () => {
    setDialog({
      open: true,
      title: "Sair",
      content: "Efetuar o logout?",
      onConfirm: logout,
      onCancel: () => setDialog(undefined)
    });
  }

  return (
    <OnlyDesktop>

      {dialog && <ConfirmDialog {...dialog} />}

      <AppBar position="relative" color="default" elevation={2} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
            <Link to="/">
              <img alt="logo do gottaup" width="120" src={GottaupLogo} />
            </Link>
          </Typography>
          <nav>
            <Button component={Link} to="/investir" color="default" variant="text" className={classes.link}>
              Investir
            </Button>
            <Button component={Link} to="/captar" color="default" variant="text" className={classes.link}>
              Captar
            </Button>
            <Button component={Link} to="#" color="default" variant="text" className={classes.link}>
              Saiba Mais
            </Button>
          </nav>
          {(!login || !LoginUtils.isValid(login)) &&
            <Button id="btnLogin" component={Link} to="/login" color="secondary" variant="outlined" className={classes.link}>
              Login
              </Button>
          }
          {login && LoginUtils.isValid(login) &&
            <>
              <Button
                id="btnMenu"
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="outlined"
                color="secondary"
                onClick={handleOpenMenu}
                startIcon={<Avatar src={LoginUtils.fotoUrl(login)} className={classes.avatar} />}
                endIcon={<ArrowDropDownIcon />}
              >
                {login.noUsuario.split(" ")[0]}
              </Button>
              <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                keepMounted
                onClose={handleCloseMenu}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: -10,
                  horizontal: 'left',
                }}>

                <MenuItem id="btnPerfilUsuario" component={Link} to="/login/perfil">
                  <ListItemIcon >
                    <PersonIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Perfil</Typography>
                </MenuItem>

                <Divider />

                {LoginUtils.isUserInAnyRole(login,[Perfil.Administrador, Perfil.Investidor]) &&

                  [

                    <MenuItem id="btnPainelInvestimentos" key={0} component={Link} to="/conta/painel">
                      <ListItemIcon >
                        <MonitorDashboardIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">Painel de Investimentos</Typography>
                    </MenuItem>
                    ,
                    <MenuItem id="btnCadastroInvestidor" key={1} component={Link} to="/conta/investidor">
                      <ListItemIcon >
                        <AccountCardDetailsIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">Cadastro do Investidor</Typography>
                    </MenuItem>
                    /*,
                    <MenuItem key={2} >
                      <ListItemIcon >
                        <ReceiptIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">Extrato IR</Typography>
                    </MenuItem>
                    */
                  ]

                }

                {/*login.isUserInAnyRole([Perfil.Administrador, Perfil.Empresa]) &&

                  [
                    <Divider key={0} />
                    ,
                    <MenuItem key={1} >
                      <ListItemIcon >
                        <MonitorDashboardIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">Painel de Captações</Typography>
                    </MenuItem>
                    ,
                    <MenuItem key={2} >
                      <ListItemIcon >
                        <FileFindOutlineIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">Pré-Projetos em Análise</Typography>
                    </MenuItem>
                    ,
                    <MenuItem key={3} >
                      <ListItemIcon >
                        <PublishIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">Publicação de Documentos</Typography>
                    </MenuItem>
                  ]

                */}

                {LoginUtils.isUserInRole(login,Perfil.Administrador) &&

                  [
                    <Divider key={0} />
                    ,
                    <MenuItem id="btnCadastroEmpresas" key={1} component={Link} to="/admin/empresas">
                      <ListItemIcon>
                        <BusinessIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">Cadastro de Empresas</Typography>
                    </MenuItem>
                    ,
                    <MenuItem id="btnCadastroProjetos" key={2} component={Link} to="/admin/projetos">
                      <ListItemIcon>
                        <WorkIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">Cadastro de Projetos</Typography>
                    </MenuItem>
                    ,
                    <MenuItem id="btnCadastroUsuarios" key={3} component={Link} to="/admin/usuarios">
                      <ListItemIcon>
                        <GroupIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">Cadastro de Usuários</Typography>
                    </MenuItem>
                    ,
                    <MenuItem key={4} id="btnCadastroTaxas" component={Link} to="/admin/taxasjuros">
                      <ListItemIcon>
                        <PercentIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">Cadastro de Taxas</Typography>
                    </MenuItem>
                  ]
                }

                {LoginUtils.isUserInRole(login,Perfil.Administrador) &&

                  [
                    <Divider key={0} />
                    /*,
                    <MenuItem key={1}>
                      <ListItemIcon>
                        <FileFindOutlineIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">Pré-Projetos para Análise</Typography>
                    </MenuItem>
                    */
                    ,

                    <MenuItem key={2} id="btnDocumentosValidacao" component={Link} to="/operacional/documentos">
                      <ListItemIcon>
                        <FileCheckOutlineIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">Documentos para Validação</Typography>
                    </MenuItem>
                    ,
                    <MenuItem key={3} id="btnAprovacaoReservas" component={Link} to="/operacional/depositos">
                      <ListItemIcon>
                        <FileDocumentBoxCheckOutlineIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">Aprovação de Reservas</Typography>
                    </MenuItem>
                    ,
                    <MenuItem key={4} id="btnUploadContratos" component={Link} to="/operacional/contratos">
                      <ListItemIcon>
                        <FileUploadOutlineIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">Upload de Contratos</Typography>
                    </MenuItem>
                  ]
                }

                <Divider />

                <MenuItem id="btnCadastroEmpresas" onClick={confirmaLogout}>
                  <ListItemIcon>
                    <LogoutIcon transform="rotate(180)" fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Sair</Typography>
                </MenuItem>
              </Menu>
            </>

          }

        </Toolbar>
      </AppBar>
    </OnlyDesktop>
  );
}

import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import { applyPatches, Patch, produceWithPatches } from 'immer';
import React from 'react';
import { useForm } from 'react-hook-form';
import { DeepPartial } from 'redux';
import { DocumentoVO } from '../../../../../../../../models/Documento';
import { FileUtils } from '../../../../../../../../utils/files';
import { ImageUtils } from '../../../../../../../../utils/images';
import ConfirmLeavePage from '../../../../../../../components/ComfirmLeavePage';
import FormPhotoUpload from '../../../../../../../components/form/inputs/FileUpload/FormPhotoUpload';
import { APIUtils } from '../../../../../../../../services/api/APIUtils';
import ButtonProgress from '../../../../../../../components/ButtonProgress';


export enum InvestidorSelfieDocumentoFormMode {
  CREATE,
  EDIT
}

interface InvestidorSelfieDocumentoFormData {
  noDocumento: string;
  imDocumento: string;
}

const generateFormDefaultValues = (documento: DeepPartial<DocumentoVO>): InvestidorSelfieDocumentoFormData => {
  return {
    imDocumento: documento.imDocumento || '',
    noDocumento: documento.noDocumento || ''
  }
}


interface ImagemFormProps {
  documento: DeepPartial<DocumentoVO>;
  onSubmit: (nextState: DeepPartial<DocumentoVO>, patches: Patch[], changes: DeepPartial<DocumentoVO>) => Promise<void> | void;
  onBack?: () => void;
  submitText: string;
  formMode: InvestidorSelfieDocumentoFormMode;
}

export const InvestidorSelfieDocumentoForm = ({ documento, onSubmit, onBack, submitText, formMode }: ImagemFormProps) => {

  const { register, handleSubmit, errors, setValue, formState: { isSubmitting, dirty } } = useForm<InvestidorSelfieDocumentoFormData>({
    mode: "onBlur",
    defaultValues: generateFormDefaultValues(documento)
  });

  const handleFormSubmit = async (formData: InvestidorSelfieDocumentoFormData) => {
    const [nextState, patches] = produceWithPatches(documento, draft => {
      draft.noDocumento = formData.noDocumento;
      draft.imDocumento = formData.imDocumento;
    })
    const changes = applyPatches({}, patches) as DeepPartial<DocumentoVO>;
    await onSubmit(nextState, patches, changes)
  }

  const handleChangeImagem = async (file: File | null) => {
    if (file) {
      setValue("noDocumento", file.name, true);
      const imageResized = FileUtils.dataURLtoBase64(await ImageUtils.resizeImageKeepProportions(await FileUtils.fileToDataURL(file), 1200, 1200));
      setValue("imDocumento", imageResized);
    } else {
      setValue("noDocumento", '');
      setValue("imDocumento", '');
    }
  }


  return (
    <form id="formSelfieDocumento" noValidate autoComplete="off" onSubmit={handleSubmit(handleFormSubmit)}>
      <ConfirmLeavePage
        when={dirty}
        title="Tem certeza que deseja deixar a página sem salvar as alterações?"
        content="Ao confirmar os dados alterados não serão salvos. Se cancelar, poderá continuar a edição."
      />
      <Paper variant="outlined" square >
        <Box p={2}>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <Typography variant="h6">Selfie com Documento</Typography>
            </Grid>

            <Grid item xs={12} >
              <input
                hidden
                name="noDocumento"
                ref={register({
                  required: "Faça upload de um selfie com documento de identificação",
                  validate: {
                    extension: value => value.match(/\.(jpg)|(png)$/i) ? true : 'Arquivo não aceito! Selecione uma imagem em formato .jpg ou .png',
                  }
                })} />
              <input
                hidden
                name="imDocumento"
                ref={register()} />
              <FormPhotoUpload
                canEdit={true}
                aspect={3/4}
                variant="picture"
                accept=".jpg, .png"
                helperText="Tire uma foto ou utilize imagens .jpg ou .png com pelo menos 1.2MP"
                error={errors.noDocumento?.message?.toString()}
                onChange={handleChangeImagem}
                previewURL={APIUtils.generateDocumentoURL(documento)}
              />
            </Grid>

            <Grid item container justify="flex-end" spacing={2}>
              {onBack &&
                <Grid item>
                  <Button
                    id="btnVoltar"
                    variant="text"
                    color="default"
                    onClick={onBack}
                  >
                    Voltar
              </Button>
                </Grid>
              }
              <Grid item>
                <ButtonProgress
                  id="btnSubmit"
                  loading={isSubmitting}
                  disabled={Object.values(errors).length > 0 || (formMode == InvestidorSelfieDocumentoFormMode.EDIT && !dirty)}
                  variant="contained"
                  color="primary"
                  type="submit">
                  {submitText}
                </ButtonProgress>
              </Grid>
            </Grid>


          </Grid>


        </Box>
      </Paper>
    </form>
  )
}

import React from 'react';
import MaskedInput from 'react-text-mask';
import emailMask from 'text-mask-addons/dist/emailMask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

interface Props {
  inputRef: (ref: HTMLInputElement | null) => void;
}

export function CNPJMaskedInput(props: Props){
  const { inputRef, ...other } = props;
  return (
      <MaskedInput
        {...other}
        ref={(ref: any) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-',  /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask={false}
      />
    );   
}

export function CPFMaskedInput(props: Props){
  const { inputRef, ...other } = props;
  return (
      <MaskedInput
        {...other}
        ref={(ref: any) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-',  /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask={false}
      />
    );   
}

function telefoneMask(userInput: string) {
  const numbers = userInput.match(/\d/g);
  let numberLength = 0;
  if (numbers) {
    numberLength = numbers.join("").length;
  }

  if (numberLength > 10) {
    return ['(', /[1-9]/, /[1-9]/, ')',  /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  } else {
    return ['(', /[1-9]/, /[1-9]/, ')',  /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  }
}

export function TelefoneMaskedInput(props: Props){
  const { inputRef, ...other } = props;
  return (
      <MaskedInput
        {...other}
        ref={(ref: any) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={telefoneMask}
        placeholderChar={'\u2000'}
        showMask={false}
      />
    );   
}

export function EmailMaskedInput(props: Props){
  const { inputRef, ...other } = props;
  return (
      <MaskedInput
        {...other}
        ref={(ref: any) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={emailMask}
        placeholderChar={'\u2000'}
        showMask={false}
      />
    );   
}

export function CEPMaskedInput(props: Props){
  const { inputRef, ...other } = props;
  return (
      <MaskedInput
        {...other}
        ref={(ref: any) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask={false}
      />
    );   
}

export function CodigoMaskedInput(props: Props){
  const { inputRef, ...other } = props;
  return (
      <MaskedInput
        {...other}
        ref={(ref: any) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/[0-9A-Z]/, /[0-9A-Z]/, /[0-9A-Z]/, /[0-9A-Z]/, /[0-9A-Z]/, /[0-9A-Z]/]}
        placeholderChar={'\u2000'}
        showMask={false}
      />
    );   
}


const onlyNumbersMask = createNumberMask({
  prefix: '',
  includeThousandsSeparator: false,
  allowLeadingZeroes: true
})

export function OnlyNumbersMaskedInput(props: Props){
  const { inputRef, ...other } = props;
  return (
      <MaskedInput
        {...other}
        ref={(ref: any) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={onlyNumbersMask}
        placeholderChar={'\u2000'}
        showMask={false}
      />
    );   
}

function contaBancariaMask(userInput: string) {
  const numbers = userInput.match(/[0-9A-Z]/g);
  let numberLength = 0;
  if (numbers) {
    numberLength = numbers.join("").length;
  }
  //[0-9]{1,11}-[A-Z0-9]{1}
  
  if(numberLength <=1){
    return [/[0-9A-Z]/]
  } else {
    let mask: Array<string|RegExp> = ['-',/[0-9A-Z]/]
    for(let i = 0; i < numberLength-1; i++){
      mask = [/[0-9]/, ...mask]
    }
    return mask;
  }
  
  
}

export function ContaBancariaMaskedInput(props: Props){
  const { inputRef, ...other } = props;
  return (
      <MaskedInput
        {...other}
        ref={(ref: any) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={contaBancariaMask}
        placeholderChar={'\u2000'}
        showMask={false}
      />
    );   
}



/*
const reaisMask = function(rawValue: string) {
  const numberMask = createNumberMask({
    prefix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol:'.',
    requireDecimal: true,
    allowDecimal: true,
    decimalSymbol: ','
  });
  // Faz ajuste para que o padrão seja que os números digitados se tornem decimais. Ex: 1 -> 0,01
  console.log("antes",rawValue);
  rawValue = rawValue.replace('.', '').replace(',', '').replace(/^(\d+)$/g, '00$1').replace(/^(\d+)(\d{2})$/g, '$1,$2');
  console.log("depois",rawValue);
  let mask = numberMask(rawValue);
  return mask;
}
const reaisMask = createNumberMask({
  prefix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol:'.',
  requireDecimal: true,
  allowDecimal: true,
  decimalSymbol: ','
})

*/

function reaisMask(userInput: string) {
  
  const numbers = userInput.replace('.', '').replace(',', '').replace(/^(0*)(\d+)$/g, '$2'); // Remove formatação e 0s a esquerda
  
  if(numbers.length <= 1){
     return ['0',',','0',/[1-9]/]; // não permite adicionar zeros a esquerda
  } else if (numbers.length == 2) {
     return ['0',',',/[0-9]/,/[0-9]/];
  } else {
    let mask: Array<string|RegExp> = [',',/[0-9]/,/[0-9]/];
    for(let i = 0; i < numbers.length-2; i++){
      if(i>0 && i%3 == 0){
        mask = ['.', ...mask]
      }
      mask = [/[0-9]/, ...mask]
    }
    return mask;
  }
  
}


const pipeReaisMask = (conformedValue: string, config: any): string => {
  const _ = 0;//config.placeholderChar;
  //config.rawValue
  const digits = config.rawValue.replace(/[^0-9]/g,'').replace(/^(0*)(\d+)$/, '$2').split("");
  if (digits.length == 0) {
    return '';
  } else if (digits.length == 1) {
    if(digits[0] == '0'){
      return '';
    } else {
      return `${_},${_}${digits[0]}`;
    }
  } else if (digits.length == 2) {
    const cents = digits.slice(digits.length - 2).join('');
    return `${_},${cents}`;
  } else {
    const cents = digits.slice(digits.length - 2).join('');
    const dollars = digits.slice(0, digits.length - 2).join('');
    return `${dollars},${cents}`.replace(/\d(?=(\d{3})+,)/g, '$&.')// Formata com separador de milhares
  }
}


export function ReaisMaskedInput(props: Props){
  const { inputRef, ...other } = props;
  return (
      <MaskedInput
        {...other}
        ref={(ref: any) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={reaisMask}
        pipe={pipeReaisMask}
        placeholderChar={'\u2000'}
        showMask={false}
      />
    );   
}

/*
const porcentagemMask = createNumberMask({
  prefix: '',
  includeThousandsSeparator: false,
  requireDecimal: true,
  allowDecimal: true,
  decimalSymbol: ','
})
*/

function porcentagemMask(userInput: string) {
  
  const numbers = userInput.replace('.', '').replace(',', '').replace(/^(0*)(\d+)$/g, '$2'); // Remove formatação e 0s a esquerda
  
  if(numbers.length <= 1){
     return ['0',',','0',/[1-9]/,'%']; // não permite adicionar zeros a esquerda
  } else if (numbers.length == 2) {
     return ['0',',',/[0-9]/,/[0-9]/,'%'];
  } else {
    let mask: Array<string|RegExp> = [',',/[0-9]/,/[0-9]/,'%'];
    for(let i = 0; i < numbers.length-2; i++){
      mask = [/[0-9]/, ...mask]
    }
    return mask;
  }
  
}


const pipePorcentagemMask = (conformedValue: string, config: any): string => {
  const _ = 0;//config.placeholderChar;
  //config.rawValue
  const digits = config.rawValue.replace(/[^0-9]/g,'').replace(/^(0*)(\d+)$/, '$2').split("");
  if (digits.length == 0) {
    return '';
  } else if (digits.length == 1) {
    if(digits[0] == '0'){
      return '';
    } else {
      return `${_},${_}${digits[0]}%`;
    }
  } else if (digits.length == 2) {
    const decimals = digits.slice(digits.length - 2).join('');
    return `${_},${decimals}%`;
  } else {
    const decimals = digits.slice(digits.length - 2).join('');
    const integers = digits.slice(0, digits.length - 2).join('');
    return `${integers},${decimals}%`;
  }
}

export function PorcentagemMaskedInput(props: Props){
  const { inputRef, ...other } = props;
  return (
      <MaskedInput
        {...other}
        ref={(ref: any) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={porcentagemMask}
        pipe={pipePorcentagemMask}
        placeholderChar={'\u2000'}
        showMask={false}
      />
    );   
}


import { Container, Grid, IconButton, Step, StepButton, Stepper, useMediaQuery, useTheme, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import WorkIcon from '@material-ui/icons/Work';
import HomeIcon from '@material-ui/icons/Home';
import { DeepPartial, unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Patch } from 'immer';
import { AppDispatch } from '../../../../../../../browser';
import { trackFetchProjeto, fetchProjeto, patchProjetoEndereco, patchProjetoCaptacao, patchProjetoDadosBasicos, changeProjetoImagemCapa, changeProjetoProspecto, changeProjetoModeloContrato, batchUpdateProjetoGaleriaFotos } from '../../../../../../stores/slices/entities/projetosSlice';
import { ProjetoVO } from '../../../../../../models/Projeto';
import { useDialog } from '../../../../../components/dialog/PageServiceProvider';
import PageTemplate from '../../../../../layout/PageTemplate';
import { EnderecoVO } from '../../../../../../models/Endereco';
import { APIError } from '../../../../../../services/api/APIError';
import { generateErrorDialog } from '../../../../../../services/api/APIErrorUtils';
import Loading from '../../../../../components/Loading';
import ErrorEmptyState from '../../../../../components/empty/ErrorEmptyState';
import { EnderecoForm, EnderecoFormMode } from '../../../../../components/form/shared/EnderecoForm';
import { ProjetoReviewCadastro, ProjetoReviewCadastroFormMode } from '../../create/components/ProjetoReviewCadastro';
import { ProjetoDadosBasicosFormMode, ProjetoDadosBasicosForm } from '../../create/components/ProjetoDadosBasicosForm';
import { ProjetoImagemCapaForm, ProjetoImagemCapaFormMode } from '../../create/components/ProjetoImagemCapaForm';
import { ProjetoGaleriaFotosForm, ProjetoGaleriaFotosFormMode } from '../../create/components/ProjetoGaleriaFotosForm';
import { ImagemVO } from '../../../../../../models/Imagem';
import { ProjetoDocumentoProspectoForm, ProjetoDocumentoProspectoFormMode } from '../../create/components/ProjetoDocumentoProspectoForm';
import { ProjetoDocumentoContratoForm, ProjetoDocumentoContratoFormMode } from '../../create/components/ProjetoDocumentoContratoForm';
import { DocumentoVO } from '../../../../../../models/Documento';
import { makeRangeIterator } from '../../../../../ViewUtils';
import BatchOperationDTO from '../../../../../../models/dtos/BatchOperationDTO';
import { Operation } from '../../../../../../models/enums/Operation';
import { HistoricoCotasList } from './components/HistoricoCotasList';
import { ProjetoEditCaptacaoForm } from './components/ProjetoEditCaptacaoForm';

enum ProjetoEditSteps {
  REVISAO,
  DADOS_BASICOS,
  ENDERECO,
  CAPTACAO,
  IMAGEM_CAPA,
  GALERIA_FOTOS,
  PROSPECTO,
  CONTRATO,
  VALOR_COTA
}

export const ProjetoEditPage = () => {

  const history = useHistory();
  const { from } = history.location.state as any || { from: { pathname: "/admin/projetos" } };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const dispatch = useDispatch<AppDispatch>()
  const params = useParams<{ id: string }>();
  const id = Number(params.id);
  const requestState = useSelector(trackFetchProjeto(Number(id)));

  const [state, setState] = useState<DeepPartial<ProjetoVO> | undefined>();

  const dialog = useDialog();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [step, setStep] = useState(ProjetoEditSteps.REVISAO);

  useEffect(() => {
    const promise = dispatch(fetchProjeto({ id }));
    return () => promise.abort();
  }, [id, dispatch]);

  useEffect(() => {
    if (requestState.data) {
      setState(requestState.data)
    }
  }, [requestState.data]);

  useEffect(() => {
    scrollTo({ top: 0 })
  }, [step])


  const handleDadosBasicosSave = async (nextState: DeepPartial<ProjetoVO>, patches: Patch[], changes: DeepPartial<ProjetoVO>) => {
    const resultAction = await dispatch(patchProjetoDadosBasicos({ id, changes }));
    if (patchProjetoDadosBasicos.fulfilled.match(resultAction)) {
      enqueueSnackbar('Dados básicos alterados com sucesso!', {
        variant: 'success',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });
      setState({ ...state, ...nextState });
      setStep(ProjetoEditSteps.REVISAO);
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível atualizar os dados básicos. Tente novamente."))
    }
  };

  const handleEnderecoSave = async (nextState: DeepPartial<EnderecoVO>, patches: Patch[], changes: DeepPartial<EnderecoVO>) => {
    const resultAction = await dispatch(patchProjetoEndereco({ id, changes }));
    if (patchProjetoEndereco.fulfilled.match(resultAction)) {
      enqueueSnackbar('Endereço alterado com sucesso!', {
        variant: 'success',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });
      setState({ ...state, endereco: { ...nextState } });
      setStep(ProjetoEditSteps.REVISAO);
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível atualizar endereço. Tente novamente."))
    }
  };

  const handleProjetoCaptacaoSave = async (nextState: DeepPartial<ProjetoVO>, patches: Patch[], changes: DeepPartial<ProjetoVO>) => {
    const resultAction = await dispatch(patchProjetoCaptacao({ id, changes }));
    if (patchProjetoCaptacao.fulfilled.match(resultAction)) {
      enqueueSnackbar('Captação alterada com sucesso!', {
        variant: 'success',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });
      setState({ ...state, ...nextState });
      setStep(ProjetoEditSteps.REVISAO);
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível atualizar a captação. Tente novamente."))
    }
  }

  const handleImagemCapaSave = async (nextState: DeepPartial<ImagemVO>) => {
    const resultAction = await dispatch(changeProjetoImagemCapa({ id, imagemCapa: nextState }));
    if (changeProjetoImagemCapa.fulfilled.match(resultAction)) {
      enqueueSnackbar('Imagem de Capa trocada com sucesso!', {
        variant: 'success',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });
      setState({ ...state, imagemCapa: { ...nextState } });
      setStep(ProjetoEditSteps.REVISAO);
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível trocar imagem de capa. Tente novamente."))
    }
  }

  const handleGaleriaFotosSave = async (imagensToInsert: DeepPartial<ImagemVO>[], imagensToDelete: DeepPartial<ImagemVO>[], galeriaFotos: DeepPartial<ImagemVO>[]) => {
    const sequence = makeRangeIterator(1);
    const operations = [
      ...imagensToInsert.map(img => new BatchOperationDTO<DeepPartial<ImagemVO>>(sequence.next().value, Operation.INSERT, undefined, img)),
      ...imagensToDelete.map(img => new BatchOperationDTO<DeepPartial<ImagemVO>>(sequence.next().value, Operation.DELETE, img.nuImagem, undefined))
    ];
    const resultAction = await dispatch(batchUpdateProjetoGaleriaFotos({ id, operations }));
    if (batchUpdateProjetoGaleriaFotos.fulfilled.match(resultAction)) {
      const returnedOperations = unwrapResult(resultAction);
      enqueueSnackbar('Galeria de fotos atualizada com sucesso!', {
        variant: 'success',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });
      
      // Acrescenta os Ids das imagens que foram inseridas
      for(const img of galeriaFotos){
        if (!img.nuImagem) {
          // Busca a operação submetida
          const submittedOperation = operations.find(op => op.value && op.value.imImagem == img.imImagem);
          if (submittedOperation) {
            // Busca a operação retornada
            const returnedOperation = returnedOperations.find(res => res.sequential == submittedOperation.sequential);
            if (returnedOperation) {
              img.nuImagem = returnedOperation.id; // Seta o id retornado
            }
          }
        }
      }
      setState({ ...state, galeriaFotos: [ ...galeriaFotos ] });
      setStep(ProjetoEditSteps.REVISAO);
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível atualizar galeria de fotos. Tente novamente."))
    }
  }

  const handleDocumentoProspectoSave = async (nextState: DeepPartial<DocumentoVO>) => {
    const resultAction = await dispatch(changeProjetoProspecto({ id, prospecto: nextState }));
    if (changeProjetoProspecto.fulfilled.match(resultAction)) {
      enqueueSnackbar('Prospecto trocado com sucesso!', {
        variant: 'success',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });
      setState({ ...state, documentoProspecto: { ...nextState } });
      setStep(ProjetoEditSteps.REVISAO);
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível trocar prospecto. Tente novamente."))
    }

  }

  const handleDocumentoContratoSave = async (nextState: DeepPartial<DocumentoVO>) => {
    const resultAction = await dispatch(changeProjetoModeloContrato({ id, modeloContrato: nextState }));
    if (changeProjetoModeloContrato.fulfilled.match(resultAction)) {
      enqueueSnackbar('Modelo de contrato trocado com sucesso!', {
        variant: 'success',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });
      setState({ ...state, documentoModeloContrato: { ...nextState } });
      setStep(ProjetoEditSteps.REVISAO);
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível trocar modelo de contrato. Tente novamente."))
    }
  }


  const handleResumo = () => setStep(ProjetoEditSteps.REVISAO)
  const handleDadosBasicosEdit = () => setStep(ProjetoEditSteps.DADOS_BASICOS);
  const handleEnderecoEdit = () => setStep(ProjetoEditSteps.ENDERECO);
  const handleCaptacaoEdit = () => setStep(ProjetoEditSteps.CAPTACAO);
  const handleImagemCapaEdit = () => setStep(ProjetoEditSteps.IMAGEM_CAPA)
  const handleGaleriaFotosEdit = () => setStep(ProjetoEditSteps.GALERIA_FOTOS)
  const handleProspectoEdit = () => setStep(ProjetoEditSteps.PROSPECTO)
  const handleContratoEdit = () => setStep(ProjetoEditSteps.CONTRATO)
  const handleValorCotaEdit = () => setStep(ProjetoEditSteps.VALOR_COTA)

  return (
    <PageTemplate
      metaTags={{
        title: "Gottaup.com | Editar Projeto",
        description: "Atualize os dados do projeto"
      }}
      menuDesktop
      headerMobile={{
        title: "Editar Projeto",
        leftButtons: (
          <IconButton edge="start" color="inherit" component={Link} to={from}>
            <ArrowBackIcon />
          </IconButton>
        )
      }}
      headerDesktop={{
        title: "Editar Projeto",
        breadcrumbs: [
          { label: "Home", to: '/', icon: HomeIcon },
          { label: "Projetos", to: '/admin/projetos', icon: WorkIcon },
          { label: "Editar" }
        ]
      }}
    >

      <Container component="section" style={{ marginTop: 16 }}>

        {requestState.pending &&
          <Loading text="Carregando Formulário" />
        }

        {requestState.error &&
          <ErrorEmptyState
            error={requestState.error}
            defaultDescription="Não foi possível carregar dados do projeto"
            onTryAgainClick={() => dispatch(fetchProjeto({ id }))}
          />
        }

        {requestState.data && state &&

          <Grid container spacing={2}>
            {isDesktop &&
              <Grid item xs={12} md={2}>
                <Stepper nonLinear activeStep={step} orientation="vertical" variant="outlined"  >
                  <Step>
                    <StepButton onClick={handleResumo} icon=" ">Resumo</StepButton>
                  </Step>
                  <Step>
                    <StepButton onClick={handleDadosBasicosEdit} icon=" " style={{ textAlign: 'left' }}>Dados Básicos</StepButton>
                  </Step>
                  <Step>
                    <StepButton onClick={handleEnderecoEdit} icon=" ">Endereço</StepButton>
                  </Step>
                  <Step>
                    <StepButton onClick={handleCaptacaoEdit} icon=" ">Captação</StepButton>
                  </Step>
                  <Step>
                    <StepButton onClick={handleImagemCapaEdit} icon=" " style={{ textAlign: 'left' }}>Imagem Capa</StepButton>
                  </Step>
                  <Step>
                    <StepButton onClick={handleGaleriaFotosEdit} icon=" " style={{ textAlign: 'left' }}>Galeria Fotos</StepButton>
                  </Step>
                  <Step>
                    <StepButton onClick={handleProspectoEdit} icon=" ">Prospecto</StepButton>
                  </Step>
                  <Step>
                    <StepButton onClick={handleContratoEdit} icon=" ">Contrato</StepButton>
                  </Step>
                  <Step>
                    <StepButton onClick={handleValorCotaEdit} icon=" " style={{ textAlign: 'left' }}>Valor Cota</StepButton>
                  </Step>
                </Stepper>
              </Grid>
            }
            <Grid item xs={12} md={10}>
              {step == ProjetoEditSteps.DADOS_BASICOS &&
                <ProjetoDadosBasicosForm
                  projeto={state}
                  submitText="Salvar"
                  onBack={handleResumo}
                  formMode={ProjetoDadosBasicosFormMode.EDIT}
                  onSubmit={handleDadosBasicosSave} />
              }
              {step == ProjetoEditSteps.ENDERECO &&
                <EnderecoForm
                  endereco={state.endereco || {}}
                  onBack={handleResumo}
                  onSubmit={handleEnderecoSave}
                  formMode={EnderecoFormMode.EDIT}
                  submitText="Salvar" />
              }


              {step == ProjetoEditSteps.CAPTACAO &&
                <ProjetoEditCaptacaoForm
                  projeto={state}
                  submitText="Salvar"
                  onSubmit={handleProjetoCaptacaoSave}
                  onBack={handleResumo} />
              }


              {step == ProjetoEditSteps.IMAGEM_CAPA &&
                <ProjetoImagemCapaForm
                  imagem={state.imagemCapa || {}}
                  onSubmit={handleImagemCapaSave}
                  submitText="Salvar"
                  formMode={ProjetoImagemCapaFormMode.EDIT}
                  onBack={handleResumo} />
              }

              {step == ProjetoEditSteps.GALERIA_FOTOS &&
                <ProjetoGaleriaFotosForm
                  galeriaFotos={(state.galeriaFotos || []) as DeepPartial<ImagemVO>[]}
                  onSubmit={handleGaleriaFotosSave}
                  submitText="Salvar"
                  formMode={ProjetoGaleriaFotosFormMode.EDIT}
                  onBack={handleResumo} />
              }

              {step == ProjetoEditSteps.PROSPECTO &&
                <ProjetoDocumentoProspectoForm
                  documento={state.documentoProspecto || {}}
                  onSubmit={handleDocumentoProspectoSave}
                  submitText="Salvar"
                  formMode={ProjetoDocumentoProspectoFormMode.EDIT}
                  onBack={handleResumo} />
              }

              {step == ProjetoEditSteps.CONTRATO &&
                <ProjetoDocumentoContratoForm
                  documento={state.documentoModeloContrato || {}}
                  onSubmit={handleDocumentoContratoSave}
                  submitText="Salvar"
                  formMode={ProjetoDocumentoContratoFormMode.EDIT}
                  onBack={handleResumo} />
              }


              {step == ProjetoEditSteps.REVISAO &&
                <ProjetoReviewCadastro
                  projeto={state}
                  formMode={ProjetoReviewCadastroFormMode.EDIT}
                  onDadosBasicosEdit={handleDadosBasicosEdit}
                  onEnderecoEdit={handleEnderecoEdit}
                  onCaptacaoEdit={handleCaptacaoEdit}
                  onContratoEdit={handleContratoEdit}
                  onGaleriaFotosEdit={handleGaleriaFotosEdit}
                  onImagemCapaEdit={handleImagemCapaEdit}
                  onProspectoEdit={handleProspectoEdit}
                  onHistoricoCotasEdit={handleValorCotaEdit}
                />
              }

              {step == ProjetoEditSteps.VALOR_COTA &&
                <HistoricoCotasList nuProjeto={id} onBack={handleResumo} />
              }

            </Grid>
          </Grid>
        }

      </Container>

    </PageTemplate >
  )
}
import { Box, Button, createStyles, Grid, makeStyles, Paper, Typography, Link } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { DeepPartial } from 'redux';
import { EmpresaVO } from '../../../../../../models/Empresa';
import { ImagemVO } from '../../../../../../models/Imagem';
import { APIUtils } from '../../../../../../services/api/APIUtils';
import ButtonProgress from '../../../../../components/ButtonProgress';
import ConfirmLeavePage from '../../../../../components/ComfirmLeavePage';
import { FieldOutput } from '../../../../../components/form/output/FieldOutput';
import { Formatters } from '../../../../../../utils/formatters';


const useStyles = makeStyles(() => createStyles({
  logo: {
    maxHeight: 200,
    width: 200
  },
}), { name: 'EmpresaReviewCadastro' });


interface EmpresaReviewCadastroProps {
  empresa: DeepPartial<EmpresaVO>;
  onSubmit?: () => Promise<void> | void;
  submitText?: string;
  onBack?: () => void;
  onDadosBasicosEdit: () => void;
  onEnderecoEdit: () => void;
  onDadosBancariosEdit: () => void;
  onLogoEdit: () => void;

}

export const EmpresaReviewCadastro = ({ empresa, onSubmit, onBack, submitText, 
  onDadosBancariosEdit, onDadosBasicosEdit,onEnderecoEdit,onLogoEdit }: EmpresaReviewCadastroProps) => {

  const classes = useStyles();

  const { handleSubmit, formState: { isSubmitting } } = useForm();

  return (
    <>
      <ConfirmLeavePage
        when={!!onSubmit}
        title="Tem certeza que deseja deixar a página sem salvar as alterações?"
        content="Ao confirmar os dados alterados não serão salvos. Se cancelar, poderá continuar a edição."
      />
      <Paper variant="outlined" square >
        <Box p={2}>
          <Box mb={2} display="flex" alignItems="center">
            <Typography variant="h6">Dados Básicos&nbsp;</Typography>
            <Link component="button" variant="subtitle2" color="primary" onClick={onDadosBasicosEdit}>[Editar]</Link>
          </Box>
          <Box ml={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="CNPJ" value={Formatters.cnpj.format(empresa.nuCnpjEmpresa)} />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Nome Fantasia" value={empresa.noFantasia || ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Razão Social" value={empresa.noRazaoSocial || ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Telefone" value={Formatters.telefone.format(empresa.nuTelefone) || '(em branco)'} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Celular" value={Formatters.telefone.format(empresa.nuCelular)} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Email" value={empresa.noEmail || ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="URL Site" value={empresa.noUrlSite || '(em branco)'} />
              </Grid>
            </Grid>
          </Box>

          <Box my={2} display="flex" alignItems="center">
            <Typography variant="h6">Endereço&nbsp;</Typography>
            <Link component="button" variant="subtitle2" color="primary" onClick={onEnderecoEdit}>[Editar]</Link>
          </Box>
          <Box ml={2}>
            <Grid container spacing={2}>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="CEP" value={Formatters.cep.format(empresa.endereco?.coCep)} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="UF" value={empresa.endereco?.noUf || ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Município" value={empresa.endereco?.noMunicipio || ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Bairro" value={empresa.endereco?.noBairro || ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Rua" value={empresa.endereco?.noLogradouro || ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Número" value={empresa.endereco?.nuLogradouro?.toString() || ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Complemento" value={empresa.endereco?.deComplemento || '(em branco)'} />
              </Grid>

            </Grid>
          </Box>

          <Box my={2} display="flex" alignItems="center">
            <Typography variant="h6">Dados Bancários&nbsp;</Typography>
            <Link component="button" variant="subtitle2" color="primary" onClick={onDadosBancariosEdit}>[Editar]</Link>
          </Box>
          <Box ml={2}>
            <Grid container spacing={2}>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Banco" value={Formatters.conta.banco.format(empresa.dadoBancario?.coBanco)} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Agência" value={empresa.dadoBancario?.nuAgencia || ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Conta" value={Formatters.conta.numero.format(empresa.dadoBancario?.nuConta)} />
              </Grid>

            </Grid>
          </Box>

          <Box my={2} display="flex" alignItems="center">
            <Typography variant="h6">Logo&nbsp;</Typography>
            <Link component="button" variant="subtitle2" color="primary" onClick={onLogoEdit}>[Editar]</Link>
          </Box>

          <Box ml={2} mb={2}>
            <img className={classes.logo} src={APIUtils.generateImageURL(empresa.imagemLogo as ImagemVO)} />
          </Box>

          <Grid container justify="flex-end" spacing={2}>
            {onBack &&
              <Grid item>
                <Button
                  id="btnVoltar"
                  variant="text"
                  color="default"
                  onClick={onBack}
                >
                  Voltar
              </Button>
              </Grid>
            }
            {onSubmit &&
              <Grid item>
                <form id="formRevisaCadastro" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                  <ButtonProgress
                    id="btnSubmit"
                    loading={isSubmitting}
                    variant="contained"
                    color="primary"
                    type="submit">
                    {submitText}
                  </ButtonProgress>
                </form>
              </Grid>
            }

          </Grid>

        </Box>
    </Paper >
    </ >
  )
}

import React, {FormEvent, Fragment, useRef, useState} from 'react';
import {
  Box,
  Button,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  Theme
} from '@material-ui/core';
import {Sexo} from '../../../../../models/enums/Sexo';
import {SituacaoComprovanteResidencia} from '../../../../../models/enums/SituacaoComprovanteResidencia';
import {SituacaoSelfie} from '../../../../../models/enums/SituacaoSelfie';
import Investidor from '../../../../../models/Investidor';
import {ConfirmDialog, ConfirmDialogProps} from '../../../../components/ConfirmDialog';
import DownloadBox from '../../../../components/DownloadBox';
import {getPathProperty, setPathProperty} from '../../../../ViewUtils';
import {Link, useLocation} from 'react-router-dom';
import AccountCardDetailsIcon from 'mdi-material-ui/AccountCardDetails';
import {FieldOutput} from "../../../../components/form/output/FieldOutput";


const useStyles = makeStyles((theme: Theme) => createStyles({
  form: {
    marginBottom: 0
  },
  listItem: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(3, 2),
  },
}), {name: 'ValidacaoDocumentosInvestidorItem'});


interface Props {
  investidor: Investidor;
  onFormUpdate?: (unsaved: boolean) => void;
  onFormSubmit: (coContaUsuario: number, investidor: Investidor) => void;
}

interface State {
  investidor: Investidor;
}

export default function ValidacaoDocumentosInvestidorItem(props: Props) {

  const classes = useStyles();
  const {investidor} = props;
  const [dialog, setDialog] = useState<ConfirmDialogProps | undefined>(undefined);
  const state = useRef<State>({investidor: new Investidor()});
  const fieldsUpdated = useRef<string[]>([]);
  const formRef = useRef<HTMLFormElement>(null);
  const [unsavedFormData, setUnsavedFormData] = useState(false);
  const [invalidFormData, setInvalidFormData] = useState(false);
  const location = useLocation();


  const handleValidInput = (path: string) => (newValue: string | number | boolean) => {
    const initialValue = getPathProperty(path, props);
    const currValue = getPathProperty(path, state.current);
    if (initialValue != newValue) {
      setPathProperty(path, state.current, newValue);
      if (!fieldsUpdated.current.includes(path)) {
        fieldsUpdated.current.push(path); // Marca que o campo foi alterado
      }
      if (!unsavedFormData) {
        setUnsavedFormData(true); // Seta que o form possui alterações não salvas
        if (props.onFormUpdate) {
          props.onFormUpdate(true);
        }
      }
      console.log(state.current, fieldsUpdated.current.length);
    } else {
      if (currValue) {
        setPathProperty(path, state.current, undefined); // É igual a valor inicial, então desmarca a alteração
      }
      fieldsUpdated.current = fieldsUpdated.current.filter(field => field != path); // Desmarca que o campo foi alterado
      if (fieldsUpdated.current.length == 0 && unsavedFormData) {
        setUnsavedFormData(false); // Seta que o form deixou de possuir alterações não salvas
        if (props.onFormUpdate) {
          props.onFormUpdate(false);
        }
      }
      console.log(state.current, fieldsUpdated.current.length);
    }
    // Revalida form para verificar se zerou as pendências
    if (formRef.current && formRef.current.checkValidity()) {
      setInvalidFormData(false); // Seta que o form não possui dados inválidos
    } else {
      setInvalidFormData(true); // Seta que o form possui dados inválidos
    }
  }

  const validaConfirmaUpdate = (e: FormEvent) => {
    e.preventDefault();
    setDialog({
      open: true,
      title: "Confirma alteração da situação dos documentos do investidor " + (props.investidor.contaUsuario && props.investidor.contaUsuario.noUsuario) + "?",
      content: "Ao confirmar as informações serão salvas. Se cancelar, nenhum dado será salvo no servidor.",
      onConfirm: () => {
        setDialog(undefined); // Fecha dialog
        props.onFormSubmit((props.investidor.coContaUsuario) || 0, state.current.investidor); //chama evento no pai para persistir
        fieldsUpdated.current = []; // Zera controles de alteração
        setUnsavedFormData(false); // Zera controles de alteração
        state.current = {investidor: new Investidor()} // Zera controles de alteração
      },
      onCancel: () => setDialog(undefined)
    })
  }


  return (
    <Fragment>
      {dialog && <ConfirmDialog {...dialog} />}

      <Paper className={classes.listItem}>

        <form
          noValidate
          autoComplete="off"
          className={classes.form}
          onSubmit={validaConfirmaUpdate}
          ref={formRef}>

          <Grid container spacing={2}>

            <Grid item xs={12} sm={4} md={2}>
              <FieldOutput label="Cadastro" value={investidor.tsCadastroFormatado}/>
            </Grid>

            <Grid item xs={12} sm={8} md={10}>
              <FieldOutput label="Investidor" value={investidor.contaUsuario?.noUsuario}/>
            </Grid>
          </Grid>

          {investidor.icSituacaoSelfie === SituacaoSelfie["Em Análise"] &&
          <>

            <Box mb={3}/>
            <Divider/>
            <Box mt={3}/>
            <Grid container spacing={2}>

              <Grid item xs={6} sm={4} md={2}>
                <FieldOutput label="CPF" value={investidor.nuCpfCnpjInvestidorFormatado}/>
              </Grid>

              <Grid item xs={6} sm={4} md={2}>
                <FieldOutput label="RG" value={investidor.coRg}/>
              </Grid>

              <Grid item xs={6} sm={4} md={2}>
                <FieldOutput label="Orgão Emissor" value={investidor.noOrgaoEmissorRg}/>
              </Grid>

              <Grid item xs={6} sm={4} md={2}>
                <FieldOutput label="Data Nascimento" value={investidor.dtNascimentoFormatado}/>
              </Grid>

              <Grid item xs={6} sm={4} md={2}>
                <FieldOutput label="Sexo" value={investidor.icSexo && Sexo[investidor.icSexo]}/>
              </Grid>

              <Grid item xs={12} sm={7} md={5}>
                {(investidor.documentoSelfieAutentica && investidor.documentoSelfieAutentica.documentoURL) &&
                <DownloadBox name="Selfie com Documento" url={investidor.documentoSelfieAutentica.documentoURL}
                             icon="img"/>
                }

              </Grid>

              <Hidden smDown>
                <Grid item md={3}>
                  <Box/>
                </Grid>
              </Hidden>
              <Grid item xs={12} sm={5} md={4}>
                <FormControl component="fieldset" required>
                  <FormLabel component="legend">Análise Selfie com Documento</FormLabel>
                  <RadioGroup name="icSituacaoSelfie" row={true}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => {
                                handleValidInput("investidor.icSituacaoSelfie")(Number(value));
                              }}>
                    <FormControlLabel value={SituacaoSelfie.Aprovado.toString()} control={<Radio required/>}
                                      label={SituacaoSelfie[SituacaoSelfie.Aprovado]}/>
                    <FormControlLabel value={SituacaoSelfie.Inválido.toString()} control={<Radio/>}
                                      label={SituacaoSelfie[SituacaoSelfie.Inválido]}/>
                  </RadioGroup>
                </FormControl>


              </Grid>


            </Grid>
          </>

          }

          {investidor.icSituacaoComprovanteResidencia === SituacaoComprovanteResidencia["Em Análise"] &&
          <>
            <Box mb={3}/>
            <Divider/>
            <Box mt={3}/>
            <Grid container spacing={2}>

              <Grid item xs={4} sm={4} md={2}>
                <FieldOutput label="CEP" value={investidor.endereco?.coCepFormatado}/>
              </Grid>

              <Grid item xs={8} sm={4} md={2}>
                <FieldOutput label="Município"
                             value={investidor.endereco?.noMunicipio + " / " + investidor.endereco?.noUf}/>
              </Grid>

              <Grid item xs={8} sm={12} md={6}>
                <FieldOutput label="Endereço"
                             value={investidor.endereco?.noLogradouro + ", " + investidor.endereco?.nuLogradouro + " - " + investidor.endereco?.deComplemento + " - " + investidor.endereco?.noBairro}/>
              </Grid>


              <Grid item xs={12} sm={7} md={5}>
                {(investidor.documentoComprovanteResidencia && investidor.documentoComprovanteResidencia.documentoURL) &&
                <DownloadBox name="Comprovante Residência" url={investidor.documentoComprovanteResidencia.documentoURL}
                             icon="file"/>
                }

              </Grid>

              <Hidden smDown>
                <Grid item md={3}>
                  <Box/>
                </Grid>
              </Hidden>

              <Grid item xs={12} sm={5} md={4}>
                <FormControl component="fieldset" required>
                  <FormLabel component="legend">Análise Comprovante de Residencia</FormLabel>
                  <RadioGroup name="icSituacaoComprovanteResidencia" row
                              onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => {
                                handleValidInput("investidor.icSituacaoComprovanteResidencia")(Number(value));
                              }}>
                    <FormControlLabel value={SituacaoComprovanteResidencia.Aprovado.toString()}
                                      control={<Radio required/>}
                                      label={SituacaoComprovanteResidencia[SituacaoComprovanteResidencia.Aprovado]}/>
                    <FormControlLabel value={SituacaoComprovanteResidencia.Inválido.toString()} control={<Radio/>}
                                      label={SituacaoComprovanteResidencia[SituacaoComprovanteResidencia.Inválido]}/>
                  </RadioGroup>
                </FormControl>


              </Grid>

            </Grid>
          </>


          }

          <Box mb={3}/>
          <Divider/>
          <Box mt={3}/>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <Grid container justify="flex-end" spacing={2}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    component={Link}
                    startIcon={<AccountCardDetailsIcon/>}
                    to={{
                      pathname: `/conta/${investidor.coContaUsuario}/investidor/edit`,
                      state: {from: location.pathname}
                    }}
                  >
                    Cadastro
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    type="submit"
                    disabled={invalidFormData || !unsavedFormData}
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>


        </form>

      </Paper>


    </Fragment>

  )
}
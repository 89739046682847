import React, { useState } from 'react'
import { Typography, Slider, makeStyles, createStyles, Theme, Grid, Button } from '@material-ui/core';
import Cropper, { Area } from 'react-easy-crop';
import CheckIcon from '@material-ui/icons/Check';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';


const useStyles = makeStyles((theme: Theme) => createStyles({
    cropperContainer: {
        position: 'relative',
        width: '100%',
        height: '250px'
    },
    cropperControls: {
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
        }
    },
    sliderContainer: {
        display: 'flex',
        flex: '1',
        alignItems: 'center',
    },
    slider: {
        padding: '12px 0px',
        marginLeft: 16,
        marginRight: 16,
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
            margin: '0 16px',
        },
    },
}), { name: 'FormPhotoUploadEditor' });


interface FormPhotoUploadEditorProps {
    image: string;
    aspect?: number;
    showGrid?: boolean;
    cropShape?: "rect" | "round";
    onFinishEditClick: (pixelCrop: Area, rotation: number) => void;
}

export const FormPhotoUploadEditor = ({ image, aspect, showGrid, cropShape, onFinishEditClick }: FormPhotoUploadEditorProps) => {

    const classes = useStyles();

    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState<number>(1);
    const [pixelCrop, setPixelCrop] = useState<Area>();
    const [rotation, setRotation] = useState<number>(0);

    const handleZoomIn = () => { if (zoom < 5) setZoom(zoom + 0.05) }
    const handleZoomOut = () => { if (zoom > 1) setZoom(zoom - 0.05) }
    const handleRotateLeft = () => { if (rotation > 0) setRotation(rotation - 1) }
    const handleRotateRight = () => { if (rotation < 360) setRotation(rotation + 1) }
    const handleChangeRotation = (event: React.ChangeEvent<{}>, rotation: number | number[]) => setRotation(rotation as number);
    const handleChangeZoom = (event: React.ChangeEvent<{}>, zoom: number | number[]) => setZoom(zoom as number);
    const handleCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
        setPixelCrop(croppedAreaPixels);
    }
    const handleFinishEditClick = () => {
        onFinishEditClick(pixelCrop as Area, rotation);
    }

    return (
        <>
            <div className={classes.cropperContainer}>
                <Cropper
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    maxZoom={5}
                    rotation={rotation}
                    cropShape={cropShape}
                    aspect={aspect}
                    showGrid={showGrid}
                    onCropChange={setCrop}
                    onCropComplete={handleCropComplete}
                    onZoomChange={setZoom}
                    onRotationChange={setRotation}
                />
            </div>
            <Typography variant="overline">Zoom</Typography>
            <div className={classes.sliderContainer}>

                <ZoomOutIcon onClick={handleZoomOut} />
                <Slider
                    value={zoom}
                    min={1}
                    max={5}
                    step={0.05}
                    aria-labelledby="Zoom"
                    className={classes.slider}
                    onChange={handleChangeZoom}
                />
                <ZoomInIcon onClick={handleZoomIn} />

            </div>
            <Typography variant="overline">Rotação</Typography>
            <div className={classes.sliderContainer}>
                <RotateLeftIcon onClick={handleRotateLeft} />
                <Slider
                    value={rotation}
                    min={0}
                    max={360}
                    step={1}
                    aria-labelledby="Rotação"
                    className={classes.slider}
                    onChange={handleChangeRotation}
                />
                <RotateRightIcon onClick={handleRotateRight} />
            </div>
            <Grid container alignItems="center" justify="center">
                <Grid item>
                    <Button color="default" variant="outlined" onClick={handleFinishEditClick} startIcon={<CheckIcon />}>Finalizar</Button>
                </Grid>
            </Grid>
        </>

    )
}

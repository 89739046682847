import { parse } from 'date-fns';
import { formatPorcentagem } from '../views/ViewUtils';
import ISODate from './types/ISODate';

export interface TaxaJurosVO {
  coTaxaJuros: number;
  dtInicio: string;
  dtFim: string;
  pcTaxaSelic: number;
  pcTaxaCdi: number;
  pcTaxaPoupanca: number;
}

export default class TaxaJuros {

    public coTaxaJuros: number | undefined;
    public dtInicio: ISODate | undefined;
    public dtFim: ISODate | undefined;
    public pcTaxaSelic: number | undefined;
    public pcTaxaCdi: number | undefined;
    public pcTaxaPoupanca: number | undefined;
    
    constructor(obj: object | null = null) {
        if (obj) {
            Object.assign(this, obj);
            if (this.dtInicio) this.dtInicio = new ISODate(String(this.dtInicio))
            if (this.dtFim) this.dtFim = new ISODate(String(this.dtFim))
        }
        Object.seal(this); // Não permite alterar estrutura da classe
    }

    set dtInicioFormatado(value) {
        this.dtInicio = value ? new ISODate(parse(value, 'dd/MM/yyyy', new Date())) : undefined;
    }

    get dtInicioFormatado() {
        return this.dtInicio ? this.dtInicio.toFormat('dd/MM/yyyy') : ''
    }

    set dtFimFormatado(value) {
        this.dtFim = value ? new ISODate(parse(value, 'dd/MM/yyyy', new Date())) : undefined;
    }

    get dtFimFormatado() {
        return this.dtFim ? this.dtFim.toFormat('dd/MM/yyyy') : ''
    }

    set pcTaxaSelicFormatado(value: string) {
        this.pcTaxaSelic = value ? Number.parseFloat(('0000' + value).replace(',', '').replace(/^(0+)(\d+)(\d{4})$/g, '$2.$3')) : undefined;
    }

    get pcTaxaSelicFormatado() {
        return this.pcTaxaSelic ? formatPorcentagem(this.pcTaxaSelic) : '';
    }

    set pcTaxaCdiFormatado(value: string) {
        this.pcTaxaCdi = value ? Number.parseFloat(('0000' + value).replace(',', '').replace(/^(0+)(\d+)(\d{4})$/g, '$2.$3')) : undefined;
    }

    get pcTaxaCdiFormatado() {
        return this.pcTaxaCdi ? formatPorcentagem(this.pcTaxaCdi) : '';
    }

    set pcTaxaPoupancaFormatado(value: string) {
        this.pcTaxaPoupanca = value ? Number.parseFloat(('0000' + value).replace(',', '').replace(/^(0+)(\d+)(\d{4})$/g, '$2.$3')) : undefined;
    }

    get pcTaxaPoupancaFormatado() {
        return this.pcTaxaPoupanca ? formatPorcentagem(this.pcTaxaPoupanca) : '';
    }


}
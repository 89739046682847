import { getServiceURL } from "../ServiceURL";
import Mime from 'mime'
import { AxiosError } from "axios";
import { APIError } from "./APIError";
import { ImagemVO } from "../../models/Imagem";
import { DeepPartial } from "@reduxjs/toolkit";
import { DocumentoVO } from "../../models/Documento";

const generateImageURL = (imagem: DeepPartial<ImagemVO> | undefined) => {
  if (!imagem) return ''
  if (imagem.nuImagem) {
    return `${getServiceURL()}/api/imagens/${imagem.nuImagem}`;
  } else if (imagem.imImagem && imagem.noImagem) {
    return `data:${Mime.getType(imagem.noImagem)};charset=utf-8;base64,${imagem.imImagem}`;
  } else {
    return ''
  }
}

const generateDocumentoURL = (documento: DeepPartial<DocumentoVO> | undefined) => {
  if (!documento) return ''
  if (documento.nuDocumento) {
    return `${getServiceURL()}/api/documentos/${documento.nuDocumento}`;
  } else if (documento.imDocumento && documento.noDocumento) {
    return `data:${Mime.getType(documento.noDocumento)};charset=utf-8;base64,${documento.imDocumento}`;
  } else {
    return ''
  }
}

const errorToAPIError = (ex: Error): APIError => {
  let type = ex.name;
  let message = ex.message;
  switch (ex.message) {
    case "Network Error":
      type = "NetworkError"
      message = "Falha de comunicação";
      break;
    case "Offline Error":
      type = "OfflineError"
      message = "Sem conexão de internet";
      break;
    case "Request failed with status code 401":
      type = "UnauthorizedError"
      message = "Falha na autenticação. Tente efetuar novo login.";
      break;
    case "Timeout has occurred":
      type = "TimeoutError"
      message = "Timeout na conexão com o servidor.";
      break;

    /*
    case "ajax error":
        // Erro de conexão Rxjs
        message = "Falha na requisição com o servidor";
        break;
    case "Timeout has occurred":
        // Timeout Rxjs
        message = "Timeout na transação";
        break;
    case "Aborted":
        message = "Transação cancelada";
        break;
    case "Failed to fetch":
        // Erro de conexão no Chrome
        message = "Falha na requisição com o servidor";
        break;
    case "NetworkError when attempting to fetch resource.":
        // Erro de conexão no Firefox
        message = "Falha na requisição com o servidor";
        break;
    case "The user aborted a request.":
        // Timeout no Chrome
        message = "Timeout na transação";
        break;
    case "The operation was aborted. ":
        // Timeout no Firefox
        message = "Timeout na transação";
        break;
    */

    default:
      break;
  }
  return {
    message,
    type
  };
}


const axiosErrorToAPIError = (err: AxiosError<APIError>) => {
  if (err.response?.data) {
    throw err.response.data;
  } else {
    throw errorToAPIError(err);
  }
}



export const APIUtils = {
  generateImageURL,
  generateDocumentoURL,
  axiosErrorToAPIError
}
import { Box, Button, Container, createStyles, Grid, IconButton, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { blue, green, red } from '@material-ui/core/colors';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import CheckIcon from '@material-ui/icons/Check';
import GroupIcon from '@material-ui/icons/Group';
import HomeIcon from '@material-ui/icons/Home';
import MoneyIcon from '@material-ui/icons/Money';
import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';
import WorkIcon from '@material-ui/icons/Work';
import clsx from 'clsx';
import TimerSandIcon from 'mdi-material-ui/TimerSand';
import React, { Fragment, useEffect, useState } from 'react';
import { FormattedPlural } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, match, useLocation, useRouteMatch } from 'react-router-dom';
import Empresa from '../../../../../../../models/Empresa';
import { MotivoCancelamentoInvestimento } from '../../../../../../../models/enums/MotivoCancelamentoInvestimento';
import { SituacaoInvestimento } from '../../../../../../../models/enums/SituacaoInvestimento';
import Investimento from '../../../../../../../models/Investimento';
import APIException from '../../../../../../../services/APIException';
import ProjetoService from '../../../../../../../services/ProjetoService';
import { BannerProps } from '../../../../../../components/Banner';
import DashboardTotalizador from '../../../../../../components/DashboardTotalizador';
import EmptyState from '../../../../../../components/EmptyState';
import Loading from '../../../../../../components/Loading';
import OnlyDesktop from '../../../../../../layout/OnlyDesktop';
import OnlyMobile from '../../../../../../layout/OnlyMobile';
import PageTemplate from '../../../../../../layout/PageTemplate';
import { formatMoeda, reducerTotalNumber } from '../../../../../../ViewUtils';
import { selectToken } from '../../../../../../../stores/slices/userSlice';
import { PageSubtitle } from '../../../../../../layout/PageSubtitle';


const useStyles = makeStyles((theme: Theme) => createStyles({
    fieldGroup: {
        marginTop: theme.spacing(2)
    },
    paragrafo: {
        marginLeft: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(1),
    },
    listItem: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(3, 2),
    },
    listFieldName: {
        color: '#546e7a'
    },
    listFieldValue: {
        fontWeight: 500
    },
    statusReserva: {
        color: blue[500]
    },
    statusAprovado: {
        color: green[500]
    },
    statusCancelado: {
        color: red[600]
    },

}), { name: 'InvestimentosByProjetoList' });


interface State {
    investimentos: Investimento[];
    empresa: Empresa;
}

export async function loadInvestimentosByProjetoInitialState(matcher: match<{ id: string }> | null, token: string): Promise<State | undefined> {
    if (matcher && matcher.params.id) {
        const empresa = await new ProjetoService().findEmpresa(parseInt(matcher.params.id), token);
        const investimentos = await new ProjetoService().listInvestimentosByProjeto(parseInt(matcher.params.id), token);
        if (empresa && investimentos) {
            return { empresa, investimentos };
        }
    }
    return undefined;
}


export const InvestimentosByProjetoListPage = () => {

    const classes = useStyles();

    const token = useSelector(selectToken)||'';

    const match = useRouteMatch<{ id: string }>();

    const [isLoading, setIsLoading] = useState(true);
    const [banner, setBanner] = useState<BannerProps | undefined>(undefined);

    const location = useLocation();
    const { from } = location.state as any || { from: `/admin/projetos/${match && match.params.id}` };

    const [initialState, setInitialState] = useState<State | undefined>();

    const fetchInitialState = () => {
        // Se não renderizado no servidor, faz o fetch
        if (!initialState) {
            setIsLoading(true);
            loadInvestimentosByProjetoInitialState(match, token)
                .then((data) => {
                    setInitialState(data);
                })
                .catch((apiexception: APIException) => {
                    setBanner({
                        text: `Erro ao buscar investimentos: ${apiexception.message}`,
                        traceId: apiexception.errorJson && apiexception.errorJson.traceid,
                        timestamp: apiexception.errorJson && apiexception.errorJson.timestamp,
                        icon: <SignalWifiOffIcon />,
                        buttons: <Button onClick={() => { setBanner(undefined); fetchInitialState(); }} color="primary">Tentar Novamente</Button>
                    })
                    scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    console.log(apiexception);
                })
                .finally(() => setIsLoading(false))
        }
    }
    useEffect(fetchInitialState, [token])

    if (!initialState) {
        return (
            <PageTemplate
                menuDesktop
                headerMobile={{
                    title: "Investimentos",
                    leftButtons: (
                        <IconButton edge="start" color="inherit" component={Link} to={from}>
                            <ArrowBackIcon />
                        </IconButton>
                    )
                }}
                loading={{ isLoading }}
                banner={banner}
            >
                {isLoading && <Loading text="Carregando investimentos..." />}
            </PageTemplate>
        )
    } else {
        return (
            <PageTemplate
                metaTags={{
                    title: "Gottaup.com | Investimentos",
                    description: "Investimentos do projeto"
                }}
                menuDesktop
                headerMobile={{
                    title: "Investimentos",
                    leftButtons: (
                        <IconButton edge="start" color="inherit" component={Link} to={from}>
                            <ArrowBackIcon />
                        </IconButton>
                    )
                }}
                headerDesktop={{
                    title: `${initialState.empresa.noFantasia}: Investimentos`,
                    breadcrumbs: [
                        { label: "Home", to: '/', icon: HomeIcon },
                        { label: "Projetos", to: '/admin/projetos', icon: WorkIcon },
                        { label: initialState.empresa.noFantasia || '', to: `/admin/projetos/${match && match.params.id}`, icon: BusinessCenterIcon },
                        { label: "Investimentos" }
                    ]
                }}
                banner={banner}
            >
                <Container component="section">

                    {initialState.investimentos.length === 0 &&
                        <EmptyState
                            icon={<TimerSandIcon style={{ fontSize: '58px' }} color="disabled" />}
                            title="Não há investimentos até o momento."
                            subtitle="Quando investidores fizerem reservas elas serão listadas aqui"
                        />
                    }

                    {initialState.investimentos.length > 0 &&
                        <Fragment>

                            <OnlyMobile>
                                <Box my={3} />
                            </OnlyMobile>

                            <Grid container spacing={4}>

                                <Grid item lg={4} sm={6} xs={12}>

                                    <DashboardTotalizador
                                        nome="RESERVAS ATIVAS"
                                        icon={<GroupIcon />}
                                        iconBackgroundColor="grey"
                                        valor={initialState.investimentos.filter(i => i.situacaoInvestimento !== SituacaoInvestimento.Cancelado).length.toString()}
                                    />

                                </Grid>

                                <Grid item lg={4} sm={6} xs={12}>

                                    <DashboardTotalizador
                                        nome="TOTAL RESERVADO"
                                        icon={<MoneyIcon />}
                                        iconBackgroundColor="blue"
                                        valor={`R$ ${formatMoeda(initialState.investimentos.filter(i => i.situacaoInvestimento !== SituacaoInvestimento.Cancelado).map(i => i.vrInvestimento as number).reduce(reducerTotalNumber, 0))}`}
                                    />

                                </Grid>

                                <Grid item lg={4} sm={6} xs={12}>

                                    <DashboardTotalizador
                                        nome="TOTAL APROVADO"
                                        icon={<CheckIcon />}
                                        iconBackgroundColor="green"
                                        valor={`R$ ${formatMoeda(initialState.investimentos.filter(i => i.situacaoInvestimento === SituacaoInvestimento.Aprovado).map(i => i.vrInvestimento as number).reduce(reducerTotalNumber, 0))}`}
                                    />

                                </Grid>
                            </Grid>

                            <PageSubtitle>
                              Exibindo {initialState.investimentos.length} <FormattedPlural value={initialState.investimentos.length} one="investimento" other="investimentos" />
                            </PageSubtitle>

                            {initialState.investimentos.map(investimento => {
                                return (
                                    <Paper key={investimento.coInvestimento} className={classes.listItem}>
                                        <Grid container alignItems="center">

                                            <Grid item xs={6} sm={2} md={2}>
                                                <Grid container direction="column">
                                                    <Grid item>
                                                        <Typography variant="subtitle2" className={classes.listFieldValue}>{investimento.dhReservaFormatado}</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="caption" className={classes.listFieldName}>Data Reserva</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={6} sm={2} md={2}>
                                                <Grid container direction="column">
                                                    <Grid item>
                                                        <Typography variant="subtitle2" className={classes.listFieldValue}>{`R$ ${investimento.vrInvestimentoFormatado}`}</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="caption" className={classes.listFieldName}>Valor</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>


                                            <Grid item xs={12} sm={4} md={4}>
                                                <Grid container direction="column">
                                                    <Grid item>
                                                        <Typography variant="subtitle2" className={classes.listFieldValue}>{investimento.contaUsuario?.noUsuario || ''}</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="caption" className={classes.listFieldName}>Investidor</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>


                                            <Grid item xs={6} sm={2} md={2}>
                                                <Grid container direction="column">
                                                    <Grid item>
                                                        <Typography variant="subtitle2" className={clsx({
                                                            [classes.listFieldValue]: true,
                                                            [classes.statusReserva]: investimento.situacaoInvestimento === SituacaoInvestimento.Reserva,
                                                            [classes.statusAprovado]: investimento.situacaoInvestimento === SituacaoInvestimento.Aprovado,
                                                            [classes.statusCancelado]: investimento.situacaoInvestimento === SituacaoInvestimento.Cancelado,
                                                        })} >
                                                            {investimento.situacaoInvestimento && SituacaoInvestimento[investimento.situacaoInvestimento]}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="caption" className={classes.listFieldName}>Situação</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={6} sm={2} md={2}>
                                                {investimento.motivoCancelamentoInvestimento &&
                                                    <Grid container direction="column">
                                                        <Grid item>
                                                            <Typography variant="subtitle2" className={classes.statusCancelado}>{MotivoCancelamentoInvestimento[investimento.motivoCancelamentoInvestimento]}</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="caption" className={classes.listFieldName}>Motivo Cancelamento</Typography>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </Grid>

                                            {/* 
                                                <Grid item sm={2} md={2}>
                                                    <Grid container justify="flex-end">
                                                        <Button
                                                            color="secondary"
                                                            variant="outlined"
                                                            component={Link}
                                                            startIcon={<SearchIcon />}
                                                            to="#">
                                                            Detalhes
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                */}
                                        </Grid>

                                    </Paper>
                                );
                            })}
                            <Box my={3} />
                            {from &&
                                <OnlyDesktop>
                                    <Box display="flex" justifyContent="flex-end" mt={2} mb={2}>
                                        <Button variant="contained" color="secondary" component={Link} to={from}>Voltar</Button>
                                    </Box>
                                </OnlyDesktop>
                            }
                        </Fragment>
                    }


                </Container>


            </PageTemplate>
        )
    }
}
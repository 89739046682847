import React, { useEffect, useReducer } from 'react'

interface LoadingTextProps {
  text: string;
}

export const LoadingText = ({text}: LoadingTextProps) => {

  //const [count, increment] = useReducer(count => count>2?0:count++, 0);
  const [dots, flipDots] = useReducer(count => count>2?0:count+1, 0);

  useEffect(() => {
    const interval = setInterval(() => flipDots(), 500);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <>{text}{".".repeat(dots)}</>
  )
}

import { Box, Container, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HomeIcon from '@material-ui/icons/Home';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AppDispatch } from '../../../../../../browser';
import { fetchTaxasJurosList, selectTaxaJuros, trackFetchTaxasJuros } from '../../../../../stores/slices/entities/taxaJurosSlice';
import { ISO_MAX_DATE } from '../../../../../utils/datetime';
import ErrorEmptyState from '../../../../components/empty/ErrorEmptyState';
import Loading from '../../../../components/Loading';
import { PageSubtitle } from '../../../../layout/PageSubtitle';
import PageTemplate from '../../../../layout/PageTemplate';
import { TaxaJurosForm, TaxaJurosFormMode } from './components/TaxaJurosForm';

export const TaxaJurosListPage = () => {

  const requestState = useSelector(trackFetchTaxasJuros);
  const taxasJuros = useSelector(selectTaxaJuros);

  const dispatch = useDispatch<AppDispatch>();

  const location = useLocation();
  const { from } = location.state as any || { from: '/conta' };

  useEffect(() => {
    const promise = dispatch(fetchTaxasJurosList());
    return () => promise.abort();
  }, [dispatch]);


  return (
    <PageTemplate
      metaTags={{
        title: "Gottaup.com | Atualização de Taxas de Juros",
        description: "Atualize as taxas de juros"
      }}
      menuDesktop
      headerMobile={{
        title: "Atualização de Taxas de Juros",
        leftButtons: (
          <IconButton edge="start" color="inherit" component={Link} to={from}>
            <ArrowBackIcon />
          </IconButton>
        )
      }}
      headerDesktop={{
        title: "Atualização de Taxas de Juros",
        breadcrumbs: [
          { label: "Home", to: '/', icon: HomeIcon },
          { label: "Taxas de Juros" }
        ]
      }}
    >

      <Container component="section" >

        {requestState.pending &&
          <Loading text="Carregando Formulário" />
        }

        {requestState.error &&
          <ErrorEmptyState
            error={requestState.error}
            defaultDescription="Não foi possível buscar taxas de juros"
            onTryAgainClick={() => dispatch(fetchTaxasJurosList())}
          />
        }

        {requestState.data &&
          <>
            {taxasJuros.length === 0 &&
              <>
                <PageSubtitle>Nova Taxa</PageSubtitle>
                <TaxaJurosForm formMode={TaxaJurosFormMode.CREATE} />
              </>
            }
            {taxasJuros.length > 0 &&
              <>

                {taxasJuros[0].dtFim && !moment(taxasJuros[0].dtFim).isSame(ISO_MAX_DATE) &&
                  <>
                    <PageSubtitle>Nova Taxa</PageSubtitle>
                    <TaxaJurosForm formMode={TaxaJurosFormMode.CREATE} dtInicioCreate={moment(taxasJuros[0].dtFim).add(1, 'day')} />
                    <PageSubtitle>Histórico</PageSubtitle>
                    {taxasJuros.map((taxaJuros) => (
                      <TaxaJurosForm formMode={TaxaJurosFormMode.VIEW} key={taxaJuros.coTaxaJuros} taxaJuros={taxaJuros} />
                    ))}
                  </>
                }

                {taxasJuros[0].dtFim && moment(taxasJuros[0].dtFim).isSame(ISO_MAX_DATE) &&
                  <>
                    <PageSubtitle>Vigente</PageSubtitle>
                    <TaxaJurosForm formMode={TaxaJurosFormMode.EDIT} taxaJuros={taxasJuros[0]} />

                    {taxasJuros.length > 1 &&
                      <>
                        <PageSubtitle>Histórico</PageSubtitle>
                        {taxasJuros.filter((element, index) => index > 0).map((taxaJuros) => (
                          <TaxaJurosForm formMode={TaxaJurosFormMode.VIEW} key={taxaJuros.coTaxaJuros} taxaJuros={taxaJuros} />
                        ))}
                      </>
                    }
                  </>
                }

                <Box mb={3} />

              </>

            }
          </>

        }

      </Container>

    </PageTemplate>

  )
}
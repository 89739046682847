import { Container, Grid, IconButton, Step, StepLabel, Stepper, useMediaQuery, useTheme } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import BusinessIcon from '@material-ui/icons/Business';
import HomeIcon from '@material-ui/icons/Home';
import { DeepPartial } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { DadoBancarioVO } from '../../../../../models/DadoBancario';
import { EmpresaVO } from '../../../../../models/Empresa';
import { EnderecoVO } from '../../../../../models/Endereco';
import { ImagemVO } from '../../../../../models/Imagem';
import PageTemplate from '../../../../layout/PageTemplate';
import { DadosBancariosForm, DadosBancariosFormMode } from '../../../../components/form/shared/DadosBancariosForm';
import { EmpresaDadosBasicosForm, EmpresaDadosBasicosFormMode } from './components/EmpresaDadosBasicosForm';
import { EmpresaReviewCadastro } from './components/EmpresaReviewCadastro';
import { EmpresaSucessoCadastro } from './components/EmpresaSucessoCadastro';
import { EnderecoForm, EnderecoFormMode } from '../../../../components/form/shared/EnderecoForm';
import { EmpresaImagemLogoForm, EmpresaImagemLogoFormMode } from './components/EmpresaImagemLogoForm';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../../../browser';
import { createEmpresa } from '../../../../../stores/slices/entities/empresasSlice';
import { APIError } from '../../../../../services/api/APIError';
import { generateErrorDialog } from '../../../../../services/api/APIErrorUtils';
import { useDialog } from '../../../../components/dialog/PageServiceProvider';

enum EmpresaCreateSteps {
  DADOS_BASICOS,
  ENDERECO,
  DADOS_BANCARIOS,
  LOGO,
  REVISAO,
  SUCESSO
}

export const EmpresaCreatePage = () => {

  const history = useHistory();
  const { from } = history.location.state as any || { from: { pathname: "/admin/empresas" } };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const dispatch = useDispatch<AppDispatch>()

  const dialog = useDialog();

  const [step, setStep] = useState(EmpresaCreateSteps.DADOS_BASICOS);

  const [state, setState] = useState<DeepPartial<EmpresaVO>>({});

  const handleEmpresaDadosBasicosComplete = (nextState: DeepPartial<EmpresaVO>) => {
    setState({ ...state, ...nextState });
    setStep(EmpresaCreateSteps.ENDERECO);
    scrollTo({ top: 0, behavior: "smooth" })
  };

  const handleEnderecoComplete = (nextState: DeepPartial<EnderecoVO>) => {
    setState({ ...state, endereco: { ...nextState } });
    setStep(EmpresaCreateSteps.DADOS_BANCARIOS);
    scrollTo({ top: 0, behavior: "smooth" })
  };

  const handleDadosBancariosComplete = (nextState: DeepPartial<DadoBancarioVO>) => {
    setState({ ...state, dadoBancario: { ...nextState } });
    setStep(EmpresaCreateSteps.LOGO);
    scrollTo({ top: 0, behavior: "smooth" })
  };

  const handleImagemLogoComplete = (nextState: DeepPartial<ImagemVO>) => {
    setState({ ...state, imagemLogo: { ...nextState } });
    setStep(EmpresaCreateSteps.REVISAO);
    scrollTo({ top: 0, behavior: "smooth" })
  };

  const handleRevisaoComplete = async () => {
    const resultAction = await dispatch(createEmpresa({ empresa: state }));
    if (createEmpresa.fulfilled.match(resultAction)) {
      setStep(EmpresaCreateSteps.SUCESSO);
      scrollTo({ top: 0, behavior: "smooth" })
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível concluir o cadastro da empresa. Tente novamente."))
    }

  };

  const handleDadosBasicosEdit = () => setStep(EmpresaCreateSteps.DADOS_BASICOS)
  const handleEnderecoEdit = () => setStep(EmpresaCreateSteps.ENDERECO)
  const handleDadosBancariosEdit = () => setStep(EmpresaCreateSteps.DADOS_BANCARIOS)
  const handleLogoEdit = () => setStep(EmpresaCreateSteps.LOGO)

  const handleBack = () => {
    setStep(step => step - 1)
    scrollTo({ top: 0, behavior: "smooth" })
  }

  const handleNew = () => {
    setState({})
    setStep(EmpresaCreateSteps.DADOS_BASICOS)
    scrollTo({ top: 0, behavior: "smooth" })
  }


  return (
    <PageTemplate
      metaTags={{
        title: "Gottaup.com | Cadastro de Empresa",
        description: "Cadastro de Empresa"
      }}
      menuDesktop
      headerMobile={{
        title: "Cadastro de Empresa",
        leftButtons: (
          <IconButton edge="start" color="inherit" component={Link} to={from}>
            <ArrowBackIcon />
          </IconButton>
        )
      }}
      headerDesktop={{
        title: "Cadastro de Empresa",
        breadcrumbs: [
          { label: "Home", to: '/', icon: HomeIcon },
          { label: "Empresas", to: '/admin/empresas', icon: BusinessIcon },
          { label: "Cadastro" }
        ]
      }}
    >

      <Container component="section" style={{ marginTop: 16 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <Stepper activeStep={step} orientation={isDesktop ? "vertical" : "horizontal"} alternativeLabel={!isDesktop} nonLinear variant="outlined"  >
              <Step completed={step > 0}>
                <StepLabel>Dados Básicos</StepLabel>
              </Step>
              <Step completed={step > 1}>
                <StepLabel>Endereco</StepLabel>
              </Step>
              <Step completed={step > 2}>
                <StepLabel>Dados Bancários</StepLabel>
              </Step>
              <Step completed={step > 3}>
                <StepLabel>Logo</StepLabel>
              </Step>
            </Stepper>

          </Grid>
          <Grid item xs={12} md={10}>
            {step == EmpresaCreateSteps.DADOS_BASICOS &&
              <EmpresaDadosBasicosForm
                empresa={state}
                submitText="Próximo"
                formMode={EmpresaDadosBasicosFormMode.CREATE}
                onSubmit={handleEmpresaDadosBasicosComplete} />
            }
            {step == EmpresaCreateSteps.ENDERECO &&
              <EnderecoForm
                endereco={state.endereco || {}}
                onSubmit={handleEnderecoComplete}
                submitText="Próximo"
                formMode={EnderecoFormMode.CREATE}
                onBack={handleBack} />
            }
            {step == EmpresaCreateSteps.DADOS_BANCARIOS &&
              <DadosBancariosForm
                dadoBancario={state.dadoBancario || {}}
                onSubmit={handleDadosBancariosComplete}
                submitText="Próximo"
                formMode={DadosBancariosFormMode.CREATE}
                onBack={handleBack} />
            }
            {step == EmpresaCreateSteps.LOGO &&
              <EmpresaImagemLogoForm
                imagem={state.imagemLogo || {}}
                formTitle="Logo"
                onSubmit={handleImagemLogoComplete}
                submitText="Próximo"
                formMode={EmpresaImagemLogoFormMode.CREATE}
                onBack={handleBack} />
            }
            {step == EmpresaCreateSteps.REVISAO &&
              <EmpresaReviewCadastro
                empresa={state}
                onSubmit={handleRevisaoComplete}
                submitText="Finalizar"
                onBack={handleBack}
                onDadosBancariosEdit={handleDadosBancariosEdit}
                onDadosBasicosEdit={handleDadosBasicosEdit}
                onEnderecoEdit={handleEnderecoEdit}
                onLogoEdit={handleLogoEdit}
              />
            }
            {step == EmpresaCreateSteps.SUCESSO &&
              <EmpresaSucessoCadastro empresa={state} onNew={handleNew} />
            }
          </Grid>
        </Grid>

      </Container>

    </PageTemplate >
  )
}
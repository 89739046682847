import { createMuiTheme } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';

// Create a theme instance.
const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#ee7a40",//"#7986cb",
      main: "#EA5911",//"#3f51b5",
      dark: "#a33e0b",//"#303f9f",
      contrastText: "#fff"
    },
    secondary: {
      light: "#339388",//"#ff4081",
      main:  "#00796b",// "#f50057",
      dark: "#00544a", //"#c51162",
      contrastText: "#fff"
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff"
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)"
    },
    background: {
      paper: "#fff",
      default: "#fafafa"
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "rgba(0, 0, 0, 0.08)",
      hoverOpacity: 0.08,
      selected: "rgba(0, 0, 0, 0.14)",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)"
    }
  },
  typography: {
    h1:{
      fontSize:"2.5rem",
      fontWeight: 500
    },
    h2:{
      fontSize:"2.25rem",
      fontWeight: 500
    },
    h3:{
      fontSize:"2rem",
      fontWeight: 500
    },
    h4:{
      fontSize:"1.75rem",
      fontWeight: 500
    },
    h5:{
      fontSize:"1.5rem",
      fontWeight: 500
    },
    h6:{
      fontSize:"1.25rem",
      fontWeight: 500
    }
  },
  zIndex: {
    mobileStepper: 1000,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500
  }
},ptBR);

export default theme;
import { Box, Button, Card, CardActions, CardContent, CardHeader, createStyles, Divider, Grid, makeStyles } from '@material-ui/core';
import React, { FormEvent, Fragment, useRef, useState } from 'react';
import { ConfirmDialogProps, ConfirmDialog } from '../../../../components/ConfirmDialog';
import Message, { MessageProps } from '../../../../components/Message';
import { setPathProperty } from '../../../../ViewUtils';
import TextInput from '../../../../components/TextInput';

const useStyles = makeStyles(() => createStyles({
  form: {
    marginBottom: 0
  },
  actions: {
    justifyContent: 'flex-end'
  }
}), { name: 'TrocaSenhaForm' });

interface Props {
  onFormUpdate?: (unsaved: boolean) => void;
  onFormSubmit: (senhaAtual: string, senhaNova: string) => void;
}

interface State {
  senhaAtual: string;
  senhaNova: string;
  senhaNovaConfirmacao: string;
}

export default function TrocaSenhaForm(props: Props) {

  const classes = useStyles();
  const state = useRef<State>({
    senhaAtual: '',
    senhaNova: '',
    senhaNovaConfirmacao: ''
  });
  const fieldsUpdated = useRef<string[]>([]);
  const [unsavedFormData, setUnsavedFormData] = useState(false);
  const [invalidFormData, setInvalidFormData] = useState(false);
  const [dialog, setDialog] = useState<ConfirmDialogProps | undefined>(undefined);
  const [message, setMessage] = useState<MessageProps | undefined>(undefined);
  const formRef = useRef<HTMLFormElement>(null);

  const handleValidInput = (path: string) => (newValue: string) => {
    setPathProperty(path, state.current, newValue);
    if (!fieldsUpdated.current.includes(path)) {
      fieldsUpdated.current.push(path); // Marca que o campo foi alterado
    }
    if (!unsavedFormData) {
      setUnsavedFormData(true); // Seta que o form possui alterações não salvas
      if (props.onFormUpdate) {
        props.onFormUpdate(true);
      }
    }
    console.log(state.current, fieldsUpdated.current.length);

    // Valida para ver se todos os campos estão válidos
    if (formRef.current && formRef.current.checkValidity()) {
      if (state.current.senhaNova === state.current.senhaNovaConfirmacao) {
        if (invalidFormData) {
          setInvalidFormData(false); // Seta que o form não possui dados inválidos
        }
        if (message) {
          setMessage(undefined); // Fecha a mensagem, se estiver aparecendo
        }
      } else {
        if (!invalidFormData) {
          setInvalidFormData(true); // Seta que o form possui dados inválidos
        }
        // Exibe mensagem que as senhas não conferem
        setMessage({
          content: "Senhas digitadas não conferem",
          onDismiss: () => setMessage(undefined)
        })
      }

    } else {
      if (!invalidFormData) {
        setInvalidFormData(true); // Seta que o form possui dados inválidos
      }
    }
  }

  const handleInvalidInput = (path: string) => () => {
    if (!fieldsUpdated.current.includes(path)) {
      fieldsUpdated.current.push(path); // Marca que o campo foi alterado
    }
    if (!invalidFormData) {
      setInvalidFormData(true); // Seta que o form possui dados inválidos
    }
    if (!unsavedFormData) {
      setUnsavedFormData(true); // Seta que o form possui alterações não salvas
      if (props.onFormUpdate) {
        props.onFormUpdate(true);
      }
    }
  }

  const validaConfirmaUpdate = (e: FormEvent) => {
    e.preventDefault();
    setDialog({
      open: true,
      title: "Confirma alteração da Senha?",
      content: "Ao confirmar as informações serão salvas. Se cancelar, nenhum dado será salvo no servidor.",
      onConfirm: () => {
        setDialog(undefined); // Fecha dialog
        props.onFormSubmit(state.current.senhaAtual, state.current.senhaNova); //chama evento no pai para persistir
        fieldsUpdated.current = []; // Zera controles de alteração
        setUnsavedFormData(false); // Zera controles de alteração
        // Zera o estado
        state.current = {
          senhaAtual: '',
          senhaNova: '',
          senhaNovaConfirmacao: ''
        };
        // Reseta o form
        if (formRef.current) {
          formRef.current.reset();
        }
      },
      onCancel: () => setDialog(undefined)
    })
  }

  return (
    <Fragment>
      {dialog && <ConfirmDialog {...dialog} />}
      <Card>

        <form
          id="formTrocaSenha"
          noValidate
          autoComplete="off"
          className={classes.form}
          onSubmit={validaConfirmaUpdate}
          ref={formRef}>

          <CardHeader
            title="Troca de Senha"
          />
          <Divider />

          <CardContent>
            <Grid
              container
              spacing={3}
            >

              <Grid item xs={12}>

                <TextInput
                  required
                  label="Senha Atual"
                  placeholder="*******"
                  maxLength={8}
                  minLength={6}
                  onValidInput={handleValidInput("senhaAtual")}
                  onInvalidInput={handleInvalidInput("senhaAtual")}
                  validationMessages={{
                    tooShort: 'Senha deve conter ao menos 6 caractéres',
                    invalid: 'Informe a Senha Atual'
                  }}
                  type="password"
                />

              </Grid>

              <Grid item xs={12}>

                <TextInput
                  required
                  label="Nova Senha"
                  placeholder="*******"
                  helperText="Senha com 6 a 8 caractéres"
                  maxLength={8}
                  minLength={6}
                  onValidInput={handleValidInput("senhaNova")}
                  onInvalidInput={handleInvalidInput("senhaNova")}
                  validationMessages={{
                    tooShort: 'Senha deve conter ao menos 6 caractéres',
                    invalid: 'Informe a Senha'
                  }}
                  type="password"
                />

              </Grid>

              <Grid item xs={12}>

                <TextInput
                  required
                  label="Confirmar Nova Senha"
                  placeholder="*******"
                  maxLength={8}
                  minLength={6}
                  onValidInput={handleValidInput("senhaNovaConfirmacao")}
                  onInvalidInput={handleInvalidInput("senhaNovaConfirmacao")}
                  validationMessages={{
                    tooShort: 'Senha deve conter ao menos 6 caractéres',
                    invalid: 'Informe a Senha'
                  }}
                  type="password"
                />

              </Grid>

            </Grid>
            {message &&
              <Box mt={3}>
                <Message {...message} />
              </Box>
            }
          </CardContent>
          <Divider />
          <CardActions className={classes.actions}>
            <Button
              color="secondary"
              variant="outlined"
              type="submit"
              disabled={!unsavedFormData || invalidFormData}
            >
              Salvar
          </Button>
          </CardActions>
        </form>
      </Card>
    </Fragment>

  )
}
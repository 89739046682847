const isFileImage = (file: File) => file.type.split('/')[0] === 'image';

const isFilePdf = (file: File) => file.type === 'application/pdf';

const fileToDataURL = (file: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(file);
    })
}

const dataURLtoFile = (dataURL: string, fileName: string) => {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0]
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    const blob = new File([ab], fileName, { type: mimeString });
    return blob;
}

const dataURLtoBase64 = (dataURL: string) => dataURL.replace(/^data:(.*,)?/, '');

const fileToBase64 = (file: Blob): Promise<string> => fileToDataURL(file).then(dataURLtoBase64);

const dataURLMimeType = (dataURL: string) => dataURL.split(',')[0].split(':')[1].split(';')[0]


export const FileUtils = {
    isFileImage,
    isFilePdf,
    fileToDataURL,
    dataURLtoFile,
    dataURLtoBase64,
    fileToBase64,
    dataURLMimeType
}



export interface CaptacaoProjetoVO {
  nuProjeto: number;
  qtCotaReservado: number;
  vrTotalReservado: number;
  qtCotaInvestido: number;
  vrTotalInvestido: number;
}

export default class CaptacaoProjeto {

    public nuProjeto: number|undefined;
    public qtCotaReservado: number|undefined;
    public vrTotalReservado: number|undefined;
    public qtCotaInvestido: number|undefined;
    public vrTotalInvestido: number|undefined;


    constructor(obj: object|null = null) {
        if (obj) {
            Object.assign(this, obj);
        }
        Object.seal(this); // Não permite alterar estrutura da classe
    }

    get vrTotalReservadoFormatado(){
        return this.vrTotalReservado?this.vrTotalReservado.toFixed(2).replace('.',',').replace(/\d(?=(\d{3})+,)/g, '$&.'):'0,00';
    }

  
}
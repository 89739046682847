import { Box, Button, createStyles, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => createStyles({
    sucessIcon: {
        fontSize: 96,
        color: theme.palette.success.main
    },
}), { name: 'SucessoValidacaoEmailStep' });

export default function SucessoValidacaoEmailStep() {

    const classes = useStyles();

     return (
        <Paper variant="outlined" style={{ padding: 20 }}>

                <Box display="flex" justifyContent="center" mt={2}>
                    <CheckCircleIcon className={classes.sucessIcon}/>
                </Box>

                <Box mt={1} >
                    <Typography variant="body1" paragraph align="center"><strong>Parabéns! Sua conta foi ativada com sucesso.</strong></Typography>
                    <Typography variant="body1" paragraph align="center">A partir de agora você poderá utilizar sua conta de usuário.</Typography>
                    <Typography variant="body1" paragraph align="center">Caso queira fazer investimentos, será necessário também preencher o Cadastro do Investidor.</Typography>
                </Box>

             
                <Box display="flex" justifyContent="center" mt={2}>
                    <Button variant="contained" color="secondary" component={Link} to="/conta/investidor">
                        Cadastro do Investidor
                    </Button>
                </Box>
        </Paper>

    )

}
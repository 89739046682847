import { Box, Grid, Paper, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import SvgSyncFiles from "../../../../../../../images/undraw/sync_files.svg"
import MonitorDashboardIcon from 'mdi-material-ui/MonitorDashboard';
import React from 'react';
import { ButtonWheresNext } from '../../../../../../../components/buttons/ButtonWheresNext';

export const InvestidorSucessoCadastro = () => {

  return (
    <Paper variant="outlined" square >
      <Box p={2}>

        <Box display="flex" justifyContent="center" mt={2}>
            <img src={SvgSyncFiles} height={150} />
        </Box>

        <Box mt={1} >
          <Typography variant="h6" paragraph align="center">Pronto, agora você já pode fazer investimentos!</Typography>
        </Box>

        <Box mt={4} >
          <Typography variant="h6" paragraph align="center">O que deseja fazer agora?</Typography>
        </Box>


        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} sm={4}>
            <ButtonWheresNext linkTo="/investir" label="Pesquisar captações para investir" icon={SearchIcon} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ButtonWheresNext linkTo="/conta/painel" label="Conhecer o Painel de Investimentos" icon={MonitorDashboardIcon} />
          </Grid>

        </Grid>

      </Box>
    </Paper >
  )
}




export const Formatters = {
  cnpj: {
    format: (value: string | null | undefined) => value?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/g, '$1.$2.$3/$4-$5') ?? '',
    unformat: (value: string | null | undefined) => value?.match(/\d+/g)?.join('') ?? ''
  },
  cpf: {
    format: (value: string | null | undefined) => value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})$/g, '$1.$2.$3-$4') ?? '',
    unformat: (value: string | null |undefined) => value?.match(/\d+/g)?.join('') ?? ''
  },
  telefone: {
    format: (value: string | null |undefined) => value?.replace(/^(\d{2})(\d{4,5})(\d{4})$/g, '($1)$2-$3') ?? '',
    unformat: (value: string | null |undefined) => value?.match(/\d+/g)?.join('') ?? ''
  },
  cep: {
    format: (value: string | null | undefined) => value?.replace(/(\d{5})(\d{3})$/g, '$1-$2') ?? '',
    unformat: (value: string | null | undefined) => value?.match(/\d+/g)?.join('') ?? ''
  },
  conta: {
    banco: {
      format: (value: number | null |undefined) => value ? String(value).padStart(3, '0') : '',
      unformat: (value: string | null | undefined) => value ? Number(value) : undefined
    },
    numero: {
      format: (value: string | undefined) => value?.replace(/(\d{1,11})([A-Z0-9]{1})$/g, '$1-$2') ?? '',
      unformat: (value: string | undefined) => value?.match(/[A-Z0-9]+/g)?.join('') ?? ''
    },
  }

}

import React, {Fragment, useEffect} from 'react';
import {
  Avatar,
  Box,
  Button,
  Container,
  createStyles,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
  Theme,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Edit';
import HomeIcon from '@material-ui/icons/Home';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import PostAddIcon from '@material-ui/icons/PostAdd';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import EmptyState from '../../../../components/EmptyState';
import Loading from '../../../../components/Loading';
import OnlyDesktop from '../../../../layout/OnlyDesktop';
import OnlyMobile from '../../../../layout/OnlyMobile';
import PageTemplate from '../../../../layout/PageTemplate';
import {AppDispatch} from "../../../../../../browser";
import {fetchUsuariosList, trackFetchUsuariosList} from "../../../../../stores/slices/entities/contaUsuariosSlice";
import ErrorEmptyState from "../../../../components/empty/ErrorEmptyState";
import {APIUtils} from "../../../../../services/api/APIUtils";
import {FieldOutput} from "../../../../components/form/output/FieldOutput";

const useStyles = makeStyles((theme: Theme) => createStyles({
  avatarMobile: {
    maxHeight: 40,
    width: 40,
    fontSize: 40
  },
  arrow: {
    minWidth: 0
  },
  listItem: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(3, 2),
  },
  avatarDesktop: {
    width: 50,
    height: 50,
    fontSize: 50
  },
}), {name: 'UsuariosList'});

export const UsuariosListPage = () => {

  const classes = useStyles();

  const requestState = useSelector(trackFetchUsuariosList());

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const promise = dispatch(fetchUsuariosList());
    return () => promise.abort();
  }, [dispatch]);


  return (
    <PageTemplate
      metaTags={{
        title: "Gottaup.com | Usuários",
        description: "Lista de usuários"
      }}
      menuDesktop
      headerMobile={{
        title: "Usuários",
        leftButtons: (
          <IconButton edge="start" color="inherit" component={Link} to="/conta">
            <ArrowBackIcon/>
          </IconButton>
        ),
        rightButtons: (
          <IconButton edge="end" color="inherit" component={Link} to="/admin/usuarios/create">
            <AddIcon/>
          </IconButton>
        )
      }}
      headerDesktop={{
        title: "Usuários Cadastrados",
        breadcrumbs: [
          {label: "Home", to: '/', icon: HomeIcon},
          {label: "Usuários"}
        ]
      }}
    >

      <section>

        <OnlyDesktop>
          <Container>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="contained" color="secondary" component={Link} to="/admin/usuarios/create"
                      startIcon={<AddIcon/>}>Novo</Button>
            </Box>
          </Container>
        </OnlyDesktop>

        {requestState.pending &&
        <Loading text="Buscando usuários..."/>
        }

        {requestState.error &&
        <ErrorEmptyState
          error={requestState.error}
          defaultDescription="Não foi possível buscar usuários cadastrados"
          onTryAgainClick={() => dispatch(fetchUsuariosList())}
        />
        }

        {requestState.data && requestState.data.length == 0 &&
        <EmptyState
          icon={<PostAddIcon style={{fontSize: '58px'}} color="disabled"/>}
          title="Nenhum usuário cadastrado."
          subtitle="Crie um novo usuário e ele será listado aqui."
        />
        }

        {requestState.data && requestState.data.length > 0 &&
        <Fragment>
          <OnlyMobile>
            <List>
              {requestState.data.map((usuario, index) => (
                <Fragment key={index}>
                  <ListItem button component={Link} to={`/admin/usuarios/${usuario.coContaUsuario}`}>
                    {
                      <ListItemAvatar>
                        {usuario.imagemPerfil
                          ? <Avatar
                            className={classes.avatarMobile}
                            src={APIUtils.generateImageURL(usuario.imagemPerfil)}
                            alt={"foto do perfil do usuário " + usuario.noUsuario}/>
                          : <AccountCircleIcon className={classes.avatarMobile} color="disabled"/>
                        }
                      </ListItemAvatar>
                    }
                    <ListItemText
                      primary={usuario.noUsuario}
                      secondary={usuario.noEmail}/>
                    <ListItemIcon className={classes.arrow}>
                      <KeyboardArrowRightIcon/>
                    </ListItemIcon>
                  </ListItem>
                </Fragment>
              ))}
            </List>
          </OnlyMobile>
          <OnlyDesktop>
            <Container>
              {requestState.data.map((usuario, index) => (
                <Paper key={index} className={classes.listItem}>
                  <Grid container alignItems="center">
                    <Grid item sm={2} md={2}>
                      <Grid container justify="center">
                        {usuario.imagemPerfil
                          ? <Avatar
                            src={APIUtils.generateImageURL(usuario.imagemPerfil)}
                            alt={"foto do perfil do usuário " + usuario.noUsuario}
                            className={classes.avatarDesktop}/>
                          : <AccountCircleIcon className={classes.avatarDesktop} color="disabled"/>
                        }
                      </Grid>
                    </Grid>
                    <Grid item sm={4} md={3}>
                      <FieldOutput id={`usuarios[${usuario.coContaUsuario}].noUsuario`} label="Nome" value={usuario.noUsuario} />
                    </Grid>
                    <Grid item sm={3} md={3}>
                      <FieldOutput id={`usuarios[${usuario.coContaUsuario}].noEmail`} label="Email" value={usuario.noEmail} />
                    </Grid>

                    <Grid item sm={3} md={4}>
                      <Grid container justify="flex-end">
                        <Button
                          id={`usuarios[${usuario.coContaUsuario}].btnEditarUsuario`}
                          color="secondary"
                          variant="outlined"
                          component={Link}
                          startIcon={<EditIcon/>}
                          to={`/admin/usuarios/${usuario.coContaUsuario}`}>
                          Editar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                </Paper>
              ))}
            </Container>
            <Box my={3}/>
          </OnlyDesktop>
        </Fragment>
        }
      </section>


    </PageTemplate>
  )
}
import { Badge, withStyles, Theme } from "@material-ui/core";

export const TextBadge = withStyles((theme: Theme) => ({
  badge: {
    color: theme.palette.getContrastText(theme.palette.text.disabled),
    backgroundColor: "#3f51b5"
  },
  anchorOriginTopRightRectangle: {
    transform: 'scale(1) translate(115%, 25%)'
  }
}))(Badge);
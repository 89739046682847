import { createStyles, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import DescriptionIcon from '@material-ui/icons/Description';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import MailIcon from '@material-ui/icons/Mail';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';
import PageTemplate from '../../../layout/PageTemplate';

const useStyles = makeStyles(() => createStyles({
    arrow: {
        minWidth: 0
    },
    menuGroup: {
        marginLeft: '1em'
    }
}), { name: 'MenuMobile' });


export const MenuMobilePage = () => {
    const classes = useStyles();

    return (
        <PageTemplate
            metaTags={{
                title: "Gottaup.com | Menu",
                description: "Menu"
            }}
            menuMobile
        >
            <section>

                <List>
                    <Typography className={classes.menuGroup} variant="h6" paragraph={true}>Conheça</Typography>
                    <ListItem button>
                        <ListItemIcon>
                            <ContactSupportIcon />
                        </ListItemIcon>
                        <ListItemText primary="Sobre o Gottaup" />
                        <ListItemIcon className={classes.arrow}>
                            <KeyboardArrowRightIcon />
                        </ListItemIcon>
                    </ListItem>

                    <ListItem button>
                        <ListItemIcon>
                            <QuestionAnswerIcon />
                        </ListItemIcon>
                        <ListItemText primary="Perguntas Frequentes" />
                        <ListItemIcon className={classes.arrow}>
                            <KeyboardArrowRightIcon />
                        </ListItemIcon>
                    </ListItem>

                    <ListItem button>
                        <ListItemIcon>
                            <MailIcon />
                        </ListItemIcon>
                        <ListItemText primary="Contato" />
                        <ListItemIcon className={classes.arrow}>
                            <KeyboardArrowRightIcon />
                        </ListItemIcon>
                    </ListItem>

                </List>

                <List>
                    <Typography className={classes.menuGroup} variant="h6" paragraph={true}>Legal</Typography>
                    <ListItem button>
                        <ListItemIcon>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText primary="Termos e Políticas" />
                        <ListItemIcon className={classes.arrow}>
                            <KeyboardArrowRightIcon />
                        </ListItemIcon>
                    </ListItem>

                    <ListItem button>
                        <ListItemIcon>
                            <WarningIcon />
                        </ListItemIcon>
                        <ListItemText primary="Aviso de Risco" />
                        <ListItemIcon className={classes.arrow}>
                            <KeyboardArrowRightIcon />
                        </ListItemIcon>
                    </ListItem>

                    <ListItem button>
                        <ListItemIcon>
                            <AssignmentIcon />
                        </ListItemIcon>
                        <ListItemText primary="Relatórios CVM" />
                        <ListItemIcon className={classes.arrow}>
                            <KeyboardArrowRightIcon />
                        </ListItemIcon>
                    </ListItem>
                </List>

            </section>


        </PageTemplate>
    )

}
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';

export interface EmptyStateProps {
    icon?: JSX.Element;
    title: string;
    subtitle: string;
    action?: React.ReactNode;
    margin?: string;
}

export default function EmptyState(props: EmptyStateProps) {
    return (
        <Grid container direction="column" justify="flex-end" spacing={1} alignItems="center" style={{ marginTop: props.margin||'20vh', marginBottom: props.margin||'20vh' }} >
          {props.icon &&
            <Grid item>
                {props.icon}
            </Grid>
          }
          <Typography align="center" variant="h6" gutterBottom={true} color="textSecondary">{props.title}</Typography>
          <Typography align="center" variant="body1" gutterBottom={true} color="textSecondary">{props.subtitle}</Typography>
          {props.action &&
            <Grid item>
                {props.action}
            </Grid>
          }
        </Grid>
    );
}
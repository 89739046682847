import ReportEstatisticasPlataformaDTO from "../../models/dtos/ReportEstatisticasPlataformaDTO";
import http from '../../utils/http';
import {ContaUsuarioVO} from "../../models/ContaUsuario";
import {encodeQueryParams} from "../../views/ViewUtils";
import {EmpresaVO} from "../../models/Empresa";
import {EnderecoVO} from "../../models/Endereco";
import {DadoBancarioVO} from "../../models/DadoBancario";
import {ImagemVO} from "../../models/Imagem";
import {ProjetoVO} from "../../models/Projeto";
import {TaxaJurosVO} from "../../models/TaxaJuros";
import moment from "moment";
import {DeepPartial} from "@reduxjs/toolkit";
import BatchOperationDTO from "../../models/dtos/BatchOperationDTO";
import {DocumentoVO} from "../../models/Documento";
import {ValorCotaVO} from "../../models/ValorCota";
import {MotivoRemocaoForum} from "../../models/enums/MotivoRemocaoForum";
import {PerguntaForumProjetoVO} from "../../models/PerguntaForumProjeto";
import {InvestidorVO} from "../../models/Investidor";
import {InvestimentoVO} from "../../models/Investimento";
import {MotivoCancelamentoInvestimento} from "../../models/enums/MotivoCancelamentoInvestimento";
import {Perfil} from "../../models/enums/Perfil";

export const api = {
  report: {
    fetchEstatisticas: () =>
      http.get<ReportEstatisticasPlataformaDTO>(
        "/report/estatisticas_plataforma",
        {cache: {maxAge: moment.duration(1, "minute").asMilliseconds()}}
      )
  },
  investir: {
    fetchCaptacoes: () =>
      http.get<ProjetoVO[]>(
        "/investir/captacoes",
        {cache: {maxAge: moment.duration(1, "minute").asMilliseconds()}}
      ),
    fetchDetalhesCaptacao: (noPermalink: string) =>
      http.get<ProjetoVO>(
        `/investir/captacoes/${noPermalink}`,
        {cache: {maxAge: moment.duration(1, "minute").asMilliseconds()}}
      ),
  },
  login: {
    efetuaLogin: (noEmail: string, senha: string) =>
      http.post<ContaUsuarioVO>("/login", encodeQueryParams({
        noEmail,
        senha
      }), {headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}}),
    autoRegistro: (usuario: DeepPartial<ContaUsuarioVO>) =>
      http.post<ContaUsuarioVO>(
        "/login/registro",
        usuario
      ),
  },
  usuarios: {
    createUsuario: (usuario: DeepPartial<ContaUsuarioVO>, token: string) =>
      http.post<ContaUsuarioVO>(
        "/admin/usuarios/",
        usuario,
        {headers: {Authorization: `Bearer ${token}`}}
      ),
    fetchUsuario: (id: number, token: string) =>
      http.get<ContaUsuarioVO>(
        `/admin/usuarios/${id}`,
        {headers: {Authorization: `Bearer ${token}`}}
      ),
    patchUsuarioDadosBasicos: (id: number, changes: DeepPartial<ContaUsuarioVO>, token: string) =>
      http.patch<void>(
        `/admin/usuarios/${id}/conta`,
        changes,
        {headers: {Authorization: `Bearer ${token}`}}
      ),
    changeUsuarioSenha: (id: number, senha: string, token: string) =>
      http.post<void>(
        `/admin/usuarios/${id}/trocasenha`,
        {senha: senha},
        {headers: {Authorization: `Bearer ${token}`}}
      ),
    changeUsuarioPerfis: (id: number, perfis: Perfil[], token: string) =>
      http.put<void>(
        `/admin/usuarios/${id}/perfis`,
        perfis,
        {headers: {Authorization: `Bearer ${token}`}}
      ),
    changeUsuarioImagemPerfil: (id: number, imagemPerfil: ImagemVO, token: string) =>
      http.put<void>(
        `/admin/usuarios/${id}/imagemperfil`,
        imagemPerfil,
        {headers: {Authorization: `Bearer ${token}`}}
      ),
    fetchUsuarios: (token: string) =>
      http.get<ContaUsuarioVO[]>(
        "/admin/usuarios",
        {headers: {Authorization: `Bearer ${token}`}}
      )
  },
  empresas: {
    fetchEmpresas: (token: string) =>
      http.get<EmpresaVO[]>(
        "/admin/empresas?fields=nuEmpresa,nuCnpjEmpresa,noFantasia,imagemLogo",
        {headers: {Authorization: `Bearer ${token}`}}
      ),
    fetchEmpresasCombo: (token: string) =>
      http.get<EmpresaVO[]>(
        "/admin/empresas?fields=nuEmpresa,noFantasia",
        {headers: {Authorization: `Bearer ${token}`}}
      ),
    createEmpresa: (empresa: DeepPartial<EmpresaVO>, token: string) =>
      http.post<EmpresaVO>(
        "/admin/empresas/", empresa,
        {headers: {Authorization: `Bearer ${token}`}}
      ),
    fetchEmpresaByCnpj: (cnpj: string, token: string) =>
      http.get<EmpresaVO>(
        `/admin/empresas/${cnpj}`,
        {headers: {Authorization: `Bearer ${token}`}}
      ),
    deleteEmpresaByCnpj: (cnpj: string, token: string) =>
      http.delete<void>(
        `/admin/empresas/${cnpj}`,
        {headers: {Authorization: `Bearer ${token}`}}
      ),
    patchEmpresaEndereco: (cnpj: string, changes: DeepPartial<EnderecoVO>, token: string) =>
      http.patch<void>(
        `/admin/empresas/${cnpj}/endereco`,
        changes,
        {headers: {Authorization: `Bearer ${token}`}}
      ),
    patchEmpresaDadoBancario: (cnpj: string, changes: DeepPartial<DadoBancarioVO>, token: string) =>
      http.patch<void>(
        `/admin/empresas/${cnpj}/dadobancario`,
        changes,
        {headers: {Authorization: `Bearer ${token}`}}
      ),
    patchEmpresaDadosBasicos: (cnpj: string, changes: DeepPartial<EmpresaVO>, token: string) =>
      http.patch<void>(
        `/admin/empresas/${cnpj}/dadosbasicos`,
        changes,
        {headers: {Authorization: `Bearer ${token}`}}
      ),
    changeEmpresaImagemLogo: (cnpj: string, imagemLogo: DeepPartial<ImagemVO>, token: string) =>
      http.put<void>(
        `/admin/empresas/${cnpj}/imagemlogo`,
        imagemLogo,
        {headers: {Authorization: `Bearer ${token}`}}),
    fetchEmpresaProjetos: (nuEmpresa: number, token: string) =>
      http.get<ProjetoVO[]>(
        `/admin/empresas/${nuEmpresa}/projetos`,
        {headers: {Authorization: `Bearer ${token}`}}
      ),
  },
  projetos: {
    fetchProjetos: (token: string) =>
      http.get<ProjetoVO[]>(
        "/admin/projetos?fields=nuProjeto,noProjeto,deProjeto,imagemCapa",
        {headers: {Authorization: `Bearer ${token}`}}
      ),
    createProjeto: (projeto: DeepPartial<ProjetoVO>, token: string) =>
      http.post<ProjetoVO>(
        "/admin/projetos",
        projeto,
        {headers: {Authorization: `Bearer ${token}`}}
      ),
    fetchProjeto: (id: number, token: string) => http.get<ProjetoVO>(`/admin/projetos/${id}`, {headers: {Authorization: `Bearer ${token}`}}),
    deleteProjeto: (id: number, token: string) => http.delete<void>(`/admin/projetos/${id}`, {headers: {Authorization: `Bearer ${token}`}}),
    patchProjetoEndereco: (id: number, changes: DeepPartial<EnderecoVO>, token: string) => http.patch<void>(`/admin/projetos/${id}/endereco`, changes, {headers: {Authorization: `Bearer ${token}`}}),
    patchProjetoDadosBasicos: (id: number, changes: DeepPartial<ProjetoVO>, token: string) => http.patch<void>(`/admin/projetos/${id}/dadosbasicos`, changes, {headers: {Authorization: `Bearer ${token}`}}),
    patchProjetoCaptacao: (id: number, changes: DeepPartial<ProjetoVO>, token: string) => http.patch<void>(`/admin/projetos/${id}/captacao`, changes, {headers: {Authorization: `Bearer ${token}`}}),
    changeProjetoImagemCapa: (id: number, imagemCapa: DeepPartial<ImagemVO>, token: string) => http.put<void>(`/admin/projetos/${id}/imagemcapa`, imagemCapa, {headers: {Authorization: `Bearer ${token}`}}),
    changeProjetoProspecto: (id: number, prospecto: DeepPartial<DocumentoVO>, token: string) => http.put<void>(`/admin/projetos/${id}/prospecto`, prospecto, {headers: {Authorization: `Bearer ${token}`}}),
    changeProjetoModeloContrato: (id: number, modeloContrato: DeepPartial<DocumentoVO>, token: string) => http.put<void>(`/admin/projetos/${id}/modelocontrato`, modeloContrato, {headers: {Authorization: `Bearer ${token}`}}),
    batchUpdateGaleriaFotos: (id: number, operations: BatchOperationDTO<DeepPartial<ImagemVO>>[], token: string) => http.post<BatchOperationDTO<ImagemVO>[]>(`/admin/projetos/${id}/galeriafotos`, operations, {headers: {Authorization: `Bearer ${token}`}}),
    createPerguntaForumProjeto: (nuProjeto: number, dePergunta: string, token: string) => http.post<PerguntaForumProjetoVO>(`/admin/projetos/${nuProjeto}/perguntas`, {dePergunta}, {headers: {Authorization: `Bearer ${token}`}}),
    removePerguntaForumProjeto: (nuProjeto: number, coPerguntaForumProjeto: number, motivoRemocaoForum: MotivoRemocaoForum, token: string) => http.post<void>(`/admin/projetos/${nuProjeto}/perguntas/${coPerguntaForumProjeto}/remover`, {motivoRemocaoForum}, {headers: {Authorization: `Bearer ${token}`}}),
    responderPerguntaForumProjeto: (nuProjeto: number, coPerguntaForumProjeto: number, deResposta: string, token: string) => http.post<PerguntaForumProjetoVO>(`/admin/projetos/${nuProjeto}/perguntas/${coPerguntaForumProjeto}/responder`, {deResposta}, {headers: {Authorization: `Bearer ${token}`}}),
    fetchProjetoInvestimentosUploadContrato: (nuProjeto: number, token: string) =>
      http.get<InvestimentoVO[]>(
        `/admin/projetos/${nuProjeto}/investimentosuploadcontrato`,
        {headers: {Authorization: `Bearer ${token}`}}
      ),
  },
  taxaJuros: {
    fetchTaxasJuros: (token: string) => http.get<TaxaJurosVO[]>("/admin/taxajuros", {headers: {Authorization: `Bearer ${token}`}}),
    createTaxaJuros: (taxaJuros: Partial<TaxaJurosVO>, token: string) => http.post<TaxaJurosVO>("/admin/taxajuros", taxaJuros, {headers: {Authorization: `Bearer ${token}`}}),
    patchTaxaJuros: (id: number, taxaJuros: Partial<TaxaJurosVO>, token: string) => http.patch<void>(`/admin/taxajuros/${id}`, taxaJuros, {headers: {Authorization: `Bearer ${token}`}}),
  },
  valoresCotas: {
    fetchValoresCotas: (nuProjeto: number, token: string) =>
      http.get<ValorCotaVO[]>(
        `/admin/projetos/${nuProjeto}/valorescota`,
        {headers: {Authorization: `Bearer ${token}`}}
      ),
    createValorCota: (nuProjeto: number, valorCota: DeepPartial<ValorCotaVO>, token: string) =>
      http.post<ValorCotaVO>(
        `/admin/projetos/${nuProjeto}/valorescota`,
        valorCota,
        {headers: {Authorization: `Bearer ${token}`}}
      ),
    patchValorCota: (nuProjeto: number, coValorCota: number, changes: DeepPartial<ValorCotaVO>, token: string) =>
      http.patch<void>(
        `/admin/projetos/${nuProjeto}/valorescota/${coValorCota}`,
        changes,
        {headers: {Authorization: `Bearer ${token}`}}
      ),
  },
  correios: {
    fetchCep: (cep: string, token: string) =>
      http.get<EnderecoVO>(
        `/correios/cep/${cep}`,
        {headers: {Authorization: `Bearer ${token}`}}
      ),
  },

  investidores: {
    findByContaUsuario: (coContaUsuario: number, token: string) =>
      http.get<InvestidorVO>(
        `/investidores/${coContaUsuario}`,
        {headers: {Authorization: `Bearer ${token}`}}
      ),
    createInvestidor: (investidor: DeepPartial<InvestidorVO>, token: string) =>
      http.put<InvestidorVO>(
        `/investidores/${investidor.coContaUsuario}`,
        investidor,
        {headers: {Authorization: `Bearer ${token}`}}
      ),
    createReserva: (coContaUsuario: number, reserva: DeepPartial<InvestimentoVO>, token: string) =>
      http.post<InvestimentoVO>(
        `/investidores/${coContaUsuario}/investimentos`,
        reserva,
        {headers: {Authorization: `Bearer ${token}`}}
      ),
    searchPainelInvestimentos: (coContaUsuario: number, token: string) =>
      http.get<InvestimentoVO[]>(
        `/investidores/${coContaUsuario}/painelinvestimentos`,
        {headers: {Authorization: `Bearer ${token}`}}
      ),
    changeDocumentoComprovanteDeposito: (coContaUsuario: number, coInvestimento: number, comprovante: Partial<DocumentoVO>, token: string) =>
      http.post<DocumentoVO>(
        `/investidores/${coContaUsuario}/investimentos/${coInvestimento}/comprovantedeposito`,
        comprovante,
        {headers: {Authorization: `Bearer ${token}`}}
      ),
    changeDocumentoComprovanteResidencia: (coContaUsuario: number, comprovante: Partial<DocumentoVO>, token: string) =>
      http.post<DocumentoVO>(
        `/investidores/${coContaUsuario}/comprovanteresidencia`,
        comprovante,
        {headers: {Authorization: `Bearer ${token}`}}
      ),
    changeDocumentoSelfieAutentica: (coContaUsuario: number, selfie: Partial<DocumentoVO>, token: string) =>
      http.post<DocumentoVO>(
        `/investidores/${coContaUsuario}/selfieautentica`,
        selfie,
        {headers: {Authorization: `Bearer ${token}`}}
      ),
    cancelarReserva: (coContaUsuario: number, coInvestimento: number, motivoCancelamento: MotivoCancelamentoInvestimento, token: string) =>
      http.post<InvestimentoVO>(
        `/investidores/${coContaUsuario}/investimentos/${coInvestimento}/cancelar`,
        motivoCancelamento,
        {headers: {Authorization: `Bearer ${token}`}}
      ),
    sumTotalInvestimentosAno: (coContaUsuario: number, token: string) =>
      http.get<number>(
        `/investidores/${coContaUsuario}/totalinvestimentosano`,
        {headers: {Authorization: `Bearer ${token}`, Accept: "text/plain"}}
      ),
    patchInvestidorDadosBasicos: (coContaUsuario: number, changes: DeepPartial<InvestidorVO>, token: string) =>
      http.patch<void>(
        `/investidores/${coContaUsuario}/dadosbasicos`,
        changes,
        {headers: {Authorization: `Bearer ${token}`}}
      ),
    patchInvestidorDadosBancarios: (coContaUsuario: number, changes: DeepPartial<DadoBancarioVO>, token: string) =>
      http.patch<void>(
        `/investidores/${coContaUsuario}/dadobancario`,
        changes,
        {headers: {Authorization: `Bearer ${token}`}}
      ),
    patchInvestidorEndereco: (coContaUsuario: number, changes: DeepPartial<EnderecoVO>, token: string) =>
      http.patch<void>(
        `/investidores/${coContaUsuario}/endereco`,
        changes,
        {headers: {Authorization: `Bearer ${token}`}}
      ),

    changeContrato: (coContaUsuario: number, coInvestimento: number, contrato: Partial<DocumentoVO>, token: string) =>
      http.post<DocumentoVO>(
        `/investidores/${coContaUsuario}/investimentos/${coInvestimento}/contrato`,
        contrato,
        {headers: {Authorization: `Bearer ${token}`}}
      ),
  },
  utils: {
    serverClock: () => http.get<string>("/utils/server/clock", {
      responseType: "text",
      headers: {Accept: "text/plain"},
      cache: {ignoreCache: true}
    })
  }
}


/*
create
get
read
fetch
retrieve
search
find
filter
update
change
delete
remove
*/


import { Hidden } from '@material-ui/core';
import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export default function OnlyMobile(props: Props) {

  return (
    <Hidden smUp implementation="css">
      {props.children}
    </Hidden>
  );
}

import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import { applyPatches, Patch, produceWithPatches } from 'immer';
import React from 'react';
import { useForm } from 'react-hook-form';
import { DeepPartial } from 'redux';
import { DocumentoVO } from '../../../../../../models/Documento';
import { APIUtils } from '../../../../../../services/api/APIUtils';
import { FileUtils } from '../../../../../../utils/files';
import ButtonProgress from '../../../../../components/ButtonProgress';
import ConfirmLeavePage from '../../../../../components/ComfirmLeavePage';
import FormPdfUpload from '../../../../../components/form/inputs/FileUpload/FormPdfUpload';

export enum ProjetoDocumentoProspectoFormMode {
  CREATE,
  EDIT
}

interface ProjetoDocumentoProspectoFormData {
  nuDocumento: string;
  imDocumento: string;
  noDocumento: string;
}

const generateFormDefaultValues = (documento: DeepPartial<DocumentoVO>): ProjetoDocumentoProspectoFormData => {
  return {
    nuDocumento: documento.nuDocumento?.toString() || '',
    noDocumento: documento.noDocumento || '',
    imDocumento: documento.imDocumento || ''
  }
}


interface ProjetoDocumentoProspectoFormProps {
  documento: DeepPartial<DocumentoVO>;
  onSubmit: (nextState: DeepPartial<DocumentoVO>, patches: Patch[], changes: DeepPartial<DocumentoVO>) => Promise<void> | void;
  onBack?: () => void;
  submitText: string;
  formMode: ProjetoDocumentoProspectoFormMode;
}

export const ProjetoDocumentoProspectoForm = ({ documento, onSubmit, onBack, submitText, formMode }: ProjetoDocumentoProspectoFormProps) => {

  const { register, handleSubmit, errors, setValue, formState: { isSubmitting, dirty } } = useForm<ProjetoDocumentoProspectoFormData>({
    mode: "onBlur",
    defaultValues: generateFormDefaultValues(documento)
  });

  const handleFormSubmit = async (formData: ProjetoDocumentoProspectoFormData) => {
    const [nextState, patches] = produceWithPatches(documento, draft => {
      draft.nuDocumento = formData.nuDocumento ? Number(formData.nuDocumento) : undefined;
      draft.imDocumento = formData.imDocumento;
      draft.noDocumento = formData.noDocumento;
    })
    const changes = applyPatches({}, patches) as DeepPartial<DocumentoVO>;
    await onSubmit(nextState, patches, changes)
  }

  const handleChangeDocumento = async (file: File | null) => {
    if (file) {
      setValue("noDocumento", file.name, true);
      const documentoBase64 = await FileUtils.fileToBase64(file);
      setValue("imDocumento", documentoBase64);
    } else {
      setValue("noDocumento", '');
      setValue("imDocumento", '');
    }
  }


  return (
    <form id="formDocumentoProspecto" noValidate autoComplete="off" onSubmit={handleSubmit(handleFormSubmit)}>
      <ConfirmLeavePage
        when={dirty}
        title="Tem certeza que deseja deixar a página sem salvar as alterações?"
        content="Ao confirmar os dados alterados não serão salvos. Se cancelar, poderá continuar a edição."
      />
      <Paper variant="outlined" square >
        <Box p={2}>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <Typography variant="h6">Prospecto</Typography>
            </Grid>

            <Grid item xs={12} >
              <input
                hidden
                name="noDocumento"
                ref={register({
                  required: "Faça upload de um prospecto para o projeto",
                  validate: {
                    extension: value => value.match(/\.(pdf)$/) ? true : 'Arquivo não aceito! Selecione uma documento em formato .pdf',
                  }
                })} />
              <input
                hidden
                name="imDocumento"
                ref={register()} />
              <FormPdfUpload
                helperText="Utilize um documento .pdf de preferência inferior a 10MB"
                error={errors.noDocumento?.message?.toString()}
                onChange={handleChangeDocumento}
                previewURL={APIUtils.generateDocumentoURL(documento)}
              />

            </Grid>

            <Grid item container justify="flex-end" spacing={2}>
              {onBack &&
                <Grid item>
                  <Button
                    id="btnVoltar"
                    variant="text"
                    color="default"
                    onClick={onBack}
                  >
                    Voltar
              </Button>
                </Grid>
              }
              <Grid item>
                <ButtonProgress
                  id="btnSubmit"
                  loading={isSubmitting}
                  disabled={Object.values(errors).length > 0 || (formMode == ProjetoDocumentoProspectoFormMode.EDIT && !dirty)}
                  variant="contained"
                  color="primary"
                  type="submit">
                  {submitText}
                </ButtonProgress>
              </Grid>
            </Grid>


          </Grid>


        </Box>
      </Paper>
    </form>
  )
}

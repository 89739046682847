import { Box, Button, Container, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import HomeIcon from '@material-ui/icons/Home';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';
import TimerSandIcon from 'mdi-material-ui/TimerSand';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedPlural } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Investidor from '../../../../models/Investidor';
import APIException from '../../../../services/APIException';
import InvestidorService from '../../../../services/InvestidorService';
import { BannerProps } from '../../../components/Banner';
import EmptyState from '../../../components/EmptyState';
import Loading from '../../../components/Loading';
import PageTemplate from '../../../layout/PageTemplate';
import ValidacaoDocumentosInvestidorItem from './components/ValidacaoDocumentosInvestidorItem';
import { selectToken } from '../../../../stores/slices/userSlice';
import { PageSubtitle } from '../../../layout/PageSubtitle';

export const ValidacaoDocumentosInvestidorPage = () => {

  const token = useSelector(selectToken) || '';

  const location = useLocation();
  const { from } = location.state as any || { from: '/conta' };

  const [isLoading, setIsLoading] = useState(true);
  const [banner, setBanner] = useState<BannerProps | undefined>(undefined);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [investidores, setInvestidores] = useState<Investidor[] | undefined>(undefined);

  const [unsavedFormData, setUnsavedFormData] = useState(false);
  const formsUpdated = useRef<string[]>([]);

  const fetchInitialState = () => {
    setIsLoading(true);
    new InvestidorService().searchInvestidoresComDocumentosParaValidacao(token)
      .then((investidores) => {
        setInvestidores(investidores);
      })
      .catch((apiexception: APIException) => {
        setBanner({
          text: `Erro ao buscar investidores: ${apiexception.message}`,
          traceId: apiexception.errorJson && apiexception.errorJson.traceid,
          timestamp: apiexception.errorJson && apiexception.errorJson.timestamp,
          icon: <SignalWifiOffIcon />,
          buttons: <Button onClick={() => { setBanner(undefined); fetchInitialState(); }} color="primary">Tentar Novamente</Button>
        })
        scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        console.log(apiexception);
      })
      .finally(() => setIsLoading(false))

  }
  useEffect(fetchInitialState, [])

  const handleFormUpdated = (formName: string) => (unsaved: boolean) => {
    if (unsaved) {
      formsUpdated.current.push(formName); // Marca que o form foi alterado
      if (!unsavedFormData) {
        setUnsavedFormData(true);
      }
    } else {
      formsUpdated.current = formsUpdated.current.filter(form => form != formName); // Desmarca que o form foi alterado
      if (formsUpdated.current.length == 0) {
        setUnsavedFormData(false);
      }
    }
    console.log("FormUpdated", formName, unsaved)
  }

  const updateStatusDocumentos = (coContaUsuario: number, investidor: Investidor) => {

    new InvestidorService().updateSituacaoDocumentos(coContaUsuario, investidor, token)
      .then(() => {
        const dismissButton = (key: string | number | undefined) => <IconButton onClick={() => closeSnackbar(key)}><CloseIcon color="action" /></IconButton>
        enqueueSnackbar('Situação dos documentos atualizada com sucesso!', { variant: 'success', action: dismissButton });
        // Atualiza lista de investidores
        if (investidores) {
          setInvestidores([...investidores.filter(r => (r.coContaUsuario) !== coContaUsuario)]);
        }
        // Seta que o form deixou de estar alterado
        handleFormUpdated("Validação" + coContaUsuario)(false);
      })
      .catch((apiexception: APIException) => {
        setBanner({
          text: `Erro ao tentar atualizar situação dos documentos: ${apiexception.message}`,
          traceId: apiexception.errorJson && apiexception.errorJson.traceid,
          timestamp: apiexception.errorJson && apiexception.errorJson.timestamp,
          icon: <ReportProblemIcon />,
          buttons: <Button onClick={() => { setBanner(undefined); updateStatusDocumentos(coContaUsuario, investidor) }} color="primary">Tentar Novamente</Button>
        })
        scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        console.log(apiexception);
      });


  }

  if (!investidores) {
    return (
      <PageTemplate
        menuDesktop
        headerMobile={{
          title: "Documentos para Validação",
          leftButtons: (
            <IconButton edge="start" color="inherit" component={Link} to={from}>
              <ArrowBackIcon />
            </IconButton>
          )
        }}
        headerDesktop={{
          title: "Documentos para Validação",
          breadcrumbs: [
            { label: "Home", to: '/', icon: HomeIcon },
            { label: "Documentos para Validação" }
          ]
        }}
        loading={{ isLoading }}
        banner={banner}
      >
        {isLoading && <Loading text="Buscando investidores..." />}
      </PageTemplate>
    );
  } else {
    return (
      <PageTemplate
        metaTags={{
          title: "Gottaup.com | Documentos para Validação",
          description: "Validação de documentos dos investidores"
        }}
        menuDesktop
        headerMobile={{
          title: "Documentos para Validação",
          leftButtons: (
            <IconButton edge="start" color="inherit" component={Link} to={from}>
              <ArrowBackIcon />
            </IconButton>
          )
        }}
        headerDesktop={{
          title: "Documentos para Validação",
          breadcrumbs: [
            { label: "Home", to: '/', icon: HomeIcon },
            { label: "Documentos para Validação" }
          ]
        }}
        banner={banner}
        confirmLeavePage={{
          when: unsavedFormData,
          title: "Tem certeza que deseja deixar a página sem salvar as alterações?",
          content: "Ao confirmar os dados alterados não serão salvos. Se cancelar, poderá continuar a edição."
        }}
      >

        <Container component="section">

          {investidores.length === 0 &&
            <EmptyState
              icon={<TimerSandIcon style={{ fontSize: '58px' }} color="disabled" />}
              title="Não há documentos para validar até o momento."
              subtitle="Quando novos investidores forem cadastrados eles serão listadas aqui"
            />
          }

          {investidores.length > 0 &&
            <>
              <PageSubtitle>
                Exibindo {investidores.length} <FormattedPlural value={investidores.length} one="investidor" other="investidores" />
              </PageSubtitle>

              {investidores.map(investidor => {
                return (
                  <ValidacaoDocumentosInvestidorItem
                    key={investidor.coContaUsuario}
                    investidor={investidor}
                    onFormSubmit={updateStatusDocumentos}
                    onFormUpdate={handleFormUpdated("Validação" + (investidor.coContaUsuario))}
                  />
                );
              })}

              <Box mb={3} />

            </>

          }
        </Container>

      </PageTemplate>

    )
  }
}
import ContaUsuario, { ContaUsuarioVO } from "./ContaUsuario";
import { MotivoRemocaoForum } from "./enums/MotivoRemocaoForum";
import { SituacaoPerguntaForum } from "./enums/SituacaoPerguntaForum";
import ISODateTime from "./types/ISODateTime";

export interface PerguntaForumProjetoVO {
  coPerguntaForumProjeto: number;
  usuarioPergunta: ContaUsuarioVO;
  dhPergunta: string;
  dePergunta: string;
  situacaoPerguntaForum: SituacaoPerguntaForum; 
  usuarioResposta: ContaUsuarioVO | undefined;
  dhResposta: string | undefined;
  deResposta: string | undefined;
  usuarioRemocao: ContaUsuarioVO | undefined;
  dhRemocao: string | undefined;
  motivoRemocaoForum: MotivoRemocaoForum | undefined;
}

export default class PerguntaForumProjeto {

    public coPerguntaForumProjeto: number | undefined;
    public usuarioPergunta: ContaUsuario | undefined;
    public dhPergunta: ISODateTime | undefined;
    public dePergunta: string | undefined;
    public situacaoPerguntaForum: SituacaoPerguntaForum | undefined; 
    public usuarioResposta: ContaUsuario | undefined;
    public dhResposta: ISODateTime | undefined;
    public deResposta: string | undefined;
    public usuarioRemocao: ContaUsuario | undefined;
    public dhRemocao: ISODateTime | undefined;
    public motivoRemocaoForum: MotivoRemocaoForum | undefined;

    constructor(obj: object | null = null) {
        if (obj) {
            Object.assign(this, obj);
            if (this.usuarioPergunta) this.usuarioPergunta = new ContaUsuario(this.usuarioPergunta);
            if (this.dhPergunta) this.dhPergunta = new ISODateTime(String(this.dhPergunta))
            if (this.usuarioResposta) this.usuarioResposta = new ContaUsuario(this.usuarioResposta);
            if (this.dhResposta) this.dhResposta = new ISODateTime(String(this.dhResposta))
            if (this.usuarioRemocao) this.usuarioRemocao = new ContaUsuario(this.usuarioRemocao);
            if (this.dhRemocao) this.dhRemocao = new ISODateTime(String(this.dhRemocao))
            
            Object.seal(this); // Não permite alterar estrutura da classe
        }
    }


}
import { Perfil } from "../models/enums/Perfil";
import { LoginUsuario } from "./login";

interface Token {
  exp: number;
  iat: number;
  iss: string;
  nome: string;
  perfis: Perfil[];
  sub: string;
  foto: number;
  id: number;
}

const parseJwtToToken = (token: string): Token => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
}

export const parseJwtTokenToLoginUsuario = (token: string): LoginUsuario => {
  const payload = parseJwtToToken(token);
  return {
    token,
    noUsuario: payload.nome,
    noEmail: payload.sub,
    perfis: payload.perfis,
    expiration: payload.exp,
    foto: payload.foto,
    id: payload.id
  };
}

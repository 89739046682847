import { Container } from '@material-ui/core'
import React, { ReactNode } from 'react'
import ConfirmLeavePage, { ConfirmLeavePageProps } from '../components/ComfirmLeavePage'
import PageBanner, { PageBannerProps } from './PageBanner'
import PageHeaderDesktop, { PageHeaderDesktopProps } from './PageHeaderDesktop'
import PageHeaderMobile, { PageHeaderMobileProps } from './PageHeaderMobile'
import { PageLoading, PageLoadingProps } from './PageLoading'
import PageMenuDesktop from './PageMenuDesktop'
import PageMenuMobile from './PageMenuMobile'
import { PageMetaTags, PageMetaTagsProps } from './PageMetaTags'

interface PageTemplateProps {
    metaTags?: PageMetaTagsProps;
    children: ReactNode;
    menuDesktop?: boolean;
    menuMobile?: boolean;
    headerMobile?: PageHeaderMobileProps;
    headerDesktop?: PageHeaderDesktopProps;
    loading?: PageLoadingProps;
    banner?: PageBannerProps;
    confirmLeavePage?: ConfirmLeavePageProps;
}

export default function PageTemplate(props: PageTemplateProps) {
    return (
        <>
            {props.metaTags && 
                <PageMetaTags {...props.metaTags}/>
            }
            
            {props.menuDesktop &&
                <PageMenuDesktop />
            }

            {props.menuMobile &&
                <PageMenuMobile />
            }

            {props.headerMobile &&
                <PageHeaderMobile {...props.headerMobile} />
            }

            {props.loading &&
                <PageLoading {...props.loading} />
            }

            <PageBanner {...props.banner} />

            {props.confirmLeavePage &&
                <ConfirmLeavePage {...props.confirmLeavePage} />
            }

            {props.headerDesktop &&
                <Container>
                    <PageHeaderDesktop {...props.headerDesktop} />
                </Container>
            }


            {props.children}
        </>
    )
}
import React from 'react'
import { makeStyles, Theme, createStyles, Card, CardActionArea, CardContent, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => createStyles({
  optionsCard: {
    display: 'block',
    position: 'relative',
    textAlign: 'center',
    color: theme.palette.action.active,
    transition: 'all 100ms ease-in',
    '&:hover': {
      transform: 'scale(1.03)'
    }
  },
  optionsIcon: {
    fontSize: 48
  }
}), { name: 'ButtonWheresNext' });

interface ButtonWheresNextProps {
  icon: React.ComponentType<any>;
  label: string;
  linkTo?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}


export const ButtonWheresNext = ({ linkTo, label, icon, onClick }: ButtonWheresNextProps) => {

  const Icon = icon;

  const classes = useStyles();

  return (
    <Card variant="outlined" className={classes.optionsCard}>

      {linkTo
        ?
        <CardActionArea component={Link} to={linkTo}>
          <CardContent >
            <Icon className={classes.optionsIcon} />
            <Typography variant="subtitle1"><strong>{label}</strong></Typography>
          </CardContent>
        </CardActionArea>
        :
        <CardActionArea onClick={onClick}>
          <CardContent >
            <Icon className={classes.optionsIcon} />
            <Typography variant="subtitle1"><strong>{label}</strong></Typography>
          </CardContent>
        </CardActionArea>
      }

    </Card>
  )
}

import { useForkRef } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { Area } from 'react-easy-crop';
import { FileUtils } from '../../../../../utils/files';
import { ImageUtils } from '../../../../../utils/images';
import { FormPhotoUploadEditor } from './components/FormPhotoUploadEditor';
import { FormPhotoUploadEmptyState } from './components/FormPhotoUploadEmptyState';
import { FormPhotoUploadPreview } from './components/FormPhotoUploadPreview';

enum FormPhotoUploadSteps {
    EMPTY,
    PREVIEW,
    EDITOR
}

export interface FormPhotoUploadProps {
    name?: string;
    previewURL?: string;
    helperText?: string;
    error?: string;
    inputRef?: React.Ref<any>;
    canRemove?: boolean;
    canEdit?: boolean;
    aspect?: number;
    variant: "avatar"|"picture";
    accept: string;
    onChange?: (file: File|null) => void;
}

export default function FormPhotoUpload(props: FormPhotoUploadProps) {

    // State
    const [step, setStep] = useState(props.previewURL?FormPhotoUploadSteps.PREVIEW:FormPhotoUploadSteps.EMPTY);
    const [previewURL, setPreviewURL] = useState<string | undefined>(props.previewURL);
    const [uploadedFile, setUploadedFile] = useState<File>();
    const [uploadedDataURL, setUploadedDataURL] = useState<string>();

    const [showPhotoEditButton, setShowPhotoEditButton] = useState(false);
    
    // Refs
    const inputFileRef = useRef<HTMLInputElement>(null);
    const combinedRef = useForkRef(inputFileRef, props.inputRef || null);

    // Handlers
    const handleFileUploadClick = () => inputFileRef.current?.click();
    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] as File;
        if(FileUtils.isFileImage(file)){
            const fileDataURL = await FileUtils.fileToDataURL(file);
            setUploadedFile(file);
            setPreviewURL(fileDataURL);
            setUploadedDataURL(fileDataURL);
            if(props.canEdit){
              setShowPhotoEditButton(true)
            }
            setStep(FormPhotoUploadSteps.PREVIEW);
        } else {
            setUploadedFile(undefined);
            setUploadedDataURL(undefined);
            setPreviewURL(undefined);
            setStep(FormPhotoUploadSteps.EMPTY)
        }
        inputFileRef.current?.dispatchEvent(new Event("blur"));
        if (props.onChange) props.onChange(file);
    }
    const handleFileRemove = () => {
        setUploadedFile(undefined);
        setPreviewURL(undefined);
        setUploadedDataURL(undefined);
        if (props.onChange) props.onChange(null);
        setStep(FormPhotoUploadSteps.EMPTY)
    }
    const handleStartEditClick = () => setStep(FormPhotoUploadSteps.EDITOR);

    const handleFinishEditClick = async (pixelCrop: Area, rotation: number) => {
        const croppedImage = ImageUtils.cropImage(uploadedDataURL as string, pixelCrop, rotation,FileUtils.dataURLMimeType(previewURL as string))
        const editedFile = FileUtils.dataURLtoFile(croppedImage, uploadedFile?.name as string)
        setPreviewURL(croppedImage);
        if (props.onChange) props.onChange(editedFile);
        setStep(FormPhotoUploadSteps.PREVIEW);
    }

    return (
        <>
            <input name={props.name} ref={combinedRef} type="file" hidden accept={props.accept} onChange={handleFileChange} />

            {step == FormPhotoUploadSteps.EMPTY &&
                <FormPhotoUploadEmptyState
                    error={props.error}
                    helperText={props.helperText}
                    variant={props.variant}
                    onFileUploadClick={handleFileUploadClick} />
            }

            {step == FormPhotoUploadSteps.PREVIEW &&
                <FormPhotoUploadPreview
                    error={props.error}
                    showPhotoEditButton={showPhotoEditButton}
                    helperText={props.helperText}
                    variant={props.variant}
                    fileURL={previewURL as string}
                    showPhotoRemoveButton={props.canRemove}
                    onFileChangeClick={handleFileUploadClick}
                    onFileEditClick={handleStartEditClick}
                    onFileRemoveClick={handleFileRemove} />
            }

            {step == FormPhotoUploadSteps.EDITOR &&
                <FormPhotoUploadEditor
                    image={uploadedDataURL as string}
                    aspect={props.aspect}
                    showGrid={false}
                    cropShape={props.variant=="avatar"?"round":"rect"}
                    onFinishEditClick={handleFinishEditClick} />
            }


        </>
    )

}
import { makeStyles, createStyles } from "@material-ui/core";

const useGlobalStyles = makeStyles(() => createStyles({
    '@global': {
      html: {
        overflowY: 'scroll',
        scrollbarWidth: 'none'
      },
      '::-webkit-scrollbar': {
        width: 0
      }
    }
  
  }), { name: 'GlobalStyles' });

  export default useGlobalStyles
import AbortController from 'abort-controller';
import fetch from 'isomorphic-fetch';
import ContaUsuario  from '../models/ContaUsuario';
import { ImagemVO } from '../models/Imagem';
import APIErrorMessage from './APIErrorMessage';
import APIException from './APIException';
import { getServiceURL } from './ServiceURL';
import { LoginUtils } from '../utils/login';
import { parseJwtTokenToLoginUsuario } from '../utils/token';

const BASE_URL = `${getServiceURL()}/api/login`;
//const TRANSACTION_TIMEOUT = 5000;

export default class LoginService {


  async updateImagemPerfil(imagemPerfil: ImagemVO, token: string) {
    if (__isBrowser__ && location.hostname != "localhost" && navigator.onLine !== undefined && navigator.onLine === false) {
      throw new APIException(new APIErrorMessage("Navegador Offline - Verifique sua conexão com a internet"));
    }
    const controller = new AbortController();
    const signal = controller.signal;
    const timeoutId = setTimeout(() => controller.abort(), TRANSACTION_TIMEOUT);
    let response;
    try {
      response = await fetch(`${BASE_URL}/imagemperfil`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(imagemPerfil),
        signal
      });
    } catch (ex) {
      console.log(ex);
      throw new APIException(new APIErrorMessage().setFromError(ex));
    }
    clearTimeout(timeoutId);
    if (response.status != 204) {
      throw new APIException(await new APIErrorMessage().setFromResponse(response));
    }
  }

  async trocaSenha(email: string, senhaAtual: string, senhaNova: string, token: string) {
    if (__isBrowser__ && location.hostname != "localhost" && navigator.onLine !== undefined && navigator.onLine === false) {
      throw new APIException(new APIErrorMessage("Navegador Offline - Verifique sua conexão com a internet"));
    }
    const senhaAtualCrypto = LoginUtils.encriptarSenha(email, senhaAtual);
    const senhaNovaCrypto = LoginUtils.encriptarSenha(email, senhaNova);
    const controller = new AbortController();
    const signal = controller.signal;
    const timeoutId = setTimeout(() => controller.abort(), TRANSACTION_TIMEOUT);
    let response;
    try {
      response = await fetch(`${BASE_URL}/trocasenha`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
          senhaAtual: senhaAtualCrypto,
          senhaNova: senhaNovaCrypto
        }),
        signal
      });
    } catch (ex) {
      console.log(ex);
      throw new APIException(new APIErrorMessage().setFromError(ex));
    }
    clearTimeout(timeoutId);
    if (response.status != 204) {
      throw new APIException(await new APIErrorMessage().setFromResponse(response));
    }
  }




  async validaCodigoEmail(coContaUsuario: number, codigo: string) {
    if (__isBrowser__ && location.hostname != "localhost" && navigator.onLine !== undefined && navigator.onLine === false) {
      throw new APIException(new APIErrorMessage("Navegador Offline - Verifique sua conexão com a internet"));
    }
    const controller = new AbortController();
    const signal = controller.signal;
    const timeoutId = setTimeout(() => controller.abort(), TRANSACTION_TIMEOUT);
    let response;
    try {
      response = await fetch(`${BASE_URL}/validacao/email`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify({ coContaUsuario, codigo }),
        signal
      });
    } catch (ex) {
      console.log(ex);
      throw new APIException(new APIErrorMessage().setFromError(ex));
    }
    clearTimeout(timeoutId);
    if (response.status == 200) {
      const usuario = new ContaUsuario(await response.json());
      if (usuario.token) {
        const login = parseJwtTokenToLoginUsuario(usuario.token);
        usuario.loginUsuario = login;
      }
      return usuario;
    } else {
      throw new APIException(await new APIErrorMessage().setFromResponse(response));
    }
  }

  async reenviaCodigoEmail(coContaUsuario: number) {
    if (__isBrowser__ && location.hostname != "localhost" && navigator.onLine !== undefined && navigator.onLine === false) {
      throw new APIException(new APIErrorMessage("Navegador Offline - Verifique sua conexão com a internet"));
    }
    const controller = new AbortController();
    const signal = controller.signal;
    const timeoutId = setTimeout(() => controller.abort(), TRANSACTION_TIMEOUT);
    let response;
    try {
      response = await fetch(`${BASE_URL}/validacao/email/reenvio`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify({ coContaUsuario }),
        signal
      });
    } catch (ex) {
      console.log(ex);
      throw new APIException(new APIErrorMessage().setFromError(ex));
    }
    clearTimeout(timeoutId);
    if (response.status != 204) {
      throw new APIException(await new APIErrorMessage().setFromResponse(response));
    }
  }

  async resetSenhaEnvioToken(noEmail: string) {
    if (__isBrowser__ && location.hostname != "localhost" && navigator.onLine !== undefined && navigator.onLine === false) {
      throw new APIException(new APIErrorMessage("Navegador Offline - Verifique sua conexão com a internet"));
    }
    const controller = new AbortController();
    const signal = controller.signal;
    const timeoutId = setTimeout(() => controller.abort(), TRANSACTION_TIMEOUT);
    let response;
    try {
      response = await fetch(`${BASE_URL}/senha/reset/envio`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify({ noEmail }),
        signal
      });
    } catch (ex) {
      console.log(ex);
      throw new APIException(new APIErrorMessage().setFromError(ex));
    }
    clearTimeout(timeoutId);
    if (response.status != 204) {
      throw new APIException(await new APIErrorMessage().setFromResponse(response));
    }
  }

  async resetSenhaTroca(noEmail: string, codigo: string, senhaNova: string) {
    if (__isBrowser__ && location.hostname != "localhost" && navigator.onLine !== undefined && navigator.onLine === false) {
      throw new APIException(new APIErrorMessage("Navegador Offline - Verifique sua conexão com a internet"));
    }
    const senhaNovaCrypto = LoginUtils.encriptarSenha(noEmail, senhaNova);
    const controller = new AbortController();
    const signal = controller.signal;
    const timeoutId = setTimeout(() => controller.abort(), TRANSACTION_TIMEOUT);
    let response;
    try {
      response = await fetch(`${BASE_URL}/senha/reset/troca`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify({ noEmail, codigo, senhaNova: senhaNovaCrypto }),
        signal
      });
    } catch (ex) {
      console.log(ex);
      throw new APIException(new APIErrorMessage().setFromError(ex));
    }
    clearTimeout(timeoutId);
    if (response.status == 200) {
      const usuario = new ContaUsuario(await response.json());
      if (usuario.token) {
        const login = parseJwtTokenToLoginUsuario(usuario.token);
        usuario.loginUsuario = login;
      }
      return usuario;
    } else {
      throw new APIException(await new APIErrorMessage().setFromResponse(response));
    }
  }







}

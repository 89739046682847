import { Button, Card, CardContent, CardHeader, createStyles, Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { FormEvent, useReducer, useRef, useState } from 'react';
import { ReaisMaskedInput } from '../../../../../components/MaskedInputs';
import TextInput from '../../../../../components/TextInput';
import { setPathProperty, formatMoeda } from '../../../../../ViewUtils';
import GraficoSimulador from './GraficoSimulador';

const useStyles = makeStyles((theme: Theme) => createStyles({
  chartContainer: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.up('sm')]: {
      height: 400
    },
  },
  labelText: {
    color: 'rgba(0,0,0,.5)'
  },
  projeto: {
    color: "#EA5911FF"
  },
  cdi: {
    color: "#7F7F7FFF"
  },
  poupanca: {
    color: "#9E9AC8FF"
  },
  minimoGarantido: {
    color: "#2CA02CFF"
  }

}), { name: 'Simulador' });


interface Props {
  nomeInvestimento: string;
  rentabilidadeInvestimentoFixa?: number;
  rentabilidadeInvestimentoMin?: number;
  rentabilidadeInvestimentoMax?: number;
  rentabilidadeGarantida?: number;
  rentabilidadeCDI: number;
  rentabilidadePoupanca: number;
  prazoMeses: number;
  dtInicioTitulo: Date;
}

interface State {
  valorInvestimento: string;
}

export default function Simulador(props: Props) {

  const classes = useStyles();

  const state = useRef<State>({
    valorInvestimento: '10.000,00'
  });

  const [, forceUpdate] = useReducer(flag => !flag, false);

  const calcularTotal = (valorInvestimento: number, prazo: number, jurosAnual: number): number => {
    const jurosMensal = Math.pow(1 + jurosAnual, 1 / 12) - 1
    // Montante = Capital * (1 + taxa) ^ prazo
    return Number.parseFloat((valorInvestimento * Math.pow((1.0 + jurosMensal), prazo)).toFixed(2));
  }

  const poupancaId = `Poupança (${props.rentabilidadePoupanca.toFixed(4).replace('.', '').replace(/^(0*)(\d+)(\d{2})$/g, '$2,$3')}% a.a.)`;
  const poupancaTotal = calcularTotal(Number.parseFloat(state.current.valorInvestimento.replace(/\./g, '').replace(',', '.')), props.prazoMeses, props.rentabilidadePoupanca);

  const cdiId = `CDI (${props.rentabilidadeCDI.toFixed(4).replace('.', '').replace(/^(0*)(\d+)(\d{2})$/g, '$2,$3')}% a.a.)`;
  const cdiTotal = calcularTotal(Number.parseFloat(state.current.valorInvestimento.replace(/\./g, '').replace(',', '.')), props.prazoMeses, props.rentabilidadeCDI);

  const projetoFixaId = props.rentabilidadeInvestimentoFixa ? `Estimada (${props.rentabilidadeInvestimentoFixa.toFixed(4).replace('.', '').replace(/^(0*)(\d+)(\d{2})$/g, '$2,$3')}% a.a.)` : "";
  const projetoFixaTotal = props.rentabilidadeInvestimentoFixa ? calcularTotal(Number.parseFloat(state.current.valorInvestimento.replace(/\./g, '').replace(',', '.')), props.prazoMeses, props.rentabilidadeInvestimentoFixa) : 0;

  const projetoMinId = props.rentabilidadeInvestimentoMin ? `Mínima (${props.rentabilidadeInvestimentoMin.toFixed(4).replace('.', '').replace(/^(0*)(\d+)(\d{2})$/g, '$2,$3')}% a.a.)` : "";
  const projetoMinTotal = props.rentabilidadeInvestimentoMin ? calcularTotal(Number.parseFloat(state.current.valorInvestimento.replace(/\./g, '').replace(',', '.')), props.prazoMeses, props.rentabilidadeInvestimentoMin) : 0;

  const projetoMaxId = props.rentabilidadeInvestimentoMax ? `Máxima (${props.rentabilidadeInvestimentoMax.toFixed(4).replace('.', '').replace(/^(0*)(\d+)(\d{2})$/g, '$2,$3')}% a.a.)` : "";
  const projetoMaxTotal = props.rentabilidadeInvestimentoMax ? calcularTotal(Number.parseFloat(state.current.valorInvestimento.replace(/\./g, '').replace(',', '.')), props.prazoMeses, props.rentabilidadeInvestimentoMax) : 0;

  const rentabilidadeGarantidaId = props.rentabilidadeGarantida ? `Garantida (${props.rentabilidadeGarantida.toFixed(4).replace('.', '').replace(/^(0*)(\d+)(\d{2})$/g, '$2,$3')}% a.a.)` : "";
  const rentabilidadeGarantidaTotal = props.rentabilidadeGarantida ? calcularTotal(Number.parseFloat(state.current.valorInvestimento.replace(/\./g, '').replace(',', '.')), props.prazoMeses, props.rentabilidadeGarantida) : 0;


  const [invalidFormData, setInvalidFormData] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const handleValidInput = (path: string) => (newValue: string) => {
    setPathProperty(path, state.current, newValue);
    // Se estiver marcado que o form está inválido, revalida para verificar se zerou as pendências
    if (invalidFormData && formRef.current && formRef.current.checkValidity()) {
      setInvalidFormData(false); // Seta que o form não possui dados inválidos
    }
    console.log(state.current);
  }

  const handleInvalidInput = () => () => {
    if (!invalidFormData) {
      setInvalidFormData(true); // Seta que o form possui dados inválidos
    }
  }

  const atualizarSimulacao = (e: FormEvent) => {
    e.preventDefault();
    forceUpdate();
  }

  return (
    <form
      id="formSimulador"
      noValidate
      autoComplete="off"
      onSubmit={atualizarSimulacao}
      ref={formRef}>
      <Card>
        <CardHeader
          title="Simule seu investimento"
        />
        <Divider />
        <CardContent>
          <Grid container>
            <Grid item xs={12} md={4}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <TextInput
                    label="Valor do Investimento"
                    placeholder="0,00"
                    maxLength={11}
                    minValue={100.00}
                    inputComponent={ReaisMaskedInput as any}
                    startAdornment="R$"
                    validationMessages={{
                      rangeUnderflow: 'Valor deve ser superior a R$ 100,00',
                      patternMismatch: 'Formato ?.???.??9,99',
                      valueMissing: 'Informe o Valor do Investimento',
                      invalid: 'Informe o Valor do Investimento'
                    }}
                    pattern="^\d{1,7}(?:\.\d{3})*,\d{2}$"
                    initialValue={state.current.valorInvestimento}
                    onValidInput={handleValidInput("valorInvestimento")}
                    onInvalidInput={handleInvalidInput()}
                  />
                </Grid>
                <Grid item>
                  <Grid container justify="flex-end">
                    <Grid item >
                      <Button variant="outlined" color="secondary" type="submit" disabled={invalidFormData}>Simular</Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>

                </Grid>
                <Grid item>
                  <Grid container justify="space-between">
                    <Typography variant="subtitle2" color="inherit" component="p" className={classes.labelText}>Rentabilidades</Typography>
                    <Typography variant="subtitle2" color="inherit" component="p" className={classes.labelText}></Typography>
                  </Grid>
                </Grid>
                {props.rentabilidadeInvestimentoFixa &&
                  <Grid item>
                    <Grid container justify="space-between">
                      <Typography variant="subtitle2" color="inherit" component="p" className={classes.projeto}>{projetoFixaId}:</Typography>
                      <Typography variant="subtitle2" color="inherit" component="p" className={classes.projeto}>R$ {formatMoeda(projetoFixaTotal)}</Typography>
                    </Grid>
                  </Grid>
                }

                {props.rentabilidadeInvestimentoMax &&
                  <Grid item>
                    <Grid container justify="space-between">
                      <Typography variant="subtitle2" color="inherit" component="p" className={classes.projeto}>{projetoMaxId}:</Typography>
                      <Typography variant="subtitle2" color="inherit" component="p" className={classes.projeto}>R$ {formatMoeda(projetoMaxTotal)}</Typography>
                    </Grid>
                  </Grid>
                }
                {props.rentabilidadeInvestimentoMin &&
                  <Grid item>
                    <Grid container justify="space-between">
                      <Typography variant="subtitle2" color="inherit" component="p" className={classes.projeto}>{projetoMinId}:</Typography>
                      <Typography variant="subtitle2" color="inherit" component="p" className={classes.projeto}>R$ {formatMoeda(projetoMinTotal)}</Typography>
                    </Grid>
                  </Grid>
                }
                {props.rentabilidadeGarantida &&
                  <Grid item>
                    <Grid container justify="space-between">
                      <Typography variant="subtitle2" color="inherit" component="p" className={classes.minimoGarantido}>{rentabilidadeGarantidaId}:</Typography>
                      <Typography variant="subtitle2" color="inherit" component="p" className={classes.minimoGarantido}>R$ {formatMoeda(rentabilidadeGarantidaTotal)}</Typography>
                    </Grid>
                  </Grid>
                }
                <Grid item>
                  <Grid container justify="space-between">
                    <Typography variant="subtitle2" color="inherit" component="p" className={classes.cdi}>{cdiId}:</Typography>
                    <Typography variant="subtitle2" color="inherit" component="p" className={classes.cdi}>R$ {formatMoeda(cdiTotal)}</Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container justify="space-between">
                    <Typography variant="subtitle2" color="inherit" component="p" className={classes.poupanca}>{poupancaId}:</Typography>
                    <Typography variant="subtitle2" color="inherit" component="p" className={classes.poupanca}>R$ {formatMoeda(poupancaTotal)}</Typography>
                  </Grid>
                </Grid>
              </Grid>


            </Grid>
            <Grid item className={classes.chartContainer} xs={12} md={8}>
              <GraficoSimulador
                dtInicioTitulo={props.dtInicioTitulo}
                nomeInvestimento={props.nomeInvestimento}
                prazoMeses={props.prazoMeses}
                rentabilidadeCDI={props.rentabilidadeCDI}
                rentabilidadePoupanca={props.rentabilidadePoupanca}
                rentabilidadeInvestimentoFixa={props.rentabilidadeInvestimentoFixa}
                rentabilidadeInvestimentoMin={props.rentabilidadeInvestimentoMin}
                rentabilidadeInvestimentoMax={props.rentabilidadeInvestimentoMax}
                rentabilidadeGarantida={props.rentabilidadeGarantida}
                valorInvestimento={Number.parseFloat(state.current.valorInvestimento.replace(/\./g, '').replace(',', '.'))}
              />
            </Grid>
          </Grid>



        </CardContent>

      </Card>


    </form>

  )
}
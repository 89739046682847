import {Button, createStyles, Grid, makeStyles, Paper, Theme} from '@material-ui/core';
import {green, red, yellow} from '@material-ui/core/colors';
import {useSnackbar} from 'notistack';
import React, {Fragment} from 'react';
import {DocumentoVO} from '../../../../../models/Documento';
import {InvestimentoVO} from '../../../../../models/Investimento';
import DownloadBox from '../../../../components/DownloadBox';
import {Link, useLocation} from 'react-router-dom';
import AccountCardDetailsIcon from 'mdi-material-ui/AccountCardDetails';
import {SituacaoSelfie} from '../../../../../models/enums/SituacaoSelfie';
import {SituacaoComprovanteResidencia} from '../../../../../models/enums/SituacaoComprovanteResidencia';
import {FieldOutput} from "../../../../components/form/output/FieldOutput";
import moment from "moment";
import {APIUtils} from "../../../../../services/api/APIUtils";
import {useIntl} from "react-intl";
import {ButtonFileUpload} from "../../../../components/ButtonFileUpload";
import {FileUtils} from "../../../../../utils/files";
import {useDialog} from "../../../../components/dialog/PageServiceProvider";


const useStyles = makeStyles((theme: Theme) => createStyles({
  form: {
    marginBottom: 0
  },
  actions: {
    justifyContent: 'flex-end'
  },
  documentoContainer: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 400,
    },
  },
  listItem: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(3, 2),
  },
}), {name: 'UploadContratoInvestimentoItem'});


interface Props {
  investimento: InvestimentoVO;
  onFormSubmit: (coContaUsuario: number, coInvestimento: number, documento: Partial<DocumentoVO>) => void;
}

interface State {
  documento: Partial<DocumentoVO>;
}

export const UploadContratoInvestimentoItem = ({investimento,onFormSubmit}: Props) => {

  const classes = useStyles();

  const intl = useIntl();

  const dialog = useDialog();

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const location = useLocation();

  const getSituacaoComprovanteColor = (situacao: SituacaoComprovanteResidencia) => {
    switch (situacao) {
      case SituacaoComprovanteResidencia.Inválido:
        return red[600];
      case SituacaoComprovanteResidencia["Em Análise"]:
        return yellow[600];
      case SituacaoComprovanteResidencia.Aprovado:
        return green[500];
    }
  }

  const getSituacaoSelfieColor = (situacao: SituacaoSelfie) => {
    switch (situacao) {
      case SituacaoSelfie.Inválido:
        return red[600];
      case SituacaoSelfie["Em Análise"]:
        return yellow[600];
      case SituacaoSelfie.Aprovado:
        return green[500];
    }
  }

  const handleUploadContrato = async (files: FileList) => {
    const file = files[0];
    if (file) {
      if (!FileUtils.isFilePdf(file)) {
        enqueueSnackbar('Formato de arquivo inválido!', {
          variant: 'error',
          action: <Button onClick={() => closeSnackbar()}>OK</Button>
        });
      } else {
        const confirmed = await dialog({
          title: "Confirma upload do Contrato?",
          description: "Ao confirmar o contrato será anexado ao investimento. Se cancelar, nenhuma alteração será salva no servidor.",
          confirmOption: "CONFIRMAR",
          cancelOption: "CANCELAR"
        })
        if (confirmed) {
          const documento: Partial<DocumentoVO> = {
            noDocumento: file.name,
            imDocumento: await FileUtils.fileToBase64(file)
          }
          await onFormSubmit(investimento.contaUsuario.coContaUsuario, investimento.coInvestimento, documento);
        }
      }
    }
  }


  return (
    <Fragment>
      <Paper key={investimento.coInvestimento} className={classes.listItem}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={8} sm={3} md={2}>
            <FieldOutput label="Data Reserva" value={moment(investimento.dhReserva).format("L")}/>
          </Grid>

          <Grid item xs={4} sm={3} md={2}>
            <FieldOutput label="Valor"
                         value={intl.formatNumber(investimento.vrInvestimento, {style: 'currency', currency: 'BRL'})}/>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FieldOutput label="Investidor" value={investimento.contaUsuario?.noUsuario}/>
          </Grid>

          <Grid item xs={4} sm={3} md={2}>
            <FieldOutput
              label="Selfie com Documento"
              value={SituacaoSelfie[investimento.contaUsuario?.investidor?.icSituacaoSelfie || 0]}
              color={getSituacaoSelfieColor(investimento.contaUsuario?.investidor?.icSituacaoSelfie || 0)}
            />
          </Grid>

          <Grid item xs={4} sm={3} md={2}>
            <FieldOutput
              label="Comprovante Residencia"
              value={SituacaoComprovanteResidencia[investimento.contaUsuario?.investidor?.icSituacaoComprovanteResidencia || 0]}
              color={getSituacaoComprovanteColor(investimento.contaUsuario?.investidor?.icSituacaoComprovanteResidencia || 0)}
            />
          </Grid>

          <Grid item xs={4} sm={3} md={2}>
            <FieldOutput
              label="Upload Contrato"
              value={investimento.documentoContrato === undefined ? 'Pendente' : 'Efetuado'}
              color={investimento.documentoContrato === undefined ? red[600] : green[500]}
            />
          </Grid>

          <Grid item xs={12} sm={5} md={4}>
            {investimento.documentoContrato !== undefined &&
            <DownloadBox name="Contrato"
                         url={APIUtils.generateDocumentoURL(investimento.documentoContrato)}
                         icon="pdf"
            />
            }
          </Grid>


          <Grid item xs={12} sm={7} md={6}>
            <Grid container justify="flex-end" spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  component={Link}
                  startIcon={<AccountCardDetailsIcon/>}
                  to={{
                    pathname: `/conta/${investimento.contaUsuario && investimento.contaUsuario.coContaUsuario}/investidor/edit`,
                    state: {from: location.pathname}
                  }}
                >
                  Cadastro
                </Button>
              </Grid>
              <Grid item>
                <ButtonFileUpload
                  color="secondary"
                  variant="contained"
                  accept=".pdf"
                  onFileUpload={handleUploadContrato}
                >
                  {investimento.documentoContrato !== undefined ? "Trocar Contrato" : "Upload Contrato"}
                </ButtonFileUpload>

              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Paper>
    </Fragment>

  )
}
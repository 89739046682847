import Imagem, { ImagemVO } from './Imagem';
import { Perfil } from './enums/Perfil';
import Investidor, {InvestidorVO} from './Investidor';
import { LoginUsuario } from '../utils/login';

export interface ContaUsuarioVO {
    coContaUsuario: number;
    noUsuario: string;
    noEmail: string;
    senha: string;
    imagemPerfil: ImagemVO | undefined;
    perfis: Perfil[] | undefined;
    investidor: InvestidorVO | undefined;
    loginUsuario: LoginUsuario | undefined;
    token: string | undefined;
    icAtiva: boolean;
    icEmailValidado: boolean;
    icSenhaBloqueada: boolean;
    icSenhaExpirada: boolean;
}

export default class ContaUsuario {

    public coContaUsuario: number | undefined;
    public noUsuario: string | undefined;
    public noEmail: string | undefined;
    public senha: string | undefined;
    public token: string | undefined;
    public loginUsuario: LoginUsuario | undefined;
    public imagemPerfil: Imagem | undefined;
    public perfis: Perfil[] | undefined;
    public investidor: Investidor | undefined;
    public icAtiva: boolean | undefined;
    public icEmailValidado: boolean | undefined;
    public icSenhaBloqueada: boolean | undefined;
    public icSenhaExpirada: boolean | undefined;

    constructor(obj: object | null = null) {
        if (obj) {
            Object.assign(this, obj);
            if (this.imagemPerfil) this.imagemPerfil = new Imagem(this.imagemPerfil);
            if (this.investidor) this.investidor = new Investidor(this.investidor);
        }
        Object.seal(this); // Não permite alterar estrutura da classe
    }

}

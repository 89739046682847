import { Box, Button, Container, createStyles, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import HomeIcon from '@material-ui/icons/Home';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';
import TimerSandIcon from 'mdi-material-ui/TimerSand';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { FormattedPlural } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Empresa from '../../../../models/Empresa';
import { MotivoCancelamentoInvestimento } from '../../../../models/enums/MotivoCancelamentoInvestimento';
import Investimento from '../../../../models/Investimento';
import Projeto from '../../../../models/Projeto';
import APIException from '../../../../services/APIException';
import EmpresaService from '../../../../services/EmpresaService';
import InvestidorService from '../../../../services/InvestidorService';
import ProjetoService from '../../../../services/ProjetoService';
import { BannerProps } from '../../../components/Banner';
import EmptyState from '../../../components/EmptyState';
import Loading from '../../../components/Loading';
import TextInput from '../../../components/TextInput';
import PageTemplate from '../../../layout/PageTemplate';
import AprovacaoDepositosReservasItem from './components/AprovacaoDepositosReservasItem';
import { selectToken } from '../../../../stores/slices/userSlice';
import { PageSubtitle } from '../../../layout/PageSubtitle';


const useStyles = makeStyles((theme: Theme) => createStyles({
    title: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    fieldGroup: {
        marginTop: theme.spacing(2)
    },
    paragrafo: {
        marginLeft: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(1),
    },
}), { name: 'AprovacaoDepositosReservas' });


export const AprovacaoDepositosReservasPage = () => {

    const classes = useStyles();

    const token = useSelector(selectToken)||'';

    const [isLoading, setIsLoading] = useState(true);
    const [banner, setBanner] = useState<BannerProps | undefined>(undefined);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [empresas, setEmpresas] = useState<Empresa[] | undefined>(undefined);
    const [projetos, setProjetos] = useState<Projeto[] | undefined>(undefined);
    const [reservas, setReservas] = useState<Investimento[] | undefined>(undefined);

    const projetoSelecionado = React.useRef<Projeto | undefined>(undefined);

    const fetchInitialState = () => {
        setIsLoading(true);
        new EmpresaService().comboBoxList(token)
            .then((empresas) => {
                if (empresas) {
                    setEmpresas(empresas);
                } else {
                    Promise.reject(new Error("loadData undefined"));
                }
            })
            .catch((apiexception: APIException) => {
                setBanner({
                    text: `Erro ao buscar empresas: ${apiexception.message}`,
                    traceId: apiexception.errorJson && apiexception.errorJson.traceid,
                    timestamp: apiexception.errorJson && apiexception.errorJson.timestamp,
                    icon: <SignalWifiOffIcon />,
                    buttons: <Button onClick={() => { setBanner(undefined); fetchInitialState(); }} color="primary">Tentar Novamente</Button>
                })
                scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                console.log(apiexception);
            })
            .finally(() => setIsLoading(false))

    }
    useEffect(fetchInitialState, [])

    const handleChangeEmpresa = (newValue: string) => {
        if (newValue) {
            new EmpresaService().comboBoxListProjetosByEmpresa(parseInt(newValue), token)
                .then((projetos) => {
                    setProjetos(projetos);
                })
                .catch((apiexception: APIException) => {
                    setBanner({
                        text: `Erro ao buscar projetos: ${apiexception.message}`,
                        traceId: apiexception.errorJson && apiexception.errorJson.traceid,
                        timestamp: apiexception.errorJson && apiexception.errorJson.timestamp,
                        icon: <SignalWifiOffIcon />,
                        buttons: <Button onClick={() => { setBanner(undefined); handleChangeEmpresa(newValue); }} color="primary">Tentar Novamente</Button>
                    })
                    scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    console.log(apiexception);
                });
        } else {
            // Limpa Lista de Projetos, caso não seja selecionada a Empresa  
            if (projetos) {
                setProjetos(undefined);
            }
        }
        // Limpa lista de reservas
        if (reservas) {
            setReservas(undefined);
        }
    }

    const carregarReservas = () => {
        if (projetoSelecionado.current && projetoSelecionado.current.nuProjeto) {
            new ProjetoService().listReservasByProjeto(projetoSelecionado.current.nuProjeto, token)
                .then((reservas) => {
                    setReservas(reservas);
                })
                .catch((apiexception: APIException) => {
                    setBanner({
                        text: `Erro ao buscar reservas: ${apiexception.message}`,
                        traceId: apiexception.errorJson && apiexception.errorJson.traceid,
                        timestamp: apiexception.errorJson && apiexception.errorJson.timestamp,
                        icon: <SignalWifiOffIcon />,
                        buttons: <Button onClick={() => { setBanner(undefined); carregarReservas(); }} color="primary">Tentar Novamente</Button>
                    })
                    scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    console.log(apiexception);
                });
        }
    }

    const handleChangeProjeto = (newValue: string) => {
        if (newValue) {
            projetoSelecionado.current = new Projeto({ nuProjeto: newValue });
            carregarReservas();
        } else {
            projetoSelecionado.current = undefined;
            // Limpa lista de reservas
            if (reservas) {
                setReservas(undefined);
            }
        }

    }



    const aprovarReserva = (coContaUsuario: number, coInvestimento: number) => {
        new InvestidorService().aprovarReserva(coContaUsuario, coInvestimento, token)
            .then((inv) => {
                const dismissButton = (key: string | number | undefined) => <IconButton onClick={() => closeSnackbar(key)}><CloseIcon color="action" /></IconButton>
                enqueueSnackbar('Reserva aprovada com sucesso!', { variant: 'success', action: dismissButton });
                // Atualiza lista de reservas
                if (reservas) {
                    const index = reservas.findIndex(t => t.coInvestimento === coInvestimento);
                    const newObject = new Investimento(
                        Object.assign({},
                            JSON.parse(JSON.stringify(reservas[index])),
                            JSON.parse(JSON.stringify(inv))
                        )
                    );
                    reservas[index] = newObject;
                    setReservas([...reservas]);
                }
            })
            .catch((apiexception: APIException) => {
                setBanner({
                    text: `Erro ao tentar aprovar reserva: ${apiexception.message}`,
                    traceId: apiexception.errorJson && apiexception.errorJson.traceid,
                    timestamp: apiexception.errorJson && apiexception.errorJson.timestamp,
                    icon: <ReportProblemIcon />,
                    buttons: <Button onClick={() => { setBanner(undefined); aprovarReserva(coContaUsuario, coInvestimento) }} color="primary">Tentar Novamente</Button>
                })
                scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                console.log(apiexception);
            });
    }

    const cancelarReserva = (coContaUsuario: number, coInvestimento: number, motivoCancelamento: MotivoCancelamentoInvestimento) => {

        new InvestidorService().cancelarReserva(coContaUsuario, coInvestimento, motivoCancelamento, token)
            .then((inv) => {
                const dismissButton = (key: string | number | undefined) => <IconButton onClick={() => closeSnackbar(key)}><CloseIcon color="action" /></IconButton>
                enqueueSnackbar('Reserva cancelada com sucesso!', { variant: 'success', action: dismissButton });
                // Atualiza lista de reservas
                if (reservas) {
                    const index = reservas.findIndex(t => t.coInvestimento === coInvestimento);
                    const newObject = new Investimento(
                        Object.assign({},
                            JSON.parse(JSON.stringify(reservas[index])),
                            JSON.parse(JSON.stringify(inv))
                        )
                    );
                    reservas[index] = newObject;
                    setReservas([...reservas]);
                }
            })
            .catch((apiexception: APIException) => {
                setBanner({
                    text: `Erro ao tentar cancelar reserva: ${apiexception.message}`,
                    traceId: apiexception.errorJson && apiexception.errorJson.traceid,
                    timestamp: apiexception.errorJson && apiexception.errorJson.timestamp,
                    icon: <ReportProblemIcon />,
                    buttons: <Button onClick={() => { setBanner(undefined); cancelarReserva(coContaUsuario, coInvestimento, motivoCancelamento) }} color="primary">Tentar Novamente</Button>
                })
                scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                console.log(apiexception);
            });
    }
    if (isLoading) {
        return (
            <PageTemplate
                menuDesktop
                headerMobile={{
                    title: "Aprovação de Reservas",
                    leftButtons: (
                        <IconButton edge="start" color="inherit" component={Link} to="/conta">
                            <ArrowBackIcon />
                        </IconButton>
                    )
                }}
                headerDesktop={{
                    title: "Aprovação de Reservas",
                    breadcrumbs: [
                        { label: "Home", to: '/', icon: HomeIcon },
                        { label: "Aprovação de Reservas" }
                    ]
                }}
                loading={{ isLoading }}
                banner={banner}
            >
                {isLoading && <Loading text="Carregando Formulário..." />}
            </PageTemplate>
        );
    } else {
        return (
            <PageTemplate
                metaTags={{
                    title: "Gottaup.com | Aprovação de Reservas",
                    description: "Aprovação de Reservas"
                }}
                menuDesktop
                headerMobile={{
                    title: "Aprovação de Reservas",
                    leftButtons: (
                        <IconButton edge="start" color="inherit" component={Link} to="/conta">
                            <ArrowBackIcon />
                        </IconButton>
                    )
                }}
                headerDesktop={{
                    title: "Aprovação de Reservas",
                    breadcrumbs: [
                        { label: "Home", to: '/', icon: HomeIcon },
                        { label: "Aprovação de Reservas" }
                    ]
                }}
                banner={banner}

            >
                <Container component="section">
                    <Typography variant="h6" className={classes.fieldGroup}>Selecionar Projeto</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextInput
                                required
                                label="Empresa"
                                helperText="Selecione a Empresa"
                                onChangeInput={handleChangeEmpresa}
                                select
                                autoFocus={true}
                            >
                                <option value="" />
                                {empresas && empresas.map(empresa => (
                                    <option key={empresa.nuEmpresa} value={empresa.nuEmpresa ? empresa.nuEmpresa.toString() : ''}>{empresa.noFantasia}</option>
                                ))}

                            </TextInput>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextInput
                                required
                                label="Projeto"
                                helperText="Selecione o Projeto"
                                onChangeInput={handleChangeProjeto}
                                select
                            >
                                <option value="" />
                                {projetos && projetos.map(projeto => (
                                    <option key={projeto.nuProjeto} value={projeto.nuProjeto ? projeto.nuProjeto.toString() : ''}>{projeto.noProjeto}</option>
                                ))}

                            </TextInput>
                        </Grid>
                    </Grid>

                    {reservas && reservas.length === 0 &&
                        <EmptyState
                            icon={<TimerSandIcon style={{ fontSize: '58px' }} color="disabled" />}
                            title="Não há reservas para aprovar até o momento."
                            subtitle="Quando houver reservas sem aprovação elas serão listadas aqui"
                        />
                    }

                    {reservas && reservas.length > 0 &&
                        <>
                            <PageSubtitle>
                              Exibindo {reservas.length} <FormattedPlural value={reservas.length} one="reserva" other="reservas" />
                            </PageSubtitle>

                            {reservas.map(reserva => {
                                return (
                                    <AprovacaoDepositosReservasItem
                                        key={reserva.coInvestimento}
                                        reserva={reserva}
                                        onAprovarReservaSubmit={aprovarReserva}
                                        onCancelarReservaSubmit={cancelarReserva}
                                    />
                                );
                            })}

                            <Box my={3} />

                        </>

                    }
                </Container>

            </PageTemplate>

        )
    }
}
import {Container, Grid, IconButton, Step, StepLabel, Stepper, useMediaQuery, useTheme} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HomeIcon from '@material-ui/icons/Home';
import {DeepPartial} from '@reduxjs/toolkit';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory, useParams} from 'react-router-dom';
import {AppDispatch} from '../../../../../../../../browser';
import {DadoBancarioVO} from '../../../../../../../models/DadoBancario';
import {DocumentoVO} from '../../../../../../../models/Documento';
import {EnderecoVO} from '../../../../../../../models/Endereco';
import {InvestidorVO} from '../../../../../../../models/Investidor';
import {APIError} from '../../../../../../../services/api/APIError';
import {generateErrorDialog} from '../../../../../../../services/api/APIErrorUtils';
import {createInvestidor} from '../../../../../../../stores/slices/entities/investidoresSlice';
import {selectLogin} from '../../../../../../../stores/slices/userSlice';
import {useDialog} from '../../../../../../components/dialog/PageServiceProvider';
import {DadosBancariosForm, DadosBancariosFormMode} from '../../../../../../components/form/shared/DadosBancariosForm';
import {EnderecoForm, EnderecoFormMode} from '../../../../../../components/form/shared/EnderecoForm';
import PageTemplate from '../../../../../../layout/PageTemplate';
import {
  InvestidorComprovanteEnderecoForm,
  InvestidorComprovanteEnderecoFormMode
} from './components/InvestidorComprovanteEnderecoForm';
import {InvestidorReviewCadastro} from './components/InvestidorReviewCadastro';
import {InvestidorSucessoCadastro} from './components/InvestidorSucessoCadastro';
import {InvestidorDadosBasicosForm, InvestidorDadosBasicosFormMode} from './components/InvestidorDadosBasicosForm';
import {
  InvestidorSelfieDocumentoForm,
  InvestidorSelfieDocumentoFormMode
} from './components/InvestidorSelfieDocumentoForm';


enum InvestidorCreateSteps {
  DADOS_BASICOS,
  SELFIE_DOCUMENTO,
  ENDERECO,
  COMPROVANTE_ENDERECO,
  DADOS_BANCARIOS,
  REVISAO,
  SUCESSO
}

export const InvestidorCreatePage = () => {

  const history = useHistory();
  const {from} = history.location.state as any || {from: {pathname: "/conta"}};

  const {id} = useParams<{ id: string }>();

  const login = useSelector(selectLogin);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const dispatch = useDispatch<AppDispatch>()

  const dialog = useDialog();

  const [step, setStep] = useState(InvestidorCreateSteps.DADOS_BASICOS);

  const [state, setState] = useState<DeepPartial<InvestidorVO>>({
    coContaUsuario: id ? Number(id) : login?.id || 0
  });

  const handleInvestidorDadosBasicosComplete = (nextState: DeepPartial<InvestidorVO>) => {
    setState({...state, ...nextState});
    setStep(InvestidorCreateSteps.SELFIE_DOCUMENTO);
    scrollTo({top: 0, behavior: "smooth"})
  };


  const handleSelfieDocumentoComplete = (nextState: DeepPartial<DocumentoVO>) => {
    setState({...state, documentoSelfieAutentica: {...nextState}});
    setStep(InvestidorCreateSteps.ENDERECO);
    scrollTo({top: 0, behavior: "smooth"})
  };


  const handleEnderecoComplete = (nextState: DeepPartial<EnderecoVO>) => {
    setState({...state, endereco: {...nextState}});
    setStep(InvestidorCreateSteps.COMPROVANTE_ENDERECO);
    scrollTo({top: 0, behavior: "smooth"})
  };

  const handleComprovatenResidenciaComplete = (nextState: DeepPartial<DocumentoVO>) => {
    setState({...state, documentoComprovanteResidencia: {...nextState}});
    setStep(InvestidorCreateSteps.DADOS_BANCARIOS);
    scrollTo({top: 0, behavior: "smooth"})
  };

  const handleDadosBancariosComplete = (nextState: DeepPartial<DadoBancarioVO>) => {
    setState({...state, dadoBancario: {...nextState}});
    setStep(InvestidorCreateSteps.REVISAO);
    scrollTo({top: 0, behavior: "smooth"})
  };


  const handleRevisaoComplete = async () => {


    const resultAction = await dispatch(createInvestidor({investidor: state}));
    if (createInvestidor.fulfilled.match(resultAction)) {
      setStep(InvestidorCreateSteps.SUCESSO);
      scrollTo({top: 0, behavior: "smooth"})
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível finalizar o cadastro do investidor. Tente novamente."))
    }

  };

  const handleDadosBasicosEdit = () => setStep(InvestidorCreateSteps.DADOS_BASICOS)
  const handleSelfieDocumentoEdit = () => setStep(InvestidorCreateSteps.SELFIE_DOCUMENTO)
  const handleEnderecoEdit = () => setStep(InvestidorCreateSteps.ENDERECO)
  const handleComprovanteEnderecoEdit = () => setStep(InvestidorCreateSteps.COMPROVANTE_ENDERECO)
  const handleDadosBancariosEdit = () => setStep(InvestidorCreateSteps.DADOS_BANCARIOS)

  const handleBack = () => {
    setStep(step - 1)
    scrollTo({top: 0, behavior: "smooth"})
  }

  return (
    <PageTemplate
      metaTags={{
        title: "Gottaup.com | Cadastro do Investidor",
        description: "Preencher cadastro do investidor"
      }}
      menuDesktop
      headerMobile={{
        title: "Cadastro do Investidor",
        leftButtons: (
          <IconButton edge="start" color="inherit" component={Link} to={from}>
            <ArrowBackIcon/>
          </IconButton>
        )
      }}
      headerDesktop={{
        title: "Cadastro do Investidor",
        breadcrumbs: [
          {label: "Home", to: '/', icon: HomeIcon},
          {label: "Cadastro do Investidor"}
        ]
      }}
    >

      <Container component="section" style={{marginTop: 16}}>
        <Grid container spacing={2}>
          {isDesktop &&
          <Grid item xs={12} md={2}>
            <Stepper activeStep={step} orientation={isDesktop ? "vertical" : "horizontal"} alternativeLabel={!isDesktop}
                     nonLinear variant="outlined">
              <Step completed={step > 0}>
                <StepLabel>Dados Básicos</StepLabel>
              </Step>
              <Step completed={step > 1}>
                <StepLabel>Selfie</StepLabel>
              </Step>
              <Step completed={step > 2}>
                <StepLabel>Endereço</StepLabel>
              </Step>
              <Step completed={step > 3}>
                <StepLabel>Comprovante</StepLabel>
              </Step>
              <Step completed={step > 4}>
                <StepLabel>Dados Bancários</StepLabel>
              </Step>
              <Step completed={step > 5}>
                <StepLabel>Revisão</StepLabel>
              </Step>
            </Stepper>
          </Grid>

          }
          <Grid item xs={12} md={10}>
            {step == InvestidorCreateSteps.DADOS_BASICOS &&
            <InvestidorDadosBasicosForm
              investidor={state}
              submitText="Próximo"
              formMode={InvestidorDadosBasicosFormMode.CREATE}
              onSubmit={handleInvestidorDadosBasicosComplete}/>
            }

            {step == InvestidorCreateSteps.SELFIE_DOCUMENTO &&
            <InvestidorSelfieDocumentoForm
              documento={state.documentoSelfieAutentica || {}}
              onSubmit={handleSelfieDocumentoComplete}
              submitText="Próximo"
              formMode={InvestidorSelfieDocumentoFormMode.CREATE}
              onBack={handleBack}/>
            }

            {step == InvestidorCreateSteps.ENDERECO &&
            <EnderecoForm
              endereco={state.endereco || {}}
              onSubmit={handleEnderecoComplete}
              submitText="Próximo"
              formMode={EnderecoFormMode.CREATE}
              onBack={handleBack}/>
            }

            {step == InvestidorCreateSteps.COMPROVANTE_ENDERECO &&
            <InvestidorComprovanteEnderecoForm
              documento={state.documentoComprovanteResidencia || {}}
              onSubmit={handleComprovatenResidenciaComplete}
              submitText="Próximo"
              formMode={InvestidorComprovanteEnderecoFormMode.CREATE}
              onBack={handleBack}/>
            }

            {step == InvestidorCreateSteps.DADOS_BANCARIOS &&
            <DadosBancariosForm
              dadoBancario={state.dadoBancario || {}}
              onSubmit={handleDadosBancariosComplete}
              submitText="Próximo"
              formMode={DadosBancariosFormMode.CREATE}
              onBack={handleBack}/>
            }


            {step == InvestidorCreateSteps.REVISAO &&
            <InvestidorReviewCadastro
              investidor={state}
              onSubmit={handleRevisaoComplete}
              submitText="Finalizar"
              onBack={handleBack}
              onDadosBancariosEdit={handleDadosBancariosEdit}
              onDadosBasicosEdit={handleDadosBasicosEdit}
              onEnderecoEdit={handleEnderecoEdit}
              onComprovatenResidenciaEdit={handleComprovanteEnderecoEdit}
              onSelfieDocumentoEdit={handleSelfieDocumentoEdit}
            />
            }
            {step == InvestidorCreateSteps.SUCESSO &&
            <InvestidorSucessoCadastro/>
            }
          </Grid>
        </Grid>

      </Container>

    </PageTemplate>
  )
}
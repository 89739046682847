
export const Patterns = {
  cpf: /\d{3}\.\d{3}\.\d{3}-\d{2}/,
  cnpj: /\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}/,
  telefone: /^\([1-9]{2}\)(?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/,
  celular: /^\([1-9]{2}\)[0-9]{5}-[0-9]{4}$/,
  cep: /\d{5}-\d{3}/,
  email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  url: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
  conta: {
    banco: /\d{3}/,
    agencia: /\d{4}/,
    numero: /[0-9]{1,11}-[A-Z0-9]{1}/
  },
  porcentagem: /^[0-9]+,[0-9]{2}%$/,
  moeda: /^\d+(?:\.\d{3})*,\d{2}$/,
  data: /\d{2}\/\d{2}\/\d{4}/,
  permalink: /^[a-z0-9-]+$/
}


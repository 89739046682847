import { Box, Button, createStyles, Grid, makeStyles, Paper, Theme } from '@material-ui/core';
import { applyPatches, produceWithPatches } from 'immer';
import moment, { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../../../../browser';
import { TaxaJurosVO } from '../../../../../../models/TaxaJuros';
import { APIError } from '../../../../../../services/api/APIError';
import { generateErrorDialog } from '../../../../../../services/api/APIErrorUtils';
import { createTaxaJuros, patchTaxaJuros } from '../../../../../../stores/slices/entities/taxaJurosSlice';
import { ISO_MAX_DATE } from '../../../../../../utils/datetime';
import { reverseFormatNumber } from '../../../../../../utils/intl';
import { Patterns } from '../../../../../../utils/patterns';
import ButtonProgress from '../../../../../components/ButtonProgress';
import { useDialog } from '../../../../../components/dialog/PageServiceProvider';
import FormDatePickerInput from '../../../../../components/form/inputs/DataPicker/FormDatePickerInput';
import FormInput from '../../../../../components/FormInput';
import { PorcentagemMaskedInput } from '../../../../../components/MaskedInputs';

const useStyles = makeStyles((theme: Theme) => createStyles({
  listItem: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(3, 2),
  },
}), { name: 'TaxaJurosForm' });

export enum TaxaJurosFormMode {
  VIEW,
  CREATE,
  EDIT
}

interface TaxaJurosFormData {
  taxaJuros: {
    dtInicio: string;
    dtFim: string;
    pcTaxaCdi: string;
    pcTaxaPoupanca: string;
    pcTaxaSelic: string;
  };
}

interface TaxaJurosFormProps {
  taxaJuros?: TaxaJurosVO;
  formMode: TaxaJurosFormMode;
  dtInicioCreate?: Moment;
}

const processTaxaJurosFormData = (taxaJuros: Partial<TaxaJurosVO>, formData: TaxaJurosFormData, intl: IntlShape) => {
  const [nextState, patches] = produceWithPatches(taxaJuros, draft => {
    draft.dtInicio = moment(formData.taxaJuros.dtInicio, "L").format("YYYY-MM-DD");
    draft.dtFim = moment(formData.taxaJuros.dtFim, "L").format("YYYY-MM-DD");
    draft.pcTaxaCdi = reverseFormatNumber(intl, formData.taxaJuros.pcTaxaCdi, { style: 'percent', maximumFractionDigits: 4 });
    draft.pcTaxaPoupanca = reverseFormatNumber(intl, formData.taxaJuros.pcTaxaPoupanca, { style: 'percent', maximumFractionDigits: 4 });
    draft.pcTaxaSelic = reverseFormatNumber(intl, formData.taxaJuros.pcTaxaSelic, { style: 'percent', maximumFractionDigits: 4 });
  })
  const changes = applyPatches({}, patches) as Partial<TaxaJurosVO>;
  return { nextState, patches, changes }
}

const generateFormDefaultValues = (taxaJuros: Partial<TaxaJurosVO> = {}, dtInicioCreate: Moment | undefined, formMode: TaxaJurosFormMode, intl: IntlShape): TaxaJurosFormData => {
  if (formMode == TaxaJurosFormMode.CREATE) {
    return {
      taxaJuros: {
        dtInicio: dtInicioCreate?.format("L") || moment().format("L"),
        dtFim: moment(ISO_MAX_DATE).format("L"),
        pcTaxaCdi: '',
        pcTaxaPoupanca: '',
        pcTaxaSelic: ''
      }
    }
  } else if (formMode == TaxaJurosFormMode.EDIT) {
    return {
      taxaJuros: {
        dtInicio: moment(taxaJuros.dtInicio).format("L"),
        dtFim: '',
        pcTaxaCdi: intl.formatNumber(taxaJuros.pcTaxaCdi || 0, { style: "percent", minimumFractionDigits: 2 }),
        pcTaxaPoupanca: intl.formatNumber(taxaJuros.pcTaxaPoupanca || 0, { style: "percent", minimumFractionDigits: 2 }),
        pcTaxaSelic: intl.formatNumber(taxaJuros.pcTaxaSelic || 0, { style: "percent", minimumFractionDigits: 2 }),
      }
    }
  }
  else {
    return {
      taxaJuros: {
        dtInicio: moment(taxaJuros.dtInicio).format("L"),
        dtFim: moment(taxaJuros.dtFim).format("L"),
        pcTaxaCdi: intl.formatNumber(taxaJuros.pcTaxaCdi || 0, { style: "percent", minimumFractionDigits: 2 }),
        pcTaxaPoupanca: intl.formatNumber(taxaJuros.pcTaxaPoupanca || 0, { style: "percent", minimumFractionDigits: 2 }),
        pcTaxaSelic: intl.formatNumber(taxaJuros.pcTaxaSelic || 0, { style: "percent", minimumFractionDigits: 2 }),
      }
    }
  }
}

export const TaxaJurosForm = ({ taxaJuros, formMode, dtInicioCreate }: TaxaJurosFormProps) => {

  const classes = useStyles();

  const intl = useIntl();

  const dispatch = useDispatch<AppDispatch>()

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const dialog = useDialog();

  const { register, handleSubmit, errors, formState: { isSubmitting } } = useForm<TaxaJurosFormData>({
    mode: "onBlur",
    defaultValues: generateFormDefaultValues(taxaJuros, dtInicioCreate, formMode, intl)
  });

  const handleTaxaJurosFormSubmit = async (formData: TaxaJurosFormData) => {
    if (formMode == TaxaJurosFormMode.CREATE) {
      const confirmed = await dialog({
        title: "Confirma cadastro da Taxa de Juros?",
        description: "Ao confirmar as informações serão salvas. Se cancelar, nenhum dado será salvo no servidor.",
        confirmOption: "CONFIRMAR",
        cancelOption: "CANCELAR"
      })
      if (confirmed) {
        const { nextState } = processTaxaJurosFormData({}, formData, intl);
        const resultAction = await dispatch(createTaxaJuros({ taxaJuros: nextState }));
        if (createTaxaJuros.fulfilled.match(resultAction)) {
          enqueueSnackbar('Taxa de Juros cadastrada com sucesso!', {
            variant: 'success',
            action: <Button onClick={() => closeSnackbar()}>OK</Button>
          });
        } else {
          const error = resultAction.payload || resultAction.error as APIError;
          dialog(generateErrorDialog(error, "Não foi possível cadastrar a nova taxa de juros. Tente novamente."))
        }
      }
    }
    if (formMode == TaxaJurosFormMode.EDIT && taxaJuros) {
      const confirmed = await dialog({
        title: "Confirma alteração da Taxa de Juros?",
        description: "Ao confirmar as informações serão salvas. Se cancelar, nenhum dado será salvo no servidor.",
        confirmOption: "CONFIRMAR",
        cancelOption: "CANCELAR"
      })
      if (confirmed) {
        const { changes } = processTaxaJurosFormData(taxaJuros, formData, intl);
        const resultAction = await dispatch(patchTaxaJuros({ id: taxaJuros.coTaxaJuros, changes }))
        if (patchTaxaJuros.fulfilled.match(resultAction)) {
          enqueueSnackbar('Taxa de Juros alterada com sucesso!', {
            variant: 'success',
            action: <Button onClick={() => closeSnackbar()}>OK</Button>
          });
        } else {
          const error = resultAction.payload || resultAction.error as APIError;
          dialog(generateErrorDialog(error, "Não foi possível atualizar a taxa de juros. Tente novamente."))
        }
      }
    }
  }

  return (
    <form id="formTaxaJuros" noValidate autoComplete="off" onSubmit={handleSubmit(handleTaxaJurosFormSubmit)}>
      <Paper className={classes.listItem}>

        <Grid container spacing={2}>

          <Grid item xs={6} sm={4} md={2}>
            <FormInput
              required
              disabled
              name="taxaJuros.dtInicio"
              label="Data Início"
              inputRef={register}
            />
          </Grid>

          <Grid item xs={6} sm={4} md={2}>

            <FormDatePickerInput
              required
              disabled={formMode != TaxaJurosFormMode.EDIT}
              name="taxaJuros.dtFim"
              label="Data Fim"
              minDate={taxaJuros?.dtInicio}
              maxDate={formMode == TaxaJurosFormMode.EDIT ? moment() : undefined}
              error={errors.taxaJuros?.dtFim?.message?.toString()}
              format="DD/MM/YYYY"
              inputRef={register({
                required: 'Informe a Data Fim',
                pattern: { value: Patterns.data, message: 'Formato DD/MM/AAAA' },
                validate: {
                  validDate: value => moment(value, "L").isValid() || "Data Inválida!",
                  minDate: value => moment(value, "L").isSameOrAfter(taxaJuros?.dtInicio) || "Data não pode ser inferior a data de início",
                  maxDate: value => (formMode == TaxaJurosFormMode.EDIT ? moment(value, "L").isSameOrBefore(moment()) : true) || "Data não pode ser superior a hoje"
                }
              })}
            />
          </Grid>

          <Grid item xs={6} sm={4} md={2}>
            <FormInput
              disabled={formMode != TaxaJurosFormMode.CREATE}
              required
              name="taxaJuros.pcTaxaSelic"
              label="Taxa SELIC"
              placeholder="0,00%"
              maxLength={7}
              error={errors.taxaJuros?.pcTaxaSelic?.message?.toString()}
              inputComponent={PorcentagemMaskedInput as any}
              inputRef={register({
                required: 'Informe a Taxa SELIC',
                pattern: { value: Patterns.porcentagem, message: "Formato ??9,99%" }
              })}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <FormInput
              disabled={formMode != TaxaJurosFormMode.CREATE}
              required
              name="taxaJuros.pcTaxaCdi"
              label="Taxa CDI"
              placeholder="0,00%"
              maxLength={7}
              error={errors.taxaJuros?.pcTaxaCdi?.message?.toString()}
              inputComponent={PorcentagemMaskedInput as any}
              inputRef={register({
                required: 'Informe a Taxa CDI',
                pattern: { value: Patterns.porcentagem, message: "Formato ??9,99%" }
              })}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <FormInput
              disabled={formMode != TaxaJurosFormMode.CREATE}
              required
              name="taxaJuros.pcTaxaPoupanca"
              label="Taxa Poupança"
              placeholder="0,00%"
              maxLength={7}
              error={errors.taxaJuros?.pcTaxaPoupanca?.message?.toString()}
              inputComponent={PorcentagemMaskedInput as any}
              inputRef={register({
                required: 'Informe a Taxa Poupança',
                pattern: { value: Patterns.porcentagem, message: "Formato ??9,99%" }
              })}
            />
          </Grid>
          {formMode != TaxaJurosFormMode.VIEW &&
            <Grid item xs={6} sm={4} md={2}>
              <Box display="flex" justifyContent="flex-end" alignItems="flex-end" mt={1} mb={1}>
                <ButtonProgress
                  id="btnSubmit"
                  loading={isSubmitting}
                  disabled={Object.values(errors).length > 0}
                  variant="outlined"
                  color="secondary"
                  type="submit">
                  Salvar
                </ButtonProgress>
              </Box>
            </Grid>
          }
        </Grid>
      </Paper>
    </form>
  )
}

import { Container } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import PageTemplate from '../../../../layout/PageTemplate';
import InformaCodigoEmailStep from './components/InformaCodigoEmailStep';
import SucessoValidacaoEmailStep from './components/SucessoValidacaoEmailStep';
import { useSelector } from 'react-redux';
import { selectContaUsuario } from '../../../../../stores/slices/userSlice';

enum ValidacaoEmailSteps {
    INFORMA_CODIGO_EMAIL,
    SUCESSO_VALIDACAO_EMAIL
}

export const ValidacaoEmailPage = () => {

    const [step, setStep] = useState(ValidacaoEmailSteps.INFORMA_CODIGO_EMAIL);
    
    const contaUsuario = useSelector(selectContaUsuario);

    const informaCodigoEmailComplete = () => {
        setStep(ValidacaoEmailSteps.SUCESSO_VALIDACAO_EMAIL);
    }
 
    if (!contaUsuario) {
        // URL acessada diretamente, então redirect para login 
        return <Redirect to={"/login"} />
    } else {
        return (
            <PageTemplate
                metaTags={{
                    title: "Gottaup.com | Validação de Email",
                    description: "Efetue aqui a validação de seu email"
                }}
                menuDesktop
                menuMobile
                headerDesktop={{
                    title: "Validação de Email",
                    breadcrumbs: [
                        { label: "Home", to: '/', icon: HomeIcon },
                        { label: "Login", to: '/login', icon: PersonIcon },
                        { label: "Validação de Email" }
                    ]

                }}
            >
                <Container component="section" maxWidth="sm" style={{ marginTop: 24 }}>

                    {step == ValidacaoEmailSteps.INFORMA_CODIGO_EMAIL &&
                        <InformaCodigoEmailStep contaUsuario={contaUsuario} onComplete={informaCodigoEmailComplete} />
                    }
                    {step == ValidacaoEmailSteps.SUCESSO_VALIDACAO_EMAIL &&
                        <SucessoValidacaoEmailStep />
                    }
                  
                </Container>
            </PageTemplate >
        )
    }


}
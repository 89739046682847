import { Container, Typography } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import React from 'react';
import PageTemplate from '../../../layout/PageTemplate';

export const CaptarPage = () => {

  return (
    <PageTemplate
      metaTags={{
        title: "Gottaup.com | Captar Recursos",
        description: "Capte recursos para sua empresa"
      }}
      menuDesktop
      menuMobile
      headerDesktop={{
        title: "Captar Recursos",
        breadcrumbs: [
          { label: "Home", to: '/', icon: HomeIcon },
          { label: "Captar" }
        ]
      }}
    >

      <Container component="section" style={{ marginTop: 24 }}>
        <Typography variant="h6" color="textPrimary">Capte recursos para projetos da sua empresa</Typography>
      </Container>



    </PageTemplate>
  )
}
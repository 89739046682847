
export interface DadoBancarioVO {
  nuDadoBancario: number|undefined;
  coBanco: number|undefined;
  nuAgencia: string|undefined;
  nuConta: string|undefined;
}


export default class DadoBancario {

    public nuDadoBancario: number|undefined;
    public coBanco: number|undefined;
    public nuAgencia: string|undefined;
    public nuConta: string|undefined;

    constructor(obj: object|null = null) {
        if (obj) Object.assign(this, obj);
    }


    get nuContaFormatado() {
        return this.nuConta? this.nuConta.replace(/(\d{1,11})([A-Z0-9]{1})$/g, '$1-$2'):'';
    }

    set nuContaFormatado(value) {
        const match = value.match(/[A-Z0-9]+/g);
        this.nuConta = match ? match.join('') : undefined;
    }

    get coBancoFormatado() {
        return this.coBanco? String(this.coBanco).padStart(3,'0'): '';
    }

    set coBancoFormatado(value: string) {
        this.coBanco = value ? Number(value) : undefined;
    }

}


import { Card, CardActionArea, CardContent, CardHeader, CardMedia, Container, createStyles, Fab, Grid, LinearProgress, makeStyles, Theme, Typography, withStyles } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { lighten } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import TimerSandIcon from 'mdi-material-ui/TimerSand';
import moment from 'moment';
import React, { Fragment, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AppDispatch } from '../../../../../browser';
import { TipoRentabilidadeProjetada } from '../../../../models/enums/TipoRentabilidadeProjetada';
import Projeto from '../../../../models/Projeto';
import { APIUtils } from '../../../../services/api/APIUtils';
import { fetchCaptacoes, trackFetchCaptacoes } from '../../../../stores/slices/entities/captacoesSlice';
import ErrorEmptyState from '../../../components/empty/ErrorEmptyState';
import EmptyState from '../../../components/EmptyState';
import Loading from '../../../components/Loading';
import PageTemplate from '../../../layout/PageTemplate';
import { PageSubtitle } from '../../../layout/PageSubtitle';

const useStyles = makeStyles((theme: Theme) => createStyles({
  card: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 345
    }
  },
  labelText: {
    color: 'rgba(0,0,0,.5)'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  cardHeader: {
    height: theme.spacing(10)
  },
  cardInfo: {
    display: 'flex',
    color: '#6c757d',
  },
  footer: {
    backgroundColor: 'rgba(0,0,0,.06  );',
    borderTop: '1px solid rgba(0,0,0,.125);',
    padding: '1em'
  },
  footerInfo: {
    display: 'flex',
    color: '#6c757d',
    marginRight: '1em',
    marginLeft: '1em'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  logo: {
    maxHeight: 40,
    width: 40
  },
  fab: {
    marginTop: '-1.5rem',
    marginRight: '1rem'
  },
}), { name: 'CaptacoesAbertas' });

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: lighten('#EA5911', 0.5),
  },
  bar: {
    backgroundColor: '#EA5911',
  },
})(LinearProgress);


interface CaptacoesAbertasPageState {
  captacoesAbertas: Projeto[];
  captacoesEncerradas: Projeto[];
  today: Date;
}


export const CaptacoesAbertasPage = () => {

  const classes = useStyles();

  const location = useLocation();

  const intl = useIntl();

  const dispatch = useDispatch<AppDispatch>();

  const requestState = useSelector(trackFetchCaptacoes);

  const captacoesAbertas = requestState.data?.filter(prj => moment(prj.dtFimCaptacao).isSameOrAfter(moment()));
  const captacoesEncerradas = requestState.data?.filter(prj => moment(prj.dtFimCaptacao).isBefore(moment())).sort((a, b) => moment(b.dtFimCaptacao).valueOf() - moment(a.dtFimCaptacao).valueOf());

  useEffect(() => {
    const promise = dispatch(fetchCaptacoes());
    return () => promise.abort();
  }, [dispatch]);


  return (

    <PageTemplate
      metaTags={{
        title: "Gottaup.com | Captações abertas",
        description: "Consulte aqui todas nossas captações abertas"
      }}
      loading={{ isLoading: requestState.pending }}
      menuDesktop
      menuMobile
      headerDesktop={{
        title: "Captações Abertas",
        breadcrumbs: [
          { label: "Home", to: '/', icon: HomeIcon },
          { label: "Investir" }
        ]
      }}
    >

      <Container component="section" style={{ marginTop: 24 }}>

        {requestState.pending &&
          <Loading text="Buscando captações..." />
        }

        {requestState.error &&
          <ErrorEmptyState
            error={requestState.error}
            defaultDescription="Não foi possível buscar captações"
            onTryAgainClick={() => dispatch(fetchCaptacoes())}
          />
        }

        {requestState.data && captacoesAbertas && captacoesAbertas.length === 0 &&
          <EmptyState
            icon={<TimerSandIcon style={{ fontSize: '58px' }} color="disabled" />}
            title="Não há nenhuma captação em aberto no momento."
            subtitle="Não se preocupe, em breve teremos novas oportunidades"
          />
        }

        {requestState.data && captacoesAbertas && captacoesAbertas.length > 0 &&

          <Grid container justify="center" direction="row" spacing={3}>

            {captacoesAbertas.map((projeto, index) => (
              <Grid item key={index} xs={12} sm={6} md={4}>
                <Card>
                  <CardHeader className={classes.cardHeader}
                    avatar={
                      <img className={classes.logo} src={APIUtils.generateImageURL(projeto.empresa.imagemLogo)} alt={"logo da " + projeto.empresa.noFantasia} />
                    }
                    title={projeto.empresa.noFantasia}
                    subheader={projeto.noProjeto}
                  />
                  <CardActionArea component={Link} to={`/investir/${projeto.noPermalink}`}>
                    <CardMedia
                      className={classes.media}
                      image={APIUtils.generateImageURL(projeto.imagemCapa)}
                      title={`Imagem de capa do projeto ${projeto.noProjeto}`}
                    />
                  </CardActionArea>
                  <Grid container justify="flex-end">
                    <Grid item >
                      <Fab color="primary" aria-label="detalhes do projeto" className={classes.fab} variant="extended" component={Link} to={`/investir/${projeto.noPermalink}`}>
                        <SearchIcon />
                          Detalhes
                    </Fab>
                    </Grid>
                  </Grid>

                  <CardContent style={{ marginTop: '-.5em', marginBottom: '1em' }}>
                    <Grid>
                      <Grid item className={classes.cardInfo}>
                        <Grid container justify="space-between">
                          <Typography variant="subtitle2" color="inherit" component="p" className={classes.labelText}>Rentabilidade:</Typography>
                          <Typography variant="subtitle2" color="inherit" component="p">{projeto.tipoRentabilidadeProjetada == TipoRentabilidadeProjetada.Fixa ? `${intl.formatNumber(projeto.pcRentabilidadeProjetadaFixaAnual || 0, { style: "percent", minimumFractionDigits: 2 })} a.a.` : `${intl.formatNumber(projeto.pcRentabilidadeProjetadaMinimaAnual || 0, { style: "percent", minimumFractionDigits: 2 })} a ${intl.formatNumber(projeto.pcRentabilidadeProjetadaMaximaAnual || 0, { style: "percent", minimumFractionDigits: 2 })} a.a.`}</Typography>
                        </Grid>
                      </Grid>
                      <Grid item className={classes.cardInfo}>
                        <Grid container justify="space-between">
                          <Typography variant="subtitle2" color="inherit" component="p" className={classes.labelText}>Prazo:</Typography>
                          <Typography variant="subtitle2" color="inherit" component="p">{projeto.qtPrazoMesesTitulo} meses</Typography>
                        </Grid>
                      </Grid>


                      <Grid item className={classes.cardInfo}>
                        <Grid container justify="space-between">
                          <Typography variant="subtitle2" color="inherit" component="p" className={classes.labelText}>Investimento Mínimo:</Typography>
                          <Typography variant="subtitle2" color="inherit" component="p">{intl.formatNumber(projeto.vrCotaEmitida, { style: "currency", currency: "BRL" })}</Typography>
                        </Grid>
                      </Grid>
                      <Grid item className={classes.cardInfo}>
                        <Grid container direction="column">
                          <Grid container justify="space-between">
                            <Typography variant="subtitle2" color="inherit" component="p" className={classes.labelText}>Reservas:</Typography>
                            <Typography variant="subtitle2" color="inherit" component="p">{intl.formatNumber(projeto.captacaoProjeto.vrTotalReservado / projeto.vrAlvoCaptacao, { style: "percent", minimumFractionDigits: 2 })}</Typography>
                          </Grid>
                          <BorderLinearProgress
                            variant="determinate"
                            color="primary"
                            value={(projeto.captacaoProjeto.vrTotalReservado / projeto.vrAlvoCaptacao) < 1 ? (projeto.captacaoProjeto.vrTotalReservado / projeto.vrAlvoCaptacao) * 100 : 100}
                          />
                          <Grid container justify="flex-end">

                          </Grid>

                        </Grid>
                      </Grid>

                    </Grid>

                  </CardContent>


                  <Grid container justify="center" alignItems="center" direction="row" className={classes.footer}>
                    <Grid item className={classes.footerInfo}>
                      <Typography variant="body1" color="inherit" component="p">
                        Encerra {moment(projeto.dtFimCaptacao).fromNow()}
                      </Typography>
                    </Grid>

                  </Grid>

                </Card>
              </Grid>
            ))}
          </Grid>
        }

        {requestState.data && captacoesEncerradas && captacoesEncerradas.length > 0 &&
          <Fragment>
            <PageSubtitle>Captações Encerradas</PageSubtitle>
            <Grid container justify="flex-start" direction="row" spacing={3} >

              {captacoesEncerradas.map((projeto, index) => (
                <Grid item key={index} xs={12} sm={6} md={4}>
                  <Card>
                    <CardHeader className={classes.cardHeader}
                      avatar={
                        <img className={classes.logo} src={APIUtils.generateImageURL(projeto.empresa.imagemLogo)} alt={"logo da " + projeto.empresa.noFantasia} />
                      }
                      title={projeto.empresa && projeto.empresa.noFantasia}
                      subheader={projeto.noProjeto}
                    />
                    <CardActionArea component={Link} to={`/investir/${projeto.noPermalink}`}>
                      <CardMedia
                        className={classes.media}
                        image={APIUtils.generateImageURL(projeto.imagemCapa)}
                        title={`Imagem de capa do projeto ${projeto.noProjeto}`}
                      />
                    </CardActionArea>
                    <Grid container justify="flex-end">


                      <Grid item >
                        <Fab color="primary" aria-label="detalhes do projeto" className={classes.fab} variant="extended" component={Link} to={{ pathname: `/investir/${projeto.noPermalink}`, state: { from: location.pathname } }}>
                          <SearchIcon />
                            Detalhes
                          </Fab>
                      </Grid>
                    </Grid>

                    <CardContent style={{ marginTop: '-.5em', marginBottom: '1em' }}>
                      <Grid>
                        <Grid item className={classes.cardInfo}>
                          <Grid container justify="space-between">
                            <Typography variant="subtitle2" color="inherit" component="p" className={classes.labelText}>Rentabilidade:</Typography>
                            <Typography variant="subtitle2" color="inherit" component="p">{projeto.tipoRentabilidadeProjetada == TipoRentabilidadeProjetada.Fixa ? `${intl.formatNumber(projeto.pcRentabilidadeProjetadaFixaAnual || 0, { style: "percent", minimumFractionDigits: 2 })} a.a.` : `${intl.formatNumber(projeto.pcRentabilidadeProjetadaMinimaAnual || 0, { style: "percent", minimumFractionDigits: 2 })} a ${intl.formatNumber(projeto.pcRentabilidadeProjetadaMaximaAnual || 0, { style: "percent", minimumFractionDigits: 2 })} a.a.`}</Typography>
                          </Grid>
                        </Grid>
                        <Grid item className={classes.cardInfo}>
                          <Grid container justify="space-between">
                            <Typography variant="subtitle2" color="inherit" component="p" className={classes.labelText}>Prazo:</Typography>
                            <Typography variant="subtitle2" color="inherit" component="p">{projeto.qtPrazoMesesTitulo} meses</Typography>
                          </Grid>
                        </Grid>


                        <Grid item className={classes.cardInfo}>
                          <Grid container justify="space-between">
                            <Typography variant="subtitle2" color="inherit" component="p" className={classes.labelText}>Investimento Mínimo:</Typography>
                            <Typography variant="subtitle2" color="inherit" component="p">{intl.formatNumber(projeto.vrCotaEmitida, { style: "currency", currency: "BRL" })}</Typography>
                          </Grid>
                        </Grid>
                        <Grid item className={classes.cardInfo}>
                          <Grid container direction="column">
                            <Grid container justify="space-between">
                              <Typography variant="subtitle2" color="inherit" component="p" className={classes.labelText}>Reservas:</Typography>
                              <Typography variant="subtitle2" color="inherit" component="p">{intl.formatNumber(projeto.captacaoProjeto.vrTotalReservado / projeto.vrAlvoCaptacao, { style: "percent", minimumFractionDigits: 2 })}</Typography>
                            </Grid>
                            <BorderLinearProgress
                              variant="determinate"
                              color="primary"
                              value={(projeto.captacaoProjeto.vrTotalReservado / projeto.vrAlvoCaptacao) < 1 ? (projeto.captacaoProjeto.vrTotalReservado / projeto.vrAlvoCaptacao) * 100 : 100}
                            />
                            <Grid container justify="flex-end">

                            </Grid>

                          </Grid>
                        </Grid>

                      </Grid>

                    </CardContent>


                    <Grid container justify="center" alignItems="center" direction="row" className={classes.footer}>
                      <Grid item className={classes.footerInfo}>
                        <Typography variant="body1" color="inherit" component="p">
                          Encerrada em {moment(projeto.dtFimCaptacao).format("L")}
                        </Typography>
                      </Grid>

                    </Grid>

                  </Card>
                </Grid>
              ))}
            </Grid>

          </Fragment>
        }

      </Container>

    </PageTemplate>
  )
}

export const CaptacoesAbertasLoadDataSSR = () => fetchCaptacoes();
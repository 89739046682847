import { Container, Grid, IconButton, Step, StepLabel, Stepper, useMediaQuery, useTheme } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HomeIcon from '@material-ui/icons/Home';
import WorkIcon from '@material-ui/icons/Work';
import { DeepPartial } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { AppDispatch } from '../../../../../../browser';
import { DocumentoVO } from '../../../../../models/Documento';
import { EnderecoVO } from '../../../../../models/Endereco';
import { ImagemVO } from '../../../../../models/Imagem';
import { ProjetoVO } from '../../../../../models/Projeto';
import { APIError } from '../../../../../services/api/APIError';
import { generateErrorDialog } from '../../../../../services/api/APIErrorUtils';
import { createProjeto } from '../../../../../stores/slices/entities/projetosSlice';
import { useDialog } from '../../../../components/dialog/PageServiceProvider';
import { EnderecoForm, EnderecoFormMode } from '../../../../components/form/shared/EnderecoForm';
import PageTemplate from '../../../../layout/PageTemplate';
import { ProjetoCreateCaptacaoForm } from './components/ProjetoCreateCaptacaoForm';
import { ProjetoDadosBasicosForm, ProjetoDadosBasicosFormMode } from './components/ProjetoDadosBasicosForm';
import { ProjetoDocumentoContratoForm, ProjetoDocumentoContratoFormMode } from './components/ProjetoDocumentoContratoForm';
import { ProjetoDocumentoProspectoForm, ProjetoDocumentoProspectoFormMode } from './components/ProjetoDocumentoProspectoForm';
import { ProjetoGaleriaFotosForm, ProjetoGaleriaFotosFormMode } from './components/ProjetoGaleriaFotosForm';
import { ProjetoImagemCapaForm, ProjetoImagemCapaFormMode } from './components/ProjetoImagemCapaForm';
import { ProjetoReviewCadastro, ProjetoReviewCadastroFormMode } from './components/ProjetoReviewCadastro';
import { ProjetoSucessoCadastro } from './components/ProjetoSucessoCadastro';

enum ProjetoCreateSteps {
  DADOS_BASICOS,
  ENDERECO,
  CAPTACAO,
  IMAGEM_CAPA,
  GALERIA_FOTOS,
  PROSPECTO,
  CONTRATO,
  REVISAO,
  SUCESSO
}

export const ProjetoCreatePage = () => {

  const history = useHistory();
  const { from } = history.location.state as any || { from: { pathname: "/admin/projetos" } };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const dispatch = useDispatch<AppDispatch>()

  const dialog = useDialog();

  const [step, setStep] = useState(ProjetoCreateSteps.DADOS_BASICOS);

  const [state, setState] = useState<DeepPartial<ProjetoVO>>({});

  console.log(state);

  const handleProjetoDadosBasicosComplete = (nextState: DeepPartial<ProjetoVO>) => {
    setState({ ...state, ...nextState });
    setStep(ProjetoCreateSteps.ENDERECO);
    scrollTo({ top: 0, behavior: "smooth" })
  };

  const handleEnderecoComplete = (nextState: DeepPartial<EnderecoVO>) => {
    setState({ ...state, endereco: { ...nextState } });
    setStep(ProjetoCreateSteps.CAPTACAO);
    scrollTo({ top: 0, behavior: "smooth" })
  };

  const handleProjetoCaptacaoComplete = (nextState: DeepPartial<ProjetoVO>) => {
    setState({ ...state, ...nextState });
    setStep(ProjetoCreateSteps.IMAGEM_CAPA);
    scrollTo({ top: 0, behavior: "smooth" })
  };

  const handleImagemCapaComplete = (nextState: DeepPartial<ImagemVO>) => {
    setState({ ...state, imagemCapa: { ...nextState } });
    setStep(ProjetoCreateSteps.GALERIA_FOTOS);
    scrollTo({ top: 0, behavior: "smooth" })
  };

  const handleGaleriaFotosComplete = (imagensToInsert: DeepPartial<ImagemVO>[], imagensToDelete: DeepPartial<ImagemVO>[], galeriaFotos: DeepPartial<ImagemVO>[]) => {
    setState({ ...state, galeriaFotos: [...galeriaFotos] });
    setStep(ProjetoCreateSteps.PROSPECTO);
    scrollTo({ top: 0, behavior: "smooth" })
  }

  const handleDocumentoProspectoComplete = (nextState: DeepPartial<DocumentoVO>) => {
    setState({ ...state, documentoProspecto: { ...nextState } });
    setStep(ProjetoCreateSteps.CONTRATO);
    scrollTo({ top: 0, behavior: "smooth" })
  };

  const handleDocumentoContratoComplete = (nextState: DeepPartial<DocumentoVO>) => {
    setState({ ...state, documentoModeloContrato: { ...nextState } });
    setStep(ProjetoCreateSteps.REVISAO);
    scrollTo({ top: 0, behavior: "smooth" })
  };


  const handleRevisaoComplete = async () => {
    const resultAction = await dispatch(createProjeto({ projeto: state }));
    if (createProjeto.fulfilled.match(resultAction)) {
      setStep(ProjetoCreateSteps.SUCESSO);
      scrollTo({ top: 0, behavior: "smooth" })
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível concluir o cadastro do projeto. Tente novamente."))
    }
  };

  const handleDadosBasicosEdit = () => setStep(ProjetoCreateSteps.DADOS_BASICOS)
  const handleEnderecoEdit = () => setStep(ProjetoCreateSteps.ENDERECO)
  const handleCaptacaoEdit = () => setStep(ProjetoCreateSteps.CAPTACAO)
  const handleImagemCapaEdit = () => setStep(ProjetoCreateSteps.IMAGEM_CAPA)
  const handleGaleriaFotosEdit = () => setStep(ProjetoCreateSteps.GALERIA_FOTOS)
  const handleProspectoEdit = () => setStep(ProjetoCreateSteps.PROSPECTO)
  const handleContratoEdit = () => setStep(ProjetoCreateSteps.CONTRATO)

  const handleBack = () => {
    setStep(step => step - 1)
    scrollTo({ top: 0, behavior: "smooth" })
  }

  const handleNew = () => {
    setState({})
    setStep(ProjetoCreateSteps.DADOS_BASICOS)
    scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <PageTemplate
      metaTags={{
        title: "Gottaup.com | Cadastro de Projeto",
        description: "Efetue aqui o seu cadastro de novos projetos"
      }}
      menuDesktop
      headerMobile={{
        title: "Cadastro de Projeto",
        leftButtons: (
          <IconButton edge="start" color="inherit" component={Link} to={from}>
            <ArrowBackIcon />
          </IconButton>
        )
      }}
      headerDesktop={{
        title: "Cadastro de Projeto",
        breadcrumbs: [
          { label: "Home", to: '/', icon: HomeIcon },
          { label: "Projetos", to: '/admin/projetos', icon: WorkIcon },
          { label: "Cadastrar" }
        ]
      }}
    >

      <Container component="section" style={{ marginTop: 16 }}>
        <Grid container spacing={2}>
          {isDesktop &&
            <Grid item xs={12} md={2}>
              <Stepper activeStep={step} orientation="vertical" variant="outlined"  >
                <Step completed={step > 0}>
                  <StepLabel>Dados Básicos</StepLabel>
                </Step>
                <Step completed={step > 1}>
                  <StepLabel>Endereço</StepLabel>
                </Step>
                <Step completed={step > 2}>
                  <StepLabel>Captação</StepLabel>
                </Step>
                <Step completed={step > 3}>
                  <StepLabel>Imagem Capa</StepLabel>
                </Step>
                <Step completed={step > 4}>
                  <StepLabel>Galeria Fotos</StepLabel>
                </Step>
                <Step completed={step > 5}>
                  <StepLabel>Prospecto</StepLabel>
                </Step>
                <Step completed={step > 6}>
                  <StepLabel>Contrato</StepLabel>
                </Step>
              </Stepper>

            </Grid>
          }
          <Grid item xs={12} md={10}>
            {step == ProjetoCreateSteps.DADOS_BASICOS &&
              <ProjetoDadosBasicosForm
                projeto={state}
                submitText="Próximo"
                formMode={ProjetoDadosBasicosFormMode.CREATE}
                onSubmit={handleProjetoDadosBasicosComplete} />

            }
            {step == ProjetoCreateSteps.ENDERECO &&
              <EnderecoForm
                endereco={state.endereco || {}}
                onSubmit={handleEnderecoComplete}
                submitText="Próximo"
                formMode={EnderecoFormMode.CREATE}
                onBack={handleBack} />
            }
            {step == ProjetoCreateSteps.CAPTACAO &&
              <ProjetoCreateCaptacaoForm
                projeto={state}
                submitText="Próximo"
                onSubmit={handleProjetoCaptacaoComplete}
                onBack={handleBack} />
            }


            {step == ProjetoCreateSteps.IMAGEM_CAPA &&
              <ProjetoImagemCapaForm
                imagem={state.imagemCapa || {}}
                onSubmit={handleImagemCapaComplete}
                submitText="Próximo"
                formMode={ProjetoImagemCapaFormMode.CREATE}
                onBack={handleBack} />
            }

            {step == ProjetoCreateSteps.GALERIA_FOTOS &&
              <ProjetoGaleriaFotosForm
                galeriaFotos={(state.galeriaFotos || []) as DeepPartial<ImagemVO>[]}
                onSubmit={handleGaleriaFotosComplete}
                submitText="Próximo"
                formMode={ProjetoGaleriaFotosFormMode.CREATE}
                onBack={handleBack} />
            }

            {step == ProjetoCreateSteps.PROSPECTO &&
              <ProjetoDocumentoProspectoForm
                documento={state.documentoProspecto || {}}
                onSubmit={handleDocumentoProspectoComplete}
                submitText="Próximo"
                formMode={ProjetoDocumentoProspectoFormMode.CREATE}
                onBack={handleBack} />
            }

            {step == ProjetoCreateSteps.CONTRATO &&
              <ProjetoDocumentoContratoForm
                documento={state.documentoModeloContrato || {}}
                onSubmit={handleDocumentoContratoComplete}
                submitText="Próximo"
                formMode={ProjetoDocumentoContratoFormMode.CREATE}
                onBack={handleBack} />
            }

            {step == ProjetoCreateSteps.REVISAO &&
              <ProjetoReviewCadastro
                projeto={state}
                formMode={ProjetoReviewCadastroFormMode.CREATE}
                onSubmit={handleRevisaoComplete}
                submitText="Finalizar"
                onBack={handleBack}
                onDadosBasicosEdit={handleDadosBasicosEdit}
                onEnderecoEdit={handleEnderecoEdit}
                onCaptacaoEdit={handleCaptacaoEdit}
                onImagemCapaEdit={handleImagemCapaEdit}
                onGaleriaFotosEdit={handleGaleriaFotosEdit}
                onProspectoEdit={handleProspectoEdit}
                onContratoEdit={handleContratoEdit}
              />
            }

            {step == ProjetoCreateSteps.SUCESSO &&
              <ProjetoSucessoCadastro projeto={state} onNew={handleNew} />
            }

          </Grid>
        </Grid>

      </Container>

    </PageTemplate >
  )
}
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import SvgSyncFiles from "../../../../../images/undraw/sync_files.svg"
import BriefcasePlusIcon from 'mdi-material-ui/BriefcasePlus';
import DomainPlusIcon from 'mdi-material-ui/DomainPlus';
import React from 'react';
import { DeepPartial } from 'redux';
import { EmpresaVO } from '../../../../../../models/Empresa';
import { ButtonWheresNext } from '../../../../../components/buttons/ButtonWheresNext';

interface EmpresaSucessoCadastroProps {
  empresa: DeepPartial<EmpresaVO>;
  onNew: () => void;
}

export const EmpresaSucessoCadastro = ({ onNew }: EmpresaSucessoCadastroProps) => {

  return (
    <Paper variant="outlined" square >
      <Box p={2}>

        <Box display="flex" justifyContent="center" mt={2}>
          <img src={SvgSyncFiles} height={150} />
        </Box>

        <Box mt={1} >
          <Typography variant="h6" paragraph align="center">Sua empresa foi cadastrada com sucesso!</Typography>
        </Box>

        <Box mt={4} >
          <Typography variant="h6" paragraph align="center">O que deseja fazer agora?</Typography>
        </Box>


        <Grid container justify="flex-end" spacing={2}>
          <Grid item xs={12} sm={4}>
            <ButtonWheresNext label="Cadastrar outra Empresa" icon={DomainPlusIcon} onClick={onNew} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ButtonWheresNext label="Cadastrar um Projeto" icon={BriefcasePlusIcon} linkTo="/admin/projetos/create" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ButtonWheresNext label="Pesquisar empresas" icon={SearchIcon} linkTo="/admin/empresas" />
          </Grid>
        </Grid>

      </Box>
    </Paper >
  )
}

import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Typography, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import WhatsappIcon from 'mdi-material-ui/Whatsapp'
import EmailOutlineIcon from 'mdi-material-ui/EmailOutline'
import FacebookIcon from 'mdi-material-ui/Facebook'
import LinkedinIcon from 'mdi-material-ui/Linkedin'
import TwitterIcon from 'mdi-material-ui/Twitter'
import GottaupLogo from '../images/gottaup-logo.png';
import CVMLogo from '../images/home/logo_cvm.png'


const useStyles = makeStyles((theme: Theme) => createStyles({
  footer: {
    backgroundColor: '#fff5f8',
    display: 'flex',
    flexDirection: 'column'
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',

  },
  iconsWrapper: {
    height: 120,
  },
  icons: {
    display: 'flex',

  },
  list: {
    margin: 0,
    listStyle: 'none',
    paddingLeft: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  },
  footerLink: {
    color: theme.palette.primary.dark,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  footerText: {
    color: 'rgba(0,0,0,.4)',
    textAlign: 'justify'
  },
  markedH6Left: {
    height: 2,
    width: 28,
    display: 'block',
    marginTop: theme.spacing(0.5),
    background: 'currentColor',
  },
  copyright: {
    color: '#262726',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'justify'
  },
  contato: {
    display: 'flex',
    flexDirection: 'column'
  },
  cvmLogo: {
    marginTop: theme.spacing(2),
    width: 120
  }


}), { name: 'PageFooter' });


export default function PageFooter() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={2} md={2}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"

            >
              <Grid item>
                <img alt="logo do Gottaup" width="120" src={GottaupLogo} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={1} md={4}></Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="h6" gutterBottom>
              Sessões
              <span className={classes.markedH6Left} />
            </Typography>
            <ul className={classes.list}>
              <li className={classes.listItem}>
                <Link to="/investir" className={classes.footerLink}>Investir</Link>
              </li>
              <li className={classes.listItem}>
                <Link to="/captar" className={classes.footerLink}>Captar</Link>
              </li>
              <li className={classes.listItem}>
                <Link to="#" className={classes.footerLink}>Saiba Mais</Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="h6" gutterBottom>
              Conheça
              <span className={classes.markedH6Left} />
            </Typography>
            <ul className={classes.list}>
              <li className={classes.listItem}>
                <Link to="#" className={classes.footerLink}>Sobre o Gottaup</Link>
              </li>
              <li className={classes.listItem}>
                <Link to="#" className={classes.footerLink}>Perguntas Frequentes</Link>
              </li>
              <li className={classes.listItem}>
                <Link to="#" className={classes.footerLink}>Contato</Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="h6" gutterBottom>
              Legal
              <span className={classes.markedH6Left} />
            </Typography>
            <ul className={classes.list}>
              <li className={classes.listItem}>
                <Link to="#" className={classes.footerLink}>Termos e Políticas</Link>
              </li>
              <li className={classes.listItem}>
                <Link to="#" className={classes.footerLink}>Aviso de Risco</Link>
              </li>
              <li className={classes.listItem}>
                <Link to="#" className={classes.footerLink}>Relatórios CVM</Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} className={classes.footerText}>
            <Typography variant="caption" >
              <p>
                Qualquer investimento em pequenas empresas envolve riscos financeiros e desafios significativos.
                Estes investimentos devem compor apenas uma pequena parte de uma carteira diversificada de investimentos.
                Você correrá o risco de perder parte ou todo o dinheiro investido em empresas através da plataforma Gottaup.
                Não há qualquer garantia de retorno do investimento efetuado.
                As rentabilidades informadas são apenas referências e podem não se concretizar.
                Somente invista em negócios publicados na plataforma Gottaup se você possui conhecimento suficiente para entender esses riscos e para tomar suas próprias decisões de investimento.
                Veja o nosso <Link to="#" className={classes.footerLink}>Aviso de Risco</Link> antes de tomar uma decisão de investimento.
              </p>
              <p>
                As sociedades empresárias de pequeno porte e as ofertas apresentadas nesta plataforma estão automaticamente dispensadas de registro pela Comissão de Valores Mobiliários - CVM.
                A CVM não analisa previamente as ofertas.
              </p>
              <p>
                As ofertas realizadas não implicam por parte da CVM a garantia da veracidade das informações prestadas, de adequação à legislação vigente ou julgamento sobre a qualidade da sociedade empresária de pequeno porte.
                Antes de aceitar uma oferta leia com atenção as informações essenciais da oferta, em especial a seção de alertas sobre riscos.
              </p>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={7} className={classes.copyright}>
            <Typography variant="caption">
              © Gottaup é uma plataforma de investimento autorizada pela <Link to="#">Comissão de Valores Mobiliários (CVM)</Link>
            </Typography>
            <img src={CVMLogo} alt="Logo CVM" className={classes.cvmLogo} />
          </Grid>
          <Grid item xs={12} sm={1} md={2}></Grid>
          <Grid item xs={12} sm={5} md={3} className={classes.contato}>
            <Typography variant="subtitle1" gutterBottom>
              Contato
              <span className={classes.markedH6Left} />
            </Typography>
            <div className={classes.icons}>
              <IconButton>
                <EmailOutlineIcon />
              </IconButton>
              <IconButton>
                <WhatsappIcon />
              </IconButton>
              <IconButton>
                <FacebookIcon />
              </IconButton>
              <IconButton>
                <LinkedinIcon />
              </IconButton>
              <IconButton>
                <TwitterIcon />
              </IconButton>
            </div>
            
          </Grid>
        </Grid>
  
      </Container>

    </footer>
  );
}
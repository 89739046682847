import {createAsyncThunk, createEntityAdapter, createSlice, EntityState} from "@reduxjs/toolkit";
import { fromEvent, throwError } from "rxjs";
import { catchError, takeUntil, timeout, map } from "rxjs/operators";
import ReportEstatisticasPlataformaDTO from "../../../models/dtos/ReportEstatisticasPlataformaDTO";
import { api } from "../../../services/api/api";
import { APIError } from "../../../services/api/APIError";
import { APIUtils } from "../../../services/api/APIUtils";
import moment from "moment";
import { RootState } from "../../configureStore";
import { RequestState, shouldFetch } from "../../RequestsState";

interface EstatisticasSliceState {
  requests: EntityState<RequestState<any>>;
}

const requestsAdapter = createEntityAdapter<RequestState<any>>({
  selectId: request => request.id
});

const initialState = {
  requests: requestsAdapter.getInitialState()
} as EstatisticasSliceState

const REQ_FETCH_ESTATISTICAS = "fetchEstatisticas";

const requestSelectors = requestsAdapter.getSelectors((state: RootState) => state.entities.estatisticas.requests)

const trackFetchEstatisticas = (state: RootState): RequestState<ReportEstatisticasPlataformaDTO> => {
  return requestSelectors.selectById(state, REQ_FETCH_ESTATISTICAS) ?? { id: REQ_FETCH_ESTATISTICAS, pending: false }
}

const fetchEstatisticas = createAsyncThunk<ReportEstatisticasPlataformaDTO, void, { state: RootState; rejectValue: APIError }>
  ('estatisticas/fetchEstatisticas',
    async (args, { signal, rejectWithValue }) => {
      return api.report.fetchEstatisticas().pipe(
        timeout(TRANSACTION_TIMEOUT),
        takeUntil(fromEvent(signal, 'abort')),
        map(result => result.data),
        catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
      ).toPromise().catch(err => rejectWithValue(err))
    }, { condition: (args, { getState }) => shouldFetch(trackFetchEstatisticas(getState()),moment.duration(10, 'minutes')) }
  )

const estatisticasSlice = createSlice({
  name: 'estatisticas',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    //
    builder.addCase(fetchEstatisticas.pending, (state) => {
      requestsAdapter.upsertOne(state.requests, { id: REQ_FETCH_ESTATISTICAS, pending: true, error: undefined, data: undefined });
    })
    builder.addCase(fetchEstatisticas.fulfilled, (state, { payload: empresas }) => {
      requestsAdapter.updateOne(state.requests, {
        id: REQ_FETCH_ESTATISTICAS,
        changes: { pending: false, timestamp: moment().toISOString(), data: empresas }
      });
    })
    builder.addCase(fetchEstatisticas.rejected, (state, action) => {
      const error = action.payload as APIError || { ...action.error };
      requestsAdapter.updateOne(state.requests, { id: REQ_FETCH_ESTATISTICAS, changes: { pending: false, error } });
    })

  }
})

export {
  fetchEstatisticas,
  trackFetchEstatisticas
};

export default estatisticasSlice.reducer
import React, {useEffect, useState} from 'react';
import {Button, Card, CardContent, CardHeader, Container, Divider, Grid, IconButton} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import GroupIcon from '@material-ui/icons/Group';
import HomeIcon from '@material-ui/icons/Home';
import AccountCardDetailsIcon from 'mdi-material-ui/AccountCardDetails';
import {useSnackbar} from 'notistack';
import {Link, useLocation, useParams} from 'react-router-dom';
import {ContaUsuarioVO} from '../../../../../models/ContaUsuario';
import {Perfil} from '../../../../../models/enums/Perfil';
import {ImagemVO} from '../../../../../models/Imagem';
import Loading from '../../../../components/Loading';
import PageTemplate from '../../../../layout/PageTemplate';
import ContaEditForm from './components/ContaEditForm';
import PerfisEditForm from './components/PerfisEditForm';
import TrocaSenhaForm from './components/TrocaSenhaForm';
import {useDispatch, useSelector} from 'react-redux';
import {FotoPerfilEditForm} from './components/FotoPerfilEditForm';
import {AppDispatch} from "../../../../../../browser";
import {
  changeUsuarioImagemPerfil,
  changeUsuarioPerfis,
  changeUsuarioSenha,
  fetchUsuario,
  patchUsuarioDadosBasicos,
  trackFetchUsuario
} from "../../../../../stores/slices/entities/contaUsuariosSlice";
import ErrorEmptyState from "../../../../components/empty/ErrorEmptyState";
import {DeepPartial} from "@reduxjs/toolkit";
import {Patch} from "immer";
import {APIError} from "../../../../../services/api/APIError";
import {generateErrorDialog} from "../../../../../services/api/APIErrorUtils";
import {useDialog} from "../../../../components/dialog/PageServiceProvider";

interface State {
  usuario: ContaUsuarioVO;
}

export const UsuariosEditPage = () => {

  const dispatch = useDispatch<AppDispatch>()
  const {id} = useParams<{ id: string }>();

  const requestState = useSelector(trackFetchUsuario(parseInt(id)));

  const [state, setState] = useState<State>();

  const dialog = useDialog();

  const location = useLocation();
  const {from} = location.state as any || {from: {pathname: "/admin/usuarios"}};

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  useEffect(() => {
    const promise = dispatch(fetchUsuario({id: parseInt(id)}));
    return () => promise.abort();
  }, [id, dispatch]);

  useEffect(() => {
    if (requestState.data) {
      setState({usuario: requestState.data})
    }
  }, [requestState.data]);


  const updateImagemPerfil = async (imagemPerfil: ImagemVO) => {
    const resultAction = await dispatch(changeUsuarioImagemPerfil({id: parseInt(id), imagemPerfil}));
    if (changeUsuarioImagemPerfil.fulfilled.match(resultAction)) {
      enqueueSnackbar('Imagem de perfil alterada com sucesso!', {
        variant: 'success',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível alterar a imagem de perfil. Tente novamente."))
    }
  }

  const trocaSenha = async (senhaNova: string) => {
    const resultAction = await dispatch(changeUsuarioSenha({id: parseInt(id), email: state?.usuario.noEmail||'', senha: senhaNova}));
    if (changeUsuarioSenha.fulfilled.match(resultAction)) {
      enqueueSnackbar('Senha alterada com sucesso!', {
        variant: 'success',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível trocar a senha. Tente novamente."))
    }
  }

  const updatePerfisUsuario = async (perfis: Perfil[]) => {
    const resultAction = await dispatch(changeUsuarioPerfis({id: parseInt(id), perfis}));
    if (changeUsuarioPerfis.fulfilled.match(resultAction)) {
      enqueueSnackbar('Perfis salvos com sucesso!', {
        variant: 'success',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível atualizar perfis. Tente novamente."))
    }
  }

  const handleUpdateContaUsuario = async (nextState: DeepPartial<ContaUsuarioVO>, patches: Patch[], changes: DeepPartial<ContaUsuarioVO>) => {
    const resultAction = await dispatch(patchUsuarioDadosBasicos({id: parseInt(id), changes}));
    if (patchUsuarioDadosBasicos.fulfilled.match(resultAction)) {
      enqueueSnackbar('Dados básicos alterados com sucesso!', {
        variant: 'success',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });
      setState({usuario: {...state, ...nextState} as ContaUsuarioVO});
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível atualizar os dados básicos. Tente novamente."))
    }
  }
  return (
    <PageTemplate
      metaTags={{
        title: "Gottaup.com | Editar Usuário",
        description: "Atualizar dados do usuário"
      }}
      menuDesktop
      headerMobile={{
        title: "Editar Usuário",
        leftButtons: (
          <IconButton edge="start" color="inherit" component={Link} to={from}>
            <ArrowBackIcon/>
          </IconButton>
        )
      }}
      headerDesktop={{
        title: "Editar Usuário",
        breadcrumbs: [
          {label: "Home", to: '/', icon: HomeIcon},
          {label: "Usuários", to: '/admin/usuarios', icon: GroupIcon},
          {label: "Editar"}
        ]
      }}
    >

      <Container component="section" style={{marginTop: 24}}>

        {requestState.pending &&
        <Loading text="Carregando Formulário"/>
        }

        {requestState.error &&
        <ErrorEmptyState
          error={requestState.error}
          defaultDescription="Não foi possível carregar dados do usuário"
          onTryAgainClick={() => dispatch(fetchUsuario({id: parseInt(id)}))}
        />
        }

        {requestState.data && state &&

        <Grid container spacing={3}>


          <Grid item xs={12} md={6}>
            <FotoPerfilEditForm
              imagemPerfil={state.usuario.imagemPerfil}
              onFormSubmit={updateImagemPerfil}/>
          </Grid>


          <Grid item xs={12} md={6}>
            <ContaEditForm
              usuario={state.usuario}
              onSubmit={handleUpdateContaUsuario}/>
          </Grid>

          <Grid item xs={12} md={6}>
            <TrocaSenhaForm
              onFormSubmit={trocaSenha}/>
          </Grid>

          {state.usuario.perfis &&
          <Grid item xs={12} md={6}>
            <PerfisEditForm
              perfis={state.usuario.perfis}
              onFormSubmit={updatePerfisUsuario}/>
          </Grid>
          }

          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader
                title="Outras Ações"
              />
              <Divider/>
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      component={Link}
                      startIcon={<AccountCardDetailsIcon/>}
                      to={{
                        pathname: `/conta/${state.usuario.coContaUsuario}/investidor/edit`,
                        state: {from: location.pathname}
                      }}
                    >
                      Cadastro Investidor
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

        </Grid>
        }

      </Container>


    </PageTemplate>
  )
}
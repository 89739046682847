import React, { ReactNode } from 'react'
import { Typography, makeStyles, Theme, createStyles } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => createStyles({
  title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
  },
  markedH6Left: {
      height: 3,
      width: 48,
      display: 'block',
      marginTop: theme.spacing(0.2),
      backgroundColor: "#3f51b5",
  }
}), { name: 'PageSubtitle' });

interface PageSubtitleProps {
  children: ReactNode;
}

export const PageSubtitle = ({children}: PageSubtitleProps) => {

  const classes = useStyles();

  return (
    <Typography variant="h6" color="textPrimary" className={classes.title}>
      {children}
      <span className={classes.markedH6Left} />
    </Typography>
  )
}

import React from 'react'
import { useLocation, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectLogin } from '../../stores/slices/userSlice';
import { LoginPage } from './login/root/LoginPage';
import { routes } from '../routes';
import { LoginUtils } from '../../utils/login';

export const PageRouter = () => {

  const location = useLocation();
  const login = useSelector(selectLogin);

  return (
    <Switch>
      <Route key={0} path="/login" exact render={(props) => <LoginPage {...props} />} />
      {routes.map(({ path, exact, needsLogin, rolesAuthorized, component: C, ...rest }, index) => (
        <Route
          key={index + 1}
          path={path}
          exact={exact}
          render={(props) => (
            !needsLogin || (login && LoginUtils.isValid(login)) && (!rolesAuthorized || LoginUtils.isUserInAnyRole(login, rolesAuthorized))
              ? <C {...props} {...rest} />
              : <Redirect to={{ pathname: "/login", state: { from: location.pathname } }} />
          )}
        />
      ))}
    </Switch>
  )
}

import { Checkbox, createStyles, FormControl, FormControlLabel, FormHelperText, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
    helperText: {
        marginLeft: theme.spacing(4)
    },
    error: {
        color: "#f44336"
    }
   
}), { name: 'FormCheckBoxInput' });

interface Props {
    name?: string;
    label: string;
    helperText?: string;
    disabled?: boolean;
    required?: boolean;
    error?: string;
    inputRef?: React.Ref<any>;
}

export const FormCheckBoxInput = ({name,label,required,error,helperText,disabled,inputRef}: Props) => {

    const classes = useStyles();

    return (
        <FormControl error={!!error} className={error?classes.error:undefined}>
            <FormControlLabel
                control={
                    <Checkbox
                        name={name}
                        disabled={disabled}
                        required={required}
                        inputRef={inputRef}
                    />
                }
                label={label}
            />
            <FormHelperText className={classes.helperText}>
                {error || helperText}
            </FormHelperText>
        </FormControl>
    );
}
import { createStyles, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import React, { Fragment } from 'react';
import { InvestimentoVO } from '../../../../../models/Investimento';
import DownloadBox from '../../../../components/DownloadBox';
import GraficoHistoricoPerformance from '../../../investir/permalink/root/components/GraficoHistoricoPerformance';
import { APIUtils } from '../../../../../services/api/APIUtils';
import { FieldOutput } from '../../../../components/form/output/FieldOutput';
import { useIntl } from 'react-intl';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) => createStyles({
  observacoes: {
    color: 'rgba(0,0,0,.5)',
    textAlign: 'justify'
  },
  chartContainer: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.up('sm')]: {
      height: 300
    },
  },
  listItem: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(3, 2),
  },
  logo: {
    maxHeight: 80,
    width: 70
  },
}), { name: 'PainelInvestimentoAprovadoItem' });


interface Props {
  investimento: InvestimentoVO;
}

export default function PainelInvestimentoAprovadoItem({investimento}: Props) {

  const classes = useStyles();

  const intl = useIntl();

  return (
    <Fragment>

      <Paper key={investimento.coInvestimento} className={classes.listItem}>
        <Grid container spacing={2}>

          <Grid item xs={12} md={6}>
            <Grid container alignItems="center" spacing={2}>

              <Grid item xs={3} sm={2}>
                <img className={classes.logo} src={APIUtils.generateImageURL(investimento.projeto.empresa.imagemLogo)} alt={"logo da " + (investimento.projeto.empresa.noFantasia)} />
              </Grid>

              <Grid item xs={9} sm={10}>
                <FieldOutput label={investimento.projeto.empresa.noFantasia} value={investimento.projeto.noProjeto} />
              </Grid>

              <Grid item xs={6} sm={3}>
                <FieldOutput label="Valor Investido" value={intl.formatNumber(investimento.vrInvestimento,{style:"currency",currency:"BRL"})}/>
              </Grid>

              <Grid item xs={6} sm={3}>
                <FieldOutput label="Rendimento" value={intl.formatNumber(investimento.vrAtualizado - investimento.vrInvestimento,{style:"currency",currency:"BRL"})} />
              </Grid>

              <Grid item xs={6} sm={3}>
                <FieldOutput label="Rentabilidade" value={intl.formatNumber((investimento.vrAtualizado - investimento.vrInvestimento)/investimento.vrInvestimento,{style:"percent", minimumFractionDigits: 2})} />
              </Grid>

              <Grid item xs={6} sm={3}>
                <FieldOutput label="Valor Atualizado" value={intl.formatNumber(investimento.vrAtualizado,{style:"currency",currency:"BRL"})} />
              </Grid>

              <Grid item xs={6} sm={3}>
                <FieldOutput label="Confirmação Depósito" value={moment(investimento.dhAprovacao).format("L LT")} />
              </Grid>

              <Grid item xs={6} sm={3}>
                <FieldOutput label="Data Início" value={moment(investimento.projeto.dtInicioTitulo).format("L")} />
              </Grid>

              <Grid item xs={6} sm={3}>
                <FieldOutput label="Vencimento" value={moment(investimento.projeto.dtVencimentoTitulo).format("L")} />
              </Grid>

              <Grid item xs={6} sm={3}>
                <FieldOutput label="Tempo Restante" value={`${moment(investimento.projeto.dtVencimentoTitulo).diff(moment(),'days')} dias`} />
              </Grid>


              <Grid item xs={12} sm={5} md={6}>
                {investimento.documentoContrato
                  ? <DownloadBox name="Contrato" url={APIUtils.generateDocumentoURL(investimento.documentoContrato)} icon="pdf" />
                  : <FieldOutput label="Contrato" value="Aguardando geração" color={red[600]} />
                }
              </Grid>

              <Grid item xs={12}>
                <Typography variant="caption" className={classes.observacoes}>* Última atualização de cota em {moment(investimento.projeto.valoresCota[0].dtInicio).format("L")}</Typography>
              </Grid>

            </Grid>

          </Grid>

          <Grid item xs={12} md={6}>

            <Grid container spacing={2}>

              <Grid item className={classes.chartContainer} xs={12}>
                <GraficoHistoricoPerformance
                  qtCotas={investimento.qtCota}
                  valoresCota={investimento.projeto.valoresCota}
                  dtInicioTitulo={investimento.projeto.dtInicioTitulo}
                  dtVencimentoTitulo={investimento.projeto.dtVencimentoTitulo}
                  prazoMeses={investimento.projeto.qtPrazoMesesTitulo}
                  rentabilidadeCDI={investimento.projeto.taxaJuros.pcTaxaCdi}
                  rentabilidadePoupanca={investimento.projeto.taxaJuros.pcTaxaPoupanca}
                  rentabilidadeInvestimentoFixa={investimento.projeto.pcRentabilidadeProjetadaFixaAnual || undefined}
                  rentabilidadeInvestimentoMin={investimento.projeto.pcRentabilidadeProjetadaMinimaAnual || undefined}
                  rentabilidadeInvestimentoMax={investimento.projeto.pcRentabilidadeProjetadaMaximaAnual || undefined}
                  rentabilidadeGarantida={investimento.projeto.pcRentabilidadeGarantidaFixaAnual || undefined}
                  valorCotaEmitida={investimento.projeto.vrCotaEmitida}
                />
              </Grid>

            </Grid>

          </Grid>

        </Grid>

      </Paper>

    </Fragment>

  )
}
import { Container, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PageTemplate from '../../../../layout/PageTemplate';
import InformaEmailStep from './components/InformaEmailStep';
import InformaNovaSenhaStep from './components/InformaNovaSenhaStep';
import SucessoResetSenhaStep from './components/SucessoResetSenhaStep';


enum ResetSenhaSteps {
    INFORMA_EMAIL,
    INFORMA_NOVA_SENHA,
    SUCESSO_RESET_SENHA
}

interface ResetSenhaState {
    noEmail: string;
}

export const ResetSenhaPage = () => {

    const [step, setStep] = useState(ResetSenhaSteps.INFORMA_EMAIL);
    const [state, setState] = useState<ResetSenhaState>({ noEmail: '' });

    const informaEmailComplete = (noEmail: string) => {
        setState({ ...state, noEmail })
        setStep(ResetSenhaSteps.INFORMA_NOVA_SENHA);
    }

    const informaNovaSenhaComplete = () => {
        setStep(ResetSenhaSteps.SUCESSO_RESET_SENHA);
    }

    return (
        <PageTemplate
            metaTags={{
                title: "Gottaup.com | Recuperação de Senha",
                description: "Inicie seu processo de recuperação de senha"
            }}
            menuDesktop
            headerMobile={{
                title: "Recuperação de Senha",
                leftButtons: (
                    <IconButton edge="start" color="inherit" component={Link} to="/login">
                        <ArrowBackIcon />
                    </IconButton>
                )
            }}
            headerDesktop={{
                title: "Recuperação de Senha",
                breadcrumbs: [
                    { label: "Home", to: '/', icon: HomeIcon },
                    { label: "Login", to: '/login', icon: PersonIcon },
                    { label: "Recuperação de Senha" }
                ]

            }}
        >
            <Container component="section" maxWidth="sm" style={{ marginTop: 24 }}>
                {step == ResetSenhaSteps.INFORMA_EMAIL &&
                    <InformaEmailStep onComplete={informaEmailComplete} />
                }
                {step == ResetSenhaSteps.INFORMA_NOVA_SENHA &&
                    <InformaNovaSenhaStep onComplete={informaNovaSenhaComplete} noEmail={state.noEmail} />
                }
                {step == ResetSenhaSteps.SUCESSO_RESET_SENHA &&
                    <SucessoResetSenhaStep />
                }
            </Container>

        </PageTemplate>
    )

}
import React, { useRef } from 'react'
import { ButtonProps, Button, useForkRef } from '@material-ui/core'

interface ButtonFileUploadProps extends ButtonProps {
  name?: string;
  inputRef?: React.Ref<any>;
  accept?: string;
  onFileUpload?: (files: FileList) => void;
}

export const ButtonFileUpload = (props: ButtonFileUploadProps) => {
  const { name, inputRef, accept, onFileUpload, ...rest } = props;
  const inputFileRef = useRef<HTMLInputElement>(null);
  const combinedRef = useForkRef(inputFileRef, inputRef || null);
  const handleClick = () => inputFileRef.current?.click();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if(files){
      inputFileRef.current?.dispatchEvent(new Event("blur")); 
      if (onFileUpload) onFileUpload(files);
    }
  }

  return (
    <>
      <input name={name} ref={combinedRef} type="file" hidden accept={accept} onChange={handleFileChange} />
      <Button {...rest} onClick={handleClick} />
    </>
  )
}

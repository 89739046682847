import { Operation } from "../enums/Operation";

export default class BatchOperationDTO<T> {

    public sequential: number;
    public action: Operation;
    public id: number|undefined;
    public value: T|undefined;

    constructor(sequential: number, action: Operation, id: number|undefined, value: T|undefined) {
        this.sequential = sequential;
        this.action = action;
        this.id = id;
        this.value = value;
        Object.seal(this); // Não permite alterar estrutura da classe
    }

}
import ISODateTime from "../models/types/ISODateTime";

export default class APIErrorMessage {

    public type: string | undefined;
    public message: string | undefined;
    public traceid: string | undefined;
    public timestamp: ISODateTime | undefined;
    public status: number | undefined;
    public statustext: string | undefined;
    public url: string | undefined;

    constructor(message?: string) {
        this.message = message;
    }

    async setFromResponse(response: Response) {
        const responseText = await response.text()
        const responseJson = this.tryParseJSON(responseText);
        if (responseJson) {
            Object.assign(this, responseJson);
            if (this.timestamp) this.timestamp = new ISODateTime(String(this.timestamp));
        } else {
            switch (response.status) {
                case 404:
                    this.message = "Não encontrado.";
                    break;
                case 403:
                    this.message = "Usuário não possui permissão de acesso a esta funcionalidade.";
                    break;
                //case 401:
                //    this.message = "Token inválido. Por favor faça um novo login.";
                //    break;
                default:
                    this.message = responseText;
                    break;
            }
        }
        this.status = response.status;
        this.statustext = response.statusText;
        this.url = response.url;
        return this;
    }

    setFromError(ex: Error) {
        this.type = ex.name;
        switch (ex.message) {
            case "ajax error":
                // Erro de conexão Rxjs
                this.message = "Falha na requisição com o servidor";
                break;
            case "Timeout has occurred":
                // Timeout Rxjs
                this.message = "Timeout na transação";
                break;
            case "Failed to fetch":
                // Erro de conexão no Chrome
                this.message = "Falha na requisição com o servidor";
                break;
            case "NetworkError when attempting to fetch resource.":
                // Erro de conexão no Firefox
                this.message = "Falha na requisição com o servidor";
                break;
            case "The user aborted a request.":
                // Timeout no Chrome
                this.message = "Timeout na transação";
                break;
            case "The operation was aborted. ":
                // Timeout no Firefox
                this.message = "Timeout na transação";
                break;
            default:
                this.message = ex.message;
                break;
        }
        return this;
    }

    tryParseJSON(jsonString: string) {
        try {
            const o = JSON.parse(jsonString);
            if (o && typeof o === "object") {
                return o;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }

    }

}
import { Button, createStyles, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from '@material-ui/core';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import BusinessIcon from '@material-ui/icons/Business';
import CloseIcon from '@material-ui/icons/Close';
import GroupIcon from '@material-ui/icons/Group';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import PersonIcon from '@material-ui/icons/Person';
import WorkIcon from '@material-ui/icons/Work';
import AccountCardDetailsIcon from 'mdi-material-ui/AccountCardDetails';
import FileCheckOutlineIcon from 'mdi-material-ui/FileCheckOutline';
import FileDocumentBoxCheckOutlineIcon from 'mdi-material-ui/FileDocumentBoxCheckOutline';
import FileUploadOutlineIcon from 'mdi-material-ui/FileUploadOutline';
import LogoutIcon from 'mdi-material-ui/Logout';
import MonitorDashboardIcon from 'mdi-material-ui/MonitorDashboard';
import PercentIcon from 'mdi-material-ui/Percent';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Perfil } from '../../../../models/enums/Perfil';
import { ConfirmDialog, ConfirmDialogProps } from '../../../components/ConfirmDialog';
import EmptyState from '../../../components/EmptyState';
import PageTemplate from '../../../layout/PageTemplate';
import { efetuaLogoff, selectLogin } from '../../../../stores/slices/userSlice';
import { LoginUsuario, LoginUtils } from '../../../../utils/login';

const useStyles = makeStyles(() => createStyles({
    logo: {
        maxHeight: 40,
        width: 40
    },
    avatar: {
        marginRight: '.5em'
    },
    arrow: {
        minWidth: 0
    },
    menuGroup: {
        marginLeft: '1em'
    }
}), { name: 'MinhaConta' });


export const MinhaContaPage = () => {

    const classes = useStyles();

    const login = useSelector(selectLogin) as LoginUsuario;

    const location = useLocation();
    const dispatch = useDispatch();
    const [dialog, setDialog] = useState<ConfirmDialogProps | undefined>(undefined);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const logout = () => {
        setDialog(undefined);
        dispatch(efetuaLogoff());
        const dismissButton = (key: string | number | undefined) => <Button onClick={() => closeSnackbar(key)}><CloseIcon color="action" /></Button>
        enqueueSnackbar('Logout efetuado com sucesso!', {
            variant: 'success',
            action: dismissButton
        });
       
    }

    const confirmaLogout = () => {
        setDialog({
            open: true,
            title: "Sair",
            content: "Tem certeza de que deseja efetuar o logout?",
            onConfirm: logout,
            onCancel: () => setDialog(undefined)
        });
    }

    if (!login || !LoginUtils.isValid(login)) {
        return (
            <PageTemplate
                metaTags={{
                    title: "Gottaup.com | Minha Conta",
                    description: "Minha Conta"
                }}
                menuMobile
            >
                <EmptyState
                    icon={<AccountCircleOutlinedIcon style={{ fontSize: '58px' }} color="disabled" />}
                    title="Você ainda não está logado."
                    subtitle="Efetue o login para visualizar a sua conta."
                    action={
                        <Button variant="contained" color="secondary" component={Link} to={{ pathname: "/login", state: { from: location.pathname } }}>
                            Faça Login
                            </Button>
                    }
                />
            </PageTemplate>

        )

    } else {

        return (
            <PageTemplate
                metaTags={{
                    title: "Gottaup.com | Minha Conta",
                    description: "Minha Conta"
                }}
                menuMobile
            >
                {dialog && <ConfirmDialog {...dialog} />}

                <section>
                    <List>
                        <Typography className={classes.menuGroup} variant="h6">Conta</Typography>
                        <ListItem button component={Link} to="/login/perfil" autoFocus>
                            <ListItemIcon>
                                <PersonIcon />
                            </ListItemIcon>
                            <ListItemText primary="Perfil" />
                            <ListItemIcon className={classes.arrow}>
                                <KeyboardArrowRightIcon />
                            </ListItemIcon>
                        </ListItem>
                    </List>

                    {LoginUtils.isUserInAnyRole(login,[Perfil.Administrador, Perfil.Investidor]) &&
                        <List>
                            <Typography className={classes.menuGroup} variant="h6" >Investidor</Typography>
                            <ListItem button component={Link} to="/conta/painel">
                                <ListItemIcon>
                                    <MonitorDashboardIcon />
                                </ListItemIcon>
                                <ListItemText primary="Painel de Investimentos" />
                                <ListItemIcon className={classes.arrow}>
                                    <KeyboardArrowRightIcon />
                                </ListItemIcon>
                            </ListItem>
                            <ListItem button component={Link} to={{ pathname: "/conta/investidor", state: { from: location.pathname } }}>
                                <ListItemIcon>
                                    <AccountCardDetailsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Cadastro do Investidor" />
                                <ListItemIcon className={classes.arrow}>
                                    <KeyboardArrowRightIcon />
                                </ListItemIcon>
                            </ListItem>
                            { /* 
                                <ListItem button>
                                    <ListItemIcon>
                                        <ReceiptIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Extrato IR" />
                                    <ListItemIcon className={classes.arrow}>
                                        <KeyboardArrowRightIcon />
                                    </ListItemIcon>
                                </ListItem>
                                */}
                        </List>
                    }

                    {/* props.login.isUserInAnyRole([Perfil.Administrador, Perfil.Empresa]) &&
                            <List>
                                <Typography className={classes.menuGroup} variant="h6" >Empresa</Typography>
                                <ListItem button>
                                    <ListItemIcon>
                                        <MonitorDashboardIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Painel de Captações" />
                                    <ListItemIcon className={classes.arrow}>
                                        <KeyboardArrowRightIcon />
                                    </ListItemIcon>
                                </ListItem>
                                <ListItem button>
                                    <ListItemIcon>
                                        <FileFindOutlineIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Pré-Projetos em Análise" />
                                    <ListItemIcon className={classes.arrow}>
                                        <KeyboardArrowRightIcon />
                                    </ListItemIcon>
                                </ListItem>
                                <ListItem button>
                                    <ListItemIcon>
                                        <PublishIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Publicação de Documentos" />
                                    <ListItemIcon className={classes.arrow}>
                                        <KeyboardArrowRightIcon />
                                    </ListItemIcon>
                                </ListItem>

                            </List>
                        */}

                    {LoginUtils.isUserInRole(login,Perfil.Administrador) &&
                        <List>
                            <Typography className={classes.menuGroup} variant="h6" >Administração</Typography>
                            <ListItem button component={Link} to={`/admin/empresas`}>
                                <ListItemIcon>
                                    <BusinessIcon />
                                </ListItemIcon>
                                <ListItemText primary="Empresas" />
                                <ListItemIcon className={classes.arrow}>
                                    <KeyboardArrowRightIcon />
                                </ListItemIcon>
                            </ListItem>

                            <ListItem button component={Link} to={`/admin/projetos`}>
                                <ListItemIcon>
                                    <WorkIcon />
                                </ListItemIcon>
                                <ListItemText primary="Projetos" />
                                <ListItemIcon className={classes.arrow}>
                                    <KeyboardArrowRightIcon />
                                </ListItemIcon>
                            </ListItem>

                            <ListItem button component={Link} to={`/admin/usuarios`}>
                                <ListItemIcon>
                                    <GroupIcon />
                                </ListItemIcon>
                                <ListItemText primary="Usuários" />
                                <ListItemIcon className={classes.arrow}>
                                    <KeyboardArrowRightIcon />
                                </ListItemIcon>
                            </ListItem>

                            <ListItem button component={Link} to={`/admin/taxasjuros`}>
                                <ListItemIcon>
                                    <PercentIcon />
                                </ListItemIcon>
                                <ListItemText primary="Taxas" />
                                <ListItemIcon className={classes.arrow}>
                                    <KeyboardArrowRightIcon />
                                </ListItemIcon>
                            </ListItem>
                        </List>
                    }
                    {LoginUtils.isUserInRole(login,Perfil.Administrador) &&
                        <List>
                            <Typography className={classes.menuGroup} variant="h6" >Operacional</Typography>
                            { /* 
                                <ListItem button >
                                    <ListItemIcon>
                                        <FileFindOutlineIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Pré-Projetos para Análise" />
                                    <ListItemIcon className={classes.arrow}>
                                        <KeyboardArrowRightIcon />
                                    </ListItemIcon>
                                </ListItem>
                                 */}

                            <ListItem button component={Link} to="/operacional/documentos">
                                <ListItemIcon>
                                    <FileCheckOutlineIcon />
                                </ListItemIcon>
                                <ListItemText primary="Documentos para Validação" />
                                <ListItemIcon className={classes.arrow}>
                                    <KeyboardArrowRightIcon />
                                </ListItemIcon>
                            </ListItem>

                            <ListItem button component={Link} to="/operacional/depositos">
                                <ListItemIcon>
                                    <FileDocumentBoxCheckOutlineIcon />
                                </ListItemIcon>
                                <ListItemText primary="Aprovação de Reservas" />
                                <ListItemIcon className={classes.arrow}>
                                    <KeyboardArrowRightIcon />
                                </ListItemIcon>
                            </ListItem>

                            <ListItem button component={Link} to="/operacional/contratos">
                                <ListItemIcon>
                                    <FileUploadOutlineIcon />
                                </ListItemIcon>
                                <ListItemText primary="Upload de Contratos" />
                                <ListItemIcon className={classes.arrow}>
                                    <KeyboardArrowRightIcon />
                                </ListItemIcon>
                            </ListItem>

                        </List>
                    }

                    <List>
                        <Typography className={classes.menuGroup} variant="h6" >Mais</Typography>

                        <ListItem button onClick={confirmaLogout}>
                            <ListItemIcon>
                                <LogoutIcon transform="rotate(180)" />
                            </ListItemIcon>
                            <ListItemText primary="Sair" />
                            <ListItemIcon className={classes.arrow}>
                                <KeyboardArrowRightIcon />
                            </ListItemIcon>
                        </ListItem>
                    </List>

                </section>


            </PageTemplate>
        )
    }

}
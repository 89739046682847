import { Grid } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useIntl } from 'react-intl';
import { ValorCotaVO } from '../../../../../../../models/ValorCota';
import { FieldOutput } from '../../../../../../components/form/output/FieldOutput';


interface ValorCotaHistoricoProps {
  valorCota: ValorCotaVO;
}

export const ValorCotaHistorico = ({ valorCota }: ValorCotaHistoricoProps) => {

  const intl = useIntl();

  return (
    <Grid container spacing={2}>

      <Grid item xs={4}>
        <FieldOutput label="Data Início" value={moment(valorCota.dtInicio).format("L")} />
      </Grid>

      <Grid item xs={4}>
        <FieldOutput label="Data Fim" value={moment(valorCota.dtFim).format("L")} />
      </Grid>

      <Grid item xs={4}>
        <FieldOutput label="Valor Cota" value={intl.formatNumber(valorCota.vrCota, { style: "currency", currency: "BRL" })} />
      </Grid>

    </Grid>
  )
}

import React from 'react';
import { makeStyles, createStyles, Button, CircularProgress, ButtonProps} from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}), { name: 'ButtonProgress' });

interface ButtonProgressProps extends ButtonProps {
    loading: boolean;
}


export default function ButtonProgress(props: ButtonProgressProps) {
    const classes = useStyles();
    const { disabled, loading, ...rest } = props
    return (
        <div className={classes.wrapper}>
            <Button {...rest} disabled={disabled || loading} >
                {props.children}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
    )
}
import { Box, Collapse, Paper, Typography, createStyles, makeStyles } from '@material-ui/core';
import { Alert, AlertProps, AlertTitle } from '@material-ui/lab';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import LoginService from '../../../../../../services/LoginService';
import ButtonProgress from '../../../../../components/ButtonProgress';
import FormInput from '../../../../../components/FormInput';
import { CodigoMaskedInput } from '../../../../../components/MaskedInputs';
import { useDispatch } from 'react-redux';
import { registraToken } from '../../../../../../stores/slices/userSlice';

const useStyles = makeStyles(() => createStyles({
    inputCodigo: {
        fontSize: 24, width: '7em', fontFamily: 'monospace'
    },
}), { name: 'InformaNovaSenhaStep' });

export interface InformaNovaSenhaStepFormData {
    codigo: string;
    senhaNova: string;
    senhaConfirmacao: string;
}

interface InformaNovaSenhaStepProps {
    noEmail: string;
    onComplete: () => void;
}

export default function InformaNovaSenhaStep(props: InformaNovaSenhaStepProps) {

    const classes = useStyles();

    const { register, handleSubmit, errors, getValues, formState: { isSubmitting } } = useForm<InformaNovaSenhaStepFormData>();
    const dispatch = useDispatch();


    const [alert, setAlert] = useState<AlertProps | undefined>(undefined);


    const resetSenhaTroca = async (formData: InformaNovaSenhaStepFormData) => {
        try {
            const contaUsuario = await new LoginService().resetSenhaTroca(props.noEmail, formData.codigo, formData.senhaNova);
            if (contaUsuario.token) {
                dispatch(registraToken({ token: contaUsuario.token }));
            }
            props.onComplete();
        } catch (apiexception) {
            setAlert({
                severity: 'error',
                children: <>
                    <AlertTitle>{apiexception.message}</AlertTitle>
                    {apiexception.errorJson.traceid && `Traceid: ${apiexception.errorJson.traceid}`}
                </>
            })
        }

    }

    return (
        <Paper variant="outlined">

            <form noValidate onSubmit={handleSubmit(resetSenhaTroca)} style={{ padding: 10 }}>
                <Box mt={1} mb={1}>
                    <Typography variant="body1" paragraph align="justify">Informe abaixo a nova senha desejada e o código recebido no seu email <strong>{props.noEmail}</strong>.</Typography>
                </Box>

                <Box display="flex" justifyContent="center" mt={3}>
                    <FormInput
                        fullwidth={false}
                        name="senhaNova"
                        required
                        label="Nova Senha"
                        placeholder="*******"
                        helperText="Senha com 6 a 8 caractéres"
                        maxLength={8}
                        error={errors.senhaNova?.message?.toString()}
                        inputRef={register({
                            required: 'Informe a Senha',
                            minLength: { value: 6, message: 'Informe ao menos 6 caractéres' }
                        })}
                        type="password"
                    />
                </Box>
                <Box display="flex" justifyContent="center" mt={3}>

                    <FormInput
                        fullwidth={false}
                        name="senhaConfirmacao"
                        required
                        label="Confirmação da Senha"
                        placeholder="*******"
                        helperText=" "
                        maxLength={8}
                        error={errors.senhaConfirmacao?.message?.toString()}
                        inputRef={register({
                            required: 'Confirme a Senha',
                            minLength: { value: 6, message: 'Informe ao menos 6 caractéres' },
                            validate: {
                                matchesPreviousPassword: senhaConfirmacao => {
                                    const { senhaNova } = getValues();
                                    return senhaNova === senhaConfirmacao || "Senhas não conferem!";
                                }
                            }
                        })}
                        type="password"
                    />
                </Box>
                <Box display="flex" justifyContent="center" mt={3}>

                    <FormInput
                        fullwidth={false}
                        required
                        name="codigo"
                        variant="outlined"
                        label="Código de Validação"
                        inputComponent={CodigoMaskedInput as any}
                        shrinkLabel={true}
                        helperText=' '
                        inputClassName={classes.inputCodigo}
                        error={errors.codigo?.message?.toString()}
                        inputRef={register({
                            required: 'Preencha o código',
                            pattern: { value: /[0-9A-Z]{6}/, message: 'Formato inválido' },
                            minLength: { value: 6, message: 'Informe 6 posições' },
                            maxLength: { value: 6, message: 'Informe 6 posições' }
                        })}
                    />
                </Box>



                <Box display="flex" justifyContent="center" mt={2}>
                    <ButtonProgress id="btnProximo" loading={isSubmitting} variant="contained" size="large" color="secondary" type="submit">
                        Continuar
                    </ButtonProgress>
                </Box>
                <Box mt={2}>
                    <Collapse in={alert && !alert.hidden} onExited={() => setAlert(undefined)}>
                        {alert && <Alert id="msgErro" onClose={() => setAlert({ ...alert, hidden: true })} {...alert} />}
                    </Collapse>
                </Box>

            </form>
        </Paper>

    )

}
import { createStyles, makeStyles, Typography, Slider, Theme } from '@material-ui/core';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import React, { useState,  Fragment, useEffect } from 'react';
import Cropper, { Area } from 'react-easy-crop';

const useStyles = makeStyles((theme: Theme) => createStyles({
    cropperContainer: {
        position: 'relative',
        width: '100%',
        height: '250px'
    },
    cropperControls: {
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
        }
    },
    sliderContainer: {
        display: 'flex',
        flex: '1',
        alignItems: 'center',
    },
    slider: {

        padding: '12px 0px',
        marginLeft: 16,
        marginRight: 16,
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
            margin: '0 16px',
        },

    },
}), { name: 'ImageCropper' });

export interface CroppedProps {
    area: Area;
    rotation: number;
}

export interface ImageCropperProps {
    image: string;
    aspect?: number;
    showGrid?: boolean;
    cropShape?: "rect"|"round";
    onCropComplete?: (cropped: CroppedProps) => void;
}

export default function ImageCropper(props: ImageCropperProps) {
    const classes = useStyles();
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState<number>(1);
    const [rotation, setRotation] = useState<number>(0);
    const zoomIn = () => { if (zoom < 5) setZoom(zoom + 0.05) }
    const zoomOut = () => { if (zoom > 1) setZoom(zoom - 0.05) }
    const rotateLeft = () => { if (rotation > 0) setRotation(rotation - 1) }
    const rotateRight = () => { if (rotation < 360) setRotation(rotation + 1) }


    // Zera as configurações se trocar a imagem
    useEffect(()=>{
        setCrop({ x: 0, y: 0 });
        setRotation(0);
        setZoom(1);
    }, [props.image])

    const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
        if(props.onCropComplete){
            props.onCropComplete({
                area: croppedAreaPixels,
                rotation
            });
        }
        /*
        const imageBase64 = await getCroppedImg(
            props.image,
            croppedAreaPixels,
            rotation
          )
        croppedImage.current = await resizeImageKeepProportions(imageBase64, 200, 200);
        */
        
    }

    const handleChangeRotation = (event: React.ChangeEvent<{}>, rotation: number | number[]) => setRotation(rotation as number);
    const handleChangeZoom = (event: React.ChangeEvent<{}>, zoom: number | number[]) => setZoom(zoom as number);

    return (
        <Fragment>
            <div className={classes.cropperContainer}>
                <Cropper
                    image={props.image}
                    crop={crop}
                    zoom={zoom}
                    maxZoom={5}
                    rotation={rotation}
                    cropShape={props.cropShape}
                    aspect={props.aspect}
                    showGrid={props.showGrid}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    onRotationChange={setRotation}
                />
            </div>
            <Typography variant="overline">Zoom</Typography>
            <div className={classes.sliderContainer}>

                <ZoomOutIcon onClick={zoomOut} />
                <Slider
                    value={zoom}
                    min={1}
                    max={5}
                    step={0.05}
                    aria-labelledby="Zoom"
                    className={classes.slider}
                    onChange={handleChangeZoom}
                />
                <ZoomInIcon onClick={zoomIn} />

            </div>
            <Typography variant="overline">Rotação</Typography>
            <div className={classes.sliderContainer}>
                <RotateLeftIcon onClick={rotateLeft} />
                <Slider
                    value={rotation}
                    min={0}
                    max={360}
                    step={1}
                    aria-labelledby="Rotação"
                    className={classes.slider}
                    onChange={handleChangeRotation}
                />
                <RotateRightIcon onClick={rotateRight} />

            </div>
        </Fragment>
    );
}
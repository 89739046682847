import React, {ChangeEvent, Fragment, useEffect, useState} from 'react';
import {
  Box,
  Button,
  Container,
  createStyles,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';
import HomeIcon from '@material-ui/icons/Home';
import MoneyIcon from '@material-ui/icons/Money';
import TimerSandIcon from 'mdi-material-ui/TimerSand';
import UploadIcon from 'mdi-material-ui/Upload';
import {useSnackbar} from 'notistack';
import {FormattedPlural} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, Link as RouterLink} from 'react-router-dom';
import {useSessionStorage} from 'react-use';
import {DocumentoVO} from '../../../../models/Documento';
import {EmpresaVO} from '../../../../models/Empresa';
import {InvestimentoVO} from '../../../../models/Investimento';
import {ProjetoVO} from '../../../../models/Projeto';
import DashboardTotalizador from '../../../components/DashboardTotalizador';
import EmptyState from '../../../components/EmptyState';
import Loading from '../../../components/Loading';
import OnlyMobile from '../../../layout/OnlyMobile';
import PageTemplate from '../../../layout/PageTemplate';
import {PageSubtitle} from '../../../layout/PageSubtitle';
import {AppDispatch} from "../../../../../browser";
import {APIError} from "../../../../services/api/APIError";
import {generateErrorDialog} from "../../../../services/api/APIErrorUtils";
import {
  fetchEmpresaProjetos,
  fetchEmpresasCombo, trackFetchEmpresaProjetos,
  trackFetchEmpresasCombo
} from "../../../../stores/slices/entities/empresasSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import FormInput from "../../../components/FormInput";
import {LoadingText} from "../../../components/progress/LoadingText";
import {useForm} from "react-hook-form";
import produce from "immer";
import {
  fetchProjetoInvestimentosUploadContrato,
  trackFetchProjetoInvestimentosUploadContrato
} from "../../../../stores/slices/entities/projetosSlice";
import {UploadContratoInvestimentoItem} from "./components/UploadContratoInvestimentoItem";
import {
  changeInvestidorInvestimentoContrato,
} from "../../../../stores/slices/entities/investidoresSlice";
import {useDialog} from "../../../components/dialog/PageServiceProvider";

const useStyles = makeStyles((theme: Theme) => createStyles({
  fieldGroup: {
    marginTop: theme.spacing(2)
  },
  paragrafo: {
    marginLeft: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(1),
  },
  listItem: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(3, 2),
  },
}), {name: 'UploadContratoInvestimento'});

interface UploadContratoInvestimentoFormData {
  nuEmpresa: string;
  nuProjeto: string;
}

const generateFormDefaultValues = (state: State): UploadContratoInvestimentoFormData => {
  return {
    nuEmpresa: state.nuEmpresa?.toString() || '',
    nuProjeto: state.nuProjeto?.toString() || ''
  }
}

interface State {
  empresas: EmpresaVO[] | undefined;
  projetos: ProjetoVO[] | undefined;
  investimentos: InvestimentoVO[] | undefined;
  nuEmpresa: number | undefined;
  nuProjeto: number | undefined;
}

export const UploadContratoInvestimentoPage = () => {

  const classes = useStyles();

  const [sessionState, setSessionState] = useSessionStorage<State>('UploadContratoInvestimentoState', {
    empresas: undefined,
    projetos: undefined,
    investimentos: undefined,
    nuEmpresa: undefined,
    nuProjeto: undefined
  });
  const [state, setState] = useState<State>(sessionState);

  const {register} = useForm<UploadContratoInvestimentoFormData>({
    mode: "onChange",
    defaultValues: generateFormDefaultValues(sessionState)
  });

  const dispatch = useDispatch<AppDispatch>();

  const dialog = useDialog();

  const empresasRequestState = useSelector(trackFetchEmpresasCombo);

  const projetosRequestState = useSelector(trackFetchEmpresaProjetos(state.nuEmpresa || 0));

  const investimentosRequestState = useSelector(trackFetchProjetoInvestimentosUploadContrato(state.nuProjeto || 0));

  const location = useLocation();
  const {from} = location.state as any || {from: {pathname: "/conta"}};

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  useEffect(() => {
    if (!state.empresas) {
      const promiseEmpresas = dispatch(fetchEmpresasCombo());
      return () => promiseEmpresas.abort()
    }
  }, [dispatch, state.empresas]);

  useEffect(() => {
    if (empresasRequestState.data) {
      setState(produce(draft => {
        draft.empresas = empresasRequestState.data
      }))
    }
  }, [empresasRequestState.data]);

  useEffect(() => {
    if (projetosRequestState.data) {
      setState(produce(draft => {
        draft.projetos = projetosRequestState.data
      }))
    }
  }, [projetosRequestState.data]);

  useEffect(() => {
    if (investimentosRequestState.data) {
      setState(produce(draft => {
        draft.investimentos = investimentosRequestState.data
      }))
    }
  }, [investimentosRequestState.data]);


  const handleChangeEmpresa = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedNuEmpresa = e.target.value;
    if (selectedNuEmpresa) dispatch(fetchEmpresaProjetos({nuEmpresa: parseInt(selectedNuEmpresa)}));
    setState(produce(draft => {
      draft.projetos = undefined;
      draft.investimentos = undefined;
      draft.nuEmpresa = selectedNuEmpresa ? parseInt(selectedNuEmpresa) : undefined;
      draft.nuProjeto = undefined;
    }))
  }

  const handleChangeProjeto = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedNuProjeto = e.target.value;
    if (selectedNuProjeto) dispatch(fetchProjetoInvestimentosUploadContrato({nuProjeto: parseInt(selectedNuProjeto)}));
    const newState = produce(state, draft => {
      draft.investimentos = undefined;
      draft.nuProjeto = selectedNuProjeto ? parseInt(selectedNuProjeto) : undefined;
    });
    setState(newState);
    setSessionState(newState);
  }


  const updateContrato = async (coContaUsuario: number, coInvestimento: number, contrato: Partial<DocumentoVO>) => {
    const resultAction = await dispatch(changeInvestidorInvestimentoContrato({
      coContaUsuario: coContaUsuario,
      coInvestimento,
      contrato
    }))
    if (changeInvestidorInvestimentoContrato.fulfilled.match(resultAction)) {
      enqueueSnackbar('Contrato atualizado com sucesso!', {
        variant: 'success',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });
      const newContrato = unwrapResult(resultAction);
      const newState = produce(state, draft => {
        if(draft.investimentos){
          const idx = draft.investimentos.findIndex(i => i.coInvestimento === coInvestimento);
          draft.investimentos[idx].documentoContrato = newContrato;
        }
      });
      setState(newState);
      setSessionState(newState);
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível atualizar contrato. Tente novamente."))
    }
  }

  return (
    <PageTemplate
      metaTags={{
        title: "Gottaup.com | Upload de Contratos",
        description: "Upload de Contratos"
      }}
      menuDesktop
      headerMobile={{
        title: "Upload de Contratos",
        leftButtons: (
          <IconButton edge="start" color="inherit" component={RouterLink} to={from}>
            <ArrowBackIcon/>
          </IconButton>
        )
      }}
      headerDesktop={{
        title: "Upload de Contratos",
        breadcrumbs: [
          {label: "Home", to: '/', icon: HomeIcon},
          {label: "Upload de Contratos"}
        ]
      }}
    >

      <Container component="section" style={{marginTop: 24}}>

        <Typography variant="h5" className={classes.fieldGroup}>Selecionar Projeto</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>

            <FormInput
              required
              disabled={!state.empresas}
              helperText={!state.empresas && empresasRequestState.pending ?
                <LoadingText text="Carregando empresas"/> : undefined}
              name="nuEmpresa"
              label="Empresa"
              error={
                empresasRequestState.error
                && <Link component="button" onClick={() => dispatch(fetchEmpresasCombo())}>Erro ao Carregar. Tentar
                  Novamente</Link>
              }
              inputRef={register()}
              onChange={handleChangeEmpresa}
              select
            >

              <option value=""/>
              {state.empresas?.map(empresa => (
                <option key={empresa.nuEmpresa} value={empresa.nuEmpresa.toString()}>{empresa.noFantasia}</option>
              ))}
            </FormInput>

          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              required
              disabled={!state.projetos}
              helperText={!state.projetos && projetosRequestState.pending ?
                <LoadingText text="Carregando projetos"/> : undefined}
              name="nuProjeto"
              label="Projeto"
              error={
                projetosRequestState.error &&
                <Link component="button"
                      onClick={() => dispatch(fetchEmpresaProjetos({nuEmpresa: state.nuProjeto || 0}))}>
                  Erro ao Carregar. Tentar Novamente
                </Link>
              }
              inputRef={register()}
              onChange={handleChangeProjeto}
              select
            >
              <option value=""/>
              {state.projetos?.map(projeto =>
                <option key={projeto.nuProjeto} value={projeto.nuProjeto.toString()}>{projeto.noProjeto}</option>
              )}
            </FormInput>
          </Grid>
        </Grid>

        {investimentosRequestState.pending &&
        <Loading text="Carregando Investimentos"/>
        }

        {state.investimentos && state.investimentos.length === 0 &&
        <EmptyState
          icon={<TimerSandIcon style={{fontSize: '58px'}} color="disabled"/>}
          title="Não há investimentos aprovados até o momento."
          subtitle="Quando houver investimentos aprovados eles serão listados aqui"
        />
        }

        {state.investimentos && state.investimentos.length > 0 &&
        <Fragment>

          <OnlyMobile>
            <Box my={3}/>
          </OnlyMobile>

          <Grid container spacing={4}>

            <Grid item lg={4} sm={6} xs={12}>

              <DashboardTotalizador
                nome="DEPÓSITOS"
                icon={<MoneyIcon/>}
                iconBackgroundColor="grey"
                valor={state.investimentos.length.toString()}
              />

            </Grid>

            <Grid item lg={4} sm={6} xs={12}>

              <DashboardTotalizador
                nome="UPLOADS PENDENTES"
                icon={<UploadIcon/>}
                iconBackgroundColor="red"
                valor={state.investimentos.filter(i => i.documentoContrato === undefined).length.toString()}
              />

            </Grid>

            <Grid item lg={4} sm={6} xs={12}>

              <DashboardTotalizador
                nome="UPLOADS EFETUADOS"
                icon={<CheckIcon/>}
                iconBackgroundColor="green"
                valor={state.investimentos.filter(i => i.documentoContrato !== undefined).length.toString()}
              />

            </Grid>
          </Grid>

          <PageSubtitle>
            Exibindo {state.investimentos.length} <FormattedPlural value={state.investimentos.length} one="investimento"
                                                                   other="investimentos"/>
          </PageSubtitle>
          {state.investimentos.map(investimento => {
            return (
              <UploadContratoInvestimentoItem
                key={investimento.coInvestimento}
                investimento={investimento}
                onFormSubmit={updateContrato}
              />
            );
          })}

          <Box my={3}/>


        </Fragment>
        }


      </Container>

    </PageTemplate>
  )
}
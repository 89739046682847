import { Button, createStyles, Grid, makeStyles, Paper, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close';
import UploadIcon from 'mdi-material-ui/Upload';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { DocumentoVO } from '../../../../../models/Documento';
import { InvestimentoVO } from '../../../../../models/Investimento';
import { APIUtils } from '../../../../../services/api/APIUtils';
import { FileUtils } from '../../../../../utils/files';
import { ButtonFileUpload } from '../../../../components/ButtonFileUpload';
import { ButtonDanger } from '../../../../components/buttons/ButtonDanger';
import { useDialog } from '../../../../components/dialog/PageServiceProvider';
import DownloadBox from '../../../../components/DownloadBox';
import { FieldOutput } from '../../../../components/form/output/FieldOutput';


const useStyles = makeStyles((theme: Theme) => createStyles({
  documentoContainer: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 400,
    },
  },
  listItem: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(3, 2),
  },
  logo: {
    maxHeight: 80,
    width: 70
  },
}), { name: 'PainelInvestimentoReservaItem' });


interface Props {
  reserva: InvestimentoVO;
  onFormSubmit: (nuInvestimento: number, documento: Partial<DocumentoVO>) => void;
  onCancelarReservaSubmit: (coInvestimento: number) => void;
}


export default function PainelInvestimentoReservaItem(props: Props) {

  const { reserva } = props;

  const classes = useStyles();
  const theme = useTheme();

  const intl = useIntl();

  const dialog = useDialog();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleUploadComprovanteDeposito = async (files: FileList) => {
    const file = files[0];
    if (file) {
      if (!(FileUtils.isFileImage(file) || FileUtils.isFilePdf(file))) {
        enqueueSnackbar('Formato de arquivo inválido!', {
          variant: 'error',
          action: <Button onClick={() => closeSnackbar()}>OK</Button>
        });
      } else {
        const confirmed = await dialog({
          title: "Confirma upload do Comprovate de Depósito?",
          description: "Ao confirmar o comprovante será anexado a reserva. Se cancelar, nenhuma alteração será salva no servidor.",
          confirmOption: "CONFIRMAR",
          cancelOption: "CANCELAR"
        })
        if (confirmed) {
          const documento: Partial<DocumentoVO> = {
            noDocumento: file.name,
            imDocumento: await FileUtils.fileToBase64(file)
          }
          props.onFormSubmit(reserva.coInvestimento, documento); //chama evento no pai para persistir
        }
      }
    }
  }

  const confirmaCancelamento = async () => {
    const confirmed = await dialog({
      title: "Confirma o cancelamento desta reserva?",
      description: "Ao confirmar sua reserva será cancelada sem nenhum ônus, porém você perderá o direito as cotas desta reserva. Caso queira ainda participar desta captação, terá de efetuar uma nova reserva, se ainda houver cotas disponíveis",
      confirmOption: "CONFIRMAR",
      cancelOption: "CANCELAR"
    })
    if (confirmed) props.onCancelarReservaSubmit(reserva.coInvestimento); //chama evento no pai para persistir
  }

  return (
    <Fragment>
      <Paper key={reserva.coInvestimento} className={classes.listItem}>
        <Grid container alignItems="center" spacing={2}>

          <Grid item xs={3} sm={2} md={1}>
            <img className={classes.logo} src={APIUtils.generateImageURL(reserva.projeto.empresa.imagemLogo)} alt={"logo da " + (reserva.projeto.empresa.noFantasia)} />
          </Grid>

          <Grid item xs={9} sm={10} md={3}>
            <FieldOutput label={reserva.projeto.empresa.noFantasia} value={reserva.projeto.noProjeto} />
          </Grid>

          <Grid item xs={7} sm={3} md={2}>
            <FieldOutput label="Data Reserva" value={moment(reserva.dhReserva).format("L LT")} />
          </Grid>

          <Grid item xs={5} sm={3} md={2}>
            <FieldOutput label="Fim Captação" value={moment(reserva.projeto.dtFimCaptacao).format("L")} />
          </Grid>

          <Grid item xs={7} sm={3} md={2}>
            <FieldOutput label="Alvo atingido" value={intl.formatNumber(reserva.projeto.captacaoProjeto.vrTotalReservado / reserva.projeto.vrAlvoCaptacao, { style: "percent", minimumFractionDigits: 2 })} />
          </Grid>

          <Grid item xs={5} sm={3} md={2}>
            <FieldOutput label="Valor Reservado" value={intl.formatNumber(reserva.vrInvestimento, { style: "currency", currency: "BRL" })} />
          </Grid>


          <Grid item xs={7} sm={6} md={4}>
            <FieldOutput label="Depósitos" value={moment(reserva.projeto.dtInicioDepositos).twix(reserva.projeto.dtFimDepositos).simpleFormat("L")} />
          </Grid>


          <Grid item xs={5} sm={6} md={8}>
            <FieldOutput
              label="Comprovate de Depósito"
              value={reserva.documentoComprovanteDeposito ? 'Upload Efetuado' : 'Upload Pendente'}
              color={reserva.documentoComprovanteDeposito ? green[500] : red[600]} />
          </Grid>

          <Grid item xs={12} sm={5} md={4}>
            {reserva.documentoComprovanteDeposito &&
              <DownloadBox name="Comprovante" url={APIUtils.generateDocumentoURL(reserva.documentoComprovanteDeposito)} icon="file" />
            }
          </Grid>

          <Grid item xs={12} sm={7} md={8}>
            <Grid container justify="flex-end" spacing={2}>
              <Grid item>
                <ButtonDanger
                  variant="text"
                  onClick={confirmaCancelamento}
                  startIcon={<CloseIcon />}
                  size={useMediaQuery(theme.breakpoints.up('md')) ? "medium" : "small"}
                >
                  Cancelar Reserva
                  </ButtonDanger>
              </Grid>
              <Grid item>
                <ButtonFileUpload
                  color="primary"
                  startIcon={<UploadIcon />}
                  variant="outlined"
                  accept=".pdf,.jpg"
                  onFileUpload={handleUploadComprovanteDeposito}
                  size={useMediaQuery(theme.breakpoints.up('md')) ? "medium" : "small"}
                >
                  {reserva.documentoComprovanteDeposito ? "Trocar Comprovante" : "Upload Comprovante"}
                </ButtonFileUpload>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Paper>
    </Fragment>

  )
}
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import React from 'react';
import homeHeroImage from '../../../images/home/home_hero.jpg';
import HeroLayout from '../../../layout/HeroLayout';

const useStyles = makeStyles((theme: Theme) => createStyles({
  background: {
    backgroundImage: `url(${homeHeroImage})`,
    backgroundColor: '#7fc7d9', // Average color of the background image.
    backgroundPosition: 'center',
  },
  button: {
    minWidth: 200,
  },
  subtitle: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
  },
  titleMarkedCenter: {
    height: 4,
    width: 73,
    display: 'block',
    margin: `${theme.spacing(1)}px auto 0`,
    backgroundColor: theme.palette.primary.main,
  },
  more: {
    position: 'absolute',
    bottom: theme.spacing(9),
  },
    '@keyframes bounce': {
    '0%,100%,20%,50%,80%': { transform: 'translateY(0)' },
    '40%': { transform: 'translateY(-15px)' },
    '60%': { transform: 'translateY(-7px)' }
  },
  arrowDown: {
    position: 'absolute',
    bottom: theme.spacing(4),
    fontSize: theme.typography.h1.fontSize,
    color: 'yellow',
    animation: '$bounce 2s infinite'
  },

}), { name: 'LandingHero' });

export default function LandingHero() {
  const classes = useStyles();

  return (
    <HeroLayout backgroundClassName={classes.background}>
      <Typography color="inherit" align="center" variant="h2">
        JUNTE-SE A NOSSA COMUNIDADE DE INVESTIMENTOS
        <span className={classes.titleMarkedCenter}/>
      </Typography>
      <Typography color="inherit" align="center" variant="h5" className={classes.subtitle}>
        Faça parte de grupos de investimentos para contribuir com o crescimento de negócios e participe dos resultados gerados.
      </Typography>
      {/*
      <Button
        color="secondary"
        variant="contained"
        size="large"
        className={classes.button}
        component={Link}
        to={{ pathname: "/login/registro", state: { from: location.pathname } }}
      >
        Registre-se agora
      </Button>

      */}
      
      <Typography variant="body2" color="inherit" className={classes.more}>
        Conheça mais
      </Typography>
      <ArrowDownwardIcon className={classes.arrowDown} />
    </HeroLayout>
  );
}

import { IntlShape, FormatNumberOptions } from "react-intl";

export const reverseFormatNumber = (intl: IntlShape, val: string, opts?: FormatNumberOptions): number => {
  const group = intl.formatNumber(1111).replace(/1/g, '');
  const decimal = intl.formatNumber(1.1).replace(/1/g, '');
  let reversedVal = val.replace(new RegExp('\\' + group, 'g'), '');
  reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.');
  let result = Number.parseFloat(reversedVal);
  if(opts?.style == "percent"){
    result = result / 100;
  }
  if(opts?.maximumFractionDigits){
    result = Number.parseFloat(result.toFixed(opts.maximumFractionDigits));
  }
  return Number.isNaN(result)?0:result;
}
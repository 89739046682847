import { Patterns } from "./patterns";
import { Formatters } from "./formatters";
type CheckSums = [number, number];

const getRemaining = (
  value: number,
): number => (value % 11) < 2 ? 0 : 11 - (value % 11);

const generateCheckSums = (numbers: Array<number>, validators: Array<number>): CheckSums => {
  const initialCheckSums: CheckSums = [0, 0];
  return validators.reduce(([checkerA, checkerB], validator, index) => [
    (index === 0) ? 0 : (checkerA + numbers[index - 1] * validator),
    checkerB + numbers[index] * validator
  ] as CheckSums, initialCheckSums);
};

const isCNPJ = (value: string): boolean => {
  if (!Patterns.cnpj.test(value)) return false;
  const numbers = Formatters.cnpj.unformat(value).split('').map(Number);
  const validators = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  const checkers = generateCheckSums(numbers, validators);
  return (
    numbers[12] === getRemaining(checkers[0]) &&
    numbers[13] === getRemaining(checkers[1])
  );
};

const isCPF = (value: string): boolean => {
  if (!Patterns.cpf.test(value)) return false;
  const numbers = Formatters.cpf.unformat(value).split('').map(Number);
  const validators = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2];
  const checkers = generateCheckSums(numbers, validators);
  return (
    numbers[9] === getRemaining(checkers[0]) &&
    numbers[10] === getRemaining(checkers[1])
  );
};

export const Validators = {
  isCNPJ,
  isCPF
}
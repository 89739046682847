import { Box, Button, Container, createStyles, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles, Paper, Theme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Edit';
import HomeIcon from '@material-ui/icons/Home';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import PostAddIcon from '@material-ui/icons/PostAdd';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppDispatch } from '../../../../../../browser';
import { APIUtils } from '../../../../../services/api/APIUtils';
import { fetchEmpresasList, trackFetchEmpresas } from '../../../../../stores/slices/entities/empresasSlice';
import { Formatters } from '../../../../../utils/formatters';
import ErrorEmptyState from '../../../../components/empty/ErrorEmptyState';
import EmptyState from '../../../../components/EmptyState';
import Loading from '../../../../components/Loading';
import OnlyDesktop from '../../../../layout/OnlyDesktop';
import OnlyMobile from '../../../../layout/OnlyMobile';
import PageTemplate from '../../../../layout/PageTemplate';
import { FieldOutput } from '../../../../components/form/output/FieldOutput';

const useStyles = makeStyles((theme: Theme) => createStyles({
  logo: {
    maxHeight: 40,
    width: 40
  },
  logoDesktop: {
    maxHeight: 60,
    width: 60
  },
  listItem: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(3, 2),
  },
  listFieldName: {
    color: '#546e7a'
  },
  listFieldValue: {
    fontWeight: 500
  },

}), { name: 'EmpresasListPage' });

export const EmpresasListPage = () => {

  const classes = useStyles();

  const requestState = useSelector(trackFetchEmpresas);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const promise = dispatch(fetchEmpresasList());
    return () => promise.abort();
  }, [dispatch]);

  return (
    <PageTemplate
      metaTags={{
        title: "Gottaup.com | Empresas Cadastradas",
        description: "Listagem de empresas cadastradas"
      }}
      menuDesktop
      headerMobile={{
        title: "Empresas Cadastradas",
        leftButtons: (
          <IconButton edge="start" color="inherit" component={Link} to="/conta">
            <ArrowBackIcon />
          </IconButton>
        ),
        rightButtons: (
          <IconButton edge="end" color="inherit" component={Link} to="/admin/empresas/create">
            <AddIcon />
          </IconButton>
        )
      }}
      headerDesktop={{
        title: "Empresas Cadastradas",
        breadcrumbs: [
          { label: "Home", to: '/', icon: HomeIcon },
          { label: "Empresas" }
        ]
      }}
    >
      <section>

        <OnlyDesktop>
          <Container>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="contained" color="secondary" component={Link} to="/admin/empresas/create" startIcon={<AddIcon />}>Nova</Button>
            </Box>
          </Container>
        </OnlyDesktop>


        {requestState.pending &&
          <Loading text="Buscando empresas..." />
        }

        {requestState.error &&
          <ErrorEmptyState
            error={requestState.error}
            defaultDescription="Não foi possível buscar empresas cadastradas"
            onTryAgainClick={() => dispatch(fetchEmpresasList())}
          />
        }

        {requestState.data && requestState.data.length == 0 &&
          <EmptyState
            icon={<PostAddIcon style={{ fontSize: '58px' }} color="disabled" />}
            title="Nenhuma empresa cadastrada."
            subtitle="Crie uma nova empresa e ela será listada aqui."
          />
        }

        {requestState.data && requestState.data.length > 0 &&
          <Fragment>

            <OnlyMobile>
              <List>
                {requestState.data.map((empresa, index) => (
                  <Fragment key={index}>
                    <ListItem button component={Link} to={`/admin/empresas/${empresa.nuCnpjEmpresa}`}>
                      <ListItemAvatar>
                        <img className={classes.logo} src={APIUtils.generateImageURL(empresa.imagemLogo)} alt={"logo da " + empresa.noFantasia} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={empresa.noFantasia}
                        secondary={Formatters.cnpj.format(empresa.nuCnpjEmpresa)}
                      />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="detalhes da empresa">
                          <KeyboardArrowRightIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Fragment>
                ))}
              </List>
            </OnlyMobile>

            <OnlyDesktop>
              <Container>
                {requestState.data.map((empresa, index) => (
                  <Paper key={index} className={classes.listItem}>
                    <Grid container alignItems="center">
                      <Grid item sm={2} md={2}>
                        <Grid container justify="center">
                          <img className={classes.logoDesktop} src={APIUtils.generateImageURL(empresa.imagemLogo)} alt={"logo da " + empresa.noFantasia} />
                        </Grid>
                      </Grid>
                      <Grid item sm={4} md={3}>
                        <FieldOutput label={empresa.noFantasia} value={empresa.noFantasia} />
                      </Grid>
                      <Grid item sm={3} md={3}>
                        <FieldOutput label="CNPJ" value={Formatters.cnpj.format(empresa.nuCnpjEmpresa)} />
                      </Grid>
                      <Grid item sm={3} md={4}>
                        <Grid container justify="flex-end">
                          <Button
                            color="secondary"
                            variant="outlined"
                            component={Link}
                            startIcon={<EditIcon />}
                            to={`/admin/empresas/${empresa.nuCnpjEmpresa}`}>
                            Editar
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                  </Paper>
                ))}
              </Container>
            </OnlyDesktop>
          </Fragment>
        }
      </section>


    </PageTemplate>
  )
}
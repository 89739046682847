import { Button, Card, CardActions, CardContent, CardHeader, Checkbox, createStyles, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, makeStyles } from '@material-ui/core';
import React, { FormEvent, Fragment, useRef, useState } from 'react';
import { Perfil } from '../../../../../../models/enums/Perfil';
import { ConfirmDialog, ConfirmDialogProps } from '../../../../../components/ConfirmDialog';


const useStyles = makeStyles(() => createStyles({
  form: {
    marginBottom: 0
  },
  actions: {
    justifyContent: 'flex-end'
  }
}), { name: 'PerfisEditForm' });

interface Props {
  perfis: Perfil[];
  onFormSubmit: (perfis: Perfil[]) => void;
}

export default function PerfisEditForm(props: Props) {

  const classes = useStyles();
  const [perfis, setPerfis] = useState<Perfil[]>(props.perfis);
  const [unsavedFormData, setUnsavedFormData] = useState(false);
  const [invalidFormData, setInvalidFormData] = useState(false);
  const fieldsUpdated = useRef<string[]>([]);
  const [dialog, setDialog] = useState<ConfirmDialogProps | undefined>(undefined);
  const formRef = useRef<HTMLFormElement>(null);
   
  const handleChangeCheckboxPerfil = (value: Perfil) => () => {
    const currentIndex = perfis.indexOf(value);
    
    if (currentIndex === -1) {
      setPerfis(perfis.concat(value)); // Adiciona o perfil
      if(invalidFormData){
        setInvalidFormData(false); // Ao menos um perfis está ativo
      }
    } else {
      const novosPerfis = perfis.filter(item => item != value);
      setPerfis(novosPerfis); // Remove o perfil
      if(novosPerfis.length == 0){
        setInvalidFormData(true); // Nenhum perfil ativo
      }
    }

    if (!fieldsUpdated.current.includes(value.toString())) {
      fieldsUpdated.current.push(value.toString()); // Marca que o campo foi alterado
      if (!unsavedFormData) {
        setUnsavedFormData(true); // Seta que o form possui alterações não salvas
      }
    } else {
      fieldsUpdated.current = fieldsUpdated.current.filter(field => field != value.toString()); // Desmarca que o campo foi alterado
      if (fieldsUpdated.current.length == 0 && unsavedFormData) {
        setUnsavedFormData(false); // Seta que o form deixou de possuir alterações não salvas
      }
    }
        
  };

  const validaConfirmaUpdate = (e: FormEvent) => {
    e.preventDefault();
    setDialog({
      open: true,
      title: "Confirma alteração dos Perfis?",
      content: "Ao confirmar as informações serão salvas. Se cancelar, nenhum dado será salvo no servidor.",
      onConfirm: () => {
        setDialog(undefined); // Fecha dialog
        props.onFormSubmit(perfis); //chama evento no pai para persistir
        setUnsavedFormData(false); // Zera controles de alteração
      },
      onCancel: () => setDialog(undefined)
    })
  }

  return (
    <Fragment> 
      {dialog && <ConfirmDialog {...dialog} />}
      <Card>

        <form
          id="formPerfisEdit"
          noValidate
          autoComplete="off"
          className={classes.form}
          onSubmit={validaConfirmaUpdate}
          ref={formRef}>

          <CardHeader
            title="Perfis Autorizados"
          />
          <Divider />

          <CardContent>
            <FormControl required error={perfis.length == 0} component="fieldset">
              <FormLabel component="legend">Selecione os perfis</FormLabel>
              <FormGroup>
                {Object.keys(Perfil).filter(key => !isNaN(Number(key))).map(key => (
                  <FormControlLabel
                    key={key}
                    control={
                      <Checkbox
                        id={`usuario.perfis[${key}]`}
                        defaultChecked={props.perfis.indexOf(Number(key)) >= 0}
                        value={key}
                        onChange={handleChangeCheckboxPerfil(Number(key))}
                        required={perfis.length == 0}

                      />
                    }
                    label={Perfil[Number(key)]}

                  />
                ))}
              </FormGroup>
              <FormHelperText>Selecione ao menos 1 perfil</FormHelperText>
            </FormControl>
          </CardContent>
          <Divider />
          <CardActions className={classes.actions}>
            <Button
              color="secondary"
              variant="outlined"
              type="submit"
              disabled={!unsavedFormData || invalidFormData}
            >
              Salvar
          </Button>
          </CardActions>
        </form>
      </Card>
    </Fragment>

  )
}
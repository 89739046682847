import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, makeStyles, createStyles, Theme, Grid, Collapse, Link } from "@material-ui/core";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme: Theme) => createStyles({
  error: {
    color: theme.palette.error.dark,
  },
}), { name: 'PageDialog' });

export interface PageDialogOptions {
  catchOnCancel?: boolean;
  confirmOption?: string;
  cancelOption?: string;
  severity?: 'error' | 'info' | 'success' | 'warning';
  title?: string;
  description?: React.ReactNode;
  details?: React.ReactNode;
}

interface PageDialogProps extends PageDialogOptions {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

export const PageDialog = ({
  open, title, confirmOption, cancelOption, description, details, severity, onSubmit, onClose
}: PageDialogProps) => {

  const classes = useStyles();

  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    if (showDetails) setShowDetails(false); // Close Details on Details Change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details])

  const handleToggleShowDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <Dialog open={open}>
      <DialogTitle id="alert-dialog-title">
        <Grid container alignItems="center" spacing={1}>
          {severity &&
            <Grid item>
              {severity == 'error' &&
                <ErrorOutlineIcon className={classes.error} />
              }
            </Grid>
          }
          <Grid item className={clsx({ [classes.error]: severity == 'error' })}>
            {title}
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {details &&
          <Grid container alignItems="center">
            {showDetails ? <ExpandLessIcon color="primary" /> : <ExpandMoreIcon color="primary" />}
            <Link component="button" id="btnDialogDetalhes" onClick={handleToggleShowDetails} color="primary">
              {showDetails ? "Ocultar" : "Mais"} Detalhes
            </Link>
          </Grid>
        }
        {cancelOption &&
          <Button id="btnDialogCancelar" onClick={onClose} color="primary" autoFocus>
            {cancelOption}
          </Button>
        }
        {confirmOption &&
          <Button id="btnDialogConfirmar" onClick={onSubmit} type="submit" color="primary">
            {confirmOption}
          </Button>
        }
      </DialogActions>
      <Collapse in={showDetails} timeout="auto" unmountOnExit >
        <DialogContent style={{paddingTop: 0}}>
          <DialogContentText>
            {details}
          </DialogContentText>
        </DialogContent>
      </Collapse>
    </Dialog>
  );
}



import APIErrorMessage from './APIErrorMessage';

export default class APIException extends Error {

    public errorJson: APIErrorMessage;
    public message: string;

    constructor(apiErrorMessage: APIErrorMessage) {
        super();
        this.errorJson = apiErrorMessage;
        this.message = this.errorJson.message || this.errorJson.statustext || '';
        this.name = this.constructor.name;
    }

}
import { Grid, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { InputBaseComponentProps } from '@material-ui/core/InputBase';
import useForkRef from '@material-ui/core/utils/useForkRef';
import ErrorIcon from '@material-ui/icons/Error';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React, { KeyboardEvent, useEffect, useRef, useState, ChangeEvent, FocusEvent } from 'react';

interface Props {
  id?: string;
  defaultValue?: string;
  value?: string;
  name?: string;
  label?: string;
  variant?: "standard" | "filled" | "outlined";
  autoFocus?: boolean;
  multiline?: boolean;
  rows?: number;
  select?: boolean;
  initialValue?: string;
  helperText?: string | JSX.Element;
  characterCounter?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  pattern?: string;
  maxLength?: number;
  minLength?: number;
  type?: string;
  placeholder?: string;
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
  required?: boolean;
  inputComponent?: React.ElementType<InputBaseComponentProps>;
  children?: React.ReactNode;
  inputRef?: React.Ref<any>;
  submitOnEnter?: boolean;
  shrinkLabel?: boolean;
  inputStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  inputClassName?: string;
  error?: string | JSX.Element;
  fullwidth?: boolean;
}



export default function FormInput(props: Props) {

  const [showPassword, setShowPassword] = useState(false); // Para campos password
  const [counter, setCounter] = useState<number>(0);

  const inputRef = useRef<HTMLInputElement>(null);
  const combinedRef = useForkRef(inputRef, props.inputRef || null);

  useEffect(() => {
    if (props.characterCounter && props.initialValue) {
      setCounter(props.initialValue.length);
    }
  }, [props.initialValue, props.characterCounter]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target;
    if (props.characterCounter && input.value.length !== counter) {
      setCounter(input.value.length);
    }
    if (props.onChange) props.onChange(e);
  }

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !props.multiline && !props.submitOnEnter) {
      e.preventDefault(); // Não não faz a submissão
    }
  }

  const renderEndAdornment = () => {
    if (props.endAdornment) {
      return (
        <InputAdornment position={"end"}>
          {props.endAdornment}
        </InputAdornment>
      )
    }
    if (props.type && props.type == 'password') {
      return (
        <InputAdornment position="end">
          <IconButton edge="end"
            aria-label="toggle password visibility"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>)
    }
    if (props.error && !props.select) {
      return (
        <InputAdornment position={"end"}>
          <ErrorIcon color="error" />
        </InputAdornment>
      )
    }
    return undefined;
  }

  const getInputType = () => {
    if (props.type && props.type == 'password' && showPassword) {
      return "text"; // Usuário quer visualizar a senha, então troca para text
    } else {
      return props.type;
    }
  }

  const getHelperText = () => {
    if (props.characterCounter) {
      return (
        <Grid component={'span'} container direction="row" justify="space-between" alignItems="center">
          <span>{props.error || props.helperText}</span>
          <span>{counter}/{props.maxLength}</span>
        </Grid>
      )
    } else {
      return props.error || props.helperText;
    }
  }

  return (

    <TextField
      variant={props.variant || "standard"}
      required={props.required}
      fullWidth={props.fullwidth !== undefined ? props.fullwidth : true}
      disabled={props.disabled}
      id={props.id}
      value={props.value}
      defaultValue={props.defaultValue}
      name={props.name}
      margin="none"
      label={props.label}
      multiline={props.multiline}
      rows={props.rows}
      type={getInputType()}
      placeholder={props.placeholder}
      error={!!props.error}
      helperText={getHelperText()}
      onChange={handleChange}
      onBlur={props.onBlur}
      autoFocus={props.autoFocus}
      InputProps={{
        inputComponent: props.inputComponent,
        startAdornment: props.startAdornment ? <InputAdornment position="start">{props.startAdornment}</InputAdornment> : undefined,
        endAdornment: renderEndAdornment(),
        style: props.inputStyle,
        className: props.inputClassName
      }}
      InputLabelProps={{
        shrink: props.shrinkLabel,
        style: props.labelStyle
      }}
      inputProps={{
        maxLength: props.maxLength,
        minLength: props.minLength,
        pattern: props.pattern,
        onKeyPress: handleKeyPress
      }}
      select={props.select}
      SelectProps={{
        native: true
      }}
      inputRef={combinedRef}
    >
      {props.children}
    </TextField>
  );
}
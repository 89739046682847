import {
  Container,
  Grid,
  IconButton,
  Step,
  Stepper,
  useMediaQuery,
  useTheme,
  StepButton,
  Button
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HomeIcon from '@material-ui/icons/Home';
import {DeepPartial, unwrapResult} from '@reduxjs/toolkit';
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory, useParams} from 'react-router-dom';
import {AppDispatch} from '../../../../../../../../browser';
import {DadoBancarioVO} from '../../../../../../../models/DadoBancario';
import {DocumentoVO} from '../../../../../../../models/Documento';
import {EnderecoVO} from '../../../../../../../models/Endereco';
import {InvestidorVO} from '../../../../../../../models/Investidor';
import {APIError} from '../../../../../../../services/api/APIError';
import {generateErrorDialog} from '../../../../../../../services/api/APIErrorUtils';
import {
  trackFindInvestidorByContaUsuario,
  findInvestidorByContaUsuario,
  patchInvestidorDadosBasicos,
  patchInvestidorEndereco,
  patchInvestidorDadosBancarios,
  changeInvestidorComprovanteResidencia, changeInvestidorSelfieAutentica
} from '../../../../../../../stores/slices/entities/investidoresSlice';
import {selectLogin} from '../../../../../../../stores/slices/userSlice';
import {useDialog} from '../../../../../../components/dialog/PageServiceProvider';
import {DadosBancariosForm, DadosBancariosFormMode} from '../../../../../../components/form/shared/DadosBancariosForm';
import {EnderecoForm, EnderecoFormMode} from '../../../../../../components/form/shared/EnderecoForm';
import PageTemplate from '../../../../../../layout/PageTemplate';
import Loading from '../../../../../../components/Loading';
import ErrorEmptyState from '../../../../../../components/empty/ErrorEmptyState';
import {
  InvestidorDadosBasicosForm,
  InvestidorDadosBasicosFormMode
} from '../create/components/InvestidorDadosBasicosForm';
import {
  InvestidorSelfieDocumentoForm,
  InvestidorSelfieDocumentoFormMode
} from '../create/components/InvestidorSelfieDocumentoForm';
import {
  InvestidorComprovanteEnderecoForm,
  InvestidorComprovanteEnderecoFormMode
} from '../create/components/InvestidorComprovanteEnderecoForm';
import {InvestidorReviewEditCadastro} from './components/InvestidorReviewEditCadastro';
import {Patch} from 'immer';
import {useSnackbar} from "notistack";


enum InvestidorEditSteps {
  DADOS_BASICOS,
  SELFIE_DOCUMENTO,
  ENDERECO,
  COMPROVANTE_ENDERECO,
  DADOS_BANCARIOS,
  REVISAO
}

export const InvestidorEditPage = () => {

  const history = useHistory();
  const {from} = history.location.state as any || {from: {pathname: "/conta"}};

  const {id} = useParams<{ id: string }>();
  const login = useSelector(selectLogin);
  const coContaUsuario = id ? Number(id) : login?.id || 0

  const requestState = useSelector(trackFindInvestidorByContaUsuario(coContaUsuario));

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const dispatch = useDispatch<AppDispatch>()

  const dialog = useDialog();

  const [step, setStep] = useState(InvestidorEditSteps.REVISAO);

  const [state, setState] = useState<InvestidorVO>();

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  useEffect(() => {
    if (coContaUsuario) {
      const promise = dispatch(findInvestidorByContaUsuario({coContaUsuario}));
      return () => promise.abort();
    }
  }, [coContaUsuario, dispatch]);

  useEffect(() => {
    if (requestState.data) {
      setState(requestState.data)
    }
  }, [requestState.data]);

  const handleDadosBasicosSave = async (nextState: DeepPartial<InvestidorVO>, patches: Patch[], changes: DeepPartial<InvestidorVO>) => {
    const resultAction = await dispatch(patchInvestidorDadosBasicos({coContaUsuario, changes}));
    if (patchInvestidorDadosBasicos.fulfilled.match(resultAction)) {
      enqueueSnackbar('Dados básicos alterados com sucesso!', {
        variant: 'success',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });
      setState({...state, ...nextState} as InvestidorVO);
      setStep(InvestidorEditSteps.REVISAO);
      scrollTo({top: 0, behavior: "smooth"})
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível atualizar os dados básicos. Tente novamente."))
    }
  };

  const handleSelfieDocumentoSave = async (nextState: Partial<DocumentoVO>, patches: Patch[], changes: Partial<DocumentoVO>) => {
    const resultAction = await dispatch(changeInvestidorSelfieAutentica({coContaUsuario, selfie: changes}));
    if (changeInvestidorSelfieAutentica.fulfilled.match(resultAction)) {
      const response = unwrapResult(resultAction);
      enqueueSnackbar('Selfie atualizada com sucesso!', {
        variant: 'success',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });
      setState({...state, documentoSelfieAutentica: {...changes, ...response}} as InvestidorVO);
      setStep(InvestidorEditSteps.REVISAO);
      scrollTo({top: 0, behavior: "smooth"})
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível atualizar a selfie. Tente novamente."))
    }
  };

  const handleEnderecoSave = async (nextState: DeepPartial<EnderecoVO>, patches: Patch[], changes: DeepPartial<EnderecoVO>) => {
    const resultAction = await dispatch(patchInvestidorEndereco({coContaUsuario, changes}));
    if (patchInvestidorEndereco.fulfilled.match(resultAction)) {
      enqueueSnackbar('Endereço alterado com sucesso!', {
        variant: 'success',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });
      setState({...state, endereco: {...nextState}} as InvestidorVO);
      setStep(InvestidorEditSteps.REVISAO);
      scrollTo({top: 0, behavior: "smooth"})
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível atualizar o endereço. Tente novamente."))
    }
  };

  const handleComprovanteResidenciaSave = async (nextState: Partial<DocumentoVO>, patches: Patch[], changes: Partial<DocumentoVO>) => {
    const resultAction = await dispatch(changeInvestidorComprovanteResidencia({coContaUsuario, comprovante: changes}));
    if (changeInvestidorComprovanteResidencia.fulfilled.match(resultAction)) {
      const response = unwrapResult(resultAction);
      enqueueSnackbar('Comprovante residência atualizado com sucesso!', {
        variant: 'success',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });
      setState({...state, documentoComprovanteResidencia: {...changes, ...response}} as InvestidorVO);
      setStep(InvestidorEditSteps.REVISAO);
      scrollTo({top: 0, behavior: "smooth"})
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível atualizar o comprovante de residência. Tente novamente."))
    }
  };

  const handleDadosBancariosSave = async (nextState: DeepPartial<DadoBancarioVO>, patches: Patch[], changes: DeepPartial<DadoBancarioVO>) => {
    const resultAction = await dispatch(patchInvestidorDadosBancarios({coContaUsuario, changes}));
    if (patchInvestidorDadosBancarios.fulfilled.match(resultAction)) {
      enqueueSnackbar('Dados Bancários alterados com sucesso!', {
        variant: 'success',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });
      setState({...state, dadoBancario: {...nextState}} as InvestidorVO);
      setStep(InvestidorEditSteps.REVISAO);
      scrollTo({top: 0, behavior: "smooth"})
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível atualizar os Dados Bancários. Tente novamente."))
    }
  };


  const handleDadosBasicosEdit = () => setStep(InvestidorEditSteps.DADOS_BASICOS)
  const handleSelfieDocumentoEdit = () => setStep(InvestidorEditSteps.SELFIE_DOCUMENTO)
  const handleEnderecoEdit = () => setStep(InvestidorEditSteps.ENDERECO)
  const handleComprovanteEnderecoEdit = () => setStep(InvestidorEditSteps.COMPROVANTE_ENDERECO)
  const handleDadosBancariosEdit = () => setStep(InvestidorEditSteps.DADOS_BANCARIOS)

  const handleBack = () => {
    setStep(InvestidorEditSteps.REVISAO)
  }

  return (
    <PageTemplate
      metaTags={{
        title: "Gottaup.com | Cadastro do Investidor",
        description: "Alterar cadastro do investidor"
      }}
      menuDesktop
      headerMobile={{
        title: "Cadastro do Investidor",
        leftButtons: (
          <IconButton edge="start" color="inherit" component={Link} to={from}>
            <ArrowBackIcon/>
          </IconButton>
        )
      }}
      headerDesktop={{
        title: "Cadastro do Investidor",
        breadcrumbs: [
          {label: "Home", to: '/', icon: HomeIcon},
          {label: "Cadastro do Investidor"}
        ]
      }}
    >

      <Container component="section" style={{marginTop: 16}}>


        {requestState.pending &&
        <Loading text="Carregando Formulário"/>
        }

        {requestState.error &&
        <ErrorEmptyState
          error={requestState.error}
          defaultDescription="Não foi possível carregar cadastro do investidor"
          onTryAgainClick={() => dispatch(findInvestidorByContaUsuario({coContaUsuario}))}
        />
        }

        {requestState.data && state &&


        <Grid container spacing={2}>
          {isDesktop &&
          <Grid item xs={12} md={2}>
            <Stepper nonLinear activeStep={step} orientation={isDesktop ? "vertical" : "horizontal"}
                     alternativeLabel={!isDesktop} variant="outlined">
              <Step>
                <StepButton onClick={handleDadosBasicosEdit} icon=" " style={{textAlign: 'left'}}>Dados
                  Básicos</StepButton>
              </Step>
              <Step>
                <StepButton onClick={handleSelfieDocumentoEdit} icon=" ">Selfie</StepButton>
              </Step>
              <Step>
                <StepButton onClick={handleEnderecoEdit} icon=" ">Endereço</StepButton>
              </Step>
              <Step>
                <StepButton onClick={handleComprovanteEnderecoEdit} icon=" ">Comprovante</StepButton>
              </Step>
              <Step>
                <StepButton onClick={handleDadosBancariosEdit} icon=" " style={{textAlign: 'left'}}>Dados
                  Bancários</StepButton>
              </Step>
            </Stepper>

          </Grid>
          }
          <Grid item xs={12} md={10}>
            {step == InvestidorEditSteps.DADOS_BASICOS &&
            <InvestidorDadosBasicosForm
              investidor={state}
              submitText="Salvar"
              formMode={InvestidorDadosBasicosFormMode.EDIT}
              onSubmit={handleDadosBasicosSave}
              onBack={handleBack}/>
            }

            {step == InvestidorEditSteps.SELFIE_DOCUMENTO &&
            <InvestidorSelfieDocumentoForm
              documento={state.documentoSelfieAutentica || {}}
              onSubmit={handleSelfieDocumentoSave}
              submitText="Salvar"
              formMode={InvestidorSelfieDocumentoFormMode.EDIT}
              onBack={handleBack}/>
            }

            {step == InvestidorEditSteps.ENDERECO &&
            <EnderecoForm
              endereco={state.endereco || {}}
              onSubmit={handleEnderecoSave}
              submitText="Salvar"
              formMode={EnderecoFormMode.CREATE}
              onBack={handleBack}/>
            }

            {step == InvestidorEditSteps.COMPROVANTE_ENDERECO &&
            <InvestidorComprovanteEnderecoForm
              documento={state.documentoComprovanteResidencia || {}}
              onSubmit={handleComprovanteResidenciaSave}
              submitText="Salvar"
              formMode={InvestidorComprovanteEnderecoFormMode.EDIT}
              onBack={handleBack}/>
            }

            {step == InvestidorEditSteps.DADOS_BANCARIOS &&
            <DadosBancariosForm
              dadoBancario={state.dadoBancario || {}}
              onSubmit={handleDadosBancariosSave}
              submitText="Salvar"
              formMode={DadosBancariosFormMode.EDIT}
              onBack={handleBack}/>
            }


            {step == InvestidorEditSteps.REVISAO &&
            <InvestidorReviewEditCadastro
              investidor={state}
              onDadosBancariosEdit={handleDadosBancariosEdit}
              onDadosBasicosEdit={handleDadosBasicosEdit}
              onEnderecoEdit={handleEnderecoEdit}
              onComprovatenResidenciaEdit={handleComprovanteEnderecoEdit}
              onSelfieDocumentoEdit={handleSelfieDocumentoEdit}
            />
            }
          </Grid>
        </Grid>
        }

      </Container>

    </PageTemplate>
  )
}
import React, { useRef, useState, useEffect, ChangeEvent, FocusEvent } from 'react';
import { useForkRef } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import moment, { Moment } from 'moment';

interface FormDatePickerInput {
  required?: boolean;
  autoFocus?: boolean;
  name?: string;
  disabled?: boolean;
  label?: string;
  initialValue?: ParsableDate;
  helperText?: string;
  maxDate?: ParsableDate;
  minDate?: ParsableDate;
  format: string;
  fullWidth?: boolean;
  error?: string;
  inputRef?: React.Ref<any>;
  placeholder?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  value?: string;
}

export default function FormDatePickerInput(props: FormDatePickerInput) {

  const inputRef = useRef<HTMLInputElement>(null);
  const combinedRef = useForkRef(inputRef, props.inputRef || null);

  const [date, setDate] = useState<Moment | null>(null);

  useEffect(() => {
    if (inputRef.current && inputRef.current.value) {
      setDate(moment(inputRef.current.value, "L"))
    }
  }, [inputRef])

  const handleDatePickerChange = (newDate: Moment | null, newValue?: string | null | undefined) => {
    setDate(newDate);
    if (inputRef.current) {
      inputRef.current.value = newValue || '';
      if (newDate) {
        inputRef.current.dispatchEvent(new Event("blur")) // Incluído para suporte ao React Hook Form Uncontrolled
      } else {
        inputRef.current.dispatchEvent(new Event("change")) // Incluído para suporte ao React Hook Form Uncontrolled
      }
    }
  }

  return (
    <>
      <KeyboardDatePicker
        autoFocus={props.autoFocus}
        fullWidth={props.fullWidth != undefined ? props.fullWidth : true}
        required={props.required}
        name={props.name}
        disabled={props.disabled}
        value={date}
        inputValue={props.value}
        placeholder={props.placeholder}
        onChange={handleDatePickerChange}
        autoOk
        label={props.label}
        format={props.format}
        cancelLabel="CANCELAR"
        inputVariant="standard"
        maxDate={props.maxDate}
        minDate={props.minDate}
        maskChar=' '
        KeyboardButtonProps={{ edge: 'end' }}
        helperText={props.error || props.helperText}
        error={!!props.error}
        inputRef={combinedRef}
      />
    </>

  );
}

import DadoBancario, { DadoBancarioVO } from "./DadoBancario";
import Endereco, { EnderecoVO } from "./Endereco";
import ISODate from "./types/ISODate";
import Documento, { DocumentoVO } from "./Documento";
import { parse } from "date-fns";
import { EstadoCivil } from "./enums/EstadoCivil";
import { Sexo } from "./enums/Sexo";
import { SituacaoComprovanteResidencia } from "./enums/SituacaoComprovanteResidencia";
import { SituacaoSelfie } from "./enums/SituacaoSelfie";
import { NivelInvestidor } from "./enums/NivelInvestidor";
import ISODateTime from "./types/ISODateTime";
import ContaUsuario, { ContaUsuarioVO } from "./ContaUsuario";

export interface InvestidorVO {
  coContaUsuario: number;
  nuCpfCnpjInvestidor: string;
  dtNascimento: string;
  icEstadoCivil: EstadoCivil;
  coRg: string;
  noOrgaoEmissorRg: string;
  noProfissao: string;
  icSexo: Sexo;
  icNivelInvestidor: NivelInvestidor;
  nuTelefone: string;
  nuCelular: string;
  icPpe: boolean;
  documentoSelfieAutentica: DocumentoVO;
  icSituacaoSelfie: SituacaoSelfie;
  documentoComprovanteResidencia: DocumentoVO;
  icSituacaoComprovanteResidencia: SituacaoComprovanteResidencia;
  dadoBancario: DadoBancarioVO;
  endereco: EnderecoVO;
  contaUsuario: ContaUsuarioVO;
  qtInvestimentos: number;
  tsCadastro: string;

}


export default class Investidor {

    public coContaUsuario: number|undefined;
    public nuCpfCnpjInvestidor: string|undefined;
    public dtNascimento: ISODate|undefined;
    public icEstadoCivil: EstadoCivil|undefined;
    public coRg: string|undefined;
    public noOrgaoEmissorRg: string|undefined;
    public noProfissao: string|undefined;
    public icSexo: Sexo|undefined;
    public icNivelInvestidor: NivelInvestidor|undefined;
    public nuTelefone: string|undefined;
    public nuCelular: string|undefined;
    public icPpe: boolean|undefined;
    public documentoSelfieAutentica: Documento|undefined;
    public icSituacaoSelfie: SituacaoSelfie|undefined;
    public documentoComprovanteResidencia: Documento|undefined;
    public icSituacaoComprovanteResidencia: SituacaoComprovanteResidencia|undefined;
    public dadoBancario: DadoBancario|undefined;
    public endereco: Endereco|undefined;
    public contaUsuario: ContaUsuario|undefined;
    public qtInvestimentos: number|undefined;
    public tsCadastro: ISODateTime | undefined;

    constructor(obj: object|null = null) {
        if (obj) {
            Object.assign(this, obj);
            if(this.dadoBancario) this.dadoBancario = new DadoBancario(this.dadoBancario);
            if(this.endereco) this.endereco = new Endereco(this.endereco);
            if(this.contaUsuario) this.contaUsuario = new ContaUsuario(this.contaUsuario);
            if(this.dtNascimento) this.dtNascimento = new ISODate(String(this.dtNascimento))
            if(this.documentoSelfieAutentica) this.documentoSelfieAutentica = new Documento(this.documentoSelfieAutentica)
            if(this.documentoComprovanteResidencia) this.documentoComprovanteResidencia = new Documento(this.documentoComprovanteResidencia)
            if(this.tsCadastro) this.tsCadastro = new ISODateTime(String(this.tsCadastro))
            Object.seal(this); // Não permite alterar estrutura da classe
        }
    }

    get nuCpfCnpjInvestidorFormatado(): string {
        return this.nuCpfCnpjInvestidor ? this.nuCpfCnpjInvestidor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})$/g, '$1.$2.$3-$4') : '';
    }

    set nuCpfCnpjInvestidorFormatado(value: string) {
        const match = value.match(/\d+/g);
        this.nuCpfCnpjInvestidor = match ? match.join('') : undefined;
    }

    set dtNascimentoFormatado(value){
        this.dtNascimento = value?new ISODate(parse(value, 'dd/MM/yyyy', new Date())):undefined;
    }

    get dtNascimentoFormatado(){
        return this.dtNascimento?this.dtNascimento.toFormat('dd/MM/yyyy'):''
    }

    get nuTelefoneFormatado(): string {
        return this.nuTelefone ? this.nuTelefone.replace(/^(\d{2})(\d{4,5})(\d{4})$/g, '($1)$2-$3') : '';
    }

    set nuTelefoneFormatado(value: string) {
        const match = value.match(/\d+/g);
        this.nuTelefone = match ? match.join('') : undefined
    }

    set nuCelularFormatado(value: string) {
        const match = value.match(/\d+/g);
        this.nuCelular = match ? match.join('') : undefined;
    }

    get nuCelularFormatado(): string{
        return this.nuCelular ? this.nuCelular.replace(/^(\d{2})(\d{4,5})(\d{4})$/g, '($1)$2-$3') : '';
    }

    get tsCadastroFormatado() {
        return this.tsCadastro ? this.tsCadastro.toFormat('dd/MM/yyyy HH:mm') : ''
    }



}
import { Button, Grid } from '@material-ui/core';
import { applyPatches, Patch, produceWithPatches } from 'immer';
import moment, { Moment } from 'moment';
import React from 'react';
import { DeepPartial, useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { ValorCotaVO } from '../../../../../../../models/ValorCota';
import { ISO_MAX_DATE } from '../../../../../../../utils/datetime';
import { reverseFormatNumber } from '../../../../../../../utils/intl';
import { Patterns } from '../../../../../../../utils/patterns';
import ButtonProgress from '../../../../../../components/ButtonProgress';
import ConfirmLeavePage from '../../../../../../components/ComfirmLeavePage';
import { useDialog } from '../../../../../../components/dialog/PageServiceProvider';
import FormDatePickerInput from '../../../../../../components/form/inputs/DataPicker/FormDatePickerInput';
import FormInput from '../../../../../../components/FormInput';
import { ReaisMaskedInput } from '../../../../../../components/MaskedInputs';

export enum ValorCotaFormMode {
  CREATE,
  EDIT
}

interface ValorCotaFormData {
  dtInicio: string;
  dtFim: string;
  vrCota: string;
}

interface ValorCotaFormProps {
  valorCota?: ValorCotaVO;
  formMode: ValorCotaFormMode;
  dtInicioCreate?: Moment;
  onBack?: () => void;
  onSubmit: (nextState: DeepPartial<ValorCotaVO>, patches: Patch[], changes: DeepPartial<ValorCotaVO>) => Promise<void> | void;
}

const processValorCotaFormData = (valorCota: Partial<ValorCotaVO>, formData: ValorCotaFormData, intl: IntlShape) => {
  const [nextState, patches] = produceWithPatches(valorCota, draft => {
    draft.dtInicio = moment(formData.dtInicio, "L").format("YYYY-MM-DD");
    draft.dtFim = moment(formData.dtFim, "L").format("YYYY-MM-DD");
    draft.vrCota = reverseFormatNumber(intl, formData.vrCota);
  })
  const changes = applyPatches({}, patches) as Partial<ValorCotaVO>;
  return { nextState, patches, changes }
}

const generateFormDefaultValues = (valorCota: Partial<ValorCotaVO> = {}, dtInicioCreate: Moment | undefined, formMode: ValorCotaFormMode, intl: IntlShape): ValorCotaFormData => {
  console.log("generate", ValorCotaFormMode[formMode], valorCota, dtInicioCreate)
  if (formMode == ValorCotaFormMode.CREATE) {
    return {
      dtInicio: dtInicioCreate?.format("L") || moment().format("L"),
      dtFim: moment(ISO_MAX_DATE).format("L"),
      vrCota: '',
    }
  } else {
    return {
      dtInicio: moment(valorCota.dtInicio).format("L"),
      dtFim: '',
      vrCota: intl.formatNumber(valorCota.vrCota || 0, { style: "decimal", minimumFractionDigits: 2 }),
    }
  }
}


export const ValorCotaForm = ({ valorCota, formMode, dtInicioCreate, onBack, onSubmit }: ValorCotaFormProps) => {

  const intl = useIntl();

  const dialog = useDialog();

  const { register, handleSubmit, errors, formState: { isSubmitting, dirty } } = useForm<ValorCotaFormData>({
    mode: "onBlur",
    defaultValues: generateFormDefaultValues(valorCota, dtInicioCreate, formMode, intl)
  });

  const handleValorCotaFormSubmit = async (formData: ValorCotaFormData) => {

    if (formMode == ValorCotaFormMode.CREATE) {
      const confirmed = await dialog({
        title: "Confirma atualização do valor de cota?",
        description: "Ao confirmar o novo valor será salvo e todos os investimentos atrelados terão suas renumerações atualizadas. Se cancelar, nenhum dado será salvo no servidor.",
        confirmOption: "CONFIRMAR",
        cancelOption: "CANCELAR"
      })
      if (confirmed) {
        const { nextState, patches, changes } = processValorCotaFormData({}, formData, intl);
        onSubmit(nextState, patches, changes)
      }
    }
    if (formMode == ValorCotaFormMode.EDIT && valorCota) {
      const confirmed = await dialog({
        title: "Confirma encerramento da vigência do valor da cota?",
        description: "Ao confirmar as informações serão salvas. Se cancelar, nenhum dado será salvo no servidor.",
        confirmOption: "CONFIRMAR",
        cancelOption: "CANCELAR"
      })
      if (confirmed) {
        const { nextState, patches, changes } = processValorCotaFormData(valorCota, formData, intl);
        onSubmit(nextState, patches, changes)
      }
    }
  }

  return (
    <form id="formValorCota" noValidate autoComplete="off" onSubmit={handleSubmit(handleValorCotaFormSubmit)}>
      <ConfirmLeavePage
        when={dirty}
        title="Tem certeza que deseja deixar a página sem salvar as alterações?"
        content="Ao confirmar os dados alterados não serão salvos. Se cancelar, poderá continuar a edição."
      />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={4}>
          <FormInput
            required
            disabled
            name="dtInicio"
            label="Data Início"
            inputRef={register}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
            
          <FormDatePickerInput
            name="dtFim"
            required
            autoFocus={formMode == ValorCotaFormMode.EDIT}
            disabled={formMode == ValorCotaFormMode.CREATE}
            label="Data Fim"
            minDate={valorCota?.dtInicio}
            maxDate={moment()}
            error={errors.dtFim?.message?.toString()}
            format="DD/MM/YYYY"
            inputRef={register({
              required: 'Informe a Data Fim',
              pattern: { value: Patterns.data, message: 'Formato DD/MM/AAAA' },
              validate: {
                validDate: value => moment(value, "L").isValid() || "Data Inválida!",
                minDate: value => moment(value, "L").isSameOrAfter(valorCota?.dtInicio) || "Data não pode ser inferior a data de início",
                maxDate: value => (formMode == ValorCotaFormMode.EDIT ? moment(value, "L").isSameOrBefore(moment()) : true) || "Data não pode ser superior a hoje"
              }
            })} /> 
          
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <FormInput
            name="vrCota"
            autoFocus={formMode == ValorCotaFormMode.CREATE}
            disabled={formMode == ValorCotaFormMode.EDIT}
            required
            label="Valor Cota"
            placeholder="0,00"
            maxLength={9}
            error={errors.vrCota?.message?.toString()}
            startAdornment="R$"
            inputComponent={ReaisMaskedInput as any}
            inputRef={register({
              required: "Informe o Valor da Cota",
              pattern: { value: Patterns.moeda, message: "Formato ??.??9,99" },
              validate: {
                min: value => reverseFormatNumber(intl, value) >= 1.00 || 'No mínimo R$ 1,00'
              }
            })}
          />
        </Grid>

        <Grid item container justify="flex-end" spacing={2}>
          {onBack &&
            <Grid item>
              <Button
                id="btnVoltar"
                variant="text"
                color="default"
                onClick={onBack}
              >
                Voltar
                </Button>
            </Grid>
          }
          <Grid item>
            <ButtonProgress
              id="btnSubmit"
              loading={isSubmitting}
              disabled={Object.values(errors).length > 0 || (formMode == ValorCotaFormMode.EDIT && !dirty)}
              variant="contained"
              color="primary"
              type="submit">
              Salvar
              </ButtonProgress>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

export interface EnderecoVO {
  nuEndereco: number;
  noMunicipio: string;
  noUf: string;
  coCep: string;
  noLogradouro: string;
  nuLogradouro: number;
  deComplemento?: string|null;
  noBairro: string;
  tsCadastro: Date;
}

export default class Endereco {

    public nuEndereco: number|undefined;
    public noMunicipio: string|undefined;
    public noUf: string|undefined;
    public coCep: string|undefined;
    public noLogradouro: string|undefined;
    public nuLogradouro: string|undefined;
    public deComplemento: string|undefined;
    public noBairro: string|undefined;
    public tsCadastro: Date|undefined;

    constructor(obj: object|null = null) {
        if (obj) Object.assign(this, obj);
        Object.seal(this); // Não permite alterar estrutura da classe
    }
    
    get coCepFormatado(): string {
        return this.coCep ? this.coCep.replace(/(\d{5})(\d{3})$/g, '$1-$2') : '';
    }

    set coCepFormatado(value: string){
        if(value){
            const match = value.match(/\d+/g);
            this.coCep = match ? match.join('') : undefined;
        } else {
            this.coCep = undefined;
        }

    }

}


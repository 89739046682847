import { Box, Collapse, Paper, Typography } from '@material-ui/core';
import { Alert, AlertProps, AlertTitle } from '@material-ui/lab';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import LoginService from '../../../../../../services/LoginService';
import ButtonProgress from '../../../../../components/ButtonProgress';
import FormInput from '../../../../../components/FormInput';

interface InformaEmailStepFormData {
    noEmail: string;
}

interface InformaEmailStepProps {
    onComplete: (noEmail: string) => void;
}

export default function InformaEmailStep(props: InformaEmailStepProps) {

    const { register, handleSubmit, errors, formState: { isSubmitting } } = useForm<InformaEmailStepFormData>();

    const [alert, setAlert] = useState<AlertProps | undefined>(undefined);

    const enviaCodigoVerificaoEmail = async (formData: InformaEmailStepFormData) => {
        try {
            await new LoginService().resetSenhaEnvioToken(formData.noEmail);
            props.onComplete(formData.noEmail);
        } catch (apiexception) {
            setAlert({
                severity: 'error',
                children: <>
                    <AlertTitle>{apiexception.message}</AlertTitle>
                    {apiexception.errorJson.traceid && `Traceid: ${apiexception.errorJson.traceid}`}
                </>
            })
        }

    }

    return (
        <Paper variant="outlined" style={{ padding: 20 }}>

            <form noValidate onSubmit={handleSubmit(enviaCodigoVerificaoEmail)}>
                <Box mt={1}>
                    <Typography variant="body1" paragraph align="center"><strong>Esqueceu sua senha ou conta bloqueada?</strong></Typography>
                    <Typography variant="body1" align="justify">Para iniciar o processo de redefinição de sua senha informe seu email abaixo.</Typography>
                </Box>

                <Box display="flex" justifyContent="center" mt={2}>
                    <FormInput
                        required
                        name="noEmail"
                        label="Email"
                        helperText=" "
                        placeholder="usuario@domínio.com"
                        error={errors.noEmail?.message?.toString()}
                        inputRef={register({
                            required: 'Informe o email',
                            pattern: { value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/, message: 'Email em formato inválido' },
                            maxLength: 100
                        })}
                    />
                </Box>

                <Box display="flex" justifyContent="center" mt={2}>
                    <ButtonProgress id="btnProximo" loading={isSubmitting} variant="contained" size="large" color="secondary" type="submit">
                        Continuar
                    </ButtonProgress>
                </Box>
                <Box mt={2}>
                    <Collapse in={alert && !alert.hidden} onExited={() => setAlert(undefined)}>
                        {alert && <Alert id="msgErro" onClose={() => setAlert({ ...alert, hidden: true })} {...alert} />}
                    </Collapse>
                </Box>

            </form>
        </Paper>

    )

}
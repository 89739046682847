import React, {Fragment, useRef, useState} from 'react';
import {Button, createStyles, Grid, Hidden, makeStyles, Paper, Theme} from '@material-ui/core';
import {blue, green, red} from '@material-ui/core/colors';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Investimento from '../../../../../models/Investimento';
import {ConfirmDialog, ConfirmDialogProps} from '../../../../components/ConfirmDialog';
import DownloadBox from '../../../../components/DownloadBox';
import {SituacaoInvestimento} from '../../../../../models/enums/SituacaoInvestimento';
import {MotivoCancelamentoInvestimento} from '../../../../../models/enums/MotivoCancelamentoInvestimento';
import TextInput from '../../../../components/TextInput';
import {FieldOutput} from "../../../../components/form/output/FieldOutput";


const useStyles = makeStyles((theme: Theme) => createStyles({
  form: {
    marginBottom: 0
  },
  actions: {
    justifyContent: 'flex-end'
  },
  documentoContainer: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 400,
    },
  },
  listItem: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(3, 2),
  },
}), { name: 'AprovacaoDepositosReservasItem' });


interface Props {
  reserva: Investimento;
  onCancelarReservaSubmit: (coContaUsuario: number, coInvestimento: number, motivoCancelamento: MotivoCancelamentoInvestimento) => void;
  onAprovarReservaSubmit: (coContaUsuario: number, coInvestimento: number) => void;
}

export default function AprovacaoDepositosReservasItem(props: Props) {

  const classes = useStyles();
  const { reserva } = props;
  const [dialog, setDialog] = useState<ConfirmDialogProps | undefined>(undefined);
  const motivoCancelamento = useRef<MotivoCancelamentoInvestimento | undefined>(undefined);

  const getSituacaoInvestimentoColor = (situacao: SituacaoInvestimento)=>{
    switch (situacao){
      case SituacaoInvestimento.Aprovado: return green[500];
      case SituacaoInvestimento.Cancelado: return red[600];
      case SituacaoInvestimento.Reserva: return blue[500]
    }
  }

  const confirmaAprovacao = () => {
    setDialog({
      open: true,
      title: `Confirma o depósito de R$ ${reserva.vrInvestimentoFormatado} desta reserva?`,
      content: "Ao confirmar o depósito da reserva será confirmado. Se cancelar, nenhuma operação será efetuada.",
      onConfirm: () => {
        setDialog(undefined); // Fecha dialog
        props.onAprovarReservaSubmit(reserva.contaUsuario?.coContaUsuario||0, reserva.coInvestimento||0); //chama evento no pai para persistir
      },
      onCancel: () => setDialog(undefined)
    });

  }

  const confirmaCancelamento = () => {
    const FormMotivoCancelamentoDialog = (formSubmitted: boolean) => {
      return (
        <TextInput
          required
          label="Motivo do Cancelamento"
          onValidInput={coMotivo => motivoCancelamento.current = parseInt(coMotivo)}
          formSubmitted={formSubmitted}
          validationMessages={{
            invalid: 'Informe o Motivo de Cancelamento'
          }}
          select
        >
          <option value="" />
          {Object.keys(MotivoCancelamentoInvestimento).filter(key => !isNaN(Number(key))).map(key => (
            <option key={key} value={key}>{MotivoCancelamentoInvestimento[Number(key)]}</option>
          ))}
        </TextInput>
      )
    }
    setDialog({
      open: true,
      title: "Confirma a cancelamento desta reserva?",
      content: "Para confirmar o cancelamento informe o motivo do cancelamento.",
      form: FormMotivoCancelamentoDialog,
      onConfirm: () => {
        setDialog(undefined); // Fecha dialog
        props.onCancelarReservaSubmit(reserva.contaUsuario?.coContaUsuario||0, reserva.coInvestimento||0, motivoCancelamento.current||0); //chama evento no pai para persistir
      },
      onCancel: () => setDialog(undefined)
    });
  }


  return (
    <Fragment>
      {dialog && <ConfirmDialog {...dialog} />}

      <Paper key={reserva.coInvestimento} className={classes.listItem}>
        <Grid container alignItems="center" spacing={2}>

          <Grid item xs={6} sm={4} md={2}>
            <FieldOutput label="Data Reserva" value={reserva.dhReservaFormatado} />
          </Grid>

          <Grid item xs={6} sm={4} md={2}>
            <FieldOutput label="Valor" value={`R$ ${reserva.vrInvestimentoFormatado}`}/>
          </Grid>

          <Grid item xs={6} sm={4} md={2}>
            <FieldOutput label="CPF" value={reserva.contaUsuario?.investidor?.nuCpfCnpjInvestidorFormatado||''}/>
          </Grid>

          <Grid item xs={6} sm={4} md={2}>
            <FieldOutput label="Investidor" value={reserva.contaUsuario?.noUsuario || ''}/>
          </Grid>

          <Grid item xs={6} sm={4} md={2}>
            <FieldOutput
              label="Situação"
              value={SituacaoInvestimento[reserva.situacaoInvestimento || 0]}
              color={getSituacaoInvestimentoColor(reserva.situacaoInvestimento || 0)}
              />
          </Grid>

          <Grid item xs={6} sm={4} md={2}>
            {reserva.situacaoInvestimento == SituacaoInvestimento.Cancelado &&
              <FieldOutput label="Motivo Cancelamento" value={MotivoCancelamentoInvestimento[reserva.motivoCancelamentoInvestimento||0]} />
            }
          </Grid>



          <Grid item xs={12} sm={6} md={4}>
            {(reserva.documentoComprovanteDeposito && reserva.documentoComprovanteDeposito.documentoURL)
              ? <DownloadBox name="Comprovante" url={reserva.documentoComprovanteDeposito.documentoURL} icon="file" />
              : <FieldOutput label="Comprovante de Depósito" value="Upload Pendente" color={red[600]} />
            }

          </Grid>

          <Hidden mdUp xsDown>
            <Grid item sm={6}>
            </Grid>
          </Hidden>



          <Grid item xs={6} sm={4} md={2}>
            {reserva.situacaoInvestimento == SituacaoInvestimento.Aprovado &&
              <FieldOutput label="Data Aprovação" value={reserva.dhAprovacaoFormatado} />
            }
            {reserva.situacaoInvestimento == SituacaoInvestimento.Cancelado &&
              <FieldOutput label="Data Cancelamento" value={reserva.dhCancelamentoFormatado} />
            }
          </Grid>


          <Grid item xs={6} sm={4} md={2}>
            {reserva.situacaoInvestimento == SituacaoInvestimento.Aprovado &&
              <FieldOutput label="Aprovado por" value={reserva.usuarioAprovacao?.noUsuario} />
            }
            {reserva.situacaoInvestimento == SituacaoInvestimento.Cancelado &&
              <FieldOutput label="Cancelado por" value={reserva.usuarioCancelamento?.noUsuario} />
            }
          </Grid>


          <Grid item xs={12} sm={12} md={4}>
            <Grid container justify="flex-end" spacing={2}>
              {(reserva.situacaoInvestimento == SituacaoInvestimento.Reserva || reserva.situacaoInvestimento == SituacaoInvestimento.Aprovado) &&
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={confirmaCancelamento}
                    startIcon={<CloseIcon />}>
                    Cancelar
                  </Button>
                </Grid>
              }
              {(reserva.situacaoInvestimento == SituacaoInvestimento.Reserva || reserva.situacaoInvestimento == SituacaoInvestimento.Cancelado) &&
                <Grid item>

                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={confirmaAprovacao}
                    startIcon={<CheckIcon />}>
                    Aprovar
                  </Button>

                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>

      </Paper>

    </Fragment >

  )
}
import { combineReducers } from "redux";
import contaUsuariosSlice from "./slices/entities/contaUsuariosSlice";
import estatisticasSlice from "./slices/entities/estatisticasSlice";
import empresasSlice from "./slices/entities/empresasSlice";
import projetosSlice from "./slices/entities/projetosSlice";
import taxaJurosSlice from "./slices/entities/taxaJurosSlice";
import valoresCotasSlice from "./slices/entities/valoresCotasSlice";
import perguntaForumProjetoSlice from "./slices/entities/perguntasForumProjetoSlice";
import cepSlice from "./slices/entities/cepSlice";
import userSlice from "./slices/userSlice";
import captacoesSlice from "./slices/entities/captacoesSlice";
import investidoresSlice from "./slices/entities/investidoresSlice";

const entitiesReducer = combineReducers({
  contaUsuarios: contaUsuariosSlice,
  estatisticas: estatisticasSlice,
  empresas: empresasSlice,
  projetos: projetosSlice,
  taxaJuros: taxaJurosSlice,
  cep: cepSlice,
  valoresCotas: valoresCotasSlice,
  captacoes: captacoesSlice,
  perguntasForumProjeto: perguntaForumProjetoSlice,
  investidores: investidoresSlice
})

export const rootReducer = combineReducers({
  entities: entitiesReducer,
  user: userSlice
});

export type RootState = ReturnType<typeof rootReducer>

//const epicMiddleware = createEpicMiddleware<Action, Action, RootState, void>();
//const store = createStore(rootReducer, preloadedState, applyMiddleware(thunk,logger))
//epicMiddleware.run(rootEpic);


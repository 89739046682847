import { Button } from '@material-ui/core';
import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../browser';
import ISODateTime from '../../../models/types/ISODateTime';
import { fetchEstatisticas, trackFetchEstatisticas } from '../../../stores/slices/entities/estatisticasSlice';
import { BannerProps } from '../../components/Banner';
import PageFooter from '../../layout/PageFooter';
import PageTemplate from '../../layout/PageTemplate';
import CapteConosco from './components/CapteConosco';
import LandingHero from './components/LandingHero';
import NossaComunidade from './components/NossaComunidade';
import ProductHelp from './components/ProductHelp';
import ProductHowItWorks from './components/ProductHowItWorks';
import ProductValues from './components/ProductValues';



export const HomePage = () => {

  const { pending: loading, data: estatisticas, error } = useSelector(trackFetchEstatisticas);

  const dispatch = useDispatch<AppDispatch>()

  const [banner, setBanner] = useState<BannerProps | undefined>(undefined);

  useEffect(() => {
    const promise = dispatch(fetchEstatisticas());
    return () => promise && promise.abort();
  }, [dispatch]);

  useEffect(() => {
    setBanner(error ? {
      text: `Erro ao buscar estatísticas: ${error.message}`,
      timestamp: error.timestamp ? new ISODateTime(error.timestamp) : undefined,
      traceId: error.traceid,
      icon: <SignalWifiOffIcon />,
      buttons: <Button onClick={() => { setBanner(undefined); dispatch(fetchEstatisticas()) }} color="primary">Tentar Novamente</Button>
    } : undefined)
  }, [dispatch, error]);

  return (
    <PageTemplate
      metaTags={{
        title: "Gottaup.com | Investimentos Coletivos",
        description: "Faça parte de grupos de investimentos para contribuir com o crescimento de negócios e participe dos resultados gerados."
      }}
      loading={{ isLoading: loading }}
      menuDesktop
      menuMobile
      banner={banner}
    >

      <LandingHero />

      <ProductValues />

      <NossaComunidade estatisticas={estatisticas} />

      <ProductHowItWorks />

      <CapteConosco />

      <ProductHelp />

      <PageFooter />

    </PageTemplate>
  )
}

export const HomePageLoadDataSSR = () => fetchEstatisticas();
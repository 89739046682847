import { Box, Button, Grid, Link, Paper, Typography } from '@material-ui/core';
import { applyPatches, Patch, produceWithPatches } from 'immer';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { DeepPartial } from 'redux';
import { AppDispatch } from '../../../../../../../browser';
import { ProjetoVO } from '../../../../../../models/Projeto';
import { fetchEmpresasCombo, trackFetchEmpresasCombo } from '../../../../../../stores/slices/entities/empresasSlice';
import { Patterns } from '../../../../../../utils/patterns';
import ButtonProgress from '../../../../../components/ButtonProgress';
import ConfirmLeavePage from '../../../../../components/ComfirmLeavePage';
import FormInput from '../../../../../components/FormInput';
import { LoadingText } from '../../../../../components/progress/LoadingText';

export enum ProjetoDadosBasicosFormMode {
  CREATE,
  EDIT
}

interface ProjetoDadosBasicosFormData {
  nuEmpresa: string;
  noProjeto: string;
  deProjeto: string;
  noPermalink: string;
}

const generateFormDefaultValues = (projeto: DeepPartial<ProjetoVO>): ProjetoDadosBasicosFormData => {
  return {
    nuEmpresa: projeto.empresa?.nuEmpresa?.toString() || '',
    noProjeto: projeto.noProjeto || '',
    deProjeto: projeto.deProjeto || '',
    noPermalink: projeto.noPermalink || ''
  }
}


interface ProjetoDadosBasicosFormProps {
  projeto: DeepPartial<ProjetoVO>;
  onSubmit: (nextState: DeepPartial<ProjetoVO>, patches: Patch[], changes: DeepPartial<ProjetoVO>) => Promise<void> | void;
  onBack?: () => void;
  submitText: string;
  formMode: ProjetoDadosBasicosFormMode;
}

export const ProjetoDadosBasicosForm = ({ projeto, onSubmit, onBack, submitText, formMode }: ProjetoDadosBasicosFormProps) => {

  const dispatch = useDispatch<AppDispatch>()

  const empresasRequestState = useSelector(trackFetchEmpresasCombo);

  const { register, handleSubmit, errors, formState: { isSubmitting, dirty } } = useForm<ProjetoDadosBasicosFormData>({
    mode: "onBlur",
    defaultValues: generateFormDefaultValues(projeto)
  });

  useEffect(() => {
    const promiseEmpresas = dispatch(fetchEmpresasCombo());
    return () => promiseEmpresas.abort()
  }, [dispatch]);

  const handleFormSubmit = async (formData: ProjetoDadosBasicosFormData) => {
    const [nextState, patches] = produceWithPatches(projeto, draft => {
      if (draft.empresa) {
        draft.empresa.nuEmpresa = formData.nuEmpresa ? Number(formData.nuEmpresa) : undefined;
      } else {
        draft.empresa = { nuEmpresa: formData.nuEmpresa ? Number(formData.nuEmpresa) : undefined }
      }
      draft.noProjeto = formData.noProjeto;
      draft.deProjeto = formData.deProjeto;
      draft.noPermalink = formData.noPermalink;
    })
    const changes = applyPatches({}, patches) as Partial<ProjetoVO>;
    await onSubmit(nextState, patches, changes)
  }

  return (
    <form id="formDadosBasicosProjeto" noValidate autoComplete="off" onSubmit={handleSubmit(handleFormSubmit)}>
      <ConfirmLeavePage
        when={dirty}
        title="Tem certeza que deseja deixar a página sem salvar as alterações?"
        content="Ao confirmar os dados alterados não serão salvos. Se cancelar, poderá continuar a edição."
      />
      <Paper variant="outlined" square >
        <Box p={2}>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <Typography variant="h6">Dados Básicos</Typography>
            </Grid>


            <Grid item xs={12} sm={6} lg={4}>
              <FormInput
                required
                autoFocus
                characterCounter
                name="noProjeto"
                label="Nome do Projeto"
                maxLength={100}
                error={errors.noProjeto?.message?.toString()}
                inputRef={register({
                  required: 'Informe o Nome do Projeto',
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormInput
                required
                name="noPermalink"
                startAdornment="/investir/"
                characterCounter
                label="Permalink"
                helperText="Formato [a-z0-9-]"
                maxLength={100}
                error={errors.noPermalink?.message?.toString()}
                inputRef={register({
                  required: 'Informe o Permalink',
                  pattern: { value: Patterns.permalink, message: 'Formato [a-z0-9-]' },
                })}
              />

            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <FormInput
                required
                disabled={formMode == ProjetoDadosBasicosFormMode.EDIT || !empresasRequestState.data}
                helperText={empresasRequestState.pending?<LoadingText text="Carregando empresas" />:undefined}
                name="nuEmpresa"
                label="Empresa"
                error={
                  empresasRequestState.error
                    ? <Link component="button" onClick={() => dispatch(fetchEmpresasCombo())}>Erro ao Carregar. Tentar Novamente</Link>
                    : errors.nuEmpresa?.message?.toString()
                }
                inputRef={register({
                  required: 'Selecione uma empresa'
                })}
                select
              >
                
                <option value="" />
                {empresasRequestState.data?.map(empresa => (
                  <option key={empresa.nuEmpresa} value={empresa.nuEmpresa ? empresa.nuEmpresa.toString() : ''}>{empresa.noFantasia}</option>
                ))}
              </FormInput>
            </Grid>

            <Grid item xs={12} sm={12} lg={12}>
              <FormInput
                required
                name="deProjeto"
                multiline
                rows={5}
                characterCounter
                label="Descrição do Projeto"
                maxLength={1000}
                error={errors.deProjeto?.message?.toString()}
                inputRef={register({
                  required: 'Informe uma Descrição para o Projeto',
                })}
              />

            </Grid>
            <Grid item container justify="flex-end" spacing={2}>
              {onBack &&
                <Grid item>
                  <Button
                    id="btnVoltar"
                    variant="text"
                    color="default"
                    onClick={onBack}
                  >
                    Voltar
              </Button>
                </Grid>
              }
              <Grid item>
                <ButtonProgress
                  id="btnSubmit"
                  loading={isSubmitting}
                  disabled={Object.values(errors).length > 0 || (formMode == ProjetoDadosBasicosFormMode.EDIT && !dirty)}
                  variant="contained"
                  color="primary"
                  type="submit">
                  {submitText}
                </ButtonProgress>
              </Grid>
            </Grid>


          </Grid>


        </Box>
      </Paper>
    </form>
  )
}

import { Box, Grid, Paper, Typography, Button } from '@material-ui/core';
import { applyPatches, Patch, produceWithPatches } from 'immer';
import React from 'react';
import { useForm } from 'react-hook-form';
import { EmpresaVO } from '../../../../../../models/Empresa';
import { Formatters } from '../../../../../../utils/formatters';
import { Patterns } from '../../../../../../utils/patterns';
import { Validators } from '../../../../../../utils/validators';
import ButtonProgress from '../../../../../components/ButtonProgress';
import ConfirmLeavePage from '../../../../../components/ComfirmLeavePage';
import FormInput from '../../../../../components/FormInput';
import { CNPJMaskedInput, TelefoneMaskedInput } from '../../../../../components/MaskedInputs';
import { DeepPartial } from 'redux';

export enum EmpresaDadosBasicosFormMode {
  CREATE,
  EDIT
}

interface EmpresaDadosBasicosFormData {
  nuCnpjEmpresa: string;
  noFantasia: string;
  noRazaoSocial: string;
  nuTelefone: string;
  nuCelular: string;
  noEmail: string;
  noUrlSite: string;
}

const generateFormDefaultValues = (empresa: DeepPartial<EmpresaVO>): EmpresaDadosBasicosFormData => {
  return {
    nuCnpjEmpresa: Formatters.cnpj.format(empresa.nuCnpjEmpresa),
    noFantasia: empresa.noFantasia || '',
    noRazaoSocial: empresa.noRazaoSocial || '',
    nuTelefone: Formatters.telefone.format(empresa.nuTelefone),
    nuCelular: Formatters.telefone.format(empresa.nuCelular),
    noEmail: empresa.noEmail || '',
    noUrlSite: empresa.noUrlSite || '',
  }
}


interface EmpresaDadosBasicosFormProps {
  empresa: DeepPartial<EmpresaVO>;
  onSubmit: (nextState: DeepPartial<EmpresaVO>, patches: Patch[], changes: DeepPartial<EmpresaVO>) => Promise<void> | void;
  onBack?: () => void;
  submitText: string;
  formMode: EmpresaDadosBasicosFormMode;
}

export const EmpresaDadosBasicosForm = ({ empresa, onSubmit, onBack, submitText, formMode }: EmpresaDadosBasicosFormProps) => {

  const { register, handleSubmit, errors, formState: { isSubmitting, dirty } } = useForm<EmpresaDadosBasicosFormData>({
    mode: "onBlur",
    defaultValues: generateFormDefaultValues(empresa)
  });

  const handleFormSubmit = async (formData: EmpresaDadosBasicosFormData) => {
    const [nextState, patches] = produceWithPatches(empresa, draft => {
      draft.nuCnpjEmpresa = Formatters.cnpj.unformat(formData.nuCnpjEmpresa);
      draft.noFantasia = formData.noFantasia;
      draft.noRazaoSocial = formData.noRazaoSocial;
      draft.nuTelefone = Formatters.telefone.unformat(formData.nuTelefone);
      draft.nuCelular = Formatters.telefone.unformat(formData.nuCelular);
      draft.noEmail = formData.noEmail;
      draft.noUrlSite = formData.noUrlSite;
    })
    const changes = applyPatches({}, patches) as Partial<EmpresaVO>;
    await onSubmit(nextState, patches, changes)
  }


  return (
    <form id="formDadosBasicosEmpresa" noValidate autoComplete="off" onSubmit={handleSubmit(handleFormSubmit)}>
      <ConfirmLeavePage
        when={dirty}
        title="Tem certeza que deseja deixar a página sem salvar as alterações?"
        content="Ao confirmar os dados alterados não serão salvos. Se cancelar, poderá continuar a edição."
      />
      <Paper variant="outlined" square >
        <Box p={2}>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <Typography variant="h6">Dados Básicos</Typography>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <FormInput
                required
                autoFocus={formMode == EmpresaDadosBasicosFormMode.CREATE}
                disabled={formMode == EmpresaDadosBasicosFormMode.EDIT}
                name="nuCnpjEmpresa"
                label="CNPJ"
                placeholder="99.999.999/9999-99"
                pattern={Patterns.cnpj.source}
                inputComponent={CNPJMaskedInput as any}
                error={errors.nuCnpjEmpresa?.message?.toString()}
                inputRef={register({
                  required: 'Informe o CNPJ',
                  pattern: { value: Patterns.cnpj, message: 'Formato 99.999.999/9999-99' },
                  validate: {
                    isCnpj: value => Validators.isCNPJ(value) || "CNPJ Inválido"
                  }
                })}
              />

            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormInput
                required
                autoFocus={formMode == EmpresaDadosBasicosFormMode.EDIT}
                name="noFantasia"
                label="Nome Fantasia"
                maxLength={100}
                error={errors.noFantasia?.message?.toString()}
                inputRef={register({
                  required: 'Informe o Nome Fantasia',
                })}
              />

            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormInput
                required
                name="noRazaoSocial"
                label="Razão Social"
                maxLength={100}
                error={errors.noRazaoSocial?.message?.toString()}
                inputRef={register({
                  required: 'Informe o Nome Fantasia',
                })}
              />

            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormInput
                required
                name="nuTelefone"
                label="Telefone"
                placeholder="(99)?9999-9999"
                maxLength={14}
                inputComponent={TelefoneMaskedInput as any}
                error={errors.nuTelefone?.message?.toString()}
                inputRef={register({
                  required: 'Informe o Telefone',
                  pattern: { value: Patterns.telefone, message: 'Formato (99)?9999-9999' },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormInput
                required
                name="nuCelular"
                label="Celular"
                placeholder="(99)99999-9999"
                maxLength={14}
                inputComponent={TelefoneMaskedInput as any}
                error={errors.nuCelular?.message?.toString()}
                inputRef={register({
                  required: 'Informe o Celular',
                  pattern: { value: Patterns.celular, message: 'Formato (99)99999-9999' },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>

              <FormInput
                required
                type="email"
                name="noEmail"
                label="Email"
                placeholder="usuario@domínio.com"
                maxLength={100}
                error={errors.noEmail?.message?.toString()}
                inputRef={register({
                  required: 'Informe o Email',
                  pattern: { value: Patterns.email, message: 'Formato usuario@domínio.com' },
                })}
              />

            </Grid>
            <Grid item xs={12} sm={6} lg={4}>

              <FormInput
                type="url"
                name="noUrlSite"
                label="URL do Site"
                placeholder="http://dominio..."
                maxLength={100}
                error={errors.noUrlSite?.message?.toString()}
                inputRef={register({
                  pattern: { value: Patterns.url, message: 'Formato http://dominio..' },
                })}
              />

            </Grid>

            <Grid item container justify="flex-end" spacing={2}>
              {onBack &&
                <Grid item>
                  <Button
                    id="btnVoltar"
                    variant="text"
                    color="default"
                    onClick={onBack}
                  >
                    Voltar
              </Button>
                </Grid>
              }
              <Grid item>
                <ButtonProgress
                  id="btnSubmit"
                  loading={isSubmitting}
                  disabled={Object.values(errors).length > 0  || (formMode == EmpresaDadosBasicosFormMode.EDIT && !dirty)}
                  variant="contained"
                  color="primary"
                  type="submit">
                  {submitText}
                </ButtonProgress>
              </Grid>
            </Grid>


          </Grid>


        </Box>
      </Paper>
    </form>
  )
}

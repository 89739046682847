import { addDays, addMonths, differenceInDays, differenceInMonths } from "date-fns";
import { Area } from "react-easy-crop";

export interface ValidationMessages {
    invalidDateMessage?: string;
    minDateMessage?: string;
    maxDateMessage?: string;
    patternMismatch?: string;
    rangeOverflow?: string;
    rangeUnderflow?: string;
    stepMismatch?: string;
    tooLong?: string;
    tooShort?: string;
    typeMismatch?: string;
    valueMissing?: string;
    invalid: string;
}

export interface InputState {
    value: string | boolean;
    error: string;
    validationMessages: ValidationMessages;
}

export function getPathProperty(path: string, obj: any): any {
    return path.split('.').reduce(function (prev, curr) {
        return prev ? prev[curr] : null
    }, obj || self)
}

export function setPathProperty(path: string, obj: any, value: any): any {
    const pathArray = path.split('.');
    let schema = obj;
    const len = pathArray.length;
    for (let i = 0; i < len - 1; i++) {
        const elem = pathArray[i];
        if (!schema[elem]) {
            schema[elem] = {};
        }
        schema = schema[elem];
    }
    schema[pathArray[len - 1]] = value;
    return obj;
}

export function getPathPropertiesRecursive(path: string, obj: any): string[] {
    const result: string[] = [];
    for (const attribute in obj) {
        const currPath: string = path ? path + "." + attribute : attribute;
        if (typeof obj[attribute] === 'object') {
            result.push(...getPathPropertiesRecursive(currPath, obj[attribute]));
        } else {
            result.push(currPath);
        }
    }
    const proto = Object.getPrototypeOf(obj);
    for (const key of Object.getOwnPropertyNames(proto)) {
        const currPath: string = path + "." + key;
        const desc = Object.getOwnPropertyDescriptor(proto, key);
        if (desc && typeof desc.get === 'function') {
            result.push(currPath);
        }
    }
    return result;
}

export function getInputErrorMessage(input: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement, validationMessages: ValidationMessages): string {
    if (input.validity.customError) {
        return input.validationMessage; // Validação foi customizada
    } else if (input.validity.valueMissing && validationMessages.valueMissing) {
        return validationMessages.valueMissing;
    } else if (input.validity.tooLong && validationMessages.tooLong) {
        return validationMessages.tooLong;
    } else if (input.validity.tooShort && validationMessages.tooShort) {
        return validationMessages.tooShort;
    } else if (input.validity.rangeOverflow && validationMessages.rangeOverflow) {
        return validationMessages.rangeOverflow;
    } else if (input.validity.rangeUnderflow && validationMessages.rangeUnderflow) {
        return validationMessages.rangeUnderflow;
    } else if (input.validity.patternMismatch && validationMessages.patternMismatch) {
        return validationMessages.patternMismatch;
    } else {
        return validationMessages.invalid;
    }
}


export function dataURItoBlob(dataURI: string) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], {type: mimeString});
    return blob;
}

export function getBase64(file: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (reader.result) {
                resolve(reader.result.toString());
            } else {
                reject('erro ao ler arquivo');
            }
        };
        reader.onerror = error => reject(error);
    });
}

export function resizeImageKeepProportions(dataURL: string, maxWidth: number, maxHeight: number): Promise<string> {
    return new Promise((resolve, reject) => {

        const img = new Image();
        img.src = dataURL;
        img.onload = () => {
            const canvas = document.createElement("canvas");
            const srcWidth = img.width;
            const srcHeight = img.height;
            let width = srcWidth;
            let height = srcHeight;
            if (srcWidth > maxWidth || srcHeight > maxHeight) {
                const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
                width = srcWidth * ratio
                height = srcHeight * ratio
                console.log(srcWidth + 'X' + srcHeight + " >> " + width + 'X' + height);
            }
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            if (ctx) {
                // draw the img into canvas
                ctx.drawImage(img, 0, 0, width, height);
                resolve(ctx.canvas.toDataURL())
            } else {
                reject("Nao foi possível rederizar um canvas");
            }
        }



    });
}

export function encodeQueryParams(data: any) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
}


//TODO: Remove
function getRadianAngle(degreeValue: number) {
    return (degreeValue * Math.PI) / 180
}


//TODO: Remove
export async function getCroppedImg(imageSrc: string, pixelCrop: Area, rotation = 0) {
    const image = new Image();
    image.src = imageSrc;
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    const safeArea = Math.max(image.width, image.height) * 2

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea
    canvas.height = safeArea

    if (ctx) {
        // translate canvas context to a central location on image to allow rotating around the center.
        ctx.translate(safeArea / 2, safeArea / 2)
        ctx.rotate(getRadianAngle(rotation))
        ctx.translate(-safeArea / 2, -safeArea / 2)

        // draw rotated image and store data.
        ctx.drawImage(
            image,
            safeArea / 2 - image.width * 0.5,
            safeArea / 2 - image.height * 0.5
        )
        const data = ctx.getImageData(0, 0, safeArea, safeArea)

        // set canvas width to final desired crop size - this will clear existing context
        canvas.width = pixelCrop.width
        canvas.height = pixelCrop.height

        // paste generated rotate image with correct offsets for x,y crop values.
        ctx.putImageData(
            data,
            0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
            0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
        )

    }


    // As Base64 string
    return canvas.toDataURL();

    /* As a blob
    return new Promise(resolve => {
        canvas.toBlob(file => {
            resolve(URL.createObjectURL(file))
        }, 'image/jpeg')
    })
    */
}

export function reducerTotalNumber(accumulator: number, currentValue: number) {
    return accumulator + currentValue;
}

export function formatMoeda(value: number): string {
    return value.toFixed(2).replace('.', ',').replace(/\d(?=(\d{3})+,)/g, '$&.');
}

export function formatPorcentagem(value: number): string {
    return value.toFixed(4).replace('.', '').replace(/^(0*)(\d+)(\d{2})$/g, '$2,$3');
}

export function parseStringMoedaToNumber(value: string): number {
    return Number.parseFloat(value.replace(/\./g, '').replace(',', '.'));
}


export interface ResizeProps {
    x: number;
    y: number;
}

//TODO: Remove
export function isFileImage(file: File): boolean {
    return file.type.split('/')[0] === 'image';
}

export function* makeRangeIterator(start = 0, end = Infinity, step = 1) {
    let iterationCount = 0;
    for (let i = start; i < end; i += step) {
        iterationCount++;
        yield i;
    }
    return iterationCount;
}


export function differenceInMonthsFractional(dateLeft: Date, dateRight: Date): number {
    const meses = differenceInMonths(dateLeft, dateRight);
    const diasDiferenca = differenceInDays(dateLeft, addMonths(dateRight, meses));
    const prazoTotal = meses + (diasDiferenca / 30);
    //const recalculo = addDays(addMonths(dateRight,meses),diasDiferenca);
    return prazoTotal;
}

export function addMonthsFractional(dateLeft: Date, mesesFractional: number): Date {
    const integer = Math.floor(mesesFractional);
    const decimal = mesesFractional - integer;
    const diasDiferenca = decimal * 30;

    return addDays(addMonths(dateLeft, integer), diasDiferenca);
}

export function removeSpecialChars(str: string) {
    return str.replace(/(?![0-9A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑáàâãéèêíïóôõöúçñ:./,]|\s)./g, '')
        .replace(/\s+/g, ' ')
        .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2');
}




import { getServiceURL } from "../services/ServiceURL";

export interface ImagemVO {
    nuImagem: number;
    noImagem: string;
    deImagem?: string;
    imImagem: string;
}

export default class Imagem {

    public nuImagem: number | undefined;
    public noImagem: string | undefined;
    public deImagem: string | undefined;
    public imImagem: string | undefined;

    constructor(obj: object | null = null) {
        if (obj) Object.assign(this, obj);
        Object.seal(this); // Não permite alterar estrutura da classe
    }

    get imagemURL(): string | undefined {
        if (this.nuImagem) {
            return `${getServiceURL()}/api/imagens/${this.nuImagem}`;
        }
        if (this.imImagem && this.noImagem) {
            return `data:${this.imagemMediaType};charset=utf-8;base64, ${this.imImagem}`;
        }
        return undefined;
    }

    get imagemMediaType(): string | undefined {
        if (this.noImagem) {
            const extensao = this.noImagem.match(/\.\w+$/i);
            if (extensao) {
                switch (extensao[0].toLowerCase()) {
                    case '.png':
                        return 'image/png';
                    case '.jpg':
                        return 'image/jpg';
                    case '.gif':
                        return 'image/gif';
                    default:
                        return undefined;
                }
            }
        }
        return undefined;
    }


}


import { createAsyncThunk, createSlice, EntityState, createEntityAdapter, DeepPartial } from "@reduxjs/toolkit";
import moment from "moment";
import { fromEvent, throwError } from "rxjs";
import { catchError, map, takeUntil, timeout } from "rxjs/operators";
import { TaxaJurosVO } from "../../../models/TaxaJuros";
import { api } from "../../../services/api/api";
import { APIError } from "../../../services/api/APIError";
import { APIUtils } from "../../../services/api/APIUtils";
import { RootState } from "../../configureStore";
import { RequestState, shouldFetch } from "../../RequestsState";
import { selectToken } from "../userSlice";
import { ValorCotaVO } from "../../../models/ValorCota";

interface ValoresCotasSliceState {
  requests: EntityState<RequestState<any>>;
  valoresCotas: EntityState<ValorCotaVO>;
}

const requestsAdapter = createEntityAdapter<RequestState<any>>({
  selectId: request => request.id
});

const valoresCotasAdapter = createEntityAdapter<ValorCotaVO>({
  selectId: cota => cota.coValorCota,
  sortComparer: (a, b) => moment(b.dtInicio).valueOf() - moment(a.dtInicio).valueOf()
})

const initialState: ValoresCotasSliceState = {
  requests: requestsAdapter.getInitialState(),
  valoresCotas: valoresCotasAdapter.getInitialState()
}


// REQUESTS

const REQ_FETCH_VALORES_COTAS = "fetchValoresCotas";
const REQ_CREATE_VALOR_COTA = "createValorCota";
const REQ_PATCH_VALOR_COTA = "patchValorCota";

const requestSelectors = requestsAdapter.getSelectors((state: RootState) => state.entities.valoresCotas.requests)

const valoresCotasSelectors = valoresCotasAdapter.getSelectors((state: RootState) => state.entities.valoresCotas.valoresCotas)

const trackFetchValoresCotas = (nuProjeto: number) => (state: RootState): RequestState<TaxaJurosVO[]> => {
  return requestSelectors.selectById(state, `${REQ_FETCH_VALORES_COTAS}:${nuProjeto}`) ?? { id: `${REQ_FETCH_VALORES_COTAS}:${nuProjeto}`, pending: false }
}

const trackCreateValorCota = (nuProjeto: number) => (state: RootState): RequestState<TaxaJurosVO> => {
  return requestSelectors.selectById(state, `${REQ_CREATE_VALOR_COTA}:${nuProjeto}`) ?? { id: `${REQ_CREATE_VALOR_COTA}:${nuProjeto}`, pending: false }
}

const trackPatchValorCota = (nuProjeto: number, coValorCota: number) => (state: RootState): RequestState<TaxaJurosVO> => {
  return requestSelectors.selectById(state, `${REQ_PATCH_VALOR_COTA}:${nuProjeto}:${coValorCota}`) ?? { id: `${REQ_PATCH_VALOR_COTA}:${nuProjeto}:${coValorCota}`, pending: false }
}


// THUNKS
const fetchValoresCotasList = createAsyncThunk<ValorCotaVO[], { nuProjeto: number }, { state: RootState; rejectValue: APIError }>
  ('valoresCotas/fetchTaxasJurosList',
    async ({ nuProjeto }, { signal, rejectWithValue, getState }) => {
      const token = selectToken(getState()) as string;
      return api.valoresCotas.fetchValoresCotas(nuProjeto, token).pipe(
        timeout(TRANSACTION_TIMEOUT),
        takeUntil(fromEvent(signal, 'abort')),
        map(result => result.data),
        catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
      ).toPromise().catch(err => rejectWithValue(err))
    }, { condition: ({ nuProjeto }, { getState }) => shouldFetch(trackFetchValoresCotas(nuProjeto)(getState()), moment.duration(2, "minutes")) }
  )

const createValorCota = createAsyncThunk<ValorCotaVO, { nuProjeto: number; valorCota: DeepPartial<ValorCotaVO> }, { state: RootState; rejectValue: APIError }>
  ('valoresCotas/createValorCota',
    async ({ nuProjeto, valorCota }, { signal, rejectWithValue, getState }) => {
      const token = selectToken(getState()) as string;
      return api.valoresCotas.createValorCota(nuProjeto, valorCota, token).pipe(
        timeout(TRANSACTION_TIMEOUT),
        takeUntil(fromEvent(signal, 'abort')),
        map(result => result.data),
        catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
      ).toPromise().catch(err => rejectWithValue(err))
    }, { condition: ({ nuProjeto }, { getState }) => shouldFetch(trackCreateValorCota(nuProjeto)(getState())) }
  )

const patchValorCota = createAsyncThunk<void, { nuProjeto: number; coValorCota: number; changes: Partial<ValorCotaVO> }, { state: RootState; rejectValue: APIError }>
  ('valoresCotas/patchValorCota',
    async ({ nuProjeto, coValorCota, changes }, { signal, rejectWithValue, getState }) => {
      const token = selectToken(getState()) as string;
      return api.valoresCotas.patchValorCota(nuProjeto, coValorCota, changes, token).pipe(
        timeout(TRANSACTION_TIMEOUT),
        takeUntil(fromEvent(signal, 'abort')),
        map(result => result.data),
        catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
      ).toPromise().catch(err => rejectWithValue(err))
    }, { condition: ({ nuProjeto, coValorCota }, { getState }) => shouldFetch(trackPatchValorCota(nuProjeto, coValorCota)(getState())) }
  )


// SLICE
const valoresCotasSlice = createSlice({
  name: 'valoresCotas',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    //
    builder.addCase(fetchValoresCotasList.pending, (state, { meta: { arg: { nuProjeto } } }) => {
      requestsAdapter.upsertOne(state.requests, { id: `${REQ_FETCH_VALORES_COTAS}:${nuProjeto}`, pending: true, error: undefined, data: undefined });
    })
    builder.addCase(fetchValoresCotasList.fulfilled, (state, { meta: { arg: { nuProjeto } }, payload: valoresCotas }) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_FETCH_VALORES_COTAS}:${nuProjeto}`,
        changes: { pending: false, timestamp: moment().toISOString(), data: valoresCotas }
      });
      valoresCotasAdapter.setAll(state.valoresCotas, valoresCotas);
    })
    builder.addCase(fetchValoresCotasList.rejected, (state, action) => {
      const nuProjeto = action.meta.arg.nuProjeto;
      const error = action.payload as APIError || { ...action.error };
      requestsAdapter.updateOne(state.requests, { id: `${REQ_FETCH_VALORES_COTAS}:${nuProjeto}`, changes: { pending: false, error } });
    })

    //
    builder.addCase(createValorCota.pending, (state, { meta: { arg: { nuProjeto } } }) => {
      requestsAdapter.upsertOne(state.requests, { id: `${REQ_CREATE_VALOR_COTA}:${nuProjeto}`, pending: true, error: undefined, data: undefined });
    })
    builder.addCase(createValorCota.fulfilled, (state, { meta: { arg: { nuProjeto } }, payload: valorCota }) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_CREATE_VALOR_COTA}:${nuProjeto}`,
        changes: { pending: false, timestamp: moment().toISOString(), data: valorCota }
      });
      valoresCotasAdapter.addOne(state.valoresCotas, valorCota)
    })
    builder.addCase(createValorCota.rejected, (state, action) => {
      const nuProjeto = action.meta.arg.nuProjeto;
      const error = action.payload as APIError || { ...action.error };
      requestsAdapter.updateOne(state.requests, { id: `${REQ_CREATE_VALOR_COTA}:${nuProjeto}`, changes: { pending: false, error } });
    })


    //
    builder.addCase(patchValorCota.pending, (state, { meta: { arg: { nuProjeto, coValorCota } } }) => {
      requestsAdapter.upsertOne(state.requests, { id: `${REQ_PATCH_VALOR_COTA}:${nuProjeto}:${coValorCota}`, pending: true, error: undefined, data: undefined });
    })
    builder.addCase(patchValorCota.fulfilled, (state, { meta: { arg: { nuProjeto, coValorCota, changes } } }) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_PATCH_VALOR_COTA}:${nuProjeto}:${coValorCota}`,
        changes: { pending: false, timestamp: moment().toISOString() }
      });
      valoresCotasAdapter.updateOne(state.valoresCotas, { id: coValorCota, changes })
    })
    builder.addCase(patchValorCota.rejected, (state, action) => {
      const { nuProjeto, coValorCota } = action.meta.arg;
      const error = action.payload as APIError || { ...action.error };
      requestsAdapter.updateOne(state.requests, { id: `${REQ_PATCH_VALOR_COTA}:${nuProjeto}:${coValorCota}`, changes: { pending: false, error } });
    })


  }
})

const {
  selectById: selectValorCotaById,
  selectAll: selectValoresCotas
} = valoresCotasSelectors;

export {
  fetchValoresCotasList,
  createValorCota,
  patchValorCota,
  trackFetchValoresCotas,
  selectValorCotaById,
  selectValoresCotas
};

export default valoresCotasSlice.reducer
import React from 'react'
import { makeStyles, createStyles, Grid, Typography } from '@material-ui/core';

const useStyles = (color?: string) => makeStyles(() => createStyles({
  listFieldName: {
    color: '#546e7a'
  },
  listFieldValue: {
    fontWeight: 500,
    color: color
  }
}), { name: 'FieldOutput' });


interface FieldOutputProps {
  id?: string;
  label: string;
  value?: string | JSX.Element;
  color?: string;
}

export const FieldOutput = ({ label, value, color, id }: FieldOutputProps) => {

  const classes = useStyles(color)()

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="caption" className={classes.listFieldName}>{label}</Typography>
      </Grid>
      <Grid item>
        <Typography id={id} variant="subtitle2" className={classes.listFieldValue}>{value}</Typography>
      </Grid>
    </Grid>
  )
}

import { Avatar, Card, CardContent, createStyles, Grid, Typography, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  anexo: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  }
}), { name: 'DashboardTotalizador' });


interface Props {
  nome: string;
  valor: string;
  icon: JSX.Element;
  iconBackgroundColor: string;
  iconTextColor?: string;
  cardBackgroundColor?: string;
  cardTextColor?: string;
  anexo?: JSX.Element;
}

export default function DashboardTotalizador(props: Props) {

  const { nome, valor, icon, iconBackgroundColor, iconTextColor, cardBackgroundColor, cardTextColor, anexo } = props;

  const classes = useStyles();

  return (
    <Card style={{ backgroundColor: cardBackgroundColor, color: cardTextColor }}>
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color={cardTextColor?"inherit":"textSecondary"}
              gutterBottom
              variant="body2"
            >
              {nome}
            </Typography>
            <Typography variant="h5">{valor}</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar} style={{ backgroundColor: iconBackgroundColor, color: iconTextColor }}>
              {icon}
            </Avatar>
          </Grid>
        </Grid>
        {anexo &&
          <div className={classes.anexo}>
             {anexo}
          </div>
        }


      </CardContent>
    </Card>
  );
}

import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Typography, Button } from '@material-ui/core';
import diversificacaoImage from '../../../images/home/diversificacao.svg'
import rentabilidadeImage from '../../../images/home/rentabilidade.svg'
import simplicidadeImage from '../../../images/home/simplicidade.svg'
import curvyLinesImage from '../../../images/home/curvy_lines.png'
import ArrowRightIcon from 'mdi-material-ui/ArrowRight';
import { Link } from 'react-router-dom';


const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: '#fff5f8'
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(15),
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
  button: {
    marginTop: theme.spacing(8),
    border: '4px solid currentColor',
    borderRadius: 0,
    height: 'auto',
    padding: theme.spacing(2, 5),
    backgroundColor: '#fff5f8'
  },
  
}), { name: 'ProductValues' });

export default function ProductValues() {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <img
          src={curvyLinesImage}
          className={classes.curvyLines}
          alt="curvy lines"
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
            <img
                className={classes.image}
                src={diversificacaoImage}
                alt="Diversificação"
              />
              <Typography variant="h6" className={classes.title}>
                DIVERSIFICAÇÃO
              </Typography>
              <Typography variant="subtitle1">
                Disponibilizamos captações dos mais variados setores da economia.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
            <img
                className={classes.image}
                src={rentabilidadeImage}
                alt="Rentabilidade"
              />
             
              <Typography variant="h6" className={classes.title}>
                RENTABILIDADE
              </Typography>
              <Typography variant="subtitle1">
                Os projetos disponibilizados na plataforma passam por um rigoroso processo de seleção. Somente são aceitos projetos cujo relação risco-retorno seja vantajosa aos investidores.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
            <img
                className={classes.image}
                src={simplicidadeImage}
                alt="Simplicidade"
              />
             
              <Typography variant="h6" className={classes.title}>
                SIMPLICIDADE
              </Typography>
              <Typography variant="subtitle1">
                Processo de investimento simplificado, totalmente digital, com investimento mínimo de R$1.000,00.
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Button
          size="large"
          variant="outlined"
          className={classes.button}
          component={Link}
          to="/investir"
          endIcon={<ArrowRightIcon/>}
        >
          Acessar Projetos
      </Button>
      </Container>
    </section>
  );
}
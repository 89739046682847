import { Box, Button, CircularProgress, Grid, IconButton, InputAdornment, Paper, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { unwrapResult, DeepPartial } from '@reduxjs/toolkit';
import { applyPatches, Patch, produceWithPatches } from 'immer';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../../browser';
import { EnderecoVO } from '../../../../models/Endereco';
import { UF } from '../../../../models/enums/UF';
import { APIError } from '../../../../services/api/APIError';
import { findCEP } from '../../../../stores/slices/entities/cepSlice';
import { Formatters } from '../../../../utils/formatters';
import { Patterns } from '../../../../utils/patterns';
import ButtonProgress from '../../ButtonProgress';
import ConfirmLeavePage from '../../ComfirmLeavePage';
import FormInput from '../../FormInput';
import { CEPMaskedInput, OnlyNumbersMaskedInput } from '../../MaskedInputs';

export enum EnderecoFormMode {
  CREATE,
  EDIT
}

interface EnderecoFormData {
  noMunicipio: string;
  noUf: string;
  coCep: string;
  noLogradouro: string;
  nuLogradouro: string;
  deComplemento: string;
  noBairro: string;
}

const generateFormDefaultValues = (endereco: DeepPartial<EnderecoVO>): EnderecoFormData => {
  return {
    noMunicipio: endereco.noMunicipio || '',
    noUf: endereco.noUf || '',
    coCep: Formatters.cep.format(endereco.coCep),
    noLogradouro: endereco.noLogradouro || '',
    nuLogradouro: endereco.nuLogradouro?.toString() || '',
    deComplemento: endereco.deComplemento || '',
    noBairro: endereco.noBairro || ''
  }
}

interface EnderecoFormProps {
  endereco: DeepPartial<EnderecoVO>;
  submitText: string;
  onSubmit: (nextState: DeepPartial<EnderecoVO>, patches: Patch[], changes: DeepPartial<EnderecoVO>) => Promise<void> | void;
  onBack?: () => void;
  formMode: EnderecoFormMode;
}

export const EnderecoForm = ({ endereco, onSubmit, onBack, submitText, formMode }: EnderecoFormProps) => {

  const dispatch = useDispatch<AppDispatch>();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [isCEPLoading, setIsCEPLoading] = useState(false);

  const { register, handleSubmit, errors, control, triggerValidation, getValues, setValue, formState: { dirtyFields, isSubmitting, dirty } } = useForm<EnderecoFormData>({
    mode: "onBlur",
    defaultValues: generateFormDefaultValues(endereco)
  });

  const handleFormSubmit = async (formData: EnderecoFormData) => {
    const [nextState, patches] = produceWithPatches(endereco, draft => {
      draft.coCep = Formatters.cep.unformat(formData.coCep);
      draft.noMunicipio = formData.noMunicipio;
      draft.noUf = formData.noUf;
      draft.noLogradouro = formData.noLogradouro;
      draft.nuLogradouro = Number(formData.nuLogradouro);
      draft.deComplemento = formData.deComplemento;
      draft.noBairro = formData.noBairro;
    })
    const changes = applyPatches({}, patches) as Partial<EnderecoVO>;
    await onSubmit(nextState, patches, changes)
  }

  const handleConsultaCEP = async () => {
    if (!dirtyFields.has("coCep")) return // Não alterado
    const valid = await triggerValidation("coCep");
    if (!valid) return // Inválido
    setIsCEPLoading(true);
    const cep = Formatters.cep.unformat(getValues({ nest: true }).coCep);
    const resultAction = await dispatch(findCEP(cep))
    if (findCEP.fulfilled.match(resultAction)) {
      const endereco = unwrapResult(resultAction);
      if (endereco) {
        setValue("noUf", endereco.noUf, true);
        setValue("noMunicipio", endereco.noMunicipio, true);
        setValue("noBairro", endereco.noBairro, true);
        setValue("noLogradouro", endereco.noLogradouro, true);
      } else {
        enqueueSnackbar('CEP não encontrado!', {
          variant: 'warning',
          action: <Button onClick={() => closeSnackbar()}>OK</Button>
        });
      }
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      enqueueSnackbar(`Erro na consulta CEP: ${error.message}!`, {
        variant: 'error',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });
    }
    setIsCEPLoading(false);
  }

  return (
    <form id="formEndereco" noValidate autoComplete="off" onSubmit={handleSubmit(handleFormSubmit)}>
      <ConfirmLeavePage
        when={dirty}
        title="Tem certeza que deseja deixar a página sem salvar as alterações?"
        content="Ao confirmar os dados alterados não serão salvos. Se cancelar, poderá continuar a edição."
      />
      <Paper variant="outlined" square >
        <Box p={2}>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <Typography variant="h6">Endereço</Typography>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <Controller
                name="coCep"
                control={control}
                as={
                  <FormInput
                    required
                    autoFocus
                    disabled={isCEPLoading}
                    label="CEP"
                    placeholder="99999-999"
                    inputComponent={CEPMaskedInput as any}
                    endAdornment={
                      <InputAdornment position="end">
                        {isCEPLoading
                          ? <CircularProgress size={24} />
                          : <IconButton edge="end" onClick={handleConsultaCEP} disabled={!!errors.coCep}>
                            <SearchIcon />
                          </IconButton>
                        }
                      </InputAdornment>
                    }
                    error={errors.coCep?.message?.toString()}
                  />
                }
                onBlur={handleConsultaCEP}
                rules={{
                  required: "Informe o CEP",
                  pattern: { value: Patterns.cep, message: 'Formato 99999-999' }
                }}
              />


            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Controller
                name="noUf"
                control={control}
                as={
                  <FormInput
                    required
                    disabled={isCEPLoading}
                    label="UF"
                    error={errors.noUf?.message?.toString()}
                    select
                  >
                    <option value="" />
                    {Object.entries(UF).map((key, value) => (
                      <option key={value} value={key[1]}>{key[0]}</option>
                    ))}
                  </FormInput>
                }
                rules={{
                  required: "Selecione a UF"
                }}
              />


            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Controller
                control={control}
                name="noMunicipio"
                as={
                  <FormInput
                    required
                    disabled={isCEPLoading}
                    label="Município"
                    maxLength={100}
                    error={errors.noMunicipio?.message?.toString()}
                  />
                }

                rules={{
                  required: "Informe o Município"
                }}
              />


            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Controller
                control={control}
                name="noBairro"
                as={
                  <FormInput
                    required
                    disabled={isCEPLoading}
                    label="Bairro"
                    maxLength={100}
                    error={errors.noBairro?.message?.toString()}
                  />
                }
                rules={{
                  required: "Informe o Bairro"
                }}
              />

            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Controller
                control={control}
                name="noLogradouro"
                as={
                  <FormInput
                    required
                    disabled={isCEPLoading}
                    label="Rua"
                    maxLength={100}
                    error={errors.noLogradouro?.message?.toString()}
                  />
                }
                rules={{
                  required: "Informe a Rua"
                }}
              />

            </Grid>

            <Grid item xs={12} sm={6} lg={4}>

              <FormInput
                name="nuLogradouro"
                required
                label="Número"
                maxLength={8}
                inputComponent={OnlyNumbersMaskedInput as any}
                error={errors.nuLogradouro?.message?.toString()}
                inputRef={register({
                  required: "Informe o Número",
                  pattern: { value: /\d{1,8}/, message: "Somente números" }
                })}
              />

            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormInput
                name="deComplemento"
                label="Complemento"
                maxLength={100}
                error={errors.deComplemento?.message?.toString()}
                inputRef={register()}
              />

            </Grid>

            <Grid item container justify="flex-end" spacing={2}>
              {onBack &&
                <Grid item>
                  <Button
                    id="btnVoltar"
                    variant="text"
                    color="default"
                    onClick={onBack}
                  >
                    Voltar
              </Button>
                </Grid>
              }
              <Grid item>
                <ButtonProgress
                  id="btnSubmit"
                  loading={isSubmitting}
                  disabled={Object.values(errors).length > 0 || (formMode == EnderecoFormMode.EDIT && !dirty)}
                  variant="contained"
                  color="primary"
                  type="submit">
                  {submitText}
                </ButtonProgress>
              </Grid>
            </Grid>

          </Grid>
        </Box>
      </Paper>
    </form >
  )
}

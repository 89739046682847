import React, {Fragment, useEffect} from 'react';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  Grid,
  makeStyles
} from '@material-ui/core';
import {ContaUsuarioVO} from '../../../../../../models/ContaUsuario';
import {DeepPartial} from "redux";
import {useForm} from "react-hook-form";
import {applyPatches, Patch, produceWithPatches} from "immer";
import FormInput from "../../../../../components/FormInput";
import {Patterns} from "../../../../../../utils/patterns";
import ButtonProgress from "../../../../../components/ButtonProgress";
import {useDialog} from "../../../../../components/dialog/PageServiceProvider";


const useStyles = makeStyles(() => createStyles({
  form: {
    marginBottom: 0
  },
  actions: {
    justifyContent: 'flex-end'
  }
}), {name: 'ContaEditForm'});

interface ContaEditFormData {
  noUsuario: string;
  noEmail: string;
}

interface Props {
  usuario: ContaUsuarioVO;
  onSubmit: (nextState: DeepPartial<ContaUsuarioVO>, patches: Patch[], changes: DeepPartial<ContaUsuarioVO>) => Promise<void> | void;
}

const generateFormDefaultValues = (usuario: ContaUsuarioVO): ContaEditFormData => {
  return {
    noUsuario: usuario.noUsuario,
    noEmail: usuario.noEmail
  }
}

export default function ContaEditForm({usuario, onSubmit}: Props) {

  const classes = useStyles();

  const dialog = useDialog();

  const {register, handleSubmit, errors, reset, formState: {isSubmitting, dirty}} = useForm<ContaEditFormData>({
    mode: "onBlur",
    defaultValues: generateFormDefaultValues(usuario)
  });

  useEffect(() => {
    reset(generateFormDefaultValues(usuario));
  }, [usuario, reset]);


  const handleFormSubmit = async (formData: ContaEditFormData) => {
    const confirmed = await dialog({
      title: "Confirma atualização dos dados básicos da conta?",
      description: "Ao confirmar os dados serão alterados. Se cancelar, nenhuma alteração será salva no servidor.",
      confirmOption: "CONFIRMAR",
      cancelOption: "CANCELAR"
    })
    if (confirmed) {
      const [nextState, patches] = produceWithPatches(usuario, draft => {
        draft.noUsuario = formData.noUsuario;
        draft.noEmail = formData.noEmail;
      })
      const changes = applyPatches({}, patches) as Partial<ContaUsuarioVO>;
      await onSubmit(nextState, patches, changes)
    }
  }

  return (
    <Fragment>
      <Card>

        <form id="formContaEdit" noValidate autoComplete="off" className={classes.form}
              onSubmit={handleSubmit(handleFormSubmit)}>

          <CardHeader title="Conta"/>
          <Divider/>

          <CardContent>
            <Grid container spacing={3} >
              <Grid item xs={12}>

                <FormInput
                  required
                  name="noUsuario"
                  label="Nome do Usuário"
                  placeholder="usuario@domínio.com"
                  maxLength={100}
                  error={errors.noUsuario?.message?.toString()}
                  inputRef={register({
                    required: 'Informe o Nome Completo',
                  })}
                />
              </Grid>
              <Grid item xs={12}>

                <FormInput
                  required
                  type="email"
                  name="noEmail"
                  label="Email"
                  placeholder="usuario@domínio.com"
                  maxLength={100}
                  error={errors.noEmail?.message?.toString()}
                  inputRef={register({
                    required: 'Informe o Email',
                    pattern: { value: Patterns.email, message: 'Formato usuario@domínio.com' },
                  })}
                />

              </Grid>
            </Grid>
          </CardContent>
          <Divider/>
          <CardActions className={classes.actions}>
            <ButtonProgress
              id="btnSubmit"
              loading={isSubmitting}
              disabled={Object.values(errors).length > 0 || !dirty}
              variant="contained"
              color="primary"
              type="submit">
              Salvar
            </ButtonProgress>
          </CardActions>
        </form>
      </Card>
    </Fragment>

  )
}
import { createStyles, Grid, makeStyles, Paper, Theme } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import React from 'react';
import { MotivoCancelamentoInvestimento } from '../../../../../models/enums/MotivoCancelamentoInvestimento';
import { InvestimentoVO } from '../../../../../models/Investimento';
import { APIUtils } from '../../../../../services/api/APIUtils';
import { FieldOutput } from '../../../../components/form/output/FieldOutput';
import moment from 'moment';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme: Theme) => createStyles({
  documentoContainer: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 400,
    },
  },
  listItem: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(3, 2),
  },
  logo: {
    maxHeight: 80,
    width: 70
  },
}), { name: 'PainelInvestimentoCanceladoItem' });


interface Props {
  reserva: InvestimentoVO;
}

export default function PainelInvestimentoCanceladoItem({reserva}: Props) {

  const classes = useStyles();

  const intl = useIntl();

  return (

    <Paper key={reserva.coInvestimento} className={classes.listItem}>
      <Grid container alignItems="center" spacing={2}>

        <Grid item xs={3} sm={2} md={1}>
          <img className={classes.logo} src={APIUtils.generateImageURL(reserva.projeto.empresa.imagemLogo)} alt={"logo da " + (reserva.projeto.empresa.noFantasia)} />
        </Grid>

        <Grid item xs={9} sm={10} md={3}>
          <FieldOutput label={reserva.projeto.empresa.noFantasia} value={reserva.projeto.noProjeto} />
        </Grid>

        <Grid item xs={7} sm={3} md={2}>
          <FieldOutput label="Data Reserva" value={moment(reserva.dhReserva).format("L")} />
        </Grid>

        <Grid item xs={5} sm={3} md={2}>
          <FieldOutput label="Valor Reservado" value={intl.formatNumber(reserva.vrInvestimento,{style:"currency",currency:"BRL"})} />
        </Grid>

        <Grid item xs={5} sm={3} md={2}>
          <FieldOutput label="Data Cancelamento" value={moment(reserva.dhCancelamento).format("L LT")} />
        </Grid>

        <Grid item xs={7} sm={3} md={2}>
          <FieldOutput label="Motivo" value={MotivoCancelamentoInvestimento[reserva.motivoCancelamentoInvestimento || 0]} color={red[600]} />
        </Grid>

      </Grid>

    </Paper>

  )
}
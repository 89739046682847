import { Box, Button, createStyles, Grid, makeStyles, Paper, Typography, Link, Theme } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { DeepPartial } from 'redux';
import { APIUtils } from '../../../../../../services/api/APIUtils';
import ButtonProgress from '../../../../../components/ButtonProgress';
import ConfirmLeavePage from '../../../../../components/ComfirmLeavePage';
import { FieldOutput } from '../../../../../components/form/output/FieldOutput';
import { Formatters } from '../../../../../../utils/formatters';
import { ProjetoVO } from '../../../../../../models/Projeto';
import moment from 'moment';
import { TipoPagamentoAmortizacao } from '../../../../../../models/enums/TipoPagamentoAmortizacao';
import { TipoPagamentoJuros } from '../../../../../../models/enums/TipoPagamentoJuros';
import { useIntl } from 'react-intl';
import { TipoRentabilidadeProjetada } from '../../../../../../models/enums/TipoRentabilidadeProjetada';
import { TipoRentabilidadeGarantida } from '../../../../../../models/enums/TipoRentabilidadeGarantida';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../../../browser';
import { trackFetchEmpresasCombo, fetchEmpresasCombo } from '../../../../../../stores/slices/entities/empresasSlice';
import { fetchTaxasJurosList, trackFetchTaxasJuros } from '../../../../../../stores/slices/entities/taxaJurosSlice';
import { LoadingText } from '../../../../../components/progress/LoadingText';
import { PDFViewer } from '../../../../../components/pdf/PDFViewer';

const useStyles = makeStyles((theme: Theme) => createStyles({
  imagens: {
    maxHeight: 100,
    width: 100,
    margin: theme.spacing(.5)
  },
}), { name: 'ProjetoReviewCadastro' });


export enum ProjetoReviewCadastroFormMode {
  CREATE,
  EDIT
}

interface ProjetoReviewCadastroProps {
  projeto: DeepPartial<ProjetoVO>;
  onSubmit?: () => Promise<void> | void;
  submitText?: string;
  formMode: ProjetoReviewCadastroFormMode;
  onBack?: () => void;
  onDadosBasicosEdit: () => void;
  onEnderecoEdit: () => void;
  onCaptacaoEdit: () => void;
  onImagemCapaEdit: () => void;
  onGaleriaFotosEdit: () => void;
  onProspectoEdit: () => void;
  onContratoEdit: () => void;
  onHistoricoCotasEdit?: () => void;
}

export const ProjetoReviewCadastro = ({ projeto, onSubmit, onBack, submitText, formMode,
  onCaptacaoEdit, onDadosBasicosEdit, onEnderecoEdit, onImagemCapaEdit, onGaleriaFotosEdit,
  onProspectoEdit, onContratoEdit, onHistoricoCotasEdit }: ProjetoReviewCadastroProps) => {

  const dispatch = useDispatch<AppDispatch>()

  const empresasRequestState = useSelector(trackFetchEmpresasCombo);

  const taxasJurosRequestState = useSelector(trackFetchTaxasJuros);

  const taxaJuros = taxasJurosRequestState.data?.find(t => t.coTaxaJuros == projeto.taxaJuros?.coTaxaJuros);
  const empresa = empresasRequestState.data?.find(e => e.nuEmpresa == projeto.empresa?.nuEmpresa)

  useEffect(() => {
    const promiseEmpresas = dispatch(fetchEmpresasCombo());
    const promiseTaxas = dispatch(fetchTaxasJurosList());
    return () => {
      promiseEmpresas.abort();
      promiseTaxas.abort();
    }
  }, [dispatch]);


  const classes = useStyles();

  const intl = useIntl();

  const { handleSubmit, formState: { isSubmitting } } = useForm();

  return (
    <>
      <ConfirmLeavePage
        when={!!onSubmit}
        title="Tem certeza que deseja deixar a página sem salvar as alterações?"
        content="Ao confirmar os dados alterados não serão salvos. Se cancelar, poderá continuar a edição."
      />
      <Paper variant="outlined" square >
        <Box p={2}>
          <Box mb={2} display="flex" alignItems="center">
            <Typography variant="h6">Dados Básicos&nbsp;</Typography>
            <Link component="button" variant="subtitle2" color="primary" onClick={onDadosBasicosEdit}>[Editar]</Link>
          </Box>
          <Box ml={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Nome Projeto" value={projeto.noProjeto || ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Permalink" value={`/investir/${projeto.noPermalink}` || ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                {empresasRequestState.pending &&
                  <FieldOutput label="Empresa" value={<LoadingText text="Carregando" />} />
                }
                {empresasRequestState.error &&
                  <FieldOutput label="Empresa" value={<Link component="button" onClick={() => dispatch(fetchEmpresasCombo())}>Erro ao Carregar. Tentar Novamente</Link>} />
                }
                {empresasRequestState.data &&
                  <FieldOutput label="Empresa" value={empresa?.noFantasia || ''} />
                }
              </Grid>

              <Grid item xs={12}>
                <FieldOutput label="Descrição" value={<>{projeto.deProjeto?.split('\n').map((item, i) => <p key={i}>{item}</p>) || ''}</>} />
              </Grid>
            </Grid>
          </Box>

          <Box my={2} display="flex" alignItems="center">
            <Typography variant="h6">Endereço&nbsp;</Typography>
            <Link component="button" variant="subtitle2" color="primary" onClick={onEnderecoEdit}>[Editar]</Link>
          </Box>
          <Box ml={2}>
            <Grid container spacing={2}>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="CEP" value={Formatters.cep.format(projeto.endereco?.coCep)} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="UF" value={projeto.endereco?.noUf || ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Município" value={projeto.endereco?.noMunicipio || ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Bairro" value={projeto.endereco?.noBairro || ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Rua" value={projeto.endereco?.noLogradouro || ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Número" value={projeto.endereco?.nuLogradouro?.toString() || ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Complemento" value={projeto.endereco?.deComplemento || '(vazio)'} />
              </Grid>

            </Grid>
          </Box>

          <Box my={2} display="flex" alignItems="center">
            <Typography variant="h6">Captação&nbsp;</Typography>
            <Link component="button" variant="subtitle2" color="primary" onClick={onCaptacaoEdit}>[Editar]</Link>
          </Box>
          <Box ml={2}>
            <Grid container spacing={2}>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Inicio da Captação" value={projeto.dtInicioCaptacao ? moment(projeto.dtInicioCaptacao).format("L") : ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Fim da Captação" value={projeto.dtFimCaptacao ? moment(projeto.dtFimCaptacao).format("L") : ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Início dos Depósitos" value={projeto.dtInicioDepositos ? moment(projeto.dtInicioDepositos).format("L") : ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Fim dos Depósitos" value={projeto.dtFimDepositos ? moment(projeto.dtFimDepositos).format("L") : ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Início do Título" value={projeto.dtInicioTitulo ? moment(projeto.dtInicioTitulo).format("L") : ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Prazo em Meses" value={projeto.qtPrazoMesesTitulo?.toString() || ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Vencimento do Título" value={projeto.dtVencimentoTitulo ? moment(projeto.dtVencimentoTitulo).format("L") : ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Pagamento de Amortização" value={TipoPagamentoAmortizacao[Number(projeto.tipoPagamentoAmortizacao)]} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Pagamento de Juros" value={TipoPagamentoJuros[Number(projeto.tipoPagamentoJuros)]} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Quantidade de Cotas" value={projeto.qtCota?.toString() || ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Valor da Cota Emitida" value={projeto.vrCotaEmitida ? intl.formatNumber(projeto.vrCotaEmitida, { style: 'currency', currency: 'BRL' }) : ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Valor Alvo da Captação" value={projeto.vrAlvoCaptacao ? intl.formatNumber(projeto.vrAlvoCaptacao, { style: 'currency', currency: 'BRL' }) : ''} />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Tipo de Rentabilidade" value={TipoRentabilidadeProjetada[Number(projeto.tipoRentabilidadeProjetada)]} />
              </Grid>

              {projeto.tipoRentabilidadeProjetada == TipoRentabilidadeProjetada.Fixa &&
                <>
                  <Grid item xs={12} sm={6} lg={4}>
                    <FieldOutput label="Rentabilidade Anual" value={intl.formatNumber(projeto.pcRentabilidadeProjetadaFixaAnual || 0, { style: 'percent', minimumFractionDigits: 2 })} />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <FieldOutput label="Rentabilidade Projetada" value={intl.formatNumber(projeto.pcRentabilidadeProjetadaFixa || 0, { style: 'percent', minimumFractionDigits: 2 })} />
                  </Grid>
                </>
              }

              {projeto.tipoRentabilidadeProjetada == TipoRentabilidadeProjetada.Variável &&
                <>
                  <Grid item xs={12} sm={6} lg={4}>
                    <FieldOutput label="Rentabilidade Anual Mínima" value={intl.formatNumber(projeto.pcRentabilidadeProjetadaMinimaAnual || 0, { style: 'percent', minimumFractionDigits: 2 })} />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={4}>
                    <FieldOutput label="Rentabilidade Projetada Mínima" value={intl.formatNumber(projeto.pcRentabilidadeProjetadaMinima || 0, { style: 'percent', minimumFractionDigits: 2 })} />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={4}>
                    <FieldOutput label="Rentabilidade Anual Máxima" value={intl.formatNumber(projeto.pcRentabilidadeProjetadaMaximaAnual || 0, { style: 'percent', minimumFractionDigits: 2 })} />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={4}>
                    <FieldOutput label="Rentabilidade Projetada Máxima" value={intl.formatNumber(projeto.pcRentabilidadeProjetadaMaxima || 0, { style: 'percent', minimumFractionDigits: 2 })} />
                  </Grid>
                </>
              }

              <Grid item xs={12} sm={6} lg={4}>
                {taxasJurosRequestState.pending &&
                  <FieldOutput label="Taxa de Juros" value={<LoadingText text="Carregando" />} />
                }
                {taxasJurosRequestState.error &&
                  <FieldOutput label="Taxa de Juros" value={<Link component="button" onClick={() => dispatch(fetchTaxasJurosList())}>Erro ao Carregar. Tentar Novamente</Link>} />
                }
                {taxasJurosRequestState.data &&
                  <FieldOutput label="Taxa de Juros" value={taxaJuros ?
                    `${moment(taxaJuros?.dtInicio).format("L")}|
                    ${intl.formatNumber(taxaJuros?.pcTaxaSelic || 0, { style: "percent", minimumFractionDigits: 2 })}|
                    ${intl.formatNumber(taxaJuros?.pcTaxaCdi || 0, { style: "percent", minimumFractionDigits: 2 })}|
                    ${intl.formatNumber(taxaJuros?.pcTaxaPoupanca || 0, { style: "percent", minimumFractionDigits: 2 })}` : ''
                  } />
                }

              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <FieldOutput label="Tipo de Garantia" value={TipoRentabilidadeGarantida[Number(projeto.tipoRentabilidadeGarantida)]} />
              </Grid>

              {projeto.tipoRentabilidadeGarantida == TipoRentabilidadeGarantida["% do CDI Pré-fixado"] &&
                <>
                  <Grid item xs={12} sm={6} lg={4}>
                    <FieldOutput label="% do CDI Pré-fixado" value={intl.formatNumber(projeto.pcRentabilidadeGarantidaCdi || 0, { style: 'percent', minimumFractionDigits: 2 })} />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={4}>
                    <FieldOutput label="Rentabilidade Anual Garantida" value={intl.formatNumber(projeto.pcRentabilidadeGarantidaFixaAnual || 0, { style: 'percent', minimumFractionDigits: 2 })} />
                  </Grid>
                </>
              }

            </Grid>
          </Box>

          <Box my={2} display="flex" alignItems="center">
            <Typography variant="h6">Imagem de Capa&nbsp;</Typography>
            <Link component="button" variant="subtitle2" color="primary" onClick={onImagemCapaEdit}>[Editar]</Link>
          </Box>

          <Box ml={2}>
            <img className={classes.imagens} src={APIUtils.generateImageURL(projeto.imagemCapa)} />
          </Box>

          <Box my={2} display="flex" alignItems="center">
            <Typography variant="h6">Galeria de Fotos&nbsp;</Typography>
            <Link component="button" variant="subtitle2" color="primary" onClick={onGaleriaFotosEdit}>[Editar]</Link>
          </Box>

          <Box ml={2} display="flex" flexWrap="wrap">
            {(!projeto.galeriaFotos || projeto.galeriaFotos.length == 0) &&
              <Typography variant="subtitle1">(vazio)</Typography>
            }
            {projeto.galeriaFotos?.map((img, idx) => <img key={idx} className={classes.imagens} src={APIUtils.generateImageURL(img)} />)}
          </Box>

          <Box my={2} display="flex" alignItems="center">
            <Typography variant="h6">Prospecto&nbsp;</Typography>
            <Link component="button" variant="subtitle2" color="primary" onClick={onProspectoEdit}>[Editar]</Link>
          </Box>
          <Box ml={2}>
            <PDFViewer width={200} previewURL={APIUtils.generateDocumentoURL(projeto.documentoProspecto)} />
          </Box>

          <Box my={2} display="flex" alignItems="center">
            <Typography variant="h6">Modelo de Contrato&nbsp;</Typography>
            <Link component="button" variant="subtitle2" color="primary" onClick={onContratoEdit}>[Editar]</Link>
          </Box>
          <Box ml={2}>
            <PDFViewer width={200} previewURL={APIUtils.generateDocumentoURL(projeto.documentoModeloContrato)} />
          </Box>

          {formMode == ProjetoReviewCadastroFormMode.EDIT &&
            <>
              <Box mb={2} display="flex" alignItems="center">
                <Typography variant="h6">Histórico de Cotas&nbsp;</Typography>
                <Link component="button" variant="subtitle2" color="primary" onClick={onHistoricoCotasEdit}>[Editar]</Link>
              </Box>
              <Box ml={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} lg={4}>
                    <FieldOutput label="Valor Cota Vigente" value={intl.formatNumber(projeto.vrCotaAtualizada || 0, { style: "currency", currency: "BRL" })} />
                  </Grid>
                </Grid>
              </Box>
            </>
          }



          <Grid container justify="flex-end" spacing={2}>
            {onBack &&
              <Grid item>
                <Button
                  id="btnVoltar"
                  variant="text"
                  color="default"
                  onClick={onBack}
                >
                  Voltar
              </Button>
              </Grid>
            }
            {onSubmit &&
              <Grid item>
                <form id="formRevisaCadastro" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                  <ButtonProgress
                    id="btnSubmit"
                    loading={isSubmitting}
                    variant="contained"
                    color="primary"
                    type="submit">
                    {submitText}
                  </ButtonProgress>
                </form>
              </Grid>
            }

          </Grid>

        </Box>
      </Paper >
    </ >
  )
}

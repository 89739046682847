import { Perfil } from "../models/enums/Perfil";
import CryptoJS from "crypto-js";
import { APIUtils } from "../services/api/APIUtils";

export interface LoginUsuario {
  id: number;
  noUsuario: string;
  noEmail: string;
  perfis: Perfil[];
  expiration: number;
  foto: number;
  token: string;
}

const isValid = (login: LoginUsuario) => {
  const now = Date.now().valueOf() / 1000;
  return (login.expiration > now);
}

const isUserInRole = (login: LoginUsuario, perfil: Perfil) => {
  return login.perfis.includes(perfil)
}

const isUserInAnyRole = (login: LoginUsuario, perfisAutorizados: Perfil[]) => {
  return login.perfis.some(perfil => perfisAutorizados.includes(perfil));
}

const fotoUrl = (login: LoginUsuario): string => {
  return APIUtils.generateImageURL({nuImagem:login.foto});
}

const encriptarSenha = (email: string, senha: string): string => {
  return CryptoJS.SHA512(senha + email + "updin").toString(CryptoJS.enc.Hex);
}

export const LoginUtils = { isValid, isUserInRole, isUserInAnyRole, fotoUrl, encriptarSenha };
import {createAsyncThunk, createEntityAdapter, createSlice, EntityState} from "@reduxjs/toolkit";
import moment from "moment";
import {fromEvent, throwError, of} from "rxjs";
import {catchError, takeUntil, timeout, map} from "rxjs/operators";
import {EnderecoVO} from "../../../models/Endereco";
import {api} from "../../../services/api/api";
import {APIError} from "../../../services/api/APIError";
import {APIUtils} from "../../../services/api/APIUtils";
import {RootState} from "../../configureStore";
import {RequestState, shouldFetch} from "../../RequestsState";
import {selectToken} from "../userSlice";
import {AxiosError} from "axios";

interface CEPSliceState {
  requests: EntityState<RequestState<any>>;
}

const requestsAdapter = createEntityAdapter<RequestState<any>>({
  selectId: request => request.id
});

const initialState = {
  requests: requestsAdapter.getInitialState(),
} as CEPSliceState


// REQUESTS

const REQ_CEP_FIND = "findCEP";

const requestSelectors = requestsAdapter.getSelectors((state: RootState) => state.entities.cep.requests)

const trackCEPFindRequest = (cep: string) => (state: RootState): RequestState<EnderecoVO> => {
  return requestSelectors.selectById(state, `${REQ_CEP_FIND}:${cep}`) ?? {id: `${REQ_CEP_FIND}:${cep}`, pending: false}
}

// THUNKS
const findCEP = createAsyncThunk<EnderecoVO | undefined, string, { state: RootState; rejectValue: APIError }>
('cep/find',
  async (cep, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.correios.fetchCep(cep, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => {
        console.log(result.data);
        return result.data
      }),
      catchError((err: AxiosError) => err.response?.status == 404 ? of(undefined) : throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: (args, {getState}) => shouldFetch(trackCEPFindRequest(args)(getState()), moment.duration(10, "minutes"))}
)


// SLICE
const cepSlice = createSlice({
  name: 'cep',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(findCEP.pending, (state) => {
      requestsAdapter.upsertOne(state.requests, {id: REQ_CEP_FIND, pending: true, error: undefined, data: undefined});
    })
    builder.addCase(findCEP.fulfilled, (state, {payload: endereco}) => {
      requestsAdapter.updateOne(state.requests, {
        id: REQ_CEP_FIND,
        changes: {pending: false, timestamp: moment().toISOString(), data: endereco}
      });
    })
    builder.addCase(findCEP.rejected, (state, action) => {
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {id: REQ_CEP_FIND, changes: {pending: false, error}});
    })
  }
})

export {
  findCEP,
  trackCEPFindRequest
};

export default cepSlice.reducer
import {Box, Container, createStyles, Grid, IconButton, makeStyles, Theme, Typography, Button} from '@material-ui/core';
import {grey} from '@material-ui/core/colors';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import HomeIcon from '@material-ui/icons/Home';
import MoneyIcon from '@material-ui/icons/Money';
import TodayIcon from '@material-ui/icons/Today';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import {AppDispatch} from '../../../../../browser';
import {DocumentoVO} from '../../../../models/Documento';
import {SituacaoInvestimento} from '../../../../models/enums/SituacaoInvestimento';
import {InvestimentoVO} from '../../../../models/Investimento';
import {
  searchPainelInvestimentos,
  trackSearchPainelInvestimentos,
  changeInvestimentoComprovanteDeposito,
  cancelarReserva
} from '../../../../stores/slices/entities/investidoresSlice';
import {selectLogin} from '../../../../stores/slices/userSlice';
import {LoginUsuario} from '../../../../utils/login';
import DashboardTotalizador from '../../../components/DashboardTotalizador';
import ErrorEmptyState from '../../../components/empty/ErrorEmptyState';
import Loading from '../../../components/Loading';
import {PageSubtitle} from '../../../layout/PageSubtitle';
import PageTemplate from '../../../layout/PageTemplate';
import PainelInvestimentoAprovadoItem from './components/PainelInvestimentoAprovadoItem';
import PainelInvestimentoCanceladoItem from './components/PainelInvestimentoCanceladoItem';
import PainelInvestimentoReservaItem from './components/PainelInvestimentoReservaItem';
import produce from 'immer';
import {unwrapResult} from '@reduxjs/toolkit';
import {generateErrorDialog} from '../../../../services/api/APIErrorUtils';
import {useDialog} from '../../../components/dialog/PageServiceProvider';
import {APIError} from '../../../../services/api/APIError';
import {MotivoCancelamentoInvestimento} from '../../../../models/enums/MotivoCancelamentoInvestimento';
import {useIntl} from "react-intl";

const useStyles = makeStyles((theme: Theme) => createStyles({
  paragrafo: {
    marginLeft: theme.spacing(2)
  },
}), {name: 'PainelInvestimentos'});

interface State {
  reservas: InvestimentoVO[];
  aprovados: InvestimentoVO[];
  cancelados: InvestimentoVO[];
  liquidados: InvestimentoVO[];
  totalReservas: number;
  totalAprovados: number;
  totalAtualizado: number;
  totalRendimentos: number;
}

export const PainelInvestimentosPage = () => {

  const classes = useStyles();
  const login = useSelector(selectLogin) as LoginUsuario;

  const intl = useIntl();

  const {pending: isLoading, data: investimentos, error} = useSelector(trackSearchPainelInvestimentos(login.id));

  const dispatch = useDispatch<AppDispatch>();

  const location = useLocation();
  const {from} = location.state as any || {from: {pathname: "/conta"}};

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const dialog = useDialog();

  const [state, setState] = useState<State | undefined>(undefined);

  useEffect(() => {
    const promise = dispatch(searchPainelInvestimentos({coContaUsuario: login.id}));
    return () => promise.abort();
  }, [dispatch, login.id]);


  useEffect(() => {
    if (investimentos) {
      const reservas = investimentos.filter(inv => inv.situacaoInvestimento === SituacaoInvestimento.Reserva);
      const totalReservas = reservas.reduce((acc, reserva) => acc + reserva.vrInvestimento, 0.0);
      const aprovados = investimentos.filter(inv => inv.situacaoInvestimento === SituacaoInvestimento.Aprovado);
      const totalAprovados = aprovados.reduce((acc, aprovado) => acc + aprovado.vrInvestimento, 0.0);
      const totalAtualizado = aprovados.reduce((acc, aprovado) => acc + aprovado.vrAtualizado, 0.0);
      const totalRendimentos = totalAprovados > 0.0 ? (totalAtualizado - totalAprovados) : 0.0;
      const cancelados = investimentos.filter(inv => inv.situacaoInvestimento === SituacaoInvestimento.Cancelado);
      const liquidados = investimentos.filter(inv => inv.situacaoInvestimento === SituacaoInvestimento.Liquidado);
      setState({
        reservas,
        aprovados,
        cancelados,
        liquidados,
        totalReservas,
        totalAprovados,
        totalAtualizado,
        totalRendimentos
      });
    }
  }, [investimentos])


  const handleUpdateComprovanteDeposito = async (coInvestimento: number, comprovante: Partial<DocumentoVO>) => {
    const resultAction = await dispatch(changeInvestimentoComprovanteDeposito({
      coContaUsuario: login.id,
      coInvestimento,
      comprovante
    }))
    if (changeInvestimentoComprovanteDeposito.fulfilled.match(resultAction)) {
      enqueueSnackbar('Comprovante enviado com sucesso!', {
        variant: 'success',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });
      const newComprovante = unwrapResult(resultAction);
      if (state) {
        setState(produce(draft => {
          const idx = state.reservas.findIndex(i => i.coInvestimento === coInvestimento);
          draft.reservas[idx].documentoComprovanteDeposito = newComprovante;
        }))
      }
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível enviar comprovate de depósito. Tente novamente."))
    }
  }

  const handleCancelarReserva = async (coInvestimento: number) => {
    const resultAction = await dispatch(cancelarReserva({
      coContaUsuario: login.id,
      coInvestimento,
      motivoCancelamento: MotivoCancelamentoInvestimento.Desistência
    }))
    if (cancelarReserva.fulfilled.match(resultAction)) {
      enqueueSnackbar('Reserva cancelada com sucesso!', {
        variant: 'success',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });
      dispatch(searchPainelInvestimentos({coContaUsuario: login.id})) // Faz refetch, já que mudou status e classificações
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível cancelar a reserva. Tente novamente."))
    }
  }

  return (

    <PageTemplate
      metaTags={{
        title: "Gottaup.com | Painel de Investimentos",
        description: "Painel de Investimentos"
      }}
      menuDesktop
      headerMobile={{
        title: "Painel de Investimentos",
        leftButtons: (
          <IconButton edge="start" color="inherit" component={Link} to={from}>
            <ArrowBackIcon/>
          </IconButton>
        )
      }}
      headerDesktop={{
        title: "Painel de Investimentos",
        breadcrumbs: [
          {label: "Home", to: '/', icon: HomeIcon},
          {label: "Painel"}
        ]
      }}
    >

      <Container component="section" style={{marginTop: 24}}>

        {isLoading &&
        <Loading text="Carregando painel de investimentos..."/>
        }

        {error &&
        <ErrorEmptyState
          error={error}
          defaultDescription="Não foi possível carregar painel de investimentos"
          onTryAgainClick={() => dispatch(searchPainelInvestimentos({coContaUsuario: login.id}))}
        />
        }

        {investimentos && state &&
        <>
          <Grid container spacing={3}>

            <Grid item lg={3} sm={6} xs={12}>

              <DashboardTotalizador
                nome="TOTAL RESERVADO"
                icon={<TodayIcon/>}
                iconBackgroundColor={grey[600]}
                valor={intl.formatNumber(state.totalReservas,{style:'currency', currency:'BRL'})}
              />
            </Grid>

            <Grid item lg={3} sm={6} xs={12}>

              <DashboardTotalizador
                nome="TOTAL INVESTIDO"
                icon={<AttachMoneyIcon/>}
                iconBackgroundColor="brown"
                valor={intl.formatNumber(state.totalAprovados,{style:'currency', currency:'BRL'})}
              />
            </Grid>

            <Grid item lg={3} sm={6} xs={12}>

              <DashboardTotalizador
                nome="RENDIMENTO"
                icon={<TrendingUpIcon/>}
                iconBackgroundColor="blue"
                valor={intl.formatNumber(state.totalRendimentos,{style:'currency', currency:'BRL'})}
              />


            </Grid>

            <Grid item lg={3} sm={6} xs={12}>

              <DashboardTotalizador
                nome="SALDO ATUALIZADO"
                icon={<MoneyIcon/>}
                iconBackgroundColor="white"
                iconTextColor="green"
                cardBackgroundColor="green"
                cardTextColor="white"
                valor={intl.formatNumber(state.totalAtualizado,{style:'currency', currency:'BRL'})}
              />


            </Grid>

          </Grid>

          <PageSubtitle>Suas Reservas</PageSubtitle>

          {state.reservas.length === 0 &&
          <Typography variant="body1" paragraph={true} className={classes.paragrafo}>Você não possui
            reservas.</Typography>
          }

          {state.reservas.length > 0 &&

          state.reservas.map(reserva => {
            return (
              <PainelInvestimentoReservaItem
                key={reserva.coInvestimento}
                reserva={reserva}
                onCancelarReservaSubmit={handleCancelarReserva}
                onFormSubmit={handleUpdateComprovanteDeposito}
              />
            );
          })}

          <PageSubtitle>Seus Investimentos</PageSubtitle>

          {state.aprovados.length === 0 &&
          <Typography variant="body1" paragraph={true} className={classes.paragrafo}>Você não possui
            investimentos.</Typography>
          }

          {state.aprovados.length > 0 &&

          state.aprovados.map(investimento => {
            return (
              <PainelInvestimentoAprovadoItem
                key={investimento.coInvestimento}
                investimento={investimento}
              />
            );
          })
          }

          {state.liquidados.length > 0 &&
          <>
            <PageSubtitle>Investimentos Liquidados</PageSubtitle>

            {state.liquidados.map(investimento => {
              return (
                <PainelInvestimentoAprovadoItem
                  key={investimento.coInvestimento}
                  investimento={investimento}
                />
              )
            })}
          </>
          }

          {state.cancelados.length > 0 &&
          <>

            <PageSubtitle>Reservas Canceladas</PageSubtitle>

            {state.cancelados.map(reserva => {
              return (
                <PainelInvestimentoCanceladoItem
                  key={reserva.coInvestimento || 0}
                  reserva={reserva}
                />
              );
            })}
          </>
          }

          <Box mt={3}></Box>
        </>
        }

      </Container>

    </PageTemplate>

  )


}
import {Button, Container, IconButton} from '@material-ui/core';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import HomeIcon from '@material-ui/icons/Home';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory, useParams} from 'react-router-dom';
import {AppDispatch} from '../../../../../../../browser';
import {fetchDetalhesCaptacao} from '../../../../../../stores/slices/entities/captacoesSlice';
import {
  findInvestidorByContaUsuario,
  sumTotalInvestimentosAno,
  trackFindInvestidorByContaUsuario
} from '../../../../../../stores/slices/entities/investidoresSlice';
import {selectLogin} from '../../../../../../stores/slices/userSlice';
import {LoginUsuario} from '../../../../../../utils/login';
import ErrorEmptyState from '../../../../../components/empty/ErrorEmptyState';
import EmptyState from '../../../../../components/EmptyState';
import Loading from '../../../../../components/Loading';
import PageTemplate from '../../../../../layout/PageTemplate';
import {EfetuarReservaForm} from './components/EfetuarReservaForm';

export const EfetuarReservaPage = () => {

  const login = useSelector(selectLogin) as LoginUsuario;

  const requestInvestidor = useSelector(trackFindInvestidorByContaUsuario(login.id))

  const dispatch = useDispatch<AppDispatch>();

  const {permalink} = useParams<{ permalink: string }>();

  const history = useHistory();

  const {from} = history.location.state as any || {from: `/investir/${permalink}`};

  useEffect(() => {
    const promiseInvestidor = dispatch(findInvestidorByContaUsuario({coContaUsuario: login.id}));
    const promiseSum = dispatch(sumTotalInvestimentosAno({coContaUsuario: login.id}));
    const promiseCaptacao = dispatch(fetchDetalhesCaptacao({permalink}))
    return () => {
      promiseInvestidor.abort();
      promiseSum.abort();
      promiseCaptacao.abort();
    }
  }, [dispatch, login.id, permalink]);


  return (
    <PageTemplate
      metaTags={{
        title: "Gottaup.com | Efetuar Reserva",
        description: "Efetue aqui a reserva de seu investimento"
      }}
      menuDesktop
      headerMobile={{
        title: "Efetuar Reserva",
        leftButtons: (
          <IconButton edge="start" color="inherit" component={Link} to={from}>
            <ArrowBackIcon/>
          </IconButton>
        )
      }}
      headerDesktop={{
        title: "Efetuar Reserva",
        breadcrumbs: [
          {label: "Home", to: '/', icon: HomeIcon},
          {label: "Investir", to: '/login', icon: MonetizationOnIcon},
          {label: "Projeto", to: `/investir/${permalink}`, icon: BusinessCenterIcon},
          {label: "Registro"}
        ]
      }}
    >
      {requestInvestidor.error &&
      <ErrorEmptyState
        error={requestInvestidor.error}
        defaultDescription="Erro ao carregar cadastro do investidor"
        onTryAgainClick={() => dispatch(findInvestidorByContaUsuario({coContaUsuario: login.id}))}
      />
      }
      {requestInvestidor.pending &&
      <Loading text="Carregando cadastro..."/>
      }
      {requestInvestidor.data &&
      <Container component="section" style={{marginTop: 24}}>
        {requestInvestidor.data.coContaUsuario
          ? <EfetuarReservaForm investidor={requestInvestidor.data}/>
          : <EmptyState
            icon={<AccountCircleOutlinedIcon style={{fontSize: '58px'}} color="disabled"/>}
            title="Você ainda não completou seu cadastro de investidor."
            subtitle="Efetue o preenchimento de seu cadastro de investidor para continuar com a reserva."
            action={
              <Button variant="contained" color="secondary" component={Link}
                      to={{pathname: "/conta/investidor", state: {from: history.location.pathname}}}>
                Completar cadastro
              </Button>
            }
          />
        }
      </Container>
      }
    </PageTemplate>
  )
}
import React from 'react';
import Button, { ButtonProps } from "@material-ui/core/Button";
import { Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const styles: (theme: Theme) => any = (theme) => {
  return {
    contained: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.error.dark
      }
    },
    outlined: {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      "&:hover": {
        borderColor: theme.palette.error.dark
      }
    },
    text: {
      color: theme.palette.error.main,
    }

  };
};

export const ButtonDanger = withStyles(styles)((props: ButtonProps) => {
  const { className } = props;
  const classes = props.classes || {};
  return <Button {...props} className={`${className} ${classes.root}`} />
});
import {Box, Card, CardActions, CardContent, CardHeader, Divider, Grid} from '@material-ui/core';
import React from 'react';
import {useForm} from 'react-hook-form';
import {ImagemVO} from '../../../../../../models/Imagem';
import {APIUtils} from '../../../../../../services/api/APIUtils';
import {FileUtils} from '../../../../../../utils/files';
import {ImageUtils} from '../../../../../../utils/images';
import ButtonProgress from '../../../../../components/ButtonProgress';
import {useDialog} from '../../../../../components/dialog/PageServiceProvider';
import FormPhotoUpload from '../../../../../components/form/inputs/FileUpload/FormPhotoUpload';

interface FotoPerfilEditForm {
  imagemPerfil?: ImagemVO;
  onFormSubmit: (imagemLogo: ImagemVO) => void;
}

interface FotoPerfilEditFormData {
  imagemPerfil: {
    noImagem: string;
    imImagem: string;
  };
}

export const FotoPerfilEditForm = (props: FotoPerfilEditForm) => {

  const confirm = useDialog();
  const {register, handleSubmit, errors, setValue, formState: {isValid, isSubmitting, dirty}} = useForm<FotoPerfilEditFormData>({mode: 'onBlur'});

  const handleChangeImagemPerfil = async (file: File | null) => {
    console.log("evento", file);
    if (file) {
      setValue("imagemPerfil.noImagem", file.name, true);
      const imageResized = FileUtils.dataURLtoBase64(await ImageUtils.resizeImageKeepProportions(await FileUtils.fileToDataURL(file), 200, 200));
      setValue("imagemPerfil.imImagem", imageResized);
    } else {
      setValue("imagemPerfil.noImagem", '', true);
      setValue("imagemPerfil.imImagem", '');
    }
  }

  const confirmaAlteracao = async ({imagemPerfil}: FotoPerfilEditFormData) => {
    const confirmed = await confirm({
      title: "Confirma alteração da foto de perfil?",
      description: "Ao confirmar o foto será trocada. Se cancelar, nenhuma alteração será salva no servidor.",
      confirmOption: "CONFIRMAR",
      cancelOption: "CANCELAR"
    })
    if (confirmed) {
      props.onFormSubmit(imagemPerfil as ImagemVO); //chama evento no pai para persistir
    }
  }

  return (
    <form
      id="formFotoPerfilEdit"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(confirmaAlteracao)}>

      <Card>
        <CardHeader title="Foto de Perfil"/>
        <Divider/>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <input
                hidden
                name="imagemPerfil.noImagem"
                ref={register({
                  validate: {
                    extension: value => !value || value.match(/\.(jpg)|(png)$/) ? true : 'Arquivo não aceito! Selecione uma imagem em formato .jpg ou .png',
                  }
                })}
                defaultValue={props.imagemPerfil?.nuImagem}/>
              <input
                hidden
                name="imagemPerfil.imImagem"
                ref={register()}
                defaultValue={props.imagemPerfil?.nuImagem}/>
              <FormPhotoUpload
                accept=".jpg, .png"
                variant="avatar"
                canEdit={true}
                canRemove={true}
                aspect={1}
                helperText="Utilize imagens .jpg ou .png com pelo menos 200px"
                error={errors.imagemPerfil?.noImagem?.message?.toString()}
                onChange={handleChangeImagemPerfil}
                previewURL={props.imagemPerfil && APIUtils.generateImageURL(props.imagemPerfil)}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider/>
        <CardActions>
          <Box display="flex" justifyContent="flex-end" width='100%'>
            <ButtonProgress
              color="secondary"
              variant="outlined"
              type="submit"
              loading={isSubmitting}
              disabled={!dirty || !isValid}
            >
              Salvar
            </ButtonProgress>
          </Box>
        </CardActions>
      </Card>
    </form>

  )
}
import { getServiceURL } from "../services/ServiceURL";
import axios from "axios-observable"
import { setupCache } from 'axios-cache-adapter'
import { AxiosRequestConfig } from "axios";
import { AxiosObservable } from "axios-observable/dist/axios-observable.interface";

let cache;

if (!__isBrowser__) {
  // Cache enabled only on SSR
  cache = setupCache({
    maxAge: 0,
    debug: __isDevelopment__,
    exclude: { query: false },
  })
}

const axiosInstance = axios.create({
  baseURL: `${getServiceURL()}/api`,
  timeout: TRANSACTION_TIMEOUT,
  responseType: 'json',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  validateStatus: function (status) {
    return status >= 200 && status < 300;
  },
  adapter: cache?.adapter, // Cache enabled only on SSR
})

axiosInstance.interceptors.request.use(function (config) {
  if (__isBrowser__ && location.hostname != "localhost" && navigator.onLine !== undefined && !navigator.onLine) {
    return Promise.reject(new Error("Offline Error"))
  }
  return config;
}, function (error) { return Promise.reject(error); });

const get = <T>(url: string, config?: AxiosRequestConfig): AxiosObservable<T> => {
  return axiosInstance.get<T>(url, config)
};

const post = <T>(url: string, body: any, config?: AxiosRequestConfig): AxiosObservable<T> => {
  return axiosInstance.post<T>(url, body, config)
};

const put = <T>(url: string, body: any, config?: AxiosRequestConfig): AxiosObservable<T> => {
  return axiosInstance.put<T>(url, body, config)
};

const patch = <T>(url: string, body: any, config?: AxiosRequestConfig): AxiosObservable<T> => {
  return axiosInstance.patch<T>(url, body, config)
};

const deleteR = <T>(url: string, config?: AxiosRequestConfig): AxiosObservable<T> => {
  return axiosInstance.delete(url, config)
};

export default { get, post, put, patch, delete: deleteR };
import {createAsyncThunk, createEntityAdapter, createSlice, DeepPartial, EntityState} from "@reduxjs/toolkit";
import {RequestState, shouldFetch} from "../../RequestsState";
import {RootState} from "../../configureStore";
import {ContaUsuarioVO} from "../../../models/ContaUsuario";
import {APIError} from "../../../services/api/APIError";
import {selectToken} from "../userSlice";
import {api} from "../../../services/api/api";
import {catchError, map, takeUntil, timeout} from "rxjs/operators";
import {fromEvent, throwError} from "rxjs";
import {APIUtils} from "../../../services/api/APIUtils";
import moment from "moment";
import {LoginUtils} from "../../../utils/login";
import {Perfil} from "../../../models/enums/Perfil";
import {ImagemVO} from "../../../models/Imagem";

interface ContaUsuarioSliceState {
  requests: EntityState<RequestState<any>>;
}

const requestsAdapter = createEntityAdapter<RequestState<any>>({
  selectId: request => request.id
});

const initialState: ContaUsuarioSliceState = {
  requests: requestsAdapter.getInitialState(),
}


// REQUESTS
const requestSelectors = requestsAdapter.getSelectors((state: RootState) => state.entities.contaUsuarios.requests)

const REQ_CREATE_USUARIO = "createUsuario";
const REQ_FETCH_USUARIO = "fetchUsuario";
const REQ_FETCH_USUARIOS_LIST = "fetchUsuariosList";
const REQ_PATCH_USUARIO_DADOS_BASICOS = "patchUsuarioDadosBasicos";
const REQ_CHANGE_USUARIO_SENHA = "changeUsuarioSenha";
const REQ_CHANGE_USUARIO_PERFIS = "changeUsuarioPerfis";
const REQ_CHANGE_USUARIO_IMAGEM_PERFIL = "changeUsuarioImagemPerfil";

const trackCreateUsuario = (state: RootState): RequestState<ContaUsuarioVO> => {
  return requestSelectors.selectById(state, REQ_CREATE_USUARIO) ?? {id: REQ_CREATE_USUARIO, pending: false}
}

const trackFetchUsuario = (id: number) => (state: RootState): RequestState<ContaUsuarioVO> => {
  return requestSelectors.selectById(state, `${REQ_FETCH_USUARIO}:${id}`) ?? {
    id: `${REQ_FETCH_USUARIO}:${id}`,
    pending: false
  }
}

const trackFetchUsuariosList = () => (state: RootState): RequestState<ContaUsuarioVO[]> => {
  return requestSelectors.selectById(state, `${REQ_FETCH_USUARIOS_LIST}`) ?? {
    id: `${REQ_FETCH_USUARIOS_LIST}`,
    pending: false
  }
}

const trackChangeUsuarioSenha = (id: number) => (state: RootState): RequestState<void> => {
  return requestSelectors.selectById(state, `${REQ_CHANGE_USUARIO_SENHA}:${id}`) ?? {
    id: `${REQ_CHANGE_USUARIO_SENHA}:${id}`,
    pending: false
  }
}

const trackChangeUsuarioPerfis = (id: number) => (state: RootState): RequestState<void> => {
  return requestSelectors.selectById(state, `${REQ_CHANGE_USUARIO_PERFIS}:${id}`) ?? {
    id: `${REQ_CHANGE_USUARIO_PERFIS}:${id}`,
    pending: false
  }
}

const trackChangeUsuarioImagemPerfil = (id: number) => (state: RootState): RequestState<void> => {
  return requestSelectors.selectById(state, `${REQ_CHANGE_USUARIO_IMAGEM_PERFIL}:${id}`) ?? {
    id: `${REQ_CHANGE_USUARIO_IMAGEM_PERFIL}:${id}`,
    pending: false
  }
}

const trackPatchUsuarioDadosBasicos = (id: number) => (state: RootState): RequestState<void> => {
  return requestSelectors.selectById(state, `${REQ_PATCH_USUARIO_DADOS_BASICOS}:${id}`) ?? {
    id: `${REQ_PATCH_USUARIO_DADOS_BASICOS}:${id}`,
    pending: false
  }
}


const createUsuario = createAsyncThunk<ContaUsuarioVO, { usuario: DeepPartial<ContaUsuarioVO> }, { state: RootState; rejectValue: APIError }>
('contaUsuario/createUsuario',
  async ({usuario}, {signal, rejectWithValue, getState}) => {
    usuario.senha = LoginUtils.encriptarSenha(usuario.noEmail || '', usuario.senha || '');
    const token = selectToken(getState()) as string;
    return api.usuarios.createUsuario(usuario, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: (args, {getState}) => shouldFetch(trackCreateUsuario(getState()))}
)

const fetchUsuario = createAsyncThunk<ContaUsuarioVO, { id: number }, { state: RootState; rejectValue: APIError }>
('contaUsuario/fetchUsuario',
  async ({id}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.usuarios.fetchUsuario(id, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({id}, {getState}) => shouldFetch(trackFetchUsuario(id)(getState()))}
)

const fetchUsuariosList = createAsyncThunk<ContaUsuarioVO[], void, { state: RootState; rejectValue: APIError }>
('contaUsuario/fetchUsuariosList',
  async (args, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.usuarios.fetchUsuarios(token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: (args, {getState}) => shouldFetch(trackFetchUsuariosList()(getState()))}
)

const patchUsuarioDadosBasicos = createAsyncThunk<void, { id: number; changes: DeepPartial<ContaUsuarioVO> }, { state: RootState; rejectValue: APIError }>
('contaUsuario/patchUsuarioDadosBasicos',
  async ({id, changes}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.usuarios.patchUsuarioDadosBasicos(id, changes, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({id}, {getState}) => shouldFetch(trackPatchUsuarioDadosBasicos(id)(getState()))}
)

const changeUsuarioSenha = createAsyncThunk<void, { id: number; email: string; senha: string }, { state: RootState; rejectValue: APIError }>
('contaUsuario/changeUsuarioSenha',
  async ({id, email, senha}, {signal, rejectWithValue, getState}) => {
    const senhaCrypto = LoginUtils.encriptarSenha(email,senha);
    const token = selectToken(getState()) as string;
    return api.usuarios.changeUsuarioSenha(id, senhaCrypto, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({id}, {getState}) => shouldFetch(trackChangeUsuarioSenha(id)(getState()))}
)

const changeUsuarioPerfis = createAsyncThunk<void, { id: number; perfis: Perfil[] }, { state: RootState; rejectValue: APIError }>
('contaUsuario/changeUsuarioPerfis',
  async ({id, perfis}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.usuarios.changeUsuarioPerfis(id, perfis, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({id}, {getState}) => shouldFetch(trackChangeUsuarioPerfis(id)(getState()))}
)

const changeUsuarioImagemPerfil = createAsyncThunk<void, { id: number; imagemPerfil: ImagemVO }, { state: RootState; rejectValue: APIError }>
('contaUsuario/changeUsuarioImagemPerfil',
  async ({id, imagemPerfil}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.usuarios.changeUsuarioImagemPerfil(id, imagemPerfil, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({id}, {getState}) => shouldFetch(trackChangeUsuarioImagemPerfil(id)(getState()))}
)

const contaUsuariosSlice = createSlice({
  name: 'contasUsuarios',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    //
    builder.addCase(createUsuario.pending, (state) => {
      requestsAdapter.upsertOne(state.requests, {
        id: REQ_CREATE_USUARIO,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(createUsuario.fulfilled, (state, {payload: data}) => {
      requestsAdapter.updateOne(state.requests, {
        id: REQ_CREATE_USUARIO,
        changes: {pending: false, timestamp: moment().toISOString(), data}
      });
    })
    builder.addCase(createUsuario.rejected, (state, action) => {
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: REQ_CREATE_USUARIO,
        changes: {pending: false, error}
      });
    })

    //
    builder.addCase(fetchUsuario.pending, (state, {meta: {arg: {id}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_FETCH_USUARIO}:${id}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(fetchUsuario.fulfilled, (state, {payload: usuario, meta: {arg: {id}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_FETCH_USUARIO}:${id}`,
        changes: {pending: false, timestamp: moment().toISOString(), data: usuario}
      });
    })
    builder.addCase(fetchUsuario.rejected, (state, action) => {
      const {id: cnpj} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {id: `${REQ_FETCH_USUARIO}:${cnpj}`, changes: {pending: false, error}});
    })

    //
    builder.addCase(fetchUsuariosList.pending, (state) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_FETCH_USUARIOS_LIST}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(fetchUsuariosList.fulfilled, (state, {payload: usuarios}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_FETCH_USUARIOS_LIST}`,
        changes: {pending: false, timestamp: moment().toISOString(), data: usuarios}
      });
    })
    builder.addCase(fetchUsuariosList.rejected, (state, action) => {
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {id: `${REQ_FETCH_USUARIOS_LIST}`, changes: {pending: false, error}});
    })

    //
    builder.addCase(patchUsuarioDadosBasicos.pending, (state, {meta: {arg: {id}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_PATCH_USUARIO_DADOS_BASICOS}:${id}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(patchUsuarioDadosBasicos.fulfilled, (state, {meta: {arg: {id}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_PATCH_USUARIO_DADOS_BASICOS}:${id}`,
        changes: {pending: false, timestamp: moment().toISOString()}
      });
    })
    builder.addCase(patchUsuarioDadosBasicos.rejected, (state, action) => {
      const {id} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_PATCH_USUARIO_DADOS_BASICOS}:${id}`,
        changes: {pending: false, error}
      });
    })

    //
    builder.addCase(changeUsuarioSenha.pending, (state, {meta: {arg: {id}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_CHANGE_USUARIO_SENHA}:${id}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(changeUsuarioSenha.fulfilled, (state, {meta: {arg: {id}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_CHANGE_USUARIO_SENHA}:${id}`,
        changes: {pending: false, timestamp: moment().toISOString()}
      });
    })
    builder.addCase(changeUsuarioSenha.rejected, (state, action) => {
      const {id} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_CHANGE_USUARIO_SENHA}:${id}`,
        changes: {pending: false, error}
      });
    })

    //
    builder.addCase(changeUsuarioPerfis.pending, (state, {meta: {arg: {id}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_CHANGE_USUARIO_PERFIS}:${id}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(changeUsuarioPerfis.fulfilled, (state, {meta: {arg: {id}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_CHANGE_USUARIO_PERFIS}:${id}`,
        changes: {pending: false, timestamp: moment().toISOString()}
      });
    })
    builder.addCase(changeUsuarioPerfis.rejected, (state, action) => {
      const {id} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_CHANGE_USUARIO_PERFIS}:${id}`,
        changes: {pending: false, error}
      });
    })

    //
    builder.addCase(changeUsuarioImagemPerfil.pending, (state, {meta: {arg: {id}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_CHANGE_USUARIO_IMAGEM_PERFIL}:${id}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(changeUsuarioImagemPerfil.fulfilled, (state, {meta: {arg: {id}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_CHANGE_USUARIO_IMAGEM_PERFIL}:${id}`,
        changes: {pending: false, timestamp: moment().toISOString()}
      });
    })
    builder.addCase(changeUsuarioImagemPerfil.rejected, (state, action) => {
      const {id} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_CHANGE_USUARIO_IMAGEM_PERFIL}:${id}`,
        changes: {pending: false, error}
      });
    })

  }
})

export {
  createUsuario,
  fetchUsuario,
  fetchUsuariosList,
  patchUsuarioDadosBasicos,
  changeUsuarioPerfis,
  changeUsuarioSenha,
  changeUsuarioImagemPerfil,
  trackFetchUsuario,
  trackFetchUsuariosList
}

export default contaUsuariosSlice.reducer

import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Typography, Button } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import ArrowRightIcon from 'mdi-material-ui/ArrowRight';
import curvyLinesImage from '../../../images/home/curvy_lines.png'
import registreImage from '../../../images/home/registre.svg'
import acompanheImage from '../../../images/home/acompanhe.svg'
import avalieImage from '../../../images/home/avalie.svg'

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    backgroundColor: '#fff5f8',
    overflow: 'hidden',
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  title: {
    marginBottom: theme.spacing(14),
  },
  number: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  image: {
    height: 55,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
    opacity: 0.7,
  },
  button: {
    marginTop: theme.spacing(8),
    border: '4px solid currentColor',
    borderRadius: 0,
    height: 'auto',
    padding: theme.spacing(2, 5),
    backgroundColor: '#fff5f8'
  },
  markedH2Center: {
    height: 4,
    width: 73,
    display: 'block',
    margin: `${theme.spacing(1)}px auto 0`,
    backgroundColor: theme.palette.primary.main,
  },
}), { name: 'ProductHowItWorks' });

export default function ProductHowItWorks() {
  const classes = useStyles();
  const location = useLocation();

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <img
          src={curvyLinesImage}
          className={classes.curvyLines}
          alt="curvy lines"
        />
        <Typography variant="h2" className={classes.title}>
          COMO FUNCIONA
          <span className={classes.markedH2Center}/>
        </Typography>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <div className={classes.number}>1.</div>
                <img
                  src={registreImage}
                  alt="Registro na Plataforma"
                  className={classes.image}
                />
                <Typography variant="h6" align="center">
                  Faça o seu cadastro na plataforma.
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <div className={classes.number}>2.</div>
                <img
                  src={avalieImage}
                  alt="graph"
                  className={classes.image}
                />
                <Typography variant="h6" align="center">
                  Avalie as captações disponíveis.
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <div className={classes.number}>3.</div>
                <img
                  src={acompanheImage}
                  alt="clock"
                  className={classes.image}
                />
                <Typography variant="h6" align="center">
                  Invista e acompanhe os resultados do seu portfólio.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
        <Button
          size="large"
          className={classes.button}
          component={Link}
          endIcon={<ArrowRightIcon />}
          to={{ pathname: "/login/registro", state: { from: location.pathname } }}
        >
          Registre-se agora
      </Button>
      </Container>
    </section>
  );
}

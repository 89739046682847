import React from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import OnlyMobile from './OnlyMobile';

const useStyles = makeStyles((theme: Theme) => createStyles({
    flex: {
        flex: 1
    },
    toolbarMargin: theme.mixins.toolbar,
}), { name: 'HeaderMobile' });

export interface PageHeaderMobileProps {
    leftButtons?: React.ReactNode;
    rightButtons?: React.ReactNode;
    title: string;
}

export default function PageHeaderMobile(props: PageHeaderMobileProps) {

    const classes = useStyles();
    const { title, leftButtons, rightButtons } = props;

    return (
        <OnlyMobile>
            <AppBar position="fixed" elevation={2} color="primary">
                <Toolbar>
                    {leftButtons}
                    <Typography variant="h6" color="inherit" className={classes.flex} >
                        {title}
                    </Typography>
                    {rightButtons}
                </Toolbar>
            </AppBar>
            <div className={classes.toolbarMargin} />
        </OnlyMobile>

    )
}

/**
 * Compound Interest (CI)
 * P [(1 + i)n – 1]
 * @param P principal
 * @param i rate
 * @param n number of compoundings
 */
const CI = (P: number, i: number, n: number): number => {
  return P * (((1 + i) ** n) - 1);
};


/**
 * Present Value (FV)
 * PV = FV / (1 + i) n
 * @param FV Future Value
 * @param i rate
 * @param n number of compoundings
 */
const PV = (FV: number, i: number, n: number): number => {
  return FV * (1 + i) ** n;
};

/**
 * Future Value (FV)
 * FV = PV (1 +i)n
 * @param PV Present Value
 * @param i rate
 * @param n number of compoundings
 */
const FV = (PV: number, i: number, n: number): number => {
  return PV * (1 + i) ** n;
};


export const Finance = {
  CI,
  FV,
  PV
}
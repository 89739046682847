import React, {FormEvent, useReducer, useRef, useState} from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import GroupIcon from '@material-ui/icons/Group';
import HomeIcon from '@material-ui/icons/Home';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import {useSnackbar} from 'notistack';
import {useDispatch} from 'react-redux';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {ContaUsuarioVO} from '../../../../../models/ContaUsuario';
import {Perfil} from '../../../../../models/enums/Perfil';
import Imagem from '../../../../../models/Imagem';
import {BannerProps} from '../../../../components/Banner';
import ImageCropper, {CroppedProps} from '../../../../components/ImageCropper';
import Message, {MessageProps} from '../../../../components/Message';
import TextInput from '../../../../components/TextInput';
import PageTemplate from '../../../../layout/PageTemplate';
import {
  getBase64,
  getCroppedImg,
  getPathProperty,
  resizeImageKeepProportions,
  setPathProperty
} from '../../../../ViewUtils';
import {APIError} from "../../../../../services/api/APIError";
import {generateErrorDialog} from "../../../../../services/api/APIErrorUtils";
import {DeepPartial} from "redux";
import {AppDispatch} from "../../../../../../browser";
import {createUsuario} from "../../../../../stores/slices/entities/contaUsuariosSlice";
import {useDialog} from "../../../../components/dialog/PageServiceProvider";

interface State {
  usuario: DeepPartial<ContaUsuarioVO>;
  senhaConfirmacao: string;
}

export const UsuariosCreatePage = () => {

  const dispatch = useDispatch<AppDispatch>();

  const dialog = useDialog();

  const [unsavedFormData, setUnsavedFormData] = useState(false);
  const [invalidFormData, setInvalidFormData] = useState(false);
  const fieldsUpdated = useRef<string[]>([]);

  const [banner, setBanner] = useState<BannerProps | undefined>(undefined);
  //const [dialog, setDialog] = useState<ConfirmDialogProps | undefined>(undefined);
  const [message, setMessage] = useState<MessageProps | undefined>(undefined);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [, forceUpdate] = useReducer(flag => !flag, false);
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const history = useHistory();

  const [imagemPerfilOriginal, setImagemPerfilOriginal] = useState<string | undefined>(undefined);
  const imagemPerfilCrop = useRef<CroppedProps | undefined>(undefined);
  const imageCropperRef = useRef<HTMLDivElement | null>(null)
  const fileInputRef = useRef<HTMLInputElement>(null);

  const location = useLocation();
  const {from} = location.state as any || {from: {pathname: "/admin/usuarios"}};

  const state = useRef<State>(
    {
      usuario: {
        perfis: new Array<Perfil>()
      },
      senhaConfirmacao: ''
    });

  const scrollToCropper = () => {
    if (imageCropperRef.current) {
      imageCropperRef.current.scrollIntoView({behavior: "smooth"})
    }
  }

  const handleValidInput = (path: string) => (newValue: string) => {
    const currValue = getPathProperty(path, state.current);
    if (currValue !== newValue) {
      setPathProperty(path, state.current, newValue);
      if (!fieldsUpdated.current.includes(path)) {
        fieldsUpdated.current.push(path); // Marca que o campo foi alterado
      }
      if (!unsavedFormData) {
        setUnsavedFormData(true); // Seta que o form possui alterações não salvas
      }
      console.log(state.current);
    }
    if (state.current.usuario.senha && state.current.senhaConfirmacao && (state.current.usuario.senha !== state.current.senhaConfirmacao)) {
      // Exibe mensagem que as senhas não conferem
      setMessage({
        content: "Senhas digitadas não conferem",
        onDismiss: () => setMessage(undefined)
      })
    }
  }

  const handleInvalidInput = (path: string) => () => {
    if (!fieldsUpdated.current.includes(path)) {
      fieldsUpdated.current.push(path); // Marca que o campo foi alterado
    }
    if (!invalidFormData) {
      setInvalidFormData(true); // Seta que o form possui dados inválidos
    }
    if (!unsavedFormData) {
      setUnsavedFormData(true); // Seta que o form possui alterações não salvas
    }
  }

  const handleChangeCheckboxPerfil = (value: Perfil) => () => {
    if (state.current && state.current.usuario.perfis) {
      const currentIndex = state.current.usuario.perfis.indexOf(value);
      if (currentIndex === -1) {
        state.current.usuario.perfis.push(value);
      } else {
        state.current.usuario.perfis.splice(currentIndex, 1);
      }
    }
    forceUpdate();
    console.log(state.current);
  };

  const triggerFileUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }

  const handleFileUpload = (path: string) => async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const image = event.target.files[0];
      if (image) {
        if (event.target.accept) {
          const acceptArray = event.target.accept.replace('.', '').split(",");
          const allowedExtensions = new RegExp("(.[" + acceptArray.join("|") + "])$", "i");
          if (allowedExtensions.exec(image.name)) {
            const imageBase64 = await getBase64(image);
            const encoded = imageBase64.replace(/^data:(.*,)?/, '');
            const imagem = new Imagem();
            imagem.noImagem = image.name;
            imagem.imImagem = encoded;
            setImagemPerfilOriginal(imageBase64);
            setPathProperty(path, state.current, imagem);
            console.log(state.current);
            scrollToCropper();
            return;
          }
        }
      }
      // Arquivo invalido
      setPathProperty(path, state.current, undefined);
      const dismissButton = (key: string | number | undefined) => <IconButton
        onClick={() => closeSnackbar(key)}><CloseIcon color="action"/></IconButton>
      enqueueSnackbar('Imagem não aceita! Selecione um arquivo .jpg ou .png', {
        variant: 'error',
        action: dismissButton,
        autoHideDuration: 5000
      });

    }
  }

  const handleCropComplete = (croppedProps: CroppedProps) => {
    imagemPerfilCrop.current = croppedProps;
  }

  const submitCreateUsuario = async () => {
    setUnsavedFormData(false);
    if (imagemPerfilOriginal) {
      let imageBase64 = imagemPerfilOriginal;

      // Se a imagem foi cortada, gera base64 da imagem cortada
      if (imagemPerfilCrop.current) {
        imageBase64 = await getCroppedImg(
          imagemPerfilOriginal,
          imagemPerfilCrop.current.area,
          imagemPerfilCrop.current.rotation
        )
      }

      const imageResized = await resizeImageKeepProportions(imageBase64, 200, 200);
      const encoded = imageResized.replace(/^data:(.*,)?/, '');
      if (state.current.usuario.imagemPerfil) {
        state.current.usuario.imagemPerfil.imImagem = encoded;
      }
      console.log(state.current);
    }

    const resultAction = await dispatch(createUsuario({usuario: state.current.usuario}));
    if (createUsuario.fulfilled.match(resultAction)) {
      enqueueSnackbar('Usuário cadastrado com sucesso!', {
        variant: 'success',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });

      history.push('/admin/usuarios');
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível concluir o cadastro do usuário. Tente novamente."))
    }
  }

  const validaConfirmaCadastro = async (e: FormEvent) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    if (!form.checkValidity()) {
      setFormSubmitted(true);
      setBanner({
        text: "Alguns campos do formulário estão inválidos. Efetue o ajuste e tente novamente.",
        icon: <ReportProblemIcon/>,
        buttons: <Button onClick={() => {
          setBanner(undefined);
        }} color="primary">OK</Button>
      })
      scrollTo({top: 0, left: 0, behavior: 'smooth'});
    } else {
      if (state.current.usuario.senha !== state.current.senhaConfirmacao) {
        // Exibe mensagem que as senhas não conferem
        setMessage({
          content: "Senhas digitadas não conferem",
          onDismiss: () => setMessage(undefined)
        })
      } else {
        const confirmed = await dialog({
          title: "Confirma cadastro do Usuário?",
          description: "Ao confirmar as informações serão salvas. Se cancelar, nenhum dado será salvo no servidor.",
          confirmOption: "CONFIRMAR",
          cancelOption: "CANCELAR"
        })
        if (confirmed) submitCreateUsuario();
      }
    }
  }


  return (
    <PageTemplate
      metaTags={{
        title: "Gottaup.com | Cadastrar Usuário",
        description: "Cadastro de novos usuários"
      }}
      menuDesktop
      headerMobile={{
        title: "Cadastrar Usuário",
        leftButtons: (
          <IconButton edge="start" color="inherit" component={Link} to={from}>
            <ArrowBackIcon/>
          </IconButton>
        )
      }}
      headerDesktop={{
        title: "Cadastrar Usuário",
        breadcrumbs: [
          {label: "Home", to: '/', icon: HomeIcon},
          {label: "Usuários", to: '/admin/usuarios', icon: GroupIcon},
          {label: "Cadastrar"}
        ]
      }}
      banner={banner}
      confirmLeavePage={{
        when: unsavedFormData,
        title: "Tem certeza que deseja deixar a página sem salvar as alterações?",
        content: "Ao confirmar os dados alterados não serão salvos. Se cancelar, poderá continuar a edição."
      }}
    >

      <Container component="section" style={{marginTop: 24}}>

        <form id="formCreateUsuario" noValidate autoComplete="off" onSubmit={validaConfirmaCadastro}>

          <Grid container spacing={3}>

            <Grid item xs={12} md={6}>
              <Card>
                <CardHeader title="Dados Básicos"/>
                <Divider/>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>

                      <TextInput
                        id="usuario.noUsuario"
                        required
                        label="Nome do Usuário"
                        helperText="Nome Completo"
                        maxLength={100}
                        onValidInput={handleValidInput("usuario.noUsuario")}
                        onInvalidInput={handleInvalidInput("usuario.noUsuario")}
                        validationMessages={{
                          invalid: 'Informe o Nome Completo'
                        }}
                        formSubmitted={formSubmitted}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput
                        id="usuario.noEmail"
                        type="email"
                        required
                        label="Email"
                        helperText="Email que será utilizado como login"
                        placeholder="usuario@domínio.com"
                        maxLength={100}
                        onValidInput={handleValidInput("usuario.noEmail")}
                        onInvalidInput={handleInvalidInput("usuario.noEmail")}
                        validationMessages={{
                          invalid: 'Email inválido'
                        }}
                        formSubmitted={formSubmitted}
                        pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
                      />

                    </Grid>

                    <Grid item xs={12}>
                      <TextInput
                        id="usuario.senha"
                        required
                        label="Senha"
                        placeholder="*******"
                        helperText="Senha com 6 a 8 caractéres"
                        maxLength={8}
                        minLength={6}
                        onValidInput={handleValidInput("usuario.senha")}
                        onInvalidInput={handleInvalidInput("usuario.senha")}
                        validationMessages={{
                          tooShort: 'Senha deve conter ao menos 6 caractéres',
                          invalid: 'Informe a Senha'
                        }}
                        formSubmitted={formSubmitted}
                        type="password"
                      />

                    </Grid>

                    <Grid item xs={12}>
                      <TextInput
                        id="usuario.senhaConfirmacao"
                        required
                        label="Confirmação da Senha"
                        placeholder="*******"
                        maxLength={8}
                        minLength={6}
                        onValidInput={handleValidInput("senhaConfirmacao")}
                        onInvalidInput={handleInvalidInput("senhaConfirmacao")}
                        validationMessages={{
                          tooShort: 'Senha deve conter ao menos 6 caractéres',
                          invalid: 'Informe a Senha'
                        }}
                        formSubmitted={formSubmitted}
                        type="password"
                      />
                    </Grid>

                  </Grid>
                </CardContent>
                {message &&
                <Box my={3}>
                  <Message {...message} />
                </Box>
                }
              </Card>
            </Grid>

            <Grid item xs={12} md={6} ref={imageCropperRef}>
              <Card>
                <CardHeader
                  title="Foto de Perfil"
                />
                <Divider/>
                <CardContent>
                  <Grid container spacing={3}>
                    {imagemPerfilOriginal
                      ?
                      <Grid item xs={12}>
                        <ImageCropper
                          image={imagemPerfilOriginal}
                          aspect={1}
                          showGrid={false}
                          cropShape="round"
                          onCropComplete={handleCropComplete}
                        />
                      </Grid>
                      :
                      <Grid item xs={12} container alignItems="center" justify="center" direction="column">
                        <AccountCircleIcon style={{fontSize: '200px'}} color="disabled"/>
                        <Typography variant="caption" color="textSecondary">Prefira imagens .jpg ou .png com pelo menos
                          200px</Typography>
                      </Grid>
                    }

                  </Grid>
                </CardContent>
                <Divider/>
                <CardActions>
                  <Grid container justify="flex-end" spacing={2}>
                    <Grid item>
                      <input id="usuario.imagemPerfil" type="file" ref={fileInputRef} hidden accept=".jpg, .png"
                             onChange={handleFileUpload("usuario.imagemPerfil")}/>
                      <Button
                        color="secondary"
                        variant="outlined"
                        onClick={triggerFileUpload}
                      >
                        Upload Foto
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card>
                <CardHeader title="Perfis Autorizados"/>
                <Divider/>
                <CardContent>

                  <Grid container spacing={3}>
                    <Grid item xs={12}>

                      <FormControl required
                                   error={formSubmitted && state.current && state.current.usuario.perfis && state.current.usuario.perfis.length == 0}
                                   component="fieldset">
                        <FormLabel component="legend">Selecione os perfis</FormLabel>
                        <FormGroup>
                          {Object.keys(Perfil).filter(key => !isNaN(Number(key))).map(key => (
                            <FormControlLabel
                              key={key}
                              control={
                                <Checkbox
                                  id={`usuario.perfis[${key}]`}
                                  value={key}
                                  onChange={handleChangeCheckboxPerfil(Number(key))}
                                  required={state.current && state.current.usuario.perfis && state.current.usuario.perfis.length == 0}
                                />
                              }
                              label={Perfil[Number(key)]}
                            />
                          ))}
                        </FormGroup>
                        <FormHelperText>Selecione ao menos 1 perfil</FormHelperText>
                      </FormControl>
                    </Grid>

                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="flex-end" mt={1} mb={1}>
            <Button id="btnCadastrarUsuario" variant="contained" color="secondary" type="submit">Cadastrar</Button>
          </Box>

        </form>
      </Container>


    </PageTemplate>
  )
}
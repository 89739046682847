import {createAsyncThunk, createSlice, EntityState, DeepPartial, createEntityAdapter} from "@reduxjs/toolkit";
import moment from "moment";
import {fromEvent, throwError} from "rxjs";
import {catchError, takeUntil, timeout, map} from "rxjs/operators";
import {EmpresaVO} from "../../../models/Empresa";
import {api} from "../../../services/api/api";
import {APIError} from "../../../services/api/APIError";
import {APIUtils} from "../../../services/api/APIUtils";
import {RootState} from "../../configureStore";
import {RequestState, shouldFetch} from "../../RequestsState";
import {selectToken} from "../userSlice";
import {EnderecoVO} from "../../../models/Endereco";
import {DadoBancarioVO} from "../../../models/DadoBancario";
import {ImagemVO} from "../../../models/Imagem";
import {ProjetoVO} from "../../../models/Projeto";

interface EmpresasSliceState {
  requests: EntityState<RequestState<any>>;
}

const requestsAdapter = createEntityAdapter<RequestState<any>>({
  selectId: request => request.id
});

const initialState = {
  requests: requestsAdapter.getInitialState()
} as EmpresasSliceState


// REQUESTS

const REQ_FETCH_EMPRESAS = "fetchEmpresas";
const REQ_FETCH_EMPRESAS_COMBO = "fetchEmpresasCombo";
const REQ_FETCH_EMPRESA_PROJETOS = "fetchEmpresaProjetos"
const REQ_CREATE_EMPRESA = "createEmpresa";
const REQ_FETCH_EMPRESA_BY_CNPJ = "fetchEmpresaByCnpj";
const REQ_DELETE_EMPRESA_BY_CNPJ = "deleteEmpresaByCnpj";
const REQ_PATCH_EMPRESA_ENDERECO = "patchEmpresaEndereco";
const REQ_PATCH_EMPRESA_DADO_BANCARIO = "patchEmpresaDadoBancario";
const REQ_PATCH_EMPRESA_DADOS_BASICOS = "patchEmpresaDadosBasicos";
const REQ_CHANGE_EMPRESA_IMAGEM_LOGO = "changeEmpresaImagemLogo";

const requestSelectors = requestsAdapter.getSelectors((state: RootState) => state.entities.empresas.requests)

const trackFetchEmpresas = (state: RootState): RequestState<EmpresaVO[]> => {
  return requestSelectors.selectById(state, REQ_FETCH_EMPRESAS) ?? {id: REQ_FETCH_EMPRESAS, pending: false}
}

const trackFetchEmpresasCombo = (state: RootState): RequestState<EmpresaVO[]> => {
  return requestSelectors.selectById(state, REQ_FETCH_EMPRESAS_COMBO) ?? {id: REQ_FETCH_EMPRESAS_COMBO, pending: false}
}

const trackFetchEmpresaProjetos = (nuEmpresa: number) => (state: RootState): RequestState<ProjetoVO[]> => {
  return requestSelectors.selectById(state, `${REQ_FETCH_EMPRESA_PROJETOS}:${nuEmpresa}`) ?? {
    id: `${REQ_FETCH_EMPRESA_PROJETOS}:${nuEmpresa}`,
    pending: false
  }
}


const trackCreateEmpresa = (state: RootState): RequestState<EmpresaVO> => {
  return requestSelectors.selectById(state, REQ_CREATE_EMPRESA) ?? {id: REQ_CREATE_EMPRESA, pending: false}
}

const trackFetchEmpresaByCnpj = (cnpj: string) => (state: RootState): RequestState<EmpresaVO> => {
  return requestSelectors.selectById(state, `${REQ_FETCH_EMPRESA_BY_CNPJ}:${cnpj}`) ?? {
    id: `${REQ_FETCH_EMPRESA_BY_CNPJ}:${cnpj}`,
    pending: false
  }
}

const trackDeleteEmpresaByCnpj = (cnpj: string) => (state: RootState): RequestState<EmpresaVO> => {
  return requestSelectors.selectById(state, `${REQ_DELETE_EMPRESA_BY_CNPJ}:${cnpj}`) ?? {
    id: `${REQ_DELETE_EMPRESA_BY_CNPJ}:${cnpj}`,
    pending: false
  }
}

const trackPatchEmpresaEndereco = (cnpj: string) => (state: RootState): RequestState<EmpresaVO> => {
  return requestSelectors.selectById(state, `${REQ_PATCH_EMPRESA_ENDERECO}:${cnpj}`) ?? {
    id: `${REQ_PATCH_EMPRESA_ENDERECO}:${cnpj}`,
    pending: false
  }
}

const trackPatchEmpresaDadoBancario = (cnpj: string) => (state: RootState): RequestState<EmpresaVO> => {
  return requestSelectors.selectById(state, `${REQ_PATCH_EMPRESA_DADO_BANCARIO}:${cnpj}`) ?? {
    id: `${REQ_PATCH_EMPRESA_DADO_BANCARIO}:${cnpj}`,
    pending: false
  }
}

const trackPatchEmpresaDadosBasicos = (cnpj: string) => (state: RootState): RequestState<EmpresaVO> => {
  return requestSelectors.selectById(state, `${REQ_PATCH_EMPRESA_DADOS_BASICOS}:${cnpj}`) ?? {
    id: `${REQ_PATCH_EMPRESA_DADOS_BASICOS}:${cnpj}`,
    pending: false
  }
}

const trackChangeEmpresaImagemLogo = (cnpj: string) => (state: RootState): RequestState<EmpresaVO> => {
  return requestSelectors.selectById(state, `${REQ_CHANGE_EMPRESA_IMAGEM_LOGO}:${cnpj}`) ?? {
    id: `${REQ_CHANGE_EMPRESA_IMAGEM_LOGO}:${cnpj}`,
    pending: false
  }
}


// THUNKS
const fetchEmpresasList = createAsyncThunk<EmpresaVO[], void, { state: RootState; rejectValue: APIError }>
('empresas/fetchEmpresas',
  async (args, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.empresas.fetchEmpresas(token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: (args, {getState}) => shouldFetch(trackFetchEmpresas(getState()))}
)

const fetchEmpresasCombo = createAsyncThunk<EmpresaVO[], void, { state: RootState; rejectValue: APIError }>
('empresas/fetchEmpresasCombo',
  async (args, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.empresas.fetchEmpresasCombo(token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: (args, {getState}) => shouldFetch(trackFetchEmpresasCombo(getState()), moment.duration(2, "minutes"))}
)

const fetchEmpresaProjetos = createAsyncThunk<ProjetoVO[], { nuEmpresa: number }, { state: RootState; rejectValue: APIError }>
('empresas/fetchEmpresaProjetos',
  async ({nuEmpresa}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.empresas.fetchEmpresaProjetos(nuEmpresa, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({nuEmpresa}, {getState}) => shouldFetch(trackFetchEmpresaProjetos(nuEmpresa)(getState()), moment.duration(2, "minutes"))}
)

const createEmpresa = createAsyncThunk<EmpresaVO, { empresa: DeepPartial<EmpresaVO> }, { state: RootState; rejectValue: APIError }>
('empresas/createEmpresa',
  async ({empresa}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.empresas.createEmpresa(empresa, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: (args, {getState}) => shouldFetch(trackCreateEmpresa(getState()))}
)

const fetchEmpresaByCnpj = createAsyncThunk<EmpresaVO, { cnpj: string }, { state: RootState; rejectValue: APIError }>
('empresas/fetchEmpresaByCnpj',
  async ({cnpj}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.empresas.fetchEmpresaByCnpj(cnpj, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => {
        console.log(result.data);
        return result.data
      }),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({cnpj}, {getState}) => shouldFetch(trackFetchEmpresaByCnpj(cnpj)(getState()))}
)

const deleteEmpresaByCnpj = createAsyncThunk<void, { cnpj: string }, { state: RootState; rejectValue: APIError }>
('empresas/deleteEmpresaByCnpj',
  async ({cnpj}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.empresas.deleteEmpresaByCnpj(cnpj, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => {
        console.log(result.data);
        return result.data
      }),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({cnpj}, {getState}) => shouldFetch(trackDeleteEmpresaByCnpj(cnpj)(getState()))}
)

const patchEmpresaEndereco = createAsyncThunk<void, { cnpj: string; changes: DeepPartial<EnderecoVO> }, { state: RootState; rejectValue: APIError }>
('empresas/patchEmpresaEndereco',
  async ({cnpj, changes}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.empresas.patchEmpresaEndereco(cnpj, changes, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({cnpj}, {getState}) => shouldFetch(trackPatchEmpresaEndereco(cnpj)(getState()))}
)

const patchEmpresaDadoBancario = createAsyncThunk<void, { cnpj: string; changes: DeepPartial<DadoBancarioVO> }, { state: RootState; rejectValue: APIError }>
('empresas/patchEmpresaDadoBancario',
  async ({cnpj, changes}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.empresas.patchEmpresaDadoBancario(cnpj, changes, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({cnpj}, {getState}) => shouldFetch(trackPatchEmpresaDadoBancario(cnpj)(getState()))}
)

const patchEmpresaDadosBasicos = createAsyncThunk<void, { cnpj: string; changes: DeepPartial<EmpresaVO> }, { state: RootState; rejectValue: APIError }>
('empresas/patchEmpresaDadosBasicos',
  async ({cnpj, changes}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.empresas.patchEmpresaDadosBasicos(cnpj, changes, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({cnpj}, {getState}) => shouldFetch(trackPatchEmpresaDadosBasicos(cnpj)(getState()))}
)


const changeEmpresaImagemLogo = createAsyncThunk<void, { cnpj: string; imagemLogo: DeepPartial<ImagemVO> }, { state: RootState; rejectValue: APIError }>
('empresas/changeEmpresaImagemLogo',
  async ({cnpj, imagemLogo}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.empresas.changeEmpresaImagemLogo(cnpj, imagemLogo, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({cnpj}, {getState}) => shouldFetch(trackChangeEmpresaImagemLogo(cnpj)(getState()))}
)


// SLICE
const empresasSlice = createSlice({
  name: 'empresas',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    //
    builder.addCase(fetchEmpresasList.pending, (state) => {
      requestsAdapter.upsertOne(state.requests, {
        id: REQ_FETCH_EMPRESAS,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(fetchEmpresasList.fulfilled, (state, {payload: empresas}) => {
      requestsAdapter.updateOne(state.requests, {
        id: REQ_FETCH_EMPRESAS,
        changes: {pending: false, timestamp: moment().toISOString(), data: empresas}
      });
    })
    builder.addCase(fetchEmpresasList.rejected, (state, action) => {
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {id: REQ_FETCH_EMPRESAS, changes: {pending: false, error}});
    })

    //
    builder.addCase(fetchEmpresaProjetos.pending, (state, {meta: {arg: {nuEmpresa}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_FETCH_EMPRESA_PROJETOS}:${nuEmpresa}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(fetchEmpresaProjetos.fulfilled, (state, {payload: empresas, meta: {arg: {nuEmpresa}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_FETCH_EMPRESA_PROJETOS}:${nuEmpresa}`,
        changes: {pending: false, timestamp: moment().toISOString(), data: empresas}
      });
    })
    builder.addCase(fetchEmpresaProjetos.rejected, (state, action) => {
      const nuEmpresa = action.meta.arg.nuEmpresa;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_FETCH_EMPRESA_PROJETOS}:${nuEmpresa}`,
        changes: {pending: false, error}
      });
    })

    //
    builder.addCase(fetchEmpresasCombo.pending, (state) => {
      requestsAdapter.upsertOne(state.requests, {
        id: REQ_FETCH_EMPRESAS_COMBO,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(fetchEmpresasCombo.fulfilled, (state, {payload: empresas}) => {
      requestsAdapter.updateOne(state.requests, {
        id: REQ_FETCH_EMPRESAS_COMBO,
        changes: {pending: false, timestamp: moment().toISOString(), data: empresas}
      });
    })
    builder.addCase(fetchEmpresasCombo.rejected, (state, action) => {
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {id: REQ_FETCH_EMPRESAS_COMBO, changes: {pending: false, error}});
    })

    //
    builder.addCase(createEmpresa.pending, (state) => {
      requestsAdapter.upsertOne(state.requests, {
        id: REQ_CREATE_EMPRESA,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(createEmpresa.fulfilled, (state, {payload: empresa}) => {
      requestsAdapter.updateOne(state.requests, {
        id: REQ_CREATE_EMPRESA,
        changes: {pending: false, timestamp: moment().toISOString(), data: empresa}
      });
    })
    builder.addCase(createEmpresa.rejected, (state, action) => {
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {id: REQ_CREATE_EMPRESA, changes: {pending: false, error}});
    })

    //
    builder.addCase(fetchEmpresaByCnpj.pending, (state, {meta: {arg: {cnpj}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_FETCH_EMPRESA_BY_CNPJ}:${cnpj}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(fetchEmpresaByCnpj.fulfilled, (state, {payload: empresas, meta: {arg: {cnpj}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_FETCH_EMPRESA_BY_CNPJ}:${cnpj}`,
        changes: {pending: false, timestamp: moment().toISOString(), data: empresas}
      });
    })
    builder.addCase(fetchEmpresaByCnpj.rejected, (state, action) => {
      const {cnpj} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_FETCH_EMPRESA_BY_CNPJ}:${cnpj}`,
        changes: {pending: false, error}
      });
    })

    //
    builder.addCase(deleteEmpresaByCnpj.pending, (state, {meta: {arg: {cnpj}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_DELETE_EMPRESA_BY_CNPJ}:${cnpj}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(deleteEmpresaByCnpj.fulfilled, (state, {payload: empresas, meta: {arg: {cnpj}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_DELETE_EMPRESA_BY_CNPJ}:${cnpj}`,
        changes: {pending: false, timestamp: moment().toISOString(), data: empresas}
      });
    })
    builder.addCase(deleteEmpresaByCnpj.rejected, (state, action) => {
      const {cnpj} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_DELETE_EMPRESA_BY_CNPJ}:${cnpj}`,
        changes: {pending: false, error}
      });
    })

    //
    builder.addCase(patchEmpresaEndereco.pending, (state, {meta: {arg: {cnpj}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_PATCH_EMPRESA_ENDERECO}:${cnpj}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(patchEmpresaEndereco.fulfilled, (state, {meta: {arg: {cnpj}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_PATCH_EMPRESA_ENDERECO}:${cnpj}`,
        changes: {pending: false, timestamp: moment().toISOString()}
      });
    })
    builder.addCase(patchEmpresaEndereco.rejected, (state, action) => {
      const {cnpj} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_PATCH_EMPRESA_ENDERECO}:${cnpj}`,
        changes: {pending: false, error}
      });
    })

    //
    builder.addCase(patchEmpresaDadoBancario.pending, (state, {meta: {arg: {cnpj}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_PATCH_EMPRESA_DADO_BANCARIO}:${cnpj}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(patchEmpresaDadoBancario.fulfilled, (state, {meta: {arg: {cnpj}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_PATCH_EMPRESA_DADO_BANCARIO}:${cnpj}`,
        changes: {pending: false, timestamp: moment().toISOString()}
      });
    })
    builder.addCase(patchEmpresaDadoBancario.rejected, (state, action) => {
      const {cnpj} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_PATCH_EMPRESA_DADO_BANCARIO}:${cnpj}`,
        changes: {pending: false, error}
      });
    })

    //
    builder.addCase(patchEmpresaDadosBasicos.pending, (state, {meta: {arg: {cnpj}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_PATCH_EMPRESA_DADOS_BASICOS}:${cnpj}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(patchEmpresaDadosBasicos.fulfilled, (state, {meta: {arg: {cnpj}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_PATCH_EMPRESA_DADOS_BASICOS}:${cnpj}`,
        changes: {pending: false, timestamp: moment().toISOString()}
      });
    })
    builder.addCase(patchEmpresaDadosBasicos.rejected, (state, action) => {
      const {cnpj} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_PATCH_EMPRESA_DADOS_BASICOS}:${cnpj}`,
        changes: {pending: false, error}
      });
    })

    //
    builder.addCase(changeEmpresaImagemLogo.pending, (state, {meta: {arg: {cnpj}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_CHANGE_EMPRESA_IMAGEM_LOGO}:${cnpj}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(changeEmpresaImagemLogo.fulfilled, (state, {meta: {arg: {cnpj}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_CHANGE_EMPRESA_IMAGEM_LOGO}:${cnpj}`,
        changes: {pending: false, timestamp: moment().toISOString()}
      });
    })
    builder.addCase(changeEmpresaImagemLogo.rejected, (state, action) => {
      const {cnpj} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_CHANGE_EMPRESA_IMAGEM_LOGO}:${cnpj}`,
        changes: {pending: false, error}
      });
    })

  }
})

export {
  fetchEmpresasList,
  fetchEmpresaProjetos,
  createEmpresa,
  fetchEmpresaByCnpj,
  fetchEmpresasCombo,
  deleteEmpresaByCnpj,
  patchEmpresaEndereco,
  patchEmpresaDadoBancario,
  patchEmpresaDadosBasicos,
  changeEmpresaImagemLogo,
  trackFetchEmpresaProjetos,
  trackFetchEmpresaByCnpj,
  trackFetchEmpresasCombo,
  trackFetchEmpresas
};

export default empresasSlice.reducer
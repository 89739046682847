import React from 'react'
import { LinearProgress } from '@material-ui/core'

export interface PageLoadingProps {
    isLoading: boolean;
    variant?: 'determinate' | 'indeterminate' | 'buffer' | 'query';
    value?: number;
    valueBuffer?: number;
}


export const PageLoading = ({ isLoading, ...rest }: PageLoadingProps) => {
    return (
        <LinearProgress {...rest} style={{ width: '100%', float: 'left', position: 'absolute', visibility: isLoading ? "visible" : "hidden" }} />
    )
}

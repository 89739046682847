import { Button, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AppDispatch } from '../../../../../../../browser';
import { MotivoRemocaoForum } from '../../../../../../models/enums/MotivoRemocaoForum';
import { PerguntaForumProjetoVO } from '../../../../../../models/PerguntaForumProjeto';
import { ProjetoVO } from '../../../../../../models/Projeto';
import { APIError } from '../../../../../../services/api/APIError';
import { generateErrorDialog } from '../../../../../../services/api/APIErrorUtils';
import { createPerguntaForumProjeto, removePerguntaForumProjeto, respondePerguntaForumProjeto } from '../../../../../../stores/slices/entities/perguntasForumProjetoSlice';
import { selectLogin } from '../../../../../../stores/slices/userSlice';
import { LoginUtils } from '../../../../../../utils/login';
import { useDialog } from '../../../../../components/dialog/PageServiceProvider';
import EmptyState from '../../../../../components/EmptyState';
import { TextBadge } from '../../../../../components/page/TextBadge';
import { ForumPerguntasProjetoCreateForm } from './ForumPerguntasProjetoCreateForm';
import ForumPerguntasProjetoItem from './ForumPerguntasProjetoItem';
import { PageSubtitle } from '../../../../../layout/PageSubtitle';
import produce from 'immer';

const useStyles = makeStyles((theme: Theme) => createStyles({

  naoLogado: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    borderRadius: 2,
    border: '1px solid #c8c8c8;',
    textAlign: 'center'
  },

}), { name: 'ForumPerguntasProjeto' });


interface ForumPerguntasProjetoProps {
  perguntas?: PerguntaForumProjetoVO[];
  projeto: ProjetoVO;
}

interface State {
  perguntas: PerguntaForumProjetoVO[];
}


export default function ForumPerguntasProjeto({ perguntas, projeto }: ForumPerguntasProjetoProps) {

  const classes = useStyles();
  const location = useLocation();

  const login = useSelector(selectLogin);

  const dispatch = useDispatch<AppDispatch>()
  const dialog = useDialog();

  const [state, setState] = useState<State>({
    perguntas: perguntas || new Array<PerguntaForumProjetoVO>()
  });

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.split('#').join(''));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
      }
    }
  }, [location.hash]);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleCreatePerguntaForumProjeto = async (pergunta: string) => {
    const resultAction = await dispatch(createPerguntaForumProjeto({ nuProjeto: projeto.nuProjeto, dePergunta: pergunta }));
    if (createPerguntaForumProjeto.fulfilled.match(resultAction)) {
      const returned = unwrapResult(resultAction);
      enqueueSnackbar('Pergunta enviada com sucesso!', {
        variant: 'success',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });
      setState({
        perguntas: [returned, ...state.perguntas]
      })
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível enviar pergunta. Tente novamente."))
    }
  }

  const handleRemovePerguntaForumProjeto = async (coPerguntaForumProjeto: number, motivoRemocaoForum: MotivoRemocaoForum) => {
    const resultAction = await dispatch(removePerguntaForumProjeto({ nuProjeto: projeto.nuProjeto, coPerguntaForumProjeto, motivoRemocaoForum }));
    if (removePerguntaForumProjeto.fulfilled.match(resultAction)) {
      enqueueSnackbar('Pergunta removida com sucesso!', {
        variant: 'success',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });
      setState({
        ...state,
        perguntas: state.perguntas.filter(p => p.coPerguntaForumProjeto != coPerguntaForumProjeto)
      })
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível remover pergunta. Tente novamente."))
    }
  }

  const handleRespondePerguntaForumProjeto = async (coPerguntaForumProjeto: number, deResposta: string) => {

    const resultAction = await dispatch(respondePerguntaForumProjeto({ nuProjeto: projeto.nuProjeto, coPerguntaForumProjeto, deResposta }));
    if (respondePerguntaForumProjeto.fulfilled.match(resultAction)) {
      enqueueSnackbar('Pergunta respondida com sucesso!', {
        variant: 'success',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });
      const response = unwrapResult(resultAction);
      setState(produce(draft => {
        const idx = state.perguntas.findIndex(p => p.coPerguntaForumProjeto === coPerguntaForumProjeto);
        draft.perguntas[idx] = {...state.perguntas[idx], ...response};
      }))
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível responder pergunta. Tente novamente."))
    }

  }


  return (
    <section id="forum">

      <PageSubtitle>
        <TextBadge badgeContent={state.perguntas.length} >
          Fórum
        </TextBadge>
      </PageSubtitle>

      {(!login || !LoginUtils.isValid(login)) &&
        <Grid container alignContent="center" className={classes.naoLogado}>
          <Grid item xs>
            <Typography component="p" variant="subtitle1">
              Você precisa estar logado para fazer perguntas. <Link to={{ pathname: `/login`, state: { from: location.pathname + "#forum" } }}>Efetuar login</Link>
            </Typography>
          </Grid>
        </Grid>
      }

      {login && LoginUtils.isValid(login) &&
        <Grid container spacing={2} alignContent="center" justify="flex-start">
          <Grid item xs={12}>
            <ForumPerguntasProjetoCreateForm
              onCreatePerguntaForumSubmit={handleCreatePerguntaForumProjeto}
            />
          </Grid>
        </Grid>
      }


      {state.perguntas.length == 0 &&
        <EmptyState
          icon={<ChatOutlinedIcon style={{ fontSize: '58px' }} color="disabled" />}
          title="Nenhuma pergunta neste fórum ainda."
          subtitle="Escreva sua pergunta e ela será respondida aqui."
          margin="10px"
        />
      }

      {state.perguntas.length > 0 &&
        state.perguntas.map(pergunta => {
          return (
            <ForumPerguntasProjetoItem
              key={pergunta.coPerguntaForumProjeto}
              pergunta={pergunta}
              projeto={projeto}
              onRemovePerguntaForumSubmit={handleRemovePerguntaForumProjeto}
              onRespondePerguntaForumSubmit={handleRespondePerguntaForumProjeto}
            />
          );
        })
      }

    </section>
  );
}

import { CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';

interface Props {
    text: string;
}

export default function Loading(props: Props) {
    return (
        <Grid container id="loading" direction="column" justify="center" alignItems="center" style={{ marginTop: '20vh' }} >
            <Typography variant="body1" gutterBottom={true}>{props.text}</Typography>
            <CircularProgress />
        </Grid>
    );
}
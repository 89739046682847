import React from 'react'
import { APIError } from './APIError'
import { PageDialogOptions } from '../../views/components/dialog/PageDialog'
import { Typography } from '@material-ui/core'


export const generateErrorDialog = (error: APIError, defaultDescription: string): PageDialogOptions => {

  let title = "Oops, ocorreu um erro"
  let description = defaultDescription;

  switch (error.type) {
    case "NetworkError":
      title = "Falha de Comunicação"
      description = "Não foi possível conectar ao servidor. Tente novamente.";
      break;
    case "OfflineError":
      title = "Sem Internet"
      description = "Não há conexão disponível. Verifique sua conexão de dados ou wifi";
      break;
    case "UnauthorizedError":
      title = "Falha de autenticação"
      description = "Você não está logado ou sua sessão expirou. Tente efetuar novo login.";
      break;
    default:
      title = "Oops, ocorreu um erro"
      break;
  }

  return {
    confirmOption: "OK",
    severity: "error",
    title: title,
    description: description,
    details: (
      <>
        {error.type && <Typography variant="body2"><b>Tipo:</b> {error.type} </Typography>}
        {error.message && <Typography variant="body2"><b>Mensagem:</b> {error.message} </Typography>}
        {error.traceid && <Typography variant="body2"><b>TraceId:</b> {error.traceid} </Typography>}
        {error.timestamp && <Typography variant="body2"><b>Timestamp:</b> {error.timestamp} </Typography>}
      </>
    )
  }
}
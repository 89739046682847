import { Button, createStyles, Grid, makeStyles, Theme, Typography, useForkRef, useMediaQuery, useTheme } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FilePdfBoxOutlineIcon from 'mdi-material-ui/FilePdfBoxOutline';
import UploadIcon from 'mdi-material-ui/Upload';
import React, { useRef, useState } from 'react';
import { useWindowSize } from 'react-use';
import { FileUtils } from '../../../../../utils/files';
import { PDFViewer } from '../../../pdf/PDFViewer';

const useStyles = makeStyles((theme: Theme) => createStyles({
  icon: {
    width: 200,
    height: 200
  },
  documentoContainer: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 400,
    },
  },
}), { name: 'FormPdfUpload' });

export interface FormPdfUploadProps {
  name?: string;
  previewURL?: string;
  helperText?: string;
  error?: string;
  inputRef?: React.Ref<any>;
  canRemove?: boolean;
  onChange?: (file: File | null) => void;
}

export default function FormPdfUpload(props: FormPdfUploadProps) {

  const classes = useStyles();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const { width } = useWindowSize();

  // State
  const [previewURL, setPreviewURL] = useState<string | undefined>(props.previewURL);

  // Refs
  const inputFileRef = useRef<HTMLInputElement>(null);
  const combinedRef = useForkRef(inputFileRef, props.inputRef || null);

  // Handlers
  const handleFileUploadClick = () => inputFileRef.current?.click();
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] as File;
    if (FileUtils.isFilePdf(file)) {
      const fileDataURL = await FileUtils.fileToDataURL(file);
      setPreviewURL(fileDataURL);
    } else {
      setPreviewURL(undefined);
    }
    inputFileRef.current?.dispatchEvent(new Event("blur"));
    if (props.onChange) props.onChange(file);
  }
  const handleFileRemoveClick = () => {
    setPreviewURL(undefined);
    if (props.onChange) props.onChange(null);
  }

  return (
    <Grid container alignItems="center" justify="center" direction="column" spacing={1}>
      <Grid item xs={12}>
        {previewURL
          ?
          <div className={classes.documentoContainer}>
            <PDFViewer showPagination={true} previewURL={previewURL} width={isDesktop ? 400 : width - 65} />
          </div>
          : <FilePdfBoxOutlineIcon className={classes.icon} color="disabled" />
        }
      </Grid>
      <Grid item>
        <Grid container direction="row" spacing={2}>
          {props.canRemove &&
            <Grid item>
              <Button color="inherit" style={{ color: 'red' }} variant="outlined" onClick={handleFileRemoveClick} startIcon={<DeleteOutlineIcon />}>Remover</Button>
            </Grid>
          }
          <Grid item>
            <input name={props.name} ref={combinedRef} type="file" hidden accept=".pdf" onChange={handleFileChange} />
            <Button color="primary" variant="outlined" onClick={handleFileUploadClick} startIcon={<UploadIcon />}>Upload</Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="caption" color={props.error ? "error" : "textSecondary"}>{props.error || props.helperText}</Typography>
      </Grid>

    </Grid>

  )

}
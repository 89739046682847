import React from 'react';
import { Avatar, Button, createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import UploadIcon from 'mdi-material-ui/Upload';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';


const useStyles = makeStyles(() => createStyles({
    icon: {
        width: 200,
        height: 200
    },
}), { name: 'FormPhotoUploadEmptyState' });



interface FormPhotoUploadEmptyStateProps {
    onFileUploadClick: () => void;
    error?: string;
    helperText?: string;
    variant: "avatar"|"picture";
}

export const FormPhotoUploadEmptyState = ({ error, helperText, onFileUploadClick, variant }: FormPhotoUploadEmptyStateProps) => {
    const classes = useStyles();

    return (
        <Grid container alignItems="center" justify="center" direction="column" spacing={1}>
            <Grid item>
               {variant == "avatar" &&
                  <Avatar className={classes.icon}/>
               }
               {variant == "picture" &&
                  <AddPhotoAlternateIcon className={classes.icon} color="disabled" />
               }
               
            </Grid>
            <Grid item>
                <Grid container direction="row" spacing={2}>
                    <Grid item>
                        <Button color="primary" variant="outlined" onClick={onFileUploadClick} startIcon={<UploadIcon />}>Upload</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Typography variant="caption" color={error ? "error" : "textSecondary"}>{error || helperText}</Typography>
            </Grid>
        </Grid>

    )
}

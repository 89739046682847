import { Box, Button, createStyles, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => createStyles({
    sucessIcon: {
        fontSize: 96,
        color: theme.palette.success.main
    },
}), { name: 'SucessoResetSenhaStep' });

export default function SucessoResetSenhaStep() {

    const classes = useStyles();

     return (
        <Paper variant="outlined" style={{ padding: 20 }}>

                <Box display="flex" justifyContent="center" mt={2}>
                    <CheckCircleIcon className={classes.sucessIcon}/>
                </Box>

                <Box mt={1} >
                    <Typography variant="body1" paragraph align="center"><strong>Parabéns! Sua senha foi recuperada com sucesso.</strong></Typography>
                    <Typography variant="body1" paragraph align="center">Quando for efetuar um novo login você deverá utilizar sua nova senha.</Typography>
                </Box>

             
                <Box display="flex" justifyContent="center" mt={2}>
                    <Button variant="contained" color="secondary" component={Link} to="/conta/painel">
                        Painel de Investimentos
                    </Button>
                </Box>
        </Paper>

    )

}
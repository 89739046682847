import React, { useState } from 'react'
import { Document, Page } from 'react-pdf';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { Pagination } from '@material-ui/lab';
import { Grid } from '@material-ui/core';

interface PDFViewerProps {
  previewURL: string;
  width?: number;
  showPagination?: boolean;
}

export const PDFViewer = ({ previewURL, width, showPagination }: PDFViewerProps) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }: PDFDocumentProxy) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  function handleChangePage(event: React.ChangeEvent<unknown>, value: number) {
    setPageNumber(value);
  }

  return (
    <Grid container direction="column" alignItems="flex-start" >
      <Grid item>
        <Document file={previewURL} onLoadSuccess={onDocumentLoadSuccess} loading="Carregando documento" >
          <Page pageNumber={pageNumber} width={width} />
        </Document>
      </Grid>
      {showPagination &&
        <Grid item>
          <Pagination count={numPages} page={pageNumber} onChange={handleChangePage} />
        </Grid>
      }
    </Grid>
  );
}

import React from 'react'
import { useForm } from 'react-hook-form';
import FormInput from '../../../../../components/FormInput';
import { InputAdornment, Tooltip, IconButton } from '@material-ui/core';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import SendIcon from '@material-ui/icons/Send';
import { useDialog } from '../../../../../components/dialog/PageServiceProvider';

interface ForumPerguntasProjetoCreateFormData {
  pergunta: string;
}

interface ForumPerguntasProjetoCreateFormProps{
  onCreatePerguntaForumSubmit: (pergunta: string) => Promise<void>;
}

export const ForumPerguntasProjetoCreateForm = ({onCreatePerguntaForumSubmit}: ForumPerguntasProjetoCreateFormProps) => {

  const {register, errors, handleSubmit, reset, formState:{isSubmitting}} = useForm<ForumPerguntasProjetoCreateFormData>({mode: "onBlur"});

  const dialog = useDialog();

  const handleCreatePerguntaFormSubmit = async (formData: ForumPerguntasProjetoCreateFormData) => {
    const confirmed = await dialog({
      title: "Confirma envio da pergunta?",
      description: "Ao confirmar sua pergunta será registrada no fórum. Se cancelar, nenhum dado será salvo no servidor.",
      confirmOption: "CONFIRMAR",
      cancelOption: "CANCELAR"
    })
    if(confirmed) {
      await onCreatePerguntaForumSubmit(formData.pergunta)
      reset();
    }
  }

  return (
    <form id="formPergunta" noValidate autoComplete="off" onSubmit={handleSubmit(handleCreatePerguntaFormSubmit)}>
      <FormInput
        required
        name="pergunta"
        variant="outlined"
        characterCounter
        placeholder="Escreva uma pergunta ..."
        maxLength={1000}
        error={errors.pergunta?.message?.toString()}
        startAdornment={
          <InputAdornment position="start">
            <ChatOutlinedIcon color="action" />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            <Tooltip title="Enviar pergunta" placement="right">
              <IconButton edge="end" type="submit" disabled={isSubmitting}>
                <SendIcon color="secondary" />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        }
        submitOnEnter={true}
        inputRef={register({
          required: "Escreva sua pergunta"
        })}
      />
    </form>
  )
}

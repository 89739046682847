import React from 'react'
import { Typography, makeStyles, Theme, createStyles, Breadcrumbs, Chip } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => createStyles({
    breadcrumbs: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
}), { name: 'PageBreadcrumbs' });


export interface BreadcrumbItem {
    label: string;
    to?: string;
    icon?: React.ComponentType<any>;
}

export interface PageBreadcrumbsProps {
    breadcrumbs: BreadcrumbItem[];
}

export default function PageBreadcrumbs(props: PageBreadcrumbsProps) {

    const styles = useStyles();

    return (
        <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            className={styles.breadcrumbs}>
            {props.breadcrumbs.map((breadcrumb, index) => {
                return breadcrumb.to && breadcrumb.icon
                    ? <Chip
                        key={index}
                        size="small"
                        label={breadcrumb.label}
                        icon={<breadcrumb.icon fontSize="small" />}
                        variant="outlined"
                        component={Link}
                        to={breadcrumb.to}
                        clickable
                    />
                    : <Typography
                        key={index}
                        color="textSecondary">
                        {breadcrumb.label}
                    </Typography>
            })}
        </Breadcrumbs>
    )
}

import { Box, Grid, Paper, Typography, Button } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../../../../browser';
import { fetchValoresCotasList, selectValoresCotas, trackFetchValoresCotas, createValorCota, patchValorCota } from '../../../../../../../stores/slices/entities/valoresCotasSlice';
import { ISO_MAX_DATE } from '../../../../../../../utils/datetime';
import ErrorEmptyState from '../../../../../../components/empty/ErrorEmptyState';
import Loading from '../../../../../../components/Loading';
import { ValorCotaForm, ValorCotaFormMode } from './ValorCotaForm';
import { ValorCotaHistorico } from './ValorCotaHistorico';
import { useSnackbar } from 'notistack';
import { APIError } from '../../../../../../../services/api/APIError';
import { useDialog } from '../../../../../../components/dialog/PageServiceProvider';
import { DeepPartial } from '@reduxjs/toolkit';
import { generateErrorDialog } from '../../../../../../../services/api/APIErrorUtils';
import { ValorCotaVO } from '../../../../../../../models/ValorCota';
import { Patch } from 'immer';

interface HistoricoCotasListParams {
  nuProjeto: number;
  onBack: () => void;
}

export const HistoricoCotasList = ({ nuProjeto, onBack }: HistoricoCotasListParams) => {

  const requestState = useSelector(trackFetchValoresCotas(nuProjeto));
  const valoresCotas = useSelector(selectValoresCotas);

  const dispatch = useDispatch<AppDispatch>();

  const dialog = useDialog();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    const promise = dispatch(fetchValoresCotasList({ nuProjeto }));
    return () => promise.abort();
  }, [dispatch, nuProjeto]);

  const handleValorCotaCreate = async (nextState: DeepPartial<ValorCotaVO>) => {
    const resultAction = await dispatch(createValorCota({ nuProjeto, valorCota: nextState }));
    if (createValorCota.fulfilled.match(resultAction)) {
      enqueueSnackbar('Novo valor de cota salvo com sucesso!', {
        variant: 'success',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível salvar o novo valor de cota. Tente novamente."))
    }
  };

  const handleValorCotaUpdate = async (nextState: DeepPartial<ValorCotaVO>, patches: Patch[], changes: DeepPartial<ValorCotaVO>) => {
    const resultAction = await dispatch(patchValorCota({ nuProjeto, coValorCota: valoresCotas[0].coValorCota, changes }))
    if (patchValorCota.fulfilled.match(resultAction)) {
      enqueueSnackbar('Valor de cota encerrado com sucesso!', {
        variant: 'success',
        action: <Button onClick={() => closeSnackbar()}>OK</Button>
      });
    } else {
      const error = resultAction.payload || resultAction.error as APIError;
      dialog(generateErrorDialog(error, "Não foi possível encerrar o valor da cota. Tente novamente."))
    }
  }

  return (
    <Paper variant="outlined" square >
      <Box p={2}>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Typography variant="h6">Valor da Cota</Typography>
          </Grid>

          {requestState.pending &&
            <Loading text="Carregando Formulário" />
          }

          {requestState.error &&
            <ErrorEmptyState
              error={requestState.error}
              defaultDescription="Não foi possível buscar valores de cotas"
              onTryAgainClick={() => dispatch(fetchValoresCotasList({ nuProjeto }))}
            />
          }

          {requestState.data &&
            <>
              <Grid item xs={12}>
                {moment(valoresCotas[0].dtFim).isSame(ISO_MAX_DATE) &&
                  <ValorCotaForm formMode={ValorCotaFormMode.EDIT} onBack={onBack} onSubmit={handleValorCotaUpdate} valorCota={valoresCotas[0]} />
                }
                {!moment(valoresCotas[0].dtFim).isSame(ISO_MAX_DATE) &&
                  <ValorCotaForm formMode={ValorCotaFormMode.CREATE} onBack={onBack} onSubmit={handleValorCotaCreate} dtInicioCreate={moment(valoresCotas[0].dtFim).add(1, 'day')} />
                }
              </Grid>
              {(valoresCotas.length > 1 || valoresCotas[0].dtFim != ISO_MAX_DATE) &&
                <>
                  <Grid item xs={12}>
                    <Typography variant="h6">Histórico</Typography>
                  </Grid>
                  {valoresCotas.filter(cota => cota.dtFim != ISO_MAX_DATE).map((valorCota) => (
                    <Grid item xs={12} key={valorCota.coValorCota}>
                      <ValorCotaHistorico valorCota={valorCota} />
                    </Grid>
                  ))}
                </>
              }

              <Box mb={3} />

            </>

          }

        </Grid>
      </Box>
    </Paper >

  )
}
import React from 'react';
import { Box, Grid, Paper, Typography, Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormHelperText } from '@material-ui/core';
import { applyPatches, Patch, produceWithPatches } from 'immer';
import { useForm, Controller } from 'react-hook-form';
import { DeepPartial } from 'redux';
import moment from 'moment';
import { InvestidorVO } from '../../../../../../../../models/Investidor';
import { Formatters } from '../../../../../../../../utils/formatters';
import ConfirmLeavePage from '../../../../../../../components/ComfirmLeavePage';
import FormInput from '../../../../../../../components/FormInput';
import { Patterns } from '../../../../../../../../utils/patterns';
import { CPFMaskedInput, TelefoneMaskedInput } from '../../../../../../../components/MaskedInputs';
import { Validators } from '../../../../../../../../utils/validators';
import FormDatePickerInput from '../../../../../../../components/form/inputs/DataPicker/FormDatePickerInput';
import { EstadoCivil } from '../../../../../../../../models/enums/EstadoCivil';
import { Sexo } from '../../../../../../../../models/enums/Sexo';
import { NivelInvestidor } from '../../../../../../../../models/enums/NivelInvestidor';
import ButtonProgress from '../../../../../../../components/ButtonProgress';

export enum InvestidorDadosBasicosFormMode {
  CREATE,
  EDIT
}

interface InvestidorDadosBasicosFormData {
  nuCpfCnpjInvestidor: string;
  dtNascimento: string;
  coRg: string;
  noOrgaoEmissorRg: string;
  icEstadoCivil: string;
  icSexo: string;
  nuTelefone: string;
  nuCelular: string;
  noProfissao: string;
  icNivelInvestidor: string;
  icPpe: string;
}

const generateFormDefaultValues = (investidor: DeepPartial<InvestidorVO>): InvestidorDadosBasicosFormData => {
  return {
    nuCpfCnpjInvestidor: Formatters.cpf.format(investidor.nuCpfCnpjInvestidor),
    dtNascimento: investidor.dtNascimento?moment(investidor.dtNascimento).format("L"):'',
    coRg: investidor.coRg || '',
    noOrgaoEmissorRg: investidor.noOrgaoEmissorRg || '',
    icEstadoCivil: investidor.icEstadoCivil?.toString() || '',
    icSexo: investidor.icSexo?.toString() || '',
    nuTelefone: Formatters.telefone.format(investidor.nuTelefone),
    nuCelular: Formatters.telefone.format(investidor.nuCelular),
    noProfissao: investidor.noProfissao || '',
    icNivelInvestidor: investidor.icNivelInvestidor?.toString() || '',
    icPpe: investidor.icPpe?.toString() || ''
  }
}


interface InvestidorDadosBasicosFormProps {
  investidor: DeepPartial<InvestidorVO>;
  onSubmit: (nextState: DeepPartial<InvestidorVO>, patches: Patch[], changes: DeepPartial<InvestidorVO>) => Promise<void> | void;
  onBack?: () => void;
  submitText: string;
  formMode: InvestidorDadosBasicosFormMode;
}

export const InvestidorDadosBasicosForm = ({ investidor, onSubmit, onBack, submitText, formMode }: InvestidorDadosBasicosFormProps) => {

  const { register, handleSubmit, errors, control, formState: { isSubmitting, dirty } } = useForm<InvestidorDadosBasicosFormData>({
    mode: "onBlur",
    defaultValues: generateFormDefaultValues(investidor)
  });

  const handleFormSubmit = async (formData: InvestidorDadosBasicosFormData) => {
    const [nextState, patches] = produceWithPatches(investidor, draft => {
      draft.nuCpfCnpjInvestidor = Formatters.cpf.unformat(formData.nuCpfCnpjInvestidor);
      draft.dtNascimento = moment(formData.dtNascimento, "L").format("YYYY-MM-DD");
      draft.coRg = formData.coRg;
      draft.noOrgaoEmissorRg = formData.noOrgaoEmissorRg;
      draft.icEstadoCivil = Number(formData.icEstadoCivil);
      draft.icSexo = Number(formData.icSexo);
      draft.nuTelefone = Formatters.telefone.unformat(formData.nuTelefone);
      draft.nuCelular = Formatters.telefone.unformat(formData.nuCelular);
      draft.noProfissao = formData.noProfissao;
      draft.icNivelInvestidor = Number(formData.icNivelInvestidor);
      draft.icPpe = formData.icPpe == 'true';
    })
    const changes = applyPatches({}, patches) as Partial<InvestidorVO>;
    await onSubmit(nextState, patches, changes)
  }


  return (
    <form id="formDadosBasicosInvestiodr" noValidate autoComplete="off" onSubmit={handleSubmit(handleFormSubmit)}>
      <ConfirmLeavePage
        when={dirty}
        title="Tem certeza que deseja deixar a página sem salvar as alterações?"
        content="Ao confirmar os dados alterados não serão salvos. Se cancelar, poderá continuar a edição."
      />
      <Paper variant="outlined" square >
        <Box p={2}>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <Typography variant="h6">Dados Básicos</Typography>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <FormInput
                required
                autoFocus={formMode == InvestidorDadosBasicosFormMode.CREATE}
                disabled={formMode == InvestidorDadosBasicosFormMode.EDIT}
                name="nuCpfCnpjInvestidor"
                label="CPF"
                placeholder="999.999.999-99"
                pattern={Patterns.cpf.source}
                inputComponent={CPFMaskedInput as any}
                error={errors.nuCpfCnpjInvestidor?.message?.toString()}
                inputRef={register({
                  required: 'Informe seu CPF',
                  pattern: { value: Patterns.cpf, message: 'Formato 999.999.999-99' },
                  validate: {
                    isCPF: value => Validators.isCPF(value) || "CPF Inválido"
                  }
                })}
              />

            </Grid>

            <Grid item xs={12} sm={6} lg={4}>

              <FormDatePickerInput
                required
                disabled={formMode == InvestidorDadosBasicosFormMode.EDIT}
                name="dtNascimento"
                label="Data de Nascimento"
                minDate={moment().subtract(120, 'years')}
                maxDate={moment().subtract(18, 'years')}
                error={errors.dtNascimento?.message?.toString()}
                format="DD/MM/YYYY"
                inputRef={register({
                  required: 'Informe sua Data de Nascimento',
                  pattern: { value: Patterns.data, message: 'Formato DD/MM/AAAA' },
                  validate: {
                    validDate: value => moment(value, "L").isValid() || "Data Inválida!",
                    minDate: value => moment(value, "L").isAfter(moment().subtract(120,'years')) || "Deve possuir menos de 120 anos",
                    maxDate: value => moment(value, "L").isBefore(moment().subtract(18,'years')) || "Deve possuir pelo menos 18 anos"
                  }
                })}
              />

            </Grid>

            <Grid item xs={12} sm={6} lg={4}>

              <FormInput
                required
                name="coRg"
                label="RG"
                maxLength={10}
                error={errors.coRg?.message?.toString()}
                inputRef={register({
                  required: 'Informe seu RG',
                })}
              />

            </Grid>

            <Grid item xs={12} sm={6} lg={4}>

              <FormInput
                required
                name="noOrgaoEmissorRg"
                label="Orgão Emissor"
                maxLength={100}
                error={errors.noOrgaoEmissorRg?.message?.toString()}
                inputRef={register({
                  required: 'Informe o Orgão Emissor',
                })}
              />

            </Grid>

            <Grid item xs={12} sm={6} lg={4}>

              <FormInput
                required
                name="icEstadoCivil"
                label="Estado Civil"
                error={errors.icEstadoCivil?.message?.toString()}
                inputRef={register({
                  required: 'Informe seu Estado Civil',
                })}
                select
              >
                <option value="" />
                {Object.keys(EstadoCivil).filter(key => !isNaN(Number(key))).map(key => (
                  <option key={key} value={key}>{EstadoCivil[Number(key)]}</option>
                ))}
              </FormInput>

            </Grid>

            <Grid item xs={12} sm={6} lg={4}>

              <FormInput
                required
                name="icSexo"
                label="Sexo"
                error={errors.icSexo?.message?.toString()}
                inputRef={register({
                  required: 'Informe seu Sexo',
                })}
                select
              >
                <option value="" />
                {Object.keys(Sexo).filter(key => !isNaN(Number(key))).map(key => (
                  <option key={key} value={key}>{Sexo[Number(key)]}</option>
                ))}
              </FormInput>

            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <FormInput
                name="nuTelefone"
                label="Telefone"
                placeholder="(99)?9999-9999"
                maxLength={14}
                inputComponent={TelefoneMaskedInput as any}
                error={errors.nuTelefone?.message?.toString()}
                inputRef={register({
                  pattern: { value: Patterns.telefone, message: 'Formato (99)?9999-9999' },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormInput
                required
                name="nuCelular"
                label="Celular"
                placeholder="(99)99999-9999"
                maxLength={14}
                inputComponent={TelefoneMaskedInput as any}
                error={errors.nuCelular?.message?.toString()}
                inputRef={register({
                  required: 'Informe seu Celular',
                  pattern: { value: Patterns.celular, message: 'Formato (99)99999-9999' },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>

              <FormInput
                required
                name="noProfissao"
                label="Profissão"
                maxLength={100}
                error={errors.noProfissao?.message?.toString()}
                inputRef={register({
                  required: 'Informe sua Profissão',
                })}
              />

            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <FormControl component="fieldset" required error={!!errors.icPpe?.message?.toString()}>
                <FormLabel component="legend">Pessoa Politicamente Exposta (PPE)?</FormLabel>
                <Controller
                  name="icPpe"
                  as={
                    <RadioGroup row>
                      <FormControlLabel value="true" control={<Radio />} label="Sim" />
                      <FormControlLabel value="false" control={<Radio />} label="Não" />
                    </RadioGroup>
                  }
                  rules={{ required: "Informe se é PPE" }}
                  control={control}
                />
                <FormHelperText>{errors.icPpe?.message?.toString()}</FormHelperText>
              </FormControl>

            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormControl component="fieldset" required error={!!errors.icNivelInvestidor?.message?.toString()}>
                <FormLabel component="legend">Nível de Investidor</FormLabel>
                <Controller
                  name="icNivelInvestidor"
                  as={
                    <RadioGroup
                    //onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => { handleValidInput("investidor.icNivelInvestidor")(Number(value)); }}
                    >
                      {Object.keys(NivelInvestidor).filter(key => !isNaN(Number(key))).map(key => (
                        <FormControlLabel key={key} value={key} control={<Radio />} label={NivelInvestidor[Number(key)]} />
                      ))}
                    </RadioGroup>
                  }
                  rules={{ required: "Informe o Nível de Investidor" }}
                  control={control}
                />
                <FormHelperText>{errors.icNivelInvestidor?.message?.toString()}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item container justify="flex-end" spacing={2}>
              {onBack &&
                <Grid item>
                  <Button
                    id="btnVoltar"
                    variant="text"
                    color="default"
                    onClick={onBack}
                  >
                    Voltar
              </Button>
                </Grid>
              }
              <Grid item>
                <ButtonProgress
                  id="btnSubmit"
                  loading={isSubmitting}
                  disabled={Object.values(errors).length > 0 || (formMode == InvestidorDadosBasicosFormMode.EDIT && !dirty)}
                  variant="contained"
                  color="primary"
                  type="submit">
                  {submitText}
                </ButtonProgress>
              </Grid>
            </Grid>


          </Grid>


        </Box>
      </Paper>
    </form>
  )
}

import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom';
import GottaupLogo from '../images/gottaup-logo.png'
import { removeSpecialChars } from '../ViewUtils';

export interface PageMetaTagsProps {
    title: string;
    description: string;
    imageUrl?: string;
}

export const PageMetaTags = ({ title, description, imageUrl }: PageMetaTagsProps) => {

    const location = useLocation();
    const url = SITE_URL + location.pathname;
    const imageUrlDefault = SITE_URL + GottaupLogo;
    const descriptionTreated = removeSpecialChars(description);

    return (
        <Helmet>
            {/* Primary Meta Tags */}
            <title>{title}</title>
            <meta name="title" content={title} />
            <meta name="description" content={descriptionTreated} />

            {/* Open Graph / Facebook  */}
            <meta property="og:site_name" content="Gottaup.com" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={url} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={descriptionTreated} />
            <meta property="og:image" content={imageUrl || imageUrlDefault} />

            {/* Twitter */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={url} />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={descriptionTreated} />
            <meta property="twitter:image" content={imageUrl || imageUrlDefault} />
            <meta property="twitter:site" content="@gottaup" />

        </Helmet>
    )
}

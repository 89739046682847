import React, { useState, useEffect, FormEvent } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

export interface ConfirmDialogProps {
    open: boolean;
    title: string;
    content: string;
    form?: (formSubmitted: boolean) => React.ReactNode;
    onConfirm: () => void;
    onCancel: () => void;
}

export function ConfirmDialog(props: ConfirmDialogProps) {

    const { open, title, content, onConfirm, onCancel , form } = props;

    const [dialogOpen, setDialogOpen] = useState(open);

    const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

    useEffect(() => {
        setDialogOpen(open);
    }, [open])

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        const form = e.target as HTMLFormElement;
        if (!form.checkValidity()) {
            setFormSubmitted(true);
        } else {
            onConfirm();
        }
    }

    return (

        <Dialog
            open={dialogOpen}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                </DialogContentText>
                <form id="dialogForm" noValidate autoComplete="off" onSubmit={handleSubmit}>
                    {form && form(formSubmitted)}
                </form>
            </DialogContent>
            <DialogActions>
                <Button id="btnDialogCancelar" onClick={onCancel} color="primary">
                    CANCELAR
                </Button>
                <Button id="btnDialogConfirmar" type="submit" color="primary" autoFocus form="dialogForm">
                    CONFIRMAR
                </Button>
            </DialogActions>
        </Dialog>

    )
}
import React from 'react';
import { Box, Card, CardContent, CardHeader, Container, Divider, Grid, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { ContaUsuarioVO } from '../../../../models/ContaUsuario';
import { FileUtils } from '../../../../utils/files';
import { ImageUtils } from '../../../../utils/images';
import ButtonProgress from '../../../components/ButtonProgress';
import { useDialog } from '../../../components/dialog/PageServiceProvider';
import FormPhotoUpload from '../../../components/form/inputs/FileUpload/FormPhotoUpload';
import FormInput from '../../../components/FormInput';
import PageTemplate from '../../../layout/PageTemplate';
import { Patterns } from '../../../../utils/patterns';
import {autoRegistro} from "../../../../stores/slices/userSlice";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../../../browser";
import {APIError} from "../../../../services/api/APIError";
import {generateErrorDialog} from "../../../../services/api/APIErrorUtils";

interface RegistroFormData {
  usuario: {
    noUsuario: string;
    noEmail: string;
    senha: string;
    imagemPerfil: {
      noImagem: string;
      imImagem: string;
    };
  };
  senhaConfirmacao: string;
}

export const RegistroPage = () => {

  const { register, handleSubmit, errors, getValues, setValue, formState: { isSubmitting, dirty } } = useForm<RegistroFormData>({ mode: 'onBlur' });

  const dispatch = useDispatch<AppDispatch>();

  const dialog = useDialog();

  const history = useHistory();
  const { from } = history.location.state as any || { from: { pathname: "/login" } };
  

  const handleChangeImagemPerfil = async (file: File | null) => {
    if (file) {
      setValue("usuario.imagemPerfil.noImagem", file.name, true);
      const imageResized = FileUtils.dataURLtoBase64(await ImageUtils.resizeImageKeepProportions(await FileUtils.fileToDataURL(file), 200, 200));
      setValue("usuario.imagemPerfil.imImagem", imageResized);
    } else {
      setValue("usuario.imagemPerfil.noImagem", '');
      setValue("usuario.imagemPerfil.imImagem", '');
    }
  }

  const createUsuario = async (formData: RegistroFormData) => {
    const confirmed = await dialog({
      title: "Confirma registro do Investidor?",
      description: "Ao confirmar as informações serão salvas. Se cancelar, nenhum dado será salvo no servidor.",
      confirmOption: "CONFIRMAR",
      cancelOption: "CANCELAR"
    })
    if (confirmed) {
      const usuario = {
        noEmail: formData.usuario.noEmail,
        senha: formData.usuario.senha,
        noUsuario: formData.usuario.noUsuario,
        imagemPerfil: formData.usuario.imagemPerfil.noImagem ? {
          noImagem: formData.usuario.imagemPerfil.noImagem,
          imImagem: formData.usuario.imagemPerfil.imImagem
        } : undefined
      } as ContaUsuarioVO
      const resultAction = await dispatch(autoRegistro({usuario}));
      if(autoRegistro.fulfilled.match(resultAction)){
        history.push("/login/validacao/email");
      } else {
        const error = resultAction.payload || resultAction.error as APIError;
        dialog(generateErrorDialog(error, "Não foi possível concluir o cadastro da conta. Tente novamente."))
      }
    }
  }

  return (

    <PageTemplate
      metaTags={{
        title: "Gottaup.com | Registro de Novo Investidor",
        description: "Efetue aqui o seu registro de investidor"
      }}
      menuDesktop
      headerMobile={{
        title: "Registro",
        leftButtons: (
          <IconButton edge="start" color="inherit" component={Link} to={from}>
            <ArrowBackIcon />
          </IconButton>
        )
      }}
      headerDesktop={{
        title: "Registro de Novo Investidor",
        breadcrumbs: [
          { label: "Home", to: '/', icon: HomeIcon },
          { label: "Login", to: '/login', icon: PersonIcon },
          { label: "Registro" }
        ]
      }}
      confirmLeavePage={{
        when: dirty && !isSubmitting,
        title: "Tem certeza que deseja deixar a página sem salvar as alterações?",
        content: "Ao confirmar os dados alterados não serão salvos. Se cancelar, poderá continuar a edição."
      }}
    >

      <Container component="section" style={{ marginTop: 24 }}>

        <form noValidate autoComplete="off" onSubmit={handleSubmit(createUsuario)} >

          <Grid container spacing={3}>

            <Grid item xs={12} md={6}>
              <Card>
                <CardHeader title="Dados da Conta" />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormInput
                        name="usuario.noUsuario"
                        required
                        label="Nome do Investidor"
                        helperText="Nome Completo"
                        maxLength={100}
                        error={errors.usuario?.noUsuario?.message?.toString()}
                        inputRef={register({
                          required: 'Informe o Nome Completo',
                          maxLength: 100
                        })}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormInput
                        name="usuario.noEmail"
                        required
                        label="Email"
                        helperText="Email que será utilizado como login"
                        placeholder="usuario@domínio.com"
                        maxLength={100}
                        error={errors.usuario?.noEmail?.message?.toString()}
                        inputRef={register({
                          required: 'Informe o email',
                          pattern: { value: Patterns.email, message: 'Email em formato inválido' },
                          maxLength: 100
                        })}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormInput
                        name="usuario.senha"
                        required
                        label="Nova Senha"
                        placeholder="*******"
                        helperText="Senha com 6 a 8 caractéres"
                        maxLength={8}
                        error={errors.usuario?.senha?.message?.toString()}
                        inputRef={register({
                          required: 'Informe a Senha',
                          minLength: { value: 6, message: 'Informe ao menos 6 caractéres' }
                        })}
                        type="password"
                      />

                    </Grid>
                    <Grid item xs={12}>
                      <FormInput
                        name="senhaConfirmacao"
                        required
                        label="Confirmação da Senha"
                        placeholder="*******"
                        helperText=" "
                        maxLength={8}
                        error={errors.senhaConfirmacao?.message?.toString()}
                        inputRef={register({
                          required: 'Confirme a Senha',
                          minLength: { value: 6, message: 'Informe ao menos 6 caractéres' },
                          validate: {
                            matchesPreviousPassword: senhaConfirmacao => {
                              const senha = getValues({ nest: true }).usuario.senha;
                              return senha === senhaConfirmacao || "Senhas não conferem!";
                            }
                          }
                        })}
                        type="password"
                      />

                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card>
                <CardHeader title="Foto de Perfil" />
                <Divider />
                <CardContent>
                  <Grid container alignItems="center" justify="center" spacing={1}>
                    <Grid item xs={12} >
                      <input
                        hidden
                        name="usuario.imagemPerfil.noImagem"
                        ref={register({
                          validate: {
                            extension: value => !value || value.match(/\.(jpg)|(png)$/) ? true : 'Arquivo não aceito! Selecione uma imagem em formato .jpg ou .png',
                          }
                        })} />
                      <input
                        hidden
                        name="usuario.imagemPerfil.imImagem"
                        ref={register()} />
                      <FormPhotoUpload
                        accept=".jpg, .png"
                        helperText="Utilize imagens .jpg ou .png com pelo menos 200px"
                        error={errors.usuario?.imagemPerfil?.noImagem?.message?.toString()}
                        variant="avatar"
                        aspect={1}
                        canEdit={true}
                        onChange={handleChangeImagemPerfil}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="flex-end" mt={1} mb={1}>
            <ButtonProgress
              id="btnCadastrarUsuario"
              loading={isSubmitting}
              variant="contained"
              color="secondary"
              type="submit">
              Cadastrar
            </ButtonProgress>
          </Box>

        </form>
      </Container>

    </PageTemplate>
  )
}
import React, { useState, useEffect } from 'react'
import { ConfirmDialogProps, ConfirmDialog } from './ConfirmDialog';
import { Prompt, useHistory } from 'react-router-dom';

export interface ConfirmLeavePageProps {
    when: boolean;
    title: string;
    content: string;
}

export default function ConfirmLeavePage(props: ConfirmLeavePageProps) {

    const [enabled, setEnabled] = useState(props.when);
    const history = useHistory();
    const [dialog, setDialog] = useState<ConfirmDialogProps | undefined>(undefined);

    useEffect(() => {
        setEnabled(props.when);
        if(props.when){
            setDialog(undefined);
        }
    },[props.when]);


    const confirmLeavePage = (location: any) => {
        setEnabled(false);
        setDialog({
            open: true,
            title: props.title,
            content: props.content,
            onConfirm: () => history.push(location),
            onCancel: () => { setEnabled(true); setDialog(undefined); } //Atualiza unsavedFormData para voltar a disparar Prompt do Navegador
        });
        return false;
    }

    return (
        <>
            <Prompt
                when={enabled}
                message={confirmLeavePage}
            />
            {dialog && <ConfirmDialog {...dialog} />}
        </>
    )

}
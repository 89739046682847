import {match} from "react-router";
import {RouteConfig} from "react-router-config";
import {RouteComponentProps} from "react-router-dom";
import {Perfil} from "../models/enums/Perfil";
import {InvestimentosByProjetoListPage} from "./pages/admin/projetos/id/investimentos/root/InvestimentosByProjetoListPage";
import {CaptarPage} from "./pages/captar/root";
import {NotFoundPage} from "./pages/errors/NotFoundPage";
import {DetalhesProjetoPage, DetalhesProjetoLoadDataSSR} from "./pages/investir/permalink/root/DetalhesProjetoPage";
import {ResetSenhaPage} from "./pages/login/senha/reset";
import {ValidacaoEmailPage} from "./pages/login/validacao/email";
import {MenuMobilePage} from "./pages/menu/root";
import {UploadContratoInvestimentoPage} from "./pages/operacional/contratos";
import {AprovacaoDepositosReservasPage} from "./pages/operacional/depositos";
import {ValidacaoDocumentosInvestidorPage} from "./pages/operacional/documentos";
import {TaxaJurosListPage} from "./pages/admin/taxajuros/root/TaxaJurosListPage";
import {EmpresasListPage} from "./pages/admin/empresas/root/EmpresasListPage";
import {EmpresaCreatePage} from "./pages/admin/empresas/create/EmpresaCreatePage";
import {EmpresaEditPage} from "./pages/admin/empresas/id/EmpresaEditPage";
import {ProjetosListPage} from "./pages/admin/projetos/root/ProjetosListPage";
import {ProjetoCreatePage} from "./pages/admin/projetos/create/ProjetoCreatePage";
import {ProjetoEditPage} from "./pages/admin/projetos/id/root/ProjetoEditPage";
import {HomePage, HomePageLoadDataSSR} from "./pages/root/HomePage";
import {ProfileEditPage} from "./pages/login/perfil/ProfileEditPage";
import {CaptacoesAbertasPage, CaptacoesAbertasLoadDataSSR} from "./pages/investir/root/CaptacoesAbertasPage";
import {RegistroPage} from "./pages/login/registro/RegistroPage";
import {MinhaContaPage} from "./pages/conta/root/MinhaContaPage";
import {PainelInvestimentosPage} from "./pages/conta/painel/PainelInvestimentosPage";
import {EfetuarReservaPage} from "./pages/investir/permalink/reserva/root/EfetuarReservaPage";
import {InvestidorCreatePage} from "./pages/conta/id/investidor/root/create/InvestidorCreatePage";
import {CadastroInvestidorEntryPage} from "./pages/conta/id/investidor/root/CadastroInvestidorEntryPage";
import {InvestidorEditPage} from "./pages/conta/id/investidor/root/edit/InvestidorEditPage";
import {UsuariosListPage} from "./pages/admin/usuarios/root/UsuariosListPage";
import {UsuariosCreatePage} from "./pages/admin/usuarios/create/UsuariosCreatePage";
import {UsuariosEditPage} from "./pages/admin/usuarios/id/UsuariosEditPage";

export interface ExtendedRouteProps extends RouteConfig {
  path?: string;
  loadData?: (match: match<any>) => any;
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  needsLogin?: boolean;
  rolesAuthorized?: Perfil[];
}

//TODO: Avaliar https://github.com/kriasoft/react-starter-kit
//TODO: https://github.com/ReactTraining/react-router/issues/4962

export const routes: Array<ExtendedRouteProps> = [
  {
    path: "/",
    exact: true,
    component: HomePage,
    loadData: HomePageLoadDataSSR
  },
  {
    path: "/login/registro",
    exact: true,
    component: RegistroPage
  },
  {
    path: "/login/perfil",
    component: ProfileEditPage,
    needsLogin: true
  },
  {
    path: "/login/validacao/email",
    component: ValidacaoEmailPage,
    exact: true
  },
  {
    path: "/login/senha/reset",
    component: ResetSenhaPage,
    exact: true
  },
  {
    path: "/menu",
    exact: true,
    component: MenuMobilePage
  },
  {
    path: "/investir",
    exact: true,
    component: CaptacoesAbertasPage,
    loadData: CaptacoesAbertasLoadDataSSR
  },
  {
    path: "/investir/:permalink",
    exact: true,
    component: DetalhesProjetoPage,
    loadData: DetalhesProjetoLoadDataSSR
  },
  {
    path: "/investir/:permalink/reserva",
    exact: true,
    needsLogin: true,
    component: EfetuarReservaPage
  },
  {
    path: "/captar",
    exact: true,
    component: CaptarPage
  },
  {
    path: "/conta",
    exact: true,
    component: MinhaContaPage
  },
  {
    path: "/conta/:id?/investidor",
    exact: true,
    component: CadastroInvestidorEntryPage,
    needsLogin: true
  },
  {
    path: "/conta/:id?/investidor/create",
    exact: true,
    component: InvestidorCreatePage,
    needsLogin: true
  },
  {
    path: "/conta/:id?/investidor/edit",
    exact: true,
    component: InvestidorEditPage,
    needsLogin: true
  },
  {
    path: "/conta/painel",
    exact: true,
    component: PainelInvestimentosPage,
    needsLogin: true
  },
  {
    path: "/admin/empresas",
    exact: true,
    component: EmpresasListPage,
    needsLogin: true,
    rolesAuthorized: [Perfil.Administrador]
  },
  {
    path: "/admin/empresas/create",
    exact: true,
    component: EmpresaCreatePage,
    needsLogin: true,
    rolesAuthorized: [Perfil.Administrador]
  },
  {
    path: "/admin/empresas/:cnpj",
    component: EmpresaEditPage,
    needsLogin: true,
    rolesAuthorized: [Perfil.Administrador]
  },
  {
    path: "/admin/projetos",
    exact: true,
    component: ProjetosListPage,
    needsLogin: true,
    rolesAuthorized: [Perfil.Administrador]
  },
  {
    path: "/admin/projetos/create",
    exact: true,
    component: ProjetoCreatePage,
    needsLogin: true,
    rolesAuthorized: [Perfil.Administrador]
  },
  {
    path: "/admin/projetos/:id",
    exact: true,
    component: ProjetoEditPage,
    needsLogin: true,
    rolesAuthorized: [Perfil.Administrador]
  },
  {
    path: "/admin/projetos/:id/investimentos",
    component: InvestimentosByProjetoListPage,
    needsLogin: true,
    exact: true,
    rolesAuthorized: [Perfil.Administrador]
  },
  {
    path: "/admin/usuarios",
    exact: true,
    needsLogin: true,
    rolesAuthorized: [Perfil.Administrador],
    component: UsuariosListPage,
  },
  {
    path: "/admin/usuarios/create",
    exact: true,
    needsLogin: true,
    rolesAuthorized: [Perfil.Administrador],
    component: UsuariosCreatePage
  },
  {
    path: "/admin/usuarios/:id",
    component: UsuariosEditPage,
    needsLogin: true,
    rolesAuthorized: [Perfil.Administrador]
  },
  {
    path: "/admin/taxasjuros",
    exact: true,
    component: TaxaJurosListPage,
    needsLogin: true,
    rolesAuthorized: [Perfil.Administrador]
  },
  {
    path: "/operacional/depositos",
    component: AprovacaoDepositosReservasPage,
    needsLogin: true,
    rolesAuthorized: [Perfil.Administrador]
  },
  {
    path: "/operacional/contratos",
    component: UploadContratoInvestimentoPage,
    needsLogin: true,
    rolesAuthorized: [Perfil.Administrador]
  },
  {
    path: "/operacional/documentos",
    component: ValidacaoDocumentosInvestidorPage,
    needsLogin: true,
    rolesAuthorized: [Perfil.Administrador]
  },
  {
    component: NotFoundPage
  }
];
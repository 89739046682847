import ISODate from "./types/ISODate";
import { parse } from "date-fns";
import { formatMoeda } from "../views/ViewUtils";

export interface ValorCotaVO {
  coValorCota: number;
  dtInicio: string;
  dtFim: string;
  vrCota: number;
}

export default class ValorCota{

    public coValorCota: number | undefined;
    public dtInicio: ISODate | undefined;
    public dtFim: ISODate | undefined;
    public vrCota: number | undefined;

    constructor(obj: object | null = null) {
        if (obj) {
            Object.assign(this, obj);
            if (this.dtInicio) this.dtInicio = new ISODate(String(this.dtInicio))
            if (this.dtFim) this.dtFim = new ISODate(String(this.dtFim))
        }
        Object.seal(this); // Não permite alterar estrutura da classe
    }

    set dtInicioFormatado(value) {
        this.dtInicio = value ? new ISODate(parse(value, 'dd/MM/yyyy', new Date())) : undefined;
    }

    get dtInicioFormatado() {
        return this.dtInicio ? this.dtInicio.toFormat('dd/MM/yyyy') : ''
    }

    set dtFimFormatado(value) {
        this.dtFim = value ? new ISODate(parse(value, 'dd/MM/yyyy', new Date())) : undefined;
    }

    get dtFimFormatado() {
        return this.dtFim ? this.dtFim.toFormat('dd/MM/yyyy') : ''
    }

    set vrCotaFormatado(value: string) {
        this.vrCota = value ? Number.parseFloat(value.replace(/\./g, '').replace(',', '.')) : undefined;
    }

    get vrCotaFormatado() {
        return this.vrCota ? formatMoeda(this.vrCota) : '';
    }

}
import { Area } from "react-easy-crop";
import { FileUtils } from "./files";

const getRadianAngle = (degreeValue: number) => (degreeValue * Math.PI) / 180;

export const ImageUtils = {

    cropImage: (imageSrc: string, pixelCrop: Area, rotation = 0, type = 'image/png', quality = 0.92) => {
        const image = new Image();
        image.src = imageSrc;
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
    
        const safeArea = Math.max(image.width, image.height) * 2
    
        // set each dimensions to double largest dimension to allow for a safe area for the
        // image to rotate in without being clipped by canvas context
        canvas.width = safeArea
        canvas.height = safeArea
    
        if (ctx) {
            // translate canvas context to a central location on image to allow rotating around the center.
            ctx.translate(safeArea / 2, safeArea / 2)
            ctx.rotate(getRadianAngle(rotation))
            ctx.translate(-safeArea / 2, -safeArea / 2)
    
            // draw rotated image and store data.
            ctx.drawImage(
                image,
                safeArea / 2 - image.width * 0.5,
                safeArea / 2 - image.height * 0.5
            )
            const data = ctx.getImageData(0, 0, safeArea, safeArea)
    
            // set canvas width to final desired crop size - this will clear existing context
            canvas.width = pixelCrop.width
            canvas.height = pixelCrop.height
    
            // paste generated rotate image with correct offsets for x,y crop values.
            ctx.putImageData(
                data,
                0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
                0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
            )
    
        }
    
    
        // As Base64 string
        return canvas.toDataURL(type,quality);
    
        /* As a blob
        return new Promise(resolve => {
            canvas.toBlob(file => {
                resolve(URL.createObjectURL(file))
            }, 'image/jpeg')
        })
        */
    },

    resizeImageKeepProportions: (dataURL: string, maxWidth: number, maxHeight: number): Promise<string> => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = dataURL;
            img.onload = () => {
                const canvas = document.createElement("canvas");
                const srcWidth = img.width;
                const srcHeight = img.height;
                let width = srcWidth;
                let height = srcHeight;
                if (srcWidth > maxWidth || srcHeight > maxHeight) {
                    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
                    width = srcWidth * ratio
                    height = srcHeight * ratio
                    console.log(srcWidth + 'X' + srcHeight + " >> " + width + 'X' + height);
                }
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext("2d");
                if (ctx) {
                    // draw the img into canvas
                    ctx.drawImage(img, 0, 0, width, height);
                    resolve(ctx.canvas.toDataURL(FileUtils.dataURLMimeType(dataURL)))
                } else {
                    reject("Nao foi possível rederizar um canvas");
                }
            }
        });
    }


}
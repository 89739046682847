import { Avatar, Button, createStyles, Grid, IconButton, InputAdornment, makeStyles, Theme, Tooltip, Typography, useTheme } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import ReplyIcon from '@material-ui/icons/Reply';
import SendIcon from '@material-ui/icons/Send';
import moment from 'moment';
import React, { FormEvent, Fragment, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { MotivoRemocaoForum } from '../../../../../../models/enums/MotivoRemocaoForum';
import { Perfil } from '../../../../../../models/enums/Perfil';
import { SituacaoPerguntaForum } from '../../../../../../models/enums/SituacaoPerguntaForum';
import { PerguntaForumProjetoVO } from '../../../../../../models/PerguntaForumProjeto';
import { ProjetoVO } from '../../../../../../models/Projeto';
import { APIUtils } from '../../../../../../services/api/APIUtils';
import { selectLogin } from '../../../../../../stores/slices/userSlice';
import { LoginUtils } from '../../../../../../utils/login';
import { ConfirmDialog, ConfirmDialogProps } from '../../../../../components/ConfirmDialog';
import TextInput from '../../../../../components/TextInput';
import { setPathProperty } from '../../../../../ViewUtils';


const useStyles = makeStyles((theme: Theme) => createStyles({
  avatar: {
    width: 40,
    height: 40,
  },
  tags: {
    marginLeft: theme.spacing(1),
    color: '#f5f5f5',
    backgroundColor: theme.palette.info.dark,
    borderRadius: 2,
    padding: '1px 5px',
  },
  perguntaActions: {
    marginLeft: theme.spacing(3),
    color: '#1976d2'
  },
  listItem: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    borderRadius: 2,
    border: '1px solid #eaeaea',
  },
  respostaCreate: {
    marginTop: theme.spacing(1),
  },
  respostaItem: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: 2,
    border: '1px solid #eaeaea',
    background: '#ededed'
  },
  textoPerguntas: {
    marginTop: theme.spacing(0.5),
    color: '#546e7a',
    textAlign: 'justify'
  },
  listFieldValue: {
    fontWeight: 500
  },
}), { name: 'ForumPerguntasProjetoItem' });


interface Props {
  projeto: ProjetoVO;
  pergunta: PerguntaForumProjetoVO;
  onRemovePerguntaForumSubmit: (coPerguntaForumProjeto: number, motivoRemocaoForum: MotivoRemocaoForum) => void;
  onRespondePerguntaForumSubmit: (coPerguntaForumProjeto: number, deResposta: string) => void;
}

interface State {
  resposta: string;
  motivoRemocao: MotivoRemocaoForum | undefined;
}

export default function ForumPerguntasProjetoItem(props: Props) {

  const classes = useStyles();
  const theme = useTheme();

  const login = useSelector(selectLogin);

  const { pergunta } = props;
  const [isResponding, setIsResponding] = useState(false);
  const [state, setState] = useState<State>({ resposta: '', motivoRemocao: undefined });

  const formRef = useRef<HTMLFormElement>(null);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  const [dialog, setDialog] = useState<ConfirmDialogProps | undefined>(undefined);

  const handleValidInput = (path: string) => (newValue: string | number) => {
    setPathProperty(path, state, newValue);
    setState({ ...state });
  }


  const validaConfirmaEnvioResposta = (e: FormEvent) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    if (!form.checkValidity()) {
      setFormSubmitted(true);
    } else {
      setDialog({
        open: true,
        title: "Confirma envio da resposta?",
        content: "Ao confirmar sua resposta será registrada na pergunta. Se cancelar, nenhum dado será salvo no servidor.",
        onConfirm: () => {
          setDialog(undefined); // Fecha dialog
          props.onRespondePerguntaForumSubmit(props.pergunta.coPerguntaForumProjeto || 0, state.resposta); //chama evento no pai para persistir
          setIsResponding(false);
        },
        onCancel: () => setDialog(undefined)
      })
    }
  }

  const confirmaRemocao = () => {
    const FormMotivoRemocaoDialog = (formSubmitted: boolean) => {
      return (
        <TextInput
          required
          label="Motivo da Remoção"
          onValidInput={coMotivo => handleValidInput("motivoRemocao")(parseInt(coMotivo))}
          formSubmitted={formSubmitted}
          validationMessages={{
            invalid: 'Informe o Motivo de Remoção'
          }}
          select
        >
          <option value="" />
          <option value={MotivoRemocaoForum["Não pertinente a captação"]}>{MotivoRemocaoForum[MotivoRemocaoForum["Não pertinente a captação"]]}</option>
          <option value={MotivoRemocaoForum["Fere política do site"]}>{MotivoRemocaoForum[MotivoRemocaoForum["Fere política do site"]]}</option>
        </TextInput >
      )
    }
    if (props.pergunta.usuarioPergunta?.coContaUsuario == login?.id) {
      // Pergunta do próprio usuário
      setDialog({
        open: true,
        title: "Confirma a remoção de sua pergunta?",
        content: "Se confirmar, sua pergunta será removida do fórum. Se cancelar, nenhuma alteração será salva no servidor.",
        onConfirm: () => {
          setDialog(undefined); // Fecha dialog
          props.onRemovePerguntaForumSubmit(props.pergunta.coPerguntaForumProjeto || 0, MotivoRemocaoForum["Removida pelo investidor"]); //chama evento no pai para persistir
        },
        onCancel: () => setDialog(undefined)
      });
    } else {
      // Pergunta de outro usuário, então precisa de motivo
      setDialog({
        open: true,
        title: "Confirma a remoção desta pergunta?",
        content: "Para confirmar informe o motivo da remoção.",
        form: FormMotivoRemocaoDialog,
        onConfirm: () => {
          setDialog(undefined); // Fecha dialog
          props.onRemovePerguntaForumSubmit(props.pergunta.coPerguntaForumProjeto || 0, state.motivoRemocao || 0); //chama evento no pai para persistir
        },
        onCancel: () => setDialog(undefined)
      });
    }

  }

  return (
    <Fragment>
      {dialog && <ConfirmDialog {...dialog} />}
      <Grid container className={classes.listItem}>
        <Grid container direction="row" spacing={1}>
          <Grid item>
            <Avatar src={APIUtils.generateImageURL(pergunta.usuarioPergunta.imagemPerfil)} className={classes.avatar} />
          </Grid>
          <Grid item xs>
            <Grid container direction="column">
              <Grid item>
                <Typography variant="subtitle2" className={classes.listFieldValue}>
                  {pergunta.usuarioPergunta.noUsuario}
                  <Typography
                    component="span"
                    variant="body2"
                    style={{ color: theme.palette.text.disabled, marginLeft: theme.spacing(1) }}
                  >
                    — {moment(pergunta.dhPergunta).fromNow()}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" className={classes.textoPerguntas}>{pergunta.dePergunta}</Typography>
              </Grid>
              {pergunta.situacaoPerguntaForum == SituacaoPerguntaForum["Não respondida"] &&
                login && LoginUtils.isValid(login) && (LoginUtils.isUserInRole(login, Perfil.Administrador) || pergunta.usuarioPergunta?.coContaUsuario == login.id) &&
                <Grid item className={classes.perguntaActions}>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <Button startIcon={<BlockIcon />} size="small" color="inherit" onClick={confirmaRemocao}>
                        Remover
                      </Button>
                    </Grid>
                    {LoginUtils.isUserInRole(login, Perfil.Administrador) && pergunta.usuarioPergunta.coContaUsuario != login.id &&
                      <Grid item>
                        <Button startIcon={<ReplyIcon />} size="small" color="inherit" onClick={() => setIsResponding(!isResponding)}>
                          Responder
                        </Button>
                      </Grid>
                    }
                  </Grid>
                </Grid>
              }
              {isResponding &&
                <Grid item className={classes.respostaCreate}>
                  <Grid container alignContent="center" justify="flex-start">
                    <Grid item xs>
                      <form id={`formResposta[${pergunta.coPerguntaForumProjeto}]`} noValidate autoComplete="off" ref={formRef} onSubmit={validaConfirmaEnvioResposta}>
                        <TextInput
                          required
                          variant="outlined"
                          characterCounter
                          placeholder="Escreva uma resposta ..."
                          maxLength={1000}
                          initialValue={state.resposta}
                          startAdornment={
                            <InputAdornment position="start">
                              <ChatOutlinedIcon color="action" />
                            </InputAdornment>
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <Tooltip title="Enviar resposta" placement="right">
                                <IconButton edge="end" type="submit">
                                  <SendIcon color="secondary" />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          }
                          validationMessages={{
                            invalid: 'Escreva a resposta'
                          }}
                          onValidInput={handleValidInput("resposta")}
                          submitOnEnter={true}
                          formSubmitted={formSubmitted}
                        />
                      </form>
                    </Grid>
                  </Grid>
                </Grid>
              }
              {pergunta.situacaoPerguntaForum == SituacaoPerguntaForum.Respondida &&
                <Grid item className={classes.respostaItem}>
                  <Grid container direction="row" spacing={1} >
                    <Grid item>
                      <Avatar src={APIUtils.generateImageURL(pergunta.usuarioResposta?.imagemPerfil)} className={classes.avatar} />
                    </Grid>
                    <Grid item xs>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography variant="subtitle2" className={classes.listFieldValue}>
                            {pergunta.usuarioResposta?.noUsuario}
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.tags}
                            >
                              {props.projeto.empresa?.noFantasia}
                            </Typography>
                            <Typography
                              component="span"
                              variant="body2"
                              style={{ color: theme.palette.text.disabled, marginLeft: theme.spacing(1) }}
                            >
                              — {moment(pergunta.dhResposta).fromNow()}
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2" className={classes.textoPerguntas}>{pergunta.deResposta}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </Fragment>

  )
}
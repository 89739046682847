import React from 'react';
import OnlyDesktop from './OnlyDesktop';
import PageBreadcrumbs, { BreadcrumbItem } from './PageBreadcrumbs';
import { PageTitle } from './PageTitle';

export interface PageHeaderDesktopProps {
    title: string;
    breadcrumbs: BreadcrumbItem[];
}

export default function PageHeaderDesktop({title,breadcrumbs}: PageHeaderDesktopProps) {

    return (
        <OnlyDesktop>
            <PageBreadcrumbs breadcrumbs={breadcrumbs} />
            <PageTitle title={title} />
        </OnlyDesktop>
    )
}

import {createAsyncThunk, createSlice, EntityState, DeepPartial, createEntityAdapter} from "@reduxjs/toolkit";
import moment from "moment";
import {fromEvent, throwError} from "rxjs";
import {catchError, takeUntil, timeout, map} from "rxjs/operators";
import {api} from "../../../services/api/api";
import {APIError} from "../../../services/api/APIError";
import {APIUtils} from "../../../services/api/APIUtils";
import {RootState} from "../../configureStore";
import {RequestState, shouldFetch} from "../../RequestsState";
import {selectToken} from "../userSlice";
import {EnderecoVO} from "../../../models/Endereco";
import {ImagemVO} from "../../../models/Imagem";
import {ProjetoVO} from "../../../models/Projeto";
import {DocumentoVO} from "../../../models/Documento";
import BatchOperationDTO from "../../../models/dtos/BatchOperationDTO";
import {InvestimentoVO} from "../../../models/Investimento";

interface ProjetosSliceState {
  requests: EntityState<RequestState<any>>;
}

const requestsAdapter = createEntityAdapter<RequestState<any>>({
  selectId: request => request.id
});

const initialState = {
  requests: requestsAdapter.getInitialState()
} as ProjetosSliceState


// REQUESTS

const REQ_FETCH_PROJETOS = "fetchProjetos";
const REQ_CREATE_PROJETO = "createProjeto";
const REQ_FETCH_PROJETO = "fetchProjeto";
const REQ_FETCH_PROJETO_INVESTIMENTOS_UPLOAD_CONTRATO = "fetchProjetoInvestimentosUploadContrato"
const REQ_DELETE_PROJETO = "deleteProjeto";
const REQ_PATCH_PROJETO_ENDERECO = "patchProjetoEndereco";
const REQ_PATCH_PROJETO_DADOS_BASICOS = "patchProjetoDadosBasicos";
const REQ_PATCH_PROJETO_CAPTACAO = "patchProjetoCaptacao";
const REQ_CHANGE_PROJETO_IMAGEM_CAPA = "changeProjetoImagemCapa";
const REQ_CHANGE_PROJETO_PROSPECTO = "changeProjetoProspecto";
const REQ_CHANGE_PROJETO_MODELO_CONTRATO = "changeProjetoModeloContrato";
const REQ_BATCH_UPDATE_PROJETO_GALERIA_FOTOS = "batchUpdateProjetoGaleriaFotos";

const requestSelectors = requestsAdapter.getSelectors((state: RootState) => state.entities.projetos.requests)

const trackFetchProjetos = (state: RootState): RequestState<ProjetoVO[]> => {
  return requestSelectors.selectById(state, REQ_FETCH_PROJETOS) ?? {id: REQ_FETCH_PROJETOS, pending: false}
}

const trackFetchProjetoInvestimentosUploadContrato = (nuProjeto: number) => (state: RootState): RequestState<InvestimentoVO[]> => {
  return requestSelectors.selectById(state, `${REQ_FETCH_PROJETO_INVESTIMENTOS_UPLOAD_CONTRATO}:${nuProjeto}`) ?? {
    id: `${REQ_FETCH_PROJETO_INVESTIMENTOS_UPLOAD_CONTRATO}:${nuProjeto}`,
    pending: false
  }
}


const trackCreateProjeto = (state: RootState): RequestState<ProjetoVO> => {
  return requestSelectors.selectById(state, REQ_CREATE_PROJETO) ?? {id: REQ_CREATE_PROJETO, pending: false}
}

const trackFetchProjeto = (id: number) => (state: RootState): RequestState<ProjetoVO> => {
  return requestSelectors.selectById(state, `${REQ_FETCH_PROJETO}:${id}`) ?? {
    id: `${REQ_FETCH_PROJETO}:${id}`,
    pending: false
  }
}

const trackDeleteProjeto = (id: number) => (state: RootState): RequestState<ProjetoVO> => {
  return requestSelectors.selectById(state, `${REQ_DELETE_PROJETO}:${id}`) ?? {
    id: `${REQ_DELETE_PROJETO}:${id}`,
    pending: false
  }
}

const trackPatchProjetoEndereco = (id: number) => (state: RootState): RequestState<ProjetoVO> => {
  return requestSelectors.selectById(state, `${REQ_PATCH_PROJETO_ENDERECO}:${id}`) ?? {
    id: `${REQ_PATCH_PROJETO_ENDERECO}:${id}`,
    pending: false
  }
}

const trackPatchProjetoDadosBasicos = (id: number) => (state: RootState): RequestState<ProjetoVO> => {
  return requestSelectors.selectById(state, `${REQ_PATCH_PROJETO_DADOS_BASICOS}:${id}`) ?? {
    id: `${REQ_PATCH_PROJETO_DADOS_BASICOS}:${id}`,
    pending: false
  }
}

const trackPatchProjetoCaptacao = (id: number) => (state: RootState): RequestState<ProjetoVO> => {
  return requestSelectors.selectById(state, `${REQ_PATCH_PROJETO_CAPTACAO}:${id}`) ?? {
    id: `${REQ_PATCH_PROJETO_CAPTACAO}:${id}`,
    pending: false
  }
}

const trackChangeProjetoImagemCapa = (id: number) => (state: RootState): RequestState<ProjetoVO> => {
  return requestSelectors.selectById(state, `${REQ_CHANGE_PROJETO_IMAGEM_CAPA}:${id}`) ?? {
    id: `${REQ_CHANGE_PROJETO_IMAGEM_CAPA}:${id}`,
    pending: false
  }
}

const trackChangeProjetoProspecto = (id: number) => (state: RootState): RequestState<ProjetoVO> => {
  return requestSelectors.selectById(state, `${REQ_CHANGE_PROJETO_PROSPECTO}:${id}`) ?? {
    id: `${REQ_CHANGE_PROJETO_PROSPECTO}:${id}`,
    pending: false
  }
}

const trackChangeProjetoModeloContrato = (id: number) => (state: RootState): RequestState<ProjetoVO> => {
  return requestSelectors.selectById(state, `${REQ_CHANGE_PROJETO_MODELO_CONTRATO}:${id}`) ?? {
    id: `${REQ_CHANGE_PROJETO_MODELO_CONTRATO}:${id}`,
    pending: false
  }
}

const trackBatchUpdateProjetoGaleriaFotos = (id: number) => (state: RootState): RequestState<ProjetoVO> => {
  return requestSelectors.selectById(state, `${REQ_BATCH_UPDATE_PROJETO_GALERIA_FOTOS}:${id}`) ?? {
    id: `${REQ_BATCH_UPDATE_PROJETO_GALERIA_FOTOS}:${id}`,
    pending: false
  }
}

// THUNKS
const fetchProjetosList = createAsyncThunk<ProjetoVO[], void, { state: RootState; rejectValue: APIError }>
('projetos/fetchProjetos',
  async (args, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.projetos.fetchProjetos(token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: (args, {getState}) => shouldFetch(trackFetchProjetos(getState()))}
)

const fetchProjetoInvestimentosUploadContrato = createAsyncThunk<InvestimentoVO[], { nuProjeto: number }, { state: RootState; rejectValue: APIError }>
('projetos/fetchProjetoInvestimentosUploadContrato',
  async ({nuProjeto}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.projetos.fetchProjetoInvestimentosUploadContrato(nuProjeto,token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({nuProjeto}, {getState}) => shouldFetch(trackFetchProjetoInvestimentosUploadContrato(nuProjeto)(getState()))}
)

const createProjeto = createAsyncThunk<ProjetoVO, { projeto: DeepPartial<ProjetoVO> }, { state: RootState; rejectValue: APIError }>
('projetos/createProjeto',
  async ({projeto}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.projetos.createProjeto(projeto, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: (args, {getState}) => shouldFetch(trackCreateProjeto(getState()))}
)

const fetchProjeto = createAsyncThunk<ProjetoVO, { id: number }, { state: RootState; rejectValue: APIError }>
('projetos/fetchProjeto',
  async ({id}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.projetos.fetchProjeto(id, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({id: cnpj}, {getState}) => shouldFetch(trackFetchProjeto(cnpj)(getState()))}
)

const deleteProjeto = createAsyncThunk<void, { id: number }, { state: RootState; rejectValue: APIError }>
('projetos/deleteProjeto',
  async ({id}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.projetos.deleteProjeto(id, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({id}, {getState}) => shouldFetch(trackDeleteProjeto(id)(getState()))}
)

const patchProjetoEndereco = createAsyncThunk<void, { id: number; changes: DeepPartial<EnderecoVO> }, { state: RootState; rejectValue: APIError }>
('projetos/patchProjetoEndereco',
  async ({id, changes}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.projetos.patchProjetoEndereco(id, changes, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({id}, {getState}) => shouldFetch(trackPatchProjetoEndereco(id)(getState()))}
)

const patchProjetoDadosBasicos = createAsyncThunk<void, { id: number; changes: DeepPartial<ProjetoVO> }, { state: RootState; rejectValue: APIError }>
('projetos/patchProjetoDadosBasicos',
  async ({id, changes}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.projetos.patchProjetoDadosBasicos(id, changes, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({id}, {getState}) => shouldFetch(trackPatchProjetoDadosBasicos(id)(getState()))}
)

const patchProjetoCaptacao = createAsyncThunk<void, { id: number; changes: DeepPartial<ProjetoVO> }, { state: RootState; rejectValue: APIError }>
('projetos/patchProjetoCaptacao',
  async ({id, changes}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.projetos.patchProjetoCaptacao(id, changes, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({id}, {getState}) => shouldFetch(trackPatchProjetoCaptacao(id)(getState()))}
)


const changeProjetoImagemCapa = createAsyncThunk<void, { id: number; imagemCapa: DeepPartial<ImagemVO> }, { state: RootState; rejectValue: APIError }>
('projetos/changeProjetoImagemCapa',
  async ({id, imagemCapa}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.projetos.changeProjetoImagemCapa(id, imagemCapa, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({id}, {getState}) => shouldFetch(trackChangeProjetoImagemCapa(id)(getState()))}
)

const changeProjetoProspecto = createAsyncThunk<void, { id: number; prospecto: DeepPartial<DocumentoVO> }, { state: RootState; rejectValue: APIError }>
('projetos/changeProjetoProspecto',
  async ({id, prospecto}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.projetos.changeProjetoProspecto(id, prospecto, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({id}, {getState}) => shouldFetch(trackChangeProjetoProspecto(id)(getState()))}
)

const changeProjetoModeloContrato = createAsyncThunk<void, { id: number; modeloContrato: DeepPartial<DocumentoVO> }, { state: RootState; rejectValue: APIError }>
('projetos/changeProjetoModeloContrato',
  async ({id, modeloContrato}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.projetos.changeProjetoModeloContrato(id, modeloContrato, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({id}, {getState}) => shouldFetch(trackChangeProjetoModeloContrato(id)(getState()))}
)

const batchUpdateProjetoGaleriaFotos = createAsyncThunk<BatchOperationDTO<ImagemVO>[], { id: number; operations: BatchOperationDTO<DeepPartial<ImagemVO>>[] }, { state: RootState; rejectValue: APIError }>
('projetos/batchUpdateProjetoGaleriaFotos',
  async ({id, operations}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.projetos.batchUpdateGaleriaFotos(id, operations, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({id}, {getState}) => shouldFetch(trackBatchUpdateProjetoGaleriaFotos(id)(getState()))}
)


// SLICE
const projetosSlice = createSlice({
  name: 'projetos',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    //
    builder.addCase(fetchProjetosList.pending, (state) => {
      requestsAdapter.upsertOne(state.requests, {
        id: REQ_FETCH_PROJETOS,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(fetchProjetosList.fulfilled, (state, {payload: projetos}) => {
      requestsAdapter.updateOne(state.requests, {
        id: REQ_FETCH_PROJETOS,
        changes: {pending: false, timestamp: moment().toISOString(), data: projetos}
      });
    })
    builder.addCase(fetchProjetosList.rejected, (state, action) => {
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {id: REQ_FETCH_PROJETOS, changes: {pending: false, error}});
    })

    //
    builder.addCase(fetchProjetoInvestimentosUploadContrato.pending, (state,{meta:{arg:{nuProjeto}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_FETCH_PROJETO_INVESTIMENTOS_UPLOAD_CONTRATO}:${nuProjeto}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(fetchProjetoInvestimentosUploadContrato.fulfilled, (state, {payload: projetos,meta:{arg:{nuProjeto}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_FETCH_PROJETO_INVESTIMENTOS_UPLOAD_CONTRATO}:${nuProjeto}`,
        changes: {pending: false, timestamp: moment().toISOString(), data: projetos}
      });
    })
    builder.addCase(fetchProjetoInvestimentosUploadContrato.rejected, (state, action) => {
      const nuProjeto = action.meta.arg.nuProjeto;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {id: `${REQ_FETCH_PROJETO_INVESTIMENTOS_UPLOAD_CONTRATO}:${nuProjeto}`, changes: {pending: false, error}});
    })

    //
    builder.addCase(createProjeto.pending, (state) => {
      requestsAdapter.upsertOne(state.requests, {
        id: REQ_CREATE_PROJETO,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(createProjeto.fulfilled, (state, {payload: projeto}) => {
      requestsAdapter.updateOne(state.requests, {
        id: REQ_CREATE_PROJETO,
        changes: {pending: false, timestamp: moment().toISOString(), data: projeto}
      });
    })
    builder.addCase(createProjeto.rejected, (state, action) => {
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {id: REQ_CREATE_PROJETO, changes: {pending: false, error}});
    })

    //
    builder.addCase(fetchProjeto.pending, (state, {meta: {arg: {id}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_FETCH_PROJETO}:${id}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(fetchProjeto.fulfilled, (state, {payload: projeto, meta: {arg: {id}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_FETCH_PROJETO}:${id}`,
        changes: {pending: false, timestamp: moment().toISOString(), data: projeto}
      });
    })
    builder.addCase(fetchProjeto.rejected, (state, action) => {
      const {id: cnpj} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {id: `${REQ_FETCH_PROJETO}:${cnpj}`, changes: {pending: false, error}});
    })

    //
    builder.addCase(deleteProjeto.pending, (state, {meta: {arg: {id}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_DELETE_PROJETO}:${id}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(deleteProjeto.fulfilled, (state, {payload: projetos, meta: {arg: {id}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_DELETE_PROJETO}:${id}`,
        changes: {pending: false, timestamp: moment().toISOString(), data: projetos}
      });
    })
    builder.addCase(deleteProjeto.rejected, (state, action) => {
      const {id} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {id: `${REQ_DELETE_PROJETO}:${id}`, changes: {pending: false, error}});
    })

    //
    builder.addCase(patchProjetoEndereco.pending, (state, {meta: {arg: {id}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_PATCH_PROJETO_ENDERECO}:${id}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(patchProjetoEndereco.fulfilled, (state, {meta: {arg: {id}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_PATCH_PROJETO_ENDERECO}:${id}`,
        changes: {pending: false, timestamp: moment().toISOString()}
      });
    })
    builder.addCase(patchProjetoEndereco.rejected, (state, action) => {
      const {id} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_PATCH_PROJETO_ENDERECO}:${id}`,
        changes: {pending: false, error}
      });
    })


    //
    builder.addCase(patchProjetoDadosBasicos.pending, (state, {meta: {arg: {id}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_PATCH_PROJETO_DADOS_BASICOS}:${id}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(patchProjetoDadosBasicos.fulfilled, (state, {meta: {arg: {id}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_PATCH_PROJETO_DADOS_BASICOS}:${id}`,
        changes: {pending: false, timestamp: moment().toISOString()}
      });
    })
    builder.addCase(patchProjetoDadosBasicos.rejected, (state, action) => {
      const {id} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_PATCH_PROJETO_DADOS_BASICOS}:${id}`,
        changes: {pending: false, error}
      });
    })

    //
    builder.addCase(patchProjetoCaptacao.pending, (state, {meta: {arg: {id}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_PATCH_PROJETO_CAPTACAO}:${id}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(patchProjetoCaptacao.fulfilled, (state, {meta: {arg: {id}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_PATCH_PROJETO_CAPTACAO}:${id}`,
        changes: {pending: false, timestamp: moment().toISOString()}
      });
    })
    builder.addCase(patchProjetoCaptacao.rejected, (state, action) => {
      const {id} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_PATCH_PROJETO_CAPTACAO}:${id}`,
        changes: {pending: false, error}
      });
    })

    //
    builder.addCase(changeProjetoImagemCapa.pending, (state, {meta: {arg: {id}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_CHANGE_PROJETO_IMAGEM_CAPA}:${id}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(changeProjetoImagemCapa.fulfilled, (state, {meta: {arg: {id}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_CHANGE_PROJETO_IMAGEM_CAPA}:${id}`,
        changes: {pending: false, timestamp: moment().toISOString()}
      });
    })
    builder.addCase(changeProjetoImagemCapa.rejected, (state, action) => {
      const {id} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_CHANGE_PROJETO_IMAGEM_CAPA}:${id}`,
        changes: {pending: false, error}
      });
    })

    //
    builder.addCase(changeProjetoProspecto.pending, (state, {meta: {arg: {id}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_CHANGE_PROJETO_PROSPECTO}:${id}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(changeProjetoProspecto.fulfilled, (state, {meta: {arg: {id}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_CHANGE_PROJETO_PROSPECTO}:${id}`,
        changes: {pending: false, timestamp: moment().toISOString()}
      });
    })
    builder.addCase(changeProjetoProspecto.rejected, (state, action) => {
      const {id} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_CHANGE_PROJETO_PROSPECTO}:${id}`,
        changes: {pending: false, error}
      });
    })

    //
    builder.addCase(changeProjetoModeloContrato.pending, (state, {meta: {arg: {id}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_CHANGE_PROJETO_MODELO_CONTRATO}:${id}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(changeProjetoModeloContrato.fulfilled, (state, {meta: {arg: {id}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_CHANGE_PROJETO_MODELO_CONTRATO}:${id}`,
        changes: {pending: false, timestamp: moment().toISOString()}
      });
    })
    builder.addCase(changeProjetoModeloContrato.rejected, (state, action) => {
      const {id} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_CHANGE_PROJETO_MODELO_CONTRATO}:${id}`,
        changes: {pending: false, error}
      });
    })

    //
    builder.addCase(batchUpdateProjetoGaleriaFotos.pending, (state, {meta: {arg: {id}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_BATCH_UPDATE_PROJETO_GALERIA_FOTOS}:${id}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(batchUpdateProjetoGaleriaFotos.fulfilled, (state, {meta: {arg: {id}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_BATCH_UPDATE_PROJETO_GALERIA_FOTOS}:${id}`,
        changes: {pending: false, timestamp: moment().toISOString()}
      });
    })
    builder.addCase(batchUpdateProjetoGaleriaFotos.rejected, (state, action) => {
      const {id} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_BATCH_UPDATE_PROJETO_GALERIA_FOTOS}:${id}`,
        changes: {pending: false, error}
      });
    })

  }
})

export {
  fetchProjetosList,
  createProjeto,
  fetchProjeto,
  fetchProjetoInvestimentosUploadContrato,
  deleteProjeto,
  patchProjetoEndereco,
  patchProjetoDadosBasicos,
  patchProjetoCaptacao,
  changeProjetoImagemCapa,
  changeProjetoProspecto,
  changeProjetoModeloContrato,
  batchUpdateProjetoGaleriaFotos,
  trackFetchProjeto,
  trackFetchProjetos,
  trackFetchProjetoInvestimentosUploadContrato
};

export default projetosSlice.reducer
import { Container, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import anime from "animejs";
import React, { useEffect, useRef} from 'react';
import ReportEstatisticasPlataformaDTO from '../../../../models/dtos/ReportEstatisticasPlataformaDTO';
import Loading from '../../../components/Loading';
import VisibilitySensor from 'react-visibility-sensor';
import { FormattedNumber, FormattedPlural } from 'react-intl';
import { formatMoeda } from '../../../ViewUtils';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    color: theme.palette.common.white,
    backgroundColor: '#141d21',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      height: '50vh',
      minHeight: 500,
      maxHeight: 1300,
    },
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  estatisticas: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
    color: 'rgba(255,255,255,.8)'
  },
  titleMarkedCenter: {
    height: 4,
    width: 73,
    display: 'block',
    margin: `${theme.spacing(1)}px auto 0`,
    backgroundColor: theme.palette.primary.main,
  },
}), { name: 'NossaComunidade' });

interface Props {
  estatisticas?: ReportEstatisticasPlataformaDTO;
}

export default function NossaComunidade(props: Props) {

  const classes = useStyles();

  

  const { estatisticas } = props;

  // Referencias para animação das estatísticas
  const triggerAnimation = useRef(false);
  const rodadasCaptacaoRef = useRef<HTMLParagraphElement | null>(null)
  const investidoresAtivosRef = useRef<HTMLParagraphElement | null>(null)
  const vrTotalInvestidosRef = useRef<HTMLParagraphElement | null>(null)
  const usuariosCadastradosRef = useRef<HTMLParagraphElement | null>(null)

  const handleAnimation = () => {

    if (estatisticas && triggerAnimation.current) {

      const estatististasContador: ReportEstatisticasPlataformaDTO = {
        qtInvestidoresAtivos: 0,
        qtRodadasCaptacao: 0,
        qtUsuarios: 0,
        vrTotalInvestido: 0.00
      };

      anime({
        targets: estatististasContador,
        round: 100,
        easing: 'easeOutExpo',
        duration: 3000,
        qtRodadasCaptacao: estatisticas.qtRodadasCaptacao,
        vrTotalInvestido: estatisticas.vrTotalInvestido,
        qtUsuarios: estatisticas.qtUsuarios,
        qtInvestidoresAtivos: estatisticas.qtInvestidoresAtivos,
        update: function () {
          if (rodadasCaptacaoRef.current != null) {
            rodadasCaptacaoRef.current.innerHTML = estatististasContador.qtRodadasCaptacao.toFixed(0);
          }
          if (vrTotalInvestidosRef.current != null) {
            vrTotalInvestidosRef.current.innerHTML = `R$ ${formatMoeda(estatististasContador.vrTotalInvestido)}`;
          }
          if (usuariosCadastradosRef.current != null) {
            usuariosCadastradosRef.current.innerHTML = estatististasContador.qtUsuarios.toFixed(0);
          }
          if (investidoresAtivosRef.current != null) {
            investidoresAtivosRef.current.innerHTML = estatististasContador.qtInvestidoresAtivos.toFixed(0);
          }
        },
      });
    }

  }

  useEffect(handleAnimation, [estatisticas]);

  const handleVisible = (isVisible: boolean) => {
    if(isVisible && !triggerAnimation.current){
      triggerAnimation.current = true;
      handleAnimation();
    }
  }

  return (
    <section className={classes.root}>
      <Container className={classes.container}>

        <Typography color="inherit" align="center" variant="h2">
          NOSSA COMUNIDADE
        <span className={classes.titleMarkedCenter} />
        </Typography>

        {!estatisticas && <Loading text="Carregando estatísticas" />}

        {estatisticas &&
          
            <Grid container className={classes.estatisticas}>
              <Grid item xs={12} sm={6} md={3}>
                <Grid container direction="column" alignItems="center" justify="center" style={{ marginTop: '4em' }}>
                  <Grid item>
                    <VisibilitySensor onChange={handleVisible}>
                      <Typography variant="h1" color="inherit" component="p" ref={rodadasCaptacaoRef}>{estatisticas.qtRodadasCaptacao}</Typography>
                    </VisibilitySensor>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption" color="inherit" component="p">
                      <FormattedPlural value={estatisticas.qtRodadasCaptacao} one="Rodada de Captação" other="Rodadas de Captação"/>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Grid container direction="column" alignItems="center" justify="center" style={{ marginTop: '4em' }}>
                  <Typography variant="h1" color="inherit" component="p" ref={investidoresAtivosRef}>
                    <FormattedNumber value={estatisticas.qtInvestidoresAtivos}/> 
                  </Typography>
                  <Typography variant="caption" color="inherit" component="p">
                    <FormattedPlural value={estatisticas.qtInvestidoresAtivos} one="Investidor Ativo" other="Investidores Ativos" />
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Grid container direction="column" alignItems="center" justify="center" style={{ marginTop: '4em' }}>
                  <Typography variant="h1" color="inherit" component="p" ref={vrTotalInvestidosRef}>R$ {formatMoeda(estatisticas.vrTotalInvestido)}</Typography>
                  <Typography variant="caption" color="inherit" component="p">Investidos</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Grid container direction="column" alignItems="center" justify="center" style={{ marginTop: '4em' }}>
                  <Typography variant="h1" color="inherit" component="p" ref={usuariosCadastradosRef}>{estatisticas.qtUsuarios}</Typography>
                  <Typography variant="caption" color="inherit" component="p">Usuários Cadastrados</Typography>
                </Grid>
              </Grid>
            </Grid>
          
        }
      </Container>
    </section>

  );
}

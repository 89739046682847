import {createAsyncThunk, createEntityAdapter, createSlice, EntityState} from "@reduxjs/toolkit";
import moment from "moment";
import { fromEvent, throwError } from "rxjs";
import { catchError, map, takeUntil, timeout } from "rxjs/operators";
import { MotivoRemocaoForum } from "../../../models/enums/MotivoRemocaoForum";
import { PerguntaForumProjetoVO } from "../../../models/PerguntaForumProjeto";
import { api } from "../../../services/api/api";
import { APIError } from "../../../services/api/APIError";
import { APIUtils } from "../../../services/api/APIUtils";
import { RootState } from "../../configureStore";
import { RequestState, shouldFetch } from "../../RequestsState";
import { selectToken } from "../userSlice";

interface PerguntaForumProjetoSliceState {
  requests: EntityState<RequestState<any>>;
}

const requestsAdapter = createEntityAdapter<RequestState<any>>({
  selectId: request => request.id
});

const initialState = {
  requests: requestsAdapter.getInitialState()
} as PerguntaForumProjetoSliceState


// REQUESTS

const REQ_CREATE_PERGUNTA = "createPergunta";
const REQ_REMOVE_PERGUNTA = "removePergunta";
const REQ_RESPONDE_PERGUNTA = "respondePergunta";

const requestSelectors = requestsAdapter.getSelectors((state: RootState) => state.entities.perguntasForumProjeto.requests)

const trackCreatePergunta = (nuProjeto: number) => (state: RootState): RequestState<PerguntaForumProjetoVO> => {
  return requestSelectors.selectById(state, `${REQ_CREATE_PERGUNTA}:${nuProjeto}`) ?? { id: `${REQ_CREATE_PERGUNTA}:${nuProjeto}`, pending: false }
}

const trackRemovePergunta = (coPerguntaForumProjeto: number) => (state: RootState): RequestState<PerguntaForumProjetoVO> => {
  return requestSelectors.selectById(state, `${REQ_REMOVE_PERGUNTA}:${coPerguntaForumProjeto}`) ?? { id: `${REQ_REMOVE_PERGUNTA}:${coPerguntaForumProjeto}`, pending: false }
}

const trackRespondePergunta = (coPerguntaForumProjeto: number) => (state: RootState): RequestState<PerguntaForumProjetoVO> => {
  return requestSelectors.selectById(state, `${REQ_RESPONDE_PERGUNTA}:${coPerguntaForumProjeto}`) ?? { id: `${REQ_RESPONDE_PERGUNTA}:${coPerguntaForumProjeto}`, pending: false }
}

// THUNKS
const createPerguntaForumProjeto = createAsyncThunk<PerguntaForumProjetoVO, { nuProjeto: number; dePergunta: string }, { state: RootState; rejectValue: APIError }>
  ('perguntaForumProjeto/createPergunta',
    async ({ nuProjeto, dePergunta }, { signal, rejectWithValue, getState }) => {
      const token = selectToken(getState()) as string;
      return api.projetos.createPerguntaForumProjeto(nuProjeto, dePergunta, token).pipe(
        timeout(TRANSACTION_TIMEOUT),
        takeUntil(fromEvent(signal, 'abort')),
        map(result => result.data),
        catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
      ).toPromise().catch(err => rejectWithValue(err))
    }, { condition: ({ nuProjeto }, { getState }) => shouldFetch(trackCreatePergunta(nuProjeto)(getState())) }
  )

const removePerguntaForumProjeto = createAsyncThunk<void, { nuProjeto: number; coPerguntaForumProjeto: number; motivoRemocaoForum: MotivoRemocaoForum }, { state: RootState; rejectValue: APIError }>
  ('perguntaForumProjeto/removePergunta',
    async ({ nuProjeto, coPerguntaForumProjeto, motivoRemocaoForum, }, { signal, rejectWithValue, getState }) => {
      const token = selectToken(getState()) as string;
      return api.projetos.removePerguntaForumProjeto(nuProjeto, coPerguntaForumProjeto, motivoRemocaoForum, token).pipe(
        timeout(TRANSACTION_TIMEOUT),
        takeUntil(fromEvent(signal, 'abort')),
        map(result => { console.log(result.data); return result.data }),
        catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
      ).toPromise().catch(err => rejectWithValue(err))
    }, { condition: ({ coPerguntaForumProjeto }, { getState }) => shouldFetch(trackRemovePergunta(coPerguntaForumProjeto)(getState())) }
  )


const respondePerguntaForumProjeto = createAsyncThunk<PerguntaForumProjetoVO, { nuProjeto: number; coPerguntaForumProjeto: number; deResposta: string }, { state: RootState; rejectValue: APIError }>
  ('perguntaForumProjeto/respondePergunta',
    async ({ nuProjeto, coPerguntaForumProjeto, deResposta }, { signal, rejectWithValue, getState }) => {
      const token = selectToken(getState()) as string;
      return api.projetos.responderPerguntaForumProjeto(nuProjeto, coPerguntaForumProjeto, deResposta, token).pipe(
        timeout(TRANSACTION_TIMEOUT),
        takeUntil(fromEvent(signal, 'abort')),
        map(result => result.data),
        catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
      ).toPromise().catch(err => rejectWithValue(err))
    }, { condition: ({ coPerguntaForumProjeto }, { getState }) => shouldFetch(trackRespondePergunta(coPerguntaForumProjeto)(getState())) }
  )


// SLICE
const perguntaForumProjetoSlice = createSlice({
  name: 'projetos',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {

    //
    builder.addCase(createPerguntaForumProjeto.pending, (state, { meta: { arg: { nuProjeto } } }) => {
      requestsAdapter.upsertOne(state.requests, { id: `${REQ_CREATE_PERGUNTA}:${nuProjeto}`, pending: true, error: undefined, data: undefined });
    })
    builder.addCase(createPerguntaForumProjeto.fulfilled, (state, { meta: { arg: { nuProjeto } }, payload: pergunta }) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_CREATE_PERGUNTA}:${nuProjeto}`,
        changes: { pending: false, timestamp: moment().toISOString(), data: pergunta }
      });
    })
    builder.addCase(createPerguntaForumProjeto.rejected, (state, action) => {
      const { nuProjeto } = action.meta.arg
      const error = action.payload as APIError || { ...action.error };
      requestsAdapter.updateOne(state.requests, { id: `${REQ_CREATE_PERGUNTA}:${nuProjeto}`, changes: { pending: false, error } });
    })


    //
    builder.addCase(removePerguntaForumProjeto.pending, (state, { meta: { arg: { coPerguntaForumProjeto } } }) => {
      requestsAdapter.upsertOne(state.requests, { id: `${REQ_REMOVE_PERGUNTA}:${coPerguntaForumProjeto}`, pending: true, error: undefined, data: undefined });
    })
    builder.addCase(removePerguntaForumProjeto.fulfilled, (state, { payload: projetos, meta: { arg: { coPerguntaForumProjeto } } }) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_REMOVE_PERGUNTA}:${coPerguntaForumProjeto}`,
        changes: { pending: false, timestamp: moment().toISOString(), data: projetos }
      });
    })
    builder.addCase(removePerguntaForumProjeto.rejected, (state, action) => {
      const { coPerguntaForumProjeto } = action.meta.arg;
      const error = action.payload as APIError || { ...action.error };
      requestsAdapter.updateOne(state.requests, { id: `${REQ_REMOVE_PERGUNTA}:${coPerguntaForumProjeto}`, changes: { pending: false, error } });
    })

    //
    builder.addCase(respondePerguntaForumProjeto.pending, (state, { meta: { arg: { coPerguntaForumProjeto } } }) => {
      requestsAdapter.upsertOne(state.requests, { id: `${REQ_RESPONDE_PERGUNTA}:${coPerguntaForumProjeto}`, pending: true, error: undefined, data: undefined });
    })
    builder.addCase(respondePerguntaForumProjeto.fulfilled, (state, { meta: { arg: { coPerguntaForumProjeto } } }) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_RESPONDE_PERGUNTA}:${coPerguntaForumProjeto}`,
        changes: { pending: false, timestamp: moment().toISOString() }
      });
    })
    builder.addCase(respondePerguntaForumProjeto.rejected, (state, action) => {
      const { coPerguntaForumProjeto } = action.meta.arg;
      const error = action.payload as APIError || { ...action.error };
      requestsAdapter.updateOne(state.requests, { id: `${REQ_RESPONDE_PERGUNTA}:${coPerguntaForumProjeto}`, changes: { pending: false, error } });
    })
  }
})

export {
  createPerguntaForumProjeto,
  respondePerguntaForumProjeto,
  removePerguntaForumProjeto,
  trackCreatePergunta,
  trackRemovePergunta,
  trackRespondePergunta
};

export default perguntaForumProjetoSlice.reducer
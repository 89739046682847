import {createAsyncThunk, createSlice, EntityState, createEntityAdapter} from "@reduxjs/toolkit";
import moment from "moment";
import {fromEvent, throwError} from "rxjs";
import {catchError, map, takeUntil, timeout} from "rxjs/operators";
import {TaxaJurosVO} from "../../../models/TaxaJuros";
import {api} from "../../../services/api/api";
import {APIError} from "../../../services/api/APIError";
import {APIUtils} from "../../../services/api/APIUtils";
import {RootState} from "../../configureStore";
import {RequestState, shouldFetch} from "../../RequestsState";
import {selectToken} from "../userSlice";

interface TaxaJurosSliceState {
  requests: EntityState<RequestState<any>>;
  taxasJuros: EntityState<TaxaJurosVO>;
}

const taxasJurosAdapter = createEntityAdapter<TaxaJurosVO>({
  selectId: taxa => taxa.coTaxaJuros,
  sortComparer: (a, b) => moment(b.dtInicio).valueOf() - moment(a.dtInicio).valueOf()
})

const requestsAdapter = createEntityAdapter<RequestState<any>>({
  selectId: request => request.id
});


const initialState: TaxaJurosSliceState = {
  requests: requestsAdapter.getInitialState(),
  taxasJuros: taxasJurosAdapter.getInitialState()
}


// REQUESTS

const REQ_FETCH_TAXAS_JUROS = "fetchTaxasJuros";
const REQ_CREATE_TAXA_JUROS = "createTaxaJuros";
const REQ_PATCH_TAXA_JUROS = "patchTaxaJuros";

const requestSelectors = requestsAdapter.getSelectors((state: RootState) => state.entities.taxaJuros.requests)

const taxaJurosSelectors = taxasJurosAdapter.getSelectors((state: RootState) => state.entities.taxaJuros.taxasJuros)

const trackFetchTaxasJuros = (state: RootState): RequestState<TaxaJurosVO[]> => {
  return requestSelectors.selectById(state, REQ_FETCH_TAXAS_JUROS) ?? {id: REQ_FETCH_TAXAS_JUROS, pending: false}
}

const trackCreateTaxaJuros = (state: RootState): RequestState<TaxaJurosVO> => {
  return requestSelectors.selectById(state, REQ_CREATE_TAXA_JUROS) ?? {id: REQ_CREATE_TAXA_JUROS, pending: false}
}

const trackPatchTaxaJuros = (id: number) => (state: RootState): RequestState<TaxaJurosVO> => {
  return requestSelectors.selectById(state, `${REQ_PATCH_TAXA_JUROS}:${id}`) ?? {
    id: `${REQ_PATCH_TAXA_JUROS}:${id}`,
    pending: false
  }
}


// THUNKS
const fetchTaxasJurosList = createAsyncThunk<TaxaJurosVO[], void, { state: RootState; rejectValue: APIError }>
('taxaJuros/fetchTaxasJurosList',
  async (args, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.taxaJuros.fetchTaxasJuros(token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: (args, {getState}) => shouldFetch(trackFetchTaxasJuros(getState()), moment.duration(2, "minutes"))}
)

const createTaxaJuros = createAsyncThunk<TaxaJurosVO, { taxaJuros: Partial<TaxaJurosVO> }, { state: RootState; rejectValue: APIError }>
('taxaJuros/createTaxaJuros',
  async ({taxaJuros}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.taxaJuros.createTaxaJuros(taxaJuros, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: (args, {getState}) => shouldFetch(trackCreateTaxaJuros(getState()))}
)

const patchTaxaJuros = createAsyncThunk<void, { id: number; changes: Partial<TaxaJurosVO> }, { state: RootState; rejectValue: APIError }>
('taxaJuros/patchTaxaJuros',
  async ({id, changes}, {signal, rejectWithValue, getState}) => {
    const token = selectToken(getState()) as string;
    return api.taxaJuros.patchTaxaJuros(id, changes, token).pipe(
      timeout(TRANSACTION_TIMEOUT),
      takeUntil(fromEvent(signal, 'abort')),
      map(result => result.data),
      catchError(err => throwError(APIUtils.axiosErrorToAPIError(err)))
    ).toPromise().catch(err => rejectWithValue(err))
  }, {condition: ({id}, {getState}) => shouldFetch(trackPatchTaxaJuros(id)(getState()))}
)


// SLICE
const taxaJurosSlice = createSlice({
  name: 'taxaJuros',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    //
    builder.addCase(fetchTaxasJurosList.pending, (state) => {
      requestsAdapter.upsertOne(state.requests, {
        id: REQ_FETCH_TAXAS_JUROS,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(fetchTaxasJurosList.fulfilled, (state, {payload: taxasJuros}) => {
      requestsAdapter.updateOne(state.requests, {
        id: REQ_FETCH_TAXAS_JUROS,
        changes: {pending: false, timestamp: moment().toISOString(), data: taxasJuros}
      });
      taxasJurosAdapter.setAll(state.taxasJuros, taxasJuros);
    })
    builder.addCase(fetchTaxasJurosList.rejected, (state, action) => {
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {id: REQ_FETCH_TAXAS_JUROS, changes: {pending: false, error}});
    })

    //
    builder.addCase(createTaxaJuros.pending, (state) => {
      requestsAdapter.upsertOne(state.requests, {
        id: REQ_CREATE_TAXA_JUROS,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(createTaxaJuros.fulfilled, (state, {payload: taxaJuros}) => {
      requestsAdapter.updateOne(state.requests, {
        id: REQ_CREATE_TAXA_JUROS,
        changes: {pending: false, timestamp: moment().toISOString(), data: taxaJuros}
      });
      taxasJurosAdapter.addOne(state.taxasJuros, taxaJuros)
    })
    builder.addCase(createTaxaJuros.rejected, (state, action) => {
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {id: REQ_CREATE_TAXA_JUROS, changes: {pending: false, error}});
    })


    //
    builder.addCase(patchTaxaJuros.pending, (state, {meta: {arg: {id}}}) => {
      requestsAdapter.upsertOne(state.requests, {
        id: `${REQ_PATCH_TAXA_JUROS}:${id}`,
        pending: true,
        error: undefined,
        data: undefined
      });
    })
    builder.addCase(patchTaxaJuros.fulfilled, (state, {meta: {arg: {id, changes}}}) => {
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_PATCH_TAXA_JUROS}:${id}`,
        changes: {pending: false, timestamp: moment().toISOString()}
      });
      taxasJurosAdapter.updateOne(state.taxasJuros, {id, changes})
    })
    builder.addCase(patchTaxaJuros.rejected, (state, action) => {
      const {id} = action.meta.arg;
      const error = action.payload as APIError || {...action.error};
      requestsAdapter.updateOne(state.requests, {
        id: `${REQ_PATCH_TAXA_JUROS}:${id}`,
        changes: {pending: false, error}
      });
    })


  }
})

const {
  selectById: selectTaxaJurosById,
  selectAll: selectTaxaJuros
} = taxaJurosSelectors;

export {
  fetchTaxasJurosList,
  createTaxaJuros,
  patchTaxaJuros,
  trackFetchTaxasJuros,
  selectTaxaJurosById,
  selectTaxaJuros
};

export default taxaJurosSlice.reducer
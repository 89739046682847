import React from 'react'
import { Grid, Avatar, makeStyles, createStyles, Button, Typography, Theme } from '@material-ui/core'
import CropRotateIcon from '@material-ui/icons/CropRotate';
import UploadIcon from 'mdi-material-ui/Upload'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { ButtonDanger } from '../../../../buttons/ButtonDanger';

const useStyles = makeStyles((theme: Theme) => createStyles({
  pictureContainer: {
    marginBottom: theme.spacing(1)
  },
  picture: {
    width: 200,
  },
  avatar: {
    height: 200,
    width: 200,
  },
}), { name: 'FormPhotoUploadPreview' });

interface FormPhotoUploadPreviewProps {
  fileURL: string;
  helperText?: string;
  error?: string;
  showPhotoEditButton?: boolean;
  showPhotoRemoveButton?: boolean;
  variant: "avatar" | "picture";
  onFileEditClick: () => void;
  onFileChangeClick: () => void;
  onFileRemoveClick: () => void;
}

export const FormPhotoUploadPreview = ({
  fileURL,
  helperText,
  error,
  variant,
  onFileEditClick: handleStartEditClick,
  onFileChangeClick: handleFileChangeClick,
  onFileRemoveClick: handleFileRemoveClick,
  showPhotoEditButton,
  showPhotoRemoveButton,
}: FormPhotoUploadPreviewProps) => {

  const classes = useStyles();

  return (
    <Grid container alignItems="center" justify="center" direction="column" spacing={1}>
      <Grid container item alignItems="center" justify="center" className={classes.pictureContainer}>
        {variant == "avatar" &&
          <Avatar src={fileURL} className={classes.avatar} />
        }
        {variant == "picture" &&
          <img src={fileURL} className={classes.picture} />
        }
      </Grid>
      <Grid item>
        <Grid container direction="row" spacing={2} justify="center">
          {showPhotoRemoveButton &&
            <Grid item>
              <ButtonDanger variant="outlined" onClick={handleFileRemoveClick} startIcon={<DeleteOutlineIcon />}>Remover</ButtonDanger>
            </Grid>
          }
          <Grid item>
            <Button color="primary" variant="outlined" onClick={handleFileChangeClick} startIcon={<UploadIcon />}>Trocar</Button>
          </Grid>
          {showPhotoEditButton &&
            <Grid item>
              <Button color="default" variant="outlined" onClick={handleStartEditClick} startIcon={<CropRotateIcon />}>Editar</Button>
            </Grid>
          }
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="caption" color={error ? "error" : "textSecondary"}>{error || helperText}</Typography>
      </Grid>
    </Grid>
  )
}

import { Box, Button, Card, CardContent, CardHeader, createStyles, Divider, Grid, Hidden, Link, makeStyles, Theme, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import { AppDispatch } from '../../../../../../../../browser';
import { NivelInvestidor } from '../../../../../../../models/enums/NivelInvestidor';
import { TipoRentabilidadeGarantida } from '../../../../../../../models/enums/TipoRentabilidadeGarantida';
import { TipoRentabilidadeProjetada } from '../../../../../../../models/enums/TipoRentabilidadeProjetada';
import { InvestidorVO } from '../../../../../../../models/Investidor';
import { APIUtils } from '../../../../../../../services/api/APIUtils';
import { fetchDetalhesCaptacao, trackFetchDetalhesCaptacao } from '../../../../../../../stores/slices/entities/captacoesSlice';
import { sumTotalInvestimentosAno, trackSumTotalInvestimentosAno, createReserva } from '../../../../../../../stores/slices/entities/investidoresSlice';
import { selectLogin } from '../../../../../../../stores/slices/userSlice';
import { LoginUsuario } from '../../../../../../../utils/login';
import DownloadBox from '../../../../../../components/DownloadBox';
import ErrorEmptyState from '../../../../../../components/empty/ErrorEmptyState';
import { FormCheckBoxInput } from '../../../../../../components/form/inputs/FormCheckBoxInput';
import FormInput from '../../../../../../components/FormInput';
import Loading from '../../../../../../components/Loading';
import { OnlyNumbersMaskedInput } from '../../../../../../components/MaskedInputs';
import { LoadingText } from '../../../../../../components/progress/LoadingText';
import { useDialog } from '../../../../../../components/dialog/PageServiceProvider';
import { InvestimentoVO } from '../../../../../../../models/Investimento';
import { DeepPartial } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import { APIError } from '../../../../../../../services/api/APIError';
import { generateErrorDialog } from '../../../../../../../services/api/APIErrorUtils';
import ButtonProgress from '../../../../../../components/ButtonProgress';

const useStyles = makeStyles((theme: Theme) => createStyles({
  labelText: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
    lineHeight: 1,
    letterSpacing: '0.00938em'
  },
  labelValue: {
    marginTop: theme.spacing(1)
  },
  observacoes: {
    color: 'rgba(0,0,0,.5)',
    textAlign: 'justify'
  },

}), { name: 'EfetuarReserva' });


interface EfetuarReservaFormData {
  condicoesAccept: boolean;
  contratoAccept: boolean;
  qtCota: string;
  limiteAccept: boolean;
  depositoAccept: boolean;
}

interface EfetuarReservaFormProps {
  investidor: InvestidorVO;
}

export const EfetuarReservaForm = ({ investidor }: EfetuarReservaFormProps) => {

  const classes = useStyles();

  const { register, errors, watch, handleSubmit, formState: { isSubmitting } } = useForm<EfetuarReservaFormData>({
    mode: 'onBlur',
    defaultValues: {
      condicoesAccept: false,
      contratoAccept: false,
      depositoAccept: false,
      limiteAccept: false,
      qtCota: '1'
    }
  })

  const qtCota = watch({ nest: true }).qtCota;

  const dispatch = useDispatch<AppDispatch>();

  const dialog = useDialog();

  const history = useHistory();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const intl = useIntl();

  const { permalink } = useParams<{ permalink: string }>();

  const login = useSelector(selectLogin) as LoginUsuario;

  const { data: totalInvestimentos, pending: isLoadingTotalInvestimentos, error: errorLoadingTotalInvestimentos } = useSelector(trackSumTotalInvestimentosAno(login.id));
  const { data: projeto, pending: isLoadingProjeto, error: errorLoadingProjeto } = useSelector(trackFetchDetalhesCaptacao(permalink));

  useEffect(() => {
    const promiseSum = dispatch(sumTotalInvestimentosAno({ coContaUsuario: login.id }));
    const promiseCaptacao = dispatch(fetchDetalhesCaptacao({ permalink }))
    return () => {
      promiseSum.abort();
      promiseCaptacao.abort();
    }
  }, [dispatch, login.id, permalink]);

  const handleEfetuarReserva = async (formData: EfetuarReservaFormData) => {
    const confirmed = await dialog({
      title: `Confirma reserva no valor de ${intl.formatNumber((projeto?.vrCotaEmitida || 0) * Number(formData.qtCota), { style: "currency", currency: "BRL" })} ?`,
      description: "Ao confirmar sua reserva será efetuada. Se cancelar, nenhuma ação será efetuada.",
      confirmOption: "CONFIRMAR",
      cancelOption: "CANCELAR"
    });
    if (confirmed) {
      const reserva: DeepPartial<InvestimentoVO> = {
        projeto: { nuProjeto: projeto?.nuProjeto },
        contaUsuario: { coContaUsuario: investidor.coContaUsuario },
        qtCota: Number(formData.qtCota),
        vrInvestimento: Number(formData.qtCota) * (projeto?.vrCotaEmitida || 0)
      }
      const resultAction = await dispatch(createReserva({ coContaUsuario: investidor.coContaUsuario, reserva }))
      if (createReserva.fulfilled.match(resultAction)) {
        enqueueSnackbar('Reserva efetuada com sucesso!', {
          variant: 'success',
          action: <Button onClick={() => closeSnackbar()}>OK</Button>
        });
        history.push('/investir');
      } else {
        const error = resultAction.payload || resultAction.error as APIError;
        dialog(generateErrorDialog(error, "Não foi possível concluir a reserva. Tente novamente."))
      }
    }
  }

  return (
    <form id="formEfetuarReserva" noValidate autoComplete="off" onSubmit={handleSubmit(handleEfetuarReserva)}>
      {isLoadingProjeto &&
        <Loading text="Carregando captação ..." />
      }
      {errorLoadingProjeto &&
        <ErrorEmptyState
          error={errorLoadingProjeto}
          defaultDescription="Erro ao carregar captação"
          onTryAgainClick={() => fetchDetalhesCaptacao({ permalink })}
        />
      }
      {projeto &&
        <>
          <Grid container spacing={3}>

            <Grid item xs={12}>
              <Card>

                <CardHeader title="Condições de Investimento" />
                <Divider />

                <CardContent>
                  <Grid container spacing={3} >

                    <Grid item xs={12} sm={6} className={classes.observacoes}>
                      <Typography variant="caption" >
                        <p>
                          Projeto: {projeto.empresa.noFantasia} - {projeto.noProjeto}
                        </p>
                        <ul>
                          <li>Rendimento: {projeto.tipoRentabilidadeProjetada == TipoRentabilidadeProjetada.Fixa ? `${intl.formatNumber(projeto.pcRentabilidadeProjetadaFixaAnual || 0, { style: "percent", minimumFractionDigits: 2 })} a.a.` : `${intl.formatNumber(projeto.pcRentabilidadeProjetadaMinimaAnual || 0, { style: "percent", minimumFractionDigits: 2 })} a ${intl.formatNumber(projeto.pcRentabilidadeProjetadaMaximaAnual || 0, { style: "percent", minimumFractionDigits: 2 })} a.a.`}</li>
                          <li>Prazo: {projeto.qtPrazoMesesTitulo} meses.</li>
                          {projeto.tipoRentabilidadeGarantida == TipoRentabilidadeGarantida["% do CDI Pré-fixado"] &&
                            <li>Rentabilidade Garantida: {intl.formatNumber(projeto.pcRentabilidadeGarantidaFixaAnual || 0, { style: "percent", minimumFractionDigits: 2 })} a.a. ({intl.formatNumber(projeto.pcRentabilidadeGarantidaCdi || 0, { style: "percent", minimumFractionDigits: 2 })} do CDI)</li>
                          }
                        </ul>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DownloadBox name="Prospecto da oferta" url={APIUtils.generateDocumentoURL(projeto.documentoProspecto)} icon="pdf" />
                    </Grid>

                    <Grid item xs={12} >
                      <FormCheckBoxInput
                        name="condicoesAccept"
                        required
                        label="Confirmo que estou ciente das condições de investimento"
                        error={errors.condicoesAccept?.message?.toString()}
                        inputRef={register({
                          required: 'Você precisa confirmar para que a reserva seja aceita'
                        })}
                      />
                    </Grid>


                  </Grid>
                </CardContent>


              </Card>

            </Grid>

            <Grid item xs={12}>
              <Card>

                <CardHeader title="Contrato de Investimento" />
                <Divider />

                <CardContent>
                  <Grid container spacing={3} >

                    <Grid item xs={12} sm={6} className={classes.observacoes}>
                      <Typography variant="caption" >

                        <p>
                          Solicitamos que leia o modelo de contrato antes de confirmar a reserva para estar ciente de todos os seus direitos e obrigações.
                                             </p>
                        <p>
                          OBS: O seu contrato de investimento individual será gerado somente se houver o sucesso da captação e a confirmação do depósito. O contrato individual estará disponível para consulta no <Link component={RouterLink} to="/conta/painel">Painel de Investimentos</Link>.
                                             </p>

                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DownloadBox name="Modelo do Contrato de Investimento" url={APIUtils.generateDocumentoURL(projeto.documentoModeloContrato)} icon="pdf" />
                    </Grid>

                    <Grid item xs={12} >

                      <FormCheckBoxInput
                        name="contratoAccept"
                        required
                        label="Confirmo que li e estou de acordo com o contrato de investimento"
                        error={errors.contratoAccept?.message?.toString()}
                        inputRef={register({
                          required: 'Você precisa confirmar para que a reserva seja aceita'
                        })}
                      />
                    </Grid>

                  </Grid>
                </CardContent>


              </Card>

            </Grid>

            <Grid item xs={12}>
              <Card>

                <CardHeader title="Quanto deseja investir?" />
                <Divider />

                <CardContent>
                  <Grid container spacing={3} >

                    <Grid item xs={4} sm={3}>

                      <FormInput
                        name="qtCota"
                        required
                        label="Quantidade de Cotas"
                        placeholder="00000"
                        maxLength={5}
                        inputComponent={OnlyNumbersMaskedInput as any}
                        error={errors.qtCota?.message?.toString()}
                        inputRef={register({
                          required: "Informe a quantidade de cotas",
                          pattern: { value: /\d{1,5}/, message: "Somente números" },
                          validate: {
                            min: value => Number(value) >= 1 || 'No mínimo 1 cota',
                          }
                        })}
                      />

                    </Grid>

                    <Grid item xs={2} sm={1}>
                      <Grid container direction="column">
                        <Box mt={1.5} />
                        <Typography variant="body1" color="inherit" component="p" className={classes.labelValue}>X</Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={6} sm={3}>
                      <Grid container direction="column">
                        <Typography variant="caption" color="inherit" component="p" className={classes.labelText}>Valor Unitário</Typography>
                        <Typography variant="body1" color="inherit" component="p" className={classes.labelValue}>{intl.formatNumber(projeto.vrCotaEmitida, { style: "currency", currency: "BRL" })}</Typography>
                      </Grid>
                    </Grid>

                    <Hidden smUp>
                      <Grid item xs={4}>
                        <Box />
                      </Grid>
                    </Hidden>


                    <Grid item xs={2} sm={1}>
                      <Grid container direction="column">
                        <Box mt={1.5} />
                        <Typography variant="body1" color="inherit" component="p" className={classes.labelValue}>=</Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={6} sm={4}>
                      <Grid container direction="column">
                        <Typography variant="caption" color="inherit" component="p" className={classes.labelText}>Total Reservado</Typography>
                        <Typography variant="body1" color="inherit" component="p" className={classes.labelValue}>{intl.formatNumber(qtCota ? (Number(qtCota) * projeto.vrCotaEmitida) : 0, { style: "currency", currency: "BRL" })}</Typography>
                      </Grid>
                    </Grid>



                    <Grid item xs={12} className={classes.observacoes}>
                      <Typography variant="caption" >
                        <p>
                          OBS: Seu limite é baseado no seu nível como investidor.
                                             </p>
                        <p>
                          A CMV determina os seguintes limites de investimentos por ano conforme as seguintes categorias:
                                             </p>
                        <ul>
                          <li>Comum: Até R$ 10.000,00 por ano.</li>
                          <li>Intermediário: Até 10% da renda/patrimônio.</li>
                          <li>Qualificado: Ilimitado</li>
                        </ul>
                        <p>
                          O seu nível atual é: <b>{NivelInvestidor[investidor.icNivelInvestidor]}</b> <Link component={RouterLink} to="/login/perfil">(Alterar no Perfil)</Link>
                        </p>
                        <p>
                          Total investido no ano:&nbsp;
                          {isLoadingTotalInvestimentos &&
                            <LoadingText text="Carregando" />
                          }
                          {errorLoadingTotalInvestimentos &&
                            <Link variant="caption" component="button" onClick={() => dispatch(sumTotalInvestimentosAno({ coContaUsuario: login.id }))}>Erro ao Carregar. Tentar Novamente</Link>
                          }
                          {totalInvestimentos != undefined &&
                            <>
                              <b>{intl.formatNumber(totalInvestimentos, { style: "currency", currency: "BRL" })}</b>
                              &nbsp;<Link component={RouterLink} to="/conta/painel">(Visualizar Painel de Investimentos)</Link>
                            </>
                          }
                        </p>
                      </Typography>
                    </Grid>

                    <Grid item xs={12} >

                      <FormCheckBoxInput
                        name="limiteAccept"
                        required
                        label="Confirmo que o valor solicitado não excede o meu limite anual determinado pela CVM"
                        error={errors.limiteAccept?.message?.toString()}
                        inputRef={register({
                          required: 'Você precisa confirmar para que a reserva seja aceita'
                        })}
                      />
                    </Grid>

                  </Grid>
                </CardContent>


              </Card>

            </Grid>
            <Grid item xs={12}>
              <Card>

                <CardHeader title="Compromisso de Depósito" />
                <Divider />

                <CardContent>
                  <Grid container spacing={3} >

                    <Grid item xs={12} sm={6} className={classes.observacoes}>
                      <Typography variant="caption" >
                        <p>
                          Durante a fase de reservas o Gottaup irá contabilizar a sua solicitação de reserva de cotas mas não irá exigir depósito antecipado.
                        </p>
                        <p>
                          Caso o valor alvo mínimo da captação de {intl.formatNumber(projeto.vrAlvoCaptacao, { style: "currency", currency: "BRL" })} não seja atingido até o prazo de {moment(projeto.dtFimCaptacao).format("L")}, sua reserva será automaticamente cancelada, sem nenhum ônus para todas as partes.
                        </p>
                        <p>
                          Entretanto, caso o valor alvo mínimo seja atingido, você possui o compromisso de depositar o valor solicitado após o encerramento da captação conforme o cronograma.
                        </p>

                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.observacoes}>
                      <Typography variant="caption" >
                        <p>
                          Cronograma
                        </p>
                        <ul>
                          <li>Encerramento da Captação: {moment(projeto.dtFimCaptacao).format("L")}</li>
                          <li>Início do Prazo de Depósito de Reservas: {moment(projeto.dtInicioDepositos).format("L")}</li>
                          <li>Fim do Prazo de Depósito de Reservas: {moment(projeto.dtFimDepositos).format("L")}</li>
                        </ul>


                      </Typography>
                    </Grid>

                    <Grid item xs={12} >

                      <FormCheckBoxInput
                        name="depositoAccept"
                        required
                        label={`Confirmo o compromisso de depositar ${intl.formatNumber(qtCota ? (Number(qtCota) * projeto.vrCotaEmitida) : 0, { style: "currency", currency: "BRL" })} entre ${moment(projeto.dtInicioDepositos).format("L")} e ${moment(projeto.dtFimDepositos).format("L")}, caso a captação atinja o valor mínimo de ${intl.formatNumber(projeto.vrAlvoCaptacao, { style: "currency", currency: "BRL" })} até ${moment(projeto.dtFimCaptacao).format("L")}.`}
                        error={errors.depositoAccept?.message?.toString()}
                        inputRef={register({
                          required: 'Você precisa confirmar para que a reserva seja aceita'
                        })}
                      />
                    </Grid>

                  </Grid>
                </CardContent>


              </Card>

            </Grid>


          </Grid>
          <Box display="flex" justifyContent="flex-end" mt={2} mb={2}>
            <ButtonProgress loading={isSubmitting} variant="contained" color="primary" type="submit" form="formEfetuarReserva">
              Confirmar Reserva
            </ButtonProgress>
          </Box>
        </>
      }


    </form>
  )
}

import { createStyles, Grid, IconButton, makeStyles, Typography, Button, Theme } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import ViewGridPlusOutlineIcon from 'mdi-material-ui/ViewGridPlusOutline';
import React, { useRef } from 'react';
import ReactImageGallery from 'react-image-gallery';
import UploadIcon from 'mdi-material-ui/Upload';


const useStyles = makeStyles((theme: Theme) => createStyles({
  icon: {
    width: 200,
    height: 200
  },
  deleteButtonImageGallery: {
    color: 'rgba(255, 255, 255)',
    padding: 20,
    position: 'absolute',
    top: 0,
    zIndex: 5,
    right: 0,
    transition: 'all .2s ease-out',
    filter: 'drop-shadow(0 2px 2px #1a1a1a);',
    '@media (min-width: 768px)': {
      '&:hover': {
        color: '#337ab7',
        transform: 'scale(1.1)',
      }
    },
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '@media (max-width: 768px)': {
      padding: 15,
    },
    '@media (max-width: 480px)': {
      padding: 10,
    }
  },
  deleteButtonIconImageGallery: {
    fontSize: 36,
    '@media (max-width: 768px)': {
      fontSize: 24,
    },
    '@media (max-width: 480px)': {
      fontSize: 16,
    }
  },
  imagemContainer: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 500,
    },
    [theme.breakpoints.up('md')]: {
      width: 400,
    },
    [theme.breakpoints.up('lg')]: {
      width: 500,
    },
  },
}), { name: 'FormPhotoGaleryUpload' });

export interface FormPhotoUploadProps {
  photosUrl?: string[];
  onUpload: (files: File[]) => void;
  onDelete: (index: number) => void;
  helperText?: string;
  error?: string;
  accept: string;
}

export default function FormPhotoGaleryUpload({ photosUrl, onDelete, onUpload, helperText, error, accept }: FormPhotoUploadProps) {

  const classes = useStyles();

  // Refs
  const imageGalleryRef = useRef<ReactImageGallery>(null);
  const fileIncludeImagesInputRef = useRef<HTMLInputElement>(null);

  const triggerFileUpload = () => fileIncludeImagesInputRef.current?.click()

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files ? Array.from(event.target.files) : undefined;
    if (files) {
      onUpload(files);
    }

    /*
    const acceptArray = event.target.accept ? event.target.accept.replace(/ /g, '').replace(/\./g, '').split(",") : undefined;
    const allowedExtensions = acceptArray ? new RegExp(acceptArray.join("|") + "$", "i") : undefined;
    if (files) {
      for (const file of files) {
        if (allowedExtensions) {
          if (!allowedExtensions.exec(file.name)) {
            // Imagem inválida
            const dismissButton = (key: string | number | undefined) => <IconButton onClick={() => closeSnackbar(key)}>OK</IconButton>
            enqueueSnackbar('Arquivo não aceito! Selecione um arquivo em um dos seguintes formatos: ' + acceptArray, { variant: 'error', action: dismissButton, autoHideDuration: 5000 });
            return; // Retorna para não processar outras imagens
          }
        }
        // Imagem Aceita
        let imageBase64 = await getBase64(file);
        if (props.resize) {
          imageBase64 = await resizeImageKeepProportions(imageBase64, props.resize.x, props.resize.y);
        }
        const encoded = imageBase64.replace(/^data:(.*,)?/, '');
        const imagem = new Imagem();
        imagem.noImagem = file.name;
        imagem.deImagem = "Foto Galeria Projeto";
        imagem.imImagem = encoded;
        acceptedPhotos.push(imagem);
      }
      // Imagens processadas com sucesso, então atualiza estado.
      state.current.imagensGaleria = state.current.imagensGaleria.concat(acceptedPhotos); // Galeria
      state.current.imagensToInsert = state.current.imagensToInsert.concat(acceptedPhotos); // Marca imagens inseridas
    }*/
  }


  const handleImageDelete = () => {
    const index = imageGalleryRef.current ? imageGalleryRef.current.getCurrentIndex() : 0
    onDelete(index);
    /*
    const imagemToDelete = state.current.imagensGaleria[index]; //Captura objeto da galeria
    state.current.imagensGaleria.splice(index, 1); // Remove da galeria
    if (imagemToDelete.nuImagem) {
      // Imagem pré-existente, então marca para exclusão no servidor
      state.current.imagensToDelete.push(imagemToDelete);
    } else {
      // Imagem tinha adicionado nesta edição, então remove da lista de inclusão
      state.current.imagensToInsert = state.current.imagensToInsert.filter(img => img.imImagem !== imagemToDelete.imImagem);
    }*/
  }

  return (

    <Grid container alignItems="center" justify="center" direction="column" spacing={1}>
      <Grid item xs={12}>
        {photosUrl && photosUrl.length > 0
          ?
          <ReactImageGallery
            items={photosUrl.map((photo) => ({ original: photo, thumbnail: photo }))}
            showPlayButton={false}
            ref={imageGalleryRef}
            additionalClass={classes.imagemContainer}
            lazyLoad={true}
            renderCustomControls={() => {
              return (
                <IconButton className={classes.deleteButtonImageGallery} onClick={handleImageDelete}>
                  <DeleteOutlinedIcon className={classes.deleteButtonIconImageGallery} />
                </IconButton>
              )
            }}
          />
          : <ViewGridPlusOutlineIcon className={classes.icon} color="disabled" />
        }
      </Grid>
      <Grid item>
        <Grid container direction="row" spacing={2}>
          <Grid item>
            <input type="file" ref={fileIncludeImagesInputRef} required hidden multiple accept={accept} onChange={handleImageUpload} />
            <Button color="primary" variant="outlined" onClick={triggerFileUpload} startIcon={<UploadIcon />}>Upload</Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="caption" color={error ? "error" : "textSecondary"}>{error || helperText}</Typography>
      </Grid>
    </Grid>

  )

}
import moment from "moment";
import { api } from "../services/api/api";
import { timeout, map, catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { AxiosError } from "axios";
import { APIUtils } from "../services/api/APIUtils";

const calculateServerOffset = (startTime: number, serverTime: number) => {
  console.log("[ServerDateSync]: Client Start ", moment(startTime).toISOString());
  const clientTimeNow = moment().valueOf();
  console.log("[ServerDateSync]: Client Now ", moment(clientTimeNow).toISOString());
  const delay = clientTimeNow - startTime;
  console.log("[ServerDateSync]: Network Delay ", delay);
  console.log("[ServerDateSync]: Server Time ", moment(serverTime).toISOString());
  const serverClientDiffTime = serverTime - clientTimeNow;
  console.log("[ServerDateSync]: Server Client Diff ", serverClientDiffTime);
  const serverOffset = (serverClientDiffTime + delay / 2);
  console.log("[ServerDateSync]: Offset " + serverOffset);
  console.log("[ServerDateSync]: Server Clock Replica", moment().add(serverOffset,'milliseconds').toISOString());
  console.log(moment().add(serverOffset,'milliseconds').toLocaleString())
  return serverOffset;
}

const setMomentServerOffset = (serverOffset: number) => {
  moment.now = function() {
    return serverOffset + Date.now();
  }
}

const syncronizeClientServerClock = async () => {
  const startTime = moment().valueOf();
  const serverTimeISOString = await api.utils.serverClock().pipe(
    timeout(TRANSACTION_TIMEOUT),
    map(result => result.data),
    catchError((err: AxiosError) => throwError(APIUtils.axiosErrorToAPIError(err)))
  ).toPromise();
  const serverTime = moment(serverTimeISOString).valueOf();
  const serverOffset = calculateServerOffset(startTime,serverTime);
  setMomentServerOffset(serverOffset);
}

export {
  syncronizeClientServerClock
}
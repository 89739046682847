/* eslint-env node */

let serviceURL: string;

export function getServiceURL(){
    if(!serviceURL){
        if(__isBrowser__){
            serviceURL = window.SERVICE_URL;
        } else {
            require('dotenv').config();
            serviceURL = process.env.SERVICE_URL?process.env.SERVICE_URL:'';
        }
    }
    return serviceURL;
}
import { getServiceURL } from "../services/ServiceURL";

export interface DocumentoVO {
  nuDocumento: number | undefined;
  noDocumento: string | undefined;
  deDocumento: string | undefined;
  imDocumento: string | undefined;
}


export default class Documento {

  public nuDocumento: bigint | undefined;
  public noDocumento: string | undefined;
  public deDocumento: string | undefined;
  public imDocumento: string | undefined;

  constructor(obj: object | null = null) {
    if (obj) Object.assign(this, obj);
    Object.seal(this); // Não permite alterar estrutura da classe
  }

  get documentoURL(): string | undefined {
    if (this.nuDocumento) {
      return `${getServiceURL()}/api/documentos/${this.nuDocumento}`;
    }
    if (this.imDocumento && this.noDocumento) {
      return `data:${this.documentMediaType};charset=utf-8;base64, ${this.imDocumento}`;
    }
    return undefined;
  }

  get documentMediaType(): string | undefined {
    if (this.noDocumento) {
      const extensao = this.noDocumento.match(/\.\w+$/i);
      if (extensao) {
        switch (extensao[0].toLowerCase()) {
          case '.png':
            return 'image/png';
          case '.jpg':
            return 'image/jpg';
          case '.gif':
            return 'image/gif';
          case '.pdf':
            return 'application/pdf'
          default:
            return undefined;
        }
      }
    }
    return undefined;
  }

  isImage() {
    const type = this.documentMediaType;
    return (type && type.split('/')[0] === 'image');
  }

  isPdf() {
    return this.documentMediaType === 'application/pdf';
  }


}


import { Grid, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { InputBaseComponentProps } from '@material-ui/core/InputBase';
import useForkRef from '@material-ui/core/utils/useForkRef';
import ErrorIcon from '@material-ui/icons/Error';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React, { KeyboardEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { getInputErrorMessage, ValidationMessages } from '../ViewUtils';

interface Props {
    id?: string;
    name?: string;
    label?: string;
    variant?: "standard"|"filled"|"outlined";
    autoFocus?: boolean;
    multiline?: boolean;
    rows?: number;
    select?: boolean;
    initialValue?: string;
    helperText?: string;
    characterCounter?: boolean;
    onValidInput?: (newValue: string) => void;
    onInvalidInput?: (newValue: string) => void;
    onChangeInput?: (newValue: string) => void;
    validationMessages?: ValidationMessages;
    disabled?: boolean;
    pattern?: string;
    maxLength?: number;
    minLength?: number;
    maxValue?: number;
    minValue?: number;
    formSubmitted?: boolean;
    type?: string;
    placeholder?: string;
    endAdornment?: React.ReactNode;
    startAdornment?: React.ReactNode;
    required?: boolean;
    inputComponent?: React.ElementType<InputBaseComponentProps>;
    children?: React.ReactNode;
    inputRef?: React.Ref<any>;
    submitOnEnter?: boolean;
    shrinkLabel?: boolean;
    inputStyle?: React.CSSProperties;
    labelStyle?: React.CSSProperties;
}


export default function TextInput(props: Props) {

    const [value, setValue] = useState<string>(props.initialValue ? props.initialValue : '');
    const [error, setError] = useState<string>('');
    const [showPassword, setShowPassword] = useState(false); // Para campos password
    const [counter, setCounter] = useState<number>(0);

    const inputRef = useRef<HTMLInputElement>(null);
    const combinedRef = useForkRef(inputRef, props.inputRef || null);

    useEffect(() => {
        // Se o form foi submetido, força a validação se houver.
        if (props.formSubmitted && props.validationMessages && inputRef.current && !inputRef.current.checkValidity()) {
            setError(getInputErrorMessage(inputRef.current, props.validationMessages));
        }
    }, [props.formSubmitted, props.validationMessages]);

    useEffect(() => {
        setValue(props.initialValue || '');
        if (props.characterCounter && props.initialValue) {
            setCounter(props.initialValue.length);
        }
    }, [props.initialValue, props.characterCounter]);

    const handleChange = (e: SyntheticEvent) => {
        const input = e.target as HTMLInputElement;
        //value.current = input.value;
        setValue(input.value);
        if (error && input.checkValidity()) {
            setError(''); // Remove erro
        }
        if (props.characterCounter && input.value.length !== counter) {
            setCounter(input.value.length);
        }
        if (props.onChangeInput) {
            props.onChangeInput(input.value);
        }
    }

    const checkCustomValidity = (input: HTMLInputElement) => {
        const inputNumber = Number(input.value.replace('.', '').replace(',', '.'));
        if (props.minValue && inputNumber < props.minValue) {
            const message = (props.validationMessages && props.validationMessages.rangeUnderflow)?props.validationMessages.rangeUnderflow:`Valor deve ser >= a ${props.minValue}`;
            input.setCustomValidity(message);
        } else if (props.maxValue && inputNumber > props.maxValue) {
            const message = (props.validationMessages && props.validationMessages.rangeOverflow)?props.validationMessages.rangeOverflow:`Valor deve ser <= a ${props.maxValue}`;
            input.setCustomValidity(message);
        } else {
            input.setCustomValidity('');
        }
    }

    const handleBlur = (e: SyntheticEvent) => {
        const input = e.target as HTMLInputElement;
        checkCustomValidity(input);
        if (input.checkValidity()) {
            if (error) {
                setError(''); // Remove erro
            }
            if (props.onValidInput) {
                props.onValidInput(input.value);
            }
        } else {
            if (props.validationMessages) {
                setError(getInputErrorMessage(input, props.validationMessages))// Só inclui erro no blur
            }
            if (props.onInvalidInput) {
                props.onInvalidInput(input.value);
            }
        }

    }

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if(!props.multiline){
                if (props.submitOnEnter) {
                    handleBlur(e); // Processa a alteração do campo como se tivesse saído.
                } else {
                    e.preventDefault(); // Senão não faz a submissão
                }
            }
        }
    }

    const renderEndAdornment = () => {
        if (props.endAdornment) {
            return (
                <InputAdornment position={"end"}>
                    {props.endAdornment}
                </InputAdornment>
            )
        }
        if (props.type && props.type == 'password') {
            return (
                <InputAdornment position="end">
                    <IconButton edge="end"
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </InputAdornment>)
        }
        if (error && !props.select) {
            return (
                <InputAdornment position={"end"}>
                    <ErrorIcon color="error" />
                </InputAdornment>
            )
        }
        return undefined;
    }

    const getInputType = () => {
        if (props.type && props.type == 'password' && showPassword) {
            return "text"; // Usuário quer visualizar a senha, então troca para text
        } else {
            return props.type;
        }
    }

    const getHelperText = () => {
        if (props.characterCounter) {
            return (
                <Grid component={'span'} container direction="row" justify="space-between" alignItems="center">
                    <span>{error || props.helperText}</span>
                    <span>{counter}/{props.maxLength}</span>
                </Grid>
            )
        } else {
            return error || props.helperText;
        }
    }

    return (

        <TextField
            variant={props.variant||"standard"}
            required={props.required}
            fullWidth
            disabled={props.disabled}
            id={props.id}
            name={props.name}
            margin="none"
            label={props.label}
            multiline={props.multiline}
            rows={props.rows}
            //defaultValue={props.initialValue ? props.initialValue : ''}
            value={value}
            type={getInputType()}
            placeholder={props.placeholder}
            error={error != ''}
            helperText={getHelperText()}
            onChange={handleChange}
            onBlur={handleBlur}
            autoFocus={props.autoFocus}
            InputProps={{
                inputComponent: props.inputComponent,
                startAdornment: props.startAdornment ? <InputAdornment position="start">{props.startAdornment}</InputAdornment> : undefined,
                endAdornment: renderEndAdornment(),
                style: props.inputStyle
            }}
            InputLabelProps={{
                shrink: props.shrinkLabel,
                style: props.labelStyle
            }}
            inputProps={{
                maxLength: props.maxLength,
                minLength: props.minLength,
                pattern: props.pattern,
                onKeyPress: handleKeyPress
            }}
            select={props.select}
            SelectProps={{
                native: true
            }}
            inputRef={combinedRef}
        >
            {props.children}
        </TextField>
    );
}
import React from 'react'
import { makeStyles, createStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
  },
  markedH1Left: {
      height: 4,
      width: 73,
      display: 'block',
      marginTop: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
  },
}), { name: 'PageTitle' });

interface PageTitleProps {
  title: string;
}

export const PageTitle = ({title}: PageTitleProps) => {

  const classes = useStyles();

  return (
    <Typography variant="h1" color="textPrimary" className={classes.title}>
      {title}
      <span className={classes.markedH1Left} />
    </Typography>
  )
}

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { APIError } from '../../../services/api/APIError';
import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import SecurityIcon from '@material-ui/icons/Security';
import { Button } from '@material-ui/core';

export interface EmptyStateProps {
  error: APIError;
  defaultDescription: string;
  margin?: string;
  onTryAgainClick: () => any;
}

export default function ErrorEmptyState({ error, defaultDescription, margin, onTryAgainClick: handleTryAgain }: EmptyStateProps) {

  let title = "Oops, ocorreu um erro"
  let description = defaultDescription;
  let icon = <ReportProblemIcon style={{ fontSize: '58px' }} color="disabled" />;

  switch (error.type) {
    case "NetworkError":
      title = "Falha de Comunicação"
      description = "Não foi possível conectar ao servidor";
      icon = <SignalWifiOffIcon style={{ fontSize: '58px' }} color="disabled" />
      break;
    case "OfflineError":
      title = "Sem Internet"
      description = "Não há conexão disponível. Verifique sua conexão de dados ou wifi";
      icon = <SignalWifiOffIcon style={{ fontSize: '58px' }} color="disabled" />
      break;
    case "UnauthorizedError":
      title = "Falha de autenticação"
      description = "Você não está logado ou sua sessão expirou. Tente efetuar novo login.";
      icon = <SecurityIcon style={{ fontSize: '58px' }} color="disabled" />
      break;
    default:
      title = "Oops, ocorreu um erro"
      break;
  }


  return (
    <Grid container direction="column" justify="flex-end" spacing={1} alignItems="center" style={{ marginTop: margin || '20vh', marginBottom: margin || '20vh' }} >

      <Grid item>
        {icon}
      </Grid>
      <Typography align="center" variant="h6" gutterBottom={true} color="textSecondary">{title}</Typography>
      <Typography align="center" variant="body1" gutterBottom={true} color="textSecondary">{description}</Typography>
      <Grid item>
        <Button id="btnTryAgain" onClick={handleTryAgain} type="submit" color="primary">
          Tentar Novamente
          </Button>
      </Grid>

    </Grid>
  );
}
import React from 'react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { Typography, makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  button: {
    border: '4px solid currentColor',
    borderRadius: 0,
    height: 'auto',
    padding: theme.spacing(2, 5),
  },
  link: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}), { name: 'ProductHelp' });

export default function ProductHelp() {
    const classes = useStyles();

  return (
    <Container className={classes.root} component="section">
      <Button className={classes.button}>
        <Typography variant="h4" component="span">
          Alguma dúvida? Precisa de ajuda?
        </Typography>
      </Button>
      <Typography variant="subtitle1" className={classes.link}>
        Consulte nosso FAQ ou entre em contato com nosso atendimento.
      </Typography>
      <img src="https://www.svgrepo.com/show/301688/information-service-help.svg" width="60" alt="Mais informações" />
    </Container>
  );
}

import { format, parseISO, set, isEqual } from 'date-fns';

export default class ISODate {

    static MAX_DATE: ISODate = new ISODate(new Date(9999, 11, 31, 0, 0, 0, 0));

    private localDate: Date;

    constructor(value: string | Date) {
        if (typeof value === 'string') {
            this.localDate = parseISO(value);
        } else {
            this.localDate = set(value, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
        }

    }

    get date() {
        return this.localDate;
    }

    get isMaxDate() {
        return isEqual(this.localDate, ISODate.MAX_DATE.date);
    }

    toFormat(formatString: string) {
        return format(this.localDate, formatString);
    }

    toString(): string | undefined {
        return format(this.localDate, 'yyyy-MM-dd');
    }

    toJSON(): string | undefined {
        return format(this.localDate, 'yyyy-MM-dd');
    }

}
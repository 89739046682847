import React, { useRef, useState } from 'react';
import { Button, Card, CardContent, CardHeader, Container, Divider, Grid, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { useSnackbar } from 'notistack';
import { Link, useHistory, Redirect } from 'react-router-dom';
import { ImagemVO } from '../../../../models/Imagem';
import APIException from '../../../../services/APIException';
import LoginService from '../../../../services/LoginService';
import { BannerProps } from '../../../components/Banner';
import TextInput from '../../../components/TextInput';
import PageTemplate from '../../../layout/PageTemplate';
import TrocaSenhaForm from './components/TrocaSenhaForm';
import { FotoPerfilEditForm } from '../../admin/usuarios/id/components/FotoPerfilEditForm';
import { useSelector } from 'react-redux';
import { selectLogin } from '../../../../stores/slices/userSlice';

export const ProfileEditPage = () => {

  const formsUpdated = useRef<string[]>([]);
  const [unsavedFormData, setUnsavedFormData] = useState(false);

  const [banner, setBanner] = useState<BannerProps | undefined>(undefined);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const login = useSelector(selectLogin);

  const history = useHistory();

  const { from } = history.location.state as any || { from: { pathname: "/conta" } };

  if (!login) return (<Redirect to="/login" />);

  const handleFormUpdated = (formName: string) => (unsaved: boolean) => {
    if (unsaved) {
      formsUpdated.current.push(formName); // Marca que o form foi alterado
      if (!unsavedFormData) {
        setUnsavedFormData(true);
      }
    } else {
      formsUpdated.current = formsUpdated.current.filter(form => form != formName); // Desmarca que o form foi alterado
      if (formsUpdated.current.length == 0) {
        setUnsavedFormData(false);
      }
    }
    console.log("FormUpdated", formName, unsaved)
  }

  const updateImagemPerfil = async (imagemPerfil: ImagemVO) => {
    try {
      await new LoginService().updateImagemPerfil(imagemPerfil, login.token)
      const dismissButton = (key: string | number | undefined) => <IconButton onClick={() => closeSnackbar(key)}><CloseIcon color="action" /></IconButton>
      enqueueSnackbar('Foto de Perfil alterada com sucesso! A nova imagem aparecerá no próximo login.', { variant: 'success', action: dismissButton });
    } catch (apiexception) {
      setBanner({
        text: `Erro ao tentar alterar a imagem de perfil: ${apiexception.message}`,
        traceId: apiexception.errorJson && apiexception.errorJson.traceid,
        timestamp: apiexception.errorJson && apiexception.errorJson.timestamp,
        icon: <ReportProblemIcon />,
        buttons: <Button onClick={() => { setBanner(undefined); updateImagemPerfil(imagemPerfil); }} color="primary">Tentar Novamente</Button>
      })
      console.log(apiexception);
    }
  }

  const trocaSenha = (senhaAtual: string, senhaNova: string) => {
    new LoginService().trocaSenha(login.noEmail, senhaAtual, senhaNova, login.token)
      .then(() => {
        const dismissButton = (key: string | number | undefined) => <IconButton onClick={() => closeSnackbar(key)}><CloseIcon color="action" /></IconButton>
        enqueueSnackbar('Senha alterada com sucesso!', { variant: 'success', action: dismissButton });
        formsUpdated.current = formsUpdated.current.filter(form => form != 'Troca Senha'); // Desmarca que o form foi alterado
        if (formsUpdated.current.length == 0 && unsavedFormData) {
          setUnsavedFormData(false);
        }
      })
      .catch((apiexception: APIException) => {
        setBanner({
          text: `Erro ao tentar trocar a senha: ${apiexception.message}`,
          traceId: apiexception.errorJson && apiexception.errorJson.traceid,
          timestamp: apiexception.errorJson && apiexception.errorJson.timestamp,
          icon: <ReportProblemIcon />,
          buttons: <Button onClick={() => { setBanner(undefined); trocaSenha(senhaAtual, senhaNova); }} color="primary">Tentar Novamente</Button>
        })
        scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        console.log(apiexception);
      });

  }


  return (

    <PageTemplate
      metaTags={{
        title: "Gottaup.com | Editar Perfil",
        description: "Atualize o seu perfil"
      }}
      menuDesktop
      headerMobile={{
        title: "Editar Perfil",
        leftButtons: (
          <IconButton edge="start" color="inherit" component={Link} to={from}>
            <ArrowBackIcon />
          </IconButton>
        )
      }}
      headerDesktop={{
        title: "Editar Perfil",
        breadcrumbs: [
          { label: "Home", to: '/', icon: HomeIcon },
          { label: "Login", to: '/login', icon: PersonIcon },
          { label: "Perfil" }
        ]
      }}
      banner={banner}
      confirmLeavePage={{
        when: unsavedFormData,
        title: "Tem certeza que deseja deixar a página sem salvar as alterações?",
        content: "Ao confirmar os dados alterados não serão salvos. Se cancelar, poderá continuar a edição."
      }}
    >

      <Container component="section" style={{ marginTop: 24 }}>

        <Grid container spacing={3}>

          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Conta" />
              <Divider />
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid item xs={12}>

                    <TextInput
                      id="login.noUsuario"
                      label="Nome do Usuário"
                      disabled={true}
                      initialValue={login.noUsuario}
                    />

                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      id="login.noEmail"
                      disabled={true}
                      label="Email"
                      initialValue={login.noEmail}
                    />

                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <TrocaSenhaForm
              onFormUpdate={handleFormUpdated("Troca Senha")}
              onFormSubmit={trocaSenha} />
          </Grid>

          <Grid item xs={12} md={6}>
            <FotoPerfilEditForm
              imagemPerfil={login.foto ? { nuImagem: login.foto } as ImagemVO : undefined}
              onFormUpdate={handleFormUpdated("Foto Perfil")}
              onFormSubmit={updateImagemPerfil} />
          </Grid>


        </Grid>

      </Container>

    </PageTemplate>
  )
}
import Endereco, { EnderecoVO } from "./Endereco";
import DadoBancario, { DadoBancarioVO } from "./DadoBancario";
import Imagem, { ImagemVO } from "./Imagem";

export interface EmpresaVO {
  nuEmpresa: number;
  nuCnpjEmpresa: string;
  noFantasia: string;
  noRazaoSocial: string;
  nuTelefone: string;
  nuCelular: string;
  noEmail: string;
  tsCadastro: string;
  tsExclusao?: string;
  noUrlSite?: string|null;
  imagemLogo: ImagemVO;
  endereco: EnderecoVO;
  dadoBancario: DadoBancarioVO;
}


export default class Empresa {

  public nuEmpresa: number | undefined;
  public nuCnpjEmpresa: string | undefined;
  public noFantasia: string | undefined;
  public noRazaoSocial: string | undefined;
  public nuTelefone: string | undefined;
  public nuCelular: string | undefined;
  public noEmail: string | undefined;
  public tsCadastro: Date | undefined;
  public tsExclusao: Date | undefined;
  public noUrlSite: string | undefined;
  public imagemLogo: Imagem | undefined;
  public endereco: Endereco | undefined;
  public dadoBancario: DadoBancario | undefined;

  constructor(obj: object | null = null) {
    if (obj) {
      Object.assign(this, obj);
      if (this.imagemLogo) this.imagemLogo = new Imagem(this.imagemLogo);
      if (this.endereco) this.endereco = new Endereco(this.endereco);
      if (this.dadoBancario) this.dadoBancario = new DadoBancario(this.dadoBancario);
    }
    Object.seal(this); // Não permite alterar estrutura da classe
  }


  get nuCnpjEmpresaFormatado(): string {
    return this.nuCnpjEmpresa ? this.nuCnpjEmpresa.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/g, '$1.$2.$3/$4-$5') : '';
  }

  set nuCnpjEmpresaFormatado(value: string) {
    const match = value.match(/\d+/g);
    this.nuCnpjEmpresa = match ? match.join('') : undefined;
  }

  get nuTelefoneFormatado(): string {
    return this.nuTelefone ? this.nuTelefone.replace(/^(\d{2})(\d{4,5})(\d{4})$/g, '($1)$2-$3') : '';
  }

  set nuTelefoneFormatado(value: string) {
    const match = value.match(/\d+/g);
    this.nuTelefone = match ? match.join('') : undefined
  }

  set nuCelularFormatado(value: string) {
    const match = value.match(/\d+/g);
    this.nuCelular = match ? match.join('') : undefined;
  }

  get nuCelularFormatado(): string {
    return this.nuCelular ? this.nuCelular.replace(/^(\d{2})(\d{4,5})(\d{4})$/g, '($1)$2-$3') : '';
  }

}